import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {
  ssid: string;
  phNumberViaLink: any;
  textMessage: any;

  constructor(private router: Router, private http: HttpClient) {
    this.initAsync();
  }
  
  async initAsync() {
    const tempURL = this.router.url.toString().split("?")[1];
    const phonePrams = tempURL.split("&")[0];
    const phoneSfa = tempURL.split("&")[1];
    this.ssid = phoneSfa.split("=")[1];
    this.phNumberViaLink = phonePrams.split("=")[1];
  
    // https://wa.chatmybot.in/#/termCondition?phonenumber=919696900500&ssid=11038462
    const userbody = {
      "sfaCode": "1-" + this.ssid
    };
  
    try {
      // Use .toPromise() to convert the Observable to a Promise
      const response: any = await this.http.post("https://wa.chatmybot.in/chatbotdynamicmaven/api/v1/bot/generatekeypost", userbody).toPromise();
      this.textMessage = response.id;
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  
    this.whatsAppRedirect(); 
  }
  

  whatsAppRedirect() {
    window.location.href = "https://wa.me/" + this.phNumberViaLink + "?text=" + this.textMessage
  }

  ngOnInit(): void { }
}