<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
    <input class="form-control" type="text" placeholder="Enter Employee Code" (keyup)="setEmpCode($event.target)" [value]="empCode">
    <br>
    <input class="form-control" type="file" (change)="upload($event)">
  </div>
  <div class="form-group">
    <button class="btn btn-danger">Upload</button>
  </div>
</form>
