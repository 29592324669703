
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" class="btn btn-add">Add New Template<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="addNewDiv">
          <form ngNativeValidate (submit)="isOrUpdate()">
            <div class="form-group col-lg-12" >
              <label style="color: red"><sup class="required">*</sup>1. The Template name must be small, not start's with special character and no space in between.</label>
              <br><label style="color: red"><sup class="required">*</sup>2. The Variable Part must not be at First and Last Postion in the Template Body.</label>
              <br><label style="color: red"><sup class="required">*</sup>3. Kindly use the Curly Brackets for variable Part in Template body. For Example like this:-{{this.rule}}</label>
              <br><label style="color: red"><sup class="required">*</sup>4. Kindly Enter Country Code While using Calling Button</label>
              <br><label style="color: red"><sup class="required">*</sup>5. Not Two Templates have the same Name</label>
            </div>
            <div class="form-group col-lg-6" >
              <label>Template Name  <sup class="required">*</sup></label>
              <input [(ngModel)]="cloudTemplateMasterDao.name" MAXLENGTH="40" type="text" class="form-control"  name="nam" (input)="templateNameValidate()" required [disabled] = "isUpdate == true">
            </div>
            <div class="form-group col-lg-6" >
              <label>Template Status   <sup class="required">*</sup></label>
              <select [(ngModel)]="cloudTemplateMasterDao.status" type="text" class="form-control"  name="status" required disabled>
              <option value="PENDING" >PENDING</option>
              <option value="APPROVED" >APPROVED</option>
              <option value="REJECTED" >REJECTED</option>
              </select>
            </div>
            <div class="form-group col-lg-6" >
              <label>Template Category   <sup class="required">*</sup></label>
              <select [(ngModel)]="cloudTemplateMasterDao.category" type="text" class="form-control" name="category" required [disabled] = "isUpdate == true">
                  <option [value]="'MARKETING'" >MARKETING</option>
                  <option [value]="'UTILITY'" >UTILITY</option>
                  <option [value]="'AUTHENTICATION'" >AUTHENTICATION</option>
              </select>
            </div>
            <div class="form-group col-lg-6" >
              <label>Language   <sup class="required">*</sup></label>
              <select [(ngModel)]="cloudTemplateMasterDao.language" type="text" class="form-control" name="language" required [disabled] = "isUpdate == true">
                <ng-container *ngFor="let lang of languageJson">
                  <option [value]="lang.Code">{{lang.Language}}</option>
                </ng-container>
              </select>
            </div>
            <div class="form-group col-lg-6" >
              <label>Add Media   <sup class="required">*</sup></label>
              <select [(ngModel)]="headerComponents.format" (click)="UploadStatusChange(headerComponents.format)" type="text" class="form-control" name="code" required [disabled] = "isUpdate == true">
                <option [value]="'NOMEDIA'" >NO MEDIA</option>
                <option [value]="'IMAGE'" >IMAGE</option>
                <option [value]="'DOCUMENT'" >DOCUMENT</option>
                <option [value]="'VIDEO'" >VIDEO</option>
              </select>
            </div>
            <div class="form-group col-lg-6" >
              <label>Upload Media </label>
              <input type="file" class="form-control" name="name" (change)="selectFile($event)" [disabled]="this.disableMedia  == true" [required]="this.uploadMediaRequired == true">
            </div>

            <div [hidden]="showLabel" class="form-group col-lg-12" >
              <label style="color: red"><sup class="required">*</sup> Text Must not start or ends with variable. </label>
            </div>
            <div class="form-group col-lg-12" >
              <label>Body Text<sup class="required">*</sup></label>
              <textarea type="text" class="form-control" (input)="validateTemplateBody()" [(ngModel)]="bodyComponents.text" name="templateBodyText" required></textarea>
            </div>
            <div  class=" form-group">
              <table class="table table-hover">
                <thead>
                <tr>
<!--                  <th>Add Button</th>-->
                  <th>Sub_Type</th>
                  <th>Text</th>
                  <th>URL Type</th>
                  <th>URL</th>
                  <th>Phone Number</th>
                  <th>Example</th>
                  <th ><i  title="Add Row"  (click)="addNewButtonRow(0)" class="btn btn-add fa fa-plus-circle" ></i></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let button of buttonList;let i = index">
                  <tr>
<!--                    <td >Button</td>-->
                    <td ><select [disabled] = "isUpdate == true" [(ngModel)]="button.type" type="text" class="form-control" [name]="'button'+i" required>
                      <option *ngIf="i<1" [value]="'URL'" >URL</option>
                      <option *ngIf="i<2" [value]="'PHONE_NUMBER'" >PHONE NUMBER</option>
                      <option  [value]="'QUICK_REPLY'" >QUICK REPLY</option>
                    </select></td>
                    <td ><input MAXLENGTH="20" [disabled] = "isUpdate == true" [name]="'textval'+i" type="text" [(ngModel)]="button.text"></td>
                    <td >
                      <select [disabled] = "isUpdate == true || button.type != 'URL'" [name]="'textval5'+i" type="select" [(ngModel)]="button.urlType">
                      <option value="static" >Static</option>
                      <option value="dynamic" >Dynamic</option></select>
                    </td>
                    <td ><input [disabled] = "isUpdate == true || button.type == 'PHONE_NUMBER' || button.type == 'QUICK_REPLY'" [name]="'textval2'+i" type="text" [(ngModel)]="button.url" ></td>
                    <td ><input [disabled] = "isUpdate == true || button.type == 'URL' || button.type == 'QUICK_REPLY'" [name]="'textval3'+i" type="text" [(ngModel)]="button.phone_number" ></td>
                    <td ><input style="width: 52%" [disabled] = "isUpdate == true || button.type == 'PHONE_NUMBER' || button.type == 'QUICK_REPLY'" [name]="'textval4'+i" type="text" [(ngModel)]="button.example[0]" ></td>
                    <td><i title="Remove Parameter" (click)="removeButtonRow(0,i)" class="fa fa-trash btn btn-danger"></i></td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <div class="form-group col-lg-12" >
              <label>Template Footer  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="footerComponents.text" name="namespace" required>
            </div>
            <div class="form-group col-lg-12">
              <button *ngIf="isUpdate == true" type="submit" class="btn btn-add" [innerHTML]="'Update'"></button>
              <button *ngIf="isUpdate == false" type="submit" type="submit" class="btn btn-add" [innerHTML]="'Save'"></button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Template Id</th>
              <th>Template Name</th>
              <th>Category </th>
              <th>Language </th>
              <th>Create Date </th>
              <th>Update Date</th>
              <th>Status Type</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let template of tableList;let ind =index">
              <tr>
                <td [innerHTML]="template.id"></td>
                <td [innerHTML]="template.name"></td>
                <td [innerHTML]="template.category"></td>
                <td [innerHTML]="template.language"></td>
                <td [innerHTML]="template.createDate"></td>
                <td [innerHTML]="template.updateDate"></td>
                <td *ngIf="template.status == 'APPROVED'" style="color: #2a80e1; font-weight: bold " [innerHTML]="template.status"></td>
                <td *ngIf="template.status == 'REJECTED'" style="color: red; font-weight: bold" [innerHTML]="template.status"></td>
                <td *ngIf="template.status == 'PENDING'" style="color: orange; font-weight: bold" [innerHTML]="template.status"></td>
                <td *ngIf="template.status == 'PENDING_DELETION'" style="color: orange; font-weight: bold" [innerHTML]="template.status"></td>
                <td><i title="Click Here To Edit" (click)="edit(template,ind)" class="btn btn-add fa fa-pencil"></i></td>
                <td><i title="Click Here To Delete" (click)="deleteOrDisableTemp(template,ind)" class="btn btn-add fa fa-trash"></i></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

