import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

const appPrefix = environment.appUrl + environment.accountMicroverservice;

@Injectable({
  providedIn: 'root'
})
export class UidGenerationService {

  constructor(private http: HttpClient) {
  }

  generateUid() {
    return new Promise<string>(resolve => {
      this.http.get<string>(appPrefix + 'uid/generate').subscribe(value => {
        resolve(value);
      });
    });
  }


  generateCounterNumber(id: number, inc: number) {
    return new Promise<number>(resolve => {
      this.http.get<number>(appPrefix + 'counter/get?id=' + id + '&inc=' + inc).subscribe(value => {
        resolve(value);
      });
    });
  }
}
