import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment.prod';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {MatDialog} from '@angular/material/dialog';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {UsermasterService} from '../../../../Services/UsermasterService/usermaster.service';
import {UserService} from '../../../../Services/User/user.service';
import {formatDate} from '@angular/common';
import {Subscription} from 'rxjs';
import {CigarateDao} from '../cigarate-panel-dash/CigarateDao';


@Component({
  selector: 'app-cigarate-panel-dash-c4',
  templateUrl: './cigarate-panel-dash-c4.component.html',
  styleUrls: ['./cigarate-panel-dash-c4.component.css']
})
export class CigaratePanelDashC4Component implements OnInit {
  tableData: PaginationDao<CigarateDao> = {data: [], total: 0};
  offset = 0;
  limit = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  RecordingServerUrl = 'https://prpmobility.com/voice/DIGIMOZO/RECORDINGS/';
  dataSubscription: Subscription;
  projectid:number=4;

  constructor(public user: UserService, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {
    this.loadData();
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;
      this.loadData();
    });
  }

  // tslint:disable-next-line:typedef
  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined || this.enddate == undefined) {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);

    }

    // if (this.startdate == undefined || this.enddate == undefined) {
    //   this.startdate = 20101010;
    //   this.enddate = 20301010;
    // }

    // tslint:disable-next-line:max-line-length
    this.http.get<PaginationDao<CigarateDao>>(environment.appUrl + environment.digimozoservice + 'paytm/get?projectid='+this.projectid+'&startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit).subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });

  }


  // tslint:disable-next-line:typedef
  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  // tslint:disable-next-line:typedef
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  // tslint:disable-next-line:typedef
  downloadFile() {



    this.dataSubscription = this.http.get<PaginationDao<CigarateDao>>(environment.appUrl + environment.digimozoservice + 'paytm/get?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=0&limit=200000&projectid='+this.projectid).subscribe(value => {
      this.dataSubscription.unsubscribe();
      // this.tableData = value;
      const data = value.data;

      console.log('DOWNLOADED DATA');
      let trArr = [];
      let tdArr = [];
      tdArr.push('Transaction-Id');
      tdArr.push('Mobile');
      tdArr.push('Circle');
      tdArr.push('Unique');
      tdArr.push('Brand Story Played');
      tdArr.push('Misscall Time');
      tdArr.push('Connect Time');
      tdArr.push('Hangup Time');
      tdArr.push('Call Duration');
      tdArr.push('Hangup Date');
      tdArr.push('Call Stage');
      tdArr.push('Question Number');
      tdArr.push('Question');
      tdArr.push('Answer');
      tdArr.push('Cashback');
      tdArr.push('Amount Transfer');
      tdArr.push('Status Code');
      tdArr.push('Status Message');
      tdArr.push('Answer Key');
      tdArr.push('Age Consent');
      tdArr.push('Coupon');
      tdArr.push('Circle');
      tdArr.push('Is Coupon Valid');
      trArr.push(tdArr);


      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].id);
        tdArr.push(data[i].mobile);
        tdArr.push(data[i].carrierCircle);
        if (data[i].isunique) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }
        if (data[i].callLevel == 2) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }

        tdArr.push(data[i].misscallTime);
        tdArr.push(data[i].connectTime);
        tdArr.push(data[i].disConnectTime);
        tdArr.push(data[i].callduration);
        if(data[i].disConnectTime != undefined && data[i].disConnectTime !+ null){
          let disconnectTimeArr = data[i].disConnectTime.split(" ");
          tdArr.push(disconnectTimeArr[0]);
        }else{
          tdArr.push("NA");
        }
        tdArr.push(data[i].callStage);

        if(data[i].questionSeq == 1){
          tdArr.push(1);
        }else{
          if(data[i].questionSeq > 0){
            tdArr.push(data[i].questionSeq-1);
          }
        }

        tdArr.push(data[i].questionStr);
        tdArr.push(data[i].answerStr);

        if (data[i].amountTransfer) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }

        tdArr.push(data[i].amountTransferAmt);
        tdArr.push(data[i].statusCode);
        tdArr.push(data[i].statusMessage);
        tdArr.push(data[i].answerKey);
        if (data[i].ageConsentVal) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }
        tdArr.push(data[i].coupon);
        tdArr.push(data[i].codeCircle);

        if (data[i].isCouponValid) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }


        trArr.push(tdArr);
      }

      const file = new FileService<any>();
      console.log('Sending file for xlsx creation');
      file.generateFile(trArr, 'ReportDownload.xlsx');
      trArr = [];




    });
  }

  // tslint:disable-next-line:typedef
  refreshTransaction(id: number, ind: number) {

    this.alert.showLoader();
    this.http.get<CigarateDao>(environment.appUrl + environment.digimozoservice + 'paytm/refreshOrderDetails?id=' + id).subscribe(value => {
      this.tableData.data[ind] = value;
      this.alert.hideLoader();

    });
  }

}
