<div class="row">
<!--  <div>-->
<!--    <h1  class="heading">QUESTION & ANSWER FLOW</h1>-->
<!--  </div>-->
  <div class="flow-container" [ngClass]="{'scrollable': dynamicMapEntries.length > 3}">
    <ng-container *ngFor="let entry of dynamicMapEntries; let last = last">
      <div class="flow-item" [ngClass]="{ 'flow-item-increase': entry[1].length > 20, 'flow-item-decrease': entry[1].length <= 20 }">
        <div class="key">{{ entry[0] }}</div>
        <div class="divider"></div>
        <div [innerHTML]="getSafeHtml(entry[1])" *ngIf="containsLink(entry[1])"></div>

        <div [innerHTML]=entry[1] *ngIf="!containsLink(entry[1])"></div>
<!--        <div><a href="https://www.google.com">{{entry[1]}}</a></div>-->
<!--        <div class="value">{{ entry[1] }}</div>-->
      </div>

      <div *ngIf="!last" class="vertical-arrow">↓</div>
    </ng-container>
  </div>
</div>
