import {Component, Inject, Input, OnInit} from '@angular/core';
import {AccountMasterDao} from '../AccountMasterDao';
import {FilterDao} from '../../crdrreport/FilterDao';
import {Router} from '@angular/router';
import {UserService} from '../../Services/User/user.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AccountServiceFormDao} from '../account-services/AccountServiceDao';
import {AccountFilterDao} from './AccountFilterDao';

@Component({
  selector: 'app-account-filter',
  templateUrl: './account-filter.component.html',
  styleUrls: ['./account-filter.component.css']
})
export class AccountFilterComponent implements OnInit {


  constructor(  private router: Router,private user:UserService,@Inject(MAT_DIALOG_DATA) public data: AccountFilterDao) { }

  ngOnInit(): void {
  }

  filterData() {
    this.data.filter.accountId=this.user.user.accountId;
    this.router.navigate([this.data.pageName], {queryParams: this.data.filter});
 }



}
