import {Component, OnInit} from '@angular/core';
import {DomainMasterDao} from '../domain-master/DomainMasterDao';
import {ClientSidePagination} from '../../CommonObj/ClientSidePagination';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {DomainService} from '../domain-master/DomainService/domain.service';
import {InstanceMasterDao} from './InstanceMasterDAo';
import {InstanceMasterService} from './InstanceService/instance-master.service';
import {ProxyMasterDao} from '../proxymaster/ProxyMasterDao';
import {ProxyService} from '../proxymaster/ProxyService/proxy.service';
import {PageDao} from '../../Feature/page-dao';
import {FeatureDao} from "../../Feature/feature-dao";
import {AuthGuard} from "../../Services/Routing/Authguard";


@Component({
  selector: 'app-instance-master',
  templateUrl: './instance-master.component.html',
  styleUrls: ['./instance-master.component.css']
})
export class InstanceMasterComponent implements OnInit {

  pagePermission:number;
  domainList: Array<DomainMasterDao> = [];
  proxyList: Array<ProxyMasterDao> = [];
  addNewDiv = false;
   tableList1: FeatureDao;
  instance: InstanceMasterDao = {
    hasWebhook: false,
    hasOutWebhook: false,
    accountId: 0,
    active: true,
    connected: false,
    domain: '',
    hasProxy: false,
    name: '',
    phoneNumber: '',
    id: 0,
    instanceName: '',
    netConnected: false,
    perDayLimit: 0,
    proxyId: 0,
    refId: '',
    saveToDb: false,
    webhook: '',
    outwebhook: '',
    activeSalesApi:false,
    clientid:'',
    clientsecret:'',
    granttype:'',
    username:'',
    password:''
    // hasIncomingWebhookHeader:false,
    // headerKey:'',
    // headerValue:''
  };
  tableList: ClientSidePagination<InstanceMasterDao> = {data: [], end: 0, start: 0, total: 0};

  constructor(private alert: AlertMessageService, private instanceService: InstanceMasterService, private domainService: DomainService, private proxyService: ProxyService, private page:AuthGuard) {


  }

  ngOnInit(): void {

    this.loadData();
    this. getPage();
    this.domainService.get().subscribe(value => {
      this.domainList = value;
    });
    this.proxyService.getActive().subscribe(value => {
      this.proxyList = value;
    });
  }

  getPage()
  {
    this.page.getPagePermission('instance').subscribe(value => {

      if (value!=null)
      {
        this.tableList1=value;
        if (this.tableList1.type.create==true)
        {
          this.pagePermission=1;
        }
       else{
          this.pagePermission=2;
       }
      }

      else {
        this.pagePermission=3;

      }


      console.log('pageDao',this.tableList1);
    })
  }
  // tslint:disable-next-line:typedef
  loadData() {
    this.instanceService.get().subscribe(value => {
      this.tableList = {data: value, end: 0, start: 0, total: value.length};
    });
  }

  edit(instance: InstanceMasterDao) {
    this.instance = instance;
    this.addNewDiv = true;
  }


  // tslint:disable-next-line:typedef
  isOrUpdate() {

    if (this.instance.perDayLimit <= 0)
    {
      this.alert.showErrorMessage('Per day limit cannot be zero! ');
      return;
    }
    this.alert.showLoader();
    console.log("before hit",this.instance);
    this.alert.confirmMessage('Save Instance?', 'Are you sure?').then(value => {
      this.instanceService.save(this.instance).subscribe(value1 => {
       console.log("Dharam value is ", value1);
        this.instance = value1;
        this.loadData();
        this.alert.hideLoader();
        this.alert.showSuccesMessage('Successfully Saved Instance!!');
        this.cancel();
      }, error => {
        this.alert.showErrorMessage(error.error.message);
        this.alert.hideLoader();
      });
    });

  }

  delete(data: InstanceMasterDao, ind: number) {

  }

  cancel() {
    this.addNewDiv = false;
    this.instance = {
      hasWebhook: false,
      hasOutWebhook: false,
      accountId: 0,
      active: true,
      connected: false,
      domain: '',
      hasProxy: false,
      name: '',
      phoneNumber: '',
      id: 0,
      instanceName: '',
      netConnected: false,
      perDayLimit: 0,
      proxyId: 0,
      refId: '',
      saveToDb: false,
      webhook: '',
      outwebhook: '',
      activeSalesApi:false,
      clientid:'',
      clientsecret:'',
      granttype:'',
      username:'',
      password:''
      // hasIncomingWebhookHeader:false,
      // headerKey:'',
      // headerValue:''
    };
  }


  addNew() {
    this.instance = {
      hasWebhook: false,
      hasOutWebhook: false,

      accountId: 0,
      active: true,
      connected: false,
      domain: '',
      hasProxy: false,
      name: '',
      phoneNumber: '',
      id: 0,
      instanceName: '',
      netConnected: false,
      perDayLimit: 0,
      proxyId: 0,
      refId: '',
      saveToDb: false,
      webhook: '',
      outwebhook: '',
      activeSalesApi:false,
      clientid:'',
      clientsecret:'',
      granttype:'',
      username:'',
      password:''
      // hasIncomingWebhookHeader:false,
      // headerKey:'',
      // headerValue:''
    };
    this.addNewDiv = true;
  }

  // tslint:disable-next-line:typedef
  loadQr(data: InstanceMasterDao) {
    this.instanceService.loadQr(data.id);
  }

  // tslint:disable-next-line:typedef
  reboot(data: InstanceMasterDao) {
    this.alert.confirmMessage('Reboot Instance', 'Are you sure?').then(value => {
      this.instanceService.reboot(data.id).subscribe(value1 => {
      });
    });
  }

  logout(data: InstanceMasterDao) {
    this.alert.confirmMessage('Logout Instance', 'Are you sure?').then(value => {
      this.instanceService.logout(data.id).subscribe(value1 => {
        if (value1._status == true) {
          this.alert.showSuccesMessage('Successfully Logout');
          // window.location.reload();
        }
      });
    });
  }

  loadQrlegacy(data: InstanceMasterDao) {
    this.instanceService.loadQrLegacy(data.id);
  }
}
