import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment.prod';
import {CigarateDao} from './CigarateDao';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {MatDialog} from '@angular/material/dialog';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {UsermasterService} from "../../../../Services/UsermasterService/usermaster.service";
import {UserService} from "../../../../Services/User/user.service";
import {formatDate} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-cigarate-panel-dash',
  templateUrl: './cigarate-panel-dash.component.html',
  styleUrls: ['./cigarate-panel-dash.component.css']
})
export class CigaratePanelDashComponent implements OnInit {
  tableData: PaginationDao<CigarateDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  RecordingServerUrl:string="https://prpmobility.com/voice/DIGIMOZO/RECORDINGS/";
  dataSubscription:Subscription;

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {
    this.loadData();
    //user.getAccount().id
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
    });
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }

    // if (this.startdate == undefined || this.enddate == undefined) {
    //   this.startdate = 20101010;
    //   this.enddate = 20301010;
    // }

    this.http.get<PaginationDao<CigarateDao>>(environment.appUrl + environment.digimozoservice + 'paytm/get?projectid=3&startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit).subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });

  }


  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  downloadFile() {



    this.dataSubscription = this.http.get<PaginationDao<CigarateDao>>(environment.appUrl + environment.digimozoservice + 'paytm/get?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=0&limit=200000&projectid=3').subscribe(value => {
      this.dataSubscription.unsubscribe();
      //this.tableData = value;
      var data = value.data;

      console.log("DOWNLOADED DATA")
      var trArr = [];
      var tdArr = [];
      tdArr.push('Transaction-Id');
      tdArr.push('Mobile');
      tdArr.push('Unique');
      tdArr.push('Misscall Time');
      tdArr.push('Connect Time');
      tdArr.push('Hangup Time');
      tdArr.push('Call Duration');
      tdArr.push('Call Stage');
      tdArr.push('Brand Name');
      tdArr.push('Question');
      tdArr.push('Answer');

      tdArr.push('Cashback');
      tdArr.push('Amount Transfer');
      tdArr.push('Status Code');
      tdArr.push('Status Message');

      tdArr.push('Brand Key');
      tdArr.push('Answer Key');
      tdArr.push('Age Consent');

      tdArr.push('Coupon');
      tdArr.push('Is Coupon Valid');
      tdArr.push('Is Retailer');
      tdArr.push('Clicked URL?');

      trArr.push(tdArr);


      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].id);
        tdArr.push(data[i].mobile);
        if (data[i].isunique) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }
        tdArr.push(data[i].misscallTime);
        tdArr.push(data[i].connectTime);
        tdArr.push(data[i].disConnectTime);
        tdArr.push(data[i].callduration);
        tdArr.push(data[i].callStage);
        tdArr.push(data[i].brandName);
        tdArr.push(data[i].questionStr);
        tdArr.push(data[i].answerStr);

        if (data[i].amountTransfer) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }

        tdArr.push(data[i].amountTransferAmt);
        tdArr.push(data[i].statusCode);
        tdArr.push(data[i].statusMessage);
        tdArr.push(data[i].brandKey);
        tdArr.push(data[i].answerKey);
        if (data[i].ageConsentVal) {
          tdArr.push('YES');
        } else {
          if((data[i].answerStr != "" && data[i].answerStr != undefined) || (data[i].coupon != "nocoupon" && data[i].coupon != "" && data[i].coupon != null && data[i].coupon != undefined)){
            tdArr.push('YES');
          }else{
            if (data[i].questionStr != "Toh bataye aapke Humsafar ke saath, aapka favourite hangout pal kya hai?" && data[i].questionStr != "" && data[i].questionStr != undefined && data[i].questionStr != null) {
              tdArr.push('YES');
            } else {
              tdArr.push('NO');
            }
          }

        }
        tdArr.push(data[i].coupon);

        if (data[i].isCouponValid) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }

        if (data[i].isRetailer) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }

        if (data[i].clickedUrl) {
          tdArr.push('YES');
        } else {
          tdArr.push('NO');
        }


        trArr.push(tdArr);
      }

      let file = new FileService<any>();
      console.log("Sending file for xlsx creation")
      file.generateFile(trArr, 'ReportDownload.xlsx');
      trArr=[];




    });
 }

  refreshTransaction(id: number, ind: number) {

    this.alert.showLoader();
    this.http.get<CigarateDao>(environment.appUrl + environment.digimozoservice + 'paytm/refreshOrderDetails?id=' + id).subscribe(value => {
      this.tableData.data[ind] = value;
      this.alert.hideLoader();

    });
  }


}
