import {ExpiryDateType} from "./ExpiryDateType";
import {PlanType} from "./PlanType";
import {SubscriptionPackageType} from "./SubscriptionPackageType";

export class SubScriptionPackageDao{

  id: number;
  createDate: string;
  createDateFilter: number;
  updateDate: string;
  accountId: number;
  rentalAmount: number;
  rechargeAmount: number;
  roleId: number;
  remarks: string;
  status: boolean;
  expireyDateType:ExpiryDateType;
  planType:PlanType;
  subscriptionPackageType: SubscriptionPackageType;
  country: string;
  marketingRates: number;
  utilityRates: number;
  authenticationRates: number;
  userInitiatedRates: number;
  accessToken: string;
  razorPayAmount:number;
  totalAMount: number;
  gst: number;
  amountWithGST: number;
}
