import { Component, OnInit } from '@angular/core';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-excel-reader-common',
  templateUrl: './excel-reader-common.component.html',
  styleUrls: ['./excel-reader-common.component.css']
})
export class ExcelReaderCommonComponent implements OnInit {

  constructor(private dialog: MatDialogRef<ExcelReaderCommonComponent>) { }
  file: File;

  ngOnInit(): void {
  }
  // tslint:disable-next-line:typedef
  incomingfile(event)
  {
    this.file = event.target.files[0];
  }
  // tslint:disable-next-line:typedef
  upload() {
    const file = new FileService<any>();
    file.readFile(this.file).then(value => {
      this.dialog.close(value);
    });
  }
}
