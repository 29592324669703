import { Component, OnInit } from '@angular/core';
import {EmpMasterDao} from '../empmaster/EmpMasterDao';
import {UserMasterDao} from '../../../../Services/User/UserMasterDao';
import {UsermasterService} from '../../../../Services/UsermasterService/usermaster.service';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment.prod';
import {ExcelReaderCommonComponent} from '../../../../CommonPopups/excel-reader-common/excel-reader-common.component';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {ProductMasterDao} from './ProductMasterDao';

@Component({
  selector: 'app-productmaster',
  templateUrl: './productmaster.component.html',
  styleUrls: ['./productmaster.component.css']
})
export class ProductmasterComponent implements OnInit {

  tableList: Array<ProductMasterDao> = [];
  team: string = '0';
  managerId: string = '0';

  update = false;



  productMasterDao: ProductMasterDao = {

    id: 0,
    product:""

  };
  addNewDiv: boolean = false;

  constructor(private user:UsermasterService,private alert: AlertMessageService, private router: Router, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute) {


  }

  ngOnInit(): void {
    this.getData();
  }

  addNew() {
    this.update = false;
    this.addNewDiv = true;
  }

  isOrUpdate() {
    this.alert.showLoader()
    var message="Create Product?"
    var successMessage="Successfully Create Product!";
    if(this.update)
    {
      successMessage="Successfully Updated Product!";
      message="Update Product?"
    }
    this.alert.confirmMessage("Are you sure?",message).then(value => {
      this.http.post<ProductMasterDao>(environment.appUrl + environment.gsmgatewayservice + 'product/',this.productMasterDao).subscribe(value => {
        this.productMasterDao=value;
        this.alert.showSuccesMessage(successMessage);
        this.alert.hideLoader();

      },error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage("Duplicate Product!!")
      })
    })


  }

  cancel() {
    this.update = false;
    this.addNewDiv = false;
    this.getData();
  }

  edit(productMasterDao: ProductMasterDao, ind: number) {
    this.productMasterDao = productMasterDao;
    this.update = true;
    this.addNewDiv = true;

  }

  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      if(value==null)
      {
        return;
      }

      let al:Array<ProductMasterDao>=[]
      for(var i=0;i<value.length;i++)
      {
        al.push({
          id: 1,
          product:value[i]["Product"],
        })
      }
      this.alert.confirmMessage("Are you sure?","Upload "+al.length+" Product?").then(asdasd => {
        this.alert.showLoader();
        this.http.post<Array<string>>(environment.appUrl + environment.gsmgatewayservice + 'product/bulk',al).subscribe(alret => {

          let failed:number=0;
          for(var i=0;i<alret.length;i++)
          {
            if(alret[i]=="NA")
            {
              failed++;
            }
          }

          this.alert.hideLoader();
          this.alert.showSuccesMessage("Successfully Uploaded , Failed "+failed)
          this.getData();

        },error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage("Failed To Upload!!")
        })
      })

    })

  }



  downloadSampleFile() {

    var trArr = [];
    var tdArr = [];
    tdArr.push('Product');

    trArr.push(tdArr);




    let file = new FileService<any>();
    file.generateFile(trArr, 'SampleProductUpload.xlsx');
  }

  getData() {
    this.alert.showLoader();

    this.http.get<Array<ProductMasterDao>>(environment.appUrl + environment.gsmgatewayservice + 'product/').subscribe(value => {
      this.tableList = value;
      this.alert.hideLoader();
    });
  }



  delete(productMasterDao: ProductMasterDao, ind: number) {
    this.alert.confirmMessage('Are you Sure?', 'Delete ' + productMasterDao.product + ' (All Records Will be deleted to)? ').then(value => {
      this.http.delete(environment.appUrl + environment.gsmgatewayservice + 'product/?id=' + productMasterDao.id+"&name="+productMasterDao.product).subscribe(value => {
        this.alert.showSuccesMessage("Successfully deleted!")
        this.getData();



      });
    });
  }

}
