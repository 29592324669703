import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NbccserviceService} from "../nbccservice.service";
import {data} from "jquery";
import {TransationDao} from "../transation-dao";
import {AlertMessageService} from "../../../alert-message/alert-message.service";

@Component({
  selector: 'app-transation',
  templateUrl: './transation.component.html',
  styleUrls: ['./transation.component.css']
})
export class TransationComponent implements OnInit {
  addNewDiv: boolean;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, order: []};
  submitBtnTxt: any = 'Submit';
  dao: any = {
    id:'',
    secureHash: '',
    amount: '',
    respDescription: '',
    paymentMode: '',
    paymentInstId: '',
    responseCode: '',
    customerMobileNo: '',
    paymentSubInstType: '',
    merchantId: '',
    paymentID: '',
    merchantTxnNo: '',
    paymentDateTime: '',
    txnID: '',
    bungalowN: '',
    chequeNo: '',
    receivedAmount:''
  };
  title: any = 'Payment Details';
  tableData: Array<TransationDao>;

  constructor(private dialogRef: MatDialogRef<TransationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private service: NbccserviceService, private alert: AlertMessageService) {

    if (data != null) {
      console.log('data ', data);
      this.dao.bungalowN = data.bungalowNo;
      this.dao.amount = data.totalAmout.toString();
      this.dao.customerMobileNo = data.residentialDao.number;
    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.getpaymentStatus();
  }

  addPayemntStatus() {

    this.addNewDiv = true;

  }

  onClose() {
    this.dialogRef.close();
  }

  createOrUpdate() {
    this.dao.respDescription = 'Transaction successful';
    this.dao.responseCode = '0000';
    this.alert.showLoader();
    this.alert.confirmMessage("Save", "Are You Sure ?").then(value => {
      this.service.savePaymentDetails(this.dao).subscribe((value) => {
        if (value == 200) {
          this.alert.showSuccesMessage("Successfully Inserted");
        }
      }, (error) => {
        this.alert.showErrorMessage("Something  Went Wrong!");
      })
    })
    this.alert.hideLoader();
    this.addNewDiv=false;
  }

  getpaymentStatus() {
    this.service.checkPaymentStatus(this.dao.bungalowN).subscribe(value => {
      this.tableData = value;
      this.dtTrigger.next();
    })
  }

  deleteById(id) {
    this.alert.showLoader();
    this.service.deleteByIdTrans(id).subscribe(value => {
        this.alert.showSuccesMessage("Successfully Deleted!")
        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage("Something went wrong!")
        this.alert.hideLoader();
      }
    )
  }
}
