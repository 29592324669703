<!--&lt;!&ndash;<app-payment [routerLink]="['/payment']"></app-payment>&ndash;&gt;-->

<!--<app-payment  *ngIf="userService.openpage"></app-payment>-->

<!--<app-login *ngIf="!userService.isLogin  "></app-login>-->
<!--&lt;!&ndash;<app-service-selection *ngIf="user.isServicePage&&user.isLogin"></app-service-selection>&ndash;&gt;-->
<!--<app-login-otp *ngIf="userService.hasTwoStepAuthentication() && !userService.hasOTPVerified() "></app-login-otp>-->
<!--<app-maincontainer *ngIf="userService.isLogin && !userService.hasTwoStepAuthentication()"></app-maincontainer>-->
<!--<app-maincontainer *ngIf="userService.isLogin && userService.hasTwoStepAuthentication() && userService.hasOTPVerified()">-->

<!--</app-maincontainer>-->


<ng-container *ngIf="!isDirectPage else directpage">
  <app-login *ngIf="!userService.isLogin  "></app-login>
  <!--<app-service-selection *ngIf="user.isServicePage&&user.isLogin"></app-service-selection>-->
  <app-login-otp *ngIf="userService.hasTwoStepAuthentication() && !userService.hasOTPVerified() "></app-login-otp>
  <app-maincontainer *ngIf="userService.isLogin && !userService.hasTwoStepAuthentication()"></app-maincontainer>
  <app-maincontainer *ngIf="userService.isLogin && userService.hasTwoStepAuthentication() && userService.hasOTPVerified()">

  </app-maincontainer>

</ng-container>

<ng-template #directpage>
  <ng-container [ngSwitch]="pageName">
    <app-payment *ngSwitchCase="directPages[0]"></app-payment>
    <app-client-sign-up *ngSwitchCase="directPages[1]" ></app-client-sign-up>
    <app-otp-verification *ngSwitchCase="directPages[2]" ></app-otp-verification>
    <app-initiate-chatbot *ngSwitchCase="directPages[3]" ></app-initiate-chatbot>
    <app-onetomany-no-auth *ngSwitchCase="directPages[4]"></app-onetomany-no-auth>
    <app-term-and-condition *ngSwitchCase="directPages[5]"></app-term-and-condition>

    <!--    <app-registration-otp *ngSwitchCase="directPages[1]"></app-registration-otp>-->
  </ng-container>
</ng-template>
