import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaincontainerComponent } from './maincontainer/maincontainer.component';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptorService} from './Services/TokenInterceptor/token-interceptor.service';
import {RouterModule} from '@angular/router';
import { UserMasterComponent } from './user-master/user-master.component';
import { AccountMasterComponent } from './account-master/account-master.component';
import { ChatComponent } from './chat/chat.component';
import { ReportComponent } from './report/report.component';
import { IndexComponent } from './index/index.component';
import {AuthGuard} from './Services/Routing/Authguard';
import {AppRoutingModule} from './Services/Routing/AppRoutingModule';
import {ChartsModule} from 'ng2-charts';
import {MatDialogModule} from '@angular/material/dialog';
import { FilterCommonComponent } from './filter-common/filter-common.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ChartAndTablesComponent } from './chart-and-tables/chart-and-tables.component';
import { ServiceSelectionComponent } from './service-selection/service-selection.component';
import { MatCardModule} from '@angular/material/card';
import { CrdrreportComponent } from './crdrreport/crdrreport.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccountServicesComponent } from './account-master/account-services/account-services.component';
import {MatRadioModule} from '@angular/material/radio';
import {DataTablesModule} from 'angular-datatables';
import { RechargeComponent } from './account-master/recharge/recharge.component';
import { AccountFilterComponent } from './account-master/account-filter/account-filter.component';
import { TransactionreportComponent } from './transactionreport/transactionreport.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginator} from '@angular/material/paginator';
import { RechargeViewComponent } from './account-master/recharge/recharge-view/recharge-view.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WsTemplateComponent } from './ws-template/ws-template.component';
import { WaofficialDashComponent } from './DashBoards/waofficial-dash/waofficial-dash.component';
import { DateFilterOnlyComponent } from './DashBoards/date-filter-only/date-filter-only.component';
import {NotifierModule} from 'angular-notifier';
import { MessageTypeComponent } from './message-type/message-type.component';
import { OnetomanyComponent } from './onetomany/onetomany.component';
import { ManytomanyComponent } from './manytomany/manytomany.component';
import { ApidocsComponent } from './apidocs/apidocs.component';
import { WebhookComponent } from './webhook/webhook.component';
import { ChatbotconfigComponent } from './chatbotconfig/chatbotconfig.component';
import { CombinationComponent } from './combination/combination.component';
import { ConversationComponent } from './conversation/conversation.component';
import { TemplateMessagetypeComponent } from './template-messagetype/template-messagetype.component';
import { CampaignReportComponent } from './campaign-report/campaign-report.component';

import { GraphCommonComponent } from './graph-common/graph-common.component';
import { MessageObjComponent } from './conversation/message-obj/message-obj.component';
import { ContactFormComponent } from './conversation/contact-form/contact-form.component';
import { MessageInfoComponent } from './conversation/message-info/message-info.component';
import { QuickReplyMessageComponent } from './quick-reply-message/quick-reply-message.component';
import { WaofficialdashboardComponent } from './MicroServices/Waofficial/waofficialdashboard/waofficialdashboard.component';

import { CigaratePanelDashComponent } from './MicroServices/Digimozo/Cigarate/cigarate-panel-dash/cigarate-panel-dash.component';
import { DatefilterComponent } from './CommonPopups/datefilter/datefilter.component';
import { DashboardComponent } from './MicroServices/GsmGateway/Daone/dashboard/dashboard.component';
import { EmpmasterComponent } from './MicroServices/GsmGateway/Daone/empmaster/empmaster.component';
import { EmpreportComponent } from './MicroServices/GsmGateway/Daone/empreport/empreport.component';
import {ExcelReaderCommonComponent} from './CommonPopups/excel-reader-common/excel-reader-common.component';
import {AuthguardDashboard} from './Services/Routing/AuthguardDashboard';
import { ProductmasterComponent } from './MicroServices/GsmGateway/Daone/productmaster/productmaster.component';
import { UserMasterReportingComponent } from './user-master/user-master-reporting/user-master-reporting.component';

import {Ng2SearchPipeModule} from 'ng2-search-filter';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { ServicemasterComponent } from './servicemaster/servicemaster.component';
import { MenumasterComponent } from './menumaster/menumaster.component';
import { ModuleMasterComponent } from './module-master/module-master.component';
import { PermissionentryComponent } from './permissionentry/permissionentry.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { IncomingmessagecomponentComponent } from './HeaderIcons/incomingmessagecomponent/incomingmessagecomponent.component';
import {IncommingSmsReportComponent} from './MicroServices/GsmGateway/Oppo/incomming-sms-report/incomming-sms-report.component';
import {EmasolchatbotComponent} from './MicroServices/Digimozo/EmosolChatbot/emasolchatbot/emasolchatbot.component';
import { EmasolTransactionComponent } from './MicroServices/Digimozo/EmosolChatbot/emasol-transaction/emasol-transaction.component';
import { PriceChartComponent } from './MicroServices/Nouriture/price-chart/price-chart.component';
import { OutletComponent } from './MicroServices/Nouriture/outlet/outlet.component';
import { NouritureConversationComponent } from './MicroServices/Nouriture/Conversation/nouriture-conversation/nouriture-conversation.component';
import { FreighChartComponent } from './MicroServices/Nouriture/freigh-chart/freigh-chart.component';
import { AgGridModule } from 'ag-grid-angular';
import { ApiKeyComponent } from './api-key/api-key.component';
import { OBDComponent } from './RahulPackage/obd/obd.component';
import { OBDChartComponent } from './RahulPackage/obdchart/obdchart.component';
import { EnquiryDetailsComponent } from './MicroServices/Nouriture/enquiry-details/enquiry-details.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {C2cReportComponent} from './MicroServices/HDFC/c2c-report/c2c-report.component';
import { C2cPanelComponent } from './MicroServices/HDFC/c2c-panel/c2c-panel.component';
import { HdfcSupComponent } from './MicroServices/HDFC/hdfc-sup/hdfc-sup.component';
import { HdfcCallsComponent } from './MicroServices/HDFC/hdfc-calls/hdfc-calls.component';
import { HdfcReportComponent } from './MicroServices/HDFC/hdfc-report/hdfc-report.component';
import { HdfcNewjoineeComponent } from './MicroServices/HDFC/hdfc-newjoinee/hdfc-newjoinee.component';
import { PromptUploadComponent} from './MicroServices/HDFC/prompt-upload/prompt-upload.component';
import { ExcelReaderWithDatettimeComponent } from './CommonPopups/excel-reader-with-datettime/excel-reader-with-datettime.component';
import { HdfcResignedComponent } from './MicroServices/HDFC/hdfc-resigned/hdfc-resigned.component';
import { HdfcEmployeeComponent } from './MicroServices/HDFC/hdfc-employee/hdfc-employee.component';
import { RetailerCashbackComponent } from './MicroServices/Digimozo/Cigarate/retailer-cashback/retailer-cashback.component';
import {ScratchCardReportComponent} from './RahulPackage/scratch-card-report/scratch-card-report.component';
import { MatTableModule } from '@angular/material/table';
import {
  CigaratePanelDashC4Component
} from './MicroServices/Digimozo/Cigarate/cigarate-panel-dash-c4/cigarate-panel-dash-c4.component';
import { LongCodeCampaignComponent } from './RahulPackage/long-code-campaign/long-code-campaign.component';
import { ShubhLabhcampaignComponent } from './RahulPackage/shubh-labhcampaign/shubh-labhcampaign.component';
import { LongcodenrComponent } from './RahulPackage/LongCodeNR/longcodenr/longcodenr.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SubscriptionWaUnComponent } from './Unofficial/subscription-wa-un/subscription-wa-un.component';
import { InstanceMasterComponent } from './Unofficial/instance-master/instance-master.component';
import { DomainMasterComponent } from './Unofficial/domain-master/domain-master.component';
import { CommonActiveOrDisableComponent } from './CommonObj/common-active-or-disable/common-active-or-disable.component';
import { ProxymasterComponent } from './Unofficial/proxymaster/proxymaster.component';
import { QrWindowComponent } from './Unofficial/instance-master/qr-window/qr-window.component';
import { DashboardunwsComponent } from './Unofficial/dashboardunws/dashboardunws.component';
import { CommoninstancedropdownComponent } from './Unofficial/commoninstancedropdown/commoninstancedropdown.component';
import { OnetomanywsunComponent } from './Unofficial/onetomanywsun/onetomanywsun.component';
import { TestcmpComponent } from './testcmp/testcmp.component';
import { CampaignReportUnComponent } from './Unofficial/campaign-report-un/campaign-report-un.component';
import { DlrReportComponent } from './Unofficial/dlr-report/dlr-report.component';
import {LongcodeBpphpComponent} from './RahulPackage/BPPHPCampaign/longcode-bpphp/longcode-bpphp.component';
import {DriveComponent} from './Drive/drive/drive.component';
import { FileUploadDriveComponent } from './Drive/file-upload-drive/file-upload-drive.component';
import {AllMailsComponent} from './mailSendingApp/all-mails/all-mails.component';
import {CampaintransComponent} from './mailSendingApp/campaintrans/campaintrans.component';
import {GroupMasterComponent} from './mailSendingApp/group-master/group-master.component';
import {MailgroupComponent} from './mailSendingApp/mailgroup/mailgroup.component';
import {SendMailComponent} from './mailSendingApp/send-mail/send-mail.component';
import {MailreportComponent} from './mailSendingApp/mailreport/mailreport.component';
import { ManytomanywaunComponent } from './Unofficial/manytomanywaun/manytomanywaun.component';
import { ApikeywaunComponent } from './Unofficial/apikeywaun/apikeywaun.component';
import { RoleAndPrivComponent } from './account-master/role-and-priv/role-and-priv.component';
import { CountrywiserateComponent } from './countrywiserate/countrywiserate.component';
import { CountrymasterComponent } from './countrymaster/countrymaster.component';
import { ResellerAccountComponent } from './reseller-account/reseller-account.component';
import { IncomingMessagesComponent } from './Unofficial/incoming-messages/incoming-messages.component';
import {LongcodenrctComponent} from './RahulPackage/LongcodeNRCT/longcodenrct/longcodenrct.component';
import { WaContactGroupComponent } from './Unofficial/WaContactGroup/wa-contact-group/wa-contact-group.component';
import { GroupAddComponent } from './Unofficial/WaContactGroup/group-add/group-add.component';
import { WacontactgroupMasterComponent } from './Unofficial/WaContactGroup/GroupMaster/wacontactgroup-master/wacontactgroup-master.component';
import { AddClientComponent } from './Unofficial/WaContactGroup/AddClient/add-client/add-client.component';
import { ApplicationDeployementComponent } from './ApplicationDeployement/application-deployement/application-deployement.component';
import { AppDeploymentTabeComponent } from './ApplicationDeployement/app-deployment-tabe/app-deployment-tabe.component';
import { UploadFileComponent } from './ApplicationDeployement/upload-file/upload-file.component';
import { ScheduleMailComponent } from './mailSendingApp/schedule-mail/schedule-mail.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatNativeDateModule} from '@angular/material/core';
import { DatePickerComponentComponent } from './mailSendingApp/date-picker-component/date-picker-component.component';
import { ScheduleReportComponent } from './mailSendingApp/schedule-report/schedule-report.component';
import { ConversationwaunComponent } from './Unofficial/conversationwaun/conversationwaun.component';
import { ContactFormComponentWunComponent } from './Unofficial/conversationwaun/contact-form-component-wun/contact-form-component-wun.component';
import { MessageObjunComponent } from './Unofficial/conversationwaun/message-objun/message-objun.component';
import { MessageInfoWunComponent } from './Unofficial/conversationwaun/message-info-wun/message-info-wun.component';
import {SignutraComponent} from './MicroServices/GsmGateway/Signutra/signutra/signutra.component';
import { UploadDataComponent } from './MicroServices/GsmGateway/Signutra/upload-data/upload-data.component';
import { FilterationComponent } from './MicroServices/GsmGateway/Signutra/filteration/filteration.component';
import { ClientReportComponent } from './Unofficial/client-report/client-report.component';
import { FilterReportComponent } from './Unofficial/client-report/filter-report/filter-report.component';
import { OperationDaoComponent } from './Feature/operation-dao/operation-dao.component';
import { StopCampaignComponent } from './Unofficial/stop-campaign/stop-campaign.component';
import { ScheduleCampaignReportComponent } from './Unofficial/schedule-campaign-report/schedule-campaign-report.component';
import { ChatbotMasterComponent} from "./MicroServices/Chatbot/chatbot-master/chatbot-master.component";
import { ChatbotMasterFormComponent} from "./MicroServices/Chatbot/chatbot-master-form/chatbot-master-form.component";
import { ConversationFlowComponent} from "./MicroServices/Chatbot/ChatbotConversationFlow/conversation-flow/conversation-flow.component";
import { ConversationChatbotComponent} from "./MicroServices/Chatbot/conversation-chatbot/conversation-chatbot.component";
import { ResellerStatsComponent } from './account-master/reseller-stats/reseller-stats.component';
import { UrlConfigComponent } from './MicroServices/GsmGateway/ApiConfig/url-config/url-config.component';
import {MatSelectModule} from '@angular/material/select';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { FeatureAcessComponent } from './feature-acess/feature-acess.component';
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { NbccComponent } from './MicroServices/NBCC/nbcc/nbcc.component';
import { AddResidentialComponent } from './MicroServices/NBCC/add-residential/add-residential.component';
import {MatMenuModule} from "@angular/material/menu";
import {LoginOTPComponent} from "./login-otp/login-otp.component";
import { SlabMasterComponent } from './MicroServices/NBCC/slab-master/slab-master.component';
import { CampaignScheduleReportComponent } from './campaign-schedule-report/campaign-schedule-report.component';
import { ActiveSessionReportComponent } from './active-session-report/active-session-report.component';
import { PaymentComponent } from './MicroServices/NBCC/payment/payment.component';
import { TransationComponent } from './MicroServices/NBCC/transation/transation.component';
import { PaymentStatucComponent } from './MicroServices/NBCC/payment-statuc/payment-statuc.component';
import { BungalowHistoryComponent } from './MicroServices/NBCC/bungalow-history/bungalow-history.component';
import { CreditDebitReportComponent } from './credit-debit-report/credit-debit-report.component';
import {DragAndDropChatbotComponent} from './draganddropchatbot/drag-and-drop-chatbot/drag-and-drop-chatbot.component';
import {AgentMasterComponent} from './AgentSupport/AgentMaster/agent-master/agent-master.component';
import {ClientMasterComponent} from './AgentSupport/ClientMaster/client-master/client-master.component';
import {ClientMasterFormComponent} from './AgentSupport/ClientMaster/client-master-form/client-master-form.component';

import {AgentMasterFormComponent} from './AgentSupport/AgentMaster/agent-master-form/agent-master-form.component';
import {AgentClientConversationComponent} from './AgentSupport/agent-client-conversation/agent-client-conversation.component';
import {AgentClientConversationObjComponent} from './AgentSupport/agent-client-conversation/agent-client-conversation-obj/agent-client-conversation-obj.component';
import {InitiateChatbotComponent} from './initiate-chatbot/initiate-chatbot.component';
import {InteractionSessionComponent} from './AgentSupport/interaction-session/interaction-session.component';
import {InteractionSessionFormComponent} from './AgentSupport/interaction-session/interaction-session-form/interaction-session-form.component';
import {QuestionResponseFlowComponent} from './AgentSupport/interaction-session/question-response-flow/question-response-flow.component';

import {ExtensionWiseAgentComponent} from './Gogreen/extension-wise-agent/extension-wise-agent.component';
import {ExtensionWiseAgentFormComponent} from './Gogreen/extension-wise-agent-form/extension-wise-agent-form.component';
import {LanguageWiseAgentComponent} from './Gogreen/language-wise-agent/language-wise-agent.component';
import {LanguageWiseAgentFormComponent} from './Gogreen/language-wise-agent-form/language-wise-agent-form.component';

import {
  WhatsappCloudTemplateMasterComponent
} from "./whatsapp-cloud-template-master/whatsapp-cloud-template-master.component";
import { NdtComponent } from './MicroServices/Digimozo/ndt/ndt.component';
import {
  RegistrationOtpComponent
} from "./MicroServices/Digimozo/Registration-Otp/registration-otp/registration-otp.component";
import { RegistrationComponent } from './MicroServices/Digimozo/Registration-Otp/registration/registration.component';
import { RegistrationMasterComponent } from './MicroServices/Digimozo/Registration-Otp/registration-master/registration-master.component';
import { RegistrationOtpMasterComponent } from './MicroServices/Digimozo/Registration-Otp/registration-otp-master/registration-otp-master.component';
import { ConfirmMessageComponent } from './MicroServices/Digimozo/Registration-Otp/confirm-message/confirm-message.component';
import { ZnpdComponent } from './MicroServices/Digimozo/znpd/znpd.component';
import { RegistrationKKComponent } from './MicroServices/Digimozo/RegistratonKK/registration-kk/registration-kk.component';
import { RegistrationFormComponent } from './MicroServices/Digimozo/RegistratonKK/registration-form/registration-form.component';
import { RegistrationKKMasterComponent } from './MicroServices/Digimozo/RegistratonKK/registration-kkmaster/registration-kkmaster.component';
import { ConfirmationMessageComponent } from './MicroServices/Digimozo/RegistratonKK/confirmation-message/confirmation-message.component';
import { ClientSignUpComponent } from './client-sign-up/client-sign-up.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { IncommingReportComponent } from './MicroServices/GsmGateway/Reliance/incomming-report/incomming-report.component';
import { UploadLongCodeDataComponent } from './MicroServices/Digimozo/upload-long-code-data/upload-long-code-data.component';
import { VerifiedIncomingMessageReportComponent } from './verified-incoming-message-report/verified-incoming-message-report.component';
import { SubscriptionPackageComponent } from './subscription-package/subscription-package.component';
import {MatTabsModule} from "@angular/material/tabs";
import { SubscriptionPackageReportComponent } from './subscription-package-report/subscription-package-report.component';
import { SubscriptionDetailsShowComponent } from './subscription-package/subscription-details-show/subscription-details-show.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { MetaCredentialsComponent } from './meta-credentials/meta-credentials.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { SubscriptionTransactionReportComponent } from './subscription-transaction-report/subscription-transaction-report.component';
import { OnlineSignUpReportComponent } from './online-sign-up-report/online-sign-up-report.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { CatalogueMessageComponent } from './catalogue/catalogue-message/catalogue-message.component';
import { CampaignReportResellerComponent } from './campaign-report-reseller/campaign-report-reseller.component';
import { ScheduledReportResellerComponent } from './scheduled-report-reseller/scheduled-report-reseller.component';
import { CreditDebitReportResellerComponent } from './credit-debit-report-reseller/credit-debit-report-reseller.component';
import { AgentSupportNewComponent } from './agent-support-new/agent-support-new.component';
import { KarixTemplateMasterComponent } from './karix-template-master/karix-template-master.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { AccountOnKarixComponent } from './account-on-karix/account-on-karix.component';
import { OnetomanyNoAuthComponent } from './onetomany-no-auth/onetomany-no-auth.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';


@NgModule({
  declarations: [
    QuestionResponseFlowComponent,
    InteractionSessionFormComponent,
    InitiateChatbotComponent,
    InteractionSessionComponent,
    LanguageWiseAgentComponent,
    LanguageWiseAgentFormComponent,
    ExtensionWiseAgentFormComponent,
    ExtensionWiseAgentComponent,
    AgentClientConversationObjComponent,
    AgentClientConversationComponent,
    ClientMasterFormComponent,
    AgentMasterFormComponent,
    ClientMasterComponent,
    AgentMasterComponent,
    DragAndDropChatbotComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MaincontainerComponent,
    LoginComponent,
    CigaratePanelDashC4Component,
    UserMasterComponent,
    AccountMasterComponent,
    ChatComponent,
    ReportComponent,
    IndexComponent,
    FilterCommonComponent,
    ChartAndTablesComponent,
    ServiceSelectionComponent,
    CrdrreportComponent,
    AccountServicesComponent,
    RechargeComponent,
    AccountFilterComponent,
    TransactionreportComponent,
    ProfileComponent,
    ProfileEditComponent,
    ChangePasswordComponent,
    RechargeViewComponent,
    SubscriptionComponent,
    WsTemplateComponent,
    WaofficialDashComponent,
    DateFilterOnlyComponent,
    MessageTypeComponent,
    OnetomanyComponent,
    ManytomanyComponent,
    ApidocsComponent,
    WebhookComponent,
    ChatbotconfigComponent,
    CombinationComponent,
    ConversationComponent,
    TemplateMessagetypeComponent,
    CampaignReportComponent,
    GraphCommonComponent,
    MessageObjComponent,
    ContactFormComponent,
    MessageInfoComponent,
    QuickReplyMessageComponent,
    WaofficialdashboardComponent,
    DriveComponent,
    CigaratePanelDashComponent,
    DatefilterComponent,
    DashboardComponent,
    EmpmasterComponent,
    EmpreportComponent,
    ExcelReaderCommonComponent,
    ProductmasterComponent,
    UserMasterReportingComponent,
    RolemasterComponent,
    ServicemasterComponent,
    MenumasterComponent,
    ModuleMasterComponent,
    PermissionentryComponent,
    IncomingmessagecomponentComponent,
    IncommingSmsReportComponent,
    EmasolchatbotComponent,
    EmasolTransactionComponent,
    PriceChartComponent,
    OutletComponent,
    NouritureConversationComponent,
    FreighChartComponent,
    ApiKeyComponent,
    OBDComponent,
    OBDChartComponent,
    EnquiryDetailsComponent,
    C2cReportComponent,
    C2cPanelComponent,
    HdfcSupComponent,
    HdfcCallsComponent,
    HdfcReportComponent,
    HdfcNewjoineeComponent,
    ExcelReaderWithDatettimeComponent,
    HdfcResignedComponent,
    HdfcEmployeeComponent,
    RetailerCashbackComponent,
    ScratchCardReportComponent,
    LongCodeCampaignComponent,
    ShubhLabhcampaignComponent,
    LongcodenrComponent,
    SubscriptionWaUnComponent,
    InstanceMasterComponent,
    DomainMasterComponent,
    CommonActiveOrDisableComponent,
    ProxymasterComponent,
    QrWindowComponent,
    DashboardunwsComponent,
    CommoninstancedropdownComponent,
    OnetomanywsunComponent,
    TestcmpComponent,
    CampaignReportUnComponent,
    DlrReportComponent,
    LongcodeBpphpComponent,
    FileUploadDriveComponent,
    LongcodeBpphpComponent,
    AllMailsComponent,
    CampaintransComponent,
    GroupMasterComponent,
    MailgroupComponent,
    MailreportComponent,
    SendMailComponent,
    ManytomanywaunComponent,
    ApikeywaunComponent,
    RoleAndPrivComponent,
    CountrywiserateComponent,
    CountrymasterComponent,
    ResellerAccountComponent,
    IncomingMessagesComponent,
    PromptUploadComponent,
    LongcodenrctComponent,
    WaContactGroupComponent,
    GroupAddComponent,
    WacontactgroupMasterComponent,
     AddClientComponent,
     ApplicationDeployementComponent,
     AppDeploymentTabeComponent,
     UploadFileComponent,
     ScheduleMailComponent,
     DatePickerComponentComponent,
     ScheduleReportComponent,
     ConversationwaunComponent,
     ContactFormComponentWunComponent,
     MessageObjunComponent,
     MessageInfoWunComponent,
     SignutraComponent,
     UploadDataComponent,
     FilterationComponent,
     ClientReportComponent,
     FilterReportComponent,
     OperationDaoComponent,
     StopCampaignComponent,
     ScheduleCampaignReportComponent,
     ChatbotMasterFormComponent,
    ChatbotMasterComponent,
    ConversationFlowComponent,
    ConversationChatbotComponent,
    ResellerStatsComponent,
    UrlConfigComponent,
    FeatureAcessComponent,
    NbccComponent,
    AddResidentialComponent,
    LoginOTPComponent,
    SlabMasterComponent,
    CampaignScheduleReportComponent,
    ActiveSessionReportComponent,
    PaymentComponent,
    TransationComponent,
    PaymentStatucComponent,
    BungalowHistoryComponent,
    CreditDebitReportComponent,
    WhatsappCloudTemplateMasterComponent,
    NdtComponent,
    RegistrationOtpComponent,
    RegistrationComponent,
    RegistrationMasterComponent,
    RegistrationOtpMasterComponent,
    ConfirmMessageComponent,
    ZnpdComponent,
    RegistrationKKComponent,
    RegistrationFormComponent,
    RegistrationKKMasterComponent,
    ConfirmationMessageComponent,
    ClientSignUpComponent,
    OtpVerificationComponent,
    IncommingReportComponent,
    UploadLongCodeDataComponent,
    VerifiedIncomingMessageReportComponent,
    SubscriptionPackageComponent,
    SubscriptionPackageReportComponent,
    SubscriptionDetailsShowComponent,
    MetaCredentialsComponent,
    PaymentDetailsComponent,
    SubscriptionTransactionReportComponent,
    OnlineSignUpReportComponent,
    CatalogueMessageComponent,
    CampaignReportResellerComponent,
    ScheduledReportResellerComponent,
    CreditDebitReportResellerComponent,
    AgentSupportNewComponent,
    KarixTemplateMasterComponent,
    ClientDetailsComponent,
    AccountOnKarixComponent,
    OnetomanyNoAuthComponent,
    TermAndConditionComponent

  ],
    imports: [
        AgGridModule.withComponents(null),
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        ChartsModule,
        MatPaginatorModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatTableModule,

        MatRadioModule,
        DataTablesModule,
        NotifierModule,
        Ng2SearchPipeModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatFormFieldModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatStepperModule,
        MatNativeDateModule,
        MatTableModule,
        MatRadioModule,
        MatSelectModule,
        ScrollingModule,
        MatIconModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSnackBarModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule

    ],
  providers: [{

    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }, AuthGuard, AuthguardDashboard],
  bootstrap: [AppComponent]
})
export class AppModule { }