
<!--<script type="text/javascript" src="/assets/dist/js/sha.js"></script>-->
<script src="assets/dist/js/sha.js" type="text/javascript"></script>


<div  class="row" style="margin-left: 20% ;margin-top: 5%; margin-right: 20%">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
<!--              <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal" ><i class="fa fa-window-close"></i></button>-->
       <div class="panel-body">
        <h3 style="text-align: center;text-decoration: underline;text-decoration-color: darkred"> <b>Bill Payment</b></h3> <br>

        <!--        <form    class= "form-group col-lg-12"      #myForm  name='myForm'   action='https://qa.phicommerce.com/pg/api/sale?v=4' method='post' enctype="application/x-www-form-urlencoded">-->

         <form id='myForm' name='myForm'  #myForm action='https://secure-ptg.payphi.com/pg/api/sale?v=2' method='post' enctype="application/x-www-form-urlencoded"   >

          <div class="form-group col-lg-6" hidden>
            <label   class="control-label" >merchantID<sup class="required">*</sup></label>
            <input type="text" name="merchantID" [value]="merchantID" class="form-control" />
          </div>

          <div class="form-group col-lg-6" hidden>
            <label   class="control-label"  >merchantTxnID<sup class="required">*</sup></label>
            <input type="text" name="merchantTxnNo"  [value]="merchantTxnID" class="form-control"  />
          </div>


          <div class="form-group col-lg-6" hidden>
            <label   class="control-label"  >currencyCode<sup class="required">*</sup></label>
            <input type="text" name="currencyCode" value="356" class="form-control"  />
          </div>


          <div     class="form-group col-lg-6" hidden>
            <label   class="control-label" >Pay Type: </label>
            <input type="text" name="payType" value=""  class="form-control" />
           </div>

          <div class="form-group col-lg-6"  hidden>
            <label   class="control-label"  >paymentMode: </label>
            <input type="text" name="paymentMode" value=""  class="form-control" />           </div>


            <div class="form-group col-lg-6"  hidden>
              <label   class="control-label"  >customerEmailID:</label>
              <input type="text" name="customerEmailID" value=""  class="form-control"   />
            </div>

            <div   class="form-group col-lg-6"  hidden>
              <label   class="control-label"  >transactionType:</label>
              <input type="text" name="transactionType"  [value]="transactionType"  class="form-control"    />
             </div>

            <div class="form-group col-lg-6"  hidden>
              <label   class="control-label"  >paymentOptionCodes </label>
              <input type="text" name="paymentOptionCodes" value=""  class="form-control" />            </div>



            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"  >cardNo:</label>
              <input type="text" name="cardNo" value=""   class="form-control"  />            </div>

            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"   >cardExp:</label>
              <input type="text" name="cardExpiry" value="" class="form-control"  />
             </div>

            <div class="form-group col-lg-6"  hidden>
              <label   class="control-label"  >nameOnCard:</label>
              <input type="text" name="nameOnCard" value=""  class="form-control" />
            </div>

            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"  >cvv:</label>
              <input type="text" name="cvv" value="" class="form-control"  />            </div>

            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"  >customerUPIAlias:</label>
              <input type="text" name="customerUPIAlias" value=""  class="form-control" />            </div>

            <div class="form-group col-lg-6" hidden>
              <label   class="control-label" >aggregatorID:</label>
              <input type="text" name="aggregatorID" value=""  class="form-control"  [value]='aggregatorID' />            </div>

            <div class="form-group col-lg-6" hidden>
              <label   class="control-label"  >remarks:</label>
              <input type="text" name="remarks" value="" class="form-control"  />            </div>

            <div class="form-group col-lg-6"  >
              <label   class="control-label"  >Name:</label>
              <input type="text" name="customerName"  [value]="customerName"  class="form-control"    readonly  />
            </div>

            <div class="form-group col-lg-6"  >
              <label   class="control-label"  > Mobile No:</label>
              <input type="text" name="customerMobileNo"  [value]="customerMobileNo" class="form-control"  readonly  />
            </div>


            <div class="form-group col-lg-6">
              <label   class="control-label"  >Amount<sup class="required">*</sup></label>
              <input type="text" name="amount"  [value]="amount"  class="form-control" readonly />          </div>

            <div class="form-group col-lg-6"  >
              <label   class="control-label"  >Date: </label>
              <input type="text" name="txnDate" [value]="txnDate.toString().substring(0,4)+':'+ txnDate.toString().substring(4,6)+':'+txnDate.toString().substring(6,8)+':'+txnDate.toString().substring(9,11)+':'+txnDate.toString().substring(11,13) "   class="form-control"  readonly />
            </div>

            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"    >invoiceNo:</label>
              <input type="text" name="invoiceNo" value="" class="form-control"  />            </div>

            <div class="form-group col-lg-6"  hidden >
              <label   class="control-label"   >returnURL:</label>
              <input type="text" name="returnURL" value="https://wa.chatmybot.in/gateway/nbcc/v1/api/v1/webhook"  class="form-control" />
            </div>

            <div class="form-group col-lg-6" hidden>
              <label   class="control-label"  >addlParam1:</label>
              <input type="text" name="addlParam1" value=""  class="form-control" />
            </div>

            <div class="form-group col-lg-6" hidden>
              <label   class="control-label"  >allowDisablePaymentMode: </label>
              <input type="text" name="allowDisablePaymentMode" value=""  class="form-control" />            </div>

            <div class="form-group col-lg-6 "  hidden>
              <label   class="control-label"  >addlParam2:</label>
              <input type="text" name="addlParam2" value="" class="form-control"  />
            </div>

            <div class="form-group col-lg-11"  >
              <label   class="control-label"  >secureHash:	<sup class="required">*</sup></label>
              <input type="text" name="secureHash" value=""  class="form-control" readonly /><i      title="Click Here To Generate Hash" class="btn btn-success btn-sm pull-right" (click)="getHMACResult()" > Verify  Detail</i>
            </div>

            <tr hidden>
              <td>hashtext : </td>
              <td><p id="hashtext"></p></td>
            </tr>

            <td hidden> Generate Hash : </td>
            <div  class="form-group col-lg-6">
<!--              <button  type=button   title="Click Here To Generate Hash" class="btn btn-add btn-sm" (click)="getHMACResult()" > Click Here </button>-->
            </div>

          <div class="col-sm-6" style="margin-left:  45%">
            <!--            <button type="submit" class="btn btn-add btn-sm">{{submitBtnTxt}}</button>-->
            <button *ngIf="visible" type="submit" class="btn btn-success" (click)="myForm.submit()">{{submitBtnTxt}}</button>

<!--            <button type="button" (click)="onClose()" mat-dialog-close class="btn btn-danger  ">Back</button>-->
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
