import { Injectable } from '@angular/core';
import {CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../User/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthguardDashboard implements CanActivate {
  constructor(private router: Router,private userMasterServiceService:UserService,) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.userMasterServiceService.isLogin)
    {
      return true;

    }else {
      return false;
    }


  }

}
