import {Component, OnInit} from '@angular/core';
import {UserService} from './Services/User/user.service';
import {Router} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit{
//initiate chatbot add by monika
  directPages:Array<string>=["/payment","/signup","/otpverification","/initiatechatbot", "/onetomanynoauth", "/termCondition"]
  isDirectPage:boolean=false;
  pageName:string="";

  checkDirectPage(page) :boolean
  {

    //alert(this.router.url)
    return this.directPages.includes(this.pageName)

  }
  constructor(public userService: UserService,private router: Router) {
     console.log("TwoStepAuthEnabled : ",userService.hasTwoStepAuthentication())
    console.log("OTPVerified : ",userService.hasOTPVerified())

    this.router.events.subscribe(event=>{
       this.pageName=this.router.url.toString().split("?")[0];
        this.isDirectPage=this.checkDirectPage(this.pageName);
     })


  }
  title = 'WhatsappApiUI';
  payment: any='payment';

  ngOnInit(): void {

  }
 }
