<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" class="btn btn-add">
            Add New Catalogue<i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="panel-body" *ngIf="ADDNEWCATALOG">
        <form>
          <div class="form-group col-lg-12">
            <label>Status <sup class=" ">*</sup></label>
            <mat-slide-toggle name="status" [(ngModel)]="this.catalogueMessageDao.active"></mat-slide-toggle>
          </div>

          <div class="form-group col-lg-6">
            <label>Catalog Name<sup class=" ">*</sup></label>
            <input class="form-control" type="text" name="catalogName"
                   [(ngModel)]="catalogueMessageDao.catalogName">
          </div>

          <div class="form-group col-lg-6">
            <label>Catalog Id<sup class=" ">*</sup></label>
            <input class="form-control" type="text" name="catalogId"
                   [(ngModel)]="catalogueMessageDao.interactive.action.catalog_id">
          </div>

          <div class="form-group col-lg-6">
            <label>Header Name<sup class=" ">*</sup></label>
            <input class="form-control" type="text" name="headerName"
                   [(ngModel)]="catalogueMessageDao.interactive.header.text">
          </div>
          <div class="form-group col-lg-6">
            <label>Footer<sup class=" ">*</sup></label>
            <input class="form-control" type="text" name="footerText"
                   [(ngModel)]="catalogueMessageDao.interactive.footer.text">
          </div>


          <div class="form-group col-lg-12">
            <label>Body Text<sup class=" ">*</sup></label>
            <textarea style="height: 200px" class="form-control" type="text" name="bodyText"
                      [(ngModel)]="catalogueMessageDao.interactive.body.text"></textarea>
          </div>

          <!--                    <div class="form-group col-lg-12">-->
          <!--                      <button type="submit" class="btn btn-add">Save</button>-->
          <!--                      <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>-->
          <!--                    </div>-->


          <div class="form-group col-lg-6">
            <label>Add Section</label>
            <input [(ngModel)]="titleStr" name="sectionStr" type="text" class="form-control"
                   placeholder="Enter Title"/>
          </div>

          <div class="col-lg-6">
            <br>
            <button (click)="addSections()" class="btn btn-danger">Add Section</button>
          </div>


          <ng-container *ngFor="let sections of catalogueMessageDao.interactive.action.sections;let ind =index">
            <div class="card">
              <h4 style="text-align: center" [innerHTML]="sections.title"></h4>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                  <tr class="info">
                    <th>Product Name</th>
                    <th>Product Retailer Id</th>
                    <th><i (click)="addList(ind)" class="fa fa-plus-circle btn btn-danger"></i></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let rowsSection of sections.product_items;let indRow =index">
                    <tr>
                      <td>
                        <input [(ngModel)]="rowsSection.variantsTitle" name="list_title{{indRow}}" type="text"
                               class="form-control"
                               placeholder="Enter List Title" required/>
                      </td>

                      <td>
                        <input [(ngModel)]="rowsSection.product_retailer_id" name="list_title{{indRow}}" type="text"
                               class="form-control"
                               placeholder="Enter List Title" required/>
                      </td>
                      <td>
                        <i (click)="deleteSection(ind,indRow)" class="fa fa-trash btn btn-danger"></i>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>

              </div>
            </div>
          </ng-container>

          <br>

          <div class="form-group col-lg-12">
            <button (click)="isOrUpdate()" class="btn btn-add">Save</button>
            <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
          </div>

        </form>
      </div>


      <div style="" class="panel-body" *ngIf="!ADDNEWCATALOG">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Id</th>

              <th>Catalogue Name</th>
              <th>Catalogue Id</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of tableData.data; let ind =index">
              <tr>
                <td [innerHTML]="data.id"></td>

                <td [innerHTML]="data.catalogName"></td>
                <td [innerHTML]="data.interactive.action.catalog_id"></td>
                <td [innerHTML]="data.active?'Active':'InActive'"></td>
                <td><i (click)="edit(data)" title="Click Here To Edit" class="btn btn-add fa fa-edit"></i>
                  <i (click)="deleteById(data.id)" title="Click Here To Delete"
                     class="btn btn-add fa fa-trash"></i>
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
        </div>
      </div>


    </div>
  </div>
</div>



