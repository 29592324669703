<ul class="dropdown-menu">
  <li>
    <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 200px;"><ul class="menu" style="overflow: hidden; width: 100%; height: 200px;">
      <li>
        <!-- start message -->
        <a href="#" class="border-gray">
          <div class="pull-left">
            <img src="assets/dist/img/avatar.png" class="img-circle" alt="User Image">
          </div>
          <h4>Ronaldo</h4>
          <p>Please oreder 10 pices of kits..</p>
          <span class="badge badge-success badge-massege"><small>15 hours ago</small>
                                       </span>
        </a>
      </li>
      <li>
        <a href="#" class="border-gray">
          <div class="pull-left">
            <img src="assets/dist/img/avatar2.png" class="img-circle" alt="User Image">
          </div>
          <h4>Leo messi</h4>
          <p>Please oreder 10 pices of Sheos..</p>
          <span class="badge badge-info badge-massege"><small>6 days ago</small>
                                       </span>
        </a>
      </li>
      <li>
        <a href="#" class="border-gray">
          <div class="pull-left">
            <img src="assets/dist/img/avatar3.png" class="img-circle" alt="User Image">
          </div>
          <h4>Modric</h4>
          <p>Please oreder 6 pices of bats..</p>
          <span class="badge badge-info badge-massege"><small>1 hour ago</small>
                                       </span>
        </a>
      </li>
      <li>
        <a href="#" class="border-gray">
          <div class="pull-left">
            <img src="assets/dist/img/avatar4.png" class="img-circle" alt="User Image">
          </div>
          <h4>Salman</h4>
          <p>Hello i want 4 uefa footballs</p>
          <span class="badge badge-danger badge-massege">
                                       <small>6 days ago</small>
                                       </span>
        </a>
      </li>
      <li>
        <a href="#" class="border-gray">
          <div class="pull-left">
            <img src="assets/dist/img/avatar5.png" class="img-circle" alt="User Image">
          </div>
          <h4>Sergio Ramos</h4>
          <p>Hello i want 9 uefa footballs</p>
          <span class="badge badge-info badge-massege"><small>5 hours ago</small>
                                       </span>
        </a>
      </li>
    </ul><div class="slimScrollBar" style="background: rgb(0, 0, 0); width: 3px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px;"></div><div class="slimScrollRail" style="width: 3px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div></div>
  </li>
</ul>
