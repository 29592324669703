import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ConversationDao} from "../ChatbotConversationFlow/conversation-flow/ConversationDao";
import {ChatbotMasterDao} from "../chatbot-master/ChatbotMasterDao";
import {DynamicChatbotService} from "../Service/dynamic-chatbot.service"
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {UserService} from "../../../Services/User/user.service";
import {ConversationFlowComponent} from "../ChatbotConversationFlow/conversation-flow/conversation-flow.component";
import {FormControl, NgForm, Validators} from "@angular/forms";

@Component({
  selector: 'app-chatbot-master-form',
  templateUrl: './chatbot-master-form.component.html',
  styleUrls: ['./chatbot-master-form.component.css']
})
export class ChatbotMasterFormComponent implements OnInit {
  @Output() public  eventEmitter =new EventEmitter<string>();

  chatbotNameValidation:any;
  chatbotMasterDao:ChatbotMasterDao;
  submitBtnTxt:String='Create';
  constructor(public user:UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,
              private dynamicChatbotService:DynamicChatbotService,
              @Inject(MAT_DIALOG_DATA) public data: ChatbotMasterDao) {
    // @ts-ignore
    this.chatbotMasterDao=data.chatbotMasterDao;
    // @ts-ignore
    this.submitBtnTxt=data.submitBtnTxt;
  }

  ngOnInit(): void {
  }


  submitMasterForm(chatbotMasterDao:ChatbotMasterDao) {
    console.log("chatbotMasterDao:",chatbotMasterDao)

    if (chatbotMasterDao.name == ''){
      this.alert.showErrorMessage('');
      return;

    }

    if(this.submitBtnTxt=="Create"){
      this.dynamicChatbotService.insertChatbotMasterDao(chatbotMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
      });
    }
    else{
      this.dynamicChatbotService.updateChatbotMasterDao(chatbotMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }

    //

  }





  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(ChatbotMasterFormComponent,{width: '830px',
      height: '600px'


    });
  }

  // typeStatusChange(value: any) {
  //   console.log("chkbox ",value)
  //   if (value == ){
  //     this.keyTypeFlow=true;
  //     this.keyTypeFunctional=false;
  //     this.keyRequired=true;
  //     this.keyValueRequired=false;
  //   }else{
  //     this.keyTypeFlow=false;
  //     this.keyTypeFunctional=true;
  //     this.keyRequired=false;
  //     this.keyValueRequired=true;
  //   }
  // }

}
