import {Component, Injectable, OnInit} from '@angular/core';
import {UserService} from "../../../Services/User/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {ChatbotMasterDao} from "./ChatbotMasterDao";
import {HttpClient} from "@angular/common/http";
import {
  CallButton,
  ContactMessageDao,
  ConversationDao,
  DateTiming,
  FileMessageDao,
  ListMessageButton,
  ListMessageRow,
  ListMessageSection,
  LocationMessageDao,
  MessageDao,
  MessageType,
  QuickReplyButton,
  TemplateButtonMessage,
  TemplateButtons,
  TextMessageDao,
  UrlButton
} from "../ChatbotConversationFlow/conversation-flow/ConversationDao";
import {ConversationFlowComponent} from "../ChatbotConversationFlow/conversation-flow/conversation-flow.component";
import {DynamicChatbotService} from "../Service/dynamic-chatbot.service";
import {any} from "codelyzer/util/function";
import {Image, Video} from "../../../Unofficial/DaoMessageUn";
import {ChatbotMasterFormComponent} from "../chatbot-master-form/chatbot-master-form.component";

@Component({
  selector: 'app-chatbot-master',


  templateUrl: './chatbot-master.component.html',
  styleUrls: ['./chatbot-master.component.css']
})

export class ChatbotMasterComponent implements OnInit {

  fileUrl = '';
  caption = '';
  fileName = '';
  footer = '';
  displayText = '';
  url = '';
  displayText1 = '';
  phoneNumber = '';
  quickReply = '';
  quickReply2 = '';
  quickReply3 = '';
  imageCaption = '';
  imageUrl = '';
  videoUrl = '';

  // tslint:disable-next-line:max-line-length
  image: Image = {url: ''};
  video: Video = { url: ''};
  valuemap: any;
  dynamicsms: Array<any> = [];
  tableData: Array<ChatbotMasterDao> = [];
  coversationtableData: Array<ConversationDao> = [];

  offset: number = 0;
  limit: number = 10;
  showTabeleDiv: boolean=true;
  coversationDaoList:Array<ConversationDao>=[];
  test:string="test value";
  flowRowCount=0;
  isMasterPage: boolean=true;
  isFlowPage: boolean=false;
  tableTite: String='Chatbots';
  chatbostMasterId:string;
  constructor(
    public user:UserService,
    private http: HttpClient,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alert: AlertMessageService,
    private dynamicChatbotService:DynamicChatbotService) { }

  ngOnInit(): void {
    this.loadMasterData();
  }

  loadMasterData() {

    console.log("load Master1")

    this.dynamicChatbotService.getAllMaster(this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      console.log("load Master2",value)
      });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadMasterData();

  }

  switchView(chatbotId:string){
    this.isMasterPage=false;
    this.isFlowPage=true;
    this.tableTite='Conversation Flow'
    this.chatbostMasterId=chatbotId;
    this.loadFlowData()
    // this.dynamicChatbotService.getAllConversationByChatbotId(chatbotId,this.offset,this.limit).subscribe(value => {
    //   this.coversationtableData = value;
    //   console.log("chatbotId",value)
    // });
    //  this.alert.hideLoader();

    this.showTabeleDiv=false;
    //alert(`loading flow view for chatbot id ${chatbotId}`)
  }

  openFlowForm(conversationDao: ConversationDao) {
  this.chatbostMasterId=conversationDao.chatbotId;
  let valid = Array.isArray(conversationDao.validInputs);
  if (conversationDao.validInputs != undefined && valid==true){
      conversationDao.validInputs=conversationDao.validInputs.join(",");
  }
    console.log("Edeting flow for chatbotID : ",conversationDao)

    let dialogRef = this.dialog.open(ConversationFlowComponent,{width: '830px',
      height: '500px',
      data: {conversationDao,submitBtnTxt:'Update'},
  });

  dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
    console.log("subscribe flow");

    this.loadFlowData();
  })
    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe after flow closed");

      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })
  }

  // deleteConversation(conversationId){
  //   this.alert.confirmMessage("delete this conversation flow??",'Are you sure?').then(response=>{
  //       if (response=='success'){
  //         this.dynamicChatbotService.deleteConversationDao(conversationId).subscribe(value => {
  //           console.log("chatbotId",value)
  //           this.alert.showSuccesMessage("deleted")
  //           this.loadData();
  //         });
  //       }
  //       else{
  //       }
  //     }
  //   );
  //
  // }


  openMasterEditForm(chatbotMasterDao: ChatbotMasterDao) {
    console.log("Editing flow for chatbotID : ",chatbotMasterDao)
    let dialogRef =     this.dialog.open(ChatbotMasterFormComponent,{width: '830px',
        maxHeight: '80vh',
    data: {chatbotMasterDao,submitBtnTxt:'Update'},

    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log("subscribe master");

      this.loadMasterData();

    })
    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe master closed");

      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })



  }

  openMasterCreateForm() {
    let newChatbotMasterDao:ChatbotMasterDao={createDate: "", createDateFilter: 0, id: null, name: "", status: 0}
    let dialogRef =  this.dialog.open(ChatbotMasterFormComponent,{width: '830px',
      maxHeight: '80vh',
      data: {chatbotMasterDao:newChatbotMasterDao,submitBtnTxt:'Create'}

    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log("subscribe Master dao; ",event)
      this.loadMasterData();

    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }


  backMasterFlow() {
    this.isMasterPage=true;
    this.isFlowPage=false;
    this.tableTite='Chatbot Master'
    this.dynamicChatbotService.getAllMaster(this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      console.log("chatbot master",value)
    });
    this.alert.hideLoader();

    this.showTabeleDiv=true;
  }

  addNewFlow() {

    let  textMessage:TextMessageDao= {
      content: ""
    }
      let listMessageRow:ListMessageRow={
      title: "",
      description: "",
      rowId: ""
    }




    let fileMessageDao:FileMessageDao={
      fileUrl: "",
      caption: "",
      fileName: ""
    }
    let locationMessageDao: LocationMessageDao={
      longitude: 0,
      latitude: 0
    }
    let contactMessageDao:ContactMessageDao={
      name: "",
      vcard: ""
    }


    let dateTiming: DateTiming=
    {
      sentTime: "",
      dlrTime: "",
      viewTime: ""
    }









    let messageDao:MessageDao={
      buttonMessage: undefined,
      chatSide: undefined,
      contactMessage: contactMessageDao,
      incId: 0,
      listMessage: undefined,
      locationMessage: locationMessageDao,
      promotional: false,
      templateButtonMessage: undefined,
      messageStatus: undefined,
      messageType: undefined,
      id: "",
      dst: "",
      textMessage: textMessage,
      fileMessage: fileMessageDao

    }
    let conversationDao: ConversationDao = {
      actionType: MessageType.TEXT,
      changeStage: 0,
      changeStageOnInvalid: 0,
      chatbotId: this.chatbostMasterId,
      deleteStage: 0,
      id: undefined,
      key: "",
      changeStageType:0,
      keyValue: "",
      messageDao: messageDao,
      nextStage: "",
      stageAction: 0,
      validInputs: []
    }

    let isFirstFlow=(this.coversationtableData != undefined && this.coversationtableData.length>0)?false:true;
    let dialogRef = this.dialog.open(ConversationFlowComponent,{width: '830px',
      height: '500px',
      data: {conversationDao,submitBtnTxt:'Create',isFirstFlow},

    });
    console.log("(this.coversationtableData",this.coversationtableData.length);

    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log("subscribe conversation flow");
      this.loadFlowData();

    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe conversation after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })



  }


  deleteMaster(chatbotId) {
    this.alert.confirmMessage("delete this conversation flow??",'Are you sure?').then(response=>{
      if (response=='success'){
        this.dynamicChatbotService.deleteChatbotMasterDao(chatbotId).subscribe(value => {
          console.log("chatbotId",value)
          this.alert.showSuccesMessage("deleted")
          this.loadMasterData();
        });
      }
      else{
      }
      }
    );
  }

  deleteConversation(conversationDao:ConversationDao){
    this.alert.confirmMessage("delete this conversation flow??",'Are you sure?').then(response=>{
        if (response=='success'){
          this.dynamicChatbotService.deleteConversationDao(conversationDao.id).subscribe(value => {
            console.log("Id",value)
            this.alert.showSuccesMessage("deleted")
            this.loadFlowData()
            // this.dynamicChatbotService.getAllConversationByChatbotId(conversationDao.chatbotId,this.offset,this.limit).subscribe(value => {
            //   this.coversationtableData = value;
            //   console.log("chatbotId",value)
            // });
          });
        }
        else{
        }
      }
    );

  }

  loadFlowData(){
    console.log("load Flow 1")

    this.dynamicChatbotService.getAllConversationByChatbotId(this.chatbostMasterId,this.offset,this.limit).subscribe(value => {
      this.coversationtableData = value;
      console.log(" load flow chatbotId",value)
    });
  }

}
