
<form style="width: 350px" ngNativeValidate (submit)="sendMessage()">

  <h1 mat-dialog-title style="text-align: center">Reply <i class="fa fa-reply"></i> </h1>

  <mat-dialog-content class="mat-typography">

    <div  class="form-group col-lg-12">
      <label >Message Type <sup class="required">*</sup></label>
      <div class="col-sm-12">
        <mat-radio-group [(ngModel)]="messageDao.type" name="messagetype"  aria-label="Select an option">

          <mat-radio-button value="image" >Image</mat-radio-button>
          <mat-radio-button value="audio" >Audio</mat-radio-button>
          <mat-radio-button value="video" >Video</mat-radio-button>
          <mat-radio-button value="document" >Document</mat-radio-button>
<!--          <mat-radio-button value="template" >Template</mat-radio-button>
          <mat-radio-button value="contacts" >Contact</mat-radio-button>
          <mat-radio-button value="location" >Location</mat-radio-button>-->
        </mat-radio-group>
      </div>


      <div class="col-sm-12" *ngIf="messageDao.type=='image'">
        <label >File <sup class="required">*</sup></label>
        <input required name="fileimage" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" type="file" (change)="uploadFile($event)"  />

        <label >Caption <sup class="required">*</sup></label>
        <input  type="text" class="form-control" name="captionimage" [(ngModel)]="messageDao.image.caption">

      </div>

      <div class="col-sm-12" *ngIf="messageDao.type=='video'">
        <label >File <sup class="required">*</sup></label>
        <input required name="filevideo" accept="video/*" type="file" (change)="uploadFile($event)"  />

        <label >Caption <sup class="required">*</sup></label>
        <input  type="text" class="form-control" name="captionvideo" [(ngModel)]="messageDao.video.caption">

      </div>

      <div class="col-sm-12" *ngIf="messageDao.type=='document'">
        <label >File <sup class="required">*</sup></label>
        <input required name="filedocument" type="file" (change)="uploadFile($event)"  />


        <label >Filename <sup class="required">*</sup></label>
        <input required  type="text" class="form-control" name="filenamedocument" [(ngModel)]="messageDao.document.filename">

      </div>




      <div class="col-sm-12" *ngIf="messageDao.type=='audio'">
        <label >File <sup class="required">*</sup></label>
        <input required name="fileaudio" accept="audio/*" type="file" (change)="uploadFile($event)"  />


      </div>





    </div>









  </mat-dialog-content>


  <mat-dialog-actions>
    <div class="reset-button">
      <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>
      <button   type="submit"  class="btn btn-add pull-right">Send <i class="fa fa-reply"></i> </button>

    </div>
  </mat-dialog-actions>
</form>
