import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InstanceMasterService} from '../instance-master/InstanceService/instance-master.service';
import {CommonInstanceDropDao} from '../commoninstancedropdown/CommonInstanceDropDao';
import {InstanceMasterDao} from '../instance-master/InstanceMasterDAo';
import {InstanceDashBoardReport, InstanceGroupDao} from './InstanceGroupDao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {CommoninstancedropdownComponent} from '../commoninstancedropdown/commoninstancedropdown.component';

@Component({
  selector: 'app-dashboardunws',
  templateUrl: './dashboardunws.component.html',
  styleUrls: ['./dashboardunws.component.css']
})
export class DashboardunwsComponent implements OnInit {
  commonInc: CommonInstanceDropDao = {enddate: '', incId: 0, startdate: '',reportBy:0, status:false};
  incList: Array<InstanceDashBoardReport> = [];
  transreport: Array<InstanceGroupDao> = [];
  incListArr: Array<InstanceMasterDao> = [];
  startdate = '0';
  enddate = '0';
  id = 0;

  groupQuery(id: number, incArr: Array<InstanceGroupDao>): InstanceGroupDao {
    const ret: InstanceGroupDao = {id, date: '', instanceName: '', remaining: 0, sent: 0, percentage: 0};
    for (let i = 0; i < incArr.length; i++) {

      if (id == incArr[i].id) {
        ret.remaining = ret.remaining + incArr[i].remaining;
        ret.sent = ret.sent + incArr[i].sent;
        ret.percentage = this.calculatePercentage(ret.sent, ret.sent + ret.remaining);

      }
    }

    return ret;
  }

  loadQr(id: number) {
    this.instanceService.loadQr(id);
  }

  logout(id: number) {
    this.alert.confirmMessage('You want Logout?', 'Are you sure?').then(value => {
      this.alert.showLoader();
      this.instanceService.logout(id).subscribe(value => {
        this.alert.showSuccesMessage('Logout Successfull');
        this.loadData();
        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage('Failed To Logout!!');
      });
    });

  }

  reboot(id: number) {
    this.alert.confirmMessage('You want Reboot?', 'Are you sure?').then(value => {
      this.alert.showLoader();
      this.instanceService.reboot(id).subscribe(value => {
        this.alert.showSuccesMessage('Reboot Successfull');
        this.loadData();
        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage('Reboot To Logout!!');
      });
    });
  }

  calculatePercentage(partialValue: number, totalValue: number) {

    return (100 * partialValue) / totalValue;

  }


  loadData() {
    this.alert.showLoader();
    this.incList = [];
    this.instanceService.getActive().subscribe(value => {

      this.incListArr = value;

      console.log('instance value:: {}',  this.incListArr);
      this.instanceService.getReport(this.id, this.startdate, this.enddate).subscribe(tempReport => {
        this.transreport = tempReport;
        for (let i = 0; i < value.length; i++) {

          const incTempVar: InstanceDashBoardReport = {inc: value[i], incGroup: this.groupQuery(value[i].id, tempReport)};
          this.incList.push(incTempVar);
        }
        this.alert.hideLoader();
      });
    });
  }

  constructor(private instanceService: InstanceMasterService, private alert: AlertMessageService, private dialog: MatDialog) {

    this.loadData();
  }

  ngOnInit(): void {
  }

  filter() {
    this.dialog.open(CommoninstancedropdownComponent, {data: this.incListArr}).afterClosed().subscribe(value => {
    if (value != null)
    {
      console.log('Value is ', value);
      this.startdate = value.startdate;
      this.enddate = value.enddate;
      this.id = value.incId;
      this.loadData();
    }
    });
  }

  clearFilter() {
    this.startdate = '0';
    this.enddate = '0';
    this.id = 0;
    this.loadData();
  }

  loadQrLegacy(id: number) {
    this.instanceService.loadQrLegacy(id);
  }
}
