<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <button (click)="filter()" class="btn btn-add">Filter <i class="fa fa-filter"></i></button>
          <button  (click)="clearFilter()"  class="btn btn-add">Clear Filter <i class="fa fa-times-circle"></i></button>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="row">
  <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <b>Instance</b>
        </div>
      </div>
      <div style="" class="panel-body">
        <ng-container *ngFor="let inc of incList">

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div id="cardbox1">
              <div class="statistic-box">
                <i class="fa-brands fa-whatsapp fa-3x"></i>
                <div class="counter-number pull-right">
                </div>
                <h3 [innerHTML]="inc.inc.instanceName"></h3>

                <br>
                <h4> Total : {{inc.incGroup.sent+inc.incGroup.remaining}}
                  <br>
                  Sent : {{inc.incGroup.sent}}
                  <br>
                  Remaining : {{inc.incGroup.remaining}}
                  <br>
                  Number : {{inc.inc.phoneNumber}}
                  <br>
                  Name : {{inc.inc.name}}
                  <br>
                </h4>
                <br>
<!--                <h4><i title="Blocked" *ngIf="inc.inc.netConnected"  style="color: #d22c38">Blocked!</i>-->
<!--                  <i title="Not Blocked"  *ngIf="!inc.inc.netConnected"></i>-->
<!--                </h4>-->
                <h4>Connected : <i title="Connected" *ngIf="inc.inc.connected" class="fa fa-check-circle"></i>
                  <i title="Not connected" *ngIf="!inc.inc.connected" class="fa fa-close"></i>
                </h4>
                <div *ngIf="inc.inc.connected else notconnected">
                  <i (click)="reboot(inc.inc.id)" title="Click Here To Reboot" class="btn btn-danger fa fa-refresh"></i>
                  <i (click)="logout(inc.inc.id)" style="margin-left: 10px" title="Click Here To Logout" class="btn btn-danger fa fa-sign-out"></i>
                  <br>
                  <br>
                </div>
                <ng-template #notconnected>
                  <i (click)="loadQr(inc.inc.id)"  title="Click Here To Load Qr Latest Version" class="btn btn-danger fa fa-qrcode"></i>
<!--                  <i (click)="loadQrLegacy(inc.inc.id)"  title="Click Here To Load Qr Legacy Version" class="btn btn-danger fa fa-qrcode"></i>-->
                  <br>
                  <br>
                </ng-template>

                <br>

                <div style="margin-top: 10px" class="progress">

                  <div class="progress-bar progress-bar-primary progress-bar-striped progress-animated active" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" [style.width.%]="inc.incGroup.percentage">
                    <span class="popOver" data-toggle="tooltip" data-placement="top" title="" data-original-title="{{inc.incGroup.percentage}}%" aria-describedby="tooltip940885"> </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>


</div>


<div class="row">

  <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <b>Instance Day Wise Report</b>
        </div>
      </div>
      <div style="" class="panel-body">


          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

            <table class="table table-hover table-responsive">
              <thead>
              <tr>
                <th>Instance Name</th><th>Date</th>
                <th>Total</th>
                <th>Sent</th>

              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let trans of transreport">

                <tr>
                  <td [innerHTML]="trans.instanceName"></td>
                  <td [innerHTML]="trans.date"></td>
                  <td [innerHTML]="trans.sent+trans.remaining"></td>
                  <td [innerHTML]="trans.sent"></td>
                </tr>
              </ng-container>
              </tbody>
            </table>

          </div>



      </div>
    </div>
  </div>


</div>





