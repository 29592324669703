import {Component, OnInit} from '@angular/core';
import {AccountMasterDao, CreditType} from '../account-master/AccountMasterDao';
import {Subject} from 'rxjs';
import {UserService} from '../Services/User/user.service';
import {AccountMasterService} from '../account-master/AccountMasterService/account-master.service';
import {FormBuilder} from '@angular/forms';
import {ApplicationsService} from '../Services/applications.service';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {UidGenerationService} from '../Services/Uids/uid-generation.service';
import {UsermasterService} from '../Services/UsermasterService/usermaster.service';
import {AccountServiceDao, AccountServiceFormDao} from '../account-master/account-services/AccountServiceDao';
import {AccountServicesComponent} from '../account-master/account-services/account-services.component';
import * as $ from 'jquery';
import {UserMasterDao} from '../Services/User/UserMasterDao';
import {RechargeComponent} from '../account-master/recharge/recharge.component';
import {SubscriptionFormDao} from '../subscription/SubscriptionDao';
import {SubscriptionWaUnComponent} from '../Unofficial/subscription-wa-un/subscription-wa-un.component';
import {SubscriptionComponent} from '../subscription/subscription.component';
import {RoleAndPrivComponent} from '../account-master/role-and-priv/role-and-priv.component';
import {CountrywiserateComponent} from "../countrywiserate/countrywiserate.component";

@Component({
  selector: 'app-reseller-account',
  templateUrl: './reseller-account.component.html',
  styleUrls: ['./reseller-account.component.css']
})
export class ResellerAccountComponent implements OnInit {

  hasWriteOrUpdatePermission: boolean = this.user.checkPermission('ROLE_ACCOUNTCREATIONORUPD');
  hasRechargePermission: boolean = this.user.checkPermission('ROLE_RECHARGEINS');
  accountMasterDao: AccountMasterDao = {} as AccountMasterDao;
  dtOptions: any = {  bDestroy: true, order: []};
  parentId = 0;
  dtTrigger: Subject<any> = new Subject<any>();

  tableList: Array<AccountMasterDao> = [];
  applicationArr: Array<string> = [];
  serviceTypeArr: Array<string> = [];
  accountModeArr: Array<string> = [];
  accountTypeArr: Array<string> = [];
  deductionTypeArr: Array<string> = [];
  rechargeTypeArr: Array<string> = [];
  isInsert = true;
  accountName: string;
  private updateId: number;

  // tslint:disable-next-line:max-line-length variable-name
  constructor(public user: UserService, private accountService: AccountMasterService, private _formBuilder: FormBuilder, private application: ApplicationsService, private dialog: MatDialog, private alert: AlertMessageService, private counter: UidGenerationService, private userServie: UsermasterService) {

    this.parentId = user.accountMasterDao.id;
    this.accountName = user.accountMasterDao.accountName;

    console.log('user: ', user);
    console.log('accountService: ', accountService);
    console.log('accountService: ', user.accountMasterDao);
    this.application.getAllServices().subscribe(value => {

        this.applicationArr = value;
      });
    this.application.getAllServiceType().subscribe(value => {


        this.serviceTypeArr = value;

      });




    this.application.getAllAccoutMode().subscribe(value => {
        this.accountModeArr = value;
      });
    this.application.getaccountType().subscribe(value => {
        this.accountTypeArr = value;
      });
    this.application.getdeductionType().subscribe(value => {
        this.deductionTypeArr = value;
      });
    this.application.getrechargeType().subscribe(value => {
        this.rechargeTypeArr = value;
      });


    this.refreshData();

  }
  refreshData()
  {

    this.accountService.getAccountByParentId(this.user.accountMasterDao.id).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  addService() {
    const service: AccountServiceFormDao = {
      accountDetails: {
        active: true,
        serviceName: this.applicationArr[0],
        accountMode: this.accountModeArr[0],
        serviceType: this.serviceTypeArr[0],
        whatsappCreditDao: {
          accountType: this.accountTypeArr[0],
          deductionType: this.deductionTypeArr[0],
          expiryDate: '',
          expiryDateFilter: 0,
          extraParameters: null,
          perSpendDeduction: 0,

          postPaidMaximumAllowed: 0
        }
      },
      services: this.applicationArr,
      accountMode: this.accountModeArr,
      accountType: this.accountTypeArr,
      deductionType: this.deductionTypeArr,
      serviceType: this.serviceTypeArr,
      isReseller: true
    };
    this.dialog.open(AccountServicesComponent, {data: service}).afterClosed().subscribe(value => {

      if (value != null) {
        console.log('will push data ', value);
        const serviceDaoTemp: AccountServiceDao = value;
        serviceDaoTemp.whatsappCreditDao.accountType = 'PREPAID';
        if (this.accountMasterDao.services == undefined) {

          this.accountMasterDao.services = [serviceDaoTemp];
        } else {
          this.accountMasterDao.services.push(serviceDaoTemp);
        }

      }


    });
  }

  addNew() {
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
    this.accountMasterDao = {
      accountEmail: '',
      accountLogo: '',
      accountMinLogo: '',
      accountName: '',
      accountPhone: '',
      active: true,
      enableReseller:false,
      createAt: '',
      createBy: '',
      footer: '',
      id: 0,
      services: [],
      title: '',
      updateAt: '',
      updateBy: '',
      parentId: this.parentId,
      reseller: false,
      creditType:CreditType.SELF,
      accountTransactionReportDao:null,
      resellerReportDao:null,
      password:'',
      serviceName:'',
      agentStatus:true,
      enableAgentSupport:false

    };
    this.isInsert = true;
  }

  cancelBtn() {
    $('#addnewdiv').css('display', 'none');
    $('#tablediv').fadeIn();



  }
  editAccount(account: AccountMasterDao, ind: number)
  {
    this.updateId = ind;

    this.isInsert = false;
    this.accountMasterDao = account;
    this.accountMasterDao.parentId = this.parentId;
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
  }

  ngOnInit(): void {


  }

  edit(ind: number, service: AccountServiceDao) {
    console.log(service);
    const services: AccountServiceFormDao = {
      accountDetails: service,
      services: this.applicationArr,
      accountMode: this.accountModeArr,
      accountType: this.accountTypeArr,
      deductionType: this.deductionTypeArr,
      serviceType: this.serviceTypeArr
    };
    this.dialog.open(AccountServicesComponent, {data: services}).afterClosed().subscribe(value => {
      if (value != null) {
        this.accountMasterDao.services[ind] = value;
      }


    });
  }

  // tslint:disable-next-line:typedef
  async updateOrIns(accountMasterDao: AccountMasterDao, isInsert: boolean, showConfirmMessage: boolean) {

    accountMasterDao.parentId = this.parentId;
    accountMasterDao.reseller = true;
    console.log('will update account {}', accountMasterDao);
    if (showConfirmMessage) {
      let message = 'Update Account?';
      if (isInsert) {
        message = 'Create Account?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }
    if (isInsert) {
      this.counter.generateCounterNumber(1, 1).then(id => {
        const serviceList = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < accountMasterDao.services.length; i++)
        {
          serviceList.push(accountMasterDao.services[i].serviceName);
        }
        accountMasterDao.id = id;
        this.accountService.insert(accountMasterDao).subscribe(value => {
          this.accountMasterDao = value;
          this.isInsert = false;
          const user: UserMasterDao = {
            accountId: id,
            otpVerified:false,
            hasTwoStepAuthencation:false,
            accountMasterDao: null,
            accountType: 'ADMIN',
            active: true,
            createAt: '',
            createBy: '',
            email: value.accountEmail,
            fullname: value.accountName,
            id,
            password: value.accountPhone,
            permissions: undefined,
            phone: value.accountPhone,
            services: undefined,
            servicesList: serviceList.join(','),
            updateAt: '',
            updateBy: '',
            username: id.toString(),
            enableAgentSupport: false,
            agentStatus:false
          };
          this.userServie.createUser(user).subscribe(userObj => {
            if (showConfirmMessage) {


              this.alert.showSuccesMessage('Successfully Created Account.Please Login in using your Account Id and PhoneNumber');
            }
            this.cancelBtn();
          });

          this.tableList.unshift(value);

        }, error => {

        });
      });
    } else {
      // accountMasterDao.isReseller = true;
      console.log('test ', accountMasterDao);
      this.accountMasterDao.enableAgentSupport
      this.accountService.update(accountMasterDao).subscribe(value => {

        this.accountMasterDao = value;
        console.log('AFTER AUPDATED accountMasterDao', value);
        this.tableList[this.updateId] = value;
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Updated Account');
        }
        this.cancelBtn();

      }, error => {

      });
    }

  }


  delete(ind: number, service: AccountServiceDao) {
    this.alert.confirmMessage('Delete ' + service.serviceName + ' Service?', 'Are you sure?').then(value => {
      this.accountMasterDao.services.splice(ind, 1);
      this.updateOrIns(this.accountMasterDao, false, false);
    });
  }

  // tslint:disable-next-line:typedef
  createOrUpdate() {
    this.updateOrIns(this.accountMasterDao, this.isInsert, true);
  }


  rechare(account: AccountMasterDao) {

    this.dialog.open(RechargeComponent, {data: {account, rechargeType: this.rechargeTypeArr, mode: 1}});
  }
  addCountryRates(id: number) {
    const dialogRef=this.dialog.open(CountrywiserateComponent,{data:id, maxHeight:600});
    dialogRef.afterClosed().subscribe(value =>{
      console.log('values are:',value);
    })

  }


  // tslint:disable-next-line:typedef
  addSubscription(service: AccountServiceDao) {

    console.log('Service ', service);
    // tslint:disable-next-line:triple-equals
    if (service.serviceName == 'WHATSAPPUNOFFICIAL')
    {
      const subForm: SubscriptionFormDao = {accountId: this.accountMasterDao.id, isReseller: true};
      this.dialog.open(SubscriptionWaUnComponent, {data: subForm});
      // tslint:disable-next-line:triple-equals
    }else if (service.serviceName == 'WHATSAPPOFFICIAL'){

      const subForm: SubscriptionFormDao = {accountId: this.accountMasterDao.id};
      this.dialog.open(SubscriptionComponent, {data: subForm});
    }

  }

  // tslint:disable-next-line:typedef
  addRole(id: number) {
    const user: UserMasterDao = {
      accountId: id,
      otpVerified:false,
      hasTwoStepAuthencation:false,
      accountMasterDao: undefined,
      accountType: '',
      active: false,
      createAt: '',
      createBy: '',
      email: '',
      fullname: '',
      id,
      password: '',
      permissions: undefined,
      phone: '',
      services: undefined,
      servicesList: '',
      updateAt: '',
      updateBy: '',
      username: '',
      enableAgentSupport:false,
      agentStatus:false
    };
    this.dialog.open(RoleAndPrivComponent, {data: user});

  }
}
