import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConversationDao} from '../ConversationDao';
import {ConversationService} from '../ConversationService/conversation.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ConversationSubscriptionDao} from '../ConversationService/ConversationSubscriptionDao';

@Injectable({
  providedIn: 'root'
})
export class ConversationSubscriptionService {
  private dataObsevable: BehaviorSubject<ConversationSubscriptionDao> = new BehaviorSubject(null);
  lastMessageTime:number=Number(localStorage.getItem("LASTINCOMINGMESSAGE"));

  constructor(private coversationService:ConversationService,private alertMessage:AlertMessageService) {
    this.getData();
  var current=this;
  setInterval(function(){
    current.getData();
    },2500)
  }
  refreshChatAgain()
  {
    this.getData();
  }
  getData()
  {
    this.coversationService.getConversationUnread().subscribe(value => {

      let tempObj:ConversationSubscriptionDao={data: value, isNew: false,lastMessageTime:this.lastMessageTime}
      if(value.length>0&&value[0].lastMessageTime>this.lastMessageTime)
      {
        tempObj.isNew=true;
      this.lastMessageTime=value[0].lastMessageTime;
      localStorage.setItem("LASTINCOMINGMESSAGE",String(this.lastMessageTime));
      this.alertMessage.playIncomingMessageSound();
      }
      this.dataObsevable.next(tempObj);
      tempObj=null;
    })
  }

 getObj(): Observable<ConversationSubscriptionDao>
 {
   return this.dataObsevable.asObservable();
 }

}
