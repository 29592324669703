import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceMasterDao} from '../../servicemaster/ServiceMasterDao';
import {environment} from '../../../environments/environment.prod';
import {ModuleMasterDao} from '../ModuleMasterDao';
import {PermissionEntryDao} from '../../permissionentry/PermissionEntryDao';

@Injectable({
  providedIn: 'root'
})
export class ModulemasterServiceService {

  constructor(private http:HttpClient) { }
  get(serviceId:number):Observable<Array<ModuleMasterDao>>
  {
    return this.http.get<Array<ModuleMasterDao>>(environment.appUrl+environment.authMicroservice+"module/get/"+serviceId);
  }

  save(role:ModuleMasterDao):Observable<ModuleMasterDao>
  {
    return this.http.post<ModuleMasterDao>(environment.appUrl+environment.authMicroservice+"module/save",role);
  }
  getPermissionEntry(roleId:number,serviceId:number):Observable<Array<PermissionEntryDao>>
  {
    return this.http.get<Array<PermissionEntryDao>>(environment.appUrl+environment.authMicroservice+"module/getmodulewisepermission/"+serviceId+"/"+roleId);

  }

}
