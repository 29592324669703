import { Component, OnInit } from '@angular/core';
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {ExtensionWiseAgent} from "../ExtensionWiseAgent";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {UsermasterService} from "../../Services/UsermasterService/usermaster.service";
import {ExtensionWiseAgentService} from "../extension-wise-agent.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {AgentMasterFormComponent} from "../../AgentSupport/AgentMaster/agent-master-form/agent-master-form.component";
import {LanguageWiseAgent} from "../LanguageWiseAgent";
import {LanguagewiseagentService} from "../languagewiseagent.service";
import {LanguageWiseAgentFormComponent} from "../language-wise-agent-form/language-wise-agent-form.component";

@Component({
  selector: 'app-language-wise-agent',
  templateUrl: './language-wise-agent.component.html',
  styleUrls: ['./language-wise-agent.component.css']
})
export class LanguageWiseAgentComponent implements OnInit {
  languageWiseAgents: PaginationMain<LanguageWiseAgent> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  currentUser:UserMasterDao
  offset: number = 0;
  limit: number = 10;
  private data: LanguageWiseAgent;


  constructor(private userMasterService:UsermasterService, private languagewiseagentService: LanguagewiseagentService, private dialog: MatDialog, private alert: AlertMessageService) { }

  ngOnInit(): void {
    this.userMasterService.getCurrentUser().subscribe(data => {
      this.currentUser = data;
      console.log("current user", this.currentUser)
      this.loadData();
    });
  }



  loadData()
  {
    this.languagewiseagentService.getReport(this.currentUser.accountId, this.offset, this.limit).subscribe(data => {
      console.log("data : ", data)
      this.languageWiseAgents = data;
      console.log("languageList:", this.languageWiseAgents)

    });
  }
  tableChange(json) {
    console.log("json",json)
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }


  deleteLanguage(id) {
    this.alert.confirmMessage("delete this Language??",'Are you sure?').then(response=>{
        if (response=='success'){
          this.languagewiseagentService.deleteLanguage(id).subscribe(value => {
            console.log("id",value)
            this.alert.showSuccesMessageWithoutReload("deleted")
            this.loadData();
          });
        }
        else{
        }
      }
    );
  }


  addOrUpdateLanguageMaterForm(languageWiseAgentTemp:LanguageWiseAgent,submitBtnText:String) {
    console.log("languageWiseagent Temp in edit or add:",languageWiseAgentTemp)
    let languageWiseAgent:LanguageWiseAgent={id:0,accountId: this.currentUser.accountId,agentNumbers: "", language: undefined,languageId:"",chatAgentNumber:""}


    if (languageWiseAgentTemp != undefined){
      languageWiseAgent = languageWiseAgentTemp
      console.log("languageWiseAgent",languageWiseAgent)
    }

    let dialogRef =  this.dialog.open(LanguageWiseAgentFormComponent,{width: '140vw',
      maxHeight: '95vh',
      data:{languageWiseAgent,submitBtnTxt:submitBtnText}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe language Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }




  cancel() {
    this.data = null;
  }
}
