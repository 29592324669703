import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaObj, Paramters, ParamtersButton, TemplateDao, TemplateDaoButton} from './MessageDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {UidGenerationService} from '../Services/Uids/uid-generation.service';
import {WsTemplateService} from './TemplateService/ws-template.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageTypeComponent} from '../message-type/message-type.component';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-ws-template',
  templateUrl: './ws-template.component.html',
  styleUrls: ['./ws-template.component.css']
})
export class WsTemplateComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:max-line-length

  constructor(private alert: AlertMessageService, private counter: UidGenerationService, private templateService: WsTemplateService, private dialog: MatDialog) {
    this.templateService.getTemplateWsByAccountId(1).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });

  }
  type: 'text';
  indexValue: number;
  subType: string=''  ;
  urlType: string = 'static';
  dtOptions: any = {bDestroy: true, order: []};
  hasWriteOrUpdatePermission = true;
  addNewDiv = false;
  isUpdate = false;
  templateDao: TemplateDao = null;
  templateDaoButton: TemplateDaoButton = null;
  dtTrigger: Subject<any> = new Subject<any>();
  updateInd = 0;
  tableList: Array<TemplateDao> = null;
  tableList1: Array<TemplateDaoButton> = null;
  languageJson: Array<any> = [{
    Language: 'Afrikaans',
    Code: 'af'
  },
    {
      Language: 'Albanian',
      Code: 'sq'
    },
    {
      Language: 'Arabic',
      Code: 'ar'
    },
    {
      Language: 'Azerbaijani',
      Code: 'az'
    },
    {
      Language: 'Bengali',
      Code: 'bn'
    },
    {
      Language: 'Bulgarian',
      Code: 'bg'
    },
    {
      Language: 'Catalan',
      Code: 'ca'
    },
    {
      Language: 'Chinese (CHN)',
      Code: 'zh_CN'
    },
    {
      Language: 'Chinese (HKG)',
      Code: 'zh_HK'
    },
    {
      Language: 'Chinese (TAI)',
      Code: 'zh_TW'
    },
    {
      Language: 'Croatian',
      Code: 'hr'
    },
    {
      Language: 'Czech',
      Code: 'cs'
    },
    {
      Language: 'Danish',
      Code: 'da'
    },
    {
      Language: 'Dutch',
      Code: 'nl'
    },
    {
      Language: 'English',
      Code: 'en'
    },
    {
      Language: 'English (UK)',
      Code: 'en_GB'
    },
    {
      Language: 'English (US)',
      Code: 'en_US'
    },
    {
      Language: 'Estonian',
      Code: 'et'
    },
    {
      Language: 'Filipino',
      Code: 'fil'
    },
    {
      Language: 'Finnish',
      Code: 'fi'
    },
    {
      Language: 'French',
      Code: 'fr'
    },
    {
      Language: 'German',
      Code: 'de'
    },
    {
      Language: 'Greek',
      Code: 'el'
    },
    {
      Language: 'Gujarati',
      Code: 'gu'
    },
    {
      Language: 'Hausa',
      Code: 'ha'
    },
    {
      Language: 'Hebrew',
      Code: 'he'
    },
    {
      Language: 'Hindi',
      Code: 'hi'
    },
    {
      Language: 'Hungarian',
      Code: 'hu'
    },
    {
      Language: 'Indonesian',
      Code: 'id'
    },
    {
      Language: 'Irish',
      Code: 'ga'
    },
    {
      Language: 'Italian',
      Code: 'it'
    },
    {
      Language: 'Japanese',
      Code: 'ja'
    },
    {
      Language: 'Kannada',
      Code: 'kn'
    },
    {
      Language: 'Kazakh',
      Code: 'kk'
    },
    {
      Language: 'Korean',
      Code: 'ko'
    },
    {
      Language: 'Lao',
      Code: 'lo'
    },
    {
      Language: 'Latvian',
      Code: 'lv'
    },
    {
      Language: 'Lithuanian',
      Code: 'lt'
    },
    {
      Language: 'Macedonian',
      Code: 'mk'
    },
    {
      Language: 'Malay',
      Code: 'ms'
    },
    {
      Language: 'Malayalam',
      Code: 'ml'
    },
    {
      Language: 'Marathi',
      Code: 'mr'
    },
    {
      Language: 'Norwegian',
      Code: 'nb'
    },
    {
      Language: 'Persian',
      Code: 'fa'
    },
    {
      Language: 'Polish',
      Code: 'pl'
    },
    {
      Language: 'Portuguese (BR)',
      Code: 'pt_BR'
    },
    {
      Language: 'Portuguese (POR)',
      Code: 'pt_PT'
    },
    {
      Language: 'Punjabi',
      Code: 'pa'
    },
    {
      Language: 'Romanian',
      Code: 'ro'
    },
    {
      Language: 'Russian',
      Code: 'ru'
    },
    {
      Language: 'Serbian',
      Code: 'sr'
    },
    {
      Language: 'Slovak',
      Code: 'sk'
    },
    {
      Language: 'Slovenian',
      Code: 'sl'
    },
    {
      Language: 'Spanish',
      Code: 'es'
    },
    {
      Language: 'Spanish (ARG)',
      Code: 'es_AR'
    },
    {
      Language: 'Spanish (SPA)',
      Code: 'es_ES'
    },
    {
      Language: 'Spanish (MEX)',
      Code: 'es_MX'
    },
    {
      Language: 'Swahili',
      Code: 'sw'
    },
    {
      Language: 'Swedish',
      Code: 'sv'
    },
    {
      Language: 'Tamil',
      Code: 'ta'
    },
    {
      Language: 'Telugu',
      Code: 'te'
    },
    {
      Language: 'Thai',
      Code: 'th'
    },
    {
      Language: 'Turkish',
      Code: 'tr'
    },
    {
      Language: 'Ukrainian',
      Code: 'uk'
    },
    {
      Language: 'Urdu',
      Code: 'ur'
    },
    {
      Language: 'Uzbek',
      Code: 'uz'
    },
    {
      Language: 'Vietnamese',
      Code: 'vi'
    },
    {
      Language: 'Zulu',
      Code: 'zu'
    }
  ];
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  addNew() {

    console.log('after dailog subtype value=' + this.subType);
    console.log('after dailog subtype value=' + this.indexValue);
    this.templateDao = {
      id: '',
      language: {code: 'en', policy: ''},
      name: '',
      namespace: '',
      status: true,
      templateBodyText: '',
      category:'MARKETING',
      // tslint:disable-next-line:max-line-length
      components: [
         {type: 'header', parameters: [], index: null, sub_type: '', urlType:'static'},
         {type: 'body', parameters: [] , index: null, sub_type: '', urlType:'static'},
         {type: 'footer', parameters: [], index: null, sub_type: '', urlType:'static'},
         {type: 'button' , parameters: [], index: this.indexValue, sub_type: this.subType, urlType:'static'},

      ]
    };

    this.templateDaoButton = {
      id: '',
      language: {code: 'en', policy: ''},
      name: '',
      namespace: '',
      status: true,
      // tslint:disable-next-line:max-line-length
      components1: [
        {type: 'header', parameters: [], index: null, sub_type: ''},
        {type: 'body', parameters: [] , index: null, sub_type: ''},
        {type: 'footer', parameters: [], index: null, sub_type: ''},
        {type: 'button' , parameters: [], index: 0, sub_type: 'url'},
      ]
    };

    this.addNewDiv = true;
    this.isUpdate = false;

  }

  // tslint:disable-next-line:typedef
  cancel() {
    this.addNewDiv = false;
    this.templateDao=null;
  }

  // tslint:disable-next-line:typedef
  edit(tempateDao: TemplateDao, ind: number) {
    this.templateDao = tempateDao;
    this.isUpdate = true;
    this.addNewDiv = true;
    this.updateInd = ind;

  }

  // tslint:disable-next-line:typedef
  isOrUpdate() {
    // tslint:disable-next-line:no-debugger
    let message = 'Create Template?';
    if (this.isUpdate) {
      message = 'Update Template?';
    }
     // tslint:disable-next-line:prefer-for-of
    for (let i = 0 ; i < this.templateDao.components.length; i++)
    {

       // tslint:disable-next-line:triple-equals
      if ( this.templateDao.components[i].type == 'header'){
        delete this.templateDao.components[i].sub_type;
      }

      // tslint:disable-next-line:triple-equals
      if ( this.templateDao.components[i].type == 'body'){
        delete this.templateDao.components[i].sub_type;
      }

      // tslint:disable-next-line:triple-equals
      if ( this.templateDao.components[i].type == 'footer'){
        delete this.templateDao.components[i].sub_type;
      }

      // tslint:disable-next-line:triple-equals max-line-length
      if (this.templateDao.components[i].type == 'button'){
         for ( let j = 0; j < this.templateDao.components[i].parameters.length; j++){
           delete this.templateDao.components[i].parameters[j].document;
          delete this.templateDao.components[i].parameters[j].video;
          delete this.templateDao.components[i].parameters[j].image;
          delete this.templateDao.components[i].parameters[j].date_time;
          delete this.templateDao.components[i].parameters[j].currency;
          delete this.templateDao.components[i].parameters[j].nameOfParams;
        }
        for ( let k = 0; k <= i; k++){
          // tslint:disable-next-line:triple-equals

        }
      }
      if (this.templateDao.components[i].sub_type==''){
        this.templateDao.components.splice(3,1);
      }
    }
    this.alert.confirmMessage(message, 'Are you sure?').then(value => {
      if (this.isUpdate) {
        this.templateService.updateTemplate(this.templateDao).subscribe(tem => {
          this.templateDao = tem;
          this.alert.showSuccesMessage('Successfully Updated Template');
          this.tableList[this.updateInd] = tem;
          this.cancel();
        });
      } else {


        // tslint:disable-next-line:prefer-for-of

        console.log('templateDao' + this.templateDao);
        this.templateService.insertTemplate(this.templateDao).subscribe(tem => {
          this.tableList.unshift(tem);
          this.templateDao = tem;
          this.alert.showSuccesMessage('Successfully Create Template');

          this.cancel();
        });

      }
    });

  }

  // tslint:disable-next-line:typedef
  addNewRow(type: string) {
     const mediaObj: MediaObj = {filename: '', link: ''};
    let parameters: Paramters = {
      text: '',
      type: 'text',
      currency: {amount_1000: 0, code: 'USD', fallback_value: ''},
      date_time: {day_of_month: 1, fallback_value: '', hour: 1, minute: 1, month: 1, year: 1, fullDate: ''},
      document: mediaObj,
      image: mediaObj,
      video: mediaObj,
      nameOfParams: '',
      // componentsType: type,
    };



    // tslint:disable-next-line:triple-equals
    if (type == 'button')
    {
      console.log('if block call');
      this.dialog.open(MessageTypeComponent,
        {
          data: type
        }).afterClosed().subscribe(value => {
        console.log(value);
        // tslint:disable-next-line:triple-equals
        if (value == 'null') {
          return;
        } else if (value == undefined) {
          return;
        } else if (value == 'null') {
          return;
        } else {
          this.subType = value;

          console.log('subtype value:: ' + this.subType);
          // tslint:disable-next-line:triple-equals
          if (this.subType == 'url'){
              this.indexValue = 0;
           }

          // tslint:disable-next-line:triple-equals
          if (this.subType == 'call'){
            this.indexValue = 1;
          }
          // tslint:disable-next-line:triple-equals
          if (this.subType == 'action'){
            this.indexValue = 2;
          }
          console.log('subtype is' + this.subType);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.templateDao.components.length; i++) {
            // tslint:disable-next-line:triple-equals
            if (this.templateDao.components[i].type == type) {
              // tslint:disable-next-line:no-unused-expression
                this.templateDao.components[i].parameters.push(parameters);
                this.templateDao.components[i].sub_type = this.subType;
                this.templateDao.components[i].index = this.indexValue;
                this.templateDao.components[i].urlType = this.urlType;
                 //this.templateDaoButton.components1[i].sub_type = this.subType;
              // console.log('subtype values :: ' + this.templateDao.components[i].sub_type);

            }
          }
        }
      });
    }

  else
    {
      console.log('else block call:: ', type);
      this.dialog.open(MessageTypeComponent,
        {
          data: parameters
        }).afterClosed().subscribe(value => {
        console.log(value);
        // tslint:disable-next-line:triple-equals
        if (value == 'null') {
          return;
          // tslint:disable-next-line:triple-equals
        } else if (value == undefined) {
          return;
          // tslint:disable-next-line:triple-equals
        } else if (value == 'null') {
          return;
        } else {
          parameters = value;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.templateDao.components.length; i++) {
            // tslint:disable-next-line:triple-equals
            if (this.templateDao.components[i].type == type) {
              this.templateDao.components[i].parameters.push(parameters);
              // tslint:disable-next-line:no-unused-expression
              this.templateDao.components[i].parameters[i];
            }
          }
        }
      });
    }

  }


  // tslint:disable-next-line:typedef
  editParameter(params: Paramters, ind: number, typ: string) {

    if (typ != 'button') {
      this.dialog.open(MessageTypeComponent, {data: params}).afterClosed().subscribe(value => {
        console.log(value);
        if (value == 'null') {
          return;
        } else if (value == undefined) {
          return;
        } else if (value == 'null') {
          return;
        } else {
          params = value;
          for (let i = 0; i < this.templateDao.components.length; i++) {
            if (this.templateDao.components[i].type == typ) {
              this.templateDao.components[i].parameters[ind] = params;
            }
          }
        }
      });

    }

    // tslint:disable-next-line:triple-equals
    else
    {
      console.log('else block call');
      this.dialog.open(MessageTypeComponent,
        {
          data: typ
        }).afterClosed().subscribe(value => {
        console.log(value);
        // tslint:disable-next-line:triple-equals
        if (value == 'null') {
          return;
          // tslint:disable-next-line:triple-equals
        } else if (value == undefined) {
          return;
        } else if (value == 'null') {
          return;
        } else {
          this.subType = value;
          console.log('subtype value:: ' + this.subType);
          // tslint:disable-next-line:triple-equals
          if (this.subType == 'url'){
            this.indexValue = 0;
          }

          // tslint:disable-next-line:triple-equals
          if (this.subType == 'call'){
            this.indexValue = 1;
          }
          // tslint:disable-next-line:triple-equals
          if (this.subType == 'action'){
            this.indexValue = 2;
          }
          console.log('subtype is' + this.subType);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.templateDao.components.length; i++) {
            // tslint:disable-next-line:triple-equals
            if (this.templateDao.components[i].type == typ) {
              // tslint:disable-next-line:no-unused-expression
              this.templateDao.components[i].parameters[ind] = params;
              this.templateDao.components[i].sub_type = this.subType;
              this.templateDao.components[i].index = this.indexValue;
              this.templateDao.components[i].urlType = this.urlType;
              // this.templateDaoButton.components1[i].sub_type = this.subType;
              // console.log('subtype values :: ' + this.templateDao.components[i].sub_type);



            }
          }
        }
      });
    }




  }

  // tslint:disable-next-line:typedef
  // editButton() {
  //   this.dialog.open(MessageTypeComponent, {}).afterClosed().subscribe(value => {
  //     console.log(value);
  //     if (value == 'null') {
  //       return;
  //     } else if (value == undefined) {
  //       return;
  //     } else if (value == 'null') {
  //       return;
  //     } else {
  //       params = value;
  //       for (let i = 0; i < this.templateDao.components.length; i++) {
  //         if (this.templateDao.components[i].type == typ) {
  //           this.templateDao.components[i].parameters[ind] = params;
  //         }
  //       }
  //     }
  //   });
  // }

  deleteParams(params: Paramters, ind: number, type: string) {

    this.alert.confirmMessage('Delete ' + params.nameOfParams + '?', 'Are you sure?').then(value => {
      for (let i = 0; i < this.templateDao.components.length; i++) {
        if (this.templateDao.components[i].type == type) {
          console.log('this is index ', ind , ' type is ', type);

          this.templateDao.components[i].parameters.splice(ind, 1);
        }
      }
    });

  }
}
