import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationDeployeDao} from './application-deploye-dao';
import {environment} from '../../environments/environment.prod';
import {WaContactGroupDao} from '../Unofficial/WaContactGroup/wa-contact-group-dao';

const appPrefix = environment.appUrl + environment.appDeployement;
@Injectable({
  providedIn: 'root'
})
export class ApplicationDeployementService {

  constructor(private http: HttpClient) {
  }

  get(): Observable<Array<ApplicationDeployeDao>> {
        return this.http.get<Array<ApplicationDeployeDao>>(appPrefix + 'api/appDeployement/get');
  }

  // tslint:disable-next-line:max-line-length
  save(project: any, technology: any, type: any, server: any, deployementContainer: any, uploadedBy: any, description: any, formData: any): Observable<any> {
    const desc = encodeURIComponent(description);
     // tslint:disable-next-line:max-line-length
    return this.http.post( appPrefix + 'api/appDeployement/insert?project=' + project + ' &technology=' + technology + '&type=' + type + '&server= ' + server + '&deployementcontainer=' + deployementContainer + '&uploadedBy=' + uploadedBy + '&description=' + desc,  formData, {headers: {Testtt: 'multipart/form-data', encode: 'UTF-8'}});

  }
  updateStatus(approvedBy, deployedBy, status, id): Observable<any>
  {
    // tslint:disable-next-line:max-line-length
    return this.http.post(appPrefix + 'api/appDeployement/changestatus?approvedBy=' + approvedBy + '&deployedBy=' + deployedBy + '&status=' + status + '&id=' + id, {});
  }
  // tslint:disable-next-line:typedef
  uploadFile(event) {

    const formData = new FormData();
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];
      formData.append('file', file);
    }
    return formData;
  }

}
