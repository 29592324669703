import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from '../../index/index.component';
import {UserMasterComponent} from '../../user-master/user-master.component';
import {AccountMasterComponent} from '../../account-master/account-master.component';
import {ReportComponent} from '../../report/report.component';
import {AuthGuard} from './Authguard';
import {ChatComponent} from '../../chat/chat.component';

import {CrdrreportComponent} from '../../crdrreport/crdrreport.component';
import {TransactionreportComponent} from '../../transactionreport/transactionreport.component';
import {ProfileComponent} from '../../profile/profile.component';
import { OnetomanyNoAuthComponent } from 'src/app/onetomany-no-auth/onetomany-no-auth.component';
import {WsTemplateComponent} from '../../ws-template/ws-template.component';
import {OnetomanyComponent} from '../../onetomany/onetomany.component';
import {ManytomanyComponent} from '../../manytomany/manytomany.component';
import {ApidocsComponent} from '../../apidocs/apidocs.component';
import {WebhookComponent} from '../../webhook/webhook.component';
import {ChatbotconfigComponent} from '../../chatbotconfig/chatbotconfig.component';
import {ConversationComponent} from '../../conversation/conversation.component';
import {CampaignReportComponent} from '../../campaign-report/campaign-report.component';
import {
  WaofficialdashboardComponent
} from '../../MicroServices/Waofficial/waofficialdashboard/waofficialdashboard.component';
import {environment} from '../../../environments/environment.prod';

import {
  CigaratePanelDashComponent
} from '../../MicroServices/Digimozo/Cigarate/cigarate-panel-dash/cigarate-panel-dash.component';
import {DashboardComponent} from '../../MicroServices/GsmGateway/Daone/dashboard/dashboard.component';
import {EmpmasterComponent} from '../../MicroServices/GsmGateway/Daone/empmaster/empmaster.component';
import {EmpreportComponent} from '../../MicroServices/GsmGateway/Daone/empreport/empreport.component';
import {AuthguardDashboard} from './AuthguardDashboard';
import {ProductmasterComponent} from '../../MicroServices/GsmGateway/Daone/productmaster/productmaster.component';
import {RolemasterComponent} from '../../rolemaster/rolemaster.component';
import {ServicemasterComponent} from '../../servicemaster/servicemaster.component';
import {MenumasterComponent} from '../../menumaster/menumaster.component';
import {ModuleMasterComponent} from '../../module-master/module-master.component';
import {PermissionentryComponent} from '../../permissionentry/permissionentry.component';
import {
  IncommingSmsReportComponent
} from '../../MicroServices/GsmGateway/Oppo/incomming-sms-report/incomming-sms-report.component';
import {EmasolchatbotComponent} from '../../MicroServices/Digimozo/EmosolChatbot/emasolchatbot/emasolchatbot.component';
import {PriceChartComponent} from '../../MicroServices/Nouriture/price-chart/price-chart.component';
import {OutletComponent} from '../../MicroServices/Nouriture/outlet/outlet.component';
import {
  NouritureConversationComponent
} from '../../MicroServices/Nouriture/Conversation/nouriture-conversation/nouriture-conversation.component';
import {FreighChartComponent} from '../../MicroServices/Nouriture/freigh-chart/freigh-chart.component';
import {OBDComponent} from '../../RahulPackage/obd/obd.component';
import {OBDChartComponent} from '../../RahulPackage/obdchart/obdchart.component';
import {EnquiryDetailsComponent} from '../../MicroServices/Nouriture/enquiry-details/enquiry-details.component';
import {C2cReportComponent} from '../../MicroServices/HDFC/c2c-report/c2c-report.component';
import {C2cPanelComponent} from '../../MicroServices/HDFC/c2c-panel/c2c-panel.component';
import {HdfcSupComponent} from '../../MicroServices/HDFC/hdfc-sup/hdfc-sup.component';
import {HdfcCallsComponent} from '../../MicroServices/HDFC/hdfc-calls/hdfc-calls.component';
import {HdfcReportComponent} from '../../MicroServices/HDFC/hdfc-report/hdfc-report.component';
import {HdfcNewjoineeComponent} from '../../MicroServices/HDFC/hdfc-newjoinee/hdfc-newjoinee.component';
import {HdfcResignedComponent} from '../../MicroServices/HDFC/hdfc-resigned/hdfc-resigned.component';
import {HdfcEmployeeComponent} from '../../MicroServices/HDFC/hdfc-employee/hdfc-employee.component';
import {
  RetailerCashbackComponent
} from '../../MicroServices/Digimozo/Cigarate/retailer-cashback/retailer-cashback.component';
import {ScratchCardReportComponent} from '../../RahulPackage/scratch-card-report/scratch-card-report.component';
import {
  CigaratePanelDashC4Component
} from '../../MicroServices/Digimozo/Cigarate/cigarate-panel-dash-c4/cigarate-panel-dash-c4.component';
import {LongCodeCampaignComponent} from '../../RahulPackage/long-code-campaign/long-code-campaign.component';
import {ShubhLabhcampaignComponent} from '../../RahulPackage/shubh-labhcampaign/shubh-labhcampaign.component';
import {LongcodenrComponent} from '../../RahulPackage/LongCodeNR/longcodenr/longcodenr.component';
import {DomainMasterComponent} from '../../Unofficial/domain-master/domain-master.component';
import {InstanceMasterComponent} from '../../Unofficial/instance-master/instance-master.component';
import {ProxymasterComponent} from '../../Unofficial/proxymaster/proxymaster.component';
import {DashboardunwsComponent} from '../../Unofficial/dashboardunws/dashboardunws.component';
import {OnetomanywsunComponent} from '../../Unofficial/onetomanywsun/onetomanywsun.component';
import {CampaignReportUnComponent} from '../../Unofficial/campaign-report-un/campaign-report-un.component';
import {DlrReportComponent} from '../../Unofficial/dlr-report/dlr-report.component';
import {LongcodeBpphpComponent} from '../../RahulPackage/BPPHPCampaign/longcode-bpphp/longcode-bpphp.component';
import {DriveComponent} from '../../Drive/drive/drive.component';
import {MailgroupComponent} from '../../mailSendingApp/mailgroup/mailgroup.component';
import {SendMailComponent} from '../../mailSendingApp/send-mail/send-mail.component';
import {MailreportComponent} from '../../mailSendingApp/mailreport/mailreport.component';
import {AllMailsComponent} from '../../mailSendingApp/all-mails/all-mails.component';
import {GroupMasterComponent} from '../../mailSendingApp/group-master/group-master.component';
import {ManytomanywaunComponent} from '../../Unofficial/manytomanywaun/manytomanywaun.component';
import {ApikeywaunComponent} from '../../Unofficial/apikeywaun/apikeywaun.component';
import {CountrywiserateComponent} from '../../countrywiserate/countrywiserate.component';
import {CountrymasterComponent} from '../../countrymaster/countrymaster.component';
import {ResellerAccountComponent} from '../../reseller-account/reseller-account.component';
import {IncomingMessagesComponent} from '../../Unofficial/incoming-messages/incoming-messages.component';
import {PromptUploadComponent} from '../../MicroServices/HDFC/prompt-upload/prompt-upload.component';
import {LongcodenrctComponent} from '../../RahulPackage/LongcodeNRCT/longcodenrct/longcodenrct.component';
import {WaContactGroupComponent} from '../../Unofficial/WaContactGroup/wa-contact-group/wa-contact-group.component';
import {
  WacontactgroupMasterComponent
} from '../../Unofficial/WaContactGroup/GroupMaster/wacontactgroup-master/wacontactgroup-master.component';
import {AddClientComponent} from '../../Unofficial/WaContactGroup/AddClient/add-client/add-client.component';
import {
  ApplicationDeployementComponent
} from '../../ApplicationDeployement/application-deployement/application-deployement.component';
import {DatePickerComponentComponent} from '../../mailSendingApp/date-picker-component/date-picker-component.component';
import {ScheduleReportComponent} from '../../mailSendingApp/schedule-report/schedule-report.component';
import {ConversationwaunComponent} from '../../Unofficial/conversationwaun/conversationwaun.component';
import {SignutraComponent} from '../../MicroServices/GsmGateway/Signutra/signutra/signutra.component';
import {ClientReportComponent} from '../../Unofficial/client-report/client-report.component';
import {OperationDaoComponent} from '../../Feature/operation-dao/operation-dao.component';
import {StopCampaignComponent} from '../../Unofficial/stop-campaign/stop-campaign.component';
import {
  ScheduleCampaignReportComponent
} from '../../Unofficial/schedule-campaign-report/schedule-campaign-report.component';
import {ChatbotMasterComponent} from "../../MicroServices/Chatbot/chatbot-master/chatbot-master.component";
import {ResellerStatsComponent} from "../../account-master/reseller-stats/reseller-stats.component";
import {UrlConfigComponent} from '../../MicroServices/GsmGateway/ApiConfig/url-config/url-config.component';
import {FeatureAcessComponent} from "../../feature-acess/feature-acess.component";
import {NbccComponent} from "../../MicroServices/NBCC/nbcc/nbcc.component";
import {SlabMasterComponent} from "../../MicroServices/NBCC/slab-master/slab-master.component";
import {CampaignScheduleReportComponent} from "../../campaign-schedule-report/campaign-schedule-report.component";
import {PaymentComponent} from "../../MicroServices/NBCC/payment/payment.component";
import {BungalowHistoryComponent} from "../../MicroServices/NBCC/bungalow-history/bungalow-history.component";
import {PaymentStatucComponent} from "../../MicroServices/NBCC/payment-statuc/payment-statuc.component";
import {CreditDebitReportComponent} from "../../credit-debit-report/credit-debit-report.component";
import {
  DragAndDropChatbotComponent
} from "../../draganddropchatbot/drag-and-drop-chatbot/drag-and-drop-chatbot.component";
import {
  WhatsappCloudTemplateMasterComponent
} from "../../whatsapp-cloud-template-master/whatsapp-cloud-template-master.component";
import {NdtComponent} from "../../MicroServices/Digimozo/ndt/ndt.component";
import {AgentMasterComponent} from "../../AgentSupport/AgentMaster/agent-master/agent-master.component";
import {ClientMasterComponent} from "../../AgentSupport/ClientMaster/client-master/client-master.component";
import {
  AgentClientConversationComponent
} from "../../AgentSupport/agent-client-conversation/agent-client-conversation.component";
import {
  RegistrationOtpComponent
} from "../../MicroServices/Digimozo/Registration-Otp/registration-otp/registration-otp.component";
import {RegistrationComponent} from "../../MicroServices/Digimozo/Registration-Otp/registration/registration.component";
import {
  RegistrationMasterComponent
} from "../../MicroServices/Digimozo/Registration-Otp/registration-master/registration-master.component";
import {
  RegistrationOtpMasterComponent
} from "../../MicroServices/Digimozo/Registration-Otp/registration-otp-master/registration-otp-master.component";
import {ZnpdComponent} from "../../MicroServices/Digimozo/znpd/znpd.component";
import {ExtensionWiseAgentComponent} from "../../Gogreen/extension-wise-agent/extension-wise-agent.component";

import {LanguageWiseAgentComponent} from "../../Gogreen/language-wise-agent/language-wise-agent.component";
import {
  RegistrationFormComponent
} from "../../MicroServices/Digimozo/RegistratonKK/registration-form/registration-form.component";
import {
  RegistrationKKComponent
} from "../../MicroServices/Digimozo/RegistratonKK/registration-kk/registration-kk.component";
import {
  RegistrationKKMasterComponent
} from "../../MicroServices/Digimozo/RegistratonKK/registration-kkmaster/registration-kkmaster.component";
import {ClientSignUpComponent} from "../../client-sign-up/client-sign-up.component";
import {OtpVerificationComponent} from "../../otp-verification/otp-verification.component";
import {InitiateChatbotComponent} from "../../initiate-chatbot/initiate-chatbot.component";
import {InteractionSessionComponent} from "../../AgentSupport/interaction-session/interaction-session.component";
import {
  IncommingReportComponent
} from "../../MicroServices/GsmGateway/Reliance/incomming-report/incomming-report.component";
import {
  UploadLongCodeDataComponent
} from "../../MicroServices/Digimozo/upload-long-code-data/upload-long-code-data.component";
import {
  VerifiedIncomingMessageReportComponent
} from "../../verified-incoming-message-report/verified-incoming-message-report.component";
import {
  SubscriptionPackageReportComponent
} from "../../subscription-package-report/subscription-package-report.component";
import {PaymentDetailsComponent} from "../../payment-details/payment-details.component";
import {
  SubscriptionTransactionReportComponent
} from "../../subscription-transaction-report/subscription-transaction-report.component";
import {OnlineSignUpReportComponent} from "../../online-sign-up-report/online-sign-up-report.component";
import {CatalogueMessageComponent} from "../../catalogue/catalogue-message/catalogue-message.component";
import { CampaignReportResellerComponent } from 'src/app/campaign-report-reseller/campaign-report-reseller.component';
import { ScheduledReportResellerComponent } from 'src/app/scheduled-report-reseller/scheduled-report-reseller.component';
import { CreditDebitReportResellerComponent } from 'src/app/credit-debit-report-reseller/credit-debit-report-reseller.component';
import { AgentSupportNewComponent } from 'src/app/agent-support-new/agent-support-new.component';
import {KarixTemplateMasterComponent} from "../../karix-template-master/karix-template-master.component";
import {AccountOnKarixComponent} from "../../account-on-karix/account-on-karix.component";
import { TermAndConditionComponent } from 'src/app/term-and-condition/term-and-condition.component';



const waofficial = environment.whatsappOfficialservice;
const cigarateBrand = environment.cigarateOfficialService;
const cigarateBrandC4 = environment.cigarateOfficialC4Service;
const accounts = environment.accountMicroverservice;
const gsmgateway = environment.gsmgatewayservice;
const obdcampaign = environment.digimozoobdCampaign;
const hdfc = environment.hdfc;
const longcodenr = environment.longcodenrService;
const longcodews = environment.longcodewsService;
const longcodenrct = environment.longcodenrctService;
const waunofficial = environment.whatsappunOfficialservice;
const appDeploye = environment.appDeployement;
const chatbot = environment.chatbot;
const waterconsumption = environment.waterConsumption;
const longcodendt = environment.NDT_CAMPAIGN;
const customapp = environment.customappurl
const registion_otp = environment.REGISTRATIO_OTP;
const registration_kk = environment.REGISTRATIO_KK;
const ZNPD_CAMPAIGN = environment.ZNPD_CAMPAIGN;


const daOne = environment.daoneofficialService;
const routes: Routes = [
  {path: '', component: IndexComponent, canActivate: [AuthGuard]},
  {path: 'signup', component: ClientSignUpComponent},
  {path: 'otpverification', component: OtpVerificationComponent}
  , {path: waofficial + 'dashboard', component: WaofficialdashboardComponent, canActivate: [AuthguardDashboard]}
  , {path: accounts + 'user', component: UserMasterComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'account', component: AccountMasterComponent, canActivate: [AuthGuard]}
  , {path: waunofficial + 'drive', component: DriveComponent}
  , {path: accounts + 'service', component: ServicemasterComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'permissionentry', component: PermissionentryComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'menu', component: MenumasterComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'module', component: ModuleMasterComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'reselleraccount', component: ResellerAccountComponent}
  , {path: accounts + 'role', component: RolemasterComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'operation', component: OperationDaoComponent}
  , {path: 'pagepermission', component: FeatureAcessComponent}
  , {path: accounts + 'stopCampaign', component: StopCampaignComponent}
  , {path: waofficial + 'report', component: ReportComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'chat', component: ChatComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'crdr', component: CrdrreportComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'crdrtrans', component: TransactionreportComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'profile', component: ProfileComponent, canActivate: [AuthguardDashboard]}
  , {path: waofficial + 'template', component: WsTemplateComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'cloudtemplatemaster', component: WhatsappCloudTemplateMasterComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'ktemplatemaster', component: KarixTemplateMasterComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'createkaccount', component: AccountOnKarixComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'onetomany', component: OnetomanyComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'campaign', component: CampaignReportComponent, canActivate: [AuthGuard]}
  , {
    path: waofficial + 'incomingmessagereport',
    component: VerifiedIncomingMessageReportComponent,
    canActivate: [AuthGuard]
  }
  , {path: waofficial + 'subscriptionpackagereport', component: SubscriptionPackageReportComponent}
  , {path: waofficial + 'apidocs', component: ApidocsComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'webhook', component: WebhookComponent, canActivate: [AuthGuard]}
  , {path: accounts + 'countrywiserate', component: CountrywiserateComponent}
  , {path: waofficial + 'country', component: CountrymasterComponent}
  , {path: waofficial + 'chatbot', component: ChatbotconfigComponent, canActivate: [AuthGuard]}
  , {path: waofficial + 'manytomany', component: ManytomanyComponent}
  , {path: waofficial + 'campaignschedule', component: CampaignScheduleReportComponent}
  , {path: waofficial + 'conversation', component: ConversationComponent, canActivate: [AuthGuard]}
  , {path: cigarateBrand + 'dashboard', component: CigaratePanelDashComponent, canActivate: [AuthguardDashboard]}
  , {path: cigarateBrandC4 + 'dashboard', component: CigaratePanelDashC4Component, canActivate: [AuthguardDashboard]}
  , {path: cigarateBrand + 'retailer', component: RetailerCashbackComponent, canActivate: [AuthguardDashboard]}
  , {path: longcodews + 'longcodews', component: ShubhLabhcampaignComponent, canActivate: [AuthguardDashboard]}
  , {path: longcodenr + 'longcodenr', component: LongcodenrComponent, canActivate: [AuthguardDashboard]}
  , {
    path: environment.emasolChatbot + 'dashboard',
    component: EmasolchatbotComponent, canActivate: [AuthguardDashboard]
  }
  , {path: daOne + 'dashboard', component: DashboardComponent, canActivate: [AuthguardDashboard]}
  , {path: daOne + 'empreport', component: EmpreportComponent, canActivate: [AuthguardDashboard]}
  , {path: daOne + 'emp', component: EmpmasterComponent, canActivate: [AuthGuard]}
  , {path: daOne + 'product', component: ProductmasterComponent, canActivate: [AuthGuard]}
  , {path: daOne + 'product', component: ProductmasterComponent, canActivate: [AuthGuard]}
  , {path: gsmgateway + 'oppo', component: IncommingSmsReportComponent, canActivate: [AuthGuard]}
  , {path: gsmgateway + 'pricechart', component: PriceChartComponent}
  , {path: gsmgateway + 'outlet', component: OutletComponent}
  , {path: gsmgateway + 'conversation', component: NouritureConversationComponent}
  , {path: gsmgateway + 'freight', component: FreighChartComponent}
  , {path: gsmgateway + 'enquiries', component: EnquiryDetailsComponent}
  , {path: obdcampaign + 'obdCampaignReport', component: OBDComponent}
  , {path: obdcampaign + 'obdCampaignChartReport', component: OBDChartComponent}
  , {path: obdcampaign + 'scratchcardreport', component: ScratchCardReportComponent}
  , {path: obdcampaign + 'scratchcardreport', component: ScratchCardReportComponent}
  , {path: obdcampaign + 'longCodeCampaign', component: LongCodeCampaignComponent}
  , {path: hdfc + 'c2creport', component: C2cReportComponent}
  , {path: hdfc + 'c2cpanel', component: C2cPanelComponent}
  , {path: hdfc + 'supervisor', component: HdfcSupComponent}
  , {path: hdfc + 'employee', component: HdfcEmployeeComponent}
  , {path: hdfc + 'calls', component: HdfcCallsComponent}
  , {path: hdfc + 'report', component: HdfcReportComponent}
  , {path: hdfc + 'newjoinee', component: HdfcNewjoineeComponent}
  , {path: hdfc + 'resigned', component: HdfcResignedComponent}
  , {path: hdfc + 'upload', component: PromptUploadComponent}
  , {path: waunofficial + 'domain', component: DomainMasterComponent}
  , {path: waunofficial + 'instance', component: InstanceMasterComponent}
  , {path: accounts + 'instance', component: InstanceMasterComponent}
  , {path: waunofficial + 'proxy', component: ProxymasterComponent}
  , {path: waunofficial + 'dashboard', component: DashboardunwsComponent}
  , {path: waunofficial + 'onetomany', component: OnetomanywsunComponent}
  , {path: waunofficial + 'campaignreport', component: CampaignReportUnComponent}
  , {path: waunofficial + 'schedulereport', component: ScheduleCampaignReportComponent}
  , {path: waunofficial + 'dlrreport', component: DlrReportComponent}
  , {path: waunofficial + 'manytomany', component: ManytomanywaunComponent}
  , {path: waunofficial + 'apikey', component: ApikeywaunComponent, canActivate: [AuthGuard]}
  , {path: waunofficial + 'incomingmessages', component: IncomingMessagesComponent}
  , {path: waunofficial + 'wacontactgroup', component: WaContactGroupComponent}
  , {path: waunofficial + 'conversation', component: ConversationwaunComponent}
  , {path: 'wacontactgroupmaster', component: WacontactgroupMasterComponent}
  , {path: 'wacontactgroupclient', component: AddClientComponent}
  , {path: longcodenrct + 'longcodebpphp', component: LongcodeBpphpComponent}
  , {path: longcodenrct + 'longcodenrct', component: LongcodenrctComponent}
  , {path: longcodendt + 'ndtcampaign', component: NdtComponent}
  , {path: gsmgateway + 'mailgroup', component: MailgroupComponent}
  , {path: 'groupMaster', component: GroupMasterComponent}
  , {path: gsmgateway + 'sendMail', component: SendMailComponent}
  , {path: gsmgateway + 'mailReport', component: MailreportComponent}
  , {path: gsmgateway + 'allMail', component: AllMailsComponent}
  , {path: gsmgateway + 'scheduleReport', component: ScheduleReportComponent}
  , {path: appDeploye + 'appdeployement', component: ApplicationDeployementComponent}
  , {path: 'datePicker', component: DatePickerComponentComponent}
  , {path: gsmgateway + 'signatura', component: SignutraComponent}
  , {path: waunofficial + 'resslerReport', component: ClientReportComponent}
  , {path: chatbot + 'chatbotmaster', component: ChatbotMasterComponent}
  , {path: accounts + 'resellerstats', component: ResellerStatsComponent}
  , {path: gsmgateway + 'longcodeconfig', component: UrlConfigComponent}
  , {path: gsmgateway + 'bunglowmaster', component: NbccComponent}
  , {path: gsmgateway + 'bungloreport', component: NbccComponent}
  , {path: gsmgateway + 'slabMaster', component: SlabMasterComponent}
  , {path: gsmgateway + 'bungalowHistory', component: BungalowHistoryComponent}
  , {path: gsmgateway + 'paymentstatus', component: PaymentStatucComponent}
  , {path: 'payment', component: PaymentComponent}
  , {path: 'initiatechatbot', component: InitiateChatbotComponent}
  , {path: accounts + 'creditdebit', component: CreditDebitReportComponent}
  , {path: accounts + 'campaignReport', component: CampaignReportResellerComponent}
  , {path: accounts + 'scheduledReport', component: ScheduledReportResellerComponent}
  , {path: accounts + 'creditdebitreseller', component: CreditDebitReportResellerComponent}
  , {path: waunofficial + 'creditdebit', component: CreditDebitReportComponent}
  , {path: waofficial + 'creditdebit', component: CreditDebitReportComponent}
  , {path: waofficial + 'paymentdetails', component: PaymentDetailsComponent}
  , {path: waofficial + 'subscriptiontrans', component: SubscriptionTransactionReportComponent}
  , {path: waofficial + 'onlinesignupreport', component: OnlineSignUpReportComponent}
  , {path: chatbot + 'dragchatbot', component: DragAndDropChatbotComponent}
  , {path: customapp + 'agentmaster', component: AgentMasterComponent}
  , {path: customapp + 'clientmaster', component: ClientMasterComponent}
  , {path: customapp + 'agentclientconversation', component: AgentClientConversationComponent}
  , {path: customapp + 'extensionwiseagent', component: ExtensionWiseAgentComponent}
  , {path: customapp + 'languagewiseagent', component: LanguageWiseAgentComponent}
  , {path: customapp + 'session', component: InteractionSessionComponent}
  , {path: registion_otp + 'registrationForm', component: RegistrationOtpComponent, canActivate: [AuthGuard]}
  , {path: registion_otp + 'dashboard', component: RegistrationOtpComponent, canActivate: [AuthGuard]}
  , {path: registion_otp + 'registrationReport', component: RegistrationComponent, canActivate: [AuthGuard]}
  , {path: registion_otp + 'registrationMaster', component: RegistrationOtpMasterComponent, canActivate: [AuthGuard]}
  , {path: registion_otp + 'registrationMasterData', component: RegistrationMasterComponent, canActivate: [AuthGuard]}
  , {path: ZNPD_CAMPAIGN + 'znpd', component: ZnpdComponent}
  , {path: registration_kk + 'dashboard', component: RegistrationFormComponent, canActivate: [AuthGuard]}
  , {path: registration_kk + 'registrationKKUser', component: RegistrationKKComponent, canActivate: [AuthGuard]}
  , {path: registration_kk + 'masterData', component: RegistrationKKMasterComponent, canActivate: [AuthGuard]}
  , {path: gsmgateway + "reliance", component: IncommingReportComponent}
  , {path: gsmgateway + "uploaddata", component: UploadLongCodeDataComponent}
  , {path: waofficial + "catalogues", component: CatalogueMessageComponent, canActivate: [AuthGuard]}


  , {path: accounts + 'agentsupport', component: AgentSupportNewComponent}

  , {path: accounts + 'campaignReport', component: CampaignReportResellerComponent}
  , {path: accounts + 'scheduledReport', component: ScheduledReportResellerComponent}
  , {path: accounts + 'creditdebitreseller', component: CreditDebitReportResellerComponent}
  , {path: 'onetomanynoauth', component: OnetomanyNoAuthComponent}
  , {path: 'termCondition', component: TermAndConditionComponent}

];

//
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true}),],
  exports: [RouterModule]
})
export class AppRoutingModule {
  // tslint:disable-next-line:typedef
  getRoute() {
    return IndexComponent;

  }

  constructor() {
  }
}

//commit by  monika
