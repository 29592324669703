import { Component, OnInit } from '@angular/core';
import {OperationDaoService} from '../operation-dao.service';
import {FeatureDao} from '../feature-dao';
import {PageDao} from '../page-dao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import * as $ from 'jquery';
import {MatDialog} from "@angular/material/dialog";
import {FeatureAcessComponent} from "../../feature-acess/feature-acess.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-operation-dao',
  templateUrl: './operation-dao.component.html',
  styleUrls: ['./operation-dao.component.css']
})
export class OperationDaoComponent implements OnInit {
  addNewDiv: boolean = false;
  addNewDiv1: boolean = false;
  isInsert: boolean = true;
  accountId: number;
  pageName: string;
  tableListes: any;
  tableList: Array<FeatureDao>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};

  constructor(private service: OperationDaoService, private alert: AlertMessageService, private dialog: MatDialog) {
  }


  pageDao: PageDao =
    {
      id:null,
      create: false,
      read:false,
      update:false,
      delete:false,
      template:false,
      file:false,
      text:false,
      accountId:null,
      pageName:'',
      download:false
    };
  data: FeatureDao =
    {
      id:null,
      pageName:'',
      description:'',
      menu:'',
      type:this.pageDao,
      accountId:null,
      active:false
    };

  ngOnInit(): void {
    this.getAllFeatures()
  }

  getAllFeatures() {
    this.service.getFeaurs().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
      console.log("Features Dao:: {}", value)
    })
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  edit(data: any, ind: number) {

    this.service.getbyPageNameAndAccountId(data.accountId, data.pageName).subscribe(value => {
      console.log("getByidandpage", value);
      if (value != null) {
        this.pageDao = value;
        this.isInsert = false;
        console.log("if block call");
      }
    });


    console.log('data.accountId:: {}', data.accountId);
    this.pageDao.accountId = data.accountId;
    this.pageDao.pageName = data.pageName;
    this.addNewDiv1 = true;
  }

  cancel() {
    this.addNewDiv = false;
    this.addNewDiv1 = false;
    this.pageDao = null;
    this.data = null;
  }

  createOrUpdate() {
    this.updateOrIns(this.data, this.isInsert, true);

  }

  async updateOrIns(data: FeatureDao, isInsert: boolean, showConfirmMessage: boolean) {

    console.log('will update FeatureDao {}', data);
    if (showConfirmMessage) {
      let message = 'Update page?';
      if (isInsert) {
        message = 'Create page?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }

    if (isInsert) {
      this.service.addFreatures(this.data).subscribe(value => {
        console.log("Inserted Data", value)
        if (showConfirmMessage) {


          this.alert.showSuccesMessage('Successfully Created !!');
        }
        this.cancel();

      });
    } else {
      this.service.update(this.data).subscribe(value => {
        console.log("updated Data", value)
        if (showConfirmMessage) {

          this.alert.showSuccesMessage('Successfully Updated !!');
        }
        this.cancel();

      });
    }
  }

  isOrUpdate() {

    this.service.addFreatures(this.data).subscribe(value => {
      console.log("Inserted Data", value)
    })

  }

  addNew() {

    this.addNewDiv = true
  }

  createOrUpdatePage() {

    this.updateOrInsPage(this.pageDao, this.isInsert, true);


    // console.log("this.pageDao", this.pageDao);
    // this.service.updatePage(this.pageDao).subscribe(value => {
    //   console.log("Inserted Data", value)
    // });
  }


  async updateOrInsPage(data: PageDao, isInsert: boolean, showConfirmMessage: boolean) {

    console.log('will update PageDao {}', data);
    if (showConfirmMessage) {
      let message = 'Update page?';
      if (isInsert) {
        message = 'Create page?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }

    if (isInsert) {
      this.service.addPagePErmission(data).subscribe(value => {
        console.log("Inserted Data", value)
        if (showConfirmMessage) {


          this.alert.showSuccesMessage('Successfully Created !!');
        }
        this.cancel();

      });
    } else {
      this.service.updatePage(data).subscribe(value => {
        console.log("updated Data", value)
        if (showConfirmMessage) {

          this.alert.showSuccesMessage('Successfully Updated !!');
        }
        this.cancel();

      });
    }
  }

  delete(id: number) {
    console.log("data id:: {}", id)
    this.alert.showLoader();
    this.alert.confirmMessage("Delete data", "Are you sure? ").then(value => {
      this.service.delete(id).subscribe(value => {
        console.log(value);
        this.alert.showSuccesMessage("Data Deleted Successfully!! ")
        this.alert.hideLoader();
        this.cancel();

      });
    });
  }

  editDao(data: FeatureDao, ind: number) {

    this.isInsert = false;
    const dialogRef = this.dialog.open(FeatureAcessComponent,{
      width:"50%",
      data:data,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result!=1)
      {
        this.data=result;
        this.updateOrIns(this.data, this.isInsert, true);
      }});





    this.addNewDiv = true;


    this.data = data;
    console.log("updated data::", this.data);
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();

  }


  addPage() {
    const dialogRef = this.dialog.open(FeatureAcessComponent,{
      width:"50%"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result!=1)
      {
        this.data=result;
        this.updateOrIns(this.data, this.isInsert, true);
      }});
  }


}
