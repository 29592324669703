import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Services/User/user.service';
import {UserMasterDao} from '../../Services/User/UserMasterDao';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  userDao:UserMasterDao=this.user.user;
  fullname:string=this.userDao.fullname;
  phn:string=this.userDao.phone;
  email:string=this.userDao.email;
  password:string;
  constructor(private user:UserService,private userService:UsermasterService,private alert:AlertMessageService) { }

  ngOnInit(): void {
  }

  submitData() {
    this.userService.profileEdit(this.phn,this.email,this.fullname,this.password).subscribe(value => {
      this.alert.showSuccesMessage('Successfully Changed Password!!Please Login In Again');
      this.user.logout();
    },error => {
      this.alert.showErrorMessage(error.error.message);
    });
  }
}
