import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {SubscriptionFilterDao} from "../subscription-package-report/SubscriptionFilterDao";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {FileService} from "../Services/FileUtilsService/FileService";
import {ClientSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {OnlineSignUpServiceService} from "./online-sign-up-service.service";

@Component({
  selector: 'app-online-sign-up-report',
  templateUrl: './online-sign-up-report.component.html',
  styleUrls: ['./online-sign-up-report.component.css']
})
export class OnlineSignUpReportComponent implements OnInit {

  tableList: Array<ClientSignUpDao>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  status: boolean = true;

  filterDao:SubscriptionFilterDao = {
    startdate:'0',
    enddate:'0',
    offset:0,
    limit:10,
    accountId:0,
    groupBy:'',
    dateFilterType: '0',
    services:'',
    status:true
  };
  constructor(private onlineSignUpService:OnlineSignUpServiceService, private alert:AlertMessageService) {
    this.loadData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  filterData() {
    if(this.filterDao.startdate.length != 14 || this.filterDao.enddate.length !=14){
      this.filterDao.startdate = this.filterDao.startdate + '000000';
      this.filterDao.enddate = this.filterDao.enddate + '235959';
    }
    this.filterDao.status = this.status;
    this.loadData();
  }

  ngOnInit(): void {
  }

  loadData(){
    this.alert.showLoader();
    if(this.filterDao.startdate == '0' || this.filterDao.enddate == '0' || this.filterDao.startdate == undefined || this.filterDao.enddate == undefined){
      let date = new Date();
      let monthStr = (date.getMonth() + 1).toString();
      let onlyDate: string = date.getDate().toString();
      if(monthStr.length != 2){
        monthStr = '0' + monthStr ;
      }
      if(onlyDate.length!=2){
        onlyDate = '0' + onlyDate;
      }
      this.filterDao.startdate = date.getUTCFullYear().toString() + monthStr + '01000000';
      this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate + '235959';

    }else{
      this.filterDao.startdate = this.filterDao.startdate.replace('-','').replace('-','')
      this.filterDao.enddate = this.filterDao.enddate.replace("-","").replace('-','');
    }
    $('#tableDiv').DataTable().destroy();
    this.onlineSignUpService.get(this.filterDao).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.filterDao = {
      startdate:'0',
      enddate:'0',
      offset:0,
      limit:10,
      accountId:0,
      groupBy:'',
      dateFilterType: '0',
      services:'',
      status:true
    };
    this.status = true;
    this.loadData();
  }

  downloadReport() {
    this.onlineSignUpService.get(this.filterDao).subscribe(clientSignUpDaoList => {
      let file = new FileService<any>();
      var trArr = [];
      var tdArr = [];
      tdArr.push("Create Date");
      tdArr.push("Account ID");
      tdArr.push("Client Type");
      tdArr.push("Client Name");
      tdArr.push("Client Number");
      tdArr.push("Email");
      tdArr.push("Default Subscription");
      tdArr.push("Whatsapp Number");
      trArr.push(tdArr);
      for (var i = 0; i < clientSignUpDaoList.length; i++) {
        tdArr = [];
        tdArr.push(clientSignUpDaoList[i].createAt);
        tdArr.push(clientSignUpDaoList[i].id)
        tdArr.push(clientSignUpDaoList[i].clientType);
        tdArr.push(clientSignUpDaoList[i].clientName);
        tdArr.push(clientSignUpDaoList[i].clientNumber);
        tdArr.push(clientSignUpDaoList[i].email);
        tdArr.push(clientSignUpDaoList[i].defaultSubscription);
        tdArr.push(clientSignUpDaoList[i].whatsappNumber);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,'Sub_Trans_Report_' + new Date().getDate() + '.xlsx');
    });
  }

}
