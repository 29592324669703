
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()"  class="btn btn-add">Add  Outlet<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Outlet Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.name" name="name" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Adress <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.address" name="address" required>
            </div>

            <div class="form-group col-lg-12">
              <label>State <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.state" name="state" required>
            </div>

            <div class="form-group col-lg-12">
              <label>City <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.city" name="city" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Pincode <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.pincode" name="pincode" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Latitude <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.lat" name="lat" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Longitude <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="outletDao.lng" name="lng" required>
            </div>

           <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>State</th>
              <th>City</th>
              <th>Pincode</th>
              <th>Lat.</th>
              <th>Long.</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="outletList.length==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let outletdao of outletList;let ind =index">
              <tr>
                <td [innerHTML]="outletdao.name"></td>
                <td [innerHTML]="outletdao.address"></td>
                <td [innerHTML]="outletdao.state"></td>
                <td [innerHTML]="outletdao.city"></td>
                <td [innerHTML]="outletdao.pincode"></td>
                <td [innerHTML]="outletdao.lat"></td>
                <td [innerHTML]="outletdao.lng"></td>

                <td><i title="Click Here To Edit" (click)="edit(outletdao)"
                       class="btn btn-add fa fa-pencil"></i>

                  <i title="Click Here To Delete" (click)="delete(outletdao,ind)"
                     class="btn btn-add fa fa-trash"></i>
                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







