import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FilterDao} from "../crdrreport/FilterDao";
import {Observable} from "rxjs";
import {MessageDao} from "../ws-template/MessageDao";
import {environment} from "../../environments/environment.prod";
import {PaginationDao} from "../CommonObj/PaginationDao";

@Injectable({
  providedIn: 'root'
})
export class VerfiedIncomingMessageReportService {

  constructor(private http: HttpClient) { }

  verfiedIncomingMessageReport(filterDao: FilterDao): Observable<PaginationDao<MessageDao>>{
    return this.http.post<PaginationDao<MessageDao>>(environment.appUrl+environment.whatsappOfficialservice+'message/getincomingreport',filterDao);
  }
}
