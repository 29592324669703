import { Component, OnInit } from '@angular/core';

import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {environment} from "../../../../environments/environment.prod";
import {ConversationDao} from "../ChatbotConversationFlow/conversation-flow/ConversationDao";

@Component({
  selector: 'app-conversation-chatbot',
  templateUrl: './conversation-chatbot.component.html',
  styleUrls: ['./conversation-chatbot.component.css']
})
export class ConversationChatbotComponent implements OnInit {

  tableData: Array<ConversationDao> = [];
  offset: number = 0;
  limit: number = 10;
  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }



  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    // this.alert.showLoader();
    this.http.get<Array<ConversationDao>>(environment.chatbotBaseUrl + 'conversation/getall?start=' + this.offset + '&length=' + this.limit).subscribe(value => {

      this.tableData = value;
      //this.tableData.total = value.length;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }
}
