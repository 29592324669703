<form style="width: 350px" ngNativeValidate (submit)="filterData()">

  <h1 mat-dialog-title style="text-align: center">Filter <i class="fa fa-filter"></i> </h1>

  <mat-dialog-content class="mat-typography">
  <div *ngIf="data.filter.services!=null" class="form-group col-lg-12">
    <label >Service <sup class="required">*</sup></label>
    <div class="col-sm-12">
      <mat-radio-group [(ngModel)]="data.filter.services" name="service"  aria-label="Select an option">
        <ng-container *ngFor="let ser of data.service">
          <mat-radio-button [value]="ser" >{{ser}}</mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-group col-lg-12">
    <label>Date Filter Mode  <sup class="required">*</sup></label>
    <div class="col-sm-12">
      <mat-radio-group [(ngModel)]="data.filter.dateFilterType" name="dateFilterType"  aria-label="Select an option">
        <ng-container *ngFor="let date of data.datefilterType">
          <mat-radio-button [value]="date" >{{date}}</mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
  <ng-container *ngIf="data.filter.dateFilterType=='CUSTOM'">
  <div class="form-group col-lg-6">
    <label>Start Date  <sup class="required">*</sup></label>
    <input type="date" class="form-control" name="startdate" [(ngModel)]="data.filter.startdate">
  </div>
  <div class="form-group col-lg-6">
    <label>End Date  <sup class="required">*</sup></label>
    <input type="date" class="form-control" name="enddate" [(ngModel)]="data.filter.enddate">
  </div>
  </ng-container>
</mat-dialog-content>
  <mat-dialog-actions>
  <div class="reset-button"></div>
    <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>
    <button   type="submit" mat-dialog-close class="btn btn-add pull-right">Filter <i class="fa fa-filter"></i> </button>
  </mat-dialog-actions>
</form>
