<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
                <div class="panel-title">
                </div>
            </div>
            <div style="" class="panel-body">

                <div id="tablediv">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th>Uid</th>
                                <th>Asm</th>
                                <th>Promoter Name</th>
                                <th>Mandi Name</th>
                                <th>WS Name</th>
                                <th>Region</th>
                                <th>City</th>
                                <th>DB Code</th>
                                <!--              <th>Note Book </th>-->
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="text-align: center" *ngIf="tableData.total==0">
                                <td colspan="9">No Entry</td>
                            </tr>
                            <ng-container *ngFor="let user of tableData.data;let ind = index">
                                <tr>
                                    <td [innerHTML]="user.uid"></td>
                                    <td [innerHTML]="user.asmArea"></td>
                                    <td [innerHTML]="user.promoter"></td>
                                    <td [innerHTML]="user.mandiName"></td>
                                    <td [innerHTML]="user.name"></td>
                                    <td [innerHTML]="user.region"></td>
                                    <td [innerHTML]="user.city"></td>
                                    <td [innerHTML]="user.dbCode"></td>
                                    <!--                <td [innerHTML]="user.parchiBook"></td>-->
                                    <!--                  <td [innerHTML]="user.giftHandOver==true?'Yes': 'No' "></td>-->
                                    <td></td>
                                </tr>
                            </ng-container>


                            </tbody>
                        </table>
                    </div>
                    <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                                   [pageSize]="10"
                                   [pageSizeOptions]="[2, 5,10, 25, 100]">
                    </mat-paginator>

                </div>

            </div>
        </div>
    </div>
</div>

