

<div class="row" style="border:  10px solid #1c5b7a">
  <div class="col-sm-12">
    <div class="mailbox">
      <div class="mailbox-header">
        <div class="row">
          <div class="col-xs-3">



          </div>
          <div class="col-xs-9">
            <div style="width: 20%;float: left" class="inbox-avatar">
              <i class="fa fa-user-circle fa-lg"></i>
              <div class="inbox-avatar-text hidden-xs hidden-sm">
                <div class="avatar-name" style="font-size: 20px" [innerHTML]="this.activeClientName || this.activeClientNumber"></div>

              </div>
            </div>
            <div style="width: 80%;float: right" class="inbox-toolbar btn-toolbar pull-right">
              <div class="pull-right">

                <label>Agent</label>
                <select class="form-control" [(ngModel)]="activeAgentId" (change)="onSelectAgent()"
                        name="ativeInstance">
                  <option value="undefined">---Select Agent---</option>
                  <ng-container *ngFor="let activeInstance of agentMasterList.data">

                    <option [ngValue]="activeInstance.agentNumber" [innerHTML]="activeInstance.agentNumber+' '+activeInstance.agentName"></option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mailbox-body">
        <div class="row m-0">
          <div class="col-sm-3 p-0 inbox-nav ">


            <!--            =====================================Start================================-->
            <!--                        <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport mailbox-sideber">-->

            <div style="max-height: 500px;overflow: scroll" class="mailbox-sideber example-viewport">
              <div class="profile-usermenu">
                <ul *ngIf="this.customerList!=null" class="nav">
                  <li>
                    <input [(ngModel)]="searchStr" (keyup)="searchString(searchStr)" name="searchStr" type="text"
                           class="form-control" placeholder="Search....">
                  </li>

                  <ng-container *ngFor="let customer of this.filteredCustomerList  ;let ind =index">
                    <li>
                      <a (click)="loadMyConversation(customer.customerName,customer.customerNumbers)"> <i class="fa fa-user-circle"></i>
                        <b>{{customer.customerNumbers}} {{customer.customerName}}</b> </a>
                    </li>
                  </ng-container>
                  <!--                  <mat-paginator (page)="tableChange($event,conversationList)" [length]=conversationList.total-->
                  <!--                                 [pageSize]="10"-->
                  <!--                                 [pageSizeOptions]="[10]">-->
                  <!--                  </mat-paginator>-->
                </ul>
                <hr>
              </div>
            </div>

            <!--                        </cdk-virtual-scroll-viewport>-->
            <!--            ============================End======================================-->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-9 p-0 inbox-mail conversation">
            <div id="chatconversationdiv"
                 style="max-height: 500px;min-height: 500px;overflow: scroll;overflow-x: hidden"
                 class="mailbox-content">
              <ng-container *ngIf="conversationList.length==0">
                <h3 style="text-align: center;margin-top: 200px"><b>No Contact Selected</b></h3>
              </ng-container>

              <ng-container *ngFor="let conversation of conversationList">
                <app-agent-client-conversation-obj [conversation]="conversation"></app-agent-client-conversation-obj>
              </ng-container>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
