<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu"  >
    <div class="panel panel-bd lobidragdashboard">
      <div  class="panel-heading" >

        <div class="header-title" >
          <h1>WhatsApp Clients    <i (click)="downloadSampleFile()" title="Click Here to Download Sample File" style="color: red" class="fa fa-question-circle"></i></h1>
          <button id="addnewgroup"  (click)="addClient()" title="Add New Group" class="btn btn-add">Add Client <i class="hvr-buzz-out fa fa-plus"></i></button>
          <button   title="Add New Group" id="bulkipload"  data-toggle="modal" data-target="#bulkuploadfile" class="btn btn-add">Bulk Upload <i class="hvr-buzz-out fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  class="table-responsive"  >
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">


          <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Email</th>
                <th>Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let group of tableList1;let ind =index">
                <tr>
                  <td [innerHTML]="group.clientName"></td>
                  <td [innerHTML]="group.phonenumber"></td>
                  <td  [innerHTML]="group.email"></td>
                  <td  [innerHTML]="group.address"></td>
                  <td  [innerHTML]='group.status ==1?"<i style=\"margin-left: 5px;  font-size: 18px;color: green\"   class=\"hvr-buzz-out fa fa-check\"></i>":"<i style=\" margin-left: 5px;  font-size: 18px;color: red\"    class=\"hvr-buzz-out fa fa-ban\"></i>"'></td>
                  <td>
                     <button  type="button"  id="edit" class="btn btn-add" (click)="addClient()" data-toggle="modal" data-target="#exampleModal" title="Edit Group Name"><i class="fa fa-edit"></i></button>
                    <button  type="button"  id="delete" class="btn btn-add"  style="background-color: crimson" (click)="delete(group.id)"  title="Delete Group "><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
<!--</div>-->




<div class="modal fade" id="bulkuploadfile" tabindex="-1" aria-labelledby="exampleModalLabel11" aria-hidden="true" style="margin-top: 50px">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
         <div class="modal-body">
          <form  [formGroup] = "uploadForm" ngNativeValidate    class="form-horizontal"  (ngSubmit)="bulkUpload()">
            <div  class="col-md-12 form-group">
              <label class="control-label">Group</label>
              <select class="form-control" name="status" required>
                <ng-container >
                  <option   [ngValue]="groupID" [innerHTML]= "groupName"></option>
                </ng-container>
              </select>
            </div>
            <div class="col-md-12 form-group">
              <label class="control-label">File</label>
              <input required class="form-control" name="file"  required  type="file"   accept=".xlsx" (change)="onFileSelect($event)" placeholder="Enter File To Upload" multiple/>

            </div>



            <div class="reset-button">
              <button  type="submit" class="btn btn-success">Upload</button>
              <button mat-dialog-close type="button" (click)="cancel()"  class="btn btn-warning">Cancel</button>

            </div>


          </form>
        </div>

      </div>
    </div>
  </div>
</div>
