<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <h2>Registration Form</h2>
          <!--          <button *ngIf="addNewDiv" type="button" class="btn btn-add" (click)="downloadReport()">Download <i class="fa fa-download"></i></button>-->


        </div>
      </div>
      <div class="panel-body">
        <div class="col-lg-2"></div>
        <form ngNativeValidate class="form-group col-lg-10" (ngSubmit)="insert()">
          <div class="form-group col-lg-8">
            <label class="control-label" for="mandiName">Mandi Name: <sup class="required">*</sup></label>

            <select class="form-control" id="mandiName" name="mandiName" required
                    [(ngModel)]="this.registrationDao.mandiName" (change)="getWsName($event.target.value)">
              <option value="" [selected]="">Select Mandi Name</option>
              <option [value]="data" *ngFor="let data of listOfMandiName">{{data}}</option>
            </select>
          </div>

          <div class="form-group col-lg-8">
            <label class="control-label" for="wsName">Ws Name </label>
            <select class="form-control" id="wsName" name="wsName" [(ngModel)]="this.registrationDao.wsName">
              <option value="" [selected]="">Select Mandi Name</option>
              <option [value]="data" *ngFor="let data of listOfWs">{{data}}</option>
              <option value="others">Others</option>
            </select> <br>

            <input *ngIf="registrationDao.wsName=='others' " type="text" class="form-control"
                   placeholder="other ws name" required
                   [(ngModel)]="this.registrationDao.otherWsName" name="othersWsName">
          </div>

          <div class="form-group col-lg-8">
            <label class="control-label" for="retailerName">Retailer Name.<sup class="required">*</sup></label>
            <input type="text" placeholder="Please Enter Retailer Name" title="Please Enter Retailer Name" required
                   name="retailerName" id="retailerName" class="form-control"
                   [(ngModel)]="this.registrationDao.retailerName">
          </div>


          <div class="form-group col-lg-8">
            <label class="control-label" for="retailerNumber">Retailer Phone Number <sup
              class="required">*</sup></label>
            <input type="number" placeholder="Enter Retailer Phone Number " title="Please Enter Retailer Phone Number "
                   required
                   name="retailerNumber"
                   id="retailerNumber" class="form-control" [(ngModel)]="this.registrationDao.retailerMobile">
          </div>


          <div class="form-group col-lg-8">
            <label class="control-label" for="retailerAddress">Retailer Address <sup class="required">*</sup></label>
            <textarea type="text" placeholder="Enter Retailer Address" title="Please Enter Previous Reading"
                      name="retailerAddress" id="retailerAddress" class="form-control"
                      [(ngModel)]="registrationDao.address" required></textarea>
          </div>


<!--          <div class="form-group col-lg-8">-->
<!--            <label class="control-label" for="prachiBook">Note Book.<sup class="required">*</sup></label>-->
<!--            <input type="text" placeholder="Enter Note Book" title="Please Enter Note Book" required-->
<!--                   name="prachiBook" id="prachiBook" class="form-control" [(ngModel)]="registrationDao.parchiBook">-->
<!--          </div>-->

          <div class="form-group col-lg-8">
            <label class="control-label" for="billAmount">Bill Amount </label>
            <input type="number" placeholder="Enter Bill Amount" title="Please Enter Bill Amount"
                   name="billAmount" id="billAmount" class="form-control" [(ngModel)]="registrationDao.billAmount">
          </div>


          <div class="form-group col-lg-8">
            <label class="control-label">Gift Handed Over.<sup class="required">*</sup></label><br>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="registrationDao.giftHandOver" name="status">
              <mat-radio-button [value]="1" [checked]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="0">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form-group col-lg-8" *ngIf="registrationDao.giftHandOver==true">
            <label class="control-label" for="gift">Gift </label>
            <select class="form-control" id="gift" name="gift" [(ngModel)]="registrationDao.gift" required>
              <option value="">Select Gift</option>
              <option value="Boro plus cream 5 ml">Boro plus cream 5 ml</option>
              <option value="Navratna Telcum Rs.10"> Navratna Telcum Rs.10</option>
              <option value="Navratna Telcum Rs.20">Navratna Telcum Rs.20</option>
              <option value="3 PC of 8 gm">3 PC of 8 gm</option>
              <option value="1 PC of 15 gm">1 PC of 15 gm</option>
              <option value="5 PCs of FAH Cream 8 gm">5 PCs of FAH Cream 8 gm</option>
              <option value="1 PC of FAH Cream 15gm + 1 PC 8 gm">1 PC of FAH Cream 15gm + 1 PC 8 gm</option>
              <option value="2 PC FAH Cream 8 gm">2 PC FAH Cream 8 gm</option>
              <option value="3 PC FAH Cream 8 gm">3 PC FAH Cream 8 gm</option>
              <option value="1 PC FAH Cream 15 gm + 1 PC FAH Cream 8 gm">1 PC FAH Cream 15 gm + 1 PC FAH Cream 8 gm</option>
              <option value="1 FAH Cream 8 gm"> 1 FAH Cream 8 gm</option>
              <option value="1 PC of 30gm F&H cream"> 1 PC of 30gm F&H cream</option>
            </select>
          </div>

          <div class="form-group col-lg-8" *ngIf="registrationDao.giftHandOver==true">
            <label class="control-label" for="volumeOfGift">Volume of Gift . </label>
            <input type="text" placeholder="Enter Volume of Gift " title="Please Enter Volume of Gift "
                   name="volumeOfGift" id="volumeOfGift" class="form-control"
                   [(ngModel)]="registrationDao.volumeOfGift" required>
          </div>



<!--          <div class="form-group col-lg-8">-->
<!--            <input class="form-control" placeholder="Coupon No 1" [(ngModel)]="coupons1" name="coupons1"><br>-->
<!--            <input class="form-control" placeholder="Coupon No 2" [(ngModel)]="coupons2" name="coupons2"><br>-->
<!--            <input class="form-control" placeholder="Coupon No 3" [(ngModel)]="coupons3" name="coupons3"><br>-->
<!--            <input class="form-control" placeholder="Coupon No 4" [(ngModel)]="coupons4" name="coupons4"><br>-->
<!--            <input class="form-control" placeholder="Coupon No 5" [(ngModel)]="coupons5" name="coupons5"><br>-->

<!--            &lt;!&ndash;            <button type="button" class="btn btn-add" (click)="showInputField()">+ Click to add More</button>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div id="inputContainer">&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->


<!--          <div class="col-sm-8 center-block" *ngIf="!sumbitBtnEnabled">-->
<!--            <button type="submit" class="btn btn-add btn-sm" (click)="sendOtp()">Get OTP</button>-->
<!--          </div>-->

<!--          <div class="col-sm-8 center-block" *ngIf="sumbitBtnEnabled">-->
<!--            <label class="control-label">Otp.<sup class="required">*</sup></label><br>-->
<!--            <input class="form-control" type="number" placeholder="Enter Otp" [(ngModel)]="registrationDao.otp"-->
<!--                   name="otp" required> <br>-->
<!--            <button *ngIf="showButton" type="submit" class="btn btn-add pull-right" (click)="sendOtp()">Resend OTP-->
<!--            </button>-->
<!--          </div>-->

          <br>

          <div class="col-sm-8 pull-right" >
            <button type="submit" class="btn btn-add btn-sm">Submit</button>
          </div>

        </form>

        <div class="col-lg-2"></div>

      </div>
    </div>
  </div>
</div>



