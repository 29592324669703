import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FilterDao} from "../crdrreport/FilterDao";
import {Observable} from "rxjs";
import {Chatbot} from "./Chatbot";
import {PaginationDao, PaginationMain} from "../CommonObj/PaginationDao";
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class DragAndDropChatbotService {

  constructor(private http: HttpClient) {
  }


  getReport(accountId:number,offset:number,limit:number): Observable<PaginationMain<Chatbot>> {
    // return this.http.get<PaginationMain<Chatbot>>("http://localhost:8080/v1/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<PaginationMain<Chatbot>>(environment.appUrl+environment.dragdropChatbotApiUrl+"/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);
  }
  getbyid(id:number): Observable<any> {
    // http://localhost:8080/

    return this.http.get<Chatbot>(environment.appUrl+environment.dragdropChatbotApiUrl+"/chatbot/");

  }
//

}
