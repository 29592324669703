import { Component, OnInit } from '@angular/core';
import {CampaingService} from "../CampainService/campaing.service";
import {Subject} from "rxjs";
import {CampaignDao} from "../onetomany/CampaignDao";
import {AlertMessageService} from "../alert-message/alert-message.service";

@Component({
  selector: 'app-campaign-schedule-report',
  templateUrl: './campaign-schedule-report.component.html',
  styleUrls: ['./campaign-schedule-report.component.css']
})
export class CampaignScheduleReportComponent implements OnInit {
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  tableList:Array<CampaignDao>=null;

  constructor(private campaingService:CampaingService,private alert:AlertMessageService) {
    this.loadData();
  }

  ngOnInit(): void {
  }
  loadData() {
    this.campaingService.getCampaigns().subscribe(value => {
      console.log("value:", value)

      this.tableList = value;
      this.dtTrigger.next();
    })
  }

  deleteSchedule(id: number) {
    this.alert.confirmMessage('Delete Campaign','Are You Sure?').then(value => {
      this.campaingService.deleteScheduledCampaign(id).subscribe(response => {
        this.alert.showSuccesMessage('Campaign Deleted!');
        this.cancel();
      });
    })

  }

  private cancel() {

  }
}
