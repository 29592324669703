import {Component, OnInit} from '@angular/core';
import {EmpMasterDao} from './EmpMasterDao';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {environment} from '../../../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {ExcelReaderCommonComponent} from '../../../../CommonPopups/excel-reader-common/excel-reader-common.component';
import {UserMasterDao} from '../../../../Services/User/UserMasterDao';
import {UsermasterService} from '../../../../Services/UsermasterService/usermaster.service';
import {UserService} from '../../../../Services/User/user.service';

@Component({
  selector: 'app-empmaster',
  templateUrl: './empmaster.component.html',
  styleUrls: ['./empmaster.component.css']
})
export class EmpmasterComponent implements OnInit {
  tableList: Array<EmpMasterDao> = [];

  managerId: number ;
  userList: Array<UserMasterDao> = [];
  update = false;

  startdate: number = undefined;
  enddate: number = undefined;

  empMasterDao: EmpMasterDao = {
    empId: 0,
    empName: '',
    empRole: '',
    hq: '',
    id: 0,
    mobile: '',
    reportTo: 0,
    team: '',
    updateBy: '',
    updateDate: '',
    zone: ''
  };
  addNewDiv: boolean = false;

  constructor(private user:UsermasterService,private userData:UserService,private alert: AlertMessageService, private router: Router, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {

      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      if(this.userData.user.accountType=="ADMIN") {
        this.user.getAll().subscribe(value => {
          this.userList = value;

          this.getData();
        });
      }else{
        this.userList.push(this.userData.user);

        this.getData();
      }



    });
  }

  addNew() {
    this.update = false;
    this.addNewDiv = true;
  }

  isOrUpdate() {
    this.alert.showLoader()
    var message="Create User?"
    var successMessage="Successfully Create User!";
    if(this.update)
    {
      successMessage="Successfully Updated User!";
      message="Update User?"
    }
    this.empMasterDao.reportTo=this.managerId;
    this.alert.confirmMessage("Are you sure?",message).then(value => {
      this.http.post<EmpMasterDao>(environment.appUrl + environment.gsmgatewayservice + 'hcns/master/single',this.empMasterDao).subscribe(value => {
      this.empMasterDao=value;
      this.alert.showSuccesMessage(successMessage);
      this.alert.hideLoader();

    },error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage("Duplicate Employee!!")
      })
    })


  }

  cancel() {
    this.update = false;
    this.addNewDiv = false;
    this.getData();
  }

  edit(empMasterDao: EmpMasterDao, ind: number) {
    this.empMasterDao = empMasterDao;
    this.update = true;
    this.addNewDiv = true;

  }

  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      if(value==null)
      {
        return;
      }

        let al:Array<EmpMasterDao>=[]
        for(var i=0;i<value.length;i++)
        {
          al.push({
            empId: value[i]["Emp ID"],
            empName: value[i]["Name"],
            empRole: value[i]["Role"],
            hq: value[i]["HQ"],
            id: 0,
            mobile: value[i]["Mobile Number"],
            reportTo:Number( value[i]["Reporting to"]),
            team: value[i]["Team"],
            updateBy: '',
            updateDate: '',
            zone: value[i]["Zone"],
          })
        }
        this.alert.confirmMessage("Are you sure?","Upload "+al.length+" Employees?").then(asdasd => {
          this.alert.showLoader();
          this.http.post<Array<string>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/master/',al).subscribe(alret => {

            let failed:number=0;
            for(var i=0;i<alret.length;i++)
            {
              if(alret[i]=="NA")
              {
                failed++;
              }
            }

            this.alert.hideLoader();
            this.alert.showSuccesMessage("Successfully Uploaded , Failed "+failed)
            this.getData();

          },error => {
            this.alert.hideLoader();
            this.alert.showErrorMessage("Failed To Upload!!")
          })
        })

    })

  }

  filter() {
    this.dialog.open(DatefilterComponent);
  }

  downloadSampleFile() {

    var trArr = [];
    var tdArr = [];
    tdArr.push('Team');
    tdArr.push('Emp ID');
    tdArr.push('Name');
    tdArr.push('Role');
    tdArr.push('HQ');
    tdArr.push('Zone');
    tdArr.push('Mobile Number');
    tdArr.push('Reporting to');
    trArr.push(tdArr);




    let file = new FileService<any>();
    file.generateFile(trArr, 'SampleEmpUpload.xlsx');
  }

  getData() {
    this.alert.showLoader();
    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = 20101010;
      this.enddate = 20301010;
    }
    if(this.managerId==undefined)
    {
      this.managerId=this.userData.user.id;
    }
    this.http.get<Array<EmpMasterDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/master/?startdate=' + this.startdate + '&enddate=' + this.enddate+"&id="+this.managerId).subscribe(value => {
      this.tableList = value;
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  delete(template: EmpMasterDao, ind: number) {
    this.alert.confirmMessage('Are you Sure?', 'Delete ' + template.empName + ' (All Records Will be deleted to)? ').then(value => {
      this.http.delete(environment.appUrl + environment.gsmgatewayservice + 'hcns/master/?id=' + template.id).subscribe(value => {
        this.alert.showSuccesMessage("Successfully deleted!")
        this.getData();



      });
    });
  }
}
