<h1 mat-dialog-title style="text-align: center">Filter <i class="fa fa-filter"></i> </h1>



<form ngNativeValidate (submit)="filter()">

  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Startdate  <sup class="required">*</sup></label>
      <input type="date" class="form-control" [(ngModel)]="this.startdate" name="startdate" required>
    </div>
    <div class="form-group col-lg-12">
      <label>Enddate  <sup class="required">*</sup></label>
      <input type="date" class="form-control" [(ngModel)]="this.enddate" name="enddate" required>
    </div>



  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  [mat-dialog-close]="null" class="btn btn-success"  type="submit">Filter <i class="fa fa-filter"></i></button>
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

  </mat-dialog-actions>
</form>
