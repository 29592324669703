import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {HDFCampDao} from "../c2c-report/HDFCampDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../environments/environment.prod";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {HDFCDao} from "../HDFCDao";

@Component({
  selector: 'app-hdfc-sup',
  templateUrl: './hdfc-sup.component.html',
  styleUrls: ['./hdfc-sup.component.css']
})
export class HdfcSupComponent implements OnInit {


  tableData: PaginationDao<HDFCDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public searchval:string="0";
  public startdateStr:string="0";
  public enddateStr:string="0";


  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<PaginationDao<HDFCDao>>(environment.hdfcBaseUrl + 'hdfc/getsup?searchval='+this.searchval+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {

      this.tableData = value;
      //this.tableData.total = value.length;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  filterData() {

  }


  clearFilter() {
    this.searchval ="0"
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {

  }


  download() {
    this.http.get<Array<HDFCDao>>(environment.hdfcBaseUrl + 'hdfc/getsup?searchval='+this.searchval+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit).subscribe(value => {
      this.tableData.data = value;
      this.tableData.total = value.length;
      var data = value;
      var trArr = [];
      var tdArr =["Emp Name","Emp Code","Emp Mobile","Sup Name","Sup Code","Sup Mobile","Request Time","Call Time","Call Status","Call Connect Time","Call Disonnect Time","Durration","Pulses","Id Status","Laptop Status","Attorney Status","Status"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].emp_Name)
        tdArr.push(data[i].emp_Code)
        tdArr.push(data[i].emp_Number)
        tdArr.push(data[i].supervisor_Name)
        tdArr.push(data[i].supervisor_code)
        tdArr.push(data[i].msisdn)
        tdArr.push(data[i].request_Time)
        tdArr.push(data[i].call_Time)
        tdArr.push(data[i].call_Reason)
        tdArr.push(data[i].call_Connecttime)
        tdArr.push(data[i].call_Disconnecttime)
        tdArr.push(String(data[i].call_Duration))
        tdArr.push(String(data[i].pulses))
        // @ts-ignore
        tdArr.push((data[i].id_status  == 1)?"Matched":"Mismatched")
        // @ts-ignore
        tdArr.push((data[i].laptop_status == 1)?"Matched":"Mismatched")
        // @ts-ignore
        tdArr.push((data[i].attorney_status == 1)?"Matched":"Mismatched")
        tdArr.push((data[i].flag == 1)?"Complete":"Pending")
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }


}
