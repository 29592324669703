
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i>
          </button>
          <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i>
          </button>
          <button (click)="downloadReport()" class="btn btn-add">Download File<i class="fa fa-download"></i>
          </button>
        </div>
      </div>
      <div style="" class="panel-body">

        <div id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Id </th>
                <th>RetailerUid</th>
                <th>PhoneNumber</th>
                <th>Status</th>
                <th>Date</th>
                <th>Region</th>
                <th>ASMArea</th>
                <th>Dbcode</th>
                <th>DistributorName</th>
                <th>MinSlab</th>
                <th>RetailerName</th>
                <th>Channel</th>
                <th>Depotname</th>
                <th>States</th>
                <th>textMessages</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.total==0">
                <td colspan="9">No Entry</td>
              </tr>
              <ng-container *ngFor="let user of tableData.data;let ind = index">
                <tr>
                  <td [innerHTML]="user.id"></td>
                  <td [innerHTML]="user.uid"></td>
                  <td [innerHTML]="user.phonenumber"></td>
                  <td  [innerHTML]='user.status'></td>
                  <td [innerHTML]="user.date"></td>
                  <td [innerHTML]="user.region"></td>
                  <td [innerHTML]="user.asmArea"></td>
                  <td [innerHTML]="user.dbCode"></td>
                  <td [innerHTML]="user.distributorName"></td>
                  <td [innerHTML]="user.slab"></td>
                  <td [innerHTML]="user.retailerName"></td>
                  <td [innerHTML]="user.channel"></td>
                  <td [innerHTML]="user.depotName"></td>
                  <td [innerHTML]="user.states"></td>
                  <td [innerHTML]="user.textMessages"></td>
                  <td></td>
                </tr>
              </ng-container>


              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5,10, 25, 100]">
          </mat-paginator>

        </div>

      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>


