<h1 mat-dialog-title style="text-align: center">Recharge <i class="fa fa-money"></i></h1>

<mat-dialog-content class="mat-typography">
  <div>
    <form ngNativeValidate (submit)="createRecharge()">
      <div class="form-group-12">
        <div class="form-group col-sm-6">
          <label>Select Recharge Type <sup class="required">*</sup></label>

          <select class="form-control" [(ngModel)]="rechargeDao.rechareType" name="rechargeType">
            <option value="RECHARE">Credit</option>
            <option value="REVERSERECHARE">Debit</option>
          </select>
        </div>

        <div class="form-group col-sm-6">
          <label>Select Service <sup class="required">*</sup></label>
          <select class="form-control" [(ngModel)]="rechargeDao.service" name="service">
            <option value="WHATSAPPOFFICIAL">WHATSAPPOFFICIAL</option>
            <option value="WHATSAPPUNOFFICIAL">WHATSAPPUNOFFICIAL</option>
            <option value="RESELLER">RESELLER</option>
          </select>
        </div>

        <div class="form-group col-sm-6">
          <label>Amount <sup class="required">*</sup></label>
          <input type="text"  (keypress)="decimalFilter($event)" class="form-control" placeholder="Amount" name="amount" [(ngModel)]="rechargeDao.amount"
                 required>
        </div>

        <div class="form-group col-lg-6">
          <label>Description   <sup class="required">*</sup></label>
          <input type="text" class="form-control" [(ngModel)]="rechargeDao.descri" name="descri" required>
        </div>


      </div>
      <div class="reset-button">

        <button style="float: right" type="submit" class="btn btn-add">Save</button>
        <button style="float: right" class="btn btn-add" mat-dialog-close="null">Close</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

