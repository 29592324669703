import { Component, OnInit } from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {PaginationDao} from "../../../../CommonObj/PaginationDao";
import {IncommingSmsDao} from "../../Oppo/incomming-sms-report/IncommingSmsDao";
import {UserService} from "../../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../../environments/environment.prod";
import {DatefilterComponent} from "../../../../CommonPopups/datefilter/datefilter.component";
import {FileService} from "../../../../Services/FileUtilsService/FileService";
import {IncommingReportDao} from "../incomming-report-dao";

const  appPrefix=environment.appUrl+environment.gsmgatewayservice;
@Component({
  selector: 'app-incomming-report',
  templateUrl: './incomming-report.component.html',
  styleUrls: ['./incomming-report.component.css']
})
export class IncommingReportComponent implements OnInit {

  tableData: PaginationDao<IncommingReportDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
    });
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }

    this.http.get<PaginationDao<IncommingReportDao>>(appPrefix+'api/incomming/get?offset='+this.offset+'&limit='+this.limit+'&startDate='+this.startdate+'&endDate='+this.enddate+'&keyword=RELI').subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });

  }

  tableChange(json) {
    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    this.loadData();

  }

  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  downloadFile() {
    this.http.get<PaginationDao<IncommingReportDao>>(appPrefix+'api/incomming/get?offset='+this.offset+'&limit='+this.limit+'&startDate='+this.startdate+'&endDate='+this.enddate+'&keyword=BURGER').subscribe(value => {
      this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr = [];
      tdArr.push('Date');
      tdArr.push('Number');

      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].date);
        tdArr.push(data[i].message);
        trArr.push(tdArr);
      }

      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');


    });


  }


}
