<div class="row">
    <div  style="background: lightgrey" class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div>
            <div>
                <h1>Subscription Package Details </h1>
            </div>
        </div>
        <div id="tabview" class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
            </div>
            <div  class="panel-body">
                <div class="table-responsive">
                    <div class="vertical-table">
                        <div *ngFor="let subScriptionPackageDao of tableList;let i = index">
                          <table style="width:100%">
                            <tr *ngIf="subScriptionPackageDao.subscriptionPackageType != SubscriptionPackageType.ONLYCREDIT">
                              <th>Expiry:</th>
                              <td>{{subScriptionPackageDao.expireyDateType}}</td>
                            </tr>
                            <tr>
                              <th>Country:</th>
                              <td>{{subScriptionPackageDao.country}}</td>
                            </tr>
                            <tr>
                              <th>Subscription Package Type:</th>
                              <td>{{subScriptionPackageDao.subscriptionPackageType}}</td>
                            </tr>
                            <tr>
                              <th>Marketing Rates:</th>
                              <td>{{subScriptionPackageDao.marketingRates}}</td>
                            </tr>
                            <tr>
                              <th>Utility Rates:</th>
                              <td>{{subScriptionPackageDao.utilityRates}}</td>
                            </tr>
                            <tr>
                              <th>Authentication Rates: Rs</th>
                              <td>{{subScriptionPackageDao.authenticationRates}}</td>
                            </tr>
                            <tr>
                              <th>User Initiated Rates: Rs</th>
                              <td>{{subScriptionPackageDao.userInitiatedRates}}</td>
                            </tr>
                            <tr>
                              <th>Platform Rent: Rs</th>
                              <td>{{subScriptionPackageDao.rentalAmount}}</td>
                            </tr>
                            <tr>
                              <th>Recharge Amount: Rs</th>
                              <td>{{subScriptionPackageDao.rechargeAmount}}</td>
                            </tr>
                            <tr>
                              <th>Subscription Amount: Rs</th>
                              <td>{{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</td>
                            </tr>
                            <tr>
                              <th>Subscription Amount (18% GST added): Rs</th>
                              <td>{{subScriptionPackageDao.amountWithGST}}</td>
                            </tr>
                            <tr>
                              <th>RazorPay Amount(2%): Rs</th>
                              <td>{{subScriptionPackageDao.razorPayAmount}}</td>
                            </tr>
                            <tr>
                              <th><h1>Payable Amount: Rs</h1></th>
                              <td><h1>{{subScriptionPackageDao.totalAMount}}</h1></td>
                            </tr>
                            <tr>
                              <th>Details:</th>
                              <td style="text-align: justify">{{subScriptionPackageDao.remarks}}</td>
                            </tr>
                          </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-title" style="text-align: center">
                <button [disabled]="subscriptionButtonStatus" (click)="submit()" class="btn btn-add">Buy Subscription<i class="fa fa-check"></i></button>
                <button (click)="cancel()" class="btn btn-add">Cancel<i class="fa fa-remove"></i></button>
            </div>
        </div>
    </div>
</div>


