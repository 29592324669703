
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">

    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">




          <br>
         <h4>Roles</h4>
          <ul class="nav nav-tabs">

            <ng-container *ngFor="let data of roleList;let ind =index">
              <li *ngIf="ind==0"  (click)="changeRole(data.id,true)" class="active"><a href="role#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.roleName"></a></li>
              <li *ngIf="ind>0"  (click)="changeRole(data.id,true)" class=""><a href="role#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.roleName"></a></li>

            </ng-container>
          </ul>
          <br>
          <h4>Services</h4>
          <ul class="nav nav-tabs">

            <ng-container *ngFor="let data of serviceList;let ind =index">
              <li *ngIf="ind==0"  (click)="changeServiceId(data.id,data.serviceName,true)" class="active"><a href="#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.serviceName"></a></li>
              <li *ngIf="ind>0"  (click)="changeServiceId(data.id,data.serviceName,true)" class=""><a href="#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.serviceName"></a></li>

            </ng-container>
          </ul>



        </div>
      </div>
      <div style="" class="panel-body">



        <div *ngIf="!addNewDiv">


          <ng-container *ngFor="let data of tableList.data;let ind =index">

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title [innerHTML]="data.moduleName">

                </mat-panel-title>
                <mat-panel-description >

                </mat-panel-description>
              </mat-expansion-panel-header>

              <table  class="table table-striped row-border hover">
                <thead>
                <tr>
                  <th>Url Alias</th>
                  <th>Permission</th>
                 </tr>
                </thead>
                <tbody>
              <ng-container *ngFor="let actionList of data.actionIds;let k =index">

                <tr >
                  <td [innerHTML]="data.actionAlias[k]"></td>


                  <td > <mat-slide-toggle [checked]="checkPermission(actionList,data.permissions)" (change)="toggleChanges($event,data.moduleId,actionList)"></mat-slide-toggle>
                  </td>
                </tr>
              </ng-container>
                </tbody>
                </table>
            </mat-expansion-panel>

          </ng-container>

        </div>

      </div>
    </div>
  </div>
</div>







