<h1 mat-dialog-title style="text-align: center">Cart Details <i class="fa fa-shopping-cart"></i> </h1>





  <mat-dialog-content class="mat-typography">

    <div class="table-responsive">

      <table class="table table-bordered table-striped table-hover">
        <thead>
        <tr>
          <th>Product Name</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Total Price</th>
        </tr>

        </thead>
        <tbody>
        <ng-container *ngFor="let opt of data">
          <tr><td [innerHTML]="opt.productName"></td><td [innerHTML]="opt.qty.toFixed(2)"></td><td [innerHTML]="opt.rate.toFixed(2)"></td><td [innerHTML]="(opt.qty*opt.rate).toFixed(2)"></td></tr>
        </ng-container>
        </tbody>
        <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th><b> Grand Total</b></th>
          <th [innerHTML]="totalCart.toFixed(2)"></th>
        </tr>
        </tfoot>
      </table>

    </div>



  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

  </mat-dialog-actions>

