
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Menu<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Menu name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="data.name" name="name" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Menu Icon  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="data.menuIcon" name="menuIcon" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Menu Seq  <sup class="required">*</sup></label>
              <input type="number" step="any" class="form-control" [(ngModel)]="data.menuSeq" name="menuSeq" required>
            </div>












            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Menu Name</th>


              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.name"></td>

                <td><i title="Click Here To Edit" (click)="edit(data,ind)"
                       class="btn btn-add fa fa-pencil"></i>


                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







