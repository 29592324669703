<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div id="tabview" class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title row">
          <div class="form-group col-lg-12 col-md-12 col-sm-12">
            <label style="color: #093265;" class="col-lg-1 col-md-1 col-sm-2">Date</label>
            <input class="col-lg-2 col-md-2 col-sm-4" type="date" [(ngModel)]="filterDao.startdate">
            <input class="col-lg-2 col-md-2 col-sm-4" type="date" [(ngModel)]="filterDao.enddate">
            <label style="color: #093265; margin-right: 5px" class="col-lg-1 col-md-1 col-sm-2">Account</label>
            <input class="col-lg-2 col-md-2 col-sm-4" type="number" [(ngModel)]="filterDao.accountId">
            <button (click)="filterData()" class="btn btn-add col-lg-1 col-md-2 col-sm-4"><i class="fa fa-filter"></i> Filter</button>
            <button (click)="clearFilter()" class="btn btn-add col-lg-1 col-md-2 col-sm-4">Clear <i class="fa fa-filter"></i></button>
            <button (click)="downloadReport()" class="btn btn-add col-lg-1 col-md-2 col-sm-4"><i class="fa fa-download"></i></button>
          </div>
        </div>
      </div>
      <div  class="panel-body">
        <mat-slide-toggle color="primary" [(ngModel)]="status" (click)="onchangeStatus()">ACTIVE </mat-slide-toggle>
        <div class="table-responsive">
          <table id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Account </th>
              <th>Create Date</th>
              <th>Package Type</th>
              <th>Country</th>
              <th>Expiry Type</th>
              <th>Plan Type</th>
              <th>Recharge Amount</th>
              <th>Rental Amount</th>
              <th>Total Amount</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let subScriptionTransPackageDao of tableList;let ind = index">
              <tr>
                <td [innerHTML]="subScriptionTransPackageDao.accountId"></td>
                <td [innerHTML]="subScriptionTransPackageDao.createDate"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.subscriptionPackageType"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.country"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.expireyDateType"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.planType"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.rechargeAmount"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.rentalAmount"></td>
                <td [innerHTML]="subScriptionTransPackageDao.subScriptionPackageDao.totalAMount"></td>
                <td *ngIf="subScriptionTransPackageDao.status == true"><i class="fa fa-check-circle" style="font-size:36px;color:green"></i></td>
                <td *ngIf="subScriptionTransPackageDao.status == false"><i class="fa fa-times-circle" style="font-size:36px;color:red"></i></td>
               </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
