
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <button (click)="addNew()"  class="btn btn-add">Add  Product<i
            class="fa fa-plus"></i></button>

          <button (click)="bulkUpload()"  class="btn btn-add">Add Bulk Product<i
            class="fa fa-plus"></i></button>
          <i (click)="downloadSampleFile()" title="Click Here To Download Sample File" style="color: red;cursor: pointer;margin-left: 10px" class="fa fa-question-circle"></i>



        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Product Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="productMasterDao.product" name="product" required>
            </div>








            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Product Name</th>

              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.length==0">
              <td  style="text-align: center" colspan="2">No Record</td>
            </tr>
            <ng-container *ngFor="let template of tableList;let ind =index">

              <tr>
                <td [innerHTML]="template.product"></td>



                <td><i title="Click Here To Edit" (click)="edit(template,ind)"
                       class="btn btn-add fa fa-pencil"></i>

                  <i title="Click Here To Delete" (click)="delete(template,ind)"
                     class="btn btn-add fa fa-trash"></i>
                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







