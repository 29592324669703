import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RoleMasterDao} from '../RoleMasterDao';
import {environment} from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RoleServiceService {

  constructor(private http:HttpClient) { }
  getRoles():Observable<Array<RoleMasterDao>>
  {
    return this.http.get<Array<RoleMasterDao>>(environment.appUrl+environment.authMicroservice+"role/get");
  }

  saveRole(role:RoleMasterDao):Observable<RoleMasterDao>
  {
    return this.http.post<RoleMasterDao>(environment.appUrl+environment.authMicroservice+"role/save",role);
  }
}
