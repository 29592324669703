import {AccountMasterDao} from '../../account-master/AccountMasterDao';

export interface UserMasterDao{
  id:number,
  accountId:number,
  username:string,
  password:string,
  createBy:string,
  createAt:string,
  updateBy:string,
  updateAt:string,
  active:boolean,
  email:string,
  phone:string,
  accountType:string,
  accountMasterDao:AccountMasterDao,
  servicesList:string,
  services:Array<ServiceDao>,
  permissions:Array<string>,
  fullname:string,
  hasTwoStepAuthencation:boolean,
  otpVerified:boolean,
  enableAgentSupport:boolean,
  agentStatus:boolean
}
export enum UserType{
  MASTERADMIN="MASTERADMIN",ADMIN="ADMIN",USER="USER"
}
export interface ServiceDao{
  id:number,
  apiUrl:string,
  serviceName:string,
  active:boolean,
  serviceType:ServiceType,
  logo:string,
  descri:string
}
export enum ServiceType{
  LICENSED="LICENSED",COIN="COIN"
}
