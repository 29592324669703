import {Component, OnInit} from '@angular/core';
import {UserMasterDao} from '../Services/User/UserMasterDao';
import {UserService} from '../Services/User/user.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: UserMasterDao = {} as UserMasterDao;
  errorMessage = 'Failed To Login';
  isError = false;
  firstNumber: number = this.generateRandomNumber(0, 9);
  secondNumber: number = this.generateRandomNumber(0, 9);
  totalNumber: number;

  constructor(private userService: UserService, private alertService: AlertMessageService, private router: Router) {
  }

  generateRandomNumber(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  login() {
    this.alertService.showLoader();
    this.isError = false;
    // tslint:disable-next-line:triple-equals
    if ((this.secondNumber + this.firstNumber) != this.totalNumber) {
      this.errorMessage = 'Incorrect Total';
      this.isError = true;
      return;
    }

    this.userService.login(this.user).subscribe(value => {
      this.userService.saveAccessTokenToSession(value.access_token);
      this.userService.saveRefreshToken(value.refresh_token);
      this.userService.getCurrentUserAndSaveToSession();
    }, error => {
      this.errorMessage = error.error.message;
      this.isError = true;
      this.alertService.hideLoader();
    });
  }

}
