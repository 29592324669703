import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../Services/User/user.service';
import {MenusubService} from '../Services/MenuSub/menusub.service';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {environment} from '../../environments/environment.prod';
import {ConversationSubscriptionService} from '../conversation/ConversationSubscription/ConversationSubscriptionService';
import {Subscription} from 'rxjs';
import {ConversationDao} from '../conversation/ConversationDao';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  conversationList: Array<ConversationDao> = null;

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  // tslint:disable-next-line:max-line-length
  constructor(public user: UserService, private conversationSubcriptionService: ConversationSubscriptionService, private alertService: AlertMessageService, private router: Router, private menuSub: MenusubService, private titleService: Title, @Inject(DOCUMENT) private document: HTMLDocument) {
    // this.user.accountMasterDao.title= 'sujit';
    this.titleService.setTitle(user.accountMasterDao.title);
    // this.user.accountMasterDao.accountMinLogo = 'https://prpservices.in/img/content/93d33451-cbea-4358-8414-bf9363f80078.png';
    this.document.getElementById('appFavicon').setAttribute('href', user.accountMasterDao.accountMinLogo);
    this.router.events.subscribe(event  => {
      if (event instanceof NavigationStart) {
       // this.alertService.showLoader();
      }

      if (event instanceof NavigationError) {

        this.alertService.hideLoaderinSecond(1000);

      }
      if (event instanceof NavigationEnd) {
        this.alertService.hideLoaderinSecond(1000);
      }
    });

  }

  ngOnInit(): void {
   /* */
    try {
      this.subscription = this.conversationSubcriptionService.getObj().subscribe(value => {
          if (value != null)
        {

          this.conversationList = value.data;
         }
         else
         {
            console.log("else block executed:: ");
         }


      });
    }catch (e){

    }


  }

  // tslint:disable-next-line:typedef
  changeToIndex() {
    const urlOfService = '';
    this.user.takeMeToDashBoardOfService(this.user.serviceName);
  }

  // tslint:disable-next-line:typedef
  takeMeToConversation(data: ConversationDao) {

    this.router.navigate(['wabuissness/v1/conversation'], {queryParams: data});
  }
}
