import { Component, OnInit } from '@angular/core';
import {BungalowHistoryDao} from "../bungalow-history-dao";
import {NbccserviceService} from "../nbccservice.service";
import {Subject} from "rxjs";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../../../alert-message/alert-message.service";

@Component({
  selector: 'app-bungalow-history',
  templateUrl: './bungalow-history.component.html',
  styleUrls: ['./bungalow-history.component.css']
})
export class BungalowHistoryComponent implements OnInit {
  tableData: Array<BungalowHistoryDao>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};
  constructor(private service:NbccserviceService,private alert:AlertMessageService) { }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngOnInit(): void {
    this.getData();
  }

  downloadReport() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];

      tdArr.push('Bungalow No');
      tdArr.push('Maintenance Reading');
      tdArr.push('Description');
      tdArr.push('Date');
    trArr.push(tdArr);
    this.service.getBungalowHistory().subscribe(value => {
      console.log('values are' + value);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++)
      {
        tdArr = [];
          tdArr.push(value[i].bungalowNo);
          tdArr.push(value[i].maintenanceReading);
          tdArr.push(value[i].discription);
          tdArr.push(value[i].date);
        }
        trArr.push(tdArr);
      file.generateFile(trArr,   'Maintenance_Report.xlsx');
    });
    this.alert.hideLoader();
  }

  getData(){
    this.service.getBungalowHistory().subscribe(value => {
      this.tableData=value;
      this.dtTrigger.next();
    })
  }
}
