import {Component, Inject, OnInit} from '@angular/core';
import {RegistrationOtpDao} from "../../Registration-Otp/registration-otp-dao";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: ['./confirmation-message.component.css']
})
export class ConfirmationMessageComponent implements OnInit {

  user: RegistrationOtpDao;

  constructor(@Inject(MAT_DIALOG_DATA) public data: RegistrationOtpDao,private dialog: MatDialogRef<ConfirmationMessageComponent>) { }

  ngOnInit(): void {
    this.user=this.data;
    console.log('mat dialog',this.data)
    console.log('user',this.user)
    // this.dialog.close();
  }

}
