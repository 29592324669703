
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filter()"  class="btn btn-add">Filter<i
            class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i
            class="fa fa-remove"></i></button>
          <button (click)="addNew()"  class="btn btn-add">Add  Employee<i
            class="fa fa-plus"></i></button>

          <button (click)="bulkUpload()"  class="btn btn-add">Add Bulk Employees<i
            class="fa fa-plus"></i></button>
          <i (click)="downloadSampleFile()" title="Click Here To Download Sample File" style="color: red;cursor: pointer;margin-left: 10px" class="fa fa-question-circle"></i>
<br>
          <select (change)="getData()" style="width: 200px!important;" type="date" [(ngModel)]="managerId" name="managerId" required>
            <ng-container *ngFor="let user of userList">
              <option [value]="user.id" [innerHTML]="user.fullname"></option>
            </ng-container>

          </select>



        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Emp name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.empName" name="empName" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Team <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.team" name="empName" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Role <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.empRole" name="empRole" required>
            </div>

            <div class="form-group col-lg-12">
              <label>HQ <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.hq" name="hq" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Mobile <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.mobile" name="mobile" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Zone <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.zone" name="zone" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Emp Id <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="empMasterDao.empId" name="empId" required>
            </div>






            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Emp Name</th>
              <th> Team</th>
              <th>Role</th>
              <th>HQ</th>
              <th>Mobile</th>
              <th>Zone</th>
              <th>Emp Id</th>
              <th>Last Update</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.length==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let template of tableList;let ind =index">
              <tr>
                <td [innerHTML]="template.empName"></td>
                <td [innerHTML]="template.team"></td>
                <td [innerHTML]="template.empRole"></td>
                <td [innerHTML]="template.hq"></td>
                <td [innerHTML]="template.mobile"></td>
                <td [innerHTML]="template.zone"></td>
                <td [innerHTML]="template.empId"></td>
                <td [innerHTML]="template.updateDate"></td>


                <td><i title="Click Here To Edit" (click)="edit(template,ind)"
                       class="btn btn-add fa fa-pencil"></i>

                  <i title="Click Here To Delete" (click)="delete(template,ind)"
                     class="btn btn-add fa fa-trash"></i>
                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







