import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LongcodenrctDao} from '../LongcodeNRCT/longcodenrct-dao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {PaginationDao} from "../../CommonObj/PaginationDao";
const  appprefix2 = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class BpphpServiceService {

  constructor(private http: HttpClient) { }


  getData(startdate: number, enddate: number, offset: number, limit: number): Observable<PaginationDao<LongcodenrctDao>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<PaginationDao<LongcodenrctDao>>(appprefix2 + 'digimozo/v1/api/bhhpCampaign/get?startDate=' + startdate + '&endDate=' + enddate + '&offset=' + offset + '&limit=' + limit);
  }
}
