import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DriveSubscriptionDao} from './DriveSubscriptionDao';
import {DriveMasterDao} from './drive/DriveMasterDao';
import {MediaDao} from '../MediaService/media.service';
import {AlertMessageService} from '../alert-message/alert-message.service';

const appPrefix = environment.appUrl + environment.driveService;

@Injectable({
  providedIn: 'root'
})
export class DriveService {

  constructor(private http: HttpClient, private alert: AlertMessageService) {
  }

  get(id: number): Observable<DriveSubscriptionDao> {
    return this.http.get<DriveSubscriptionDao>(appPrefix + 'drivesubscription/' + id);
  }

  save(sub: DriveSubscriptionDao): Observable<DriveSubscriptionDao> {
    return this.http.post<DriveSubscriptionDao>(appPrefix + 'drivesubscription/', sub);
  }

  getAll(): Observable<Array<DriveMasterDao>> {
    return this.http.get<Array<DriveMasterDao>>(appPrefix + 'drive/');
  }

  // tslint:disable-next-line:typedef
  uploadFile(event, accountId) {
debugger;
     return new Promise<DriveMasterDao>((resolve, reject) => {


      const fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        const file = fileList[0];

        const formData = new FormData();
        formData.append('file', file);
        formData.append('reportProgress', 'true');
        console.log(formData);

        this.http.post<DriveMasterDao>(appPrefix + 'drive/?id=' + accountId, formData, {headers: {'Testtt': 'multipart/form-data'}}).subscribe(value => {
          resolve(value);
        }, error => {
          reject(error.error.message);

        });

      }
    });
  }

  deleteFile(id:number):Observable<any>
  {
    return this.http.delete(appPrefix+"drive/"+id);
  }
  deleteMultipleFile(data:Array<DriveMasterDao>):Observable<any>
  {
    return this.http.post(appPrefix+"drive/multidelete",data);
  }

}
