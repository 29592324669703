import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-campaintrans',
  templateUrl: './campaintrans.component.html',
  styleUrls: ['./campaintrans.component.css']
})
export class CampaintransComponent implements OnInit {

  constructor(private router: Router) { }


  // tslint:disable-next-line:typedef
  addRole()
  {
    this.router.navigate(['mailsendtransroll']);
  }

  ngOnInit(): void {


    // tslint:disable-next-line:prefer-const
    let searchParams = new URLSearchParams(window.location.search);
    const datefilter = searchParams.get('datefilter') || false;
    const datevalue = searchParams.get('datevalue') || 0;
    const clientId = searchParams.get('clientId') || 0;
    const groupId = searchParams.get('groupId') || 0;
    const campaignId = searchParams.get('campaignId') || 0;
    const status = searchParams.get('status') || -10;
    const  length = searchParams.get('length') || 1;
    const   start = searchParams.get('start') || 0;

    // @ts-ignore
    // @ts-ignore
    const table = $('#table1').DataTable({
      ajax: {
        url: 'http://localhost:8089/api/camptrans/getall'

        , data: {
          datefilter,
          datevalue,
          clientId,
          groupId,
          campaignId,
          status,
          length,
          start
        }
      },

      columns: [
        /*   {id: 1, name: "Present", desc: "User IS Present", salarycount: 100, status: 1, posotiveornegative: 1}*/

        {data: 'Email'},
        {data: 'Date'},
        {
          // tslint:disable-next-line:typedef
          render(data, type, row) {


            // tslint:disable-next-line:triple-equals
            if (row.status == 1) {
              return '<span style=\'color: green\'>Sent</span> ';
            }
            // tslint:disable-next-line:triple-equals
            else if (row.status == -1)
            {
              return '<span style=\'color: red\'>Failed Sent</span> ';
            }
            else {
              return '<span style=\'color: orange\'>Not-Sent</span>';
            }

          }
        },


      ],
      processing: true,
      serverSide: true,
      ordering: false,
      searching: false,
      // bDestroy: true
    });

  }


  }




