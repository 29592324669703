import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UploadLongcodeDataService {

    constructor(private http: HttpClient) {
    }

    insertkk(fileData: any):Observable<any> {
       // let BASE_URL:'http://localhost:8083/api/longcodekk/upload';
        return this.http.post<any>('http://62.171.169.159:8083/api/longcodekk/upload', fileData,{responseType: 'text' as 'json'})
    }
}
