import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {HDFCampDao} from "../c2c-report/HDFCampDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../environments/environment.prod";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {ExcelReaderWithDatettimeComponent} from "../../../CommonPopups/excel-reader-with-datettime/excel-reader-with-datettime.component";

@Component({
  selector: 'app-hdfc-newjoinee',
  templateUrl: './hdfc-newjoinee.component.html',
  styleUrls: ['./hdfc-newjoinee.component.css']
})
export class HdfcNewjoineeComponent implements OnInit {

  tableData: PaginationDao<HDFCampDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public empname:string="0";
  public empcode:string="0";
  public empmsisdn:string="0";
  public startdateStr:string="0";
  public enddateStr:string="0";
  campaignType = 'newjoinee';


  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getcdr?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit + '&campaigntype='+this.campaignType).subscribe(value => {
      this.tableData = value;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  clearFilter() {
    this.empname ="0"
    this.empcode ="0"
    this.empmsisdn ="0"
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {
    this.dialog.open(ExcelReaderWithDatettimeComponent).afterClosed().subscribe(value => {

      if(value==null)
      {
        return;
      }

      let al:Array<HDFCampDao>=[]
      let calltime = value.time;
      calltime = calltime.replace("T"," ");
      let tempCallTime=calltime.split(" ")[0];
      let tempCallTimeArr=tempCallTime.split("-");
      let calltimefiler=tempCallTimeArr.join("");

      value = value.list;
      for(var i=0;i<value.length;i++)
      {
        let row = {
          id:0,
          msisdn:value[i]["emp_Number"],
          empName:value[i]["emp_Name"],
          empCode:value[i]["emp_Code"],
          campaignType:this.campaignType,
          callTime:calltime,
          callTimeFilter:calltimefiler


        }
        al.push(<HDFCampDao>row)
      }
      console.log(al)
      this.alert.confirmMessage("Are you sure?","Upload "+al.length+" records?").then(res => {
        this.alert.showLoader();
        this.http.post<Array<string>>(environment.hdfcBaseUrl +  'hdfccampaign/upload',al).subscribe(alret => {

          this.alert.hideLoader();
          this.alert.showSuccesMessage("Successfully Uploaded")
          this.loadData();
        },error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage("Failed To Upload!!")
        })
      })

    })

  }


  download() {
    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getcdr?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit + '&campaigntype='+this.campaignType).subscribe(value => {
      // this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr =["Emp Name","Emp Code","Emp Mobile","Request Time","Call Time","Call Status","Call Connect Time","Call Disconnect Time","Duration","Pulses"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].empName);
        tdArr.push(data[i].empCode);
        tdArr.push(data[i].msisdn);
        tdArr.push(data[i].requestTime);
        tdArr.push(data[i].callTime);
        tdArr.push(data[i].callReason);
        tdArr.push(data[i].callConnecttime);
        tdArr.push(data[i].callDisconnecttime);
        tdArr.push(data[i].callDuration);
        tdArr.push(data[i].pulses);
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

}
