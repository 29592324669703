import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupDao} from './mailgroupadd/group-dao';
import {environment} from '../../../environments/environment.prod';
import {ScheduleReportDao} from '../schedule-report/schedule-report-dao';
const appprefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class MailgroupsService {


  constructor(private  http: HttpClient) { }
Array;

  insertData(data): Observable<any>
  {

    return this.http.post(appprefix + 'gsmgateway/v1/api/group/insert' , data, {responseType: 'text'});
  }

  // insertData(data): Observable<any>
  // {
  //
  //   return this.http.post(  'http://localhost:8089/api/group/insert' , data, {responseType: 'text'});
  // }


  // getAll(): Observable<Array<GroupDao>>
  // {
  //
  //   return this.http.get<Array<GroupDao>>(appprefix + 'gsmgateway/v1/api/group/get', {});
  // }


  getAll(): Observable<Array<GroupDao>>
  {
    return this.http.get<Array<GroupDao>>(  appprefix + 'gsmgateway/v1/api/group/get', {});
  }

  // tslint:disable-next-line:typedef
  getData(startdate: number, enddate: number) {

    return this.http.get(appprefix + 'gsmgateway/v1/api/report/get?startDate=' + startdate + '&endDate=' + enddate);
  }




  // tslint:disable-next-line:typedef
  getPreviousMonday()
  {
    const date = new Date();
    const day = date.getDay();
    let prevMonday;
    prevMonday = new Date();
    // tslint:disable-next-line:triple-equals
    if (date.getDay() == 0){
      console.log('if block call');
      prevMonday.setDate(date.getDate() - 7);
    }
    else{
      prevMonday.setDate(date.getDate() - (day + 1) % 7);
      console.log('else block call' + prevMonday);
    }

    return prevMonday;
  }

  // tslint:disable-next-line:no-shadowed-variable
   getScheduleReport(): Observable  <Array<ScheduleReportDao>>
   {
     return this.http.get<Array<ScheduleReportDao>>(appprefix + 'gsmgateway/v1/api/mailSend/getSchedule');
   }


  // tslint:disable-next-line:typedef
  deleteSchedule(id: number) {
    return this.http.delete(appprefix + 'gsmgateway/v1/api/mailSend/deleteSchedule?id=' + id);
  }
}
