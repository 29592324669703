import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MessageDao, MessageType} from '../../DaoMessageUn';
import {data} from 'jquery';


@Component({
  selector: 'app-message-info-wun',
  templateUrl: './message-info-wun.component.html',
  styleUrls: ['./message-info-wun.component.css']
})
export class MessageInfoWunComponent implements OnInit {

  // tslint:disable-next-line:no-shadowed-variable
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDao) { }

  ngOnInit(): void {
    console.log('message dao in message-info', this.data );
  }

}
