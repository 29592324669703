<!--<div class="row">-->
<!--  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">-->
<!--    <div class="panel panel-bd lobidragdashboard">-->
<!--      <div class="panel-heading">-->

<!--        <div class="panel-title">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="" class="panel-body">-->
<!--        <div class="table-responsive">-->
<!--          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>Campaign Name</th>-->
<!--              <th>Reciepts</th>-->
<!--              <th>Schedule Time</th>-->
<!--              <th>Action</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <ng-container *ngFor="let data of tableLists;let ind =index">-->
<!--              <tr *ngIf="data.iSchedule==1">-->
<!--                <td [innerHTML]="data.campaignName"></td>-->
<!--                <td [innerHTML]="data.count"></td>-->

<!--                &lt;!&ndash;                <td>{{  data.messageDao.scheduleTime.toLocaleString().replace('','')}} </td>&ndash;&gt;-->
<!--                <td>{{  data.scheduleTime.toString().substring(0, 4) + "-" + data.scheduleTime.toString().substring(4, 6) + "-" + data.scheduleTime.toString().substring(6, 8) + " " + data.scheduleTime.toString().substring(8, 10) + ":" + data.scheduleTime.toString().substring(10, 12)}} </td>-->

<!--                <td>-->
<!--                  <i title="Delete Schedule Campaign " (click)="deleteCampaign(data)"-->
<!--                     class="btn btn-danger fa fa-trash"></i>-->
<!--                </td>-->
<!--              </tr>-->

<!--            </ng-container>-->
<!--            </tbody>-->
<!--          </table>-->

<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
<!--          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">-->

                      <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr >
              <th>Campaign Name</th>
              <th>Receipts</th>
              <th>Schedule Time</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: center" *ngIf="tableData.total==0">
              <td colspan="4">No Entry</td>
            </tr>
            <ng-container *ngFor="let data of tableData.data;let ind =index">
              <tr   *ngIf="data.iSchedule==1">
                <td [innerHTML]="data.campaignName"></td>
                <td [innerHTML]="data.count"></td>
                <td>{{  data.scheduleTime.toString().substring(0, 4) + "-" + data.scheduleTime.toString().substring(4, 6) + "-" + data.scheduleTime.toString().substring(6, 8) + " " + data.scheduleTime.toString().substring(8, 10) + ":" + data.scheduleTime.toString().substring(10, 12)}} </td>
                <td>
                                    <i title="Delete Schedule Campaign " (click)="deleteCampaign(data)"
                                       class="btn btn-danger fa fa-trash"></i>
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5,10, 25, 100]">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>










