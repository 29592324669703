<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button type="button" class="btn btn-add" (click)="downloadReport()">Download <i class="fa fa-download"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Uid</th>
              <th>Ase Name</th>
              <th>Ase Number</th>
              <th>Asm Area</th>
              <th>Asm Name</th>
              <th>Asm Number</th>
              <th>City</th>
              <th>Db Code</th>
              <th>Db Name</th>
              <th>Mandi Name </th>
              <th>Name </th>
              <th>Promoter</th>
              <th>Promoter Number</th>
              <th>Region</th>
              <th>State</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr>
                <td [innerHTML]="user.uid"></td>
                <td [innerHTML]="user.aseName"></td>
                <td [innerHTML]="user.aseNumber"></td>
                <td [innerHTML]="user.asmArea"></td>
                <td [innerHTML]="user.asmName"></td>
                <td [innerHTML]="user.asmNumber"></td>
                <td [innerHTML]="user.city"></td>
                <td [innerHTML]="user.dbCode"></td>
                <td [innerHTML]="user.dbName"></td>
                <td [innerHTML]="user.name"></td>
                <td [innerHTML]="user.mandiName"></td>
                <td [innerHTML]="user.promoter"></td>
                <td [innerHTML]="user.promoterNumber"></td>
                <td [innerHTML]="user.region"></td>
                <td [innerHTML]="user.state"></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>







