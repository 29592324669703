import { Component, OnInit } from '@angular/core';
import { AccountMasterDao, CreditType } from '../account-master/AccountMasterDao';
import { UserService } from '../Services/User/user.service';
import { AlertMessageService } from '../alert-message/alert-message.service';
import { UidGenerationService } from '../Services/Uids/uid-generation.service';
import { AccountMasterService } from '../account-master/AccountMasterService/account-master.service';
import { UserMasterDao } from '../Services/User/UserMasterDao';
import { UsermasterService } from '../Services/UsermasterService/usermaster.service';
import { Subject } from 'rxjs';
import { AccountServiceDao, AccountServiceFormDao } from '../account-master/account-services/AccountServiceDao';
import { AccountServicesComponent } from '../account-master/account-services/account-services.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleMasterDao } from '../rolemaster/RoleMasterDao';
import { RoleServiceService } from '../rolemaster/RoleService/role-service.service';
import { RolePrivServiceService } from '../account-master/role-and-priv/RolePriv/role-priv-service.service';
import { RoleAndPrivComponent } from '../account-master/role-and-priv/role-and-priv.component';

@Component({
  selector: 'app-agent-support-new',
  templateUrl: './agent-support-new.component.html',
  styleUrls: ['./agent-support-new.component.css']
})
export class AgentSupportNewComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};

  tableList: Array<AccountMasterDao> = [];
  
  accountMasterDao: AccountMasterDao = {} as AccountMasterDao;

  applicationArr: Array<string> = [];
  accountModeArr: Array<string> = [];
  accountTypeArr: Array<string> = [];
  deductionTypeArr: Array<string> = [];
  serviceTypeArr: Array<string> = [];
  parentId = 0;
  isInsert = true;
  private updateId: number;
  isAgentSupport:boolean = false;

  roleMasterDao:Array<RoleMasterDao>=[]

  constructor(public user: UserService, private alert: AlertMessageService, private counter: UidGenerationService,
    private accountService: AccountMasterService, private userServie: UsermasterService, private dialog: MatDialog,
    private roleService:RoleServiceService,private rolePriv:RolePrivServiceService) {

    this.parentId = user.accountMasterDao.id;

    this.refreshData();
    this.loadData();
   }

  ngOnInit(): void {
    
  }

  refreshData()
  {

    this.accountService.getAccountAgentByParentId(this.user.accountMasterDao.id).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }

  loadData()
  {
    this.roleService.getRoles().subscribe(value => {

      this.rolePriv.getByUser(this.user.accountMasterDao.id).subscribe(value1 => {

        for(var i=0;i<value.length;i++)
        {
          value[i].checked=value1.some(item => item.roleId === value[i].id);
          if(value[i].roleName == "UpdateAgentSupport"){
            this.isAgentSupport = value[i].checked;
          }
        }
        this.roleMasterDao=value;

        console.log("asd ",value);


      })




    });
  }

  addNew() {
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
    this.accountMasterDao = {
      accountEmail: '',
      accountLogo: '',
      accountMinLogo: '',
      accountName: '',
      accountPhone: '',
      active: true,
      enableReseller:false,
      createAt: '',
      createBy: '',
      footer: '',
      id: 0,
      services: [],
      title: '',
      updateAt: '',
      updateBy: '',
      parentId: this.parentId,
      reseller: false,
      creditType:CreditType.SELF,
      accountTransactionReportDao:null,
      resellerReportDao:null,
      password:'',
      serviceName:'',
      agentStatus:true,
      enableAgentSupport:this.isAgentSupport

    };
    this.isInsert = true;
  }

  cancelBtn() {
    $('#addnewdiv').css('display', 'none');
    $('#tablediv').fadeIn();
  }

  editAccount(account: AccountMasterDao, ind: number)
  {
    this.updateId = ind;

    this.isInsert = false;
    this.accountMasterDao = account;
    this.accountMasterDao.parentId = this.parentId;
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
  }

  edit(ind: number, service: AccountServiceDao) {
    console.log(service);
    const services: AccountServiceFormDao = {
      accountDetails: service,
      services: this.applicationArr,
      accountMode: this.accountModeArr,
      accountType: this.accountTypeArr,
      deductionType: this.deductionTypeArr,
      serviceType: this.serviceTypeArr
    };
    this.dialog.open(AccountServicesComponent, {data: services}).afterClosed().subscribe(value => {
      if (value != null) {
        this.accountMasterDao.services[ind] = value;
      }


    });
  }

  createOrUpdate() {
    this.updateOrIns(this.accountMasterDao, this.isInsert, true);
  }

  async updateOrIns(accountMasterDao: AccountMasterDao, isInsert: boolean, showConfirmMessage: boolean) {
    accountMasterDao.parentId = this.parentId;
    accountMasterDao.reseller = true;
  
    console.log('will update account {}', accountMasterDao);
    if (showConfirmMessage) {
      let message = 'Update Account?';
      if (isInsert) {
        message = 'Create Account?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }
    if (isInsert) {
      this.counter.generateCounterNumber(1, 1).then(id => {
        const serviceList = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < accountMasterDao.services.length; i++)
        {
          serviceList.push(accountMasterDao.services[i].serviceName);
        }
        accountMasterDao.id = id;
        accountMasterDao.password = accountMasterDao.accountPhone;
        this.accountService.insert(accountMasterDao).subscribe(value => {
          this.accountMasterDao = value;
          this.isInsert = false;
          const user: UserMasterDao = {
            accountId: id,
            otpVerified:false,
            hasTwoStepAuthencation:false,
            accountMasterDao: null,
            accountType: 'ADMIN',
            active: true,
            createAt: '',
            createBy: '',
            email: value.accountEmail,
            fullname: value.accountName,
            id,
            password: value.accountPhone,
            permissions: undefined,
            phone: value.accountPhone,
            services: undefined,
            servicesList: serviceList.join(','),
            updateAt: '',
            updateBy: '',
            username: id.toString(),
            agentStatus:true,
            enableAgentSupport:this.isAgentSupport
          };
          this.userServie.createUser(user).subscribe(userObj => {
            if (showConfirmMessage) {


              this.alert.showSuccesMessage('Successfully Created Account.Please Login in using your Account Id and PhoneNumber');
            }
            this.cancelBtn();
          });

          this.tableList.unshift(value);

        }, error => {

        });
      });
    } else {
      // accountMasterDao.isReseller = true;
      console.log('test ', accountMasterDao);
      this.accountService.update(accountMasterDao).subscribe(value => {

        this.accountMasterDao.enableAgentSupport = this.isAgentSupport;
        console.log('AFTER AUPDATED accountMasterDao', value);
        this.tableList[this.updateId] = value;
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Updated Account');
        }
        this.cancelBtn();

      }, error => {

      });
    }

  }

  addRole(id: number) {
    const user: UserMasterDao = {
      accountId: id,
      otpVerified:false,
      hasTwoStepAuthencation:false,
      accountMasterDao: undefined,
      accountType: '',
      active: false,
      createAt: '',
      createBy: '',
      email: '',
      fullname: '',
      id,
      password: '',
      permissions: undefined,
      phone: '',
      services: undefined,
      servicesList: '',
      updateAt: '',
      updateBy: '',
      username: '',
      enableAgentSupport:false,
      agentStatus:false
    };
    this.dialog.open(RoleAndPrivComponent, {data: user});

  }

}
