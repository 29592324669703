import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SubscriptionDaoUnWa} from '../SubscriptionDao';
import {environment} from '../../../../environments/environment.prod';

const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionUnService {

  constructor(private http: HttpClient) {
  }

  getSubscription(id: number): Observable<SubscriptionDaoUnWa> {
    return this.http.get<SubscriptionDaoUnWa>(appPrefix + 'api/subscription/get?accountId=' + id);
  }
  save(sub: SubscriptionDaoUnWa): Observable<SubscriptionDaoUnWa>
  {
    return this.http.post<SubscriptionDaoUnWa>(appPrefix + 'api/subscription/', sub);
  }

}
