import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../Services/User/user.service';
import {AccountMasterDao, CreditType} from './AccountMasterDao';
import {AccountMasterService} from './AccountMasterService/account-master.service';
import * as $ from 'jquery';
import {FormBuilder} from '@angular/forms';
import {ApplicationsService} from '../Services/applications.service';
import {MatDialog} from '@angular/material/dialog';
import {AccountServicesComponent} from './account-services/account-services.component';
import {AccountServiceDao, AccountServiceFormDao} from './account-services/AccountServiceDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {Subject} from 'rxjs';
import {RechargeComponent} from './recharge/recharge.component';
import {UidGenerationService} from '../Services/Uids/uid-generation.service';
import {UsermasterService} from '../Services/UsermasterService/usermaster.service';
import {UserMasterDao} from '../Services/User/UserMasterDao';
import {SubscriptionComponent} from '../subscription/subscription.component';
import {SubscriptionFormDao} from '../subscription/SubscriptionDao';
import {SubscriptionWaUnComponent} from '../Unofficial/subscription-wa-un/subscription-wa-un.component';
import {RoleAndPrivComponent} from './role-and-priv/role-and-priv.component';
import {CountrywiserateComponent} from "../countrywiserate/countrywiserate.component";


@Component({
  selector: 'app-account-master',
  templateUrl: './account-master.component.html',
  styleUrls: ['./account-master.component.css']
})
export class AccountMasterComponent implements OnInit, OnDestroy {
  hasWriteOrUpdatePermission: boolean = this.user.checkPermission('ROLE_ACCOUNTCREATIONORUPD');
  hasRechargePermission: boolean = this.user.checkPermission('ROLE_RECHARGEINS');
  accountMasterDao: AccountMasterDao = {} as AccountMasterDao;
  dtOptions: any = {  bDestroy: true, order: []};
  parentId = 0;
  dtTrigger: Subject<any> = new Subject<any>();

   hasTwoStepAuthencation:boolean=false;

  tableList: Array<AccountMasterDao> = [];
  applicationArr: Array<string> = [];
  serviceTypeArr: Array<string> = [];
  accountModeArr: Array<string> = [];
  accountTypeArr: Array<string> = [];
  deductionTypeArr: Array<string> = [];
  rechargeTypeArr: Array<string> = [];
  isInsert = true;
  private updateId: number;
  // tslint:disable-next-line:max-line-length
  constructor(public user: UserService, private accountService: AccountMasterService, private _formBuilder: FormBuilder, private application: ApplicationsService, private dialog: MatDialog, private alert: AlertMessageService, private counter: UidGenerationService, private userServie: UsermasterService) {
    if (this.hasWriteOrUpdatePermission) {
      this.application.getAllServices().subscribe(value => {

        this.applicationArr = value;
      });
      this.application.getAllServiceType().subscribe(value => {


        this.serviceTypeArr = value;

      });


      this.application.getAllAccoutMode().subscribe(value => {
        this.accountModeArr = value;
      });
      this.application.getaccountType().subscribe(value => {
        this.accountTypeArr = value;
      });
      this.application.getdeductionType().subscribe(value => {
        this.deductionTypeArr = value;
      });
      this.application.getrechargeType().subscribe(value => {
        this.rechargeTypeArr = value;
      });

    }
    this.parentId = user.accountMasterDao.id;
    this.refreshData();

  }
   refreshData()
  {

    this.accountService.getAccountsByUsername().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }



  addService() {
    const service: AccountServiceFormDao = {
      accountDetails: {
        active: true,
        serviceName: this.applicationArr[0],
        accountMode: this.accountModeArr[0],
        serviceType: this.serviceTypeArr[0],
        whatsappCreditDao: {
          accountType: this.accountTypeArr[0],
          deductionType: this.deductionTypeArr[0],
          expiryDate: '',
          expiryDateFilter: 0,
          extraParameters: null,
          perSpendDeduction: 0,
          postPaidMaximumAllowed: 0
        }
      },
      services: this.applicationArr,
      accountMode: this.accountModeArr,
      accountType: this.accountTypeArr,
      deductionType: this.deductionTypeArr,
      serviceType: this.serviceTypeArr
    };
    this.dialog.open(AccountServicesComponent, {data: service}).afterClosed().subscribe(value => {

      if (value != null) {
        console.log('will push data ', value);
        if (this.accountMasterDao.services == undefined) {
          this.accountMasterDao.services = [value];
        } else {
          this.accountMasterDao.services.push(value);
        }

      }


    });
  }

  addNew() {
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
    this.accountMasterDao = {
      accountEmail: '',
      accountLogo: '',
      accountMinLogo: '',
      accountName: '',
      accountPhone: '',
      active: true,
      enableReseller:false,
      createAt: '',
      createBy: '',
      footer: '',
      id: 0,
      services: [],
      title: '',
      updateAt: '',
      updateBy: '',
      parentId: this.parentId,
      reseller: false,
      creditType: CreditType.SELF,
      accountTransactionReportDao:null,
      resellerReportDao:null,
      password:'',
      serviceName:'',
      agentStatus:true,
      enableAgentSupport:false


    };
    this.isInsert = true;
  }

  // tslint:disable-next-line:typedef
  cancelBtn() {
    $('#addnewdiv').css('display', 'none');
    $('#tablediv').fadeIn();
  }
  editAccount(account: AccountMasterDao, ind: number)
  {

    this.updateId = ind;

    this.isInsert = false;


    console.log('account', account);
    this.accountMasterDao = account;

    console.log('accountMasterDao', this.accountMasterDao);
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
  }

  ngOnInit(): void {

  }

  edit(ind: number, service: AccountServiceDao) {
    console.log(service);
    const services: AccountServiceFormDao = {
      accountDetails: service,
      services: this.applicationArr,
      accountMode: this.accountModeArr,
      accountType: this.accountTypeArr,
      deductionType: this.deductionTypeArr,
      serviceType: this.serviceTypeArr
    };
    this.dialog.open(AccountServicesComponent, {data: services}).afterClosed().subscribe(value => {
      if (value != null) {
        this.accountMasterDao.services[ind] = value;
      }


    });
  }

  // tslint:disable-next-line:typedef
  async updateOrIns(accountMasterDao: AccountMasterDao, isInsert: boolean, showConfirmMessage: boolean) {

    // tslint:disable-next-line:triple-equals
    if (accountMasterDao.reseller == false)
    {
      accountMasterDao.parentId = this.parentId;
      accountMasterDao.reseller = false;
    }
    // accountMasterDao.parentId = this.parentId;
    console.log('will update account {}', accountMasterDao);
    if (showConfirmMessage) {
      let message = 'Update Account?';
      if (isInsert) {
        message = 'Create Account?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }
    if (isInsert) {
      this.counter.generateCounterNumber(1, 1).then(id => {
        const serviceList = [];
        debugger;
        for (let i = 0; i < accountMasterDao.services.length; i++)
        {
          serviceList.push(accountMasterDao.services[i].serviceName);
        }
        accountMasterDao.password = accountMasterDao.accountPhone;
        accountMasterDao.id = id;
        this.accountService.insert(accountMasterDao).subscribe(value => {
        this.accountMasterDao = value;
        this.isInsert = false;
        const user: UserMasterDao = {
          accountId: id,
          otpVerified:false,
          hasTwoStepAuthencation:false,
          accountMasterDao: null,
          accountType: 'ADMIN',
          active: true,
          createAt: '',
          createBy: '',
          email: value.accountEmail,
          fullname: value.accountName,
          id,
          password: value.accountPhone,
          permissions: undefined,
          phone: value.accountPhone,
          services: undefined,
          servicesList: serviceList.join(','),
          updateAt: '',
          updateBy: '',
          username: id.toString(),
          enableAgentSupport:false,
          agentStatus:false
        };
        this.userServie.createUser(user).subscribe(userObj => {
          if (showConfirmMessage) {


            this.alert.showSuccesMessage('Successfully Created Account.Please Login in using your Account Id and PhoneNumber');
          }
          this.cancelBtn();
        });

        this.tableList.unshift(value);

      }, error => {

      });
      });
    } else {


      this.accountService.update(accountMasterDao).subscribe(value => {
        this.accountMasterDao = value;
        this.tableList[this.updateId] = value;
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Updated Account');
        }
        this.cancelBtn();

      }, error => {

      });
    }

  }


  delete(ind: number, service: AccountServiceDao) {
    this.alert.confirmMessage('Delete ' + service.serviceName + ' Service?', 'Are you sure?').then(value => {
      this.accountMasterDao.services.splice(ind, 1);
      this.updateOrIns(this.accountMasterDao, false, false);
    });
  }

  // tslint:disable-next-line:typedef
  createOrUpdate() {
    this.updateOrIns(this.accountMasterDao, this.isInsert, true);
  }


  // tslint:disable-next-line:typedef
  rechare(account: AccountMasterDao) {
    this.dialog.open(RechargeComponent, {
      width:'50%',
      data: {account, rechargeType: this.rechargeTypeArr}});
  }


  // tslint:disable-next-line:typedef
  addSubscription(service: AccountServiceDao) {
    console.log('Service ', service);
    // tslint:disable-next-line:triple-equals
    if (service.serviceName == 'WHATSAPPUNOFFICIAL')
    {
      const subForm: SubscriptionFormDao = {accountId: this.accountMasterDao.id};
      this.dialog.open(SubscriptionWaUnComponent, {data: subForm});
      // tslint:disable-next-line:triple-equals
    }else if (service.serviceName == 'WHATSAPPOFFICIAL'){

      const subForm: SubscriptionFormDao = {accountId: this.accountMasterDao.id};
      this.dialog.open(SubscriptionComponent, {data: subForm});
    }
  }

  // tslint:disable-next-line:typedef
  addRole(id: number) {
    const user: UserMasterDao = {
      accountId: id,
      otpVerified:false,
      hasTwoStepAuthencation:false,
      accountMasterDao: undefined,
      accountType: '',
      active: false,
      createAt: '',
      createBy: '',
      email: '',
      fullname: '',
      id,
      password: '',
      permissions: undefined,
      phone: '',
      services: undefined,
      servicesList: '',
      updateAt: '',
      updateBy: '',
      username: '',
      enableAgentSupport:false,
      agentStatus:false
    };
    this.dialog.open(RoleAndPrivComponent, {data: user});

  }

  addCountryRates(id: number) {
  const dialogRef=this.dialog.open(CountrywiserateComponent,{data:id, maxHeight:600});
    dialogRef.afterClosed().subscribe(value =>{
      console.log('values are:',value);
    })

  }
}
