import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RoleMasterDao} from '../../rolemaster/RoleMasterDao';
import {environment} from '../../../environments/environment.prod';
import {ServiceMasterDao} from '../ServiceMasterDao';

@Injectable({
  providedIn: 'root'
})
export class ServiceMasterService {

  constructor(private http:HttpClient) { }
  getRoles():Observable<Array<ServiceMasterDao>>
  {
    return this.http.get<Array<ServiceMasterDao>>(environment.appUrl+environment.authMicroservice+"service/get");
  }

  saveRole(role:ServiceMasterDao):Observable<ServiceMasterDao>
  {
    return this.http.post<ServiceMasterDao>(environment.appUrl+environment.authMicroservice+"service/save",role);
  }
  getByRole():Observable<Array<ServiceMasterDao>>
  {
    return this.http.get<Array<ServiceMasterDao>>(environment.appUrl+environment.authMicroservice+"service/getservicebyuser");
  }

}
