import {Component, OnInit} from '@angular/core';
import {AccountMasterService} from "../account-master/AccountMasterService/account-master.service";
import {AccountMasterDao} from "../account-master/AccountMasterDao";
import {Subject} from "rxjs";
import {RechargeService} from "../account-master/recharge/RechargeService/recharge.service";
import {RechargeDao} from "../account-master/recharge/RechargeDao";
import {formatDate} from "@angular/common";
import {PageEvent} from "@angular/material/paginator";
import {UserMasterDao} from "../Services/User/UserMasterDao";
import {UserService} from "../Services/User/user.service";
import {expand} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-credit-debit-report',
  templateUrl: './credit-debit-report.component.html',
  styleUrls: ['./credit-debit-report.component.css']
})
export class CreditDebitReportComponent implements OnInit {
  tableList: Array<AccountMasterDao> = [];
  services: Array<string> = [];
  dtOptions: any = {bDestroy: true, order: []};
  dtTrigger: Subject<any> = new Subject<any>();
  showDetail = '';
  index: number;
  rechargeList: Array<Map<string, string>>;
  rechargList: any = {accountId: '', name: '', email: '', credit: '', debit: ''};
  accountWiseCredit: Array<any> = [];
  customerIndex = 0;
  customerWiseCrDrDetailList: Array<Array<RechargeDao>> = []
  creditdebitDetail: Array<RechargeDao> = [];

  listofcreditdebitDetail: Array<any> = []
  isExpand: boolean = false;
  rechargeDao: any = {
    accountId: 0,
    startDate: '',
    endDate: '',
    service: ''
  }
  accountId: any;
  name: any;
  email: any;
  credit: any;
  debit: any;
  startDate: any;
  endDate: any;
  servicesel: string;

  constructor(private accountService: AccountMasterService, private rechargeService: RechargeService, public user: UserService, private router: Router) {

  }

  ngOnInit(): void {
    this.servicesel = this.router.url.split('/v1')[0].replace('/', '');
    this.refreshData();
  }

  getBalance() {
    this.rechargeDao.startDate = this.startDate.split('-').join('');
    this.rechargeDao.endDate = this.endDate.split('-').join('');
    this.rechargeService.getByAccount(this.rechargeDao).subscribe(value => {
      this.rechargeList = new Array<Map<string, string>>();
      this.rechargeList = value;
      console.log('rechargeList', this.rechargeList);
      this.showDetail = 'Expand'
      this.dtTrigger.next();
    })
  }


  refreshData() {
    this.accountService.getAccountsByUsername().subscribe(value => {
      let accountType = this.user.user.accountType;
      let userId = this.user.accountMasterDao.id;
      if (accountType == 'MASTERADMIN') {
        this.tableList = value;
        this.services.push('WHATSAPPOFFICIAL')
        this.services.push('WHATSAPPUNOFFICIAL')
      } else if (accountType = 'ADMIN') {
        for (let i = 0; i <= value.length; i++) {
          if (value[i].parentId == userId) {
            this.tableList.push(value[i]);
          } else if (value[i].id == userId) {
            this.tableList.push(value[i]);
            console.log('services length ', value[i].services.length);
            for (let k = 0; k < value[i].services.length; k++) {
              console.log('ser', value[i].services[k].serviceName);
              this.services.push(value[i].services[k].serviceName);
            }
          } else {
          }
        }

      } else {

        for (let i = 0; i <= value.length; i++) {
          if (value[i].id == userId) {
            this.tableList.push(value[i]);
            if (this.servicesel == 'waunofficial') {
              this.services[0] = 'WHATSAPPUNOFFICIAL';
            } else if (this.servicesel == 'wabuissness') {
              this.services[0] = 'WHATSAPPOFFICIAL';
            } else {

            }

          }
        }
      }
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  tableChange($event: PageEvent) {
    console.log(this);
  }

  getCreditDebit(userId: any, index: any) {
    this.customerWiseCrDrDetailList[index] = [];
    this.customerIndex = index;
    this.isExpand = true;
    this.rechargeDao.accountId = userId;
    this.showDetail = 'Collapse'
    this.rechargeDao.startDate = this.rechargeDao.startDate.split('-').join('');
    this.rechargeDao.endDate = this.rechargeDao.endDate.split('-').join('');
    this.rechargeService.getCreditDebitDetail(this.rechargeDao).subscribe(value => {
      this.customerWiseCrDrDetailList[index] = value;
      userId = null;
      index = null;
    })
  }


  private close() {
    this.rechargeDao = null;
  }


}
