import {Component, Inject, OnInit} from '@angular/core';
import {WaContactGroupDao} from '../wa-contact-group-dao';
import {any} from 'codelyzer/util/function';
import {WaContactGroupServiceService} from '../wa-contact-group-service.service';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FeatureDao} from "../../../Feature/feature-dao";

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.css']
})
export class GroupAddComponent implements OnInit {
  private tableList: Array<WaContactGroupDao>;

  // tslint:disable-next-line:max-line-length
  constructor(private service: WaContactGroupServiceService, private alert: AlertMessageService, private dialogRef: MatDialogRef<WaContactGroupServiceService>,@Inject(MAT_DIALOG_DATA) public data: WaContactGroupDao) {
    if (this.data!=null)
    {
        this.groupadd=this.data;
    }
  }

  isInsert:boolean;
  groupName: string;
  groupDesc: string;
  createdBy: string;

groupadd: any = {
  id:  '',
  groupName: '',
  groupDesc: '',
  createdBy: '',
  totalUser: '',
  userId: '',
  date: '',
  dateFilter: '',
  status: '',
};

  ngOnInit(): void {


  }
  // tslint:disable-next-line:typedef
  addGroup() {

    this.updateOrIns(this.data, this.isInsert, true);
  }

  async updateOrIns(data: any, isInsert: boolean, showConfirmMessage: boolean) {

    if (showConfirmMessage) {
      let message = 'Update Group?';
      if (isInsert) {
        message = 'Create Group?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }

    if (isInsert){

      this.alert.showLoader();
      this.alert.confirmMessage('Insert Data?', 'Are you sure?').then(value2 => {
        // tslint:disable-next-line:max-line-length
        this.service.groupAdd(this.groupadd).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully inserted ');
          this.close();
          this.alert.hideLoader();
        }, error => {
          this.alert.showErrorMessage(error.error.message);
          this.alert.hideLoader();
        });
      });
    }
    else {

      this.alert.showLoader();
      this.alert.confirmMessage('Insert Data?', 'Are you sure?').then(value2 => {
        // tslint:disable-next-line:max-line-length
        this.service.updateGroup(this.groupadd).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully inserted ');
          this.close();
          this.alert.hideLoader();
        }, error => {
          this.alert.showErrorMessage(error.error.message);
          this.alert.hideLoader();
        });
      });

    }

  }
// tslint:disable-next-line:typedef
editgroup()
{

}
  // tslint:disable-next-line:typedef
  get(){
    this.service.get().subscribe(value => {
      this.tableList = value;
      console.log('TableList values are:: {}' + this.tableList);
    });
  }

  // tslint:disable-next-line:typedef
  close(){
    this.dialogRef.close(true);
  }
}
