import { Injectable } from '@angular/core';
import {FilterDao} from '../../crdrreport/FilterDao';
import {Observable} from 'rxjs';
import {PaginationDao} from '../../CommonObj/PaginationDao';
import {AccountPostingDao} from '../AccountPostingDao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
const appPrefix = environment.appUrl + environment.accountMicroverservice;
@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  public transactionTypeArr:Array<string>;


  constructor(private http:HttpClient) { }

  getData(filter:FilterDao):Observable<PaginationDao<AccountPostingDao>>
  {
    return this.http.post<PaginationDao<AccountPostingDao>>(appPrefix + 'accounttransaction/gettrans', filter);
  }
}
