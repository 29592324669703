
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()"  class="btn btn-add">Add  Price<i
            class="fa fa-plus"></i></button>

          <button (click)="bulkUpload(0)"  class="btn btn-add">Add Bulk Prices <i
            class="fa fa-plus"></i></button>

          <button (click)="bulkUpload(1)"  class="btn btn-add">Update Prices <i
            class="fa fa-rupee"></i></button>

          <button (click)="bulkUpload(2)"  class="btn btn-add">Update Stock <i
            class="fa fa-cubes"></i></button>

          <button (click)="bulkUpload(3)"  class="btn btn-add">Update Discount <i
            class="fa fa-cubes"></i></button>

          <i (click)="downloadSampleFile()" title="Click Here To Download Sample File" style="color: red;cursor: pointer;margin-left: 10px" class="fa fa-question-circle"></i>
          <br>


        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Material  <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="priceChartDao.material" name="material" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Feed Type  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="priceChartDao.feedType" name="feedType" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Sub Feed Type <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="priceChartDao.subFeedType" name="subFeedType" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Product <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="priceChartDao.product" name="productCategory" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Brand <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="priceChartDao.brand" name="product" required>
            </div>

            <div class="form-group col-lg-12">
              <label>State <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="priceChartDao.state" name="state" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Price <sup class="required">*</sup></label>
              <input type="number" step="0.01" class="form-control" [(ngModel)]="priceChartDao.price" name="price" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Discount <sup class="required">*</sup></label>
              <input type="number" step="0.01" class="form-control" [(ngModel)]="priceChartDao.discount" name="discount" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Weight <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="priceChartDao.weight" name="weight" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Stock <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="priceChartDao.stock" name="stock" required>
            </div>

            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>


        <div *ngIf="!addNewDiv">

          <div id="tablediv">

            <div>
              <div class="table table-striped row-border hover">
                <input type="text" id="filter-text-box" placeholder="Filter..." (keyup)="onFilterTextBoxChanged()"/>

                <ag-grid-angular
                  row-animation
                  pagination="true"
                  class="ag-theme-alpine ag-gr"
                  style="width: 100%;padding-bottom: 100px"
                  [columnDefs]="gridColumnDefs"
                  [quickFilterText]="searchValue"

                  (gridReady)="onGridReady($event)"
                >

                </ag-grid-angular>

              </div>

            </div>


          </div>
        </div>

      </div>
    </div>
  </div>
</div>







