
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu"  >
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <h3 style="text-align: center">Add Contact Number </h3>
        </div>
      </div>
      <div style="" class="panel-body">
        <form ngNativeValidate #groupMasterForm="ngForm"  (ngSubmit)="save()">
          <br>
          <div class="form-check">
            <label class="control-label">Status<sup class="required">*</sup></label>
            <br>
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="status" [(ngModel)]="groupMaster.status" value="true" checked >Active&nbsp;&nbsp;&nbsp;
            </label>
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="status" [(ngModel)]="groupMaster.status" value="false">Inactive
            </label>
          </div>
          <br>
          <div class="form-group col-lg-12">
            <label>Client Name<sup class="required">*</sup></label>
            <input type="text" class="form-control"  [(ngModel)]="groupMaster.clientName" name="clientName" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Email<sup class="required">*</sup></label>
            <input type="email" class="form-control"  [(ngModel)]="groupMaster.email" name="email" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Phone Number<sup>Sample (919999999999)</sup></label>
            <input type="text" class="form-control"  [(ngModel)]="groupMaster.phonenumber" name="phonenumber" required>
          </div>

          <div class="form-group col-lg-12">
            <label>Type<sup class="required">*</sup></label>
            <input type="text" class="form-control"  [(ngModel)]="groupMaster.type" name="type" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Address<sup class="required">*</sup></label>
            <input type="text" class="form-control"  [(ngModel)]="groupMaster.address" name="address" required>
          </div>

          <div  class="col-md-12 form-group">
            <label class="control-label">Group</label>
            <select  id="userlistdatalist" [(ngModel)]="groupMaster.groupId" name="groupId" class="form-control">
              <ng-container *ngFor="let group of tabelist">
                 <option   [value]="group.id" [innerHTML]="group.groupName"></option>

              </ng-container>

            </select>
          </div>
          <div class="form-group col-lg-12">
            <button type="submit" class="btn btn-add">Save</button>
            <button type="button"  (click)="cancel()" class="btn btn-danger">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

