import { Component, OnInit } from '@angular/core';
import {PaginationDao} from '../../CommonObj/PaginationDao';
import {IncommingSmsDao} from '../../MicroServices/GsmGateway/Oppo/incomming-sms-report/IncommingSmsDao';
import {UserService} from '../../Services/User/user.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment.prod';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {CampaignReportComponent} from '../../campaign-report/campaign-report.component';
import {CampaignDao} from '../onetomanywsun/campaignService/CampaignDao';
import {CampaignServiceService} from '../onetomanywsun/campaignService/campaign-service.service';
import {FilterReportComponent} from './filter-report/filter-report.component';

@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.css']
})
export class ClientReportComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(public user: UserService, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router, private campaign: CampaignServiceService) { }
  tableData: PaginationDao<CampaignDao> = {data: [], total: 0};
  offset = 0;
  limit = 10;
  userId = 0;
  startdate: number = undefined;
  enddate: number = undefined;


  // tslint:disable-next-line:typedef
  dtTrigger: any;
  dtOptions: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;
      this.userId = queryParams.accountId;
      console.log('start date:: enddate:: userId', this.startdate, this.enddate, this.userId);
      this.loadData();
    });

  }

  // tslint:disable-next-line:typedef
  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined || this.enddate == undefined || this.userId == undefined) {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);

      this.userId = 0;
    }
    // tslint:disable-next-line:triple-equals
    if (this.userId == undefined) {
      this.userId = 0;

    }

    console.log('ccccstart date:: enddate:: userId::  ', this.startdate, this.enddate, this.userId);
    // tslint:disable-next-line:max-line-length
  //  this.http.get<PaginationDao<IncommingSmsDao>>(environment.appUrl + environment.whatsappunOfficialservice + 'v1/campaing/resslerReport?userId=' + this.userId + '&offset=0&limit=200000&startDate=' + this.startdate + '&endDate=' + this.enddate).subscribe(value => {
    this.http.get<PaginationDao<CampaignDao>>( environment.appUrl + environment.whatsappunOfficialservice + 'v1/campaing/resslerReport?userId=' + this.userId + '&startDate=' + this.startdate + '&endDate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit).subscribe(value => {
      this.tableData = value;

      console.log('Reports are:: ', value);
      this.alert.hideLoader();
    });

  }

  // tslint:disable-next-line:typedef
  tableChange(json) {
    this.offset = json.pageIndex * json.pageSize;
    this.limit = json.pageSize;
    this.loadData();

  }

  // tslint:disable-next-line:typedef
  filterData() {
    this.dialog.open(FilterReportComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  // tslint:disable-next-line:typedef
  downloadFile(user: CampaignDao, ind: number) {
    this.alert.showLoader();
    // tslint:disable-next-line:max-line-length
    this.campaign.getCampaignReport({enddate: '20220801', id: user.id, idNum: 0, page: 0, startdate: '20220901', accountId: user.accountId,reportBy:0}).subscribe(value => {

      const file = new FileService<any>();
      const trArr = [];
      let tdArr = [];


      tdArr.push('Message-Id');
      tdArr.push('DST');
      tdArr.push('Content Type');
      tdArr.push('Content');
      tdArr.push('Caption');
      tdArr.push('Message Status');
      tdArr.push('Date');
      tdArr.push('DLR TIME');
      tdArr.push('IncId');
      trArr.push(tdArr);
      for (let i = 0; i < value.recordsTotal; i++)
      {
        tdArr = [];
        tdArr.push(value.data[i].id);
        tdArr.push(value.data[i].dst);
        tdArr.push(value.data[i].messageType);
        console.log(value.data[i].messageType.toString());
        switch (value.data[i].messageType.toString()) {
          case 'TEXT':
            tdArr.push(value.data[i].textMessage.content);
            tdArr.push('');
            break;
          case 'FILE':
            tdArr.push(value.data[i].fileMessage.fileUrl);
            tdArr.push(value.data[i].fileMessage.caption);
            break;

          default:
            tdArr.push('');
            tdArr.push('');
            break;
        }
        tdArr.push(value.data[i].messageStatus);

        tdArr.push(value.data[i].dateTiming.sentTime);
        tdArr.push(value.data[i].dateTiming.dlrTime);

        if (value.data[i].promotional)
        {
          tdArr.push(0);
        }
        else {
          tdArr.push(value.data[i].incId);
        }


        trArr.push(tdArr);


      }
      file.generateFile(trArr, 'Campaign_Report.xlsx');
      this.alert.hideLoader();

    });
  }

}
