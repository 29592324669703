export class PaymentDetailsDao{

    id: string;
    name: string;
    keyId: string;
    keySecret: string;
    amount:number;
    currency: string;
    accountId:number;
    createDate: string;
    createDateFilter:number;
    orderId: string;
    paymentId: string;
    paymentGatway: PaymentGatway;
    signature: string;
    paymentGatwayStatus: PaymentGatwayStatus;
}
export enum PaymentGatway{
    RAZORPAY
}
export enum PaymentGatwayStatus{
    CREATED, PENDING, SUCCESFULL, FAILED
}
