import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-picker-component',
  templateUrl: './date-picker-component.component.html',
  styleUrls: ['./date-picker-component.component.css']
})
export class DatePickerComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
