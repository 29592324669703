import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConversationDao} from '../../conversation/ConversationDao';
import {PaginationDao, WAPagination} from '../../CommonObj/PaginationDao';
import {FileMessageDao, MessageDao, MessageStatus, MessageType} from '../DaoMessageUn';
import {environment} from '../../../environments/environment.prod';
import {CampaignEntryDao} from '../onetomanywsun/CampaignEntryDao';
import {MessageResponseDao} from '../onetomanywsun/campaignService/MessageResponseDao';
const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class ConversationwaunService {
  constructor(private http: HttpClient) { }
  getConversationUnread(): Observable<Array<ConversationDao>>
  {
    return  this.http.get<Array<ConversationDao>>(appPrefix + 'conversation/getunreadconversation');
  }
  getConversation(search: string, offset: number, limit: number): Observable<WAPagination<ConversationDao>>
  {
    return  this.http.post<WAPagination<ConversationDao>>(appPrefix + 'conversation/', {offset, limit, search});
  }
  // tslint:disable-next-line:typedef
  updateReadStatus(id: string)
  {
    return  this.http.get<ConversationDao>(appPrefix + 'conversation/updatests?id=' + id);
  }
  getMessages(offset: number, limit: number, id: string): Observable<Array<MessageDao>>
  {
    // tslint:disable-next-line:max-line-length
     return  this.http.post<Array<MessageDao>>(appPrefix + 'conversation/getmessagesbyconversation', {offset, limit, dst: id});
  }
  getMessageAfterTime(id: string, afterDate: number): Observable<Array<MessageDao>>
  {
    return  this.http.get<Array<MessageDao>>(appPrefix + 'conversation/getmsgafter?to=' + id + '&dateTiming=' + afterDate);
  }

  // tslint:disable-next-line:typedef
  saveContact(contactName: string, id: string)
  {
    return  this.http.get<Array<ConversationDao>>(appPrefix + 'conversation/savecontact?id=' + id + '&contact=' + contactName);
  }

  startCampaign(message: MessageDao): Observable<any>{
    return this.http.post(environment.driveLocationUrl + 'gateway/waunofficial/v1/api/v2/message', message);
  }

  startCampaing(data: CampaignEntryDao): Observable<Array<MessageResponseDao>>
  {
    return this.http.post<Array<MessageResponseDao>>(appPrefix + 'v1/campaing/', data);
  }
}
