import {Component, Inject, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {UserService} from "../../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";

import {
  ButtonMessageDao,
  ButtonMessageInnerDao,
  ConversationDao,
  ListMessageButton,
  ListMessageRow,
  MessageType
} from "./ConversationDao";
import {DynamicChatbotService} from "../../Service/dynamic-chatbot.service";
import {TemplateButtonMessage} from "../../../../Unofficial/DaoMessageUn";


// export class ConversationFlowComponent implements OnInit {

@Component({
  selector: 'app-conversation-flow',
  templateUrl: './conversation-flow.component.html',
  styleUrls: ['./conversation-flow.component.css']
})
export class ConversationFlowComponent implements OnInit {

  @Output() public  eventEmitter =new EventEmitter<string>();
  tableData: Array<ConversationDao> = [];
  offset: number = 0;
  limit: number = 10;
  addNewDiv: boolean = true;
  chatbodId:string;
  keyTypeFlow=true;
  keyTypeFunctional=false;
  keyRequired=true;
  keyValueRequired=false;
  showChangeTypeRadio=false;
  keyType="flow";
  submitBtnTxt:String='Create';
  @Input() isFirstFlow:boolean=false;

  listValueArray:Array<ListMessageRow>=[{rowId:"1",title:"",description:""}]
  btnValueArray:Array<ButtonMessageInnerDao>=[{buttonId:"1",type:"1",buttonText:{displayText:""}}]
  // @ts-ignore
  buttonMessage:ButtonMessageDao={contentText:"",footerText:"",headerType:1,buttons:this.btnValueArray}

  sections:any=[{title:"", rows:this.listValueArray}]
  listMessageButton:ListMessageButton={buttonText:"",description:"",footer:"",text:"",title:"",listType:1,sections:this.sections}
  templateButtons:any= [
      {index: "1", callButton: {displayText: "", phoneNumber: ""}},
    {index: "2", urlButton: {displayText: "", url: ""}},
    {index: "3", quickReplyButton: {displayText: "",id:"id- like-buttons-message"}},
    {index: "4",quickReplyButton: {displayText: "",id:"id- like-buttons-message1"}},
    {index: "5",quickReplyButton: {displayText: "", id:"id- like-buttons-message2"}}
  ]
  templateButtonMessage: TemplateButtonMessage = {text: "", footer: "", templateButtons: this.templateButtons, caption: "", image: {url:""}, video: {url:""} };
  chatbotConversionDao:ConversationDao={
    messageDao: undefined,
    stageAction: 0,
    changeStage: 0,
    changeStageOnInvalid: 0,
    chatbotId: "",
    deleteStage: 0,
    id: "",
    key: "",
    keyValue: "",
    nextStage: "",
    changeStageType:0,
    validInputs: undefined,
    actionType:undefined

  }

  constructor(public user:UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,

              private dynamicChatbotService:DynamicChatbotService,
              @Inject(MAT_DIALOG_DATA) public data: ConversationDao) {
       // @ts-ignore
    this.submitBtnTxt=data.submitBtnTxt;

    // @ts-ignore
    this.chatbotConversionDao=data.conversationDao;
    // @ts-ignore
    this.isFirstFlow=data.isFirstFlow
    if (this.chatbotConversionDao.changeStage == 1){
      this.chatbotConversionDao.stageAction=1;
    }else if (this.chatbotConversionDao.deleteStage == 1){
      this.chatbotConversionDao.stageAction=2;
    }else{
      this.chatbotConversionDao.stageAction=0;
    }
    if(this.chatbotConversionDao.keyValue!=null && this.chatbotConversionDao.keyValue!=undefined&&this.chatbotConversionDao.keyValue!=""){
    this.keyTypeChange("functional")
    }
    console.log("this.chatbotConversionDao ",this.chatbotConversionDao)
    if(this.chatbotConversionDao.actionType.toString()=='LISTS'){
      console.log("test list");
      if(this.chatbotConversionDao.messageDao.listMessage!=undefined){
        console.log("test list not undefined");
        this.listMessageButton=this.chatbotConversionDao.messageDao.listMessage;//.sections[0].rows;
        this.listValueArray=this.listMessageButton.sections[0].rows
      }else{
        console.log("test list undefined");
        this.chatbotConversionDao.messageDao.listMessage=this.listMessageButton;
      }
      console.log("listMessageButton :: ",this.listMessageButton)

    }


    if(this.chatbotConversionDao.actionType.toString()=='BUTTONS'){
      if(this.chatbotConversionDao.messageDao.buttonMessage!=undefined){
        this.buttonMessage=this.chatbotConversionDao.messageDao.buttonMessage;//.sections[0].rows;
        // @ts-ignore
        this.btnValueArray=this.buttonMessage.buttons
      }else{
        console.log("test button undefined");
        this.chatbotConversionDao.messageDao.buttonMessage=this.buttonMessage;
      }
    }

    if(this.chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTON' || 'TEMPLATEBUTTONIMAGE' || 'TEMPLATEBUTTONVIDEO'){
      console.log("templatebutton");
      if(this.chatbotConversionDao.messageDao.templateButtonMessage!=undefined){
        console.log("templatebutton not undefined");
        this.templateButtonMessage=this.chatbotConversionDao.messageDao.templateButtonMessage;
        //.sections[0].rows;
        this.templateButtons=this.templateButtonMessage.templateButtons
      }else{
        console.log(" templatebutton undefined");
        this.chatbotConversionDao.messageDao.templateButtonMessage=this.templateButtonMessage;
      }
    }
    console.log("is first flow",this.isFirstFlow)
    if (this.isFirstFlow){

      this.chatbotConversionDao.key='0';
       this.chatbotConversionDao.changeStageType=1
      this.chatbotConversionDao.stageAction=1
      this.chatbotConversionDao.nextStage="WELCOME"

      this.chatbotConversionDao.changeStage=1
    }

    }// END OF CONSTRUCTOR

    //this.chatbotConversionDao.validInputs=this.chatbotConversionDao.validInputs.join(",")




  ngOnInit(): void {
    //this.loadData();

  }

  // loadData() {
  //   // @ts-ignore
  //   this.dynamicChatbotService.getAllConversation(this.offset,this.limit).subscribe(value => {
  //     this.tableData = value;
  //   });
  //   // this.alert.showLoader();

      //this.tableData.total = value.length;
  //     this.alert.hideLoader();
  //
  // }
  // tableChange(json) {
  //   this.offset = json.pageIndex;
  //   this.limit = json.pageSize;
  //   this.loadData();
  //
  // }

  cancel() {

  }


  updateFlow(conversationDao:ConversationDao) {
    // @ts-ignore
    this.dynamicChatbotService.updateConversationDao(conversationDao).subscribe(value => {
    });
  }

  submitFlowForm(conversationDao:ConversationDao) {

    // @ts-ignore

    if (conversationDao.actionType == '' ){
      this.alert.showErrorMessage('action type can not be blank');
      return;

    }

    if(this.keyType=="flow" && conversationDao.key==""){
      this.alert.showErrorMessage('key can not be blank');
      return;

    }

    if(this.keyType=="functional" && conversationDao.keyValue==""){
      this.alert.showErrorMessage('key can not be blank ');
      return;

    }

    if (conversationDao.actionType.toString()=='LISTS'){
      this.sections.rows=this.listValueArray;
      console.log("listValueArray ",this.listValueArray)
      conversationDao.messageDao.listMessage=this.listMessageButton
    }
    // @ts-ignore
    console.log("validInputs:", conversationDao.validInputs)
    if (conversationDao.validInputs != ""){
      conversationDao.validInputs = conversationDao.validInputs.split(',');
  }
  else{
      conversationDao.validInputs=[]
    }

    console.log("conversationDao:",conversationDao)
    if(this.submitBtnTxt=="Create"){
      this.dynamicChatbotService.insertConversationDao(conversationDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
      });
    }
  else {
      this.dynamicChatbotService.updateConversationDao(conversationDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }


  }

  keyTypeChange(value: any) {
    console.log("chkbox ",value)
    if (value == "flow"){
      this.keyType="flow"
      this.keyTypeFlow=true;
      this.keyTypeFunctional=false;
      this.keyRequired=true;
      this.keyValueRequired=false;
    }else{
      this.keyType="functional"
      this.keyTypeFlow=false;
      this.keyTypeFunctional=true;
      this.keyRequired=false;
      this.keyValueRequired=true;
    }
  }

  stageActionChange(target: EventTarget) {
    // @ts-ignore
    let value = target.value;
    if(value == '0'){
      this.chatbotConversionDao.changeStage=0
      this.chatbotConversionDao.deleteStage=0
      this.showChangeTypeRadio=false;
    }else if(value == '1'){
      this.showChangeTypeRadio=true;

      this.chatbotConversionDao.changeStage=1
      this.chatbotConversionDao.deleteStage=0
    }else{
      this.showChangeTypeRadio=false;


      this.chatbotConversionDao.changeStage=0
      this.chatbotConversionDao.deleteStage=1
    }
  }


  closeFlowForm() {
    console.log("close flow form")
    // @ts-ignore
    this.dialog.closeAll(ConversationFlowComponent,{width: '830px',
      height: '600px'


    });
  }

  addNewRow(){
    console.log("add new row")

    let newId = this.listValueArray.length+1;
    this.listValueArray.push({rowId:""+newId,title:"",description:""})
    console.log(this.listValueArray)
  }

  checkValidation(){

    // @ts-ignore

  }



  addNewBtn(){
    console.log("add new Btn")
if(this.btnValueArray.length==3){
  return;
}
    let newId = this.btnValueArray.length+1;
    this.btnValueArray.push({buttonId:''+newId,type:"1",buttonText:{displayText:""}})
    console.log(this.btnValueArray)
  }

  actionTypeChange() {
    console.log("Action Type changed to ",this.chatbotConversionDao.actionType)

    if (this.chatbotConversionDao.actionType.toString()=='LISTS'){
         this.chatbotConversionDao.messageDao.listMessage=this.listMessageButton
         console.log(this.chatbotConversionDao.messageDao.listMessage)
    }
    else{
      console.log("test")
    }

    if (this.chatbotConversionDao.actionType.toString()=='BUTTONS'){
      this.chatbotConversionDao.messageDao.buttonMessage=this.buttonMessage
      console.log(this.chatbotConversionDao.messageDao.buttonMessage)
    }
    else{
      console.log("btn test")
    }
    if (this.chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTON' || 'TEMPLATEBUTTONIMAGE' || 'TEMPLATEBUTTONVIDEO'){
      this.chatbotConversionDao.messageDao.templateButtonMessage=this.templateButtonMessage
      console.log(this.chatbotConversionDao.messageDao.templateButtonMessage)
    }
    else{
      console.log("test template")
    }

  }



  deleteRow(rowId){
    console.log("delete  row")
    console.log(this.listValueArray.length)

    // @ts-ignore
    if(this.listValueArray.length>1){
      // @ts-ignore
      this.listValueArray.pop({rowId:""+rowId,title:"",description:""})

    }
    else{
      return;
    }

  }


  deleteBtn(btnId){
    // @ts-ignore
    if(this.btnValueArray.length>1){
      // @ts-ignore
      this.btnValueArray.pop({buttonId:''+btnId,type:"1",buttonText:{displayText:""}})

    }
    else{
      return;
    }

  }

}



