
<div class="row">
  <div class="col-md-12">
    <form  ngNoForm >

      <div class="form-group col-lg-12">
        <label>Chatbot ID </label>
        <input type="text" class="form-control" [(ngModel)]="chatbotConversionDao.chatbotId" name="material" required
               disabled>
      </div>

      <div class="form-group col-lg-6">
        <label id="example-radio-group-label"> Key Type </label>
        <br>
        <mat-radio-group aria-label="Select an option" (change)="keyTypeChange($event.value)" [(ngModel)]="keyType" name="keytype">
          <mat-radio-button [value]="'flow'" [checked]="true">Flow</mat-radio-button>
          <mat-radio-button [value]="'functional'">Functional</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="keyTypeFlow" class="form-group col-lg-6">
        <label>Key <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter flow key" [(ngModel)]="chatbotConversionDao.key"
               name="feedType" [required]="keyRequired">
      </div>

      <div *ngIf="keyTypeFunctional" class="form-group col-lg-6">
        <label>Key <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter functional key"
               [(ngModel)]="chatbotConversionDao.keyValue" name="feedType" [required]="keyValueRequired">
      </div>

      <div class="form-group col-lg-6">
        <label> Stage Action <sup class="required">*</sup></label>
        <select class="form-control" name="stageAction" [(ngModel)]="chatbotConversionDao.stageAction"
                (change)="stageActionChange($event.target)" [required]>
          <option value="0"> None</option>
          <option value="1"> Change</option>
          <option value="2"> Delete</option>
        </select>
      </div>


      <div class="form-group col-lg-6">
        <label> Action Type <sup class="required">*</sup></label>
        <select class="form-control" [(ngModel)]="chatbotConversionDao.actionType" name="actionType"
                (change)="actionTypeChange()" [required]>
          <option value="TEXT"> Text</option>
          <option value="FILE"> File</option>
          <option value="BUTTONS"> Buttons</option>-->
          <option value="LISTS"> List</option>
          <option value="TEMPLATEBUTTON"> Template Button</option>
          <option value="TEMPLATEBUTTONIMAGE"> Template Button (Image)</option>
          <option value="TEMPLATEBUTTONVIDEO"> Template Button (Video)</option>
        </select>
      </div>


      <div class="form-group col-lg-6">.
        <label> Change Type<sup class="required">*</sup></label>
        <select class="form-control" name="changeStageType" [(ngModel)]="chatbotConversionDao.changeStageType" [required]="showChangeTypeRadio" [disabled]="chatbotConversionDao.stageAction==2 || chatbotConversionDao.stageAction==0">
          <option value="0"> Dynamic</option>
          <option value="1"> Static</option>
        </select>
      </div>

      <div  class="form-group col-lg-6">
        <label>Next Stage <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter next stage "
               [(ngModel)]="chatbotConversionDao.nextStage" name="nextStage" [required]="showChangeTypeRadio" [disabled]="showChangeTypeRadio && chatbotConversionDao.changeStageType==0 || chatbotConversionDao.stageAction==2 || chatbotConversionDao.stageAction==0">
      </div>

      <div class="form-group col-lg-12">
        <label>Valid Inputs </label>
        <input type="text" class="form-control" [(ngModel)]="chatbotConversionDao.validInputs" name="validInputs">
      </div>

      <div *ngIf="chatbotConversionDao.actionType.toString()=='TEXT'" class="form-group col-lg-12">
        <label>Message </label>
        <textarea type="text" class="form-control" [(ngModel)]="chatbotConversionDao.messageDao.textMessage.content"
                  name="content"  required ></textarea>
      </div>

      <div *ngIf="chatbotConversionDao.actionType.toString()=='BUTTONS'">
        <div  class="form-group col-lg-12">
          <label>Text </label>
          <textarea type="text" class="form-control" [(ngModel)]="buttonMessage.contentText" name="'btnContentText"></textarea>
        </div>

        <div  class="form-group col-lg-12">
          <label>Footer </label>
          <input type="text" class="form-control" [(ngModel)]="buttonMessage.footerText" name="'btnFooterText">
        </div>

        <ng-container *ngFor="let row of btnValueArray;let i = index">
          <div  class="form-group col-lg-3">
            <label>Button </label>
            <input type="text" class="form-control"
                   [(ngModel)]="btnValueArray[i].buttonText.displayText" [name]="'button'+i">
          </div>
          <div class="form-group col-lg-1">
            <label></label>
            <button  type=button (click)="deleteBtn(i)" class=" form-control btn btn-danger "><i
              class="fa fa-trash" ></i></button>

          </div>
        </ng-container>
        <div  class="form-group col-lg-2">
          <label></label>
          <button type=button (click)="addNewBtn()" class="form-control"><i
            class="fa fa-plus"></i></button>
        </div>
      </div>

      <div *ngIf="chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTONIMAGE'" class="form-group col-lg-12">
        <label>Url(Image) </label>
        <input type="text" class="form-control"
               [(ngModel)]="templateButtonMessage.image.url" name=templateImage>
      </div>

      <div *ngIf="chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTONVIDEO'" class="form-group col-lg-12">
        <label>Url(Video) </label>
        <input type="text" class="form-control"
               [(ngModel)]="templateButtonMessage.video.url" name=templateVideo>
      </div>


      <div *ngIf="chatbotConversionDao.actionType.toString()== 'TEMPLATEBUTTONIMAGE' || chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTONVIDEO' " class="form-group col-lg-12">
          <label>Caption </label>
        <input type="text" class="form-control"
               [(ngModel)]="templateButtonMessage.caption" name="caption">
      </div>

      <div *ngIf="chatbotConversionDao.actionType.toString()== 'TEMPLATEBUTTON'" class="form-group col-lg-12">
        <label>Text </label>
        <textarea type="text" class="form-control"
                  [(ngModel)]="templateButtonMessage.text" name="btnmsg"></textarea>
      </div>


      <div *ngIf="chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTON' || chatbotConversionDao.actionType.toString()== 'TEMPLATEBUTTONIMAGE' || chatbotConversionDao.actionType.toString()=='TEMPLATEBUTTONVIDEO' ">
        <div  class="form-group col-lg-12">
          <label>Footer </label>
          <input type="text" class="form-control" [(ngModel)]="templateButtonMessage.footer"
                 name="footer">
        </div>

        <div class="form-group col-lg-12">
          <label>URL Button</label></div>
        <div class="form-group col-lg-6">
          <label>Display Text</label>
          <input type="text" class="form-control"
                 [(ngModel)]="templateButtonMessage.templateButtons[1].urlButton.displayText"
                 name="displayText">
        </div>
        <div class="form-group col-lg-6">
          <label>URL</label>
          <input type="url" class="form-control"
                 [(ngModel)]="templateButtonMessage.templateButtons[1].urlButton.url"
                 name="url">
        </div>
        <div>
          <div class="form-group col-lg-12">
            <label>Call Button</label></div>
          <div class="form-group col-lg-6">
            <label>Display Text</label>
            <input type="text" class="form-control"
                   [(ngModel)]="templateButtonMessage.templateButtons[0].callButton.displayText"
                   name="displayText1">
          </div>
          <div class="form-group col-lg-6">
            <label>Phone Number</label>
            <input type="text" class="form-control"
                   [(ngModel)]="templateButtonMessage.templateButtons[0].callButton.phoneNumber"
                   name="phoneNumber">
          </div>
        </div>
        <div>
          <div class="form-group col-lg-12">
            <label>Quick Reply</label>
          </div>
          <div class="form-group col-lg-4">
            <label>Button 1</label>
            <input type="text" class="form-control"
                   [(ngModel)]="templateButtonMessage.templateButtons[2].quickReplyButton.displayText"
                   name="quickReply">
          </div>
          <div class="form-group col-lg-4">
            <label>Button 2</label>
            <input type="text" class="form-control"
                   [(ngModel)]="templateButtonMessage.templateButtons[3].quickReplyButton.displayText"
                   name="quickReply2">
          </div>
          <div class="form-group col-lg-4">
            <label>Button 3</label>
            <input type="text" class="form-control"
                   [(ngModel)]="templateButtonMessage.templateButtons[4].quickReplyButton.displayText"
                   name="quickReply3">
          </div>
        </div>


      </div>


      <div *ngIf="chatbotConversionDao.actionType.toString()=='FILE'">

        <div  class="form-group col-lg-3">
          <label>File Name </label>
          <input type="text" class="form-control" [(ngModel)]="chatbotConversionDao.messageDao.fileMessage.fileName"
                 name="fileName">
        </div>
        <div  class="form-group col-lg-3">
          <label>File Caption</label>
          <input type="text" class="form-control" [(ngModel)]="chatbotConversionDao.messageDao.fileMessage.caption"
                 name="fileCaption">
        </div>
        <div  class="form-group col-lg-6">
          <label>File Url </label>
          <input type="text" class="form-control" [(ngModel)]="chatbotConversionDao.messageDao.fileMessage.fileUrl"
                 name="fileurl">
        </div>
      </div>
      <div *ngIf="chatbotConversionDao.actionType.toString()=='LISTS'">
        <div class="form-group col-lg-4">
          <label>Button </label>
          <input type="text" class="form-control" [(ngModel)]="listMessageButton.buttonText" name="listBtn">
        </div>
        <div  class="form-group col-lg-4">
          <label>Footer</label>
          <input type="text" class="form-control" [(ngModel)]="listMessageButton.footer" name="listFooter">
        </div>
        <div  class="form-group col-lg-4">
          <label>Title</label>
          <input type="text" class="form-control" [(ngModel)]="listMessageButton.title" name="listTitle">
        </div>

        <div  class="form-group col-lg-12">
          <label>Text</label>
          <textarea class="form-control" [(ngModel)]="listMessageButton.text" name="listText"></textarea>
        </div>

        <div  class="form-group col-lg-12">
          <label>Rows Title</label>
          <input type="text" class="form-control" [(ngModel)]="listMessageButton.sections[0].title" name="rowsTitle">
        </div>

        <ng-container *ngFor="let row of listValueArray;let i = index">
          <div  class="form-group col-lg-5">
            <label>Row</label>
            <input type="text" class="form-control" [(ngModel)]="listValueArray[i].title" [name]="'row'+i">
          </div>
          <div class="form-group col-lg-5">
            <label>Description</label>
            <input type="text" class="form-control" [(ngModel)]="listValueArray[i].description" [name]="'desc'+i">
          </div>

          <div class="form-group col-lg-2">
            <label></label>
            <button  type=button (click)="deleteRow(i)" class=" form-control btn btn-danger "><i
              class="fa fa-trash" ></i></button>

          </div>
        </ng-container>

        <div  class="form-group col-lg-2">
          <label></label>
          <button  type=button (click)="addNewRow()" class="form-control"><i
            class="fa fa-plus"></i></button>
        </div>


      </div>



      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitFlowForm(chatbotConversionDao)">{{submitBtnTxt}}</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>

  </div>
</div>
