import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaymentDetailsDao} from "./PaymentDetailsDao";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailsService {

  constructor(private http: HttpClient) { }

  insertPaymentDetails(paymentDetailsDao: PaymentDetailsDao): Observable<PaymentDetailsDao>{
    return this.http.post<PaymentDetailsDao>(environment.appUrl + environment.whatsappOfficialservice + "api/payment/",paymentDetailsDao);
  }
  updatePaymentDetails(paymentDetailsDao: PaymentDetailsDao): Observable<PaymentDetailsDao>{
    return this.http.post<PaymentDetailsDao>(environment.appUrl + environment.whatsappOfficialservice + "api/payment/update",paymentDetailsDao);
  }
  get(startDate: string, endDate:string): Observable<Array<PaymentDetailsDao>>{
    return this.http.post<Array<PaymentDetailsDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/payment/get?startdate=" + startDate + "&enddate=" + endDate,{});
  }
}
