import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {
  accepted: any;
  ssid: any;
  phNumber: any;
  constructor(private router: Router) {
    const tempURL = this.router.url.toString().split("?")[1];
    const number = tempURL.split("&")[0];
    const id = tempURL.split("&")[1];
    this.phNumber = number.split("=")[1];
    this.ssid = id.split("=")[1];
    console.log("https://api.whatsapp.com/send?phone="+this.phNumber+"&text="+this.ssid+"-hi");
    console.log("https://wa.me/"+ this.phNumber +"?text=Topical Screen ID --" + this.ssid);
    // window.location.href = "https://api.whatsapp.com/send?phone="+this.phNumber+"&text="+this.ssid+"-hi";
    // window.location.href = "https://wa.me/"+ this.phNumber +"?text=" + this.ssid + "-Hi";
    window.location.href = "https://wa.me/"+ this.phNumber +"?text=Topical Screen ID -- " + this.ssid
  }

  ngOnInit(): void {
  }

  proceed() {
    // this.router.navigate([''])
  }
}
