import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RechargeDao, RechargeFormDao} from '../RechargeDao';
import {RechargeViewDao} from './RechargeViewDao';
import {RechargeService} from '../RechargeService/recharge.service';

@Component({
  selector: 'app-recharge-view',
  templateUrl: './recharge-view.component.html',
  styleUrls: ['./recharge-view.component.css']
})
export class RechargeViewComponent implements OnInit {
  rechargeDao:RechargeDao=null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: RechargeViewDao,private rechargeService:RechargeService) {



  }

  ngOnInit(): void {
    this.rechargeService.getById(this.data.id).subscribe(value => {
      this.rechargeDao=value;
    })
  }

}
