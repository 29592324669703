import { Injectable } from '@angular/core';
import {MessageDao, TemplateDao} from '../ws-template/MessageDao';
import {CampaignResponseDao} from './CampaignResponseDao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {Observable} from 'rxjs';
import {CampaignDao} from '../onetomany/CampaignDao';
import {GroupByDao} from './GroupByDao';
import {CampaignFilterDao} from './CampaignFilterDao';
import {UserMasterDao} from "../Services/User/UserMasterDao";
import {SubscriptionDao} from "../subscription/SubscriptionDao";
import {CatalogMessageDao} from "../catalogue/catalog-message-dao";
import {LocationMessageDao} from "../MicroServices/Chatbot/ChatbotConversationFlow/conversation-flow/ConversationDao";
const appPrefix = environment.appUrl + environment.whatsappOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class CampaingService {

  button: any;
  constructor(private http: HttpClient) { }

  convertToMessage(to: string, type: string, templateDao: TemplateDao, campaingId: number,iScheuled:number,scheduledTime:number,country: string,messageContent:string,dateFilterLong:number,profleName:string): { template: TemplateDao; date: string; image: null; scheduleTime: number; chatSide: string; document: null; interactive: null; active: boolean; messageId: string; video: null; mimeType: string; type: string; isActive: boolean; campaingId: number; attachementBase64: string; messageStatus: string; viewTime: string; iScheduled: number; isPanel: boolean; to: string; text: null; audio: null; dlrTime: string; button: any; countryCode: string,catalogMessageDao:null, location:null,messageContent:'',dateFilterLong:0, profileName:'' }
  {

    switch (type)
    {
      case 'template':
        for (let i = 0; i < templateDao.components.length; i++)
        {
          // tslint:disable-next-line:triple-equals
          if (templateDao.components[i].parameters.length == 0)
          {
            templateDao.components.splice(i, 1);
          }

        }
        // tslint:disable-next-line:max-line-length
        return {
          active: false,
          template: templateDao, to, type, campaingId, date: '', dlrTime: '', viewTime: '', messageId: 'SENT', messageStatus: 'SENT', chatSide: 'User', text: null, image: null, video: null, document: null, audio: null, isPanel: true,interactive:null,button: null,attachementBase64:'',mimeType:'',iScheduled:iScheuled,scheduleTime:scheduledTime,isActive:true, countryCode: country,catalogMessageDao:null,location:null,messageContent:'', dateFilterLong:0, profileName:''};


    }
    return null;

  }


  convertToCatalogueMessage(to: string, type: string, catalogue: CatalogMessageDao, campaingId: number,iScheuled:number,scheduledTime:number,country: string,messageContent:string,dateFilterLong:number,profileName:string): { template: null; date: string; image: null; scheduleTime: number; chatSide: string; document: null; interactive: null; active: boolean; messageId: string; video: null; mimeType: string; type: string; isActive: boolean; campaingId: number; attachementBase64: string; messageStatus: string; viewTime: string; iScheduled: number; isPanel: boolean; to: string; text: null; audio: null; dlrTime: string; button: any; countryCode: string,catalogMessageDao:CatalogMessageDao, location:null, messageContent:'',dateFilterLong:0, profileName:''}
  {
    catalogue.to=to;

    if (type=='CATALOGUE'){
        return {
          active: false,
          template: null, to, type, campaingId, date: '', dlrTime: '', viewTime: '', messageId: 'SENT', messageStatus: 'SENT', chatSide: 'User', text: null, image: null, video: null, document: null, audio: null, isPanel: true,interactive:null,button: null,attachementBase64:'',mimeType:'',iScheduled:iScheuled,scheduleTime:scheduledTime,isActive:true, countryCode: country,catalogMessageDao:catalogue,location:null,messageContent:'',dateFilterLong:0, profileName:''
        };


    }
    return null;

  }

  sendSingleMessage(MessageDa: MessageDao): Observable<CampaignResponseDao>
  {
    switch (MessageDa.type)
    {
      case 'text':
        MessageDa.image = null;
        break;
      case 'image':
        MessageDa.text = null;
        break;

    }
    MessageDa.isPanel = true;
    return this.http.post<CampaignResponseDao>(appPrefix + 'message/', MessageDa);
  }
  sendMultipleMessage(MessageList: Array<MessageDao>): Observable<CampaignResponseDao>
  {
    debugger;
    const subscription: SubscriptionDao = JSON.parse(localStorage.getItem('subscriptionDao'));
    if (subscription.enablePriorityQueue == true) {
      if(MessageList.length<=800){
        return this.http.post<CampaignResponseDao>(appPrefix + '/api/combinedqueue2/batchpanel', MessageList);
      }else if(MessageList.length>800 && MessageList.length<=3000){
        return this.http.post<CampaignResponseDao>(appPrefix + '/api/combinedqueue3/batchpanel', MessageList);
      } else if(MessageList.length>3000){
        return this.http.post<CampaignResponseDao>(appPrefix + 'message/batchpanel', MessageList);
      }
    }else{
        return this.http.post<CampaignResponseDao>(appPrefix + 'message/batchpanel', MessageList);
    }
  }
  createCampaign(campaignDao: CampaignDao): Observable<CampaignDao>
  {
   return  this.http.post<CampaignDao>(appPrefix + 'campaign/insert', campaignDao);
  }
  getCampaigns(): Observable<Array<CampaignDao>>
  {
    return this.http.get<Array<CampaignDao>>(appPrefix + 'campaign/');
  }

  getCampaingWiseReport(campaidId: number): Observable<Array<GroupByDao>>
  {
    return this.http.get<Array<GroupByDao>>(appPrefix + 'message/getsts?id=' + campaidId);
  }
  getTransCampaingWise(campaignId: number): Observable<Array<MessageDao>>
  {

    return this.http.post<Array<MessageDao>>(appPrefix + 'message/gettrans?id=' + campaignId, {});

  }
  deleteScheduledCampaign(id:number) :Observable<any>{
    return this.http.delete<any>(appPrefix + '/api/schedular/delete?id='+id);
  }
}
