import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
export class FileService<T> {



  public readFile(_file:File)
  {
    return new Promise<Array<T>>((resolve, reject) => {
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var data = fileLoadedEvent.target.result;
        var workbook = XLSX.read(data, {
          type: 'binary'
        });

        let XL_row_object:Array<T> = XLSX.utils.sheet_to_json(workbook.Sheets[workbook["SheetNames"][0]], {raw: false});
        resolve(XL_row_object)
      }
      fileReader.readAsBinaryString(_file);

    })
  }

  s2ab(s) {

    console.log("ENTERED S2B ",)
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    console.log("STARTING LOOP S2B ",)
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    console.log("FINISHED LOOP S2B ",)
    return buf;

  }

  public generateFile(json: Array<T>, fileName: string)
  {

    console.log("RECEIVED DATA FOR :: ",fileName)
    var wb = XLSX.utils.book_new();
    console.log("CREATED WB :: ",fileName)
    wb.Props = {
      Title:fileName,
      Subject:fileName,
      Author:fileName,
      CreatedDate: new Date()
    };
    console.log("CREATED PROPS :: ",fileName)
    wb.SheetNames.push(fileName);
    console.log("PUSHED SHEETNAME :: ",fileName)

    // @ts-ignore
    var ws = XLSX.utils.aoa_to_sheet(json);
    jQuery.each(ws, function(cell, item) {
      console.log("item WS :: ",item)




      try {
        if (item.v != null && item.v != undefined && item.v.indexOf('http') == 0){
          ws[cell] = {f: '=HYPERLINK("' + item.v + '","'+item.v+'")'};
        }
      }catch (e) {
        console.log(e.message)
      }




    });

    console.log("CREATED WS :: ",fileName)
    wb.Sheets[fileName] = ws;

    console.log("ADDED WB :: ",fileName)
    var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
    console.log("CREATED WBOUT :: ",fileName)
    let s2bData = this.s2ab(wbout);
    console.log("CREATED BLOB :: ",fileName)
    let blob = new Blob([s2bData], {type: "application/octet-stream"});
    console.log("SAVING FILE :: ",fileName)
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();


    //saveAs(blob, fileName);
    console.log("CREATED :: ",fileName)
    return
  }

  public generateFileWithoutHyperLink(json: Array<T>, fileName: string)
  {

    console.log("RECEIVED DATA FOR :: ",fileName)
    var wb = XLSX.utils.book_new();
    console.log("CREATED WB :: ",fileName)
    wb.Props = {
      Title:fileName,
      Subject:fileName,
      Author:fileName,
      CreatedDate: new Date()
    };
    console.log("CREATED PROPS :: ",fileName)
    wb.SheetNames.push(fileName);
    console.log("PUSHED SHEETNAME :: ",fileName)

    // @ts-ignore
    var ws = XLSX.utils.aoa_to_sheet(json);
    jQuery.each(ws, function(cell, item) {
      console.log("item WS :: ",item)


//it is used if client want hyperlink so that open image directly
      // try {
      //   if (item.v != null && item.v != undefined && item.v.indexOf('http') == 0){
      //     ws[cell] = {f: + item.v + '","'+item.v+};
      //   }
      // }catch (e) {
      //   console.log(e.message)
      // }




    });

    console.log("CREATED WS :: ",fileName)
    wb.Sheets[fileName] = ws;

    console.log("ADDED WB :: ",fileName)
    var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
    console.log("CREATED WBOUT :: ",fileName)
    let s2bData = this.s2ab(wbout);
    console.log("CREATED BLOB :: ",fileName)
    let blob = new Blob([s2bData], {type: "application/octet-stream"});
    console.log("SAVING FILE :: ",fileName)
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
    //saveAs(blob, fileName);
    console.log("CREATED :: ",fileName)
    return
  }



}
