import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {Agent} from "../AgentMaster/agent-master/AgentDao";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {UsermasterService} from "../../Services/UsermasterService/usermaster.service";
import {AgentServiceService} from "../AgentMaster/agent-service.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {InteractionSessionsDao} from "./InteractionSession";
import {Chatbot} from "../../draganddropchatbot/Chatbot";
import {environment} from "../../../environments/environment.prod";
import {FileService} from "../../Services/FileUtilsService/FileService";
import {ConversationService} from "../../conversation/ConversationService/conversation.service";
import {SessionDocument} from "./SessionDocument";
import {AgentMasterFormComponent} from "../AgentMaster/agent-master-form/agent-master-form.component";
import {QuestionResponseFlowComponent} from "./question-response-flow/question-response-flow.component";
import {InteractionSessionFormComponent} from "./interaction-session-form/interaction-session-form.component";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {formatDate} from "@angular/common";
import {DatefilterComponent} from "../../CommonPopups/datefilter/datefilter.component";

@Component({
  selector: 'app-interaction-session',
  templateUrl: './interaction-session.component.html',
  styleUrls: ['./interaction-session.component.css']
})
export class InteractionSessionComponent implements OnInit,AfterViewInit {

  sessionList: PaginationMain<SessionDocument> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  currentUser: UserMasterDao
  offset: number = 0;
  limit: number = 100000;
  startdate: number = undefined;
  enddate: number = undefined;
  agentList: Array<SessionDocument>;
  session:SessionDocument;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};


  constructor( private router: Router,private route: ActivatedRoute,private conversationService:ConversationService,private userMasterService: UsermasterService, private agentServiceService: AgentServiceService, private dialog: MatDialog, private alert: AlertMessageService) {
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];

      this.userMasterService.getCurrentUser().subscribe(data => {
        this.currentUser = data;
        console.log("current user", this.currentUser)
        this.loadData();

      });

    });    }

  ngOnInit(): void {
    // this.userMasterService.getCurrentUser().subscribe(data => {
    //   this.currentUser = data;
    //   console.log("current user", this.currentUser)
    //   this.loadData();
    // });




  }

  loadData() {
    // this.alert.showLoader();
    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }
    $('#tableDiv').DataTable().destroy();
    this.agentServiceService.getSession(this.currentUser.accountId,this.startdate,this.enddate, this.offset, this.limit).subscribe(data => {
      console.log("data : ", data)
      this.sessionList = data;
      this.dtTrigger.next();
      // this.alert.hideLoader();
      console.log("sessionlist:", this.sessionList)

    });
  }
  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  // tableChange(json) {
  //   console.log("json", json)
  //   this.offset = json.pageIndex;
  //   this.limit = json.pageSize;
  //   this.loadData();
  //
  // }
  copy(data:InteractionSessionsDao)
  {
    this.copyToClipboard(environment.withoutGatewayAppUrl+data.agentId)
    this.alert.showSuccesMessage("Succesfully Copied Link!!")
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  updateSessionFormForm(sessionDocumentTemp:SessionDocument) {
    console.log("agentTemp in edit or add:",sessionDocumentTemp)
    let dialogRef =  this.dialog.open(InteractionSessionFormComponent,{width: '140vw',
      maxHeight: '95vh',
      data:{sessionDocumentTemp}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao; ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe sesions Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }

  getFlow(sessionDocument:SessionDocument){
    console.log("SessionDocument in edit or add:",sessionDocument)
    let dialogRef =  this.dialog.open(QuestionResponseFlowComponent,{width: '40vw',
      maxHeight: '95vh',
      data:{sessionDocument}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao; ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe agent Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })
  }
  downloadFile(){
    this.alert.showLoader();
    this.agentServiceService.getSession(this.currentUser.accountId,this.startdate,this.enddate, this.offset, this.limit).subscribe(value => {
      const file = new FileService<any>();
      const trArr = [];

      // Initialize an empty Set to store unique headers
      const headerSet = new Set();
      const updatedQuestionResponse = {};
      let maxQuestionResponse = {};
      let maxQuestionResponseLength = 0;

      // Populate headers based on data
      for (let i = 0; i < value.data.length; i++) {
    const   questionResponse = value.data[i].questionsResponse;
        let medicineValues = questionResponse["medicine"];

// Check if 'medicine' is defined before attempting to split
        if (medicineValues) {
          let medicineList = medicineValues.split(',');
// Add medicine properties after the pin code key
          medicineList.forEach((medValue, index) => {
            questionResponse[`medicine_${index + 1}`] = medValue.trim();
          });
        }
        else{
          console.log("no medicine field");

        }

          const questionResponseLength = Object.keys(questionResponse).length;

          if (questionResponseLength > maxQuestionResponseLength) {
            maxQuestionResponseLength = questionResponseLength;
            maxQuestionResponse = { ...questionResponse }; // Clone the object
          }

      }

      console.log("maximum length question response",maxQuestionResponse)

      let medineFieldInMaxResponse = maxQuestionResponse["medicine"];
//       const reorderedQuestionResponse = {};
//       if(medineFieldInMaxResponse) {
//         const pincodeIndex = Object.keys(maxQuestionResponse).indexOf("pin code");
//
// // Extract all fields starting with "medicine"
//         const medicineFields = Object.keys(maxQuestionResponse).filter(key => key.startsWith("medicine"));
//
// // Move medicine fields after the "pin code" field
//         const reorderedKeys = [
//           ...Object.keys(maxQuestionResponse).slice(0, pincodeIndex + 1),
//           ...medicineFields,
//           ...Object.keys(maxQuestionResponse).slice(pincodeIndex + 1)
//         ];
//
// // Create a new object with reordered keys
//         reorderedKeys.forEach(key => {
//           reorderedQuestionResponse[key] = maxQuestionResponse[key];
//         });
//         console.log("medicine will be placed after pincode and delete medicine column")
//
//
//         delete  reorderedQuestionResponse['medicine']
//         delete  reorderedQuestionResponse['Welcome']
//
//         Object.keys(reorderedQuestionResponse).forEach(header => {
//           headerSet.add(header);
//
//         });
//       }
//         else{
          console.log("no medicine filed in max question repsone so it will work normal flow")
        Object.keys(maxQuestionResponse).forEach(header => {
          headerSet.add(header);

        });
        // }
      // Create a sorted array of headers
      const headers = Array.from(headerSet);
      console.log("hederset",headers[0])

      const headers1=["Agent Id", "house", "street", "landmark", "city", "state", "pin code", "medicine_1", "medicine_2", "medicine_3","prescription", "bill", "Patient Name", "Age", "Gender"];


if(this.currentUser.accountId=20922){
  console.log("curernt user on 20922",this.currentUser)
  trArr.push(['Create Date','clientName', 'Client Number', ...headers1]);

}
else{
  trArr.push(['Create Date','clientName', 'Client Number', ...headers]);

}
      // Push headers as the first row

      // Populate rows
      for (let i = 0; i < value.data.length; i++) {
        const questionRes=value.data[i].questionsResponse;
        const rowData = [];

        // Push clientName, Client Number, Agent Id, Create Date
        rowData.push(value.data[i].createDate);

        rowData.push(value.data[i].clientName);
        rowData.push(value.data[i].clientNumber);
        // rowData.push(value.data[i].agentId);

        if(this.currentUser.accountId=20922){
          console.log("curernt user  on execel",this.currentUser)

          headers1.forEach(header => {


            // @ts-ignore
            rowData.push(questionRes[header] || '');
          });
        }
        else {
          // Loop through headers and add values accordingly
          headers1.forEach(header => {


            // @ts-ignore
            rowData.push(questionRes[header] || '');
          });
        }
        trArr.push(rowData);
      }

      file.generateFileWithoutHyperLink(trArr, 'SessionData.xlsx');
      this.alert.hideLoader();
    });













  }
}
