<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" *ngIf="hasWriteOrUpdatePermission" class="btn btn-add">Add New User<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="hasWriteOrUpdatePermission && addNewDiv" >
          <form ngNativeValidate (submit)="createOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Two Step Authencation</label>
              <mat-slide-toggle name="hasTwoStepAuthencation" [(ngModel)]="userMasterDao.hasTwoStepAuthencation"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-6">
              <label>Status</label>
              <mat-slide-toggle name="status" [(ngModel)]="userMasterDao.active"></mat-slide-toggle>
            </div>

            <div class="form-group col-lg-6">
            <mat-dialog-content class="mat-typography">

              <div class="form-group col-lg-12">


                  <mat-radio-group [(ngModel)]="userMasterDao.accountType" name="accountType"  aria-label="Select an option">

                    <ng-container *ngFor="let accountMode of userAccountType">

                      <mat-radio-button [value]="accountMode" >{{accountMode}}</mat-radio-button>

                    </ng-container>
                  </mat-radio-group>




              </div>
            </mat-dialog-content>
            </div>

            <div class="form-group col-lg-12">
              <label>Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="userMasterDao.fullname" name="fullname" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Password  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="userMasterDao.password" name="password" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Email  <sup class="required">*</sup></label>
              <input type="email" class="form-control" [(ngModel)]="userMasterDao.email" name="email" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Phone  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="userMasterDao.phone" name="phone" required>
            </div>
            <div *ngIf="accountMasterDao!=null" class="form-group col-lg-12">
              <label>Services  <sup class="required">*</sup></label>


              <table   class="table table-striped row-border hover">
                <thead>
                <tr>
                  <th>Service</th>
                  <th>Status</th>

                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let serviceDao of userMasterDao.services;let ind =index">


                <tr>
                  <td [innerHTML]="serviceDao.serviceName"></td><td> <mat-slide-toggle [name]="'service'+ind"  [(ngModel)]="serviceDao.active"></mat-slide-toggle></td>

                </tr>
                </ng-container>
                </tbody>
              </table>



            </div>
            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">

<div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Create By</th>
              <th>Create At</th>
              <th>Update By</th>
              <th>Update At</th>
              <th>Status</th>
              <th>Two Step Authencation</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr>
                <td [innerHTML]="user.username"></td>
                <td [innerHTML]="user.email"></td>
                <td [innerHTML]="user.phone"></td>
                <td [innerHTML]="user.createBy"></td>
                <td [innerHTML]="user.createAt"></td>
                <td [innerHTML]="user.updateBy"></td>
                <td [innerHTML]="user.updateAt"></td>

                <td><i *ngIf="user.active" style="color: green" class="fa fa-check"></i><i *ngIf="!user.active"
                                                                                           style="color: red"
                                                                                           class="fa fa-remove"></i>

                </td>

                <td><i *ngIf="user.hasTwoStepAuthencation" style="color: green" class="fa fa-check"></i><i *ngIf="!user.hasTwoStepAuthencation"
                                                                                           style="color: red"
                                                                                           class="fa fa-remove"></i>

                </td>
                <td><i title="Click Here To Edit" (click)="edit(user,ind)" *ngIf="hasWriteOrUpdatePermission"
                       class="btn btn-add fa fa-pencil"></i>
                  <i title="Click Here To View Reporting" (click)="viewReporting(user,ind)"
                     class="btn btn-add  fa fa-sitemap"></i>

                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>
</div>
        </div>

      </div>
    </div>
  </div>
</div>






