import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SubscriptionDao} from '../SubscriptionDao';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';

const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionServiceService {

  constructor(private http: HttpClient) {
  }


  addSubsription(sub: SubscriptionDao): Observable<SubscriptionDao> {
    return this.http.post<SubscriptionDao>(appPrefix + 'susbcription/insert', sub);
  }

  updateSubsription(sub: SubscriptionDao): Observable<SubscriptionDao> {
    return this.http.post<SubscriptionDao>(appPrefix + 'susbcription/update', sub);
  }

  getByAccountId(id: number): Observable<SubscriptionDao> {
    return this.http.get<SubscriptionDao>(appPrefix + 'susbcription/getbyaccountId?id=' + id);
  }

  getBySubscriptionId(id: number): Observable<SubscriptionDao> {
    return this.http.get<SubscriptionDao>(appPrefix + 'susbcription/get?id=' + id);
  }

  getCurrentSubscription(): Observable<SubscriptionDao> {
    debugger; 
    return this.http.get<SubscriptionDao>(appPrefix + 'susbcription/getcurrent');
  }
  restoreSubscription(accountId: number): Observable<SubscriptionDao> {
    return this.http.get<SubscriptionDao>(appPrefix + 'susbcription/restorelatest?accountId=' +accountId );
  }


}
