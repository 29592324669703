import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomainMasterDao} from '../DomainMasterDao';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment.prod';

const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http: HttpClient) {
  }

  save(domain: DomainMasterDao): Observable<DomainMasterDao> {
    return this.http.post<DomainMasterDao>(appPrefix + 'domain/', domain);
  }

  get(): Observable<Array<DomainMasterDao>> {
    return this.http.get<Array<DomainMasterDao>>(appPrefix + 'domain/');
  }

  delete(id:string):Observable<any> {
    return this.http.delete(appPrefix + 'domain/?id='+id);
  }
}
