<div class="row">
  <div class="col-md-12">

    <form >

      <div class="form-group col-lg-12">
        <label>Language <sup class="required">*</sup></label>
        <select class="form-control" [(ngModel)]="languageWiseAgent.language" (change)="updateLanguageId()" name="language">
          <ng-container>
            <option value="undefined"> ---Select Language---</option>
            <option value="ENGLISH">English</option>
            <option value="HINDI">Hindi</option>
            <option value="KANNADA">Kannada</option>
            <option value="TELUGU">Telugu</option>
            <option value="TAMIL">Tamil</option>
            <option value="URDU">Urdu</option>
          </ng-container>
        </select>
      </div>

      <div  class="form-group col-lg-12">
        <label>Agent Numbers <sup class="required">*</sup></label>
        <textarea type="text" class="form-control" placeholder="Enter agent number +91 and separate by,"
               name="agentNumber" [(ngModel)]="languageWiseAgent.agentNumbers">
        </textarea>
      </div>
      <div  class="form-group col-lg-12">
        <label>Chat Agent Numbers <sup class="required">*</sup></label>
        <textarea type="text" class="form-control" placeholder="Enter agent number 91 and separate by,"
                  name="chatAgentNumber" [(ngModel)]="languageWiseAgent.chatAgentNumber">
        </textarea>
      </div>

      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitMasterForm(languageWiseAgent)">{{submitBtnTxt}}</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>
  </div>
</div>
