import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../../CommonObj/PaginationDao";
import {IncommingSmsDao} from "./IncommingSmsDao";
import {EmpReportDao} from "../../Daone/empreport/EmpReportDao";
import {environment} from "../../../../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {DatefilterComponent} from "../../../../CommonPopups/datefilter/datefilter.component";
import {CigarateDao} from "../../../Digimozo/Cigarate/cigarate-panel-dash/CigarateDao";
import {FileService} from "../../../../Services/FileUtilsService/FileService";
import {UserService} from "../../../../Services/User/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-incomming-sms-report',
  templateUrl: './incomming-sms-report.component.html',
  styleUrls: ['./incomming-sms-report.component.css']
})
export class IncommingSmsReportComponent implements OnInit {

  tableData: PaginationDao<IncommingSmsDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
    });
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }

    this.http.get<PaginationDao<IncommingSmsDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/getincommingmsg?startdate=' + this.startdate + '&enddate=' + this.enddate  + '&offset=' + this.offset + '&limit=' + this.limit+"&port=15").subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });

  }

  tableChange(json) {
    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    this.loadData();

  }

  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  downloadFile() {

    console.log("startdate :: ",this.startdate)
    console.log("enddate :: ",this.enddate)

    this.http.get<PaginationDao<IncommingSmsDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/getincommingmsg?offset=0&limit=200000&port=15&startdate=' + this.startdate + '&enddate=' + this.enddate).subscribe(value => {
      this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr = [];
      tdArr.push('Date');
      tdArr.push('Number');
      tdArr.push('Message');

      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].timestamp);
        tdArr.push(data[i].number);
        tdArr.push(data[i].text);
        trArr.push(tdArr);
      }

      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');


    });


  }

}
