import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FeatureDao} from "../../../Feature/feature-dao";
import {BungalowMasterDao, ResidentialDao} from "../BungalowMasterDao";

@Component({
  selector: 'app-add-residential',
  templateUrl: './add-residential.component.html',
  styleUrls: ['./add-residential.component.css']
})
export class AddResidentialComponent implements OnInit {
  submitBtnTxt: any = "Save";
  addNewDiv: boolean = false;

  residentialDao: ResidentialDao = {
    name: '',
    number: '',
  };
  dao: BungalowMasterDao = {
    currentReading: null,
    previousReading: null,
    bungalowNo: null,
    residentiaId: null,
    residentialDao: this.residentialDao,
    meterNo: null,
    chargeableUnit: null,
    ewsQtrsNo: null,
    payDate: null,
    date: null,
    totalAmout: null,
    paymentStaus: false,
    maintenanceReading: null,
    billPeriod: '',
    outstandingAmount: null,
    outstandingPeriod:'',
    discription: '',
    billNumber: '',
    occupationDate: ''
  };
  hasValues: boolean = false;
  title: any = 'Add New Record';

  constructor(private dialogRef: MatDialogRef<AddResidentialComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {

      if (data.pageValue != undefined && data.pageValue != null) {
        this.addNewDiv = data.pageValue;
      }
      if (data.data != null) {
        this.dao = data.data;
        this.addNewDiv = data.pageValue;
        this.submitBtnTxt = 'Update';
        this.title = 'Update Record'
      }

      console.log('data', this.dao);
      console.log('page', data.pageValue);

    }
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(1);
    this.dao = null;
  }

  createOrUpdate() {
    this.dialogRef.close(this.dao);
    this.dao = null;
  }
}
