import { Component, OnInit } from '@angular/core';
import {WhatsAppCloudTemplateMasterService} from "./whats-app-cloud-template-master.service";
import {Buttons, CloudErrorObj, CloudTemplateMasterDao, TemplateComponents} from "./CloudTemplateMasterDao";
import {style} from "@angular/animations";
import {Paramters, TemplateDao} from "../ws-template/MessageDao";
import {Subject} from "rxjs";
import swal from '../../assets/plugins/sweetalert/sweetalert';
import {AlertMessageService} from "../alert-message/alert-message.service";

@Component({
  selector: 'app-whatsapp-cloud-template-master',
  templateUrl: './whatsapp-cloud-template-master.component.html',
  styleUrls: ['./whatsapp-cloud-template-master.component.css']
})
export class WhatsappCloudTemplateMasterComponent implements OnInit {

  protected readonly style = style;
  isUpdate: boolean = false;
  disableMedia: boolean;
  rule: string = "Dear Customer your otp is {{1}} .Don't SHare it with anyone"
  uploadMediaRequired: boolean;
  templateDao: CloudTemplateMasterDao = null;
  addNewDiv = false;
  tableList: Array<CloudTemplateMasterDao> = null;
  dtOptions: any = {bDestroy: true, order: []};
  dtTrigger: Subject<any> = new Subject<any>();
  languageJson: Array<any> = [{
    Language: 'Afrikaans',
    Code: 'af'
  },
    {
      Language: 'Albanian',
      Code: 'sq'
    },
    {
      Language: 'Arabic',
      Code: 'ar'
    },
    {
      Language: 'Azerbaijani',
      Code: 'az'
    },
    {
      Language: 'Bengali',
      Code: 'bn'
    },
    {
      Language: 'Bulgarian',
      Code: 'bg'
    },
    {
      Language: 'Catalan',
      Code: 'ca'
    },
    {
      Language: 'Chinese (CHN)',
      Code: 'zh_CN'
    },
    {
      Language: 'Chinese (HKG)',
      Code: 'zh_HK'
    },
    {
      Language: 'Chinese (TAI)',
      Code: 'zh_TW'
    },
    {
      Language: 'Croatian',
      Code: 'hr'
    },
    {
      Language: 'Czech',
      Code: 'cs'
    },
    {
      Language: 'Danish',
      Code: 'da'
    },
    {
      Language: 'Dutch',
      Code: 'nl'
    },
    {
      Language: 'English',
      Code: 'en'
    },
    {
      Language: 'English (UK)',
      Code: 'en_GB'
    },
    {
      Language: 'English (US)',
      Code: 'en_US'
    },
    {
      Language: 'Estonian',
      Code: 'et'
    },
    {
      Language: 'Filipino',
      Code: 'fil'
    },
    {
      Language: 'Finnish',
      Code: 'fi'
    },
    {
      Language: 'French',
      Code: 'fr'
    },
    {
      Language: 'German',
      Code: 'de'
    },
    {
      Language: 'Greek',
      Code: 'el'
    },
    {
      Language: 'Gujarati',
      Code: 'gu'
    },
    {
      Language: 'Hausa',
      Code: 'ha'
    },
    {
      Language: 'Hebrew',
      Code: 'he'
    },
    {
      Language: 'Hindi',
      Code: 'hi'
    },
    {
      Language: 'Hungarian',
      Code: 'hu'
    },
    {
      Language: 'Indonesian',
      Code: 'id'
    },
    {
      Language: 'Irish',
      Code: 'ga'
    },
    {
      Language: 'Italian',
      Code: 'it'
    },
    {
      Language: 'Japanese',
      Code: 'ja'
    },
    {
      Language: 'Kannada',
      Code: 'kn'
    },
    {
      Language: 'Kazakh',
      Code: 'kk'
    },
    {
      Language: 'Korean',
      Code: 'ko'
    },
    {
      Language: 'Lao',
      Code: 'lo'
    },
    {
      Language: 'Latvian',
      Code: 'lv'
    },
    {
      Language: 'Lithuanian',
      Code: 'lt'
    },
    {
      Language: 'Macedonian',
      Code: 'mk'
    },
    {
      Language: 'Malay',
      Code: 'ms'
    },
    {
      Language: 'Malayalam',
      Code: 'ml'
    },
    {
      Language: 'Marathi',
      Code: 'mr'
    },
    {
      Language: 'Norwegian',
      Code: 'nb'
    },
    {
      Language: 'Persian',
      Code: 'fa'
    },
    {
      Language: 'Polish',
      Code: 'pl'
    },
    {
      Language: 'Portuguese (BR)',
      Code: 'pt_BR'
    },
    {
      Language: 'Portuguese (POR)',
      Code: 'pt_PT'
    },
    {
      Language: 'Punjabi',
      Code: 'pa'
    },
    {
      Language: 'Romanian',
      Code: 'ro'
    },
    {
      Language: 'Russian',
      Code: 'ru'
    },
    {
      Language: 'Serbian',
      Code: 'sr'
    },
    {
      Language: 'Slovak',
      Code: 'sk'
    },
    {
      Language: 'Slovenian',
      Code: 'sl'
    },
    {
      Language: 'Spanish',
      Code: 'es'
    },
    {
      Language: 'Spanish (ARG)',
      Code: 'es_AR'
    },
    {
      Language: 'Spanish (SPA)',
      Code: 'es_ES'
    },
    {
      Language: 'Spanish (MEX)',
      Code: 'es_MX'
    },
    {
      Language: 'Swahili',
      Code: 'sw'
    },
    {
      Language: 'Swedish',
      Code: 'sv'
    },
    {
      Language: 'Tamil',
      Code: 'ta'
    },
    {
      Language: 'Telugu',
      Code: 'te'
    },
    {
      Language: 'Thai',
      Code: 'th'
    },
    {
      Language: 'Turkish',
      Code: 'tr'
    },
    {
      Language: 'Ukrainian',
      Code: 'uk'
    },
    {
      Language: 'Urdu',
      Code: 'ur'
    },
    {
      Language: 'Uzbek',
      Code: 'uz'
    },
    {
      Language: 'Vietnamese',
      Code: 'vi'
    },
    {
      Language: 'Zulu',
      Code: 'zu'
    }
  ];

  formdata: any;
  file:any;
  buttonList: Array<Buttons> = [];
  cloudTemplateMasterDao: CloudTemplateMasterDao = {
    id:null,
    name: '',
    language: 'en_US',
    category: 'MARKETING',
    accountId: 0,
    status: 'PENDING',
    createDate: '',
    updateDate: '',
    dateFilter: 0,
    components: [],
    error: null,
    multipartFile:null
  }
  headerComponents: TemplateComponents = {
    type: 'HEADER',
    text: '',
    format: '',
    example: null,
    buttons: []
  }
  bodyComponents: TemplateComponents = {
    type: 'BODY',
    text: '',
    format: '',
    example: null,
    buttons: []
  }
footerComponents: TemplateComponents = {
  type: 'FOOTER',
  text: '',
  format: '',
  example: null,
  buttons: []
}
  buttonComponents: TemplateComponents = {
  type: 'BUTTONS',
  text: '',
  format: '',
  example: null,
  buttons: []
}
  componentList: Array<TemplateComponents> = [];
  showLabel: boolean = true;
  constructor(private whatsappCloudTemplateService: WhatsAppCloudTemplateMasterService, private alert: AlertMessageService) { }

  ngOnInit(): void {
    this.whatsappCloudTemplateService.getAllCloudTemplates().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addNew() {
    this.clearData();
    this.addNewDiv = true;
  }
  cancel() {
    this.clearData();
    this.addNewDiv = false;
  }

  isOrUpdate() {
    if(this.isUpdate == true){
      if(this.showLabel == true){
        this.update();
      }else{
        this.alert.showErrorMessage("Wrong Data Entered")
      }
    }else{
      if(this.showLabel == true){
        if(this.validateButtonValidation()=="0"){
          this.insert();
        }else{
          // this.alert.showErrorMessage("Wrong Data Entered")
        }
      }else{
        this.alert.showErrorMessage("Wrong Data Entered")
      }
    }
  }

  insert() {
    this.componentList = [];
    let bodyParametersCount = this.bodyComponents.text.split("{{");
    let bodyParameters = [];
    for(let i =0;i<bodyParametersCount.length-1;i++){
      bodyParameters[i] = "test"+i;
    }
    if(bodyParameters.length>0){
      this.bodyComponents.example = { body_text:[bodyParameters], header_handle: null };
    }
    this.componentList.push(this.bodyComponents);
    this.componentList.push(this.footerComponents);
    if(this.headerComponents.format !=''  && this.file != undefined){
      this.componentList.push(this.headerComponents)
    }
    if(this.buttonList.length > 0){
      this.buttonComponents.buttons = this.buttonList;
      this.componentList.push(this.buttonComponents);
    }
    this.cloudTemplateMasterDao.name = this.cloudTemplateMasterDao.name.toLowerCase().trim().replace(" ","");
    this.cloudTemplateMasterDao.components = this.componentList;
    console.log("The value of new Cloud Template is {}", this.cloudTemplateMasterDao);
    var formdata = new FormData();
    if(this.file != null){
      formdata.append('file', this.file);
    }
    formdata.append('cloudTemplateMasterDao', JSON.stringify(this.cloudTemplateMasterDao));
    this.alert.confirmMessage("Create Template ?" ,"Are you sure ?").then(value1 => {
      this.alert.showLoader();
      this.whatsappCloudTemplateService.insert(formdata).subscribe(value => {
        if(value.error == null){
          this.alert.hideLoader();
          this.alert.showSuccesMessage("Template Created");
        }else{
          this.alert.hideLoader();
          this.alert.showErrorMessage("Template creation Failed due to " + value.error.error.message);
        }
      }, error => {
        this.alert.hideLoader();
        // this.alert.showErrorMessage("Failed To Create Template with reason!! {}" + error.message);
        this.alert.showSuccesMessage("Template Created");
      });
      this.clearData();
      this.addNewDiv = false;
    });
  }

  public showErrorMessage(message: string) {
    swal({
      title: 'Error',
      text: message,
      icon: 'success',
      button: 'Ok'
    });
  }
  update() {

    let bodyParametersCount = this.bodyComponents.text.split("{{");
    let bodyParameters = [];
    for(let i =0;i<bodyParametersCount.length-1;i++){
      bodyParameters[i] = "test"+i;
    }
    for(let i=0;i<this.cloudTemplateMasterDao.components.length;i++){
      if(this.cloudTemplateMasterDao.components[i].type == "BODY"){
         this.cloudTemplateMasterDao.components[i].text = this.bodyComponents.text;
         this.cloudTemplateMasterDao.components[i].example = { body_text:[bodyParameters], header_handle: null };
      }if(this.cloudTemplateMasterDao.components[i].type == "FOOTER"){
        this.cloudTemplateMasterDao.components[i].text = this.footerComponents.text;
      }
    }
    console.log("The value of Updated Template is ", this.cloudTemplateMasterDao);
    this.alert.confirmMessage("Update Template ?" ,"Are you sure ?").then(value1 => {
      this.alert.showLoader();
      this.whatsappCloudTemplateService.update(this.cloudTemplateMasterDao).subscribe(value => {
        this.alert.hideLoader();
          this.alert.showSuccesMessage("Template Updated")
      }, error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage(error);
      });
      this.isUpdate = false;
      this.addNewDiv = false;
    });
  }

  showSuccessMessage(message)
  {
    swal({
      title: 'Success',
      text: message,
      icon: 'success',
      button: 'Ok'
    });
  }

  selectFile(event: any) {
    this.file = event.target.files[0];
    console.log("file is", this.file);
  }

  edit(template: CloudTemplateMasterDao, ind: number) {
    this.clearData();
    this.cloudTemplateMasterDao = template;
    for(let i=0;i<this.cloudTemplateMasterDao.components.length;i++){
      if(this.cloudTemplateMasterDao.components[i].type == "HEADER"){
        this.headerComponents.format = this.cloudTemplateMasterDao.components[i].format;
      }if(this.cloudTemplateMasterDao.components[i].type == "BODY"){
        this.bodyComponents.text = this.cloudTemplateMasterDao.components[i].text;
      }if(this.cloudTemplateMasterDao.components[i].type == "FOOTER"){
        this.footerComponents.text = this.cloudTemplateMasterDao.components[i].text;
      }if(this.cloudTemplateMasterDao.components[i].type == "BUTTONS"){
        this.buttonList = this.cloudTemplateMasterDao.components[i].buttons;
      }
    }
    this.isUpdate = true;
    this.addNewDiv = true;
  }

  clearData(){
    this.disableMedia = true;
    this.isUpdate = false;
    this.file = undefined;
    this.cloudTemplateMasterDao = {
      id:null,
      name: '',
      language: 'en_US',
      category: 'MARKETING',
      accountId: 0,
      status: 'PENDING',
      createDate: '',
      updateDate: '',
      dateFilter: 0,
      components: [],
      error: null,
      multipartFile:null
    }
    this.headerComponents = {
      type: 'HEADER',
      text: '',
      format: 'NOMEDIA',
      example: null,
      buttons: []
    }
    this.bodyComponents = {
      type: 'BODY',
      text: '',
      format: '',
      example: null,
      buttons: []
    }
    this.footerComponents = {
      type: 'FOOTER',
      text: '',
      format: '',
      example: null,
      buttons: []
    }
    this.buttonComponents = {
      type: 'BUTTONS',
      text: '',
      format: '',
      example: null,
      buttons: []
    }
    this.buttonList = [];
    this.componentList = [];
  }

  addNewButtonRow(number: number) {
    if(this.buttonList.length==0){
      this.buttonList.push({type:"", text: "", phone_number:'',url:'',example:[], urlType:'static'});
    }
    if(this.buttonList[0].type=='URL' && this.buttonList.length<2){
      this.buttonList.push({type:"", text: "", phone_number:'',url:'',example:[], urlType:'static'})
    }
    else if(this.buttonList[0].type=='PHONE_NUMBER' && this.buttonList.length<2){
      this.buttonList.push({type:"", text: "", phone_number:'',url:'',example:[], urlType:'static'});
    }
    else if(this.buttonList.length<3 && this.buttonList[0].type =='QUICK_REPLY'){
      this.buttonList.push({type:"", text: "", phone_number:'',url:'',example:[], urlType:'static'})
    }
  }


  removeButtonRow(number: number, i: number) {
    this.buttonList.splice(i, 1);
  }

  UploadStatusChange(format: string) {
    console.log("The value of format is ", format);
    if(format == 'NOMEDIA'){
      this.disableMedia = true;
      this.uploadMediaRequired = false;
    }else{
      this.disableMedia = false;
      this.uploadMediaRequired = true;
    }
  }

  deleteOrDisableTemp(template: CloudTemplateMasterDao, ind: number) {
    this.alert.confirmMessage("Delete "+template.name+"?" ,"Are you sure ?").then(value => {
      this.whatsappCloudTemplateService.disableCloudTemplate(template.id).subscribe(value => {
            this.showSuccessMessage("Template Deleted")
      });
    });
  }

  templateNameValidate() {
    this.cloudTemplateMasterDao.name = this.cloudTemplateMasterDao.name.replace(/[^a-zA-Z]/g,"_");
    this.cloudTemplateMasterDao.name = this.cloudTemplateMasterDao.name.toLowerCase().trim().replace(" ","_");
  }

  validateTemplateBody() {
    if(this.bodyComponents.text.startsWith("{{") || this.bodyComponents.text.endsWith("}}") ){
      this.showLabel = false;
    }else{
      this.showLabel = true;
    }
  }
  validateButtonValidation(){
    if(this.buttonList.length==2){
      if(this.buttonList[0].type=='PHONE_NUMBER' && this.buttonList[1].type=='PHONE_NUMBER'){
        this.alert.showErrorMessage("Two Calling Buttons are not allowed");
        return "1";
      }
      else if(this.buttonList[0].type=='PHONE_NUMBER' && this.buttonList[1].type=='QUICK_REPLY'){
        this.alert.showErrorMessage("Calling and QuickReply Buttons are not allowed together");
        return "2";
      }else if(this.buttonList[1].type=='PHONE_NUMBER' && this.buttonList[0].type=='QUICK_REPLY'){
        this.alert.showErrorMessage("Calling and QuickReply Buttons are not allowed together");
        return "2";
      }else if(this.buttonList[0].type=='URL' && this.buttonList[1].type=='QUICK_REPLY'){
        this.alert.showErrorMessage("URL and QuickReply Buttons are not allowed together");
        return "3";
      }else if(this.buttonList[1].type=='URL' && this.buttonList[0].type=='QUICK_REPLY'){
        this.alert.showErrorMessage("URL and QuickReply Buttons are not allowed together");
        return "3";
      }else{
        return "0";
      }
    }else if(this.buttonList.length==3){
      if(this.buttonList[0].type=='QUICK_REPLY' && this.buttonList[1].type=='QUICK_REPLY'  && this.buttonList[2].type=='QUICK_REPLY'){
        return "0";
      }else{
        this.alert.showErrorMessage("Only Three QuickReply Buttons are allowed together");
        return "4";
      }
    }else{
      return "0";
    }
  }
}
