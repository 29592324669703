import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
const  appPrefix=environment.appUrl+environment.accountMicroverservice;

@Injectable({
  providedIn: 'root'
})
export class EnumServiceService {

  constructor(private http:HttpClient) { }

  getArrrayEnum(url:string):Observable<Array<string>> {
   return  this.http.get<Array<string>>(appPrefix+"enum/"+url);
  }
}
