import {AccountServiceDao} from './account-services/AccountServiceDao';
import {ResellerReportDao} from "./ResellerReportDao";
import {AccountTransactionReportDao} from "./AccountTransactionReportDao";

export interface AccountMasterDao {
  accountLogo: string;
  accountMinLogo: string;
  accountEmail: string;
  accountPhone: string;
  title: string;
  footer: string;
  id: number;
  active: boolean;
  enableReseller:boolean;
  accountName: string;
  services: Array<AccountServiceDao>;
  createBy: string;
  updateBy: string;
  createAt: string;
  updateAt: string;
  parentId: number;
  reseller: boolean;
  creditType:CreditType;
  resellerReportDao:ResellerReportDao;
   accountTransactionReportDao:AccountTransactionReportDao;
   password:string;
  serviceName:string;
  agentStatus:boolean;
  enableAgentSupport:boolean

}
export enum CreditType{
  SELF="SELF",INHERITED="INHERITED",RESELLER="RESELLER"
}


