<div style="min-width: 400px">

  <h1 mat-dialog-title style="text-align: center">Subscription <i class="fa fa-shopping-cart"></i></h1>

  <mat-dialog-content class="mat-typography">


    <div>
      <form ngNativeValidate (submit)="addOrUpdate()">

        <div class="form-group col-lg-12">
          <label>Subscription Status </label>
          <mat-slide-toggle [(ngModel)]="subscriptionDao.active" name="active"></mat-slide-toggle>
        </div>

        <div *ngIf="!data.isReseller" class="form-group col-lg-12">
          <label>Service Type </label>
          <mat-radio-group name="serviceType" [(ngModel)]="subscriptionDao.serviceType">
            <mat-radio-button value="EXPIRY">EXPIRY</mat-radio-button>
            <mat-radio-button value="BALANCE">BALANCE</mat-radio-button>
            <mat-radio-button value="HYBRID">HYBRID</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="subscriptionDao.serviceType=='EXPIRY'">
          <div class="form-group col-lg-12">
            <label>Subscription Duration </label>
            <mat-radio-group name="subscriptionDuration" [(ngModel)]="subscriptionDao.subscriptionDuration">
              <mat-radio-button value="MONTHLY">MONTHLY</mat-radio-button>
              <mat-radio-button value="QUARTER">QUARTER</mat-radio-button>
              <mat-radio-button value="OTHER">OTHER</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="subscriptionDao.subscriptionDuration=='OTHER'" class="form-group col-lg-12">
            <label>Subscription Duration Days</label>
            <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.subscriptionDurationDays"
                   name="subscriptionDurationDays" tep="any" required/>

          </div>
          <div class="form-group col-lg-12">
            <label>Expiry </label>
            <div *ngIf="subscriptionDao.subscriptionDuration=='MONTHLY'" class="form-group col-lg-12">
              <input type="date" class="form-control" step="any" [(ngModel)]="subscriptionDao.expiry" name="expiry"
                     required/>
            </div>
            <!--            <input type="date" class="form-control" step="any" [(ngModel)]="subscriptionDao.expiry" name="expiry"   required/>-->

          </div>

          <div class="form-group col-lg-12">
            <label>Per minute Message </label>
            <input type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.perMinMsg" name="perMinMsg"
                   required/>
          </div>

          <!--          <div class="form-group col-lg-12">-->
          <!--            <label>Panel Que </label>-->
          <!--            <select class="form-control" [(ngModel)]="subscriptionDao.panelQue" name="panelQue" required>-->
          <!--              <ng-container *ngFor="let panel of panelQueList">-->
          <!--                <option [value]="panel" [innerHTML]="panel"></option>-->
          <!--              </ng-container>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--          <div class="form-group col-lg-12">-->
          <!--            <label>Api Que </label>-->
          <!--            <select class="form-control" [(ngModel)]="subscriptionDao.apiQue" name="apiQue" required>-->

          <!--              <ng-container *ngFor="let apiQue of apiQueList">-->
          <!--                <option [value]="apiQue" [innerHTML]="apiQue"></option>-->
          <!--              </ng-container>-->

          <!--            </select>-->
          <!--          </div>-->

          <div class="form-group col-lg-12">
            <label>Subscription Amount </label>
            <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.subscriptionAmt"
                   name="subscriptionAmt" tep="any" required/>

          </div>
          <div class="form-group col-lg-12">
            <label>Expiry Reminder Emails </label>
            <textarea type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.expiryReminder"
                      name="expiryReminder" tep="any" required></textarea>
          </div>

          <div class="form-group col-lg-12">
            <label>Sales Person Emails </label>
            <textarea type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.salesPersonEmail"
                      name="salesPersonEmail" tep="any" required></textarea>
          </div>

          <div class="form-group col-lg-12">
            <label>Drive Size </label>
            <select class="form-control" [(ngModel)]="subscriptionDao.driveLimit" name="driveLimit">
              <ng-container>
                <option value="0">---Select Drive Size---</option>
                <option value="5">5 MB</option>
                <option value="10">10 MB</option>
                <option value="20">20 MB</option>
                <option value="30">30 MB</option>
              </ng-container>
            </select>
          </div>

        </div>


        <!--        =======================================expiry end==================-->

        <div *ngIf="subscriptionDao.serviceType=='BALANCE'">
          <div class="form-group col-lg-12">
            <label>Per Message Deduction </label>
            <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.perMessageDeduct"
                   name="perMessageDeduct" tep="any" required/>

          </div>
        </div>

        <!--        ===============================End Blance=======================================-->

        <!--        =========================================Start Hybrid=============================-->

        <div *ngIf="subscriptionDao.serviceType=='HYBRID'">
          <div class="form-group col-lg-12">
            <label>Expiry </label>
            <input type="date" class="form-control" step="any" [(ngModel)]="subscriptionDao.expiry" name="expiry"
                   required/>
          </div>
          <div class="form-group col-lg-12">
            <label>Per minute Message </label>
            <input type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.perMinMsg" name="perMinMsg"
                   required/>

          </div>
          <!--          <div class="form-group col-lg-12">-->
          <!--            <label>Panel Que </label>-->
          <!--            <select class="form-control" [(ngModel)]="subscriptionDao.panelQue" name="panelQue" required>-->
          <!--              <ng-container *ngFor="let panel of panelQueList">-->
          <!--                <option [value]="panel" [innerHTML]="panel"></option>-->
          <!--              </ng-container>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--          <div class="form-group col-lg-12">-->
          <!--            <label>Api Que </label>-->
          <!--            <select class="form-control" [(ngModel)]="subscriptionDao.apiQue" name="apiQue" required>-->

          <!--              <ng-container *ngFor="let apiQue of apiQueList">-->
          <!--                <option [value]="apiQue" [innerHTML]="apiQue"></option>-->
          <!--              </ng-container>-->

          <!--            </select>-->
          <!--          </div>-->
          <div class="form-group col-lg-12">
            <label>Subscription Amount </label>
            <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.subscriptionAmt"
                   name="subscriptionAmt" tep="any" required/>

          </div>
          <div class="form-group col-lg-12">
            <label>Expiry Reminder Emails </label>
            <textarea type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.expiryReminder"
                      name="expiryReminder" tep="any" required></textarea>
          </div>
          <div class="form-group col-lg-12">
            <label>Sales Person Emails </label>
            <textarea type="text" class="form-control" step="any" [(ngModel)]="subscriptionDao.salesPersonEmail"
                      name="salesPersonEmail" tep="any" required></textarea>
          </div>

          <div class="form-group col-lg-12">
            <label>Drive Size </label>
            <select class="form-control" [(ngModel)]="subscriptionDao.driveLimit" name="driveLimit">
              <ng-container>
                <option value="0">---Select Drive Size---</option>
                <option value="5">5 MB</option>
                <option value="10">10 MB</option>
                <option value="20">20 MB</option>
                <option value="30">30 MB</option>
              </ng-container>
            </select>
          </div>

          <div class="form-group col-lg-12">
            <label>Per Message Deduction </label>
            <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.perMessageDeduct"
                   name="perMessageDeduct" tep="any" required/>

          </div>
        </div>
        <!--            ==========================================End Hybrid=============================-->
        <div class="form-group col-lg-12">
          <label>Per Instance Creation </label>
          <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.perInstanceCreation"
                 name="perInstanceCreation" tep="any" required/>

        </div>


        <div class="reset-button">

          <button style="float: right" type="submit" class="btn btn-add">Save</button>

        </div>
      </form>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>

  </mat-dialog-actions>
</div>
