<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu" style="margin-top: 25px">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <div class="row">
              <button  (click)="filterData()" class="btn btn-add buttonStyle">Filter<i class="fa fa-filter"></i></button>
              <button (click)="clearFilter()" class="btn btn-add buttonStyle">Clear Filter<i class="fa fa-remove"></i></button>
              <button (click)="downloadReport()" class="btn btn-add buttonStyle">Download Report<i class="fa fa-download"></i></button>
<!--              <button *ngIf="subscriptionDao!=null && subscriptionDao.gateway == 'CLOUDAPI' && this.subscriptionDao.phoneNumberEvent != null" class="btn btn-add buttonStyle ">Account Status:-  {{this.subscriptionDao.phoneNumberEvent}}</button>-->
              <button *ngIf="subscriptionDao!=null && subscriptionDao.gateway == 'CLOUDAPI' && this.subscriptionDao.phoneNumberEvent != null" class="btn btn-add buttonStyle">Per Day Limit:-  {{this.subscriptionDao.current_limit}} </button>
              <button  *ngIf="subscriptionDao !=null" class="btn btn-add buttonStyle">Expiry:- {{subscriptionDao.expiry}}</button>
              <button  *ngIf="subscriptionDao ==null" class="btn btn-danger buttonStyle">Expiry:- Subscription Expired...</button>
            <button *ngIf="clientSignUpDao != null || clientSignUpDao != undefined" (click)="recharge()" class="btn btn-add buttonStyle" style="background: orange">Recharge<i class="fa fa-user-plus" ></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<mat-card class="col-lg-5  " style="background-color: lightgrey;width: 16%; padding-bottom: 15px">-->
<!--  <mat-card-header style="margin-left: 4px;">-->
<!--    <mat-card-title>Active </mat-card-title>-->
<!--  </mat-card-header>-->
<!--&lt;!&ndash;  <mat-card class="form-group col-sm-2   " style="margin-left: 25px;height: 100px;width: 200px">&ndash;&gt;-->
<!--&lt;!&ndash;    <mat-card-content><img src="https://wa.chatmybot.in/Shareablelinks/1137/WhatsApp Image 2023-01-20 at 17.24.19.jpeg">&ndash;&gt;-->
<!--&lt;!&ndash;      &nbsp; &nbsp;Total <br>&ndash;&gt;-->
<!--&lt;!&ndash;      <span style="text-align: center;margin-left: 75px">{{totalSession}}</span> <br></mat-card-content>&ndash;&gt;-->
<!--&lt;!&ndash;  </mat-card>&ndash;&gt;-->

<!--  <mat-card class="form-group col-sm-3  " style="height: 100px; width: 90%;margin: 5px"-->
<!--            (click)="openDataTable()">-->
<!--    <mat-card-content><i class="fa-solid fa-user" style="color:darkblue;font-size: 30px;"> </i> &nbsp; Active-->
<!--      <br>-->
<!--      <span style="text-align: center;margin-left: 25px">{{activeSession}}</span><br></mat-card-content>-->
<!--  </mat-card>-->

<!--</mat-card>-->
<!--<mat-card class="col-lg-5 pull-right  " style="background-color: lightgray;margin-left: 0px;width: 82%">-->
<!--  <mat-card-header style="margin-left: 30px;">-->
<!--    <mat-card-title> User/Business Session</mat-card-title>-->
<!--  </mat-card-header>-->
<!--  <mat-card class="form-group col-sm-3  " style="height: 100px; width: 15%;margin-left: 20px">-->
<!--    <mat-card-content><i class="fa-solid fa-user" style="color:darkblue;font-size: 30px;"> </i> &nbsp; User <br>-->
<!--      <span style="text-align: center;margin-left: 75px">{{usrSession}}</span></mat-card-content>-->
<!--  </mat-card>-->

<!--  <mat-card class="form-group col-sm-3   " style="margin-left: 25px;height: 100px;width: 17%">-->
<!--    <mat-card-content><i class="fa-solid fa-briefcase" style="color:darkblue;font-size: 30px;"></i> &nbsp; Marketing-->
<!--      <br>-->
<!--      <span style="text-align: center;margin-left: 75px">{{marketingSession}}</span>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->
<!--  <mat-card class="form-group col-sm-3   " style="margin-left: 25px;height: 100px;width: 15%">-->
<!--    <mat-card-content><i class="fa-solid fa-briefcase" style="color:darkblue;font-size: 30px;"></i> &nbsp; Utility-->
<!--      <br>-->
<!--      <span style="text-align: center;margin-left: 75px">{{utilitySession}}</span>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->
<!--  <mat-card class="form-group col-sm-3   " style="margin-left: 25px;height: 100px;width: 20%">-->
<!--    <mat-card-content><i class="fa-solid fa-briefcase" style="color:darkblue;font-size: 30px;"></i> &nbsp; Authentication-->
<!--      <br>-->
<!--      <span style="text-align: center;margin-left: 75px">{{authenticationSession}}</span>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->
<!--  <mat-card class="form-group col-sm-2   " style="margin-left: 25px;height: 100px;width: 16%">-->
<!--    <mat-card-content><img src="https://wa.chatmybot.in/Shareablelinks/1137/WhatsApp Image 2023-01-20 at 17.24.19.jpeg">-->
<!--      &nbsp; &nbsp;Total <br>-->
<!--      <span style="text-align: center;margin-left: 75px">{{totalSession}}</span> <br></mat-card-content>-->
<!--  </mat-card>-->
<!--  <br>-->
<!--</mat-card>-->

<div class="container">
  <div class="row">
    <!-- Active Session -->
    <mat-card class="col-md-2" style="background-color: lightgrey; margin-bottom: 15px;">
      <mat-card-header style="margin-left: 4px;">
        <mat-card-title>Active</mat-card-title>
      </mat-card-header>
      <mat-card class="form-group col-sm-6" style="height: 100px; width: 100%; margin: 5px;" (click)="openDataTable()">
        <mat-card-content>
          <i class="fa-solid fa-users" style="color: #81086f; font-size: 30px;"></i>&nbsp; Active
          <br>
          <span style="text-align: center; margin-left: 25px">{{activeSession}}</span>
          <br>
        </mat-card-content>
      </mat-card>
    </mat-card>

    <!-- User/Business Session -->
      <mat-card class="col-md-9 matCard" style="background-color: lightgray; margin-bottom: 15px; margin-left: 5px;">
        <mat-card-header style="margin-left: 30px;">
          <mat-card-title>User/Business Session</mat-card-title>
        </mat-card-header>

        <!-- User -->
        <mat-card class="form-group col-sm-2 col-md-2" style=" height: 100px; margin-left: 5px; ">
          <mat-card-content>
            <i class="fa-solid fa-user" style=" color: #09a409; font-size: 30px;"></i>&nbsp; User
            <br>
            <span style="text-align: center; margin-left: 75px">{{usrSession}}</span>
          </mat-card-content>
        </mat-card>

        <!-- Marketing -->
        <mat-card class="form-group col-sm-2 col-md-2" style="margin-left: 5px; height: 100px; ">
          <mat-card-content>
            <i class="fa-solid fa-bullseye" style="color: #ce9b2e; font-size: 30px;"></i>&nbsp; Marketing
            <br>
            <span style="text-align: center; margin-left: 75px">{{marketingSession}}</span>
          </mat-card-content>
        </mat-card>

        <!-- Utility -->
        <mat-card class="form-group col-sm-2 col-md-2" style="margin-left: 5px; height: 100px;">
          <mat-card-content>
            <i sty class="fa-solid fa-briefcase" style="color: red; font-size: 30px;"></i>&nbsp; Utility
            <br>
            <span style="text-align: center; margin-left: 75px">{{utilitySession}}</span>
          </mat-card-content>
        </mat-card>

        <!-- Authentication -->
        <mat-card class="form-group col-sm-2 col-md-2" style="margin-left: 5px; height: 100px;">
          <mat-card-content>
            <i class="fa-solid fa fa-key" aria-hidden="true" style="color: #2056c4; font-size: 30px;"></i>Authenticate
            <br>
            <span style="text-align: center; margin-left: 75px">{{authenticationSession}}</span>
          </mat-card-content>
        </mat-card>

        <!-- Total Session -->
        <mat-card class="form-group col-sm-2 col-md-2" style="margin-left: 5px; height: 100px;">
          <mat-card-content>
            <i class="fa-solid fa-calculator" style="color: #09a409; font-size: 30px;"></i>
<!--            <img src="https://wa.chatmybot.in/Shareablelinks/1137/WhatsApp Image 2023-01-20 at 17.24.19.jpeg">-->
            &nbsp; &nbsp;Total
            <br>
            <span style="text-align: center; margin-left: 75px">{{totalSession}}</span>
            <br>
          </mat-card-content>
        </mat-card>
      </mat-card>
  </div>
</div>


<div class="col-lg-12" style="margin-top: 20px">
  <mat-card *ngIf="panelOpenState==true">
    <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal"><i
      class="fa fa-window-close"></i></button>

    <mat-card-content>
      <div class="row">
        <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
          <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
              <div class="panel-title">

              </div>
            </div>
            <div style="" class="panel-body">
              <div class="table-responsive">
                <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable
                       class="table table-striped row-border hover">
                  <thead>
                  <tr>
                    <th>Number</th>
                    <th>Session Start Time</th>
                    <th>Session End Time</th>
                    <th>Session Type</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let user of activeSessions;let ind =index">
                    <tr>
                      <a (click)="TakeToMeNumber(user)" title="Click To Here Open Conversation">
                        <td [innerHTML]="user.name"></td>
                      </a>
                      <td [innerHTML]="user.sessionStartTime"></td>
                      <td [innerHTML]="user.sessionEndTime"></td>
                      <td *ngIf="user.count == 0" [innerHTML]="'USER'"></td>
                      <td *ngIf="user.count == 1" [innerHTML]="'BUSINESS'"></td>
                      <td *ngIf="user.count == 2" [innerHTML]="'AUTHENTICATION'"></td>
                      <td *ngIf="user.count == 3" [innerHTML]="'UTILITY'"></td>
                      <td *ngIf="user.count == 4" [innerHTML]="'MARKETING'"></td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>


<div class="row" style="margin-top: 35px">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu" style="margin-top: 25px">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="table!=0" class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="tab-content">
              <div class="tab-pane fade active in" id="tab2">
                <div class="panel-body">


                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover">

                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Count</th>
                        <th>Percentage</th>
                      </tr>
                      </thead>
                      <tbody>

                      <ng-container *ngFor="let fieldTable of tableList;let ind = index">
                        <tr>
                          <td [innerHTML]="fieldTable.name"></td>
                          <td [innerHTML]="fieldTable.count"></td>
                          <td [innerHTML]="fieldTable.percentage +'%'"></td>
                        </tr>
                      </ng-container>
                      </tbody>
                      <tfoot>
                      <tr>


                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>


              <div class="tab-pane fade" id="tab3">
                <div class="panel-body">

                  <form>
                    <div>


                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--==================================DLR REPORT=================================-->
<div>
  <div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
      <div class="panel panel-bd lobidragdashboard">
        <div class="panel-heading">
          <button (click)="downloadDlrReport()" class="btn btn-add">Download DLR Report <i class="fa fa-download"></i>
          </button>
          <div class="panel-title">
          </div>
        </div>
        <div style="" class="panel-body">
          <canvas [style]="{width: '100%', height: '250px'}" id="campaignNameChart">{{ campaignNameChart }}</canvas>
        </div>
      </div>
    </div>
  </div>

</div>

<!--</div>-->
