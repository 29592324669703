import {Component, OnInit} from '@angular/core';
import {FilterDao} from '../../crdrreport/FilterDao';
import {Subject} from 'rxjs';
import {ScratchCardDao} from '../scratch-card-report/ScratchCardDao';
import {formatDate} from '@angular/common';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ShubhLabhcampaignServiceService} from './shubh-labhcampaign-service.service';
import {ShubhLabhcampaignDao} from './shubh-labhcampaign-dao';
import {PaginationDao} from "../../CommonObj/PaginationDao";
import {IncommingSmsDao} from "../../MicroServices/GsmGateway/Oppo/incomming-sms-report/IncommingSmsDao";


@Component({
    selector: 'app-shubh-labhcampaign',
    templateUrl: './shubh-labhcampaign.component.html',
    styleUrls: ['./shubh-labhcampaign.component.css']
})
export class ShubhLabhcampaignComponent implements OnInit {

    constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private alert: AlertMessageService, private service: ShubhLabhcampaignServiceService) {

    }

    tableData: PaginationDao<any> = {data: [], total: 0};
    offset: number = 0;
    limit: number = 10;
    startdate: number = undefined;
    enddate: number = undefined;
    dateFilterType: Array<string>;
    services: Array<string>;
    groupBy: Array<string>;
    private filter: FilterDao;
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
    searchText: any;
    tableList: Array<ShubhLabhcampaignDao>;

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        // this.loadData();


        this.route.queryParams.subscribe(queryParams => {
            this.startdate = queryParams.startdate;
            this.enddate = queryParams.enddate;

            console.log(this.startdate, +'  ' + this.enddate);
            this.loadDataforDate(this.offset, this.limit);

        });
    }

    // tslint:disable-next-line:typedef
    loadData() {
        // @ts-ignore
        this.service.getAll().subscribe(value => {
            this.tableList = value;
            console.log(this.tableList);
            this.dtTrigger.next();
        });
    }


    // tslint:disable-next-line:typedef
    loadDataforDate(startdate: number, enddate: number) {
        this.tableList = null;
        this.alert.showLoader();

        const format = 'yyyyMMdd';
        const locale = 'en-IN';
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const formattedDateFirst = formatDate(firstDay, format, locale);
        const formattedDateLast = formatDate(lastDay, format, locale);

        // tslint:disable-next-line:triple-equals
        if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined) {
            // tslint:disable-next-line:radix
            this.startdate = parseInt(formattedDateFirst);
            // tslint:disable-next-line:radix
            this.enddate = parseInt(formattedDateLast);
            // this.loadData();
            console.log('Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
        }
        this.service.getData(this.startdate, this.enddate, this.offset, this.limit).subscribe(value => {
            this.tableData = value;
            console.log(this.tableList);
            this.alert.hideLoader();
        });
    }


    // tslint:disable-next-line:typedef
    downloadReport() {
        this.alert.showLoader();

        const format = 'yyyyMMdd';
        const locale = 'en-IN';
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const formattedDateFirst = formatDate(firstDay, format, locale);
        const formattedDateLast = formatDate(lastDay, format, locale);

        // tslint:disable-next-line:triple-equals
        if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined) {
            // tslint:disable-next-line:radix
            this.startdate = parseInt(formattedDateFirst);
            // tslint:disable-next-line:radix
            this.enddate = parseInt(formattedDateLast);
            // this.loadData();
            console.log('Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
        }
        const file = new FileService<any>();
        // tslint:disable-next-line:prefer-const
        let trArr = [];
        let tdArr = [];
        tdArr.push('Id');
        tdArr.push('Phone_Number');
        tdArr.push('Uid');
        tdArr.push('Status');
        tdArr.push('Date');
        trArr.push(tdArr);
        this.service.getData(this.startdate, this.enddate, this.offset, this.limit).subscribe(value => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < value.total; i++) {
                tdArr = [];
                tdArr.push(value.data[i].id);
                tdArr.push(value.data[i].phoneNumber);
                tdArr.push(value.data[i].uid);
                tdArr.push(value.data[i].status);
                tdArr.push(value.data[i].date);
                trArr.push(tdArr);
            }
            file.generateFile(trArr, 'Shubh_Labh_Campaign.xlsx');
        });
        this.alert.hideLoader();
    }

    // tslint:disable-next-line:typedef
    filterDatafordate() {
        this.dialog.open(DatefilterComponent);
    }

    clearFilter() {
        this.router.navigate([], {queryParams: {}});
    }

    tableChange(json) {
        this.offset = json.pageIndex * json.pageSize;
        this.limit = json.pageSize;
        this.loadDataforDate(0, 0);
    }
}
