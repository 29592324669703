import { Component, OnInit } from '@angular/core';
import {WaContactGroupServiceService} from '../../wa-contact-group-service.service';
import {WaContactGroupDao} from '../../wa-contact-group-dao';
import {Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private service: WaContactGroupServiceService, private router: Router, private alert: AlertMessageService, private location: Location) { }
tabelist: Array<WaContactGroupDao>;
  groupMaster: any = {
    id: '',
    phonenumber: '',
    status: '',
    date: '',
    dateFilter: '',
    clientName: '',
    email: '',
    address: '',
    type: '',
    userId: '',
    groupId: '',
    groupName: '',
  };

  ngOnInit(): void {
    this.getGroupList();
  }


  // tslint:disable-next-line:typedef
  getGroupList()
  {
    this.service.get().subscribe(value => {
      this.tabelist = value;
    });
  }
  // tslint:disable-next-line:typedef
  save() {
    this.alert.showLoader();
    this.alert.confirmMessage('Insert Data?', 'Are you sure?').then(value2 => {
      // tslint:disable-next-line:max-line-length
      this.service.insertClient(this.groupMaster).subscribe(value1 => {
         this.alert.showSuccesMessage('insert Successfully !!');
         console.log('value1',value1);
         this.cancel();
      }, error => {
        console.log(error);
        this.alert.showErrorMessage(error.error.message.toString());
        this.alert.hideLoader();
      });
    });
  }

  // tslint:disable-next-line:typedef
  cancel() {
     this.location.back();

  }
}
