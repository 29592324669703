<!--name: Sujit Yadav-->
<!--   Date: 23/june/2022-->

<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" class="btn btn-add">Add Deployement <i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="addNewDiv">
          <form ngNativeValidate (submit)="submit()">

            <div class="form-group col-lg-12">
              <label>Project <sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="project" name="project">
                <ng-container>
                  <option value="undefined">---Select Project---</option>
                  <option value="Whatsappunoffical">Whatsappunoffical</option>
                  <option value="Digimozocommon">Digimozocommon</option>
                  <option value="Gsmgateway">Gsmgateway</option>
                  <option value="Lmstree">Lmstree</option>
                  <option value="Whatsappapiui">Whatsappapiui</option>
                  <option value="Lmstreeangular">Lmstreeangular</option>
                  <option value="WhatsappOfficial">WhatsappOfficial</option>
                  <option value="Whatsapp">Whatsapp</option>
                  <option value="chatbotnodejs">chatbotnodejs</option>
                  <option value="Authserver">Auth_Server</option>
                  <option value="Drive">Drive</option>
                  <option value="ConfigServer">Config_Server</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-lg-12">
              <label>Technology <sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="technology" name="technology">
                <ng-container>
                  <option value="undefined"> ---Select Technology---</option>
                  <option value="java">Java</option>
                  <option value="angular">Angular</option>
                  <option value="nodejs">Nodejs</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-lg-12">
              <label>Project Type <sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="type" name="type">
                <ng-container>
                  <option value="undefined"> ---Select Project Type---</option>
                  <option value="microservices">Microservices</option>
                  <option value="standalone">Standalone</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-lg-12">
              <label>Server <sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="server" name="server">
                <ng-container>
                  <option value="undefined"> ---Select Server---</option>
                  <option value="appServer">Application Server</option>
                  <option value="DbServer">Database Server</option>
                  <option values="PromotionServer">Whatsapp Promotion Server</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-lg-12">
              <label>Deployement Container <sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="deployementcontainer" name="deployementcontainer">
                <ng-container>
                  <option value="undefined"> --Select Deployement Container---</option>
                  <option value="tomcat">Tomcat</option>
                  <option value="pm2">PM2</option>
                </ng-container>
              </select>
            </div>

            <!--            <div class="form-group col-lg-12">-->
            <!--              <label>UploadedBy  <sup class="required">*</sup></label>-->
            <!--&lt;!&ndash;              <input type="text" class="form-control"  [(ngModel)]="uploadedBy" name="uploadedBy" required>&ndash;&gt;-->

            <!--              <select class="form-control"   [(ngModel)]="uploadedBy" name="uploadedBy"  >-->
            <!--                <ng-container >-->
            <!--                  <option value="undefined" >    &#45;&#45;Select Name-&#45;&#45;  </option>-->
            <!--                  <option value="tomcat">Gurpreet Singh</option>-->
            <!--                  <option value="Rahul">Rahul Mehra</option>-->
            <!--                  <option value="Sujit">Sujit Yadav</option>-->
            <!--                  <option value="Monika">Monika  Singh</option>-->
            <!--                </ng-container>-->
            <!--              </select>-->
            <!--            </div>-->

            <div class="form-group col-lg-12">
              <label>File <sup class="required">*</sup></label><br>
              <!--              <input type="file" class="form-control"   name="file"  (change)="onFileSelect($event && $event != 'null' ? $event : null)" multiple required>-->
              <button type="button" (click)="uploadFileComponent()" class="btn btn-add">Select File <i
                class="fa fa-upload" required></i></button>

            </div>

            <div class="form-group col-lg-12">
              <label>Description <sup class="required">*</sup></label>
              <textarea type="text" class="form-control" [(ngModel)]="description" name="description" required
                        placeholder="Enter Project Description"></textarea>
            </div>


            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Submit</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>

          </form>

        </div>


        <!--==================================Change Status=========================================-->

        <div *ngIf="addNewDivS">
          <form ngNativeValidate (submit)="updateStatus()">

            <div class="form-group col-lg-12">
              <label>Approved_By <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="approvedBy" name="uploadedBy" required
                     placeholder="Enter Your Name">
            </div>

            <div class="form-group col-lg-12">
              <label>DeployedBy <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="deployedBy" name="uploadedBy" required
                     placeholder="Enter Your Name">
            </div>


            <div class="form-group col-lg-12">
              <label>Status<sup class="required">*</sup></label>
              <select class="form-control" [(ngModel)]="status" name="project">
                <ng-container>
                  <option value="undefined"> --Select Project Status---</option>
                  <option value="deployed">Deployed</option>
                  <option value="faild">Faield</option>
                  <option value="error">Error</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Submit</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>

          </form>

        </div>

        <!--==============================================Data Table=================================-->
        <div *ngIf="!addNewDivS">

          <div *ngIf="!addNewDiv">
            <table class="table table-striped row-border hover">
              <thead>
              <tr>
                <th>Project</th>
                <th>Type</th>
                <th>Server</th>
                <th>FileName</th>
                <th>UploadedBy</th>
                <th>ApprovedBy</th>
                <th>DeployedBy</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="tableList==null">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <ng-container *ngFor="let user of tableList ;let ind =index">
                <tr>
                  <td [innerHTML]="user.project"></td>
                  <td [innerHTML]="user.type"></td>
                  <td [innerHTML]="user.server"></td>
                  <td [innerHTML]="user.fileName"></td>
                  <td [innerHTML]="user.uploadedBy"></td>
                  <td [innerHTML]="user.approvedBy"></td>
                  <td [innerHTML]="user.deployedBy"></td>
                  <td [innerHTML]="user.status"></td>
                  <!--                <td *ngIf="user.status == null">-->
                  <!--                  <i   style=" margin-left: 5px;  font-size: 18px;color: blue"   class="hvr-buzz-out fa fa-ban"></i> </td>-->
                  <!--                <td *ngIf="user.status == 'Approved'">-->
                  <!--                  <i style="margin-left:5px;  font-size: 18px;color: green"   class="hvr-buzz-out fa fa-check"></i>-->
                  <!--                </td>-->
                  <!--                <td *ngIf="user.status == 'Faild'">-->
                  <!--                  <i class="fa fa-exclamation-triangle" style=" margin-left: 5px;  font-size: 18px;color: red" aria-hidden="true"></i>-->

                  <!--                </td>-->

                  <!--                <td  [innerHTML]='user.status ==""?"<i style=\"margin-left: 5px;  font-size: 18px;color: green\"   class=\"hvr-buzz-out fa fa-check\"></i>":"<i style=\" margin-left: 5px;  font-size: 18px;color: red\"    class=\"hvr-buzz-out fa fa-ban\"></i>"'></td>-->
                  <td [innerHTML]="user.date"></td>
                  <td>
                    <button class="btn btn-add" (click)="changeStatus(user.id)"><i class="fa fa-pencil"
                                                                                   aria-hidden="true"
                                                                                   title="Click here to change status"></i>
                    </button>&nbsp;&nbsp;
                    <!--                  <button class="btn btn-add" (click)="changeStatus(user.id)"><i class="fa fa-edit" aria-hidden="true" title="Click here to edit"></i></button>-->
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
