




<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>
          <select (change)="refreshChange()" style="width: 200px!important;" type="date" [(ngModel)]="managerId" name="managerId" required>
            <ng-container *ngFor="let user of userList">
              <option [value]="user.id" [innerHTML]="user.fullname"></option>
            </ng-container>

          </select>

          <select (change)="refreshChange()"  style="width: 200px!important;" type="date" [(ngModel)]="team" name="team" required>

            <option value="0">Show All</option>
            <option value="ELN">ELN</option>
            <option value="HT">HT</option>
            <option value="HA">HA</option>
          </select>

          <select (change)="refreshChange()"  style="width: 200px!important;" type="date" [(ngModel)]="brand" name="brand" required>

            <option value="0">Show All</option>
            <ng-container *ngFor="let product of listproduct">
              <option [value]="product.product" [innerHTML]="product.product"></option>
            </ng-container>
          </select>

        <select (change)="refreshChange()"  style="width: 200px!important;" type="date" [(ngModel)]="show" name="show" required>

          <option value="valid">Valid</option>
          <option value="invalid">In-Valid</option>

        </select>


      </div>
      </div>
      <div style="" class="panel-body">

        <div  id="tablediv">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Date</th>
                <th>Team</th>
                <th>Mobile No</th>
                <th>Emp Id</th>
                <th>User Name</th>
                <th>Manager Mobile No</th>
                <th>Manager Name</th>
                <th>Zone</th>
                <th>Brand1</th>
                <th>QTY</th>

              </tr>
              </thead>
              <tbody *ngIf="userMap!=null">
              <tr style="text-align: center" *ngIf="tableData.total==0">
                <td colspan="9">No Entry</td>


              </tr>

              <ng-container  *ngFor="let data of tableData.data;let ind = index">
                <tr>
                  <td [innerHTML]="data.date"></td>
                  <td [innerHTML]="data.team"></td>
                  <td [innerHTML]="data.mobile"></td>
                  <td [innerHTML]="data.empId"></td>
                  <td [innerHTML]="data.empName"></td>
                  <td [innerHTML]="getUserDetails(data.managerId).phone"></td>
                  <td [innerHTML]="getUserDetails(data.managerId).fullname" ></td>
                  <td [innerHTML]="data.zone"></td>
                  <td [innerHTML]="data.product"></td>
                  <td *ngIf="role=='ADMIN'">

                    <input type="number" [(ngModel)]="data.qty" [name]="'dropdown'+ind"> <button  (click)="editQty(data.qty,data.id)" class="btn btn-add fa fa-save"></button>

                  </td>

                  <td *ngIf="role!='ADMIN'" [innerHTML]="data.qty">



                  </td>
                        </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5,10, 25, 100]">
          </mat-paginator>

        </div>

      </div>
    </div>
  </div>
</div>









