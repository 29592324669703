import { Component, OnInit } from '@angular/core';
import {RegistrationOtpDao} from "../registration-otp-dao";
import {RegistrationOtpService} from "../registration-otp.service";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  tableList: Array<RegistrationOtpDao>;

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  constructor(private service:RegistrationOtpService,private alert:AlertMessageService) { }

  ngOnInit(): void {
    this.service.get().subscribe(value => {
      console.log(value)
      this.tableList=value;
      this.dtTrigger.next();
    })
  }

}
