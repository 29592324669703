
<h1 mat-dialog-title style="text-align: center">Password Change <i class="fa fa-unlock"></i> </h1>

<form style="width: 350px" ngNativeValidate (submit)="submitData()">
  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Old Password</label>
      <div class="col-sm-12">
        <input name="oldpassword" [(ngModel)]="oldpassword" type="password" class="form-control"  required/>
      </div>
    </div>

    <div class="form-group col-lg-12">
      <label>New Password</label>
      <div class="col-sm-12">
        <input name="newpassowrd" [(ngModel)]="newpassowrd" type="password" class="form-control"  required/>
      </div>
    </div>
    <div class="form-group col-lg-12">
      <label>Confirm New Password</label>
      <div class="col-sm-12">
        <input name="newpasswordCon" [(ngModel)]="newpasswordCon" type="password" class="form-control"  required/>
      </div>
    </div>

    <div class="form-group col-lg-12">

      <label >Enter Total Of Following</label>
      <div class="col-sm-12">
      <input tabIndex="-1" id="number1" name="firstNumber" class="calculatediv" [(ngModel)]="firstNumber" type="number" readonly="readonly"  value=""><span class="calculatediv">+</span> <input tabIndex="-1" id="number2" name="secondNumber" [(ngModel)]="secondNumber" class="calculatediv" readonly="readonly" type="number" value="">

        <b>    =</b>  <input name="total" [(ngModel)]="total" type="number" class="calculatediv"  required/>
      </div>
    </div>









  </mat-dialog-content>
  <mat-dialog-actions align="end">
  </mat-dialog-actions>
  <button [mat-dialog-close]="null"  class="btn btn-add pull-right" type="button">Cancel</button>
  <button class="btn btn-add pull-right" type="submit">Save</button>
</form>

