import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {TokenDao} from './TokenDao';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.css']
})
export class ApiKeyComponent implements OnInit {
  tokenDao:TokenDao={createAt: '', lastCreateBy: '', token: ''}
  constructor(private http:HttpClient) {
    this.getToken();
  }
  getToken()
  {
    this.http.get<TokenDao>(environment.appUrl+environment.whatsappOfficialservice+"externaltoken/").subscribe(value => {
      this.tokenDao=value;
    })
  }
  ngOnInit(): void {
  }

  regerateToken() {
    this.http.get<TokenDao>(environment.appUrl+environment.whatsappOfficialservice+"externaltoken/generatetoken").subscribe(value => {
      this.tokenDao=value;
    })
  }
}
