<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

        </div>
      </div>
      <div style="" class="panel-body">


      <div  id="tablediv">
  <h3>Reseller Stats</h3>

  <div class="table-responsive">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th class="color-green">AccountId</th>
        <th>Name</th>

        <th>Acc. Type</th>
        <th>Total Msg</th>
        <th>Sent Msg</th>
        <th>Remaining</th>
        <th>Number</th>

        <th>Connected</th>
        <th>Expiry</th>
        <th>Type</th>
        <th>Credit</th>
        <th>Debit</th>
        <th>Balance</th>






      </tr>
      </thead>
      <tbody>
      <tr style="text-align: center" *ngIf="tableData.length==0">
        <td colspan="14">No Entry</td>


      </tr>
      <ng-container *ngFor="let data of tableData;let ind = index">
        <tr>
          <td  [innerHTML]="data.id"  ></td>
          <td [innerHTML]="getInnerHtml(data,'accountName')" ></td>

          <td [innerHTML]="getInnerHtml(data,'accountType')" ></td>

          <td [innerHTML]="getInnerHtml(data,'totalMsg')" ></td>
          <td [innerHTML]="getInnerHtml(data,'totalSent')" ></td>
          <td [innerHTML]="getInnerHtml(data,'totalRem')" ></td>
          <td  [innerHTML]="getInnerHtml(data,'phoneNumber')"></td>

          <td ><i [class]="getInnerHtml(data,'connected')"></i></td>
          <td  [innerHTML]="getInnerHtml(data,'expiry')" ></td>
          <td [innerHTML]="getInnerHtml(data,'type')" ></td>
          <td [innerHTML]="getInnerHtml(data,'credit')" ></td>
          <td [innerHTML]="getInnerHtml(data,'debit')" ></td>
          <td [innerHTML]="getInnerHtml(data,'balance')" ></td>






        </tr>
      </ng-container>



      </tbody>
    </table>
  </div>
<!--  <mat-paginator (page)="tableChange($event)"-->
<!--                 [length]=tableData.length-->
<!--                 [pageSize]="10"-->
<!--                 [pageSizeOptions]="[2, 5, 25, 100]">-->
<!--  </mat-paginator>-->

</div>
      </div>
    </div>
  </div>
</div>
