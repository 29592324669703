
<div style="width: 800px;overflow: scroll;overflow-y: hidden">
<h1 mat-dialog-title style="text-align: center"> {{graphCommonDao.header}} <i class="fa fa-bar-chart"></i></h1>
<mat-dialog-content class="mat-typography">
  <app-chart-and-tables [chartData]="graphCommonDao.chartData"></app-chart-and-tables>
</mat-dialog-content>


<mat-dialog-actions>

  <button mat-dialog-close="null" type="button" class="btn btn-add pull-right">Close</button>


</mat-dialog-actions>
</div>
