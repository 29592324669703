<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div id="tabview" class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
                <div class="panel-title row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                        <label style="color: #093265;" class="col-lg-1 col-md-1 col-sm-2">Date</label>
                        <input class="col-lg-2 col-md-2 col-sm-4" type="date" [(ngModel)]="filterDao.startdate">
                        <input class="col-lg-2 col-md-2 col-sm-4" type="date" [(ngModel)]="filterDao.enddate">
                        <label style="color: #093265; margin-right: 5px" class="col-lg-1 col-md-1 col-sm-2">Account</label>
                        <input class="col-lg-2 col-md-2 col-sm-4" type="number" [(ngModel)]="filterDao.accountId">
                        <button (click)="filterData()" class="btn btn-add col-lg-1 col-md-2 col-sm-4"><i class="fa fa-filter"></i> Filter</button>
                        <button (click)="clearFilter()" class="btn btn-add col-lg-1 col-md-2 col-sm-4">Clear <i class="fa fa-filter"></i></button>
                        <button (click)="downloadReport()" class="btn btn-add col-lg-1 col-md-2 col-sm-4"><i class="fa fa-download"></i></button>
                    </div>
                </div>
            </div>
            <div  class="panel-body">
                <div class="table-responsive">
                    <table id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
                        <thead>
                        <tr>
                            <th>Account ID</th>
                            <th>Company Name</th>
                            <th>Client Name</th>
                            <th>Client Number</th>
                            <th>Email</th>
                            <th>Create At </th>
                            <th>Client Type</th>
                            <th>Whatsapp Number</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let clientSignUpDao of tableList;let ind = index">
                            <tr>
                                <td [innerHTML]="clientSignUpDao.id"></td>
                                <td [innerHTML]="clientSignUpDao.companyName"></td>
                                <td [innerHTML]="clientSignUpDao.clientName"></td>
                                <td [innerHTML]="clientSignUpDao.clientNumber"></td>
                                <td [innerHTML]="clientSignUpDao.email"></td>
                                <td [innerHTML]="clientSignUpDao.createAt"></td>
                                <td style="color: #0b3ca4" *ngIf="clientSignUpDao.defaultSubscription == false">Client Account</td>
                                <td style="color: #c2a514" *ngIf="clientSignUpDao.defaultSubscription == true">Demo Account</td>
                                <td *ngIf="clientSignUpDao.defaultSubscription == false" [innerHTML]="clientSignUpDao.whatsappNumber"></td>
                                <td *ngIf="clientSignUpDao.defaultSubscription == true" [innerHTML]="'No Value'"></td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
