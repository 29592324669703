import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ClientSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {HttpClient} from "@angular/common/http";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {TextSMSSendingDao} from "../client-sign-up/TextSMSSendingDao";
import {environment} from "../../environments/environment.prod";

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {
  receivedPhoneOtp: number;
  receivedWhatsappNumberOtp: number;
  phoneOtp: number;
  whatsappNumberOtp: number;
  textSMSSendingDao: TextSMSSendingDao;
  accountCreationStatus: boolean = false;
  signUpButtonStatus: boolean = false;
  showSpinner: boolean = true;

  constructor(private alertService: AlertMessageService, private http:HttpClient, private dialog: MatDialogRef<OtpVerificationComponent>,@Inject(MAT_DIALOG_DATA) public clientSignUpDao: ClientSignUpDao) { }

  ngOnInit(): void {
      this.generateOtp();
  }

  back() {
    this.dialog.close(this.clientSignUpDao);
  }
  generateOtp(){
    let msg = "";
    this.phoneOtp = Math.round((Math.random()*1000000));
    msg = "Your one-time password (OTP) for signing up for WhatsApp services is "+this.phoneOtp+"\nPRP SERVICES PRIVATE LIMITED";
    this.sendOtp(this.clientSignUpDao.clientNumber,this.phoneOtp,"PRPSMS", msg);
      if(this.clientSignUpDao.defaultSubscription == false){
        this.whatsappNumberOtp = Math.round((Math.random()*1000000));
        msg = "Your one-time password (OTP) for the verification of WhatsApp number service is "+this.whatsappNumberOtp+"\nPRP SERVICES PRIVATE LIMITED";
        this.sendOtp(this.clientSignUpDao.whatsappNumber,this.whatsappNumberOtp,"PRPSMS",msg);
      }
  }
  sendOtp(number: string,otp:any, senderId: any, msg: string) {
      // let msg = "";
      if(this.accountCreationStatus == false){
        // msg = "OTP for the Login: "+otp+" PRPOTP";
        // msg = "Your one-time password (OTP) for signing up for WhatsApp services is "+otp+"\nPRP SERVICES PRIVATE LIMITED";
        this.textSMSSendingDao = {msg:msg, number: number, senderId:senderId};
        this.http.post<TextSMSSendingDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a", this.textSMSSendingDao).subscribe(value => {
          this.alertService.showSuccesMessage("Successfully OTP Send to Given Details");
        });
      }else{
        // msg = "Dear Customer Your Account has been created with us. Your Login Credential is Account id: "+this.clientSignUpDao.id+" and password: "+this.clientSignUpDao.clientNumber+" ACCINF";
        this.textSMSSendingDao = {msg:msg, number: number, senderId:senderId};
        this.http.post<TextSMSSendingDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a", this.textSMSSendingDao).subscribe();
      }
  }

  verifyOtp(){
    let verifyStatus = false;
    if(this.phoneOtp == this.receivedPhoneOtp ){
      if(this.clientSignUpDao.defaultSubscription == false){
        if(this.whatsappNumberOtp == this.receivedWhatsappNumberOtp){
          verifyStatus = true;
          return verifyStatus;
        }else{
          this.showSpinner = true;
          this.signUpButtonStatus = false;
          this.alertService.showErrorMessage("Registered Whatsapp Number Otp Doesn't Matched");
            return verifyStatus;
        }
      }
      verifyStatus = true;
      return verifyStatus;
    }else{
      this.showSpinner = true;
      this.signUpButtonStatus = false;
      this.alertService.showErrorMessage("Registered Number Otp Doesn't Matched");
      return verifyStatus;
    }
  }
    accountCreation() {
            console.log("THe value of CLient Sign up dao is ",this.clientSignUpDao);
            this.http.post<ClientSignUpDao>(environment.appUrl + environment.whatsappOfficialservice +"api/clientsignup/insert?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a",this.clientSignUpDao).subscribe(value => {
                this.accountCreationStatus = true;
                this.clientSignUpDao.id = value.id;
                if(this.accountCreationStatus == true){
                  let msg = "Dear Customer, Your account has been created with us.\nYour login credentials are as follows:\n1- Account ID: " + this.clientSignUpDao.id +"\n2- Password: "+ this.clientSignUpDao.clientNumber +"\nYou can access your account at: https://wa.chatmybot.in/\nThank you,\nPRP SERVICES PRIVATE LIMITED"
                  this.sendOtp(this.clientSignUpDao.clientNumber,0, "ACCINF", msg);
                    this.alertService.hideLoader();
                    this.showSpinner = true;
                    this.alertService.showSuccesMessage("Account Created and Login details are Send to registered Mobile Number");
                    this.accountCreationStatus = false;
                  this.signUpButtonStatus = false;
                  this.back();
                }else{
                  this.alertService.hideLoader();
                  this.showSpinner = true;
                  this.alertService.showErrorMessage("Account is not created. Error Occurred");
                    this.back();
                }
            },error => {
                console.log("The error is ", error);
            });
    }

  signUp() {
    this.showSpinner = false;
    this.signUpButtonStatus = true;
    this.alertService.showLoader();
    let verificationStatus = this.verifyOtp();
      if(verificationStatus == true){
        this.accountCreation();
    }else{
        this.alertService.hideLoader();
        console.log("Account not created");
    }
  }
}
