import { Injectable } from '@angular/core';
import {CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../User/user.service';
import {FeatureDao} from "../../Feature/feature-dao";
import {environment} from "../../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";
import {any} from "codelyzer/util/function";

const appPrefix1=environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  currentPage: any;
  constructor(private router: Router,private userMasterServiceService:UserService,private http:HttpClient) {
    // let tempUrl=router.url.split("?")[0];
    // this.currentPage=tempUrl.substring(tempUrl.lastIndexOf("/") + 1);
    // console.log("currentUrl: ",this.currentPage);
    // console.log("currentUrl is: ",this.router.url);


  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.userMasterServiceService.isLogin)
    {
      let tempUrl=state.url.split("?")[0];
      //   this.currentPage=tempUrl.substring(tempUrl.lastIndexOf("/") + 1);
      // console.log("currentUrl: ",this.currentPage);
       console.log("currentUrl:  And permission is ",tempUrl,this.userMasterServiceService.permission)
      return this.userMasterServiceService.permission.indexOf(tempUrl) > -1;
    }else{

      return false;
    }




  }



  getPagePermission(pageName:string): Observable<FeatureDao>
  {
    return this.http.get<FeatureDao>(appPrefix1 + 'auth/v1/api/features/getbypage?pageName='+pageName);
  }

}
