import { Component, OnInit } from '@angular/core';
import {RegistrationOtpDao} from "../../Registration-Otp/registration-otp-dao";
import {Subject} from "rxjs";
import {RegistrationOtpService} from "../../Registration-Otp/registration-otp.service";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {PaginationDao} from "../../../../CommonObj/PaginationDao";

@Component({
  selector: 'app-registration-kk',
  templateUrl: './registration-kk.component.html',
  styleUrls: ['./registration-kk.component.css']
})
export class RegistrationKKComponent implements OnInit {

  tableList: Array<RegistrationOtpDao>;
  tableData: PaginationDao<any> = {data: [], total: 0}
  offset:number=0;
  limit:number=10;

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  constructor(private service:RegistrationOtpService,private alert:AlertMessageService) { }

  ngOnInit(): void {
   this.getData();
  }

  getData() {
    this.service.getFromKK(this.offset,this.limit).subscribe(value => {
      this.tableData = value;
    })
  }

  tableChange(json) {
    this.offset = json.pageIndex * json.pageSize;
    this.limit = json.pageSize;
    this.getData();
  }
}
