import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConversationSubscriptionService} from '../../conversation/ConversationSubscription/ConversationSubscriptionService';

@Component({

  selector: 'incomingmessage',
  templateUrl: './incomingmessagecomponent.component.html',
  styleUrls: ['./incomingmessagecomponent.component.css']
})
export class IncomingmessagecomponentComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  constructor(private conversationSubcriptionService: ConversationSubscriptionService) { }

  ngOnInit(): void {
    this.subscription = this.conversationSubcriptionService.getObj().subscribe(value => {


    })
  }

}
