import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {FilterDao} from '../FilterDao';
import {GroupReportDao} from '../GroupReportDao';
import {Observable} from 'rxjs';
const appPrefix = environment.appUrl + environment.accountMicroverservice;
@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) {
  }

  getGroupByReport(filter: FilterDao): Observable<Array<GroupReportDao>> {
    return this.http.post<Array<GroupReportDao>>(appPrefix + 'accounttransaction/getreportgroup', filter);
  }
  getTransByReport(filter: FilterDao): Observable<Array<GroupReportDao>> {
    return this.http.post<Array<GroupReportDao>>(appPrefix + 'accounttransaction/gettrans', filter);
  }

}

export class ReportServiceService {
}
