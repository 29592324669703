import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {BungalowMasterDao} from "./BungalowMasterDao";
import {SlabDao} from "./slab-dao";
import {environment} from "../../../environments/environment.prod";
import {TransationDao} from "./transation-dao";
import {BungalowHistoryDao} from "./bungalow-history-dao";
import * as http from "http";

const appPrefix = environment.appUrl + 'nbcc/v1';
// const  appPrefix='https://173.212.247.177:8080';
//const  appPrefix='http://localhost:8080';

@Injectable({
  providedIn: 'root'
})
export class NbccserviceService {

  constructor(private http: HttpClient) {
  }

  // headers.append('Access-Control-Allow-Origin', '*');
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization"
  }

  getAllData(): Observable<Array<BungalowMasterDao>> {

    // ,{headers:this.headers}
    return this.http.get<Array<BungalowMasterDao>>(appPrefix + "/api/v1/getAll");
  }

  findById(id: string): Observable<BungalowMasterDao> {
    let url = appPrefix + `/api/v1/get/${id}`;
    return this.http.get<BungalowMasterDao>(url);
  }

  deleteById(id: string): Observable<any> {
    let url = appPrefix + `/api/v1/get/${id}`;
    return this.http.get<any>(url);
  }

  update(data: any): Observable<BungalowMasterDao> {
    let url = appPrefix + '/api/v1/update';
    return this.http.patch<BungalowMasterDao>(url, data)
  }


  insert(data: any): Observable<BungalowMasterDao> {
    let url = appPrefix + '/api/v1/save';
    return this.http.post<BungalowMasterDao>(url, data)
  }

  payment(data: any): Observable<any> {
    let url = appPrefix + '/api/v1/payment';
    return this.http.post<any>(url, data)
  }

  insertSlab(data: any): Observable<SlabDao> {
    let url = appPrefix + '/api/slabMaster/save';
    return this.http.post<SlabDao>(url, data)
  }


  updateSlab(data: any): Observable<SlabDao> {
    let url = appPrefix + '/api/slabMaster/update';
    return this.http.patch<SlabDao>(url, data)
  }

  getSlab(): Observable<Array<SlabDao>> {
    let url = appPrefix + '/api/slabMaster/get';
    return this.http.get<Array<SlabDao>>(url);
  }

  deleteSlabById(id: any): Observable<any> {
    let url = appPrefix + `/api/slabMaster/delete/${id}`;
    return this.http.delete<any>(url);
  }

  generateRecipt(data: any): Observable<any> {
    let url = appPrefix + '/api/v1/generateRecipt';
    return this.http.post<any>(url, data)
  }


  otpVerifiy(number: any, otp: any): Observable<any> {
    let url = appPrefix + `/api/v1/otp/${number}/${otp}`;
    console.log("finalurl ", url)
    return this.http.get<any>(url)
  }

  whatsapp(data: any): Observable<BungalowMasterDao> {
    let url = appPrefix + '/api/v1/whatsapp';
    return this.http.post<BungalowMasterDao>(url, data)
  }

  sms(data: any): Observable<BungalowMasterDao> {
    let url = appPrefix + '/api/v1/sms';
    return this.http.post<BungalowMasterDao>(url, data)
  }

  generateNoc(data: BungalowMasterDao): Observable<any> {
    let url = appPrefix + '/api/v1/generatenoc';
    return this.http.post<any>(url, data, {responseType: 'text' as 'json'});
  }


  checkPaymentStatus(bungalowNo: string): Observable<any> {
    let url = appPrefix + `/api/trans/status/${bungalowNo}`;
    return this.http.get<Array<any>>(url);
  }

  savePaymentDetails(data: TransationDao): Observable<any> {
    let url = appPrefix + '/api/trans/save';
    return this.http.post<any>(url, data);
  }

  checkpagepermission() {
    return true;
  }

  getBungalowHistory(): Observable<any> {
    return this.http.get<Array<BungalowHistoryDao>>(appPrefix + '/api/bungalowhistory/get');
  }


  getTransHistory(startdate: any, enddate: any): Observable<any> {
    return this.http.get<Array<TransationDao>>(appPrefix + '/api/trans/get?startDate=' + startdate + '&endDate=' + enddate);
  }

  sendrecipt(): Observable<string> {
    return this.http.get<string>(appPrefix + '/api/v1/sendgeneraterecipt', {responseType: 'text' as 'json'})
  }

  deleteByIdTrans(id) {
    let params = new HttpParams();
    return this.http.get<number>(appPrefix+'/api/trans/delete?id='+id);
  }
}
