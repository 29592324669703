import {Component, Inject, OnInit} from '@angular/core';
import {EmbeddedSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TextSMSSendingDao} from "../client-sign-up/TextSMSSendingDao";
import {environment} from "../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {ClientSignUpService} from "../client-sign-up/client-sign-up.service";

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent implements OnInit {
  otp: number;
  otpSend: boolean = false;
  enableSendButton: boolean = true;
  counter: number =0;
  intervalId: any;
  generatedOtp: number = 0;
  textSMSSendingDao: TextSMSSendingDao;



  constructor(@Inject(MAT_DIALOG_DATA) public embeddedSignUpDao:EmbeddedSignUpDao, private dialogRef: MatDialogRef<EmbeddedSignUpDao>,private http:HttpClient, private alertService:AlertMessageService, private client:ClientSignUpService) { }

  ngOnInit(): void {
  }

  validateNumber(){
    this.embeddedSignUpDao.contactNumber = this.embeddedSignUpDao.contactNumber.replace(/[^0-9\n]/g,"").replace(/ /g, '').trim();
  }

  signUp() {
      let status = this.verifyOtp();
      console.log("Status is "+ status);
      if(status == true){
        this.client.insertToEmbeddedSignUpDao(this.embeddedSignUpDao).subscribe(value => {
          this.alertService.showSuccesMessage("Our Sales Person will Contact you shortly");
          this.dialogRef.close();
          this.clearMethod();
        },error => {
          this.alertService.showErrorMessage("Bad Request");
          this.dialogRef.close();
        });
      }
  }

  test() {

  }

  sendOtp() {
    if(this.embeddedSignUpDao.contactNumber.match(/^[0-9]+$/)) {
      console.log("Status is " + status);
      console.log("Send OTP Method called");
      this.otpSend = true;
      this.enableSendButton = false;
      this.generateOtp();
      this.delayMethod();
    } else {
      this.alertService.showErrorMessage("Number not Valid");
    }
  }
  delayMethod(){
    setTimeout(() => {
      this.enableSendButton = true;
    }, 30000);
      this.intervalId = setInterval(() => {
        if(this.counter == 30){
          this.counter =0;
          clearInterval(this.intervalId);
        }else{
          this.counter++;
        }
      }, 1000);
  }
  generateOtp(){
    this.generatedOtp = Math.round((Math.random()*1000000));
    let msg = "Your one-time password (OTP) for signing up for WhatsApp services is "+this.generatedOtp+"\nPRP SERVICES PRIVATE LIMITED";
    this.pushOtp(this.embeddedSignUpDao.contactNumber,this.generatedOtp,"PRPSMS", msg);
  }
  pushOtp(number: string,otp:any, senderId: any, msg: string) {
    this.textSMSSendingDao = {msg:msg, number: number, senderId:senderId};
      this.http.post<TextSMSSendingDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a", this.textSMSSendingDao).subscribe(value => {
        console.log("The value of text response is "+ value);
      });
  }
  verifyOtp(){
    let verifyStatus = false;
    if(this.generatedOtp == this.otp ){
      verifyStatus = true;
      return verifyStatus;
    }else{
      // this.showSpinner = true;
      // this.signUpButtonStatus = false;
      this.alertService.showErrorMessage("Registered Number Otp Doesn't Matched");
      return verifyStatus;
    }
  }

  clearMethod() {
    this.otp = 0;
    this.otpSend = false;
    this.enableSendButton = true;
    this.counter = 0;
    this.generatedOtp = 0;
    this.embeddedSignUpDao = {
      tempToken:'',
      businessToken:'',
      wabaId:'',
      phoneNumberId:'',
      name:'',
      email:'',
      contactNumber:'',
      displayName:'',
      whatsappNumber:''
    };
  }
}
