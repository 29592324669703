<div  class="row">
  <div class="col-sm-12">
    <div class="mailbox">
      <div class="mailbox-header">
        <div class="row">
          <div class="col-xs-3">

          </div>
          <div class="col-xs-9">
            <div *ngIf="chatHistoryDao!=null" style="width: 20%;float: left" class="inbox-avatar">
              <i class="fa fa-user-circle fa-lg"></i>
              <div class="inbox-avatar-text hidden-xs hidden-sm">
                <div class="avatar-name" [innerHTML]="chatHistoryDao.customerNumber"></div>

              </div>
            </div>
            <div style="width: 80%;float: right" class="inbox-toolbar btn-toolbar pull-right">


              <div class="btn-group pull-right">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mailbox-body">
        <div class="row m-0">
          <div class="col-sm-3 p-0 inbox-nav ">
            <div style="max-height: 500px;overflow: scroll" class="mailbox-sideber">
              <div class="profile-usermenu">
                <ul *ngIf="conversationList!=null" class="nav">
                  <ng-container *ngFor="let conve of conversationList.data">


                    <li >
                      <a (click)="takeMeToNumber(conve)"><i class="fa fa-user-circle"></i>

                        <b [innerHTML]="conve.customerNumber"></b>
                        </a>

                    </li>
                  </ng-container>
                  <mat-paginator (page)="tableChange($event,conversationList)" [length]=conversationList.total
                                 [pageSize]="10"
                                 [pageSizeOptions]="[10]">
                  </mat-paginator>
                </ul>
                <hr>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-9 p-0 inbox-mail">

            <div id="chatconversationdiv" style="max-height: 400px;min-height: 400px;overflow: scroll;overflow-x: hidden" class="mailbox-content">
              <ng-container *ngIf="chatHistoryDao==null">
                <h4 style="text-align: center;margin-top: 100px">No Contact Selected</h4>
              </ng-container>
              <ng-container *ngFor="let mess of messageList">
                <app-message-obj [messageDao]="mess"></app-message-obj>
              </ng-container>






            </div>
            <footer>

            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
