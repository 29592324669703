
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div    class="panel panel-bd lobidragdashboard">
      <div   class="panel-heading">
        <div class="panel-title">
<!--          <button (click)="addResidential(false)"  class="btn btn-add">Add New Data <i class="fa fa-plus"></i></button>-->
          <button style="margin-left: 5px"   class="btn btn-add " title="Bulk Upload (Maximum 1000)"   (click)="bulkUpload()">Bulk Upload <i  (click)="downloadSampleFile()" title="Click Here To View Sample File" class="fa fa-question-circle"></i>
          </button>
          <button  style="margin-left: 5px" (click)="downloadReport()" class="btn btn-add">Download <i class="fa fa-download"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="!addNewDiv" class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Min Slab</th>
              <th>Max Slab</th>
              <th>Consumption Charges</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of tableList;let ind =index">

              <tr *ngIf="tableList.length==0">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr>
                <td [innerHTML]="data.minSlab"></td>
                <td [innerHTML]="data.maxSlab"></td>
                 <td [innerHTML]="data.consumptionCharges"></td>
                <td>
                  <i title="Click Here To Edit" class="btn btn-add fa fa-pencil" (click)="editDao(data,ind,true)"></i>
                  <i title="Click Here To Delete" class="btn btn-danger fa fa-trash" (click)="delete(data.id)"></i>

                </td>


              </tr>
            </ng-container>
            </tbody>
          </table>

        </div   >

        <div *ngIf="addNewDiv">
        <h3 style="text-align: center;text-decoration: underline;text-decoration-color: darkred"> <b>Update Slab</b></h3><br>

        <form ngNativeValidate  class= "form-group col-lg-12" (ngSubmit)="createOrUpdate()" >


          <div class="form-group col-lg-6">
            <label   class="control-label" for="bungalowNo">Min Slab<sup class="required">*</sup></label>
            <input  type="text" placeholder="Please min slab" title="Please Enter Min Slab" required   name="minSlab" id="bungalowNo" class="form-control"  [(ngModel)]="this.dao.minSlab" >
          </div>
<!--          <br/>-->
          <div class="form-group col-lg-6">
            <label   class="control-label" for="ewsQtrsNo">Max Slab. <sup class="required">*</sup></label>
            <input    type="text" placeholder="Enter maxSlab" title="Please Enter maxSlab" required   name="maxSlab" id="ewsQtrsNo" class="form-control"  [(ngModel)]="this.dao.maxSlab"  >
          </div>
          <div class="form-group col-lg-6">
            <label   class="control-label" for="name">Consumption Charge.<sup class="required">*</sup></label>
            <input   type="text" placeholder="Please Enter Consumption Charge" title="Please Enter Consumption Charge" required   name="consumptionCharges" id="name" class="form-control"  [(ngModel)]="this.dao.consumptionCharges">
          </div>
          <div class="col-sm-6" style="margin-left:  83%">
            <button type="submit" class="btn btn-add btn-sm">{{submitBtnTxt}}</button>
            <button type="button" (click)="onClose()" mat-dialog-close class="btn btn-danger btn-sm">Close</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
</div>
