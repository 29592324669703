<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" *ngIf="hasWriteOrUpdatePermission" class="btn btn-add">Add New Template<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="hasWriteOrUpdatePermission && addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">
            <div class="form-group col-lg-12">
              <label>Template Status  <sup class="required">*</sup></label>
             <mat-slide-toggle name="templatestatus" [(ngModel)]="templateDao.status"></mat-slide-toggle>
            </div>


            <div class="form-group col-lg-12">
              <label>Template Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="templateDao.name" name="name" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Template Namespace  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="templateDao.namespace" name="namespace" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Policy   <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="templateDao.language.policy" name="policy" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Body Text<sup class="required">*</sup></label>
              <textarea type="text" class="form-control" [(ngModel)]="templateDao.templateBodyText" name="templateBodyText" required></textarea>
            </div>
            <div class="form-group col-lg-12" >
              <label>Template Category   <sup class="required">*</sup></label>
              <select [(ngModel)]="templateDao.category" type="text" class="form-control" name="category" required >
                <option [value]="'MARKETING'" >MARKETING</option>
                <option [value]="'UTILITY'" >UTILITY</option>
                <option [value]="'AUTHENTICATION'" >AUTHENTICATION</option>
              </select>
            </div>
            <div class="form-group col-lg-12">
              <label>Language   <sup class="required">*</sup></label>
              <select type="text" class="form-control" [(ngModel)]="templateDao.language.code" name="code" required>
             <ng-container *ngFor="let lang of languageJson">

               <option [value]="lang.Code" [innerHTML]="lang.Language"></option>
             </ng-container>
              </select>
            </div>
            <ng-container *ngFor="let components of templateDao.components">
            <div class="form-group col-lg-12">
              <label> Components ({{components.type}})   <i (click)="addNewRow(components.type)" class="btn btn-add fa fa-plus-circle"></i></label>
              <div *ngIf="components.type!='button'">
              <table class="table table-striped row-border hover">
                <thead>
                <tr>
                  <th>Parameter Name</th>
                  <th>Parameter Type</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let params of components.parameters;let ind = index">
                  <tr><td [innerHTML]="params.nameOfParams"></td><td [innerHTML]="params.type"></td><td><i (click)="editParameter(params,ind,components.type)" class="fa fa-pencil-square btn btn-add"></i><i (click)="deleteParams(params,ind,components.type)" class="fa fa-trash btn btn-add"></i></td></tr>
                </ng-container>
                </tbody>
              </table>
              </div>

              <div *ngIf="components.type=='button'">
                <table class="table table-striped row-border hover">
                  <thead>
                  <tr>
                    <th>Type</th>
                    <th>Button Name</th>
                    <th *ngIf="components.sub_type=='url'">URL Type</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let params of components.parameters;let ind = index">
                    <tr><td [innerHTML]="components.type"></td>
                      <td [innerHTML]="components.sub_type"></td>
                      <td *ngIf="components.sub_type=='url'">
                        <select [(ngModel)]="components.urlType" name="urlType">
                        <option value="static">Static</option>
                        <option value="dynamic">Dynamic</option>
                      </select>
                      </td>
                      <td><i (click)="editParameter(params,ind,components.type)" class="fa fa-pencil-square btn btn-add"></i><i (click)="deleteParams(params,ind,components.type)" class="fa fa-trash btn btn-add"></i></td></tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>







            </div>
            </ng-container>




            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Template Id</th>
              <th>Template Name</th>
              <th>Approval Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let template of tableList;let ind =index">
              <tr>
                <td [innerHTML]="template.id"></td>
                <td [innerHTML]="template.name"></td>
                <td><i *ngIf="template.status" style="color: green" class="fa fa-check"></i><i *ngIf="!template.status"
                                                                                           style="color: red"
                                                                                           class="fa fa-remove"></i>
                </td>
                <td><i title="Click Here To Edit" (click)="edit(template,ind)" *ngIf="hasWriteOrUpdatePermission"
                       class="btn btn-add fa fa-pencil"></i></td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







