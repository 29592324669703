import {Component, OnInit} from '@angular/core';
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {LongcodekkDao} from "./longcodekk-dao";
import {UploadLongcodeDataService} from "./upload-longcode-data.service";

@Component({
  selector: 'app-upload-long-code-data',
  templateUrl: './upload-long-code-data.component.html',
  styleUrls: ['./upload-long-code-data.component.css']
})
export class UploadLongCodeDataComponent implements OnInit {
  isUpload: boolean = false;
  file: File;
  fileData: any
  longcodkk: LongcodekkDao;
  program: '';
  toleng = 0;
  campaignName: string[] = ["KK", "NR"];

  constructor(private alert: AlertMessageService, private service: UploadLongcodeDataService) {
  }

  ngOnInit(): void {
  }

  enableUpload() {
    this.isUpload = true;
  }

  uploadFile(event) {
    this.file = event.target.files[0];
  }

  insert() {
    const fileService = new FileService<any>();

    fileService
      .readFile(this.file)
      .then((value) => {
        const dataLength = value.length;
        this.fileData = value;

        if (dataLength < 1) {
          this.alert.showErrorMessage('No Data found!!');
          return Promise.reject('No Data found');
        } else {
          this.alert.showLoader();
          return this.service.insertkk(this.fileData);
        }
      })
      .then((value) => {
        console.log('values', value);
        this.alert.showSuccesMessage("Successfully Uploaded!!")
        this.cancel();
      })
      .catch((error) => {
      })
      .finally(() => {
        this.alert.hideLoader();
      });
  }


  cancel() {
    this.isUpload = false;
    this.program = '';
    //  window.location.reload();
  }

  downloadSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];

    tdArr.push('uid');
    tdArr.push('region');
    tdArr.push('asmAreana');
    tdArr.push('dbCode');
    tdArr.push('distributorCode');
    tdArr.push('uidSecond');
    tdArr.push('retailerName');
    tdArr.push('state');
    tdArr.push('town');
    tdArr.push('populationBracket');
    tdArr.push('classification');

    trArr.push(tdArr);
    tdArr = [];
    tdArr.push('1110011');
    tdArr.push('Delhi');
    tdArr.push('karol Bagh');
    tdArr.push('1234');
    tdArr.push('sujit');
    tdArr.push('1110011');
    tdArr.push('Sujit Yadav');
    tdArr.push('Delhi');
    tdArr.push('NA');
    tdArr.push('NA');
    trArr.push(tdArr);

    file.generateFile(trArr, 'KKMasterDataSample.xlsx');
    this.alert.hideLoader();
  }
}
