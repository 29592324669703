import {Component, OnDestroy, OnInit} from '@angular/core';
import {ObdService} from './obd.service';
import {OBDCampaignDao} from './OBDCampaignDao';
import {Subject} from 'rxjs';
import {OBDCampaignGroupByDao} from './OBDCampaignGroupByDao';
import {CampaignDao} from '../../onetomany/CampaignDao';
import {ChartDataDao} from '../../chart-and-tables/ChartDataDao';
import {Label} from 'ng2-charts';
import {GraphCommonDao} from '../../graph-common/GraphCommonDao';
import {GraphCommonComponent} from '../../graph-common/graph-common.component';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {any} from 'codelyzer/util/function';
import {OBDChartComponent} from '../obdchart/obdchart.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountFilterDao} from '../../account-master/account-filter/AccountFilterDao';
import {AccountFilterComponent} from '../../account-master/account-filter/account-filter.component';
import {FilterDao} from '../../crdrreport/FilterDao';
import {formatDate} from '@angular/common';
import {PaginationDao} from '../../CommonObj/PaginationDao';
import {EnquiryDetailDao} from '../../MicroServices/Nouriture/enquiry-details/EnquiryDetailDao';
import {environment} from '../../../environments/environment.prod';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {UserService} from '../../Services/User/user.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
const appprefix2 = environment.appUrl;

@Component({
  selector: 'app-obd',
  templateUrl: './obd.component.html',
  styleUrls: ['./obd.component.css']
})
export class OBDComponent implements OnInit, OnDestroy {

  startdate: number = undefined;
  enddate: number = undefined;

  // tslint:disable-next-line:no-shadowed-variable max-line-length
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private ObdService: ObdService, private dialog: MatDialog, private alert: AlertMessageService) {
    // this.loadData();
    // this.loadDataforDate();
  }
  dateFilterType: Array<string>;
  services: Array<string>;
  groupBy: Array<string>;
  private filter: FilterDao;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  // dtOptions: any = {bPaginate: true, order: []};
  tableList: Array<OBDCampaignGroupByDao> = null;
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;
      console.log(this.startdate, + '  ' + this.enddate);
      this.loadDataforDate();
      this.startdate = undefined;
      this.enddate = undefined;
    });
  }
  // tslint:disable-next-line:typedef
  loadData()
  {
    this.ObdService.getCampaignOBDGroupByReport().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }
  // tslint:disable-next-line:typedef
  loadDataforDate() {
    this.tableList = null;
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    this.ObdService.getCampaignOBDGroupByReportDatewise(this.startdate, this.enddate).subscribe(value => {
        this.tableList = value;
        console.log(this.tableList);
        this.alert.hideLoader();
      });
  }
  // tslint:disable-next-line:typedef
  filterDatafordate() {
    this.dialog.open(DatefilterComponent);
  }
  // tslint:disable-next-line:typedef
  drawObdReport(user: OBDCampaignGroupByDao, ind: number) {
    this.alert.showLoader();
    // @ts-ignore
    // this.ObdService.getTransCampaingWise(user.name, user.date).subscribe(campResponse => {
      // const chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
      // const labels: Array<Label> = [];
      // const value: Array<string> = [];
      // // tslint:disable-next-line:prefer-for-of
      // for (let i = 0; i < campResponse.length; i++)
      // {
      //   labels.push(campResponse[i]._id);
      //   value.push(campResponse[i].id);
      // }
      // chartDataDao.labels = labels;
      // // tslint:disable-next-line:max-line-length
      // tslint:disable-next-line:max-line-length
      // chartDataDao.barChartData.push({data: value, label: 'Message Report', backgroundColor: '#FB8170', hoverBackgroundColor: '#fb8170',  fill: false, borderColor: '#fb8170', barThickness: 40});//     chartDataDao.chartName = 'Day Wise Report';
      // const graph: GraphCommonDao = {chartData: chartDataDao, header: user.name + ' Report'};
    this.dialog.open(GraphCommonComponent);
    this.alert.hideLoader();
   // });
  }
  // tslint:disable-next-line:typedef
    downloadReport(user: OBDCampaignGroupByDao, ind: number) {
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('Campaign Id');
    tdArr.push('Phone No');
    tdArr.push('Campaign Name');
    tdArr.push('Service Number');
    tdArr.push('Call Dial Time');
    tdArr.push('Call Answer Time');
    tdArr.push('Call End Time');
    tdArr.push('Call Duration');
    tdArr.push('Call Status');
    tdArr.push('Disposition');
    tdArr.push('Pulse');

    trArr.push(tdArr);
    this.ObdService.getTransCampaingWise(user.date, user.name).subscribe(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++)
      {
        tdArr = [];
        tdArr.push(value[i].id);
        tdArr.push(value[i].phoneNo);
        tdArr.push(value[i].champaginName);
        tdArr.push(value[i].serviceNo);
        tdArr.push(value[i].callDialTime);
        tdArr.push(value[i].callAnswerTime);
        tdArr.push(value[i].callEndTime);
        tdArr.push(value[i].callDuration);
        tdArr.push(value[i].callStatus);
        tdArr.push(value[i].disposition);
        // tslint:disable-next-line:radix
        tdArr.push(Math.ceil(parseInt(value[i].callDuration) / 30));
        trArr.push(tdArr);
      }
      file.generateFile(trArr,   'Campaign Report.xlsx');
  });
   }
  // tslint:disable-next-line:typedef
  clearFilter()
  {
    this.router.navigate([], {queryParams: {}});
  }
  // tslint:disable-next-line:typedef
  // filterData() {
  //   const accountFilterDao: AccountFilterDao = {
  //     datefilterType: this.dateFilterType,
  //     filter: this.filter,
  //     groupBy: this.groupBy,
  //     pageName: 'obdCampaignReport',
  //     service: this.services
  //   };
  //   this.dialog.open(AccountFilterComponent, {data: accountFilterDao});
  // }
}
