// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore

import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {GroupDao} from './mailgroupadd/group-dao';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ShubhLabhcampaignDao} from '../../RahulPackage/shubh-labhcampaign/shubh-labhcampaign-dao';
import {MailgroupsService} from './mailgroups.service';
import {LongcodenrctDao} from '../../RahulPackage/LongcodeNRCT/longcodenrct-dao';
import {data} from 'jquery';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {group} from '@angular/animations';
import {MailReportDao} from '../mailreport/mail-report-dao';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MailReportService} from '../mailreport/mail-report.service';
import {formatDate} from '@angular/common';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {MatDialog} from '@angular/material/dialog';
import {ScheduleMailComponent} from '../schedule-mail/schedule-mail.component';
import {CommoninstancedropdownComponent} from '../../Unofficial/commoninstancedropdown/commoninstancedropdown.component';
import {UploadFileComponent} from '../../ApplicationDeployement/upload-file/upload-file.component';
import {environment} from "../../../environments/environment.prod";


// @ts-ignore
@Component({
  selector: 'app-mailgroup',
  templateUrl: './mailgroup.component.html',
  styleUrls: ['./mailgroup.component.css']
})
export class MailgroupComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private http: HttpClient, private service: MailgroupsService, private alert: AlertMessageService, private reportService: MailReportService, private formBuilder: FormBuilder, private dialog: MatDialog, private route: ActivatedRoute) {
  }

  createdBy: string;
  groupId: string;
  groupName: string;
  groupDesc: string;
  openForm: boolean;
  tableList: Array<GroupDao>;
  tableList1: Array<MailReportDao>;
  table: string;
  searchText: any;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  display = false;
  subject: any;
  message: any;
  uploadForm: FormGroup;
  startdate: number = undefined;
  enddate: number = undefined;
  addNewDiv = false;
  scheduleDate: string;
  attachmentLinks: Array<string>;

  formData;
 // mailSchedule = false;
  // tslint:disable-next-line:typedef
  updateToGroup =
  {
      id: '',
      groupName: '',
      groupDesc: '',
    createdBy: ''
  };


  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  // tslint:disable-next-line:typedef
  addnew() {
    this.router.navigate(['mailgroupadd']);
  }

  // tslint:disable-next-line:typedef
  getData() {
    console.log('method is call');
    this.service.getAll().subscribe(value => {
      for (let i = 0; i < value.length; i++) {
        this.tableList = value;
        // tslint:disable-next-line:max-line-length
        console.log('table balue' + this.tableList[i].groupDesc + this.tableList[i].groupId + this.tableList[i].groupName + this.tableList[i].status);
      }
    });
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });

    console.log('Heeyyyyy');
    this.getData();




    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;

      console.log(this.startdate, + '  ' + this.enddate);
      this.loadDataforDate();
    });



    this.loadData();


  }


  // tslint:disable-next-line:typedef
  cancel() {
    this.addNewDiv = false;
  }

  // tslint:disable-next-line:typedef
  save(id: number, groupName: any) {

    this.router.navigate(['groupMaster'], {state: {groupId: id, gname: groupName} });

  }


  // tslint:disable-next-line:typedef
  delete(id: number) {
    console.log('id is tis' + id);
    this.alert.showLoader();
    this.alert.confirmMessage('Delete Group?', 'Are you sure?').then(value => {
      this.http.delete(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/group/delete?groupId=' + id).subscribe(value1 => {
        window.location.reload();
      }, error => {
        this.alert.showErrorMessage(error.error.message);
      });
    }, );
  }

  // tslint:disable-next-line:typedef
  updateGroup(updateForm: GroupDao) {
    const groupId = updateForm.groupId;
  //  this.router.navigate(['groupMaster'], {state: {groupId: updateForm.id}});
    console.log('GroupId is ' + groupId );
    // tslint:disable-next-line:prefer-for-of


    this.alert.showLoader();
    this.alert.confirmMessage('Update Group?', 'Are you sure?').then(value => {
      this.http.put(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/group/update/{groupId}' , updateForm  ).subscribe(value1 => {

        window.location.reload();
      }, error => {
        this.alert.showErrorMessage(error.message);
        window.location.reload();
      });
    }, );
  }

  // tslint:disable-next-line:typedef
  edit(user: any) {
  this.updateToGroup = user;
  this.groupDesc = user.groupDesc;
  }

  // tslint:disable-next-line:typedef
  createOrUpdate(data1: GroupDao) {
    console.log('Fucntion Working');
    this.alert.showLoader();
    this.alert.confirmMessage('Group Create?', 'Are you sure?').then(value => {
      this.service.insertData(data1).subscribe(value1 => {

        this.alert.showSuccesMessage('Successfully Created!!');
        this.cancel();
        window.location.reload();
        this.alert.hideLoader();

      }, error => {
        console.log('error is this ' + error.message);
        this.alert.showErrorMessage('Something Went Wrong!!');
        this.alert.hideLoader();
      });
    }, );


  }

  // tslint:disable-next-line:typedef
  refresh() {
    window.location.reload();
  }


  // tslint:disable-next-line:typedef
  submitData() {

    // tslint:disable-next-line:triple-equals
    if (this.scheduleDate == 'undefined') {
       this.scheduleDate = null;
    }
    console.log('groupId ' + this.groupId);
    console.log('subject ' + this.subject);
    console.log('message ' + this.message);
    console.log(' this.scheduleDate ' +  this.scheduleDate);
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('filetoUploadDrive').value ) ;

    this.subject = this.subject.replaceAll('&', 'And111');
    this.subject = this.subject.replaceAll('#', 'hash111');
    this.subject = this.subject.replaceAll('@', 'atthi111');
    this.subject = this.subject.replaceAll('+', 'plus111');
    this.subject = this.subject.replaceAll('*', 'astrict111');
    this.subject = this.subject.replaceAll('%', 'module111');
    this.subject = this.subject.replaceAll('$', 'dolor111');
    this.subject = this.subject.replaceAll('\n', 'enter123');

    this.message = this.message.replaceAll('&', 'And111');
    this.message = this.message.replaceAll('#', 'hash111');
    this.message = this.message.replaceAll('@', 'atthi111');
    this.message = this.message.replaceAll('+', 'plus111');
    this.message = this.message.replaceAll('*', 'astrict111');
    this.message = this.message.replaceAll('%', 'module111');
    this.message = this.message.replaceAll('$', 'dolor111');
    this.message = this.message.replaceAll('\n', 'enter123');
    console.log('formData are:: ' + formData);
    this.alert.showLoader();
    this.alert.confirmMessage('Send  Mail?', 'Are you sure?').then(value => {
      // tslint:disable-next-line:max-line-length
      // this.http.post('https://wa.chatmybot.in/gateway/gsmgateway/v1/api/mailSend/send?groupId=' + this.groupId + '&subject=' + this.subject + '&message=' + this.message, formData, {headers: {Testtt: 'multipart/form-data'}}).subscribe(value1 => {
      // tslint:disable-next-line:max-line-length
       this.http.post('http://localhost:8081/api/schedular/mail?groupId=' + this.groupId + '&subject=' + this.subject + '&body=' + this.message + '&date=' + this.scheduleDate, this.formData, {headers: {Testtt: 'multipart/form-data'}}).subscribe(value1 => {
        this.alert.showSuccesMessage('Send Successfully !!');
        // this.cancel();
        window.location.reload();

      }, error => {
        this.alert.showErrorMessage(error.error.message);
      });
    }, );

  }




  // tslint:disable-next-line:typedef
  onFileSelect(event: Event) {

    if ((event.target  as HTMLInputElement).files.length > 0) {
      const file = (event.target  as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
    }

  }


  // tslint:disable-next-line:typedef
  loadData()
  {
    // @ts-ignore
    this.service.getAll().subscribe(value => {
      this.tableList = value;
      console.log(this.tableList);
      this.dtTrigger.next();
    });
  }


  // tslint:disable-next-line:typedef
  loadDataforDate() {
    this.tableList1 = null;
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    this.service.getData(this.startdate, this.enddate).subscribe(value => {
      // @ts-ignore
      this.tableList1 = value;
      console.log('report table value' + this.tableList1);
      this.alert.hideLoader();
    });
  }

  // tslint:disable-next-line:typedef
  downloadReport() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('Email');
    tdArr.push('Status');
    tdArr.push('Date');

    trArr.push(tdArr);
    this.service.getData(this.startdate, this.enddate).subscribe(value => {

      console.log('values are' + value);

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value; i++)
      {
        tdArr = [];
        tdArr.push(value[i].email);
        tdArr.push(value[i].status);
        tdArr.push(value[i].date);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,   'EmailReport.xlsx');
    });
    this.alert.hideLoader();
  }
  // tslint:disable-next-line:typedef
  filterDatafordate() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter()
  {
    this.router.navigate([], {queryParams: {}});
  }

  // tslint:disable-next-line:typedef
  reportPage() {

    this.router.navigate(['mailReport']);
  }

  // tslint:disable-next-line:typedef
  allMail() {
     this.router.navigate(['allMail']);
  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  createSchedule() {

    console.log('schedule method calling');
    this.dialog.open(ScheduleMailComponent, {
       width: '40%',
      height: '40%'
    }).afterClosed().subscribe(value => {

      // tslint:disable-next-line:triple-equals
       if (value == 'undefined')
       {
            this.scheduleDate = null;
       }
       else
       {
         this.scheduleDate = value;
         console.log('scedule date ' + this.scheduleDate);
         console.log('Schedule value getting' + value);
       }

    });
  }


  // tslint:disable-next-line:typedef
  addGroup() {
    // tslint:disable-next-line:no-unused-expression
    this.addNewDiv = true;
  }
  // tslint:disable-next-line:typedef
  uploadFileComponent() {
    this.dialog.open(UploadFileComponent).afterClosed().subscribe(value => {
        const fileList: FileList = value.target.files;
        if (fileList.length > 0) {
          const file = fileList[0];
          // const formData = new FormData();
          this.formData = new FormData();
          this.formData.append('file', file);
         // this.attachmentLinks.push(this.formData.get('file').name);
         // console.log('attachmentLinks' + this.attachmentLinks);

          console.log('form Data value' + this.formData);
        }
        else
        {
          this.alert.showErrorMessage('Please Select File');
        }
        console.log(value);
      });


  }


}
