import {Component, OnInit} from '@angular/core';
import {NbccserviceService} from "../nbccservice.service";
import {BungalowMasterDao, ResidentialDao} from "../BungalowMasterDao";
import {MatDialog} from "@angular/material/dialog";
import {AddResidentialComponent} from "../add-residential/add-residential.component";
import {FeatureAcessComponent} from "../../../feature-acess/feature-acess.component";
import {FeatureDao} from "../../../Feature/feature-dao";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {GroupMasterDao} from "../../../mailSendingApp/group-master/group-master-dao";
import {FormBuilder} from "@angular/forms";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {Subject} from "rxjs";
import {SlabDao} from "../slab-dao";
import {formatDate} from "@angular/common";
import {Router} from "@angular/router";
import {PaymentComponent} from "../payment/payment.component";
import {TransationComponent} from "../transation/transation.component";
import {environment} from "../../../../environments/environment.prod";

@Component({
  selector: 'app-nbcc',
  templateUrl: './nbcc.component.html',
  styleUrls: ['./nbcc.component.css']
})
export class NbccComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, order: []};
  tableList: Array<BungalowMasterDao>;
  private isInsert: boolean;
  private data: BungalowMasterDao;
  uploadForm: any;
  residentialDao: ResidentialDao = {
    name: '',
    number: '',
  };
  dao: BungalowMasterDao = {
    currentReading: null,
    previousReading: null,
    bungalowNo: '',
    residentiaId: null,
    residentialDao: this.residentialDao,
    meterNo: '',
    chargeableUnit: null,
    ewsQtrsNo: '',
    payDate: '',
    date: '',
    totalAmout: null,
    paymentStaus: false,
    maintenanceReading: null,
    billPeriod: '',
    outstandingAmount: null,
    outstandingPeriod: '',
    discription: '',
    billNumber: '',
    occupationDate: ''
  };

  arrayofbungaow: Array<BungalowMasterDao>;
  slabDao: SlabDao = {id: null, maxSlab: null, minSlab: null, consumptionCharges: null};
  page: string;
  addNewDiv: boolean = false;
  formattedDateFirst: any;

  constructor(private service: NbccserviceService, private dialog: MatDialog, private alert: AlertMessageService, private formBuilder: FormBuilder, private router: Router) {
    const urls = router.url;
    // let after =urls.split("?")[1];
    // after=after.split("=")[1]
    console.log('paramurl', urls);

    if (urls != '/gsmgateway/v1/bunglowmaster') {
      this.addNewDiv = true;
      console.log('thisaddnewdiv', this.addNewDiv);
    }
  }

  ngOnInit(): void {
    this.getData();
    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });

    const format = 'yyyyMM';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    this.formattedDateFirst = formatDate(firstDay, format, locale);
    console.log('formattedDateFirst', this.formattedDateFirst)
  }

  editDao(data: any, ind: number, page: boolean) {
    this.isInsert = false;
    const dialogRef = this.dialog.open(AddResidentialComponent, {
      width: "50%",
      data: {data: data, pageValue: page},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != 1 && result != undefined) {
        this.data = result;
        this.updateOrIns(this.data, this.isInsert, true);
      }
    });
  }

  delete(id) {

  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  addResidential(page: boolean) {
    this.isInsert = true;
    const dialogRef = this.dialog.open(AddResidentialComponent, {
      width: "50%",
      data: {pageValue: page}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != 1 && result != undefined) {
        this.data = result;
        this.updateOrIns(this.data, this.isInsert, true);
      }
    });
  }

  getData() {
    this.service.getAllData().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    })
  }

  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const toleng = value.length;

      console.log('arrayofvalues', value);
      console.log('data length', toleng);
      if (toleng < 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      if (this.addNewDiv == false) {
        this.arrayofbungaow = value;
        this.service.insert(this.arrayofbungaow).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
          console.log('upload file values is :: ' + value1);
          this.alert.showSuccesMessage('Upload Successfully !!');
          window.location.reload();
          this.cancel();
        }, error => {
          this.alert.showErrorMessage('Failed To Upload Data!!');
          this.alert.hideLoader();
        });
      } else {
        this.arrayofbungaow = value;
        console.log('arrays', this.arrayofbungaow);
        this.alert.hideLoader();
        this.service.generateRecipt(this.arrayofbungaow).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
          this.alert.showSuccesMessage('Upload Successfully !!');
          this.cancel();
        }, error => {
          this.alert.showErrorMessage('Failed To Upload Data!!');
          this.alert.hideLoader();
        });
      }

      // this.alert.showLoader();
      // for (let i = 0; i < toleng; i++) {
      //   if (this.addNewDiv == false) {
      //     this.dao.bungalowNo = value[i]['Bungalow No'];
      //     this.dao.ewsQtrsNo = value[i]['EwsQtrs No'];
      //     this.dao.meterNo = value[i]['Meter No'];
      //     this.dao.residentialDao.name = value[i]['Name'];
      //     this.dao.residentialDao.number = value[i]['Number'];
      //     this.dao.previousReading = value[i]['Previous Reading'];
      //     this.dao.maintenanceReading = value[i]['Maintenance Reading']
      //
      //     this.service.insert(this.dao).subscribe(value1 => {
      //       this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
      //       console.log('upload file values is :: ' + value1);
      //       this.alert.showSuccesMessage('Upload Successfully !!');
      //       window.location.reload();
      //       this.cancel();
      //     }, error => {
      //       this.alert.showErrorMessage('Failed To Upload Data!!');
      //       this.alert.hideLoader();
      //     });
      //   } else {
      //     // this.dao.bungalowNo = value[i]['Bungalow No'];
      //     // this.dao.currentReading = value[i]['Current Reading'];
      //     // this.dao.payDate = value[i]['Due Date'];
      //     // this.dao.billPerioud = value[i]['Bill Period'];
      //     // this.dao.outstandingAmount = value[i]['Outstanding Amount']
      //
      //
      //     this.service.generateRecipt(this.dao).subscribe(value1 => {
      //       this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
      //       this.alert.showSuccesMessage('Upload Successfully !!');
      //       this.cancel();
      //     }, error => {
      //       this.alert.showErrorMessage('Failed To Upload Data!!');
      //       this.alert.hideLoader();
      //     });
      //   }
      //
      //
      //
      // }
      this.alert.hideLoader();
      // window.location.reload();

    });


  }

  createOrUpdate() {
    this.updateOrIns(this.data, this.isInsert, true);

  }

  async updateOrIns(data: BungalowMasterDao, isInsert: boolean, showConfirmMessage: boolean) {

    this.alert.showLoader();
    if (showConfirmMessage) {
      let message = 'Update Data?';
      if (isInsert) {
        message = 'Insert Data?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }

    if (isInsert) {
      this.service.insert(this.data).subscribe(value => {
        console.log("Inserted Data", value)
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Created !!');
        }
        this.cancel();

      });
      this.alert.hideLoader();
    } else {
      this.service.update(this.data).subscribe(value => {
        console.log("updated Data", value)
        if (showConfirmMessage) {

          this.alert.showSuccesMessage('Successfully Updated !!');
        }
        this.cancel();
        this.alert.hideLoader();
      });
    }
    // window.location.reload();
  }

  cancel() {
    this.data = null;
  }

  onFileSelect($event: Event) {
    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
    }
  }

  downloadSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    if (this.addNewDiv == false) {
      tdArr.push('bungalowNo');
      tdArr.push('ewsQtrsNo');
      tdArr.push('name');
      tdArr.push('number');
      tdArr.push('meterNo');
      tdArr.push('previousReading');
      tdArr.push('maintenanceReading');
    } else {
      tdArr.push('bungalowNo');
      tdArr.push('currentReading');
      tdArr.push('payDate');
      tdArr.push('billPeriod');
      tdArr.push('outstandingAmount');
      tdArr.push('outstandingPeriod');
      tdArr.push('occupationDate');
    }
    trArr.push(tdArr);

    tdArr = [];
    if (this.addNewDiv == false) {
      tdArr.push('A212');
      tdArr.push('ABC212');
      tdArr.push('Avtar');
      tdArr.push('9999999999');
      tdArr.push('CBD067/2023-02-18');
      tdArr.push('400.0');
      tdArr.push('40.0');
      trArr.push(tdArr);
      file.generateFile(trArr, 'SampleBungalowDataFile.xlsx');
      this.alert.hideLoader();
    } else {
      tdArr.push('A212');
      tdArr.push('4400');
      tdArr.push('2023-01-01');
      tdArr.push('2023-01-01 to2023-01-01');
      tdArr.push('200');
      tdArr.push('10/march/2023');
      tdArr.push('2023-01-01');
      trArr.push(tdArr);
      file.generateFile(trArr, 'SampleGenerateBillFile.xlsx');
      this.alert.hideLoader();
    }

  }

  generateRecipt(dao: BungalowMasterDao) {
    this.alert.showLoader();
    this.service.generateRecipt(dao).subscribe(value => {
      console.log("values: ", value)
      this.alert.showSuccesMessage('Bill Send !!');
    })
    this.alert.hideLoader();
  }

  sendWhatsapp(data: BungalowMasterDao) {
    this.alert.showLoader();
    this.alert.confirmMessage("Send Receipt On Whatsapp", "Are You Sure?").then(value => {
      this.service.whatsapp(data).subscribe(value => {
        console.log("values: ", value)
        this.alert.showSuccesMessage('Bill Send !!');
        this.alert.showLoader();

      })
    })


  }

  sendSMS(data: BungalowMasterDao) {
    this.alert.showLoader();
    this.alert.confirmMessage("Send Recipt On SMS", "Are You Sure?").then(value => {
      this.service.sms(data).subscribe(value => {
        console.log("values: ", value)
        this.alert.showSuccesMessage('Recipt Sent !!');
        this.alert.showLoader();
      })
    });
  }

  viewRecipt(data: BungalowMasterDao) {


    location.href = environment.driveLocationUrl + "Shareablelinks/NBCCPDF/" + data.bungalowNo + this.formattedDateFirst.toString() + ".pdf";

  }

  downloadReport() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    if (this.addNewDiv == false) {
      tdArr.push('Bungalow No');
      tdArr.push('EWSQrts No');
      tdArr.push('Name Of Allottee');
      tdArr.push('Mobile No');
      tdArr.push('Meter No');
      tdArr.push('Previous Reading');
      tdArr.push('Maintenance Reading');
    } else {
      tdArr.push('Bungalow No');
      tdArr.push('EWSQrts No');
      tdArr.push('Name Of Allottee');
      tdArr.push('Mobile No');
      tdArr.push('Meter No');
      tdArr.push('Previous Reading');
      tdArr.push('Current Reading');
      tdArr.push('Charegable Unit');
      tdArr.push('Outstanding Amount');
      tdArr.push('Due Date');
      tdArr.push('Total Amount');
    }
    trArr.push(tdArr);
    this.service.getAllData().subscribe(value => {
      let fileName = 'Bungalow_Data_Report.xlsx';
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++) {
        tdArr = [];
        if (this.addNewDiv == false) {
          tdArr.push(value[i].bungalowNo);
          tdArr.push(value[i].ewsQtrsNo);
          tdArr.push(value[i].residentialDao.name);
          tdArr.push(value[i].residentialDao.number);
          tdArr.push(value[i].meterNo);
          tdArr.push(value[i].previousReading);
          tdArr.push(value[i].maintenanceReading);
          trArr.push(tdArr);
          // file.generateFile(trArr, ' Bungalow_Data_Report.xlsx');
          // this.alert.hideLoader();
        } else {
          tdArr.push(value[i].bungalowNo);
          tdArr.push(value[i].ewsQtrsNo);
          tdArr.push(value[i].residentialDao.name);
          tdArr.push(value[i].residentialDao.number);
          tdArr.push(value[i].meterNo);
          tdArr.push(value[i].previousReading);
          tdArr.push(value[i].currentReading);
          tdArr.push(value[i].chargeableUnit);
          tdArr.push(value[i].outstandingAmount);
          tdArr.push(value[i].payDate);
          tdArr.push(value[i].totalAmout);
          trArr.push(tdArr);
          fileName = 'Generate_Bil_Report.xlsx';
        }

      }
      file.generateFile(trArr, fileName);
      this.alert.hideLoader();
    });
  }

  downloadSlabSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('Max Slab');
    tdArr.push('Min Slab');
    tdArr.push('Consumption Charge');
    trArr.push(tdArr);

    tdArr = [];
    tdArr.push('7000');
    tdArr.push('5000');
    tdArr.push('20');
    trArr.push(tdArr);
    file.generateFile(trArr, 'Slab_Sample_BulkUpload.xlsx');
    this.alert.hideLoader();
  }

  bulkUploadSlab() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const toleng = value.length;
      console.log('data length', toleng);
      if (toleng < 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      this.alert.showLoader();
      for (let i = 0; i < toleng; i++) {
        this.slabDao.maxSlab = value[i]['Max Slab'];
        this.slabDao.minSlab = value[i]['Min Slab'];
        this.slabDao.consumptionCharges = value[i]['Consumption Charge'];
        this.service.insertSlab(this.slabDao).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
          console.log('upload file values is :: ' + value1);
          this.alert.showSuccesMessage('Upload Successfully !!');
          this.cancel();
        }, error => {
          this.alert.showErrorMessage('Failed To Upload Data!!');

          this.alert.hideLoader();
        });

      }
      this.alert.hideLoader();

    });

  }

  generateNoc(data: BungalowMasterDao) {
    console.log('data', data);
    this.alert.showLoader();
    this.alert.confirmMessage('Generate NOC', 'Are You Sure?').then(value => {
      this.service.generateNoc(data).subscribe((response: any) => {
        this.cancel()
        if (response == 'Payment Is Pending Can\'t Be Generate NOC') {
          this.alert.showErrorMessage(response);
        } else {
          this.alert.showSuccesMessage(response);
        }
      }, (error: any) => {
        this.alert.showErrorMessage(error.message);
      })
    })
    this.alert.hideLoader();
  }

  payPayment(data: any) {
    this.router.navigate(['payment'], {
      queryParams: {
        amount: data.totalAmout,
        name: data.residentialDao.name,
        mobile: data.residentialDao.number,
        bungalowNo: data.bungalowNo
      }
    })
  }

  checkPaymentStatus(data: BungalowMasterDao) {
    this.dialog.open(TransationComponent, {
      'width': '880px',
      data: data,
    }).afterClosed().subscribe(value => {

    })
  }


  sendRecipt() {
    this.alert.confirmMessage('Send Recipt', 'Are You Sure ?').then(value => {
      this.alert.hideLoader();
      this.service.sendrecipt().subscribe((value1) => {
        console.log('recipt', value1)
        this.alert.showSuccesMessage("Bill Send Successfully!");
      }, (error) => {
        console.log('error', error);
        this.alert.showErrorMessage("Something Wen't Wrong")
      })
      this.alert.hideLoader();
    })
  }


  roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
  }
}
