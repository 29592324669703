

<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
      </div>
      <div style="" class="panel-body">
        <div >
          <form ngNativeValidate (submit)="createCampiagn()">

            <div class="form-group col-lg-12">
              <label>Campaign Name   <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="campaingnDao.campaingName" name="campaingName" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Country <sup class="required">*</sup></label>
              <select type="text" class="form-control" [(ngModel)]="campaingnDao.country" name="country" required>
                <ng-container *ngFor="let countryDao of countryWithPricesList">
                  <option [value]="countryDao.countryCode" [innerHTML]="countryDao.country"></option>
                </ng-container>
              </select>
            </div>


            <div class="form-group col-lg-12">
              <label>Template   <sup class="required">*</sup> <br> Reciepts Count : {{campaingnDao.totalReciepts}}</label>
              <select (change)="changeTemplateForDownload()"    type="text" class="form-control" [(ngModel)]="campaingnDao.template" name="template" required>

                <option value="">---Select Template---</option>
                <ng-container *ngFor="let template of templateList">

                  <option [value]="template.id" [innerHTML]="template.name"></option>
                </ng-container>
              </select>
            </div>





            <div *ngIf="campaingnDao.template!=''&&messageArray.length==0" class="form-group col-lg-12">
              <button type="button" (click)="downloadSampleFile()" class="btn btn-add">Sample File Download <i class="fa fa-download"></i></button>

            </div>

            <div *ngIf="campaingnDao.template!=''&&messageArray.length==0" class="form-group col-lg-12">
              <button type="button" (click)="uploadFileComponent()" class="btn btn-add">Upload File <i class="fa fa-upload"></i></button>


            </div>








            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Start Campaign</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>

          </form>

        </div>


      </div>
    </div>
  </div>
</div>








