import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
const appprefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class MailReportService {

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line:typedef
  getData(startdate: number, enddate: number) {

    return this.http.get(appprefix + 'gsmgateway/v1/api/report/get?startDate=' + startdate + '&endDate=' + enddate);
  }


  // // tslint:disable-next-line:typedef
  // getData(startdate: number, enddate: number) {
  //
  //   return this.http.get('http://localhost:8089/api/report/get?startDate=' + startdate + '&endDate=' + enddate);
  // }
}
