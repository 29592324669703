

<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
        <h1 class="modal-title" id="exampleModa"><b>Group Details</b></h1>
      </div>
      </div>
      <div class="panel-body" style="margin-top: 15px">
        <form #updateForm="ngForm" ngNativeValidate (ngSubmit)="addGroup()">

          <div class="form-group col-lg-12">
            <label>Group Name  <sup class="required">*</sup></label>
            <input type="text" class="form-control"  #groupName [(ngModel)]="groupadd.groupName" name="groupName" placeholder="Enter Group Name" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Group Desc<sup class="required">*</sup></label>
            <input type="text" class="form-control"  #groupDesc  [(ngModel)]="groupadd.groupDesc" name="groupDesc"  placeholder="Enter Group Description" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Created By<sup class="required">*</sup></label>
            <input type="text" class="form-control"  #createdBy  [(ngModel)]="groupadd.createdBy"  name="createdBy" required placeholder="Enter CreatedBy Name">
          </div>

          <div class="form-group col-lg-12">
            <label>Status <sup class="required">*</sup></label>
             <select class="form-control"  [(ngModel)]="groupadd.status" name="status" required>
               <option value="undefined" >  --Select Group---  </option>
              <option [value]="true">Active</option>
              <option [value]="false">In Active</option>
            </select>
          </div>

          <div class="form-group  pull-right">
            <button  type="submit" class="btn btn-add"   >Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>

