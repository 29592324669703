import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import swal from '../../assets/plugins/sweetalert/sweetalert';
import {Howl, Howler} from 'howler';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  public showSuccesMessage(message: string) {
    swal({
      title: 'Success',
      text: message,
      icon: 'success',
      button: 'Ok'
    })
  }
  public showSuccesMessageWithoutReload(message: string) {
    swal({
      title: 'Success',
      text: message,
      icon: 'success',
      button: 'Ok'
    }).then(function (){
    });
  }

  public showSuccesMessagewithhtml(message: any) {
    swal({
      title: 'Success11',
      html: '<table id="myTable">\n' +
        '    <thead>\n' +
        '      <tr>\n' +
        '        <th>Address</th>\n' +
        '        <th>Bill </th>\n' +
        '        <th>Amount</th>\n' +
        '      </tr>\n' +
        '    </thead>\n' +
        '    <tbody>\n' +
        '      <tr>\n' +
        '        <td>'+message.address+'</td>\n' +
        '        <td>message.date</td>\n' +
        '        <td>message.gift</td>\n' +
        '      </tr>\n' +
        '      <!-- Add more rows as needed -->\n' +
        '    </tbody>\n' +
        '  </table>',
      icon: 'success',
      button: 'Ok'
    });
  }

  public showErrorMessage(message: string) {
    swal({
      title: 'Error',
      text: message,
      icon: 'error',
      button: 'Ok'
    });
  }



  // tslint:disable-next-line:typedef
  public confirmMessage(message: string, subject: string) {
    return new Promise((resolve, reject) => {
      swal({
      title: subject,
      text: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve("success")
        }else{
          reject("error")
        }
      })
    })
  }
  showLoader()
  {
    $('#preloader').css("display","block")
    $('#status').css("display","block")

  }





  playIncomingMessageSound()
  {

      var sound = new Howl({
        src: ['assets/Sound/newmessage.mp3']
      });
      sound.play();

  }
  hideLoader()
  {
    $('#status').fadeOut("slow");
    $('#preloader').fadeOut("slow");
  }
  hideLoaderinSecond(time:number)
  {
    setTimeout(function() {
      $('#status').fadeOut("slow");
      $('#preloader').fadeOut("slow");
    },time)
  }

  constructor() { }

}
