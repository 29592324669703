
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
        <button  (click)="addRole()"   id="addNewButton" class="btn btn-add">Add Role<i class="fa fa-plus-circle"></i></button>

        <!--        <input  style="float: right"  type="text" name="search" [(ngModel)]="searchText"   placeholder="Type to search here">-->

      </div>

      <div style="" class="panel-body">

        <div  class="table-responsive">


          <table  id="table1" datatable class="table table-bordered table-striped table-hover" >
            <!--          <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">-->
            <thead>
            <tr>
              <th>Email</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody id="tbody">
            <ng-container >

            </ng-container>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>



