import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {HDFCampDao} from "../c2c-report/HDFCampDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../environments/environment.prod";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {HDFCDao} from "../HDFCDao";

@Component({
  selector: 'app-hdfc-report',
  templateUrl: './hdfc-report.component.html',
  styleUrls: ['./hdfc-report.component.css']
})
export class HdfcReportComponent implements OnInit {

  tableData: PaginationDao<HDFCDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public empname:string="0";
  public empcode:string="0";
  public empmsisdn:string="0";
  public supname:string="0";
  public supcode:string="0";
  public supmsisdn:string="0";
  public startdateStr:string="0";
  public enddateStr:string="0";


  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<Array<HDFCDao>>(environment.hdfcBaseUrl + 'hdfc/getreport?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {
      this.tableData.data = value;
      this.tableData.total = value.length
      var data = value;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  filterData() {

  }


  clearFilter() {
    this.empname ="0"
    this.empcode ="0"
    this.empmsisdn ="0"
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {

  }


  download() {
    this.http.get<Array<HDFCDao>>(environment.hdfcBaseUrl + 'hdfc/getreport?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit).subscribe(value => {
      this.tableData.data = value;
      this.tableData.total = value.length;
      var data = value;
      var trArr = [];
      var tdArr =["Emp Call ID","Emp Call Time","Emp Name","Emp Code","Emp Mobile","Sup Call Time","Sup Name","Sup Code","Sup Mobile","Emp - Id Status","Sup - Id Status","Emp - Laptop Status","Sup - Laptop Status","Emp - Attorney Status","Sup - Attorney Status","Status"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        // @ts-ignore
        tdArr.push(data[i].emp_FK_ID);
        tdArr.push(data[i].emp_Call_Connecttime);
        tdArr.push(data[i].emp_Name);
        tdArr.push(data[i].emp_Code);
        tdArr.push(data[i].emp_Number);
        tdArr.push(data[i].call_Connecttime);
        tdArr.push(data[i].supervisor_Name);
        tdArr.push(data[i].supervisor_code);
        tdArr.push(data[i].msisdn);
        // @ts-ignore
        tdArr.push(data[i].emp_id_status);
        // @ts-ignore
        tdArr.push((data[i].id_status  == 1)?"Matched":"Mismatched")
        // @ts-ignore
        tdArr.push(data[i].emp_laptop_status);
        // @ts-ignore
        tdArr.push((data[i].laptop_status  == 1)?"Matched":"Mismatched")
        // @ts-ignore
        tdArr.push(data[i].emp_attorney_status);
        // @ts-ignore
        tdArr.push((data[i].attorney_status  == 1)?"Matched":"Mismatched")
        // @ts-ignore
        tdArr.push((data[i].flag == 1)?"Complete":"Pending");
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  formatDTMFResponse(empInput:string,supInput:string,type:string,asset:string):string{

    if(empInput == null){
      empInput="NA"
    }
    if(type == "emp"){
      let color = "orange"
      if(supInput=="1") {
        color = 'green'
      }
      console.log("<span style='color: "+color+"'>"+empInput+"</span>")
      return "<span style='color: orange'>"+empInput+"</span>"

    }else{
      let color = "Black"
      let status = "Pending"
      if(asset=="ID"){
        if(supInput=="1") {
          color = 'green'
          status = "Handed"
        }else if(supInput=="2"){
          color = "orange"
          status = "Not Handed"
        }
      }else if(asset == "LAPTOP"){
        if(supInput=="1") {
          color = 'green'
          status = "Handed"
        }else if(supInput=="2"){
          color = "orange"
          status = "Not Handed"
        }else if(supInput=="3"){
          color = "orange"
          status = "Not Issued"
        }
      }else if(asset == "ATTORNEY"){
        if(supInput=="1") {
          color = 'orange'
          status = "Not Issued"
        }else if(supInput=="2"){
          color = "green"
          status = "Handed"
        }else if(supInput=="3"){
          color = "orange"
          status = "Not Handed"
        }
      }


      return "<span style='color: "+color+"'>"+status+"</span>"
    }

  }

  getColor(input:string):string{
    if(input =="1" ){
      return 'color:green'
    }else{
      return 'color:orange'
    }
  }
}
