import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Agent} from "../../AgentMaster/agent-master/AgentDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {DynamicChatbotService} from "../../../MicroServices/Chatbot/Service/dynamic-chatbot.service";
import {AgentServiceService} from "../agent-service.service";

@Component({
  selector: 'app-agent-master-form',
  templateUrl: './agent-master-form.component.html',
  styleUrls: ['./agent-master-form.component.css']
})
export class AgentMasterFormComponent implements OnInit {
  @Output() public eventEmitter = new EventEmitter<string>();

  chatbotNameValidation: any;
  agentMasterDao: Agent;
  submitBtnTxt: String = 'Create';

  constructor(public user: UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,
              private agentService: AgentServiceService,
              @Inject(MAT_DIALOG_DATA) public data) {
    // @ts-ignore
    this.agentMasterDao = data.agentMasterDao;
    // @ts-ignore
    this.submitBtnTxt = data.submitBtnTxt;
  }

  ngOnInit(): void {
  }

  //

  submitMasterForm(agentMasterDao: Agent) {
    console.log("agentMasterDao:", agentMasterDao,this.submitBtnTxt)

    if (agentMasterDao.agentName == '') {
      this.alert.showErrorMessage('');
      return;

    }

    if (this.submitBtnTxt == "Create") {
      console.log("create:")

      this.agentService.insert(agentMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
      });
    } else {
      this.agentService.update(agentMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }

    //

  }


  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(AgentMasterFormComponent, {
      width: '830px',
      height: '600px'


    });
  }
}
