<!--/*-->
<!--Author: sujit yadav-->
<!--date: 24/jul/2022-->
<!--*/-->
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <h3>New Message</h3>
        </div>
      </div>
      <div style="" class="panel-body">
        <form  ngNativeValidate [formGroup] = "uploadForm"  >

          <div class="form-group col-lg-12">
            <label>Group Name  <sup class="required">*</sup></label>

            <select class="form-control" name="groupId" [(ngModel)]="groupId"  required [ngModelOptions]="{standalone: true}">
              <option value="undefined">---Select Group---</option>

              <option [ngValue]="dep.id"   *ngFor="let dep of tableList">
                {{dep.groupName}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-12">
            <label>Subject<sup class="required">*</sup></label>
            <textarea type="text" class="form-control"   style="height: 50px"   [(ngModel)]="subject" name="subject" required placeholder="Enter Subject" [ngModelOptions]="{standalone: true}" ></textarea>


          </div>
          <div class="form-group col-lg-12">
            <label>Message <sup class="required">*</sup></label>
            <textarea type="text" style="height: 150px" class="form-control"  [(ngModel)]="message" name="message" required placeholder="Enter Message" [ngModelOptions]="{standalone: true}"></textarea>

<!--            <quill-editior><div style="height: 100px" class="editor"></div></quill-editior>-->
          </div>


          <div class="form-group col-lg-12">
            <label>Attachment  <sup class="required">*</sup></label>
             <button  type="button" (click)="uploadFileComponent()" class="btn btn-add"><i style="" class="fa fa-plus-circle" required></i></button>

            <div *ngIf="this.fileName!=null || this.fileName!= undefined  ">
              <table class="table table-striped row-border hover">
                <thead>
                <tr>
                  <th>File Name</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <!--                            <ng-container >-->
                <ng-container >
                  <tr>
                    <td [innerHTML]="this.fileName" ></td>
                    <td>
                      <i  (click)="deleteParams(this.fileId)" class="fa fa-trash btn btn-danger"></i>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>

          </div>
          <div class="form-group col-lg-12">
            <button type="submit" title="Send" id="btnsend"  (click)="submitData()"   class="btn btn-primary">Send</button>
             <button title=" More send options" (click)="createSchedule()" id="btnschedule"   class="btn btn-primary" ><i style="color: white" class="fa fa-angle-down"> </i></button>
            <button type="button" (click)="cancelbtn()" class="btn btn-add">Cancel</button>
           </div>
        </form>
      </div>

    </div>
  </div>
</div>

