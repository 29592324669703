// noinspection AngularMissingOrInvalidDeclarationInModule

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GroupMasterService} from '../group-master/group-master.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {GroupMasterDao} from '../group-master/group-master-dao';
import {error} from 'jquery';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-all-mails',
  templateUrl: './all-mails.component.html',
  styleUrls: ['./all-mails.component.css']
})
export class AllMailsComponent implements OnInit {
  groupId: any;
  email: any;
  status: 'Enable';
  gId: any;
  searchText: any;
  tableList: any;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  togg = true;
 toggle: Array<any>;


  emailStatus: string;
  constructor(private router: Router, private service: GroupMasterService, private alert: AlertMessageService, private http: HttpClient) {
    // this.groupId = this.router.getCurrentNavigation().extras.state.groupId;
    //
    // console.log('gId' + this.groupId);
  }

  ngOnInit(): void {
    this.createOrUpdate();

    this.btn();
  }

  // tslint:disable-next-line:typedef
  createOrUpdate() {
    // @ts-ignore
    this.http.get(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/groupmaster/getAll').subscribe(value => {
      this.tableList = value;
      console.log(this.tableList);
      this.dtTrigger.next();
    });


  }


  // tslint:disable-next-line:typedef
  btn()
  {
    this.emailStatus = this.tableList.status;
    console.log(this.emailStatus);
    alert(this.emailStatus);

  }

  // tslint:disable-next-line:typedef
  enableDisableRule(id: number, status: string) {


    console.log('id is this values ' + id , status);

    // tslint:disable-next-line:triple-equals
    if (status == '1')
    {
      status = status.replace('1', '0');

      console.log('if' + status);
    }
    else
    {
      status = status.replace('0', '1');
      console.log('else is this' + status);
    }


    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    this.http.post(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/groupmaster/status?status=' + status + '&id=' + id).subscribe(value => {
      window.location.reload();

    });


  }

  // tslint:disable-next-line:typedef
   delete(id: number)
   {
     this.http.delete(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/groupmaster/delete?id=' + id).subscribe(value => {
       window.location.reload();

     });

   }
}
