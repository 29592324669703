<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div *ngIf="!addNewDiv" class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addResidential(false)" class="btn btn-add">Add New Data <i class="fa fa-plus"></i></button>
          <button style="margin-left: 5px" class="btn btn-add " title="Bulk Upload (Maximum 1000)"
                  (click)="bulkUpload()">Bulk Upload <i (click)="downloadSampleFile()"
                                                        title="Click Here To View Sample File"
                                                        class="fa fa-question-circle"></i>
          </button>
          <button style="margin-left: 5px" (click)="downloadReport()" class="btn btn-add">Download <i
            class="fa fa-download"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Bungalow No</th>
              <th>EwsQtrsNo</th>
              <th>Name Of Allottee</th>
              <th>Mobile No</th>
              <th> Meter No</th>
              <!--              <th> Previous Reading</th>-->
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of tableList;let ind =index">

              <tr *ngIf="tableList.length==0">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr>
                <td [innerHTML]="data.bungalowNo"></td>
                <td [innerHTML]="data.ewsQtrsNo"></td>
                <td [innerHTML]="data.residentialDao.name"></td>
                <td [innerHTML]="data.residentialDao.number"></td>
                <td [innerHTML]="data.meterNo"></td>
                <!--                 <td [innerHTML]="data.previousReading"></td>-->

                <td>
                  <i title="Click Here To Edit" class="btn btn-add fa fa-pencil" (click)="editDao(data,ind,false)"></i>

                </td>


              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>

    <!--    ===============================================================user detatil==================================-->
    <div *ngIf="addNewDiv" class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button style="margin-left: 5px" class="btn btn-add " title="Bulk Upload (Maximum 1000)"
                  (click)="bulkUpload()">Bulk Upload <i (click)="downloadSampleFile()"
                                                        title="Click Here To View Sample File"
                                                        class="fa fa-money-bills"></i>
          </button>
          <button style="margin-left: 5px" (click)="downloadReport()" class="btn btn-add">Download <i
            class="fa fa-download"></i></button>
          <button style="margin-left: 5px" class="btn btn-add " title="Send Recipt" (click)="sendRecipt()">Send Recipt
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Bungalow No</th>
              <th>EwsQtrsNo</th>
              <th>Name Of Allottee</th>
              <th>Mobile No</th>
              <th> Meter No</th>
              <th> Previous Reading</th>
              <th>Current Reading</th>
              <th>Chargeable Unit</th>
              <th>OutstandingAmount</th>
              <th>Due Date</th>
              <th>Total Amount</th>
              <!--              <th>Payment Staus</th>-->
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data of tableList;let ind =index">

              <tr *ngIf="tableList.length==0">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr *ngIf="data.totalAmout!=0">

                <td [innerHTML]="data.bungalowNo"></td>
                <td [innerHTML]="data.ewsQtrsNo"></td>
                <td [innerHTML]="data.residentialDao.name"></td>
                <td [innerHTML]="data.residentialDao.number"></td>
                <td [innerHTML]="data.meterNo"></td>
                <td [innerHTML]="roundUp(data.previousReading-data.chargeableUnit,2)"></td>

                <td [innerHTML]="data.currentReading"></td>
                <td [innerHTML]="data.chargeableUnit"></td>
                <td [innerHTML]="data.outstandingAmount"></td>
                <td [innerHTML]="data.payDate"></td>
                <td [innerHTML]="data.totalAmout"></td>
                <!--                <td [innerHTML]="data.paymentStaus"></td>-->

                <td>

                  <!--                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
                  <!--                    <mat-icon>more_vert</mat-icon>-->
                  <i title="Click Here To View More Option " [matMenuTriggerFor]="menu"
                     class="btn btn-add fa fa-ellipsis-v"></i>
                  <!--                  </button>-->
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editDao(data,ind,true)">
                      <i title="Click Here To Edit"
                         class=" fa fa-pencil"></i>&nbsp;&nbsp;
                      <span>Edit Detail</span>
                    </button>
                    <button mat-menu-item (click)="sendWhatsapp(data)">
                      <i style="color: #1c8379" class="fa-brands fa-whatsapp" title="Send Whatsappp" aria-hidden="true">
                      </i>&nbsp;&nbsp;
                      <span>Send On Whatsapp</span>
                    </button>
                    <button mat-menu-item (click)="sendSMS(data)">
                      <!--                      <span>Disable alerts</span>-->
                      <i style="margin-left: 0px" class="fa-regular fa-message" title="Send  SMS"></i>&nbsp;&nbsp;
                      <span>Send On SMS</span>
                    </button>

                    <!--                    <button mat-menu-item (click)="generateRecipt(data)">-->
                    <!--                      <i style="margin-left: 0px; color: darkred"   class="fa fa-money-bills" title="Generate Bill"    ></i>&nbsp;&nbsp;-->
                    <!--                      <span>Generate Bill</span>-->
                    <!--                    </button>-->

                    <button mat-menu-item (click)="viewRecipt(data)">
                      <i style="margin-left: 0px;color: mediumpurple" class="  fa fa-eye" title="View Bill"></i>&nbsp;&nbsp;
                      <span>View  Recipt</span>
                    </button>


                    <button mat-menu-item (click)="generateNoc(data)">
                      <!--                      <span>Disable alerts</span>-->
                      <i style="margin-left: 0px; color: #104e75" class="fas fa-certificate" title="Send  NOC"></i>&nbsp;&nbsp;
                      <!--                      <i class="fas fa-certificate"></i>-->
                      <span>Generate NOC</span>
                    </button>

                    <button mat-menu-item (click)="payPayment(data)">
                      <i style="margin-left: 0px; color: #104e75" class="fa fa-credit-card" title="Payment"></i>&nbsp;&nbsp;
                      <span>Click Here To Payment</span>
                    </button>

                    <button mat-menu-item (click)="checkPaymentStatus(data)">
                      <i style="margin-left: 0px; color: #104e75" class="fa fa-credit-card" title="Payment"></i>&nbsp;&nbsp;
                      <span>Check Payment Status</span>
                    </button>
                  </mat-menu>
                </td>

              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</div>


