import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WaContactGroupDao} from './wa-contact-group-dao';
import {WacontactgroupmasterDao} from './GroupMaster/wacontactgroupmaster-dao';
import {environment} from '../../../environments/environment.prod';
import {GroupInnerDao} from "../WhatsappGroup/GroupInnerDao";

const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class WaContactGroupServiceService {

  constructor(private http: HttpClient) { }

  getallGroupDetails(id: number): Observable<Array<GroupInnerDao>>
  {
    console.log("groupid:",id)
    return this.http.get<Array<GroupInnerDao>>(appPrefix + 'group/getallgroups?id=' + id);

  }


  get(): Observable<Array<WaContactGroupDao>>
  {
    return this.http.get<Array<WaContactGroupDao>>(appPrefix + 'api/contactgroup/getall');

  }

  getClient(): Observable<Array<WacontactgroupmasterDao>>
  {
    return this.http.get<Array<WacontactgroupmasterDao>>(appPrefix +  'api/contactgroupmaster/getall');

  }

  groupAdd(data: any): Observable<any>
  {
    return this.http.post(appPrefix +  'api/contactgroup/insert', data);
  }

  updateGroup(data: any): Observable<any>
  {
    return this.http.patch(appPrefix +  'api/contactgroup/update', data);
  }

  deleteGroup(id: number): Observable<any>
  {
    return this.http.delete(appPrefix +  'api/contactgroup/delete?id=' + id);
  }

  // tslint:disable-next-line:typedef
  bulkUpload(groupId: any, leadJson: Array<any>) {

    return this.http.post(appPrefix +  'api/contactgroupmaster/bulkupload?groupId=' + groupId, leadJson);
  }

  // tslint:disable-next-line:typedef
  insertClient(groupMaster: any) :Observable<any>{
    return this.http.post(appPrefix + 'api/contactgroupmaster/insertsingle', groupMaster);
  }

  deletePhonenumber(id: number): Observable<any>
  {
    return this.http.delete(appPrefix +  'api/contactgroupmaster/delete?id=' + id);
  }

  getbyGroupId(id: any): Observable<Array<WacontactgroupmasterDao>>
  {
    return this.http.get<Array<WacontactgroupmasterDao>>(appPrefix +  'api/contactgroupmaster/getclientbygroupId?groupId=' + id);
  }


  getContryCode():Observable<any> {
    return this.http.get<Array<any>>(appPrefix+'api/country/get');
  }
}
