import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {CountryMasterDao} from './CountryMasterDao';

const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Component({
  selector: 'app-countrymaster',
  templateUrl: './countrymaster.component.html',
  styleUrls: ['./countrymaster.component.css']
})
export class CountrymasterComponent implements OnInit {
  tableList: Array<CountryMasterDao> = [];
  addnewDiv: boolean = false;
  countryDao: CountryMasterDao = {code: '', countryCode: '', name: ''};

  constructor(private http: HttpClient) {

    this.loadData();
  }

  async loadData() {
    this.tableList = await this.http.get<Array<CountryMasterDao>>(appPrefix + 'country/').toPromise();
  }

  ngOnInit(): void {
  }

   addNew() {

    this.addnewDiv=true;


  }

  edit(data: CountryMasterDao) {
    this.countryDao=data;
    this.addnewDiv=true;
  }

 async isOrUpdate() {

    this.countryDao.code=this.countryDao.countryCode;
    this.countryDao = await this.http.post<CountryMasterDao>(appPrefix + 'country/', this.countryDao).toPromise();
    this.clearData();
  }

  clearData() {
    this.countryDao = {code: '', countryCode: '', name: ''};
  }

  cancelBtn() {
    this.loadData();
    this.addnewDiv=false;
    this.clearData();

  }
}
