import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceMasterDao} from '../../servicemaster/ServiceMasterDao';
import {environment} from '../../../environments/environment.prod';
import {MenuMasterDao} from '../MenuMasterDao';

@Injectable({
  providedIn: 'root'
})
export class MenuMasterService {

  constructor(private http:HttpClient) { }
  getRoles():Observable<Array<MenuMasterDao>>
  {
    return this.http.get<Array<MenuMasterDao>>(environment.appUrl+environment.authMicroservice+"menu/get");
  }

  saveRole(role:MenuMasterDao):Observable<MenuMasterDao>
  {
    return this.http.post<MenuMasterDao>(environment.appUrl+environment.authMicroservice+"menu/save",role);
  }

}
