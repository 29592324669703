import {Component, Inject, OnInit} from '@angular/core';
import {RechargeDao, RechargeFormDao} from './RechargeDao';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AccountServiceFormDao} from '../account-services/AccountServiceDao';
import {AccountMasterDao} from '../AccountMasterDao';
import {RechargeService} from './RechargeService/recharge.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {UserService} from '../../Services/User/user.service';
import {any} from "codelyzer/util/function";
import {AccountMasterService} from "../AccountMasterService/account-master.service";
import {AccountBalanceDao} from "../account-balance-dao";

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.css']
})
export class RechargeComponent implements OnInit {
  tableList: Array<RechargeDao> = [];
  addnewform: boolean = false;
  rechargeDao: RechargeDao = {
    accountId: 0,
    amount: 0,
    createAt: '',
    createBy: '',
    descri: '',
    rechareType: '',
    transactionId: '',
    dateFilter: 0,
    totalCredit: 0,
    totalDebit: 0,
    service: ''
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: RechargeFormDao, private http: HttpClient, private rechargeService: RechargeService, private alert: AlertMessageService, private user: UserService, private account: AccountMasterService) {


  }

  // loadData() {
  //   this.rechargeService.getByAccount(this.data.account.id).subscribe(value => {
  //     this.tableList = value;
  //   })
  // }

  ngOnInit(): void {
    // this.loadData();
  }

  async createRecharge() {

    let balanceDao: AccountBalanceDao = await this.account.getbalance(this.data.account.id).toPromise();
    let preBalance: number;

    if (this.rechargeDao.service ==='WHATSAPPOFFICIAL') {
      preBalance = balanceDao.balance
    } else if (this.rechargeDao.service === 'WHATSAPPUNOFFICIAL') {
      preBalance = balanceDao.balanceUnofficial;
    }else if (this.rechargeDao.service === 'RESELLER') {
      preBalance = balanceDao.resellerBalance;
    } else {
      preBalance = 0;
    }

    console.log("Balance before credit", this.rechargeDao);
    this.alert.confirmMessage('Recharge', 'Are You Sure?').then(async value => {

      if (this.rechargeDao.amount <= 0) {
        this.alert.showErrorMessage("Amount Should be greater then 0");
        return;
      }
      if (this.rechargeDao.amount >= 900000) {
        this.alert.showErrorMessage("Maximum Recharge Limit At a Time 9 Lac.");
        return;
      }

      this.rechargeDao.accountId = this.data.account.id;
      if (this.rechargeDao.rechareType == 'REVERSERECHARE') {
        if (preBalance! <= this.rechargeDao.amount) {
          this.alert.showErrorMessage("Your Current Balance Is " + preBalance);
          return;
        }
      }

      console.log("Data mode is ", this.data.mode)
      if (this.data.mode == 1) {
        this.rechargeDao.accountId = this.data.account.id;
        let firstRecharge = await this.rechargeService.insert(this.rechargeDao).toPromise();
        if (firstRecharge.rechareType ==="RECHARE") {
          this.rechargeDao.rechareType = "REVERSERECHARE";
        } else {
          this.rechargeDao.rechareType = "RECHARE";
        }
        this.rechargeDao.accountId = this.user.accountMasterDao.id;

       let secondRechare = await this.rechargeService.insert(this.rechargeDao).toPromise();

        this.alert.showSuccesMessage("Recharge Successfull");

      } else {
        this.rechargeService.insert(this.rechargeDao).subscribe(value => {
          if (value.rechareType ==='RECHARE') {
            this.alert.showSuccesMessage("Recharge Successfull");
          } else {
            this.alert.showSuccesMessage("Recharge Return Successfull");
          }
          this.cancelBtn();
        })
      }
    });
  }


  clearData() {
    this.rechargeDao = {
      accountId: 0,
      amount: 0,
      createAt: '',
      createBy: '',
      descri: '',
      rechareType: 'RECHARE',
      transactionId: '',
      dateFilter: 0,
      totalCredit: 0,
      totalDebit: 0,
      service: ''
    }

  }

  cancelBtn() {
    this.addnewform = false;
    this.clearData()

  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }
}
