<div class="row" style="border:  10px solid #1c5b7a">
  <div class="col-sm-12">
    <div class="mailbox">
      <div class="mailbox-header">
        <div class="row">
          <div class="col-xs-4">
            <div *ngIf="isAgentSupport" style="width: 100%;float: left" class="inbox-avatar">
              <div style="display: flex;">
                <!-- <label>Assign Agent</label> -->
                <select class="form-control" name="accountId" style="width: 60% !important;" [ngStyle]="{'color': ddlAgentTxtColor}" [(ngModel)]="assignAgentParam.agentAccountId">
                  <option value="undefined">---Select Agent---</option>
                  <option style="color: black;" *ngFor="let agent of ddlAgents " value="{{agent.clientId}}">{{agent.agentName}}</option>
                </select>
                <!-- {{assignAgentParam.agentAccountId}} -->
                <button style="float: right; height: max-content;" type="submit" (click)="assignAgent()" class="btn btn-add">Assign</button>

              </div>
            </div>
          </div>
          <div class="col-xs-8">
            <div *ngIf="conversationDao!=null" style="width: 25%;float: left" class="inbox-avatar">
              <i class="fa fa-user-circle fa-lg"></i>
              <div class="inbox-avatar-text hidden-xs hidden-sm">
                <div class="avatar-name" style="font-size: 15px"
                  [innerHTML]="conversationDao.contactName||conversationDao.id"></div>

              </div>
            </div>

            

            <div style="width: 50%;float: right" class="inbox-toolbar btn-toolbar pull-right">

              <div class="btn-group pull-right">

                <i title="Click Here To Refresh" style="margin-left: 10%"
                  *ngIf="conversationDao!=null&&!conversationDao.saved"
                  (click)="loadChats(offset,limit,conversationDao.id,true)" class="btn btn-add fa fa-refresh"></i>
                <i title="Click Here To Save To Contact" *ngIf="conversationDao!=null&&!conversationDao.saved"
                  (click)="saveContact()" class="btn btn-add fa fa-user-plus"></i>
                <i title="Click Here To Reply " *ngIf="conversationDao!=null&&!conversationDao.saved"
                  (click)="replyMessage()" class="btn btn-add fa fa-ellipsis-v"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mailbox-body">
        <button class="btn btn-add fa fa-arrow-left" *ngIf="!isLargeScreen && !isDiv1Visible" (click)="toggleDiv2()">Back</button>

        <div class="row m-0">
          <div class="col-sm-3 p-0 inbox-nav " *ngIf="isDiv1Visible">

            <input [(ngModel)]="searchStr" (keyup)="loadData(0,this.limit,searchStr)" name="searchStr" type="text"
              class="form-control" placeholder="Search....">
            <!--            =====================================Start================================-->
            <!--            <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport mailbox-sideber">-->

            <div style="max-height: 500px;overflow: scroll" class="mailbox-sideber example-viewport">
              <div class="profile-usermenu">
                <ul *ngIf="conversationList!=null" class="nav">


                  <ng-container *ngFor="let conve of conversationList.data  ;let ind =index">
                    <li *ngIf="conversationDao!=null" [ngClass]="{'active': conve.id == conversationDao.id}">
                      <a (click)="takeMeToNumber(conve)"> <i class="fa fa-user-circle"></i>
                        <b *ngIf="conve.saved">{{conve.contactName}}({{conve.id}})</b>
                        <b *ngIf="!conve.saved" [innerHTML]="conve.id"></b>
                        <span *ngIf="conve.unread" style="border-radius: 50%" class="label label-danger"
                          [innerHTML]="conve.unreadMessage"></span></a>
                    </li>
                    <li *ngIf="conversationDao==null">
                      <a (click)="takeMeToNumber(conve)"><i class="fa fa-user-circle"></i>
                        <b *ngIf="conve.saved">{{conve.contactName}}({{conve.id}})</b>
                        <b *ngIf="!conve.saved" [innerHTML]="conve.id"></b>
                        <span *ngIf="conve.unread" style="border-radius: 50%" class="label label-danger"
                          [innerHTML]="conve.unreadMessage"></span></a>

                    </li>

                  </ng-container>
                  <mat-paginator (page)="tableChange($event,conversationList)" [length]=conversationList.total
                    [pageSize]="10" [pageSizeOptions]="[10,25,50,100,1000]">
                  </mat-paginator>




                </ul>
                <hr>
              </div>
            </div>

            <!--            </cdk-virtual-scroll-viewport>-->
            <!--            ============================End======================================-->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-9 p-0 inbox-mail conversation" *ngIf="isDiv2Visible">
            <div id="chatconversationdiv"
              style="max-height: 400px;min-height: 400px;overflow: scroll;overflow-x: hidden" class="mailbox-content">
              <ng-container *ngIf="conversationDao==null">
                <h3 style="text-align: center;margin-top: 200px"><b>No Contact Selected...</b></h3>
              </ng-container>
              <div class="row">
                <button (click)="loadChats(offset+1,10,conversationDao.id,false,false)" *ngIf="loadMoreEnable"
                  style=" margin-left: auto!important;" class="btn btn-add btn-rounded center-block">Load More</button>

                <!--              <button (click)="loadChats(offset+1,10,conversationDao.id,false,false)" *ngIf="loadMoreEnable" style="margin-left: auto!important;" class="btn btn-add btn-rounded center-block">Load More</button>-->
              </div>
              <ng-container *ngFor="let mess of messageList">
                <app-message-obj [messageDao]="mess"></app-message-obj>
              </ng-container>

            </div><br>
            <footer>

            </footer>

            <div *ngIf="conversationDao!=null"
              style="margin-left: 0px;padding: 10px;border-top: 1px solid lightgray;border-radius: 10px 10px"
              class="row">

              <textarea [(ngModel)]="textMessage" name="textMessage"
                style="width: 85%!important;height:80px;float: left;border-radius: 10px 10px" class="form-control"
                placeholder="Reply Message"></textarea>
              <button (click)="sendTextMessage()"
                style="float: left;height: 80px;font-size: 20px;width: 10%;margin-left: 5px!important;"
                class="btn btn-add"><i class="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>