import {Component, OnInit} from '@angular/core';
import { FilterDao} from './FilterDao';
import {ReportService} from './ReportService/report-service.service';
import {UserService} from '../Services/User/user.service';
import {ChartDataDao} from '../chart-and-tables/ChartDataDao';
import * as moment from 'moment';
import {Label} from 'ng2-charts';
import {GroupReportDao} from './GroupReportDao';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountMasterDao} from '../account-master/AccountMasterDao';
import {AccountMasterService} from '../account-master/AccountMasterService/account-master.service';
import {EnumServiceService} from '../Services/EnumController/enum-service.service';
import {MatDialog} from '@angular/material/dialog';
import {AccountFilterComponent} from '../account-master/account-filter/account-filter.component';
import {AccountFilterDao} from '../account-master/account-filter/AccountFilterDao';
import {FileService} from '../Services/FileUtilsService/FileService';

@Component({
  selector: 'app-crdrreport',
  templateUrl: './crdrreport.component.html',
  styleUrls: ['./crdrreport.component.css']
})
export class CrdrreportComponent implements OnInit {
  openingBalance: number;
  spentDuringDuration: number;
  closingBalance: number;
  showChart = false;
  result: Array<GroupReportDao> = [];
  lengthData = 0;
  accountList: Array<AccountMasterDao>;
  dateFilterType: Array<string>;
  services: Array<string>;
  groupBy: Array<string>;
  chartData: ChartDataDao = {barChartData: [], chartName: '', labels: []};
  filter: FilterDao = null;
  loadData( filter: FilterDao)
  {
    if (filter.accountId == 0)
    {
      filter.accountId = this.user.user.accountId;
    }
    this.reportService.getGroupByReport(this.filter).subscribe(data => {
      this.lengthData = data.length;
      const chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
      this.openingBalance = data[0].crAmount - data[0].drAmount;
      data[0].runningBalance = this.openingBalance;
      const labels: Array<Label> = [];
      const crAmount: Array<number> = [];
      const drAmount: Array<number> = [];
      const closingBalance: Array<number> = [];
      for (let i = 1; i < this.lengthData; i++)
      {
        data[i].runningBalance = ((data[i].crAmount - data[i].drAmount) + data[i - 1].runningBalance);
        if (data[i].name.length == 4)
        {
          data[i].name = moment(data[i].name, 'YYYYMM').format('MM/YY');
        }else{
          data[i].name = moment(data[i].name, 'YYYYMMDD').format('DD/MM/YY');
        }
        labels.push(data[i].name);
        crAmount.push(data[i].crAmount);
        drAmount.push(data[i].drAmount);
        closingBalance.push(data[i].runningBalance);
      }

      chartDataDao.labels = labels;
   /*   chartDataDao.barChartData.push({data:crAmount,label:"Cr Amount",barThickness:40})
      chartDataDao.barChartData.push({data:drAmount,label:"Dr Amount",barThickness:40})*/
      chartDataDao.barChartData.push({data: closingBalance, label: 'Closing Balance', backgroundColor: '#009688', hoverBackgroundColor: '#374767',  fill: false, borderColor: '#009688', barThickness: 40});
      chartDataDao.chartName = 'Account Credit/Debit';
      this.showChart = true;
      this.chartData = chartDataDao;
      this.result = data;
      this.closingBalance = Number((this.result[this.lengthData - 1].runningBalance).toFixed(2));

    });
  }
  // tslint:disable-next-line:max-line-length
  constructor(private reportService: ReportService, private user: UserService, private route: ActivatedRoute, private accountService: AccountMasterService, private enumService: EnumServiceService, private dialog: MatDialog, private router: Router) {

    this.accountService.getAccountsByUsername().subscribe(value => {
      this.accountList = value;
    });
    enumService.getArrrayEnum('groupby').subscribe(value => {
      this.groupBy = value;
    });
    enumService.getArrrayEnum('getdatefilter').subscribe(value => {
      this.dateFilterType = value;
    });
    const serviceArr = [];

    for (let i = 0; i < this.user.accountMasterDao.services.length; i++)
    {
      serviceArr.push(this.user.accountMasterDao.services[i].serviceName);
    }
    serviceArr.push('NONE');
    this.services = serviceArr;

  }
  tableEvent(event)
  {
  }
  ngOnInit(): void {

    this.route.queryParams.subscribe(queryParams => {
      const filterDao: FilterDao = {accountId: queryParams.accountId || 0, dateFilterType: queryParams.dateFilterType || 'CURRENTMONTH', enddate: queryParams.enddate || '0', groupBy: queryParams.groupBy || 'DAY', limit: 0, offset: 0, services: queryParams.services || 'NONE', startdate:  queryParams.startdate || '0'};
      this.filter = filterDao;
      this.loadData(filterDao);
    });
  }

  calculatePercentage(totalConvertLeads: number, totalLeads: number) {

  }






  takeMeToTable(fieldTable: GroupReportDao) {

  }


  filterData() {
    const accountFilterDao: AccountFilterDao = {
      datefilterType: this.dateFilterType,
      filter: this.filter,
      groupBy: this.groupBy,
      pageName: 'crdr',
      service: this.services
    };
    this.dialog.open(AccountFilterComponent, {data: accountFilterDao});
  }

  clearFilter()
  {
    this.router.navigate(['crdr']);
  }

  downloadFile() {
    const tempFilter = this.filter;
    this.getDataAndDownload(tempFilter);

  }

  private getDataAndDownload(tempFilter: FilterDao) {
      this.reportService.getGroupByReport(tempFilter).subscribe(value => {
        const trArr = [];
        let tdArr = [];
        tdArr.push('Name');
        tdArr.push('Cr Amount');
        tdArr.push('Dr Amount');
        tdArr.push('Running Balance');
        trArr.push(tdArr);
        tdArr = [];
        tdArr.push('Opening Balance');
        tdArr.push(value[0].crAmount);
        tdArr.push(value[0].drAmount);
        value[0].runningBalance = ((value[0].crAmount - value[0].drAmount));
        tdArr.push(value[0].runningBalance.toFixed(2));
        trArr.push(tdArr);


        for (let i = 1; i < value.length; i++)
        {
          tdArr = [];
          value[i].runningBalance = ((value[i].crAmount - value[i].drAmount) + value[i - 1].runningBalance);
          if (value[i].name.length == 4)
          {
            value[i].name = moment(value[i].name, 'YYYYMM').format('MM/YY');
          }else{
            value[i].name = moment(value[i].name, 'YYYYMMDD').format('DD/MM/YY');
          }
          tdArr.push(value[i].name);
          tdArr.push(value[i].crAmount);
          tdArr.push(value[i].drAmount);

          tdArr.push(value[i].runningBalance.toFixed(2));
          trArr.push(tdArr);

        }

        tdArr = [];
        tdArr[0] = '';
        tdArr[1] = '';
        tdArr[2] = 'Closing Balance';
        tdArr[3] = value[value.length - 1].runningBalance.toFixed(2);
        trArr.push(tdArr);

        const file = new FileService<any>();
        file.generateFile(trArr, 'CrDrReport.xlsx');

      });
  }
}
