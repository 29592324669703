<div class="row">
  <div class="col-md-12">

    <form >

      <!--<h3>Agent Master Form</h3>-->

      <div  class="form-group col-lg-12">
        <label>Dispatch Date <sup class="required">*</sup></label>
        <input type="datetime-local" class="form-control" placeholder="Enter Name"
               name="dispatchDate" [(ngModel)]="this.sessionDocument.dispatchDate">
      </div>
      <div  class="form-group col-lg-12">
        <label>Dispatch Remark <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="dispatchRemark" [(ngModel)]="this.sessionDocument.dispatchRemark">
      </div>
      <div  class="form-group col-lg-12">
        <label>Delivery Date <sup class="required">*</sup></label>
        <input type="datetime-local" class="form-control" placeholder="Enter Delivery Date"
               name="deliveryDate" [(ngModel)]="this.sessionDocument.deliveryDate">
      </div>
      <div  class="form-group col-lg-12">
        <label>Delivery Remark <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="deliveryRemark" [(ngModel)]="this.sessionDocument.deliveryRemark">
      </div>

      <!--      <div  class="form-group col-lg-12">-->
      <!--        <label>Account Id <sup class="required">*</sup></label>-->
      <!--        <input type="text" class="form-control" placeholder="Enter Name"-->
      <!--               name="accountId" [(ngModel)]="agentMasterDao.accountId">-->
      <!--      </div>-->

      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitMasterForm(sessionDocument)">Update</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>
  </div>
</div>
