<div class="background-image">
<!--  <div class="login-wrapper" >-->
<!--    <div class="container-center" >-->
<!--      <div class="login-area" >-->
        <div class="panel panel-bd panel-custom"  >
          <div class="panel-heading"  >
            <div class="view-header" >
              <div class="header-icon">
                <i class="fa-solid fa-lock"></i>

              </div>
              <div class="header-title">
                <h3>Sign Up</h3>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <form style="height: 100%" ngNativeValidate (submit)="signUp()">
              <div class="form-group col-lg-6">
                <label   class="control-label" >Person Name <i style="color: red" class="fa fa-asterisk"></i></label>
                <input   type="text" [minlength]="3" placeholder="Enter Your Name" title="Please enter you name" required [(ngModel)]="embeddedSignUpDao.name" name="name" id="name" class="form-control">
              </div>
              <div class="form-group col-lg-6">
                <label   class="control-label" >Display Name <i style="color: red" class="fa fa-asterisk"></i></label>
                <input   type="text" [minlength]="3" placeholder="Enter Your Name" title="Please enter you name" required [(ngModel)]="embeddedSignUpDao.displayName" name="name" id="displayName" class="form-control">
              </div>
              <div class="form-group col-lg-6">
                <label   class="control-label" >Verify Email <i style="color: red" class="fa fa-asterisk"></i></label>
                <input   type="email" placeholder="example@gmail.com" title="Please enter you email" required [(ngModel)]="embeddedSignUpDao.email" name="email" id="email" class="form-control">
              </div>
              <div class="form-group col-lg-6">
                <label   class="control-label" >Whatsapp Number <i style="color: red" class="fa fa-asterisk"></i></label>
                <input type="text" [disabled]="otpSend==true" [minlength]="10" (input)="validateNumber()" [maxlength]="14" placeholder="Enter Mobile Number" name="contactNumber" id="whatsappNumber" class="form-control" title="Please enter Mobile Number" required [(ngModel)]="embeddedSignUpDao.whatsappNumber">
              </div>
              <div class="form-group col-lg-12">
                <label   class="control-label" >Contact Number <i style="color: red" class="fa fa-asterisk"></i></label>
                <input type="text" [disabled]="otpSend==true" [minlength]="10" (input)="validateNumber()" [maxlength]="14" placeholder="Enter Mobile Number" name="contactNumber" id="contactNumber" class="form-control" title="Please enter Mobile Number" required [(ngModel)]="embeddedSignUpDao.contactNumber">
              </div>
              <div *ngIf="otpSend==true" class="form-group col-lg-12">
                <label   class="control-label" >Verify OTP <i style="color: red" class="fa fa-asterisk"></i> (Resend OTP wait for 30 Seconds) {{this.counter}}</label>
                <input   type="text" placeholder="ex:- 454645" title="Please enter you otp" required [(ngModel)]="otp" name="otp" id="otp" class="form-control">
              </div>
              <div *ngIf="enableSendButton == true" class="form-group col-lg-12"><br>
                <button type="button"   (click)="sendOtp();" style="width: 100%; float:right; background-color: #b1d3db; border: 0; border-radius: 50px; color: black; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; height: 40px;padding: 0 24px;">SendOTP</button>
              </div><br>
              <div class="form-group col-lg-12"><br>
                <button  *ngIf="otpSend==true" type="submit" style="width: 100%; float:right; background-color: #b1d3db; border: 0; border-radius: 50px; color: black; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; height: 40px;padding: 0 24px;">Submit</button>
              </div>
            </form>
          </div>
        </div>
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
