<div class="row">
  <div>
    <mat-dialog-actions align="end">
      <button class="btn btn-danger" [mat-dialog-close]="null" title="click here close">X</button>
    </mat-dialog-actions>

    <h4> Data successfully inserted!</h4>
    <div class="table-responsive">
      <table datatable class="table">
        <table>
          <tr>
            <th>S'No</th>
            <td>{{user.id}}</td>
          </tr>

          <tr>
            <th>Date</th>
            <td>{{user.date}}</td>
          </tr>

          <tr>
            <th>Mandi Name</th>
            <td>{{user.mandiName}}</td>
          </tr>
          <tr>
            <th>Ws Name</th>
            <td>{{user.wsName==''?'NA':user.wsName}}</td>
          </tr>
          <tr>
            <th>Others Ws Name</th>
            <td>{{user.otherWsName==''?'NA':user.otherWsName}}</td>
          </tr>
        </table>

        <table>
          <tr>
            <th>Retailer Name</th>
            <td>{{user.retailerName}}</td>
          </tr>
          <tr>
            <th>Retailer Number</th>
            <td>{{user.retailerMobile}}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{{user.address==''?'NA':user.address}}</td>
          </tr>
          <tr>
            <th>Note Book</th>
            <td>{{user.parchiBook}}</td>
          </tr>
          <tr>
            <th>Bill Amount</th>
            <td>{{user.billAmount}}</td>
          </tr>
          <tr>
            <th>Gift Hand Over</th>
            <td>{{user.giftHandOver==true?'Yes':'No'}}</td>
          </tr>
          <tr>
            <th>Gift</th>
            <td>{{user.gift==''?'NA':user.gift}}</td>
          </tr>

          <tr>
            <th>Volume Of Gift</th>
            <td>{{user.volumeOfGift==''?'NA':user.volumeOfGift}}</td>
          </tr>

          <tr>
            <th>Coupons</th>
            <td>{{user.coupon.replace("undefined","0").replace("undefined","0")}}</td>
          </tr>

        </table>
      </table>
    </div>
  </div>
</div>
<!--  </div>-->
<!--</div>-->







