<!--Author: Sujit Yada
Date: 31/July/2022
-->
<a class="inbox_item {{className}}">
  <div class="inbox-avatar">
    <img alt="" class="border-gray hidden-xs hidden-sm" src="assets/dist/img/avatar4.png" style="float: right">
    <div class="inbox-avatar-text">
      <div>
        <small>
        <span>

<!--                <ng-container *ngIf=" checkMessageType(messageDao)">-->
                <ng-container *ngIf="messageDao.templateButtonMessage!=null">
                <b>Template</b><br>
<!--                  <div *ngIf="checkMessageType(messageDao)">-->
<!--                    <figure>-->
<!--                      <img class="commonmessagetypeimg"-->
<!--                           src="assets/Images/imageicon.png">-->
<!--             <figcaption [innerHTML]="messageDao.templateButtonMessage.caption"></figcaption>-->
<!--               <button  (click)="downloadMedia(messageDao.templateButtonMessage.image.url)" style="width: 100%" class="fa fa-download btn btn-add"></button>-->
<!--                  </figure>-->
<!--                  </div>-->
                  {{messageDao.messageType}}
                  </ng-container>

      <ng-container *ngIf="messageDao.textMessage!=null">
        <span [innerHTML]="messageDao.textMessage.content"></span>
      </ng-container>
           <ng-container *ngIf="messageDao.fileMessage!=null">
                  <figure>
                      <img class="commonmessagetypeimg"
                           src="assets/Images/imageicon.png">
             <figcaption [innerHTML]="messageDao.fileMessage.caption"></figcaption>
               <button  title="View Image" (click)="downloadMedia(messageDao.fileMessage.fileUrl)" style="width: 50%" class="fa fa-eye btn btn-add"></button>
                  </figure>
           </ng-container>
      </span>
        </small>
      </div>
      <br>


      <div *ngIf="messageDao.chatSide!='User'">
        <ng-container *ngIf="messageDao.messageStatus=='SESSIONEXP'">
          <i [title]="messageDao.messageStatus" class="fa fa-exclamation" style="color: red"></i>
        </ng-container>
        <ng-container *ngIf="messageDao.messageStatus=='FAILED'">
          <i [title]="messageDao.messageStatus" class="fa fa-exclamation-triangle" style="color: red"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='PENDING'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>
        <ng-container *ngIf="messageDao.messageStatus=='SENT'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='DLV'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: grey"></i>
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: grey"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='VIEW'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: deepskyblue"></i>
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: deepskyblue"></i>
        </ng-container>
        <i (click)="openMessageDetails(messageDao)" class="fa fa-info-circle"
           style="cursor: pointer;font-weight: bolder" title="Click Here To View Message Info"></i>
      </div>
      <br>

      <br>
      <small [innerHTML]="messageDao.dateTiming.sentTime"></small>
    </div>

  </div>
</a>




