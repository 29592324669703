import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Service, UrlConfigDao} from '../url-config-dao';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {UrlConfigServiceService} from '../url-config-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-url-config',
  templateUrl: './url-config.component.html',
  styleUrls: ['./url-config.component.css']
})
export class UrlConfigComponent implements OnInit {
  tableData: Array<UrlConfigDao>;
  dtTrigger: any;
  dtOptions: any;
  hasWriteOrUpdate: boolean =false;
  showForm:boolean=false;
  port: number[] =[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
  service: any;
  field: string;
  isInsert:boolean=true;
  enumService :Service


  dao:UrlConfigDao={
     id:0,
     url:'',
     fields: [],
     requestMethods:'',
     status: undefined,
      phone:'',
     keyword:'',
     port:'',
     description:'',
     createDate:'',
     service: undefined,

  }




  constructor(private alert:AlertMessageService, private services: UrlConfigServiceService, private router:Router) { }

  ngOnInit(): void {
    this.getAll();
  }

  tableChange($event: PageEvent) {

  }

  filterData() {

  }

  clearFilter() {

  }

  getAll()
  {
    this.services.get().subscribe(value => {
      this.tableData=value;
      console.log(value);
    })
  }





  cancelBtn() {
    this.field='';
    location.reload();
    //this.router.navigate(['/'])
  }
  cancel() {
    this.field='';
     //this.router.navigate(['/'])
  }

  addNew() {
    this.hasWriteOrUpdate=true;
  }

  createOrUpdate() {

    console.log('method is workiing ',this.dao)

    this.updateOrIns(this.dao, this.isInsert, true);
  }

  addParameters() {
    this.showForm= true;
  }

  saveFields() {

    this.dao.fields.push(this.field)
    console.log('filed:: ',this.field)
    console.log('fileds are:: ',    this.dao.fields);
    this.showForm=false;
    this.cancel();

  }

  deleteFiled(field: string) {

    console.log('deleted fileds values is:: ',field)
    if (this.dao.fields!=null)
    {
      console.log('deleted if block call:: ')

        this.dao.fields.splice(this.dao.fields.indexOf(field), 1);
      console.log('After fields delted:: ',this.dao)
     }
  }

  editDao(data: UrlConfigDao, ind: number) {

    console.log("Getting data is:: ",data);
    this.dao=data;

    console.log("Getting data Dao:: ",this.dao);

    this.hasWriteOrUpdate=true;
    this.isInsert=false;
    // this.updateOrIns(this.dao,false,true);
  }

  private async updateOrIns(data: UrlConfigDao, isInsert: boolean, showConfirmMessage: boolean) {

    console.log('will update  {}', data);
    if (showConfirmMessage) {
      let message = 'Update Configuration ?';
      if (isInsert) {
        message = 'Create Configuration?';
      }
      await this.alert.confirmMessage(message, 'Are you sure?');
    }

    if (isInsert)
    {
      this.services.insert(data).subscribe(value => {
        console.log("Inserted Data", value)
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Created !!');
        }
        this.cancelBtn();
      });
    }
    else
    {
      this.services.update(data).subscribe(value => {
        console.log("updated Data", value)
        if (showConfirmMessage) {
          this.alert.showSuccesMessage('Successfully Updated !!');
        }
        this.cancelBtn();

      });
    }

  }
}
