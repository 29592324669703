import {Component, Inject, OnInit} from '@angular/core';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {Router} from '@angular/router';
import {WaContactGroupServiceService} from '../../wa-contact-group-service.service';
import {GroupMasterDao} from '../../../../mailSendingApp/group-master/group-master-dao';
import {WaContactGroupDao} from '../../wa-contact-group-dao';
import {FormBuilder} from '@angular/forms';
import {WacontactgroupmasterDao} from '../wacontactgroupmaster-dao';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-wacontactgroup-master',
  templateUrl: './wacontactgroup-master.component.html',
  styleUrls: ['./wacontactgroup-master.component.css']
})
export class WacontactgroupMasterComponent implements OnInit {
  constructor(private alert: AlertMessageService, private router: Router, private service: WaContactGroupServiceService, private formBuilder: FormBuilder ) {
    this.groupID = this.router.getCurrentNavigation().extras.state.groupId;
    this.groupName = this.router.getCurrentNavigation().extras.state.gname;

   // this.groupMaster=this.data;

  }

  groupID: any;
  groupId: any;
  groupName: any;
  uploadForm: any;
  tableList: Array<WaContactGroupDao>;
  tableList1: Array<WacontactgroupmasterDao>;

  groupMaster: any = {
    groupId: '',
    groupName: '',
    phoneNumber: '',
    status: ''
  };
  dtOptions: any = {bDestroy: true, order: []};
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngOnInit(): void {
    this.dtTrigger.unsubscribe();
   // this.getGroupList();
    this.getData();
    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });
  }
getData()
{
  console.log('GetData method call');
  this.service.getbyGroupId(this.groupID).subscribe(value => {
    this.tableList1 = value;
    this.dtTrigger.next();
    console.log(this.tableList1);
  });
}
  getGroupList()
  {
    this.service.get().subscribe(value => {
      this.tableList = value;
    });
  }
  cancel() {
    this.router.navigate(['waunofficial/v1/wacontactgroup']);
  }
  save() {
    // this.groupMaster.groupId=this.groupID;
    // this.groupMaster.groupName=this.groupName;
   // this.service.insertMaster(this.groupMaster).subscribe(value => {
   //   console.log(value);
   // })
  }



  // tslint:disable-next-line:typedef
  downloadSampleFile() {

    this.alert.showLoader();

    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('phonenumber');
    tdArr.push('clientName');
    tdArr.push('type');
    tdArr.push('email');
    tdArr.push('address');
    trArr.push(tdArr);

    tdArr = [];
    tdArr.push('919999999999');
    tdArr.push('Sujit');
    tdArr.push('anytype1');
    tdArr.push('yadavsujit22@gmail.com');
    tdArr.push('Delhi');
    trArr.push(tdArr);
    file.generateFile(trArr, 'SampleBulkUpload.xlsx');
    this.alert.hideLoader();
  }


  // tslint:disable-next-line:typedef
  onFileSelect($event: Event) {

    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
    }
  }

  // tslint:disable-next-line:typedef
  bulkUpload() {
    const formData = new FormData();
    const file = this.uploadForm.get('filetoUploadDrive').value;
    const fileService = new FileService<WacontactgroupmasterDao>();
    const leadJson: Array<WacontactgroupmasterDao> = [];
    fileService.readFile(file).then(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++) {
        const leadData: WacontactgroupmasterDao = value[i];
        leadJson.push(leadData);
      }

      console.log('this is lead json ', leadJson);
      console.log('this is lgroupId ', this.groupId);
      this.alert.showLoader();
      this.alert.confirmMessage('Upload Data?', 'Are you sure?').then(value2 => {
        // tslint:disable-next-line:max-line-length
        this.service.bulkUpload(this.groupID, leadJson).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + leadJson.length + ' phonenumber ');
          this.alert.showSuccesMessage('Upload Successfully !!');
          this.alert.hideLoader();
        }, error => {
          console.log(error);
          this.alert.showErrorMessage('Failed To Upload Data!!');
          this.alert.hideLoader();
        });
      });


    });
  }

  // tslint:disable-next-line:typedef
  addClient() {
       this.router.navigate(['wacontactgroupclient']);
  }

  // tslint:disable-next-line:typedef
  delete(id: number) {
    this.alert.showLoader();
    this.service.deletePhonenumber(id).subscribe(value => {
      console.log(value);
      this.alert.hideLoader();
    });
  }
}
