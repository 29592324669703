
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div   class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
        <button  (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
        <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>


        <button  (click)="downloadReport()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Bungalow No</th>
              <th>Mobile No</th>
              <th>Amount</th>
              <th>Payment Mode</th>
              <th>Payment Id</th>
              <th>Payment Time</th>
              <th>Payment Status</th>
              <!--               <th>Action</th>-->
            </tr>
            </thead>
            <tbody>
            <ng-container  *ngFor=" let tableData of this.tableData  ;let ind =index">

              <tr  *ngIf="tableData==null">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr>
                <td [innerHTML]="this.tableData.bungalowN"></td>
                <td [innerHTML]="this.tableData.customerMobileNo"></td>
                <td [innerHTML]="this.tableData.amount"></td>
                <td [innerHTML]="this.tableData.paymentMode"></td>
                <td [innerHTML]="this.tableData.paymentID"></td>
                <td [innerHTML]="this.tableData.paymentDateTime"></td>
                <td [innerHTML]="this.tableData.respDescription"></td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    </div>
    </div>
