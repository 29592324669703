import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ClientSignUpDao} from "../client-sign-up/ClientSignUpDao";
import * as http from "http";
import {environment} from "../../environments/environment.prod";
import {FilterDao} from "../crdrreport/FilterDao";

@Injectable({
  providedIn: 'root'
})
export class OnlineSignUpServiceService {

  constructor(private http: HttpClient) { }

  get(filterDao: FilterDao): Observable<Array<ClientSignUpDao>>{
    return this.http.post<Array<ClientSignUpDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/get", filterDao);
  }
}
