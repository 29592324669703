<div class="row">
  <div class="col-md-12">

    <form >
      <!--<h3>Agent Master Form</h3>-->

      <div  class="form-group col-lg-12">
        <label>Client Name <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="clientName" [(ngModel)]="clientMasterDao.clientName">
      </div>
      <div  class="form-group col-lg-12">
        <label>Client Number <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="clientNumber" [(ngModel)]="clientMasterDao.clientNumber">
      </div>
      <div  class="form-group col-lg-12">
        <label>Client Email <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter email"
               name="clientEmailId" [(ngModel)]="clientMasterDao.clientEmailId">
      </div>
      <div  class="form-group col-lg-12">
        <label>Client Company Name <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter email"
               name="clientCompanyName" [(ngModel)]="clientMasterDao.clientCompanyName">
      </div>


      <div  class="form-group col-lg-12">
        <label>Agent Number <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="agent Number"
               name="agentNumber" [(ngModel)]="clientMasterDao.agentNumber">
      </div>



      <!--      <div  class="form-group col-lg-12">-->
      <!--        <label>Account Id <sup class="required">*</sup></label>-->
      <!--        <input type="text" class="form-control" placeholder="Enter Name"-->
      <!--               name="accountId" [(ngModel)]="agentMasterDao.accountId">-->
      <!--      </div>-->

      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitMasterForm(clientMasterDao)">{{submitBtnTxt}}</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>
  </div>
</div>
