

<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">

        <h2>Dynamic Url Configure</h2>
        <div class="panel-title">
          <button (click)="addNew()"   class="btn btn-add">Add New Gateway <i class="fa fa-plus"></i></button>
        </div>
      </div>
      <div   style="" class="panel-body">
        <div *ngIf="hasWriteOrUpdate"   id="addnewdiv">
          <form ngNativeValidate (submit)="createOrUpdate()">
            <div class="form-group col-lg-12">
              <label>LongCode Status</label>
              <mat-slide-toggle name="status" [(ngModel)]="this.dao.status"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-6">
              <label>Url<sup class="required">*</sup></label>
              <input type="url" class="form-control"  [(ngModel)]="this.dao.url" name="url" required>
            </div>




            <div class="form-group col-lg-6">
              <label>Parameters <sup class="required"></sup></label>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                  <tr>
                     <th>Name</th>
                     <th style="text-align: right">Action <i (click)="addParameters()"    class="fa fa-plus-circle"></i></th>
                  </tr>
                  </thead>
                  <tbody *ngIf="!showForm">

                  <ng-container  *ngFor="let filed of this.dao.fields;let ind = index">
                    <tr>
                      <td [innerHTML]="filed">
                        <td style="text-align: right"><i   (click)="deleteFiled(field)" class="btn btn-danger fa fa-trash"></i></td>
                   </ng-container>
                  </tbody>
                </table>

                <div *ngIf="showForm==true">

                   <form ngNativeValidate (submit)="saveFields()">
                     <div class="form-group col-lg-6">
<!--                       <label>Url<sup class="required">*</sup></label>-->
                       <input type="text" class="form-control"  [(ngModel)]="this.field" name="fields" required>
                     </div>
                     <div class="form-group col-lg-6">
                       <button  type="submit" class="btn btn-add">Save</button>
                       <button type="button" (click)="cancelBtn()" class="btn btn-add">Cancel</button>
                     </div>
                   </form>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label>Request Method<sup class="required">*</sup></label>
              <mat-radio-group aria-label="Select an option"  [(ngModel)]="this.dao.requestMethods"  name="metod">
                <mat-radio-button [value]="'GET'" [checked]="true">GET</mat-radio-button>
                <mat-radio-button [value]="'POST'">POST</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="form-group col-lg-6">
              <label>Service<sup class="required">*</sup></label>
              <mat-radio-group aria-label="Select an option"   [(ngModel)]="this.dao.service" name="service">
                 <mat-radio-button [value]="'LONGCODE'" [checked]="true">LONG_CODE</mat-radio-button>
                <mat-radio-button [value]="'GSMGATEWAY'"  >GSM_GATEWAY</mat-radio-button>

              </mat-radio-group>
            </div>

            <container-element [ngSwitch]="this.dao.service">
              <some-element *ngSwitchCase="'GSMGATEWAY'">
            <div class="form-group col-lg-6">
              <label>Number <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="this.dao.phone"  name="longcodeNumber" required>
            </div>
            <div class="form-group col-lg-6">
              <label>Port <sup class="required">*</sup></label>
              <select class="form-control" name="port"[(ngModel)]="this.dao.port" required>
                <ng-container *ngFor="let ports of port">
                  <option [value]="ports" [innerHTML]="ports"></option>
                </ng-container>
              </select>
            </div>
              </some-element>

              <some-element *ngSwitchDefault>

              </some-element>
            </container-element>



            <div class="form-group col-lg-6">
              <label>keyword </label>
              <input type="text" class="form-control"  [(ngModel)]="this.dao.keyword" name="keyword" >
            </div>

            <div class="form-group col-lg-6">
              <label>Description<sup class="required">*</sup></label>
              <input type="text" class="form-control"  [(ngModel)]="this.dao.description" name="description" required>
            </div>

            <div class="form-group col-lg-6">
              <button  type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancelBtn()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>

        <div *ngIf="!hasWriteOrUpdate">
        <div id="tablediv">
          <div class="table-responsive">
            <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
              <thead>
              <tr>
                <th>Url</th>
                <th>Port</th>
                <th>Keyword</th>
                <th>Date</th>
                <th>Status</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container  *ngFor="let data of tableData,let ind = index">
                <tr>

                  <td [innerHTML]="data.url"></td>
                   <td [innerHTML]="data.port"> </td>
                   <td [innerHTML]="data.keyword"> </td>
                  <td [innerHTML]="data.createDate"> </td>
                  <td >
                    <i *ngIf="data.status== true" style="color: green" class="fa fa-check"></i><i *ngIf="data.status== 0" style="color: red" class="fa fa-remove"></i>
                  </td>
                  <td [innerHTML]="data.description"> </td>
                 <td>  <i title="Click Here To Edit" (click)="editDao(data,ind)"
                     class="btn btn-add fa fa-pencil"></i> </td> </tr>

              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>



