import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientSidePagination} from "../../../../CommonObj/ClientSidePagination";
import {ConversationDao} from "../../../../conversation/ConversationDao";
import {MessageDao, TextMessageDao} from "../../../../ws-template/MessageDao";
import {Observable, Subscription} from "rxjs";
import {ConversationService} from "../../../../conversation/ConversationService/conversation.service";
import {CampaingService} from "../../../../CampainService/campaing.service";
import {ConversationSubscriptionService} from "../../../../conversation/ConversationSubscription/ConversationSubscriptionService";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ContactFormDao} from "../../../../conversation/contact-form/ContactFormDao";
import {ContactFormComponent} from "../../../../conversation/contact-form/contact-form.component";
import {QuickReplyMessageComponent} from "../../../../quick-reply-message/quick-reply-message.component";
import {ChatHistoryDao} from "./ChatHistoryDao";
import {PaginationDao} from "../../../../CommonObj/PaginationDao";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment.prod";

@Component({
  selector: 'app-nouriture-conversation',
  templateUrl: './nouriture-conversation.component.html',
  styleUrls: ['./nouriture-conversation.component.css']
})
export class NouritureConversationComponent implements OnInit,OnDestroy {
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  textMessage:string="";
  conversationList:ClientSidePagination<ChatHistoryDao> =  null;
  chatHistoryDao:ChatHistoryDao=null;
  messageList:Array<ChatHistoryDao>=[];
  offset:number=0;
  limit:number=10;
  searchStr:string="";
  loadMoreEnable: boolean=false;
  private subscription:Subscription;
  constructor(private http:HttpClient,private conversationService:ConversationService,private campservice:CampaingService,private conversationSub:ConversationSubscriptionService,private alertService:AlertMessageService,private router: Router,private route: ActivatedRoute,private dialog:MatDialog) {
    this.searchStr="0";
    //this.loadData(0,10);

  }

  loadData(offset,limit)
  {
    this.alertService.showLoader();
    this.getConversation(offset,limit).subscribe(value => {
      console.table(value)
      this.conversationList={data: value, end: limit, start: offset, total: value.length};
      this.alertService.hideLoader();
    })

  }

  getConversation(offset:number,limit:number):Observable<Array<ChatHistoryDao>>
  {
    return  this.http.get<Array<ChatHistoryDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/chathistory/getallcustomernumbers');
  }
  getMessages(number:string):Observable<Array<ChatHistoryDao>>
  {
    return  this.http.get<Array<ChatHistoryDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/chathistory/getallbycustomernumber?customerNumber='+number);

  }

  loadChats(offset,limit,id,isNew,scroll=true) {
    if (isNew)
    {
      this.messageList=[];
      offset =0;
      limit=10;
    }

    this.offset=offset;
    this.limit=limit;
    this.loadMoreEnable=true;
    this.alertService.showLoader();
    this.getMessages("").subscribe(value => {
      var len=value.length;
      for(var i=0;i<len;i++)
      {

        this.messageList.unshift(value[i]);
      }
      if(scroll) {
        setTimeout(function() {

          $('#chatconversationdiv').stop().animate({
            scrollTop: $('#chatconversationdiv')[0].scrollHeight
          }, 800);
        }, 1000)
      }

      this.alertService.hideLoader();
    })
  }

  ngOnInit(): void {
    this.loadData(0,10);

  }


  takeMeToNumber(json: ChatHistoryDao) {
   //this.router.navigate([],{queryParams:json});
this.getMessages(json.customerNumber).subscribe(messages=>{
  let chatHistoryDao:ChatHistoryDao;
  this.messageList=[];
  for(let i=0;i<messages.length;i++){

    chatHistoryDao={
      accountId: 0,
      audio: undefined,
      campaingId: 0,
      chatSide: (messages[i].sender == 2)?'Client':'User',
      customerNumber: "",
      date: messages[i].date,
      dlrTime: "",
      document: undefined,
      id: messages[i].id,
      image: undefined,
      isPanel: false,
      messageId: "",
      messageStatus: "VIEW",
      reply: "",
      replyTime: "",
      sender: 0,
      template: undefined,
      text: {body: messages[i].reply},
      to:  messages[i].customerNumber,
      type: "text",
      video: undefined,
      viewTime: ""
    }
this.messageList.push(chatHistoryDao)
  }
  //this.messageList=messages;
})
  }

  tableChange(json,data:ClientSidePagination<any>) {
    /*   this.offset = json.pageIndex;
       this.limit = json.pageSize;
       this.*/
    data.start=json.pageIndex;
    data.end=json.pageSize;
    this.loadData(data.start,data.end);

  }


}
