import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserMasterDao} from '../User/UserMasterDao';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
const  appPrefix = environment.appUrl + environment.authMicroservice;
@Injectable({
  providedIn: 'root'
})
export class UsermasterService {

  constructor(private http: HttpClient) {


  }
  createUser(user: UserMasterDao): Observable<UserMasterDao>
  {

    return this.http.post<UserMasterDao>(appPrefix + 'user/insert', user);
  }

  updateUser(user: UserMasterDao): Observable<UserMasterDao>
  {
    return this.http.post<UserMasterDao>(appPrefix + 'user/update', user);
  }
  getAll(): Observable<Array<UserMasterDao>>
  {
    return this.http.get<Array<UserMasterDao>>(appPrefix + 'user/get');
  }

  getMyTeam(id: number): Observable<Array<UserMasterDao>>
  {
    return this.http.get<Array<UserMasterDao>>(appPrefix + 'user/getreporting?id=' + id);
  }
  getById(id: number): Observable<UserMasterDao>
  {
    return this.http.get<UserMasterDao>(appPrefix + id);
  }


  getCurrentUser(): Observable<UserMasterDao>
  {
    return this.http.get<UserMasterDao>(appPrefix + 'user/getcurrentuser');
  }
  authenticateUser(user: UserMasterDao): Observable<UserMasterDao>
  {
    const formData = new FormData();
    formData.append('username', user.username);
    formData.append('password', user.password);
    formData.append('grant_type', 'password');
    formData.append('client_secret', environment.clientSecret);
    formData.append('client_id', environment.clientId);
    // tslint:disable-next-line:max-line-length
    return this.http.post<UserMasterDao>(appPrefix + 'oauth/token', formData, {headers: {Authorization: 'Basic ' + environment.headerToken}});
  }
  changePassword(currentpass: string, newPass: string)
  {
    return this.http.get<UserMasterDao>(appPrefix + 'user/changepassword?oldpassword=' + currentpass + '&newpass=' + newPass);
  }
  profileEdit(phn: string, email: string, name: string, pass: string)
  {
    return this.http.get<UserMasterDao>(appPrefix + 'user/editprofile?oldpass=' + pass + '&name=' + name + '&phn=' + phn + '&email=' + email);
  }
  deleteReporting(userId: number, parentId: number): Observable<void>
  {
    return this.http.delete<void>(appPrefix + 'user/delete?userId=' + userId + '&parentId=' + parentId);
  }
  insertReporting(userId: number, parentId: number): Observable<void>
  {
    return this.http.get<void>(appPrefix + 'user/insertReporting?userId=' + userId + '&parentId=' + parentId);
  }

  getAllUsers():Observable<any>{

    return this.http.get<Array<any>>(appPrefix+'user/getAll')
  }


}
