
<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
                <div class="panel-title">
                    <button (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i>
                    </button>
                    <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i>
                    </button>
                    <button (click)="downloadReport()" class="btn btn-add">Download File<i class="fa fa-download"></i>
                    </button>
                </div>
            </div>
            <div style="" class="panel-body">

                <div id="tablediv">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>PhoneNumber</th>
                                <th>Uid</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="text-align: center" *ngIf="tableData.total==0">
                                <td colspan="9">No Entry</td>
                            </tr>
                            <ng-container *ngFor="let data of tableData.data;let ind = index">
                                <tr>
                                    <td [innerHTML]="data.id"></td>
                                    <td [innerHTML]="data.phoneNumber"></td>
                                    <td [innerHTML]="data.uid"></td>
                                    <td  [innerHTML]='data.status'></td>
                                    <td [innerHTML]="data.date"></td>
                                    <td></td>
                                </tr>
                            </ng-container>


                            </tbody>
                        </table>
                    </div>
                    <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                                   [pageSize]="10"
                                   [pageSizeOptions]="[2, 5,10, 25, 100]">
                    </mat-paginator>

                </div>

            </div>
        </div>
    </div>
</div>


<router-outlet></router-outlet>
