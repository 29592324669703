



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <button type="button" (click)="onClose()" mat-dialog-close  class="close" data-dismiss="modal" aria-hidden="true">×</button>

        <div class="panel-title">
        <h3 style="text-align: center">Add Country Wise Rates</h3>
        </div>
      </div>
      <div style="" class="panel-body">
        <form>
        <ng-container *ngFor="let data of countryWisePrice;let ind = index ">
          <div class="form-group col-lg-12 card">
            <label [innerHTML]="data.countryDao.country">  <sup class="required">*</sup></label>
            <mat-slide-toggle name="toggle{{ind}}" [(ngModel)]="data.found" (click)="deleteCountryRates(!data.found,data.rate)"></mat-slide-toggle>
            <div *ngIf="data.found">
              <div class="form-group col-lg-12">
                <label>User Initiated Charge  <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="data.rate.userInitiatedRates" name="userInitiatedRates{{ind}}" required>
              </div>

              <div class="form-group col-lg-12">
                <label>Marketing Initiated Charge  <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="data.rate.marketingRates" name="marketingInitiatedRates{{ind}}" required>
              </div>
               <div class="form-group col-lg-12">
                <label>Utility Initiated Charge  <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="data.rate.utilityRates" name="utilityInitiatedRates{{ind}}" required>
              </div>
            <div class="form-group col-lg-12">
                <label>Authentication Initiated Charge  <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="data.rate.authenicationRates" name="authenticationInitiatedRates{{ind}}" required>
              </div>
              <button style="margin: 10px" type="button" class="btn btn-add fa fa-save" (click)="saveData(data.rate)"> Save</button>

            </div>






          </div>
          <hr>
          <hr>
        </ng-container>
        </form>

      </div>
    </div>
  </div>
</div>







