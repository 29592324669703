<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">

<!--                    <div class="panel panel-bd lobidragdashboard">-->
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
        <button  (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
        <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>


        <button  (click)="downloadReport()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>
        <input  style="float: right"  type="text" name="search" [(ngModel)]="searchText"   placeholder="Type to search here">


      </div>

      <div style="" class="panel-body">

        <div  class="table-responsive">


             <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" >
<!--          <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">-->
            <thead>
            <tr>
<!--              <th>Id </th>-->
              <th>UID</th>
              <th>coupon code</th>
              <th>MR No.</th>
              <th>Retailer Number</th>
              <th>Depot_No</th>
               <th>Prize</th>
              <th>AsmArea</th>
              <th>MrUid</th>
              <th>MrName</th>
              <th>RetailerName</th>
              <th>RetailerCategory</th>
              <th>DbCode</th>
              <th>Plant Code</th>
              <th>Plant</th>
              <th>RegionCode</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList  | filter: searchText  ">
              <tr >
<!--                <td [innerHTML]="user.id"></td>-->
                <td [innerHTML]="user.uid"></td>
                <td [innerHTML]="user.code"></td>
                <td [innerHTML]="user.phone"></td>
                <td Exit [innerHTML]='user.endUser'></td>
                <td [innerHTML]="user.depot_No"></td>
                 <td [innerHTML]="user.prize"></td>
                <td [innerHTML]="user.asmArea"></td>
                <td [innerHTML]="user.mrUid"></td>
                <td [innerHTML]="user.mrName"></td>
                <td [innerHTML]="user.retailerName"></td>
                <td [innerHTML]="user.retailerCategory"></td>
                <td [innerHTML]="user.dbCode"></td>
                <td [innerHTML]="user.sl_No"></td>
                <td [innerHTML]="user.plant"></td>
                <td [innerHTML]="user.regionCode"></td>
                <td Exit [innerHTML]='user.responseScratchCard'></td>
                <td [innerHTML]="user.date"></td>
                <td></td>
<!--                <td>-->
                  <!-- <i title="Click Here To Edit " (click)="drawObdReport(user,ind)"
                        class="btn btn-add fa fa-bar-chart "></i>-->
<!--                  <i title="Download Report" (click)="downloadReport(user,ind)"-->
<!--                     class="btn btn-add fa fa-download "></i>-->
<!--                </td>-->
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

