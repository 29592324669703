import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuMasterService} from "../menumaster/MenuMasterService/menu-master.service";
import {ClientSidePagination} from "../CommonObj/ClientSidePagination";
import {MenuMasterDao} from "../menumaster/MenuMasterDao";
import {FeatureDao} from "../Feature/feature-dao";
import {PageDao} from "../Feature/page-dao";
import {FormBuilder} from "@angular/forms";
import {AccountServiceFormDao} from "../account-master/account-services/AccountServiceDao";

@Component({
  selector: 'app-feature-acess',
  templateUrl: './feature-acess.component.html',
  styleUrls: ['./feature-acess.component.css']
})
export class FeatureAcessComponent implements OnInit {
  addNewDiv: null;
  tableLists: any;
  addNewDiv1:boolean ;
  submitBtnTxt: any='Save';
  menuList:ClientSidePagination<MenuMasterDao>={data: [], end: 0, start: 0, total: 0}
   pagedao: PageDao={
    id:null,
    create: false,
     read:false,
     update:false,
     delete:false,
     template:false,
     file:false,
     text:false,
     accountId:null,
     pageName:'',
     download:false
   }
  dao: FeatureDao={
    id:null,
    pageName:'',
    description:'',
    menu:'',
    type: this.pagedao,
    accountId:null,
    active:false
  }
  constructor(public dialogRef: MatDialogRef<FeatureAcessComponent>, private menu: MenuMasterService,private _formBuilder: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: FeatureDao) {
    if (data!=undefined || data!=null)
    {
      this.dao=data;
      this.types.get("create").setValue(this.dao.type.create)
      this.types.get("read").setValue(this.dao.type.read)
      this.types.get("update").setValue(this.dao.type.update)
       this.types.get("delete").setValue(this.dao.type.delete)
      this.types.get("template").setValue(this.dao.type.template)
      this.types.get("download").setValue(this.dao.type.download)
      this.types.get("file").setValue(this.dao.type.file)
      this.types.get("text").setValue(this.dao.type.text)
      console.log("Features doooo :: ",this.dao);
    }


  }

  ngOnInit(): void {
    this.menu.getRoles().subscribe(value => {
      this.menuList={data:value, end: 10, start: 0, total: value.length};
    })
  }

  addNew() {

  }

  createOrUpdate() {

    this.dao.type.create=this.types.value.create;
    this.dao.type.update=this.types.value.update;
    this.dao.type.delete=this.types.value.delete;
    this.dao.type.read=this.types.value.read;
    this.dao.type.download=this.types.value.download;
     this.dao.type.file=this.types.value.file;
    this.dao.type.template=this.types.value.template;
    this.dao.type.text=this.types.value.text;
    console.log('FeaturesDao:: ',this.dao)

    if (this.dao!=null)
    {
      this.dialogRef.close(this.dao);
    }

  }


  cancel() {
   }

  editDao(data: any, ind: number) {

  }

  edit(data: any, ind: number) {

  }

  delete(id) {

  }

  onClose() {
    this.dialogRef.close(1);

  }

  setType(event: Event) {

   console.log("Event values:: ",event);

  }

  types = this._formBuilder.group({
    create: false,
    read: false,
    update: false,
    delete: false,
    template:false,
    download:false,
    file:false,
    text:false,
  });


}
