
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()"  class="btn btn-add">Add  Freight<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>State <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="freightChartDao.state" name="state" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Min Weight (Kg) <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="freightChartDao.minWeight" name="minWeight" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Max Weight (Kg) <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="freightChartDao.maxWeight" name="maxWeight" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Fixed Cost <sup class="required">*</sup></label>
              <input type="number" step="0.01" class="form-control" [(ngModel)]="freightChartDao.fixedCost" name="fixedCost" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Toll <sup class="required">*</sup></label>
              <input type="number" step="0.01" class="form-control" [(ngModel)]="freightChartDao.toll" name="fixedCost" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Per Km Charge<sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="freightChartDao.perKmCharge" name="perKmCharge" required>
            </div>

            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>State</th>
              <th>Weight</th>
              <th>Fixed Cost</th>
              <th>Toll</th>
              <th>Per Km Charge</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="freightList.length==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let freightDao of freightList;let ind =index">
              <tr>
                <td [innerHTML]="freightDao.state"></td>
                <td >{{freightDao.minWeight}} to {{freightDao.maxWeight}} Kg</td>
                <td [innerHTML]="freightDao.fixedCost"></td>
                <td [innerHTML]="freightDao.toll"></td>
                <td [innerHTML]="freightDao.perKmCharge"></td>
                <td><i title="Click Here To Edit" (click)="edit(freightDao)"
                       class="btn btn-add fa fa-pencil"></i>

                  <i title="Click Here To Delete" (click)="delete(freightDao,ind)"
                     class="btn btn-add fa fa-trash"></i>
                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







