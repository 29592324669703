import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-session-report',
  templateUrl: './active-session-report.component.html',
  styleUrls: ['./active-session-report.component.css']
})
export class ActiveSessionReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
