import { Component, OnInit } from '@angular/core';
import {ServiceMasterDao} from '../servicemaster/ServiceMasterDao';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {ServiceMasterService} from '../servicemaster/ServiceMasterService/service-master.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {MenuMasterDao} from './MenuMasterDao';
import {MenuMasterService} from './MenuMasterService/menu-master.service';

@Component({
  selector: 'app-menumaster',
  templateUrl: './menumaster.component.html',
  styleUrls: ['./menumaster.component.css']
})
export class MenumasterComponent implements OnInit {

  addNewDiv:boolean=false;

  data:MenuMasterDao={ id: 0, name: '',menuIcon:"",menuSeq:0.00}
  tableList:ClientSidePagination<MenuMasterDao>={data: [], end: 0, start: 0, total: 0}
  constructor(private service:MenuMasterService,private alert:AlertMessageService) {

    this.getData();
  }
  getData()
  {
    this.service.getRoles().subscribe(value => {
      this.tableList={data:value, end: 10, start: 0, total: value.length}
    })
  }
  ngOnInit(): void {
  }

  isOrUpdate() {
    this.alert.showLoader()
    this.alert.confirmMessage("Save Menu?","Are you sure?").then(value => {
      this.service.saveRole(this.data).subscribe(value1 => {

        this.getData();
        this.alert.showSuccesMessage("Successfully saved!!")
        this.cancel()
        this.alert.hideLoader()
      },error => {
        this.alert.showErrorMessage("Duplicate Menu!!")
        this.alert.hideLoader()
      })
    },)

  }

  cancel() {
    this.addNewDiv=false;
    this.data={id: 0, name: '',menuSeq:0.00,menuIcon:""}
  }

  edit(data: MenuMasterDao, ind: number) {
    this.data=data;
    this.addNewDiv=true;


  }

  addNew() {
    this.addNewDiv=true;
  }


}
