import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ScratchCardServiceService} from './scratch-card-service.service';
import {FilterDao} from '../../crdrreport/FilterDao';
import {Subject} from 'rxjs';
import {OBDCampaignGroupByDao} from '../obd/OBDCampaignGroupByDao';
import {formatDate} from '@angular/common';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {ScratchCardDao} from './ScratchCardDao';


@Component({
  selector: 'app-scratch-card-report',
  templateUrl: './scratch-card-report.component.html',
  styleUrls: ['./scratch-card-report.component.css']
})
export class ScratchCardReportComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private scratchcardservice: ScratchCardServiceService, private dialog: MatDialog, private alert: AlertMessageService) { }
  startdate: number = undefined;
  enddate: number = undefined;
  dateFilterType: Array<string>;
  services: Array<string>;
  groupBy: Array<string>;
  public filter: FilterDao;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  // dtOptions: any = {bPaginate: true, order: []};
  tableList: Array<ScratchCardDao> = null;
  searchText: any;
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;

      console.log(this.startdate, + '  ' + this.enddate);
      this.loadDataforDate();
    });
  }
  // tslint:disable-next-line:typedef
  loadData()
  {
    // @ts-ignore
    this.scratchcardservice.getCampaignOBDGroupByReportDatewise().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }
  // tslint:disable-next-line:typedef
  loadDataforDate() {
    this.tableList = null;
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    this.scratchcardservice.getCampaignOBDGroupByReportDatewise(this.startdate, this.enddate).subscribe(value => {
      this.tableList = value;
      console.log(this.tableList);
      this.alert.hideLoader();
    });
  }
  // tslint:disable-next-line:typedef
  filterDatafordate() {
    this.dialog.open(DatefilterComponent);
  }
  // tslint:disable-next-line:typedef
  downloadReport() {


    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    // tdArr.push('Id');
    tdArr.push('Uid');
    tdArr.push('coupon code');
    tdArr.push('MR No');
    tdArr.push('Retailers Number');
    tdArr.push('Depot_NO');
    tdArr.push('Prize');
    tdArr.push('AsmArea');
    tdArr.push('MrUid');
    tdArr.push('MrName');
    tdArr.push('RetailerName');
    tdArr.push('RetailerCategory');
    tdArr.push('DbCode');
    tdArr.push('Plant Code');
    tdArr.push('Plant');
    tdArr.push('RegionCode');
    tdArr.push('Status');
    tdArr.push('Date');
    trArr.push(tdArr);
    this.scratchcardservice.getCampaignOBDGroupByReportDatewise(this.startdate, this.enddate).subscribe(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++)
      {
        tdArr = [];
        // tdArr.push(value[i].id);
        tdArr.push(value[i].uid);
        tdArr.push(value[i].code);
        tdArr.push(value[i].phone);
        tdArr.push(value[i].endUser);
        tdArr.push(value[i].depot_No);
         tdArr.push(value[i].prize);
        tdArr.push(value[i].asmArea);
        tdArr.push(value[i].mrUid);
        tdArr.push(value[i].mrName);
        tdArr.push(value[i].retailerName);
        tdArr.push(value[i].retailerCategory);
        tdArr.push(value[i].dbCode);
        tdArr.push(value[i].sl_No);
        tdArr.push(value[i].plant);
        tdArr.push(value[i].regionCode);
        tdArr.push(value[i].responseScratchCard);
        tdArr.push(value[i].date);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,   'Scratch_Card_Report.xlsx');
    });
    this.alert.hideLoader();
   }
  // tslint:disable-next-line:typedef
  clearFilter()
  {
    this.router.navigate([], {queryParams: {}});
  }
}
