<h1 mat-dialog-title style="text-align: center">Role And Access <i class="fa fa-lock"></i>
</h1>

<mat-dialog-content class="mat-typography">

  <ng-container *ngFor="let role of roleMasterDao;let ind = index">
  <div class="form-group col-lg-12">
    <label [innerHTML]="role.roleName"> </label>
    <mat-slide-toggle (change)="updateRole(ind)" [(ngModel)]="role.checked" name="active{{ind}}"></mat-slide-toggle>
  </div>
  </ng-container>



</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-add" mat-dialog-close="null">Close</button>
</mat-dialog-actions>
