
<!--Author: Sujit Yadav-->
<!--Date: 03/Sep/2022-->


<!--Author: Sujit Yadav-->
<!--Date: 03/Sep/2022-->
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu"  >
    <div class="panel panel-bd lobidragdashboard">
      <div  class="panel-heading" >
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  class="table-responsive"  >
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>User Id</th>
              <th>Campaign Name</th>
              <th>Reciepts</th>

              <th>Create At</th>

              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: center" *ngIf="tableData.total==0">
              <td colspan="9">No Entry</td>
            </tr>
            <ng-container  *ngFor="let data of tableData.data;let ind = index">
              <tr>
                <td [innerHTML]="data.accountId"></td>
                <td [innerHTML]="data.campaignName"></td>
                <td [innerHTML]="data.count"></td>
                <td [innerHTML]="data.date"></td>
                <td>
                  <i title="Download Report" (click)="downloadFile(data,ind)"
                     class="btn btn-add fa fa-download"></i>
                </td>
              </tr>
            </ng-container>



            </tbody>
          </table>
        </div>
        <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                       [pageSize]="10"
                       [pageSizeOptions]="[2, 5,10, 25, 100]">
        </mat-paginator>

      </div>

    </div>
  </div>
</div>








