import { Component, OnInit } from '@angular/core';
import {RoleMasterDao} from '../rolemaster/RoleMasterDao';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {RoleServiceService} from '../rolemaster/RoleService/role-service.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {ServiceMasterDao} from './ServiceMasterDao';
import {ServiceMasterService} from './ServiceMasterService/service-master.service';

@Component({
  selector: 'app-servicemaster',
  templateUrl: './servicemaster.component.html',
  styleUrls: ['./servicemaster.component.css']
})
export class ServicemasterComponent implements OnInit {

  addNewDiv:boolean=false;

  data:ServiceMasterDao={descrip: '', logo: '', serviceName: '', serviceUrl: '', id: 0, status: true}
  tableList:ClientSidePagination<ServiceMasterDao>={data: [], end: 0, start: 0, total: 0}
  constructor(private service:ServiceMasterService,private alert:AlertMessageService) {

    this.getData();
  }
  getData()
  {
    this.service.getRoles().subscribe(value => {
      this.tableList={data:value, end: 10, start: 0, total: value.length}
    })
  }
  ngOnInit(): void {
  }

  isOrUpdate() {
    this.alert.showLoader()
    this.alert.confirmMessage("Save Service?","Are you sure?").then(value => {
      this.service.saveRole(this.data).subscribe(value1 => {

        this.getData();
        this.alert.showSuccesMessage("Successfully saved!!")
        this.cancel()
        this.alert.hideLoader()
      },error => {
        this.alert.showErrorMessage("Duplicate Service!!")
        this.alert.hideLoader()
      })
    },)

  }

  cancel() {
    this.addNewDiv=false;
    this.data={descrip: '', id: 0, logo: '', serviceName: '', serviceUrl: '', status: true}
  }

  edit(data: ServiceMasterDao, ind: number) {
    this.data=data;
    this.addNewDiv=true;


  }

  addNew() {
    this.addNewDiv=true;
  }

}
