import {Component, OnInit} from '@angular/core';
import {Observable, Observer, Subject} from 'rxjs';
import {CampaignDao} from '../../onetomany/CampaignDao';
import {ChartDataDao} from '../../chart-and-tables/ChartDataDao';
import {Label} from 'ng2-charts';
import {GraphCommonDao} from '../../graph-common/GraphCommonDao';
import {GraphCommonComponent} from '../../graph-common/graph-common.component';
import {formatDate} from '@angular/common';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {PaginationDao, WAPagination} from '../../CommonObj/PaginationDao';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {IncomingMessageDao} from './IncomingMessageDao';
import {IncomingMessagesService} from './incoming-messages.service';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import {UserMasterDao} from '../../Services/User/UserMasterDao';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {HttpClient} from "@angular/common/http";
import { saveAs } from 'file-saver';

const MIME_TYPES={
pdf:'application/pdf',
jpeg:'image/jpeg',
jpg:'image/jpeg',
mp3:'audio/mpeg',
png:'image/png',
ppt:'application/vnd.ms-powerpoint',
mp4:'video/mp4',
xlsx:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
xls:'application/vnd.ms-excel',
csv:'text/csv',
doc:'application/msword',
docx:'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
txt:'text/plain',
vcf:'text/x-vcard',
zip:'application/zip'
};

@Component({
  selector: 'app-incoming-messages',
  templateUrl: './incoming-messages.component.html',
  styleUrls: ['./incoming-messages.component.css']
})
export class IncomingMessagesComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, order: []};
  tableList: WAPagination<IncomingMessageDao> = {data: [], recordsTotal: 0};
  startdate: string = undefined;
  enddate: string = undefined;
  tempStartDate: string = undefined;
  tempEndDate: string = undefined;
  pageNumber = '0';
  limit = 10;
  userId = 0;
  userMasterDao: UserMasterDao;
  base64Image: any;


  // tslint:disable-next-line:max-line-length

  constructor(private router: Router, private route: ActivatedRoute, private incomingMessageService: IncomingMessagesService, private dialog: MatDialog, private alert: AlertMessageService, private userMasterService: UsermasterService,private http:HttpClient ) {
    this.getUserDetails();
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(queryParams => {

      this.tempStartDate = undefined;
      this.tempEndDate = undefined;
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;
      console.log(this.startdate, +'  ' + this.enddate);
      this.loadData();
      this.tempStartDate = this.startdate;
      this.tempEndDate = this.enddate;
      // this.startdate = undefined;
      // this.enddate = undefined;
    });
  }

  // tslint:disable-next-line:typedef
  getUserDetails() {
    this.userMasterDao = JSON.parse(localStorage.getItem('userDeatails'));
    this.userId = this.userMasterDao.id;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  // tslint:disable-next-line:typedef
  filterDatafordate() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  // tslint:disable-next-line:typedef
  tableChange(json) {
    this.pageNumber = json.pageIndex;
    this.limit = json.pageSize;

    this.startdate = this.tempStartDate.replace('-', '').replace(' 00:00:00', '').replace('-', '');
    this.enddate = this.tempEndDate.replace('-', '').replace(' 23:59:59', '').replace('-', '');
    this.loadData();

  }


  // tslint:disable-next-line:typedef
  loadData() {
    this.alert.showLoader();
    const format = 'yyyy-MM-dd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);
    if (this.startdate == undefined || this.enddate == undefined) {
      // tslint:disable-next-line:radix
      this.startdate = formattedDateFirst + ' 00:00:00';
      this.enddate = formattedDateLast + ' 23:59:59';
    } else {
      this.startdate = this.startdate.slice(0, 4) + ('-') + this.startdate.slice(4, 6) + ('-') + this.startdate.slice(6, 8) + ' 00:00:00';
      this.enddate = this.enddate.slice(0, 4) + ('-') + this.enddate.slice(4, 6) + ('-') + this.enddate.slice(6, 8) + ' 23:59:59';
    }

    console.log('limit' + this.limit);

    this.incomingMessageService.getIncomingMesssage(this.startdate, this.enddate, this.pageNumber, 10, this.userId).subscribe(value => {
      this.tableList = value;
    });
    this.alert.hideLoader();
  }


  downloadFile() {
    this.alert.showLoader();
    this.incomingMessageService.getIncomingMesssage(this.startdate, this.enddate, this.pageNumber, 99999, this.userId).subscribe(value => {
      // console.log("value of incoming msg",value)
      const file = new FileService<any>();
      const trArr = [];
      let tdArr = [];
      tdArr.push('Date and Time');
      tdArr.push('Sender');
      tdArr.push('Group Name');
      tdArr.push('Message Type');
      tdArr.push('Message');
      tdArr.push('Status');

      trArr.push(tdArr);
      console.log(value.recordsTotal);
      console.log(value.data);

      for (let i = 0; i < value.data.length; i++) {
        console.log('value of  data ', i, value.data[i]);
        const data = value.data[i].dateTimeFilter;
        const afterformat = data.toString().slice(0, 4) + ('-') + data.toString().slice(4, 6) + ('-') + data.toString().slice(6, 8) + (' ') + data.toString().slice(8, 10) +
          (':') + data.toString().slice(10, 12) + (':') + data.toString().slice(12, 14);
        console.log('after format', afterformat);

        tdArr = [];

        tdArr.push(afterformat);
        tdArr.push(value.data[i].dst);
        tdArr.push(value.data[i].groupName);
        tdArr.push(value.data[i].messageType);
        if (value.data[i].messageType != null) {
          switch (value.data[i].messageType.toString()) {
            case 'TEXT':
              tdArr.push(value.data[i].textMessage.content);
              break;
            case 'BUTTONS':
              tdArr.push(value.data[i].textMessage.content);
              break;
            case 'FILE':
              let fileName = value.data[i].fileMessage.fileUrl;
              tdArr.push(fileName);
              break;
            default:
              tdArr.push('');
              tdArr.push('');
              break;
          }
        }
        tdArr.push(value.data[i].messageStatus);
        trArr.push(tdArr);


      }
      file.generateFile(trArr, 'IncomingReport.xlsx');
      this.alert.hideLoader();

    });
  }

  // DOWNLOAD ANY MEDIA TYPE IN LOCAL SYSTEM
  downloadMedia(data: any) {
    let fileName='';
    if (data.fileMessage!=null)
    {
      fileName='readme.txt';
      this.alert.showLoader();
      const EXT = fileName.substring(fileName.lastIndexOf(".") + 1)
      // const EXT = "pdf"
      this.incomingMessageService.downloadExternalFile(fileName).subscribe(data => {

        console.log('data value',data);
        // SAVE IT ON CLIENT SIDE MACHINE
        saveAs(new Blob([data], {type: (MIME_TYPES)[EXT]}), fileName)
      })
      this.alert.hideLoader();
    }
    else {
      this.alert.showErrorMessage("Extension Not Found!")
    }


  }


  viewMedia(data: any) {
    let fileUrl = '';
    if (data.fileMessage != null) {
      fileUrl = data.fileMessage.fileUrl;
      const EXT = fileUrl.substring(fileUrl.lastIndexOf(".") + 1)
      window.location.href = fileUrl;

    } else {
      return this.alert.showErrorMessage("File Not Found!")
    }

  }

  checkMimeType(extension: any) {
    console.log('extension',extension)
    var type: any;
    switch (MIME_TYPES) {
      case extension:
        type = MIME_TYPES;
        break;
      default:
        type = "c";
        break;
    }
  }
}

