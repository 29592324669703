import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationMain} from "../CommonObj/PaginationDao";
import {environment} from "../../environments/environment.prod";
import {Agent} from "../AgentSupport/AgentMaster/agent-master/AgentDao";
import {LanguageWiseAgent} from "./LanguageWiseAgent";
import {ChatbotMasterDao} from "../MicroServices/Chatbot/chatbot-master/ChatbotMasterDao";

@Injectable({
  providedIn: 'root'
})
export class LanguagewiseagentService {

  constructor(private http: HttpClient) {
  }


  getReport(accountId: number, offset: number, limit: number): Observable<PaginationMain<LanguageWiseAgent>> {
    // return this.http.get<PaginationMain<LanguageWiseAgent>>("http://monika-linux.dlink:8080/custom/v1/languagewiseagent/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<PaginationMain<LanguageWiseAgent>>(environment.appUrl + environment.customappurl + 'api/languagewiseagent/getall?accountId=' + accountId + "&offset=" + offset + "&limit=" + limit);
  }


  update(languageWiseAgent: LanguageWiseAgent): Observable<any> {
    // return this.http.post<Array<LanguageWiseAgent>>("http://192.168.1.43:8080/custom/v1/languagewiseagent/update",languageWiseAgent)

    return this.http.post<Array<Agent>>(environment.appUrl + environment.customappurl + 'api/languagewiseagent/update', languageWiseAgent)
  }

  insert(languageWiseAgent: LanguageWiseAgent): Observable<any> {
    // return this.http.post<Array<LanguageWiseAgent>>("http://192.168.1.43:8080/custom/v1/languagewiseagent/update",languageWiseAgent)

    return this.http.post(environment.appUrl + environment.customappurl + 'api/languagewiseagent/insert', languageWiseAgent,{responseType: 'text'})
  }

  deleteLanguage(id):Observable<any>{
    console.log("langauge id:",id)
    // return this.http.delete<Array<LanguageWiseAgent>>("http://192.168.1.43:8080/custom/v1/languagewiseagent/deletebyid?id="+id)

    return this.http.delete<Array<LanguageWiseAgent>>(environment.appUrl+environment.customappurl+'api/languagewiseagent/deletebyid?id='+id)
  }

}
