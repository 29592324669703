<div id="preloader">

  <div id="status"> </div>
</div>
<!-- Site wrapper -->
<div class="wrapper">
<app-header></app-header>
  <app-sidebar [menuJson]="user.menuJson"></app-sidebar>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="header-icon">
        <i ></i>
      </div>
      <div class="header-title">
        <h1 ></h1>
        <small></small>
      </div>
    </section>
    <!-- Main content -->
    <section class="content">

          <router-outlet></router-outlet>

    </section>
    <!-- /.content -->
  </div>
  <app-footer></app-footer>
</div>
