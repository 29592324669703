import {Component, Inject, OnInit} from '@angular/core';
import {TextSMSSendingDao} from "../client-sign-up/TextSMSSendingDao";
import {SubscriptionServiceService} from "../subscription/SubscriptionService/subscription-service.service";
import {HttpClient} from "@angular/common/http";
import {ClientSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {environment} from "../../environments/environment.prod";
import {SubScriptionPackageDao} from "../subscription-package/SubScriptionPackageDao";


@Component({
  selector: 'app-meta-credentials',
  templateUrl: './meta-credentials.component.html',
  styleUrls: ['./meta-credentials.component.css']
})
export class MetaCredentialsComponent implements OnInit {

  public visibleOtp: boolean = true;
  public otp: number = 0;
  public phoneOtp: number;
  public textSMSSendingDao: TextSMSSendingDao;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ClientSignUpDao, private dialogRef: MatDialogRef<MetaCredentialsComponent> ,private subscriptionService: SubscriptionServiceService, private http: HttpClient, private alert: AlertMessageService) {
  }

  ngOnInit(): void {
  }


  submit(){
  let verifyStatus = this.verifyOtp();
  if(verifyStatus ==true){
    this.subscriptionService.getByAccountId(this.data.id).subscribe(value => {
      value.cloudAPIWhatsappID = this.data.whatsappId;
      value.cloudAPIAppID = this.data.whatsappAppId;
      value.cloudAPIPhoneNumberID = this.data.whatsappPhoneId;
      value.cloudAPIToken = this.data.whatsappVerifiedToken;
      this.data.defaultSubscription = false;
      this.subscriptionService.updateSubsription(value).subscribe(value1 => {
        console.log("The Subscription Updated");
      }, error => {
        console.log("Error occured in adding Subscription", error);
      });
      this.http.post<ClientSignUpDao>(environment.appUrl + environment.whatsappOfficialservice +"api/clientsignup/update",this.data).subscribe(value2 => {
        console.log("The Client SignUp Page Updated");
        this.dialogRef.close();
        this.alert.showSuccesMessage("Details are added. Now Buy Subscription");
      }, error2 => {
        console.log("Error occured in updating ClientSignup Page", error2);
      });
    });
  }
  }
  sendOtp(number: string,otp:any, senderId: any) {
    this.visibleOtp = false;
    let msg = "OTP for the Login: "+otp+" PRPOTP";
      this.textSMSSendingDao = {msg:msg, number: number, senderId:senderId};
      this.http.post<TextSMSSendingDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a", this.textSMSSendingDao).subscribe(value => {
        this.alert.showSuccesMessage("Successfully OTP Send to Given Details");
      });
  }
  generateOtp(){
    let duplicateStatus = false;

    this.http.post<Array<ClientSignUpDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/getclientbywhatsappnumber?number="+this.data.whatsappNumber,{}).subscribe(value => {
      if (value.length > 0) {
        duplicateStatus = true;
        value = null;
      }
      if (duplicateStatus == false) {
        this.phoneOtp = Math.round((Math.random() * 1000000));
        this.sendOtp(this.data.whatsappNumber, this.phoneOtp, "PRPOTP");
      } else {
        this.alert.showErrorMessage("Number Already Exist");
      }
    });

  }
  verifyOtp(){
    let verifyStatus = false;
    if(this.phoneOtp == this.otp ){
      verifyStatus = true;
      return verifyStatus;
    }else{
      this.alert.showErrorMessage("Registered Number Otp Doesn't Matched");
      return verifyStatus;
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}
