import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router, Routes} from "@angular/router";
import {TransationDao} from "../transation-dao";
import {formatDate} from "@angular/common";
import {NbccserviceService} from "../nbccservice.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {FileService} from "../../../Services/FileUtilsService/FileService";

@Component({
  selector: 'app-payment-statuc',
  templateUrl: './payment-statuc.component.html',
  styleUrls: ['./payment-statuc.component.css']
})
export class PaymentStatucComponent implements OnInit {
  tableData: Array<TransationDao>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  private startdate: any;
  private enddate: any;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(private dialog:MatDialog,private route:ActivatedRoute,private service:NbccserviceService,private  alert:AlertMessageService,private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;

      console.log(this.startdate, + '  ' + this.enddate);
      this.loadDataforDate();
    });
  }

  filterDatafordate() {
    this.dialog.open(DatefilterComponent);


  }

  downloadReport() {


    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    // tdArr.push('Id');
    tdArr.push('Bungalow No');
    tdArr.push('Mobile No');
    tdArr.push('Amount');
    tdArr.push('Payment Mode');
    tdArr.push('Payment Id');
    tdArr.push('Payment Time');
    tdArr.push('Status');
    trArr.push(tdArr);
    this.service.getTransHistory(this.startdate, this.enddate).subscribe(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++)
      {
        tdArr = [];
        // tdArr.push(value[i].id);
        tdArr.push(value[i].bungalowN);
        tdArr.push(value[i].customerMobileNo);
        tdArr.push(value[i].amount);
        tdArr.push(value[i].paymentMode);
        tdArr.push(value[i].paymentID);
        tdArr.push(value[i].date);
        tdArr.push(value[i].respDescription);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,   'Payment_Transation_Report.xlsx');
    });
    this.alert.hideLoader();
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});

  }

  private loadDataforDate() {

    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined)  {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
      // this.loadData();
      console.log( 'Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
    }
    this.service.getTransHistory(this.startdate, this.enddate).subscribe(value => {
      this.tableData = value;
       this.alert.hideLoader();
    });
  }
}
