import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConversationSubscriptionDao} from '../../conversation/ConversationService/ConversationSubscriptionDao';
import {ConversationService} from '../../conversation/ConversationService/conversation.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ConversationwaunService} from './conversationwaun.service';

@Injectable({
  providedIn: 'root'
})
export class ConversationWaunSubscriptionService {
  private dataObsevable: BehaviorSubject<ConversationSubscriptionDao> = new BehaviorSubject(null);
  lastMessageTime: number = Number(localStorage.getItem('LASTINCOMINGMESSAGE'));

  constructor(private coversationService: ConversationwaunService, private alertMessage: AlertMessageService) {
    this.getData();
    const current = this;
    // tslint:disable-next-line:only-arrow-functions typedef
    setInterval(function(){
      current.getData();
    }, 10000);
  }
  // tslint:disable-next-line:typedef
  refreshChatAgain()
  {
    this.getData();
  }
  // tslint:disable-next-line:typedef
  getData()
  {
    this.coversationService.getConversationUnread().subscribe(value => {
      const tempObj: ConversationSubscriptionDao = {data: value, isNew: false, lastMessageTime: this.lastMessageTime};
      if (value.length > 0 && value[0].lastMessageTime > this.lastMessageTime)
      {
        tempObj.isNew = true;
        this.lastMessageTime = value[0].lastMessageTime;
        localStorage.setItem('LASTINCOMINGMESSAGE', String(this.lastMessageTime));
        this.alertMessage.playIncomingMessageSound();
      }
      this.dataObsevable.next(tempObj);
    });
  }

  getObj(): Observable<ConversationSubscriptionDao>
  {
    return this.dataObsevable.asObservable();
  }
}
