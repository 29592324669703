import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ChartDataDao} from '../../chart-and-tables/ChartDataDao';
import {GroupDao, SessionType, UserOrBuisnessIntiatedDao} from '../../CommonObj/GroupDao';
import {
  CommoninstancedropdownComponent
} from '../../Unofficial/commoninstancedropdown/commoninstancedropdown.component';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {ActiveSessionReportComponent} from "../../active-session-report/active-session-report.component";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {ConversationDao} from "../../conversation/ConversationDao";
import {formatDate} from "@angular/common";
import {GroupByDao} from "../../CampainService/GroupByDao";
import * as Chart from "chart.js";
import {MessageDao} from "../../ws-template/MessageDao";
import {SubscriptionServiceService} from "../../subscription/SubscriptionService/subscription-service.service";
import {SubscriptionDao} from "../../subscription/SubscriptionDao";
import {UserService} from "../../Services/User/user.service";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {SubscriptionPackageComponent} from "../../subscription-package/subscription-package.component";
import {ClientSignUpDao} from "../../client-sign-up/ClientSignUpDao";

const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Component({
  selector: 'app-waofficial-dash',
  templateUrl: './waofficial-dash.component.html',
  styleUrls: ['./waofficial-dash.component.css']
})
export class WaofficialDashComponent implements OnInit {
  ngOnInit(): void {
  }
  clientSignUpDao: ClientSignUpDao;
  userMasterDao: UserMasterDao;
  subscriptionDao: SubscriptionDao;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, order: []};
  panelOpenState = false;
  tableList: Array<GroupDao<string, string, string, string>>;
  table: number;
  fieldTable: Array<GroupByDao>
  activeSession: number = 0;
  chatside: any = null;
  type: any = null;
  // activeSession: number = 0;
  totalSession: number = 0;
  usrSession: string;
  bsnsSession: string;
  marketingSession: string;
  utilitySession: string;
  authenticationSession: string;
  startDate: any;
  endddate: any;
  reportBy:any;
  dlrstartdate: any;
  dlrenddate: any;
  userNumber: string;
  activeSessions: any;
  json: ConversationDao = {
    id: '',
    unread: false,
    lastMessageType: '',
    totalMessage: 0,
    lastMessage: '',
    lastMessageTime: 0,
    unreadMessage: 0,
    saved: false,
    contactName: ''
  };
  public campaignNameChart: any;
  public chartColors4: any[] = [
    {
      backgroundColor: [
        "#82a8cd", "#C1DAD6", "#82cda8", "#B7AFA3", "#ACD1E9", "#82cdcd", "#eedd82", "#F5FAFA", "#E8D0A9"

      ]
    }];

  chartData: ChartDataDao = null;
  messagedao: Array<MessageDao>;

  constructor(private dialog: MatDialog, private http: HttpClient, private alert: AlertMessageService, private router: Router,private subscriptionService:SubscriptionServiceService, private userService: UserService) {
    this.userMasterDao = this.userService.getUser();
    this.subscriptionService.getByAccountId(this.userMasterDao.id).subscribe(value => {
      this.subscriptionDao = value;
      console.log("The value of Subscription Dao is {}", this.subscriptionDao);
    });
    this.http.get<ClientSignUpDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/getbyid").subscribe(clientSignUpDao => {
      this.clientSignUpDao = clientSignUpDao;
    });


      this.tableList = [];
    this.clearFilter();

  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  filterData() {
    this.dialog.open(CommoninstancedropdownComponent, {data: []}).afterClosed().subscribe(value => {
      if (value != null) {
        this.startDate = value.startdate;
        this.endddate = value.enddate;
        this.reportBy=value.reportBy;
        console.log("value",this.reportBy)

        this.loadData();
      }
    });
  }

  clearFilter() {
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log('toddaydate' + todayDate);
    this.startDate = todayDate
    this.endddate = todayDate
    this.reportBy=0;
    this.loadData();
  }

  private async loadData() {
    this.totalSession = 0;
    this.activeSession = 0;
    this.usrSession = '0';
    this.bsnsSession = '0';
    this.authenticationSession = '0';
    this.marketingSession = '0';
    this.utilitySession = '0';
    this.alert.showLoader();
    this.startDate = this.startDate.split("-").join("");
    this.endddate = this.endddate.split("-").join("");


    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);


    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);


    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));


    if (this.startDate == this.endddate) {
      this.dlrstartdate = parseInt(formattedDateFirst);
      this.dlrenddate = parseInt(formattedDateLast);

    } else {
      this.dlrstartdate = this.startDate;
      this.dlrenddate = this.endddate;

    }

    if (this.startDate == undefined && isNaN(this.endddate) || this.endddate == undefined) {


      const formattedDateLasts = formatDate(yesterday, format, locale);
      // tslint:disable-next-line:radix
      this.startDate = formattedDateLasts;
      // tslint:disable-next-line:radix
      this.endddate = parseInt(formattedDateLast);


      // this.loadData();
    }

   console.log('report',this.reportBy)
    let chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
    this.activeSessions = await this.http.get<Array<GroupDao<string, string, string, string>>>(appPrefix + 'api/userorbsns/groupQuery?startdate=' + this.startDate + '&enddate=' + this.endddate + '&type=ACTIVESESSIONS').toPromise();
    //  let activeSessions = await this.http.get<Array<GroupDao<string, number>>>( 'sujit-HP-EliteBook-840-G3.Dlink:8082/wabuissness/v1/api/userorbsns/groupQuery?startdate='+this.startDate+'&enddate='+this.endddate+'&type=ACTIVESESSIONS').toPromise();
    this.tableList = await this.http.get<Array<GroupDao<string, string, string, string>>>(appPrefix + 'api/userorbsns/groupQuery?startdate=' + this.startDate + '&enddate=' + this.endddate + '&type=COUNTRYWISE').toPromise();
    let userOrBuissness = await this.http.get<Array<GroupDao<SessionType, string, string, string>>>(appPrefix + 'api/userorbsns/groupQuery?startdate=' + this.startDate + '&enddate=' + this.endddate + '&type=USERORBUISSNESS').toPromise();
    this.fieldTable = await this.http.get<Array<GroupByDao>>(appPrefix + 'messagereport/getdlr?startdate=' + this.startDate + '&enddate=' + this.endddate + '&chatside=User&type=template&reportBy='+this.reportBy).toPromise();
    // this.messagedao= await  this.http.get<Array<MessageDao>>(appPrefix+'messagereport/getreport?startdate='+dlrstartdate+'&enddate='+dlrenddate+'&chatside=User&type=template').toPromise();
    console.log('fieldData ', this.fieldTable);
    var acts = 0;
    for (var i = 0; i < this.activeSessions.length; i++) {
      acts++;
      // if(activeSessions[i].name=="dst")
      // {
      // this.activeSession=activeSessions[i].count;
      this.userNumber = this.activeSessions[i].name;

      //this.totalSession= this.totalSession+this.activeSessions.length;
    }
    this.activeSession = acts;
    for (var i = 0; i < userOrBuissness.length; i++) {

      if (userOrBuissness[i].name == SessionType.BUISSNESS) {
        this.bsnsSession = userOrBuissness[i].count;
      }else if (userOrBuissness[i].name == SessionType.AUTHENTICATION) {
        this.authenticationSession = userOrBuissness[i].count;
      }else if (userOrBuissness[i].name == SessionType.MARKETING) {
        this.marketingSession = userOrBuissness[i].count;
      }else if (userOrBuissness[i].name == SessionType.UTILITY) {
        this.utilitySession = userOrBuissness[i].count;
      } else if (userOrBuissness[i].name == SessionType.USER){
        this.usrSession = userOrBuissness[i].count;
      }
      // this.totalSession = Number(this.usrSession) + Number(this.bsnsSession);
      this.totalSession = Number(this.usrSession) + Number(this.authenticationSession) + Number(this.marketingSession) + Number(this.utilitySession);

      this.dtTrigger.next();
    }

    let dataNumber: Array<any> = [];
    let datelist: Array<any> = [];
    let labelList: Array<any> = [];

    for (var i = 0; i < this.fieldTable.length; i++) {
      labelList.push(this.fieldTable[i]._id);
      datelist.push(this.fieldTable[i].count);

    }
    this.createChartCampaignName(labelList, datelist);

    this.table = this.tableList.length;
    for (var i = 0; i < this.tableList.length; i++) {
      console.log("The value of Table List is ", this.tableList[i]);
      this.tableList[i].percentage = this.percentage(this.tableList[i].count, this.totalSession);
    }
    // chartDataDao.barChartData.push({
    //   data: dataNumber,
    //   label: 'Delivery Report',
    //   backgroundColor: '#3297a8',
    //   hoverBackgroundColor: '#328fa8',
    //   fill: true,
    //   borderColor: '#fb8170',
    //   barThickness: 40
    // });
    // chartDataDao.chartName = 'Month Wise Delivery Data';
    //
    this.chartData = chartDataDao;
    this.alert.hideLoader();

  }

  percentage(partialValue: string, totalValue: number): number {
    // @ts-ignore
    return Number(((100 * partialValue) / totalValue).toFixed(2));
  }

  async downloadReport() {
    this.alert.showLoader();
    let data = await this.http.get<Array<UserOrBuisnessIntiatedDao>>(appPrefix + 'api/userorbsns/gettrans?startdate=' + this.startDate + '&enddate=' + this.endddate + '&type=ACTIVESESSIONS').toPromise();
    var trArr = [];
    var tdArr = [];
    tdArr.push("Transaction Id");
    tdArr.push("Country Code");
    tdArr.push("Dst");
    tdArr.push("Session Type");
    tdArr.push("Session Start Time");
    tdArr.push("Session End Time");
    tdArr.push("User Message Count");
    tdArr.push("Buisness Message Count");

    trArr.push(tdArr);

    for (var i = 0; i < data.length; i++) {
      tdArr = [];
      tdArr.push(data[i].refId);
      tdArr.push(data[i].countryCode);
      tdArr.push(data[i].dst);
      tdArr.push(data[i].sessionType);
      tdArr.push(data[i].sessionStartTime);
      tdArr.push(data[i].sessionEndTime);
      tdArr.push(data[i].usrMessageCount);
      tdArr.push(data[i].bsnsMessageCount);

      trArr.push(tdArr);
    }

    let file = new FileService();
    file.generateFile(trArr, "SessionReport.xlsx")
    this.alert.hideLoader();
  }

  showActiveSession() {
    this.dialog.open(ActiveSessionReportComponent).afterClosed().subscribe(value => {

    })

  }

  openDataTable() {
    this.panelOpenState = true;
  }

  onClose() {
    this.panelOpenState = false;

  }

  TakeToMeNumber(user: any) {

    const id = user.name;
    this.json.id = user.name;

    this.router.navigate(['wabuissness/v1' + '/conversation'], {queryParams: this.json});

  }

  createChartCampaignName(campaignNameLabel, campaignNameData) {
    if (this.campaignNameChart != undefined) {
      this.campaignNameChart.destroy();
    }
    this.campaignNameChart = new Chart("campaignNameChart", {
      type: 'bar', //this denotes tha type of chart
      data: {// values on X-Axis
        labels: campaignNameLabel,


        datasets: [
          {
            data: campaignNameData,
            backgroundColor: this.chartColors4[0].backgroundColor,
            barThickness: 80,
            datalabels: {
              anchor: 'end',
              align: 'top'

            }

          }]
      },

      options: {
        responsive: true,
        legend: {
          display: false
        },
        onClick: (evt) => {
          var activePoints = this.campaignNameChart.getElementsAtEvent(evt);
          var labeltag = activePoints[0]._view.label;
          console.log("this.chart : ", activePoints, labeltag)

          // this.goToLeadPage('vendor',labeltag)
        },

//
        aspectRatio: 2.5,
        title: {
          text: "Day Wise DLR Report",
          display: true,
          fontSize: 15,
          fontColor: "Black",
          position: "top"
        },

        plugins: {
          datalabels: {
            color: 'black',

          }
        }
      }
    });
  }

  public async downloadDlrReport() {
    this.alert.showLoader();
    let data = await this.http.get<Array<MessageDao>>(appPrefix + 'messagereport/getreport?startdate=' + this.startDate + '&enddate=' + this.endddate + '&chatside=User&type=template&reportBy='+this.reportBy).toPromise();
    let leng = data.length;
    console.log("data",data)
    var trArr = [];
    var tdArr = [];
    tdArr.push("Id");
    tdArr.push("DST");
    tdArr.push("Report Type");

    tdArr.push("Message Type");
    tdArr.push("Template Name");

    tdArr.push("Message Status");
    tdArr.push("SENT TIME");
    tdArr.push("DLR TIME");
    tdArr.push("VIEW TIME");
    trArr.push(tdArr);
    console.log('datalength', data.length);
    for (let i = 0; i < leng; i++) {
      tdArr = [];
      tdArr.push(data[i].messageId);
      tdArr.push(data[i].to);
      // @ts-ignore
      console.log("ispanel",data[i].panel)
      // @ts-ignore
      if(data[i].panel){
        tdArr.push("System");
      }
      else{
        tdArr.push("API");
      }

      tdArr.push(data[i].type);
      tdArr.push(data[i].template.name);

      tdArr.push(data[i].messageStatus);
      tdArr.push(data[i].date);
      tdArr.push(data[i].viewTime);
      tdArr.push(data[i].dlrTime);
      trArr.push(tdArr);
    }

    let file = new FileService();
    file.generateFile(trArr, "DLReport.xlsx")
    this.alert.hideLoader();
  }

  recharge() {
    this.dialog.open(SubscriptionPackageComponent,{width: '140vw', maxHeight: '80vh'});
  }
}
