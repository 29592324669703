<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div id="tabview" class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadReport()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>
        </div>
      </div>
      <div  class="panel-body">
        <div class="table-responsive">
          <table id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Account </th>
              <th>Create Date</th>
              <th>Currency</th>
              <th>Order Id</th>
              <th>Payment Id</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let paymentDetailsDao of tableList;let ind = index">
              <tr>
                <td [innerHTML]="paymentDetailsDao.accountId"></td>
                <td [innerHTML]="paymentDetailsDao.createDate"></td>
                <td [innerHTML]="paymentDetailsDao.currency"></td>
                <td [innerHTML]="paymentDetailsDao.orderId"></td>
                <td [innerHTML]="paymentDetailsDao.paymentId"></td>
                <td [innerHTML]="paymentDetailsDao.amount"></td>
                <td *ngIf="paymentDetailsDao.paymentGatwayStatus == 'SUCCESFULL'" style="color: #0b3ca4"><i class="fa fa-check-circle" style="font-size:36px;color:green"></i></td>
                <td *ngIf="paymentDetailsDao.paymentGatwayStatus == 'FAILED' " style="color: red" ><i class="fa fa-times-circle" style="font-size:36px;color:red"></i></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
