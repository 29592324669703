import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import {UserMasterDao} from '../../Services/User/UserMasterDao';

@Component({
  selector: 'app-datefilter',
  templateUrl: './datefilter.component.html',
  styleUrls: ['./datefilter.component.css']
})
export class DatefilterComponent implements OnInit {
  startdate: string;
  enddate: string;

  constructor(private router: Router, private user: UsermasterService,private route:ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.startdate.split('-').join('')

    this.route.queryParams.subscribe(queryParams => {
      const year =  queryParams['startdate'].slice(0, 4);
      const month =  queryParams['startdate'].slice(4, 6);
      const day =  queryParams['startdate'].slice(6, 8);
      const year1 =  queryParams['enddate'].slice(0, 4);
      const month1 =  queryParams['enddate'].slice(4, 6);
      const day1 =  queryParams['enddate'].slice(6, 8);
      const formattedStartDate = `${year}-${month}-${day}`;
      const formattedEndDate = `${year1}-${month1}-${day1}`;


      this.startdate = formattedStartDate;
      this.enddate = formattedEndDate;

    });






  }

  // tslint:disable-next-line:typedef
  filter() {
    console.log("startdate",this.startdate)
    this.router.navigate([], {queryParams: {startdate: this.startdate.split('-').join(''), enddate: this.enddate.split('-').join('')}});
  }
}
