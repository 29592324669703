import { Component, OnInit } from '@angular/core';
import {RoleMasterDao} from './RoleMasterDao';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {RoleServiceService} from './RoleService/role-service.service';
import {AlertMessageService} from '../alert-message/alert-message.service';

@Component({
  selector: 'app-rolemaster',
  templateUrl: './rolemaster.component.html',
  styleUrls: ['./rolemaster.component.css']
})
export class RolemasterComponent implements OnInit {
  addNewDiv:boolean=false;

  roleDao:RoleMasterDao={id: 0, roleName: '', status: true}
  tableList:ClientSidePagination<RoleMasterDao>={data: [], end: 0, start: 0, total: 0}
  constructor(private roleService:RoleServiceService,private alert:AlertMessageService) {

   this.getData();
  }
  getData()
  {
    this.roleService.getRoles().subscribe(value => {
      this.tableList={data:value, end: 10, start: 0, total: value.length}
    })
  }
  ngOnInit(): void {
  }

  isOrUpdate() {
    this.alert.showLoader()
    this.alert.confirmMessage("Save Role?","Are you sure?").then(value => {
      this.roleService.saveRole(this.roleDao).subscribe(value1 => {

        this.getData();
        this.alert.showSuccesMessage("Successfully saved Role!!")
        this.cancel()
        this.alert.hideLoader()
      },error => {
        this.alert.showErrorMessage("Duplicate Role Name!!")
        this.alert.hideLoader()
      })
    },)

  }

  cancel() {
    this.addNewDiv=false;
    this.roleDao={id: 0, roleName: '', status: true};
  }

  edit(data: RoleMasterDao, ind: number) {
    this.roleDao=data;
    this.addNewDiv=true;


  }

  addNew() {
    this.addNewDiv=true;
  }
}
