import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FeatureDao} from './feature-dao';
import {environment} from '../../environments/environment.prod';
import {PageDao} from './page-dao';
const appPrefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class OperationDaoService {

  constructor(private http: HttpClient) { }

  getFeaurs(): Observable<Array<FeatureDao>>
  {
    return this.http.get<Array<FeatureDao>>(appPrefix + 'auth/v1/api/features/get')
  }

  addFreatures(data: FeatureDao) : Observable<any>
  {
    return this.http.post(appPrefix +'auth/v1/api/features/', data);
  }

  update(data: FeatureDao) : Observable<any>
  {
    return this.http.patch(appPrefix +'auth/v1/api/features/update', data);
  }

  addPagePErmission(data: PageDao):Observable<any>
  {
    return this.http.post(appPrefix + 'auth/v1/api/pagepermission/',data)

  }

  updatePage(data: PageDao):Observable<any>
  {
    return this.http.patch(appPrefix + 'auth/v1/api/pagepermission/update',data)

  }

  getbyPageNameAndAccountId(id:number,pageName:string):Observable<PageDao>
  {
    return this.http.get<PageDao>(appPrefix +'auth/v1/api/pagepermission/getByPageName?pageName='+pageName+'&accountId='+id)
  }

  delete(id:number):Observable<any> {

    return this.http.delete(appPrefix + 'auth/v1/api/features/delete?id='+id);
  }
}
