<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

    </div>
          <button  (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
<!--      <button  (click)="loadData()" class="btn btn-add">Show All<i class="fa fa-remove"></i></button>-->

  </div>

      <div style="" class="panel-body">

        <div  class="table-responsive">


          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover" >
            <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Campaign Date</th>
              <th>Count</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr >
                <td [innerHTML]="user.name"></td>
                <td [innerHTML]="(user.date | slice:6:8)+('-')+(user.date | slice:4:6)+('-')+(user.date | slice:0:4)"></td>
                <td [innerHTML]="user.count"></td>
                <td></td>
                <td>
                 <!-- <i title="Click Here To Edit " (click)="drawObdReport(user,ind)"
                       class="btn btn-add fa fa-bar-chart "></i>-->
                  <i title="Download Report" (click)="downloadReport(user,ind)"
                     class="btn btn-add fa fa-download "></i>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
