<div class="login-wrapper" >
  <div class="container-center" >
    <div class="login-area" >
      <div class="panel panel-bd panel-custom"  >
        <div class="panel-heading"  >
          <div class="view-header"  >
            <div class="header-icon">
              <i class="fa-solid fa-unlock"></i>

            </div>
            <div class="header-title">
              <h3>Login</h3>
              <small><strong>Please enter your credentials to login.</strong></small>

<!--              <h3 style="margin-left: 20px">&nbsp;&nbsp;&nbsp;&nbsp;PRP</h3>-->
<!--              <small><strong>Services Private Limited</strong></small>-->
            </div>

          </div>
        </div>
        <div class="panel-body">
<!--          <div style="text-align: center;text-decoration: underline;font-size: 18px"> <b>Login</b></div><br>-->
          <form ngNativeValidate (submit)="login()">
            <div class="form-group">
              <label   class="control-label" for="username">Username</label>
              <input   type="text" placeholder="example@gmail.com" title="Please enter you username" required [(ngModel)]="user.username" name="username" id="username" class="form-control">

            </div>
            <div class="form-group">
              <label class="control-label" for="password">Password</label>
              <input   type="password" title="Please enter your password" placeholder="******" required  [(ngModel)]="user.password"name="password" id="password" class="form-control">

            </div>

            <div class="form-group">

              <label class="control-label" for="password">Enter Total Of Following</label>
              <input   tabIndex="-1" id="number1" name="number1" class="calculatediv" [(ngModel)]="firstNumber" type="number" readonly="readonly"  value=""><span class="calculatediv">+</span> <input   tabIndex="-1" id="number2" name="number2" [(ngModel)]="secondNumber" class="calculatediv" readonly="readonly" type="number" value="">

            </div>

            <div  class="form-group">
              <label class="control-label" for="password">Enter Total</label>
              <input   type="number" [(ngModel)]="totalNumber"   placeholder="Enter Total" required="" value="" name="total"  class="form-control">
              <span *ngIf="isError" style="text-align: center;
    font-weight: 900;
    font-size: 14px;
    color: red;" class="help-block small">{{errorMessage}} <i class="fa fa-lock"></i></span>
            </div>
            <div class="form-group"><br>
            <button style=" background-color: #b1d3db;
            border-radius: 50px;
    border-color: #b1d3db;
    width: 100%;
    color: black;
    font-weight: 900;" class="btn btn-success">Login <i class="fa fa-unlock-alt"></i></button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>



