import { Component, OnInit } from '@angular/core';
import {AccountOnKarixService} from "./account-on-karix.service";
import {AccountOnKarixDao} from "./AccountOnKarixDao";
import {CloudTemplateMasterDao} from "../whatsapp-cloud-template-master/CloudTemplateMasterDao";
import {Subject} from "rxjs";
import {EmbeddedSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {AlertMessageService} from "../alert-message/alert-message.service";

@Component({
  selector: 'app-account-on-karix',
  templateUrl: './account-on-karix.component.html',
  styleUrls: ['./account-on-karix.component.css']
})
export class AccountOnKarixComponent implements OnInit {
   limit: number;
   offset: number;

  constructor(private accountOnKarixService:AccountOnKarixService,private alert:AlertMessageService) {
    this.loadData(null);
  }
  accountOnKarixDao: AccountOnKarixDao = {
    userName:'',
    mobileno:'',
    email:'',
    createDate:'',
    token:'',
    api_key:'',
    toUsername:'',
    waba_id:'',
    phone_display_id:'',
    sender_id:'',
    display_name:'',
    channel:''
  }
  addNewDiv: boolean = false;
  tableList: Array<AccountOnKarixDao> = null;
  dtOptions: any = {bDestroy: true, order: []};
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnInit(): void {
  }

  test() {
    this.accountOnKarixService.create(this.accountOnKarixDao).subscribe(value =>{
      console.log("The value is "+ value);
    });
  }

  isOrUpdate() {
    this.accountOnKarixService.create(this.accountOnKarixDao).subscribe(value =>{
      console.log("The value is "+ value);
      this.clearData();
    });
  }
  clearData(){
    this.accountOnKarixDao = {
      userName:'',
      mobileno:'',
      email:'',
      createDate:'',
      token:'',
      api_key:'',
      toUsername:'',
      waba_id:'',
      phone_display_id:'',
      sender_id:'',
      display_name:'',
      channel:''
    }
    this.addNewDiv = false;
  }

  loadData(event:any){

    if(event==null){
      this.offset = 0
      this.limit = 5000;
    }else{
      this.offset = event.pageIndex;
      this.limit = event.pageSize;
    }
    this.accountOnKarixService.getAll(this.offset,this.limit).subscribe(value =>{
      console.log("The value is "+ value);
      this.tableList = value.data;

      this.dtTrigger.next();
    });
  }

  addNew() {
    this.addNewDiv = true;
  }

  cancel() {
    this.addNewDiv = false;
  }

  getDetails() {
    if(this.accountOnKarixDao.waba_id.length>10){
      this.accountOnKarixService.getEmbeddedSignUpDaoByWabaId(this.accountOnKarixDao.waba_id).subscribe(value => {
        console.log("The valye is "+ value);
        this.accountOnKarixDao.mobileno = value.contactNumber,
          this.accountOnKarixDao.email = value.email,
          this.accountOnKarixDao.userName = value.name,
          this.accountOnKarixDao.waba_id = value.wabaId,
          this.accountOnKarixDao.phone_display_id = value.phoneNumberId,
          this.accountOnKarixDao.display_name = value.displayName,
          this.accountOnKarixDao.sender_id = value.whatsappNumber
      });
    }else{
      this.alert.showErrorMessage("Waba ID is not valid");
    }
  }
}
