import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {UserMasterDao} from "../Services/User/UserMasterDao";
import {UserService} from "../Services/User/user.service";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {ClientSignUpDao, EmbeddedSignUpDao} from "./ClientSignUpDao";
import {CountryWisePriceListDao} from "./CountryWisePriceDaoList";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {OtpVerificationComponent} from "../otp-verification/otp-verification.component";
import {UsermasterService} from "../Services/UsermasterService/usermaster.service";
import {environment} from "../../environments/environment.prod";
import {ClientSignUpService} from "./client-sign-up.service";
import {ClientDetailsComponent} from "../client-details/client-details.component";
const  appPrefix = environment.appUrl + environment.authMicroservice;
declare const FB: any;

@Component({
  selector: 'app-client-sign-up',
  templateUrl: './client-sign-up.component.html',
  styleUrls: ['./client-sign-up.component.css']
})
export class ClientSignUpComponent implements OnInit{


  user: UserMasterDao = {} as UserMasterDao;
  isError = false;
  clientNumberstatus: boolean = false;
  getPhoneOtp: boolean = true;
  phoneVerifyButtonStatus:boolean = true;
  clientWhatsappNumberStatus: boolean = false;
  getWhatsappNumberOtp: boolean = true;
  WhatsappNumberVerifyButtonStatus:boolean = true;
  countryWithPricesList: Array<CountryWisePriceListDao> = [];
  embeddedSignUp:EmbeddedSignUpDao = {
    tempToken:'',
    businessToken:'',
    wabaId:'',
    phoneNumberId:'',
    name:'',
    email:'',
    contactNumber:'',
    displayName:'',
    whatsappNumber:''
  };
  clientSignUpDao: ClientSignUpDao = {
    id:0,
    active:true,
    whatsappVerifyStatus: false,
    facebookVerifyStatus: false,
    createAt:"",
    createBy:"20004",
    password:"",
    email:"",
    accountId:0,
    clientName:"",
    companyName:"",
    clientNumber:"",
    verifyPhoneStatus:false,
    country:"IND",
    whatsappPhoneId:"",
    whatsappAppId:"",
    whatsappVerifiedToken:"",
    whatsappId:"",
    whatsappNumber:"",
    verifyEmailStatus:true,
    defaultSubscription:true,
    templateId:"67a485a8-a2f0-4cf0-96c0-1f9b9537ba5e",
    // templateId:"3d51a307-b355-427c-ad0c-bcc0e6d77e9a",
    authToken:"",
    clientType:'DEMO'
  };
  phoneOtp: number;
  whatsappOtp: number;
  duplicateStatus: boolean = false;
  userMasterDao: UserMasterDao = {} as UserMasterDao;
  authToken: string;

  constructor(private clientSignUpService: ClientSignUpService, private elementRef: ElementRef, private dialog: MatDialog, private http: HttpClient, private alertService: AlertMessageService, private userMasterService:UsermasterService, private userService: UserService) {

  }

  ngOnInit(): void {
    this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/countrywisepricelist/getall").subscribe(value => {
      this.countryWithPricesList = value;
    },error => {
      console.log("Error occured", error );
    });
    const formData = new FormData();
    formData.append('username', "20004");
    // for prod
    formData.append('password', "fu5Jxd646Y(j5h;");
    // for test
    // formData.append('password', "TFQGVDGiz9FWISt");
    formData.append('grant_type', 'password');
    formData.append('client_secret', environment.clientSecret);
    formData.append('client_id', environment.clientId);
    // tslint:disable-next-line:max-line-length
    this.http.post<any>(appPrefix + 'oauth/token', formData, {headers: {Authorization: 'Basic ' + environment.headerToken}}).subscribe(access_token=>{
      console.log("The value of Access token is", access_token);
      this.authToken = access_token.access_token;
      this.clientSignUpDao.authToken = access_token.access_token;
    });
  }

  login() {
    window.location.replace('/');
  }
  checkDuplicate(){
    this.http.post<Array<ClientSignUpDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/getclient?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a",this.clientSignUpDao).subscribe(value => {
      if(value.length>0){
        this.duplicateStatus = true;
        value=null;
      }
      if(this.clientSignUpDao.defaultSubscription == false && this.clientSignUpDao.whatsappNumber.match(/^[0-9]+$/) && this.duplicateStatus == false){
        this.dialog.open(OtpVerificationComponent,{width: '400px',height:'50%',data:this.clientSignUpDao}).afterClosed().subscribe(value1 => {
          if(value1.id != 0){
            this.clearData();
          }
        });
      }else if(this.clientSignUpDao.defaultSubscription == true && this.duplicateStatus == false){
        this.dialog.open(OtpVerificationComponent,{width: '400px',height:'50%',data:this.clientSignUpDao}).afterClosed().subscribe(value1 => {
          if(value1.id != 0){
            this.clearData();
          }
        });
      }else if(this.duplicateStatus == true){
        this.alertService.showErrorMessage("Number Already Exist");
        this.duplicateStatus = false;
      }else{
        this.alertService.showErrorMessage("Whatsapp Number not Valid");
      }
    });
  }
  signUp() {
    if(this.clientSignUpDao.clientNumber.match(/^[0-9]+$/) ){
      this.checkDuplicate();
    }else {
      this.alertService.showErrorMessage("Number not Valid");
    }
  }

  clearData() {
    this.clientSignUpDao = {
      id:0,
      active:true,
      whatsappVerifyStatus: false,
      facebookVerifyStatus: false,
      createAt:"",
      createBy:"",
      password:"",
      email:"",
      accountId:0,
      clientName:"",
      companyName:"",
      clientNumber:"",
      verifyPhoneStatus:false,
      country:"IND",
      whatsappPhoneId:"",
      whatsappAppId:"",
      whatsappVerifiedToken:"",
      whatsappId:"",
      whatsappNumber:"",
      verifyEmailStatus:false,
      defaultSubscription:true,
      templateId:"43d344f2-4032-4900-93c6-f237a09b671e",
      authToken:this.authToken,
      clientType: 'DEMO'
    };
    this.embeddedSignUp = {
      tempToken:'',
      businessToken:'',
      wabaId:'',
      phoneNumberId:'',
      name:'',
      email:'',
      contactNumber:'',
      displayName:'',
      whatsappNumber:''
    };
    this.phoneOtp = 0;
    this.whatsappOtp = 0;
    this.clientNumberstatus = false;
    this.phoneVerifyButtonStatus = true;
    this.getPhoneOtp = true;
    this.clientWhatsappNumberStatus = false;
    this.getWhatsappNumberOtp = true;
    this.WhatsappNumberVerifyButtonStatus = true;
    this.duplicateStatus = false;

  }

  showSignUpDoc() {
    window.open(environment.driveLocationUrl + "Shareablelinks/20004/16697/finally.pdf");
  }

  showFacebookDoc() {
    window.open(environment.driveLocationUrl + "Shareablelinks/20004/16699/Facebook verification guide.pdf");
  }

  showVerfiedWhatsappDoc() {
    window.open(environment.driveLocationUrl + "Shareablelinks/20004/16698/Whatsapp CLOUDAPI (1) (1).pdf");
  }
  onSignupClick() {
    this.clientSignUpService.launchWhatsAppSignup();
  }

  test() {
    this.embeddedSignUp.tempToken = 'ef38wefjkwfowefweie';
    this.embeddedSignUp.wabaId = 'chwefjwefwejew';
    this.dialog.open(ClientDetailsComponent,{
      width:"100%",
      height:"75%",
      data:this.embeddedSignUp,
      disableClose: true,
    }).afterClosed().subscribe(value => {
      console.log("dialog is ",value);
      this.clearData();
    });
    console.log("Te method hits after");
  }
}
