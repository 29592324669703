<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadReport()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>
        </div>
      </div>
<!--      <div style="" class="panel-body">-->
<!--        <div class="table-responsive">-->
<!--          <table  id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>Date</th>-->
<!--              <th>Phone Number</th>-->
<!--              <th>Message Type</th>-->
<!--              <th>Message Content</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <ng-container *ngFor="let messageDao of tableList;let ind =index">-->
<!--              <tr>-->
<!--                <td [innerHTML]="messageDao.date"></td>-->
<!--                <td [innerHTML]="messageDao.to"></td>-->
<!--                <td [innerHTML]="messageDao.type"></td>-->
<!--                <td *ngIf="messageDao.type == 'text'">{{messageDao.text.body}}</td>-->
<!--                <td *ngIf="messageDao.type == 'image'">{{messageDao.image.link}}  <span *ngIf="messageDao.image.caption != null" style="font-weight: bold"> Caption :- {{messageDao.image.caption}}</span>  </td>-->
<!--                <td *ngIf="messageDao.type == 'video'" >{{messageDao.video.link}}    <span *ngIf="messageDao.video.caption != null" style="font-weight: bold"> Caption :- {{messageDao.video.caption}}</span> </td>-->
<!--                <td *ngIf="messageDao.type == 'audio'" >{{messageDao.audio.link}}    <span *ngIf="messageDao.audio.caption != null" style="font-weight: bold"> Caption :- {{messageDao.audio.caption}}</span> </td>-->
<!--                <td *ngIf="messageDao.type == 'document'" >{{messageDao.document.link}}  <span *ngIf="messageDao.document.caption != null" style="font-weight: bold"> Caption :- {{messageDao.document.caption}}</span> </td>-->
<!--                <td *ngIf="messageDao.type == 'location'" >Longitude:- {{messageDao.location.longitude}} Latitude:- {{messageDao.location.latitude}}   </td>-->
<!--                <td *ngIf="messageDao.type == 'interactive' && messageDao.interactive.button_reply != null" >{{messageDao.interactive.button_reply.title}}</td>-->
<!--                <td *ngIf="messageDao.type == 'interactive' && messageDao.interactive.list_reply != null" >{{messageDao.interactive.list_reply.title}}</td>-->
<!--                <td *ngIf="messageDao.type == 'button'" >{{messageDao.button.text}}</td>-->
<!--                <td *ngIf="messageDao.type == 'ORDER'" >{{messageDao.type}}</td>-->
<!--              </tr>-->

<!--            </ng-container>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
      <div id="tablediv">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Message Type</th>
                            <th>Message Content</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: center" *ngIf="tableList.total==0">
              <td colspan="14">No Entry</td>
            </tr>
            <ng-container *ngFor="let messageDao of tableList.data;let ind = index">
              <tr>
                <td [innerHTML]="messageDao.date"></td>
                <td [innerHTML]="messageDao.profileName"></td>
                                <td [innerHTML]="messageDao.to"></td>
                                <td [innerHTML]="messageDao.type"></td>
                                <td *ngIf="messageDao.type == 'text'">{{messageDao.text.body}}</td>
                                <td *ngIf="messageDao.type == 'image'">{{messageDao.image.link}}  <span *ngIf="messageDao.image.caption != null" style="font-weight: bold"> Caption :- {{messageDao.image.caption}}</span>  </td>
                                <td *ngIf="messageDao.type == 'video'" >{{messageDao.video.link}}    <span *ngIf="messageDao.video.caption != null" style="font-weight: bold"> Caption :- {{messageDao.video.caption}}</span> </td>
                                <td *ngIf="messageDao.type == 'audio'" >{{messageDao.audio.link}}    <span *ngIf="messageDao.audio.caption != null" style="font-weight: bold"> Caption :- {{messageDao.audio.caption}}</span> </td>
                                <td *ngIf="messageDao.type == 'document'" >{{messageDao.document.link}}  <span *ngIf="messageDao.document.caption != null" style="font-weight: bold"> Caption :- {{messageDao.document.caption}}</span> </td>
                                <td *ngIf="messageDao.type == 'location'" >Longitude:- {{messageDao.location.longitude}} Latitude:- {{messageDao.location.latitude}}   </td>
                                <td *ngIf="messageDao.type == 'interactive' && messageDao.interactive.button_reply != null" >{{messageDao.interactive.button_reply.title}}</td>
                                <td *ngIf="messageDao.type == 'interactive' && messageDao.interactive.list_reply != null" >{{messageDao.interactive.list_reply.title}}</td>
                                <td *ngIf="messageDao.type == 'button'" >{{messageDao.button.text}}</td>
                                <td *ngIf="messageDao.type == 'ORDER'" >{{messageDao.type}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <mat-paginator (page)="this.loadData($event)" [length]=tableList.total
                       [pageSize]="10"
                       [pageSizeOptions]="[10, 20, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
