import { Component, OnInit } from '@angular/core';
import {ScheduleReportDao} from './schedule-report-dao';
import {MailgroupsService} from '../mailgroup/mailgroups.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';

@Component({
  selector: 'app-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrls: ['./schedule-report.component.css']
})
export class ScheduleReportComponent implements OnInit {
  dtTrigger: any;
  dtOptions: any;
  tableList: Array<ScheduleReportDao>;

  constructor(private servie: MailgroupsService, private  alert: AlertMessageService) {  }

  ngOnInit(): void {

    this.getAllData();
  }

  // tslint:disable-next-line:typedef
  getAllData()
  {
      this.servie.getScheduleReport().subscribe(value => {
        this.tableList = value;
        console.log('Schedule Report :: {}', value);
      });
  }
  // tslint:disable-next-line:typedef
  delete(id: number) {
    console.log('deleted id :: {}', id);
    this.alert.confirmMessage('Delete ?', 'Are you sure?').then(value => {
      // tslint:disable-next-line:no-shadowed-variable
      this.servie.deleteSchedule(id).subscribe(value => {
         console.log('value is', value);
         console.log('delete id', id);
         window.location.reload();
      });

    });
  }
}
