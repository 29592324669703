import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ContactFormDao} from '../../../../conversation/contact-form/ContactFormDao';
import {ChatBotDetailsDao} from '../ChatbotDao';

@Component({
  selector: 'app-emasol-transaction',
  templateUrl: './emasol-transaction.component.html',
  styleUrls: ['./emasol-transaction.component.css']
})
export class EmasolTransactionComponent implements OnInit {
  totalCart: number=0.00;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<ChatBotDetailsDao>) { }

  ngOnInit(): void {
    for(var i=0;i<this.data.length;i++)
    {

     let totalRow= this.data[i].qty*this.data[i].rate;
     console.log("this is total",totalRow)
      this.totalCart=Number(this.totalCart+(totalRow))
    }
  }

}
