<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div id="addnewdiv" hidden>
      <div>
        <h1>Add Subscription Package</h1>
      </div>
        <form ngNativeValidate>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Plan Type</label>
            <select class="form-control" name="planType" required [(ngModel)]="subScriptionPackageDao.planType">
              <option [value]="PlanType.BASIC">BASIC</option>
              <option [value]="PlanType.STANDARD">STANDARD</option>
              <option [value]="PlanType.ADVANCED">ADVANCED</option>
            </select>
          </div>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Subscription Package Type</label>
            <select class="form-control" name="subscriptionPackageType" required [(ngModel)]="subScriptionPackageDao.subscriptionPackageType">
              <option [value]="SubscriptionPackageType.NORMAL">NORMAL</option>
              <option [value]="SubscriptionPackageType.RECOMMENDED">RECOMMENDED</option>
              <option [value]="SubscriptionPackageType.ONLYCREDIT">ONLYCREDIT</option>
            </select>
          </div>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Expiry Date</label>
            <select class="form-control" name="expiryDateType" required [(ngModel)]="subScriptionPackageDao.expireyDateType">
              <option [value]="ExpiryDateType.MONTHLY">MONTHLY</option>
              <option [value]="ExpiryDateType.QUARTERLY">QUARTERLY</option>
              <option [value]="ExpiryDateType.SIXMONTH">SIXMONTH</option>
              <option [value]="ExpiryDateType.YEARLY">YEARLY</option>
            </select>
          </div>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Country</label>
            <select type="text" class="form-control" [(ngModel)]="subScriptionPackageDao.country" name="country" required>
              <ng-container *ngFor="let countryDao of this.countryWithPricesList">
                <option [value]="countryDao.countryCode" [innerHTML]="countryDao.country"></option>
              </ng-container>
            </select>
          </div>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Rental Amount</label>
            <input type="number" class="form-control" [(ngModel)]="subScriptionPackageDao.rentalAmount" name="rentalAmount" required>
          </div>
          <div class="form-group col-lg-6">
            <label> <sup class="required">*</sup>Recharge Amount</label>
            <input type="number" class="form-control" [(ngModel)]="subScriptionPackageDao.rechargeAmount" name="rechargeAmount" required>
          </div>
          <div class="form-group col-lg-3">
            <label> <sup class="required">*</sup>Marketing Rates</label>
            <input type="number" class="form-control" [(ngModel)]="subScriptionPackageDao.marketingRates" name="marketingRates" required>
          </div>
          <div class="form-group col-lg-3">
            <label> <sup class="required">*</sup>Authentication Rates</label>
            <input type="number" class="form-control" [(ngModel)]="subScriptionPackageDao.authenticationRates" name="authenicationRates" required>
          </div>
          <div class="form-group col-lg-3">
            <label> <sup class="required">*</sup>Utility Rates</label>
            <input type="number" class="form-control" [(ngModel)]="subScriptionPackageDao.utilityRates" name="utilityRates" required>
          </div>
          <div class="form-group col-lg-3">
            <label> <sup class="required">*</sup>User Initiated Rates</label>
            <input  type="number"  class="form-control" [(ngModel)]="subScriptionPackageDao.userInitiatedRates" name="userInitiatedRates" required>
          </div>
          <div class="form-group col-lg-12">
            <label> <sup class="required">*</sup>Description</label>
            <textarea  type="text"  class="form-control" [(ngModel)]="subScriptionPackageDao.remarks" name="remarks" required></textarea>
          </div>
          <div class="form-group col-lg-12">
            <button type="submit" class="btn btn-add" (click)="addSubscription()">Submit</button>
            <button title="Cancel" (click)="cancel()" class="btn btn-danger">Cancel</button>
          </div>
        </form>
    </div>
    <div id="tabview" class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="openSubscription()" class="btn btn-add">Add Subscription<i class="fa fa-plus"></i></button>
          <button (click)="filterData()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
        </div>
      </div>
      <div  class="panel-body">
        <mat-slide-toggle color="primary" [(ngModel)]="this.status" (click)="getByStatus(!this.status)">ACTIVE </mat-slide-toggle>
        <div class="table-responsive">
          <table id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Create Date</th>
              <th>Expiry Date</th>
              <th>Subscription Type</th>
              <th>Plan Type</th>
              <th>Rental Amount</th>
              <th>Recharge Amount</th>
              <th>Status</th>
              <th>Subscription Amount</th>
              <th>Total Amount</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let subScriptionPackageDao of tableList;let ind = index">
              <tr>
                <td [innerHTML]="subScriptionPackageDao.createDate"></td>
                <td [innerHTML]="subScriptionPackageDao.expireyDateType"></td>
                <td [innerHTML]="subScriptionPackageDao.subscriptionPackageType"></td>
                <td [innerHTML]="subScriptionPackageDao.planType"></td>
                <td [innerHTML]="subScriptionPackageDao.rentalAmount"></td>
                <td [innerHTML]="subScriptionPackageDao.rechargeAmount"></td>
                <td><mat-slide-toggle color="primary" (click)="changeStatus(subScriptionPackageDao)" [(ngModel)]="subScriptionPackageDao.status" name="Active"></mat-slide-toggle></td>
                <td [innerHTML]="subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount"></td>
                <td [innerHTML]="subScriptionPackageDao.totalAMount"></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
