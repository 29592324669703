import {Component, Inject, OnInit} from '@angular/core';
import {SubScriptionPackageDao} from "../SubScriptionPackageDao";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {ClientSignUpDao} from "../../client-sign-up/ClientSignUpDao";
import {environment} from "../../../environments/environment.prod";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {MetaCredentialsComponent} from "../../meta-credentials/meta-credentials.component";
import {PaymentDetailsService} from "../../payment-details/payment-details.service";
import {PaymentDetailsDao, PaymentGatway, PaymentGatwayStatus} from "../../payment-details/PaymentDetailsDao";
import {UserService} from "../../Services/User/user.service";
import {ExpiryDateType} from "../ExpiryDateType";
import {SubscriptionPackageType} from "../SubscriptionPackageType";
// import * as Razorpay from 'razorpay';

declare var Razorpay: any

@Component({
  selector: 'app-subscription-details-show',
  templateUrl: './subscription-details-show.component.html',
  styleUrls: ['./subscription-details-show.component.css']
})
export class SubscriptionDetailsShowComponent implements OnInit {
  tableList: Array<SubScriptionPackageDao>;
  paymentDetailsDao: PaymentDetailsDao = {
    id: '',
    name: '',
    keyId: '',
    keySecret: '',
    amount:0,
    currency: '',
    accountId:0,
    createDate: '',
    createDateFilter:0,
    orderId: '',
    paymentId: '',
    paymentGatway: PaymentGatway.RAZORPAY,
    signature: '',
    paymentGatwayStatus: PaymentGatwayStatus.PENDING,
  };
  constructor(private userService: UserService, private paymentService: PaymentDetailsService, private dialog: MatDialog, private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: SubScriptionPackageDao, private dialogRef: MatDialogRef<SubscriptionDetailsShowComponent>, private alert: AlertMessageService) {
    this.tableList = [data];
  }

  ngOnInit(): void {
  }

  submit() {
    this.subscriptionButtonStatus = true;
    this.http.get<ClientSignUpDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/getbyid").subscribe(clientSignUpDao => {
      if(clientSignUpDao != null && clientSignUpDao.defaultSubscription == true ){
        this.dialog.open(MetaCredentialsComponent, {data: clientSignUpDao, width:'70vh'}).afterClosed().subscribe(value => {    this.subscriptionButtonStatus = false;
        });
      }else if(clientSignUpDao != null && clientSignUpDao.defaultSubscription == false){
          this.paymentDetailsDao.amount = this.data.totalAMount;
          this.paymentService.insertPaymentDetails(this.paymentDetailsDao).subscribe(paymentDetails => {
              var options = {
                order_id:paymentDetails.orderId,
                key_id: paymentDetails.keyId,
                amount: paymentDetails.amount,
                currency: paymentDetails.currency,
                name: 'PRP SERVICES PVT LTD',
                description:'Payment for buying Subscription',
                image: environment.driveLocationUrl + 'Shareablelinks/20004/3423/PRP-LOGO.png',
                handler:(response:any)=>{
                  console.log('the response is', response);
                  if(response != null || response != undefined){
                    paymentDetails.paymentId = response.razorpay_payment_id;
                    paymentDetails.orderId = response.razorpay_order_id;
                    paymentDetails.signature = response.razorpay_signature;
                    this.paymentService.updatePaymentDetails(paymentDetails).subscribe(paymentDetailsDap =>{
                      this.data.accessToken = localStorage.getItem("accessToken");
                      this.http.post<SubScriptionPackageDao>(environment.appUrl + environment.whatsappOfficialservice + "api/subscriptionpackage/addsubscriptiontoclient",this.data).subscribe(value => {
                        this.alert.confirmMessage("Subscription Added Kindly Relogin","Click OK").then(response =>{
                          this.userService.logout();
                        });
                      });
                    });
                  }else{
                    this.alert.showSuccesMessage("Subscription is not added due to payment Failure");
                  }
                },
                prefill:{
                  name:clientSignUpDao.clientName,
                  email:clientSignUpDao.email,
                  contact:clientSignUpDao.clientNumber
                }
              }
              let  razorPayObject = new Razorpay(options);
              razorPayObject.open();
          });
          this.subscriptionButtonStatus = false;
      }else {
        this.dialog.open(MetaCredentialsComponent, {data:ClientSignUpDao, width:'70vh'}).afterClosed().subscribe(value2 => {});
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  protected readonly ExpiryDateType = ExpiryDateType;
  protected readonly SubScriptionPackageDao = SubScriptionPackageDao;
  protected readonly SubscriptionPackageType = SubscriptionPackageType;
  subscriptionButtonStatus: boolean = false;
}
