<a class="inbox_item {{className}}" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div class="inbox-avatar">

    <img alt="" class="border-gray hidden-xs hidden-sm" src="assets/dist/img/avatar4.png" style="float: right">
    <div class="inbox-avatar-text">
      <div style="font-size: 18px">
        <small>

        <span>


      <ng-container *ngIf="messageDao.type=='template'">
        <b>Template:- {{ messageDao.template.name }}</b>
        <div *ngIf="messageDao.template != null " style="margin-bottom: 0px; padding: 0px" class="form-group col-lg-12">
            <ng-container *ngFor="let component of messageDao.template.components">
              <div *ngIf="component.type == 'header'">
                <ng-container *ngFor="let parameter of component.parameters">
                  <button *ngIf="parameter.type == 'image' && parameter.image.link != null" (click)="openTemplateMediaFile(parameter)" style="width: 20%" class="fa fa-eye btn-circle"></button>
                  <button *ngIf="parameter.type == 'document' && parameter.document.link != null" (click)="openTemplateMediaFile(parameter)" style="width: 20%" class="fa fa-eye btn-circle"></button>
                  <button *ngIf="parameter.type == 'video' && parameter.video.link != null" (click)="openTemplateMediaFile(parameter)" style="width: 20%" class="fa fa-eye btn-circle"></button>
                </ng-container>
              </div>
            </ng-container>
          </div>
        <br>
        {{messageDao.messageContent || ''}}
      </ng-container>

          <ng-container *ngIf="messageDao.type=='CATALOGUE'">
        <b>Catalogue  </b>{{ messageDao.catalogMessageDao.catalogName }}
      </ng-container>
          <!--          <ng-container *ngIf="messageDao.type=='ORDER'">-->
          <!--        <b>Catalogue  </b>-->
          <!--            <br>{{messageDao.catalogMessageDao.interactive.action.sections.pop().product_items.pop().variantsTitle}}-->
          <!--            <br>{{messageDao.catalogMessageDao.interactive.action.sections.pop().product_items.pop().quantity}}-->
          <!--            <br>{{messageDao.catalogMessageDao.interactive.action.sections.pop().product_items.pop().item_price}}-->
          <!--      </ng-container>-->


<ng-container *ngIf="messageDao.type === 'ORDER'">
  <b>Catalogue</b>
  <ng-container *ngIf="messageDao.catalogMessageDao.interactive.action.sections.length > 0">
    <table class="table table-striped row-border hover">
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>

        <ng-container *ngFor="let data of messageDao.catalogMessageDao.interactive.action.sections[0].product_items">
          <tr>
            <td>{{ data.variantsTitle }}</td>
            <td>{{ data.quantity }}</td>
            <td>{{ data.item_price }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</ng-container>




      <ng-container *ngIf="messageDao.type=='text'&&checkTextMessage(messageDao)">
         <span [innerHTML]="messageDao.text.body||''"></span>
      </ng-container>

          <ng-container *ngIf="messageDao.type=='interactive' && checkChatbootMessage(messageDao)">
             <div *ngIf="messageDao.interactive.type=='button'">
                          <span [innerHTML]="messageDao.interactive.body.text"> </span>

               <div *ngFor="let chatbuttons of messageDao.interactive.action.buttons">

               <div *ngIf="chatbuttons.type=='reply'">
               <span style="margin-top: 30px" [innerHTML]="chatbuttons.reply.title"> </span>
               </div>

               </div>

             </div>

              <div *ngIf="messageDao.interactive.type=='button_reply'">
                          <span [innerHTML]="messageDao.interactive.button_reply.title"> </span>
             </div>

             <div *ngIf="messageDao.interactive.type=='list_reply'">

                             <span [innerHTML]="messageDao.interactive.list_reply.title"></span> <br>
                             <span [innerHTML]="messageDao.interactive.list_reply.description"></span>
             </div>

           </ng-container>

           <ng-container *ngIf="messageDao.type=='image'&&checkImageMessage(messageDao)">
                  <figure>
             <img class="commonmessagetypeimg"
                  src="assets/Images/imageicon.png">
                    <!--                    <span [innerHTML]="messageDao.document.filename"> </span>-->
              <figcaption [innerHTML]="messageDao.image.caption"></figcaption>
                          <button
                            (click)="downloadMedia(messageDao.image.id,messageDao.image.mime_type,messageDao.date,'',messageDao.attachementBase64,messageDao.mimeType)"
                            style="width: 20%"
                            class="fa fa-download btn-circle"></button>
                      <button *ngIf="messageDao.image.link != null" (click)="openMediaFile(messageDao)"
                              style="width: 20%"
                              class="fa fa-eye btn-circle"></button>
             </figure>

      </ng-container>
          <ng-container *ngIf="messageDao.type=='button'&&checkButtonMessage(messageDao)">

                 <span [innerHTML]="messageDao.button.text||''"></span>
      </ng-container>
           <ng-container *ngIf="messageDao.type=='location' && checkLocationMessage(messageDao)">
                          <span [innerHTML]="'Longitude:- '+messageDao.location.longitude"> </span>
                            <br>
                          <span [innerHTML]="'Latitude:- '+messageDao.location.latitude"> </span>
      </ng-container>


            <ng-container *ngIf="messageDao.type=='video'&&checkVideoMessage(messageDao)">
               <figure>
             <img class="commonmessagetypeimg"
                  src="assets/Images/videoicon.png">
             <figcaption [innerHTML]="messageDao.video.caption"></figcaption>
                          <button
                            (click)="downloadMedia(messageDao.video.id,messageDao.video.mime_type,messageDao.date,'',messageDao.attachementBase64,messageDao.mimeType)"
                            style="width: 20%" class="fa fa-download btn-circle"></button>
                          <button *ngIf="messageDao.video.link != null" (click)="openMediaFile(messageDao)"
                                  style="width: 20%"
                                  class="fa fa-eye btn-circle"></button>
               </figure>


      </ng-container>


          <ng-container *ngIf="messageDao.type=='document' && checkDocumentMessage(messageDao)">
              <figure>

             <img class="commonmessagetypeimg" [src]="this.documentIcon">
             <figcaption [innerHTML]="messageDao.document.caption"></figcaption>
                          <button
                            (click)="downloadMedia(messageDao.document.id,messageDao.document.mime_type,messageDao.date,messageDao.document.filename, messageDao.attachementBase64,messageDao.mimeType)"
                            style="width: 20%" class="fa fa-download btn btn-add"></button>
                          <button *ngIf="messageDao.document.link != null" (click)="openMediaFile(messageDao)"
                                  style="width: 20%"
                                  class="fa fa-eye btn-circle"></button>
              </figure>


      </ng-container>
            <ng-container *ngIf="messageDao.type=='audio'&&checkAudioMessage(messageDao)">
            <figure>
             <img class="commonmessagetypeimg" src="assets/Images/audioicon.png">
             <figcaption [innerHTML]="messageDao.audio.caption"></figcaption>
                          <button
                            (click)="downloadMedia(messageDao.audio.id,messageDao.audio.mime_type,messageDao.date,'',messageDao.attachementBase64,messageDao.mimeType)"
                            style="width: 20%" class="fa fa-download btn-circle"></button>
                          <button *ngIf="messageDao.audio.link != null" (click)="openMediaFile(messageDao)"
                                  style="width: 20%"
                                  class="fa fa-eye btn-circle"></button>
            </figure>

      </ng-container>





      </span>


        </small></div>
      <!--      <br>-->

      <div *ngIf="messageDao.chatSide!='Client'">
        <ng-container *ngIf="messageDao.messageStatus=='SESSIONEXP'">
          <i [title]="messageDao.messageStatus" class="fa fa-exclamation" style="color: red"></i>
        </ng-container>
        <ng-container *ngIf="messageDao.messageStatus=='INVALIDDST'">
          <i [title]="messageDao.messageStatus" class="fa fa-exclamation" style="color: red"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='PENDING'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>
        <ng-container *ngIf="messageDao.messageStatus=='SENT'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='DLV'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>

        <ng-container *ngIf="messageDao.messageStatus=='VIEW'">
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: #1b5b7a"></i>
          <i [title]="messageDao.messageStatus" class="fa fa-check" style="color: #1b5b7a"></i>
        </ng-container>
        <i (click)="openMessageDetails(messageDao)" class="fa fa-info-circle"
           style="cursor: pointer;font-weight: bolder" title="Click Here To View Message Info"></i>
      </div>
      <!--      <br>-->
      <small [innerHTML]="messageDao.date"></small>
    </div>

  </div>
</a>




