<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <th>Promoter Name</th>
              <th>Mandi Name</th>
              <th>WS Name </th>
              <th>Retailer Name </th>
              <th>Retailer Number </th>
              <th>Bill Amount</th>
              <th>Note Book </th>
              <th>Gift Hand Over</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr>
                <td [innerHTML]="user.id"></td>
                <td [innerHTML]="user.date"></td>
                <td [innerHTML]="user.promoterName"></td>
                <td [innerHTML]="user.mandiName"></td>
                <td [innerHTML]="user.wsName"></td>
                <td [innerHTML]="user.retailerName"></td>
                <td [innerHTML]="user.retailerMobile"></td>
                <td [innerHTML]="user.billAmount"></td>
                <td [innerHTML]="user.parchiBook"></td>
                <td [innerHTML]="user.giftHandOver==true?'Yes': 'No' "></td>

              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>







