import {Injectable} from '@angular/core';
import {ServiceDao, UserMasterDao, UserType} from './UserMasterDao';
import {Observable} from 'rxjs';
import {UsermasterService} from '../UsermasterService/usermaster.service';

import {AccountMasterService} from '../../account-master/AccountMasterService/account-master.service';
import {AccountMasterDao} from '../../account-master/AccountMasterDao';
import {Router} from '@angular/router';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {AppRoutingModule} from '../Routing/AppRoutingModule';
import {environment} from '../../../environments/environment.prod';
import {ServiceMasterDao} from '../../servicemaster/ServiceMasterDao';
import {ServiceMasterService} from '../../servicemaster/ServiceMasterService/service-master.service';
import {PermissionentryService} from '../../permissionentry/PermissionEntryService/permissionentry.service';
import {MenuDao} from './MenuDao';
import {PaymentComponent} from "../../MicroServices/NBCC/payment/payment.component";
import {NbccserviceService} from "../../MicroServices/NBCC/nbccservice.service";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  openpage:boolean= this.checkPayment();
  isLogin: boolean = this.checkLogin();
  user: UserMasterDao = this.getUser();
  isServicePage: boolean = this.checkService();
  menuJson: Array<MenuDao> = this.getMenuJson();
  accountMasterDao: AccountMasterDao = this.getAccount();
  serviceName: ServiceMasterDao = this.getService();
  permission: Array<string> = [];
  reportingList: Array<UserMasterDao> = this.getReportList();

  // tslint:disable-next-line:max-line-length
  constructor(private userService: UsermasterService, private permissionEntry: PermissionentryService, private accountService: AccountMasterService, private router: Router, private alertservice: AlertMessageService,private paymentts: NbccserviceService ) {
    this.refreshPermission();
    console.log('openpage', this.openpage);

  }
  // tslint:disable-next-line:typedef
  loadPermissions()
  {

  }
  checkLogin(): boolean {
     // tslint:disable-next-line:triple-equals
    if (localStorage.getItem('accessToken') == undefined && !this.isServicePage) {
      return false;
    }
    return true;
  }

  getUser(): UserMasterDao {
    const user: UserMasterDao = JSON.parse(localStorage.getItem('userDeatails'));
    // tslint:disable-next-line:triple-equals
    if (user == undefined) {
      return null;
    } else {
      return user;
    }

  }

  getAccount(): AccountMasterDao {
    const dao: AccountMasterDao = JSON.parse(localStorage.getItem('accountDetails'));
    // tslint:disable-next-line:triple-equals
    if (dao == undefined) {
      return null;
    } else {
      return dao;
    }

  }

  // tslint:disable-next-line:typedef
  selectService(json: ServiceMasterDao) {
    this.alertservice.showLoader();
    this.isLogin = true;
    this.isServicePage = false;
    this.menuJson = [];
    this.serviceName = json;
    localStorage.setItem('deafaultService', JSON.stringify(json));
     this.loadMenu(json).then(check => {
      this.takeMeToDashBoardOfService(json);
      this.alertservice.hideLoader();

    });

  //  this.takeMeToDashBoardOfService(json,true);
  }
  // tslint:disable-next-line:typedef
  loadMenu(json: ServiceMasterDao)
  {
    return new Promise((resolve, reject) => {
      this.permissionEntry.getMenuByApplication(json.id).subscribe(map => {
        // tslint:disable-next-line:forin
        for (const key in map) {
          const menuoutDet = key.toString().split('=');
          this.menuJson.push({menuName: menuoutDet[0], menuIcon: menuoutDet[1], menuinnerdao: map[key]});
        }
        localStorage.setItem('menuJson', JSON.stringify(this.menuJson));
        this.refreshPermission();
        resolve('success');


      }, error => {
        this.alertservice.showErrorMessage('Failed To Load Service');
      });
    });
  }

  // tslint:disable-next-line:typedef
  takeMeToDashBoardOfService(json: ServiceMasterDao) {
    console.log('This is service ', json);
    this.router.navigate([json.serviceUrl + '/dashboard']);
  }


  // tslint:disable-next-line:typedef
  refreshPermission() {
    try {
      this.permission = ['/'];

      const len = this.menuJson.length;
      for (let i = 0; i < len; i++) {
        const menuInnerLen = this.menuJson[i].menuinnerdao.length;

        // tslint:disable-next-line:prefer-for-of
        for (let m = 0; m < this.menuJson[i].menuinnerdao.length; m++)
        {
          this.permission.push('/' + this.menuJson[i].menuinnerdao[m].menuUrl);
        }

      }
    } catch (e) {

    }
    console.log('This are the permissions ', this.permission);
  }

  getReportList(): Array<UserMasterDao> {
    const reportList = JSON.parse(localStorage.getItem('reportingList'));
    if (reportList == undefined) {
      return [];
    }
    return reportList;
  }

  getService(): ServiceMasterDao {
    const service = JSON.parse(localStorage.getItem('deafaultService'));
    if (service == undefined) {
      return undefined;
    }
    return service;
  }

  checkService(): boolean {
    if (localStorage.getItem('deafaultService') == undefined) {
      return true;
    }
    return false;
  }

  login(user: UserMasterDao): Observable<any> {
    return this.userService.authenticateUser(user);
  }

  // tslint:disable-next-line:typedef
  saveAccessTokenToSession(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  }

  // tslint:disable-next-line:typedef
  saveRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }


  // tslint:disable-next-line:typedef
  getCurrentUserAndSaveToSession() {
    debugger;
    this.userService.getCurrentUser().subscribe(value => {
       value.otpVerified=false;
      //value.hasTwoStepAuthencation=false;
      localStorage.setItem('userDeatails', JSON.stringify(value));
      this.user = value;
      this.getReportList();
      this.userService.getMyTeam(value.id).subscribe(reportList => {

        this.reportingList = reportList;
        localStorage.setItem('reportingList', JSON.stringify(reportList));
      });

      // tslint:disable-next-line:no-debugger
      this.accountService.getAccountById(value.accountId).subscribe(value1 => {

        localStorage.setItem('accountDetails', JSON.stringify(value1));
        console.log('accountDetails', value1);
        this.accountMasterDao = value1;
        // this.loadDefaultMenu();
        this.alertservice.hideLoader();
        location.reload();
      });




    });
  }

  getAccessToken(): string {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken == undefined) {
      accessToken = 'NA';
    }
    return accessToken;
  }

  getRefreshToken(): string {
    let accessToken = localStorage.getItem('refreshToken');
    if (accessToken == undefined) {
      accessToken = 'NA';
    }
    return accessToken;
  }



  getMenuJson(): Array<MenuDao> {

    return JSON.parse(localStorage.getItem('menuJson'));
  }

  clearMenuJson() {
    localStorage.removeItem('menuJson');
    this.menuJson = [];
    this.refreshPermission();
  }

  logout() {
    localStorage.removeItem('userDeatails');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('deafaultService');
    this.router.navigate(['/']);
    localStorage.clear();

    this.isLogin = false;
    location.reload();
  }

  checkPermission(operation): boolean {
    if (this.user.permissions.indexOf(operation) == -1) {
      return false;
    } else {
      return true;
    }

  }

  logoutOff() {
    this.serviceName = undefined;
    localStorage.removeItem('deafaultService');
    this.clearMenuJson();
  //  this.loadDefaultMenu();
    this.router.navigate(['/']);
    this.isLogin = true;
    this.isServicePage = true;


  }

  setOTPStatus(status:boolean) {
    let user = this.getUser();
    user.otpVerified = status;
    localStorage.setItem('userDeatails', JSON.stringify(user));
  }

  hasTwoStepAuthentication(){

    let user = this.getUser();
     if (user == undefined){
      return false;
    }else{
      return user.hasTwoStepAuthencation
    }
  }

  hasOTPVerified(){
    let user = this.getUser();
     if (user == undefined){
      return false;
    }else{
     return user.otpVerified
    }
  }

  openPaymentPage() {

   return true;
  }

  checkPayment() {
    this.openpage=this.paymentts.checkpagepermission();
    console.log('checkpage',this.openpage);
     return this.openpage;
  }
}
