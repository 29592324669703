export interface SubscriptionDaoUnWa{
  active: boolean;
  perMessageDeduct: number;
  perInstanceCreation: number;
  serviceType: ServiceTypeApp;
  expiry: string;
  dateFilter: number;
  subscriptionAmt: number;
  expiryReminder: string;
  refId: string;
  subscriptionDuration: SubscriptionDuration;
  subscriptionDurationDays: number;
  accountId: number;
  perMinMsg: number;
  panelQue: string;
  apiQue: string;
  updateBy: number;
  salesPersonEmail: string;
  driveLimit: number;
}
export enum  ServiceTypeApp{
  EXPIRY= 'EXPIRY', BALANCE= 'BALANCE', HYBRID= 'HYBRID'
}
export enum SubscriptionDuration{
  MONTHLY= 'MONTHLY', QUARTER= 'QUARTER', OTHER= 'OTHER'
}
