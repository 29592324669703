import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SubScriptionPackageDao} from "../subscription-package/SubScriptionPackageDao";
import {environment} from "../../environments/environment.prod";
import {SubscriptionFilterDao} from "./SubscriptionFilterDao";



@Injectable({
  providedIn: 'root'
})
export class SubscriptionPackageService {
  constructor(private http: HttpClient) { }

  getstatusWiseReport(filterDao: SubscriptionFilterDao):Observable<Array<SubScriptionPackageDao>>{
    return this.http.get<Array<SubScriptionPackageDao>>(environment.appUrl+environment.whatsappOfficialservice + "api/subscriptionpackage/get?startdate="+ filterDao.startdate + "&enddate=" + filterDao.enddate + "&status=" + filterDao.status);
  }
  insert(subScriptionPackageDao:SubScriptionPackageDao):Observable<SubScriptionPackageDao>{
    return this.http.post<SubScriptionPackageDao>(environment.appUrl+environment.whatsappOfficialservice + "api/subscriptionpackage/",subScriptionPackageDao);
  }
  changeStatus(subScriptionPackageDao:SubScriptionPackageDao):Observable<SubScriptionPackageDao>{
    return this.http.post<SubScriptionPackageDao>(environment.appUrl+environment.whatsappOfficialservice + "api/subscriptionpackage/statuschange",subScriptionPackageDao);
  }
}
