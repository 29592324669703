
<div *ngIf=" components!=='button'">
  <form style="width: 350px" ngNativeValidate (submit)="submitData()">

    <h1 mat-dialog-title style="text-align: center">Parameters <i class="fa fa-filter"></i> </h1>
    <mat-dialog-content class="mat-typography">
      <div class="form-group col-lg-12">
        <label>Message Type  <sup class="required">*</sup></label>
        <div class="col-sm-12">
          <mat-radio-group [(ngModel)]="components.type" [name]="'componentType'+components.type"  aria-label="Select an option">
            <mat-radio-button value="text" >Text</mat-radio-button>
            <mat-radio-button value="image" >Image</mat-radio-button>
            <mat-radio-button value="document" >Document</mat-radio-button>
            <mat-radio-button value="currency" >Currency</mat-radio-button>
            <mat-radio-button value="date_time" >Date Time</mat-radio-button>
            <mat-radio-button value="video" >Video</mat-radio-button>

            <!--          <ng-container *ngIf="components.type=='text' ">-->
            <!--             <label>Button Url</label>-->
            <!--            <input type="text" class="form-control" name="subtype" [(ngModel)]="components." required>-->
            <!--          </ng-container>-->
          </mat-radio-group>

        </div>



      </div>
      <div class="form-group col-lg-12">
        <label>Parameter Name  <sup class="required">*</sup></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" name="nameOfParams" [(ngModel)]="components.nameOfParams" required>
        </div>
      </div>



      <!-- <div class="form-group col-lg-12">-->

      <!--      <div class="col-sm-12">-->
      <!--        <ng-container *ngIf="components.type=='button'">-->
      <!--          <label>Text  <sup class="required">*</sup></label>-->
      <!--          <input type="text" class="form-control" name="textformat" [(ngModel)]="components.text" required>-->
      <!--        </ng-container>-->
      <!--        <ng-container *ngIf="components.type=='image'">-->
      <!--          <label>Image Url  <sup class="required">*</sup></label>-->
      <!--          <input type="url" class="form-control" name="imageurl" [(ngModel)]="components.image.link" required>-->
      <!--          <label>Image Name  <sup class="required">*</sup></label>-->
      <!--          <input type="text" class="form-control" name="imagename" [(ngModel)]="components.image.filename" required>-->

      <!--        </ng-container>-->

      <!--        <ng-container *ngIf="components.type=='document'">-->
      <!--          <label>Document Url  <sup class="required">*</sup></label>-->
      <!--          <input type="url" class="form-control" name="documenturl" [(ngModel)]="components.document.link" required>-->
      <!--          <label>Document Name  <sup class="required">*</sup></label>-->
      <!--          <input type="text" class="form-control" name="documentname" [(ngModel)]="components.document.filename" required>-->

      <!--        </ng-container>-->



      <!--        <ng-container *ngIf="components.type=='currency'">-->
      <!--          <label>Fallback Value  <sup class="required">*</sup></label>-->
      <!--          <input type="url" class="form-control" name="currency" [(ngModel)]="components.image.link" required>-->
      <!--          <label>Currency Code  <sup class="required">*</sup></label>-->
      <!--         <select class="form-control" name="currencyCode" [(ngModel)]="components.currency.code">-->
      <!--           <ng-container *ngFor="let curr of currency">-->

      <!--             <option [value]="curr.currency_code" [innerHTML]="curr.country">-->

      <!--             </option>-->
      <!--           </ng-container>-->
      <!--         </select>-->

      <!--        </ng-container>-->
      <!--        <ng-container *ngIf="components.type=='date_time'">-->


      <!--        </ng-container>-->
      <!--      </div>-->
      <!--    </div>-->


    </mat-dialog-content>


    <mat-dialog-actions>
      <div class="reset-button">
        <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>
        <button   type="submit" class="btn btn-add pull-right">Filter <i class="fa fa-filter"></i> </button>

      </div>
    </mat-dialog-actions>
  </form>
</div>

<div *ngIf="components=='button'">
<form style="width: 350px" ngNativeValidate (submit)="submitDataButton()">

  <mat-dialog-content class="mat-typography">
    <div class="form-group col-lg-12">
      <label>Button  <sup class="required">*</sup></label>
      <select class="form-control"  [(ngModel)]="subType"  name="templatebutton"  >
        <ng-container >
          <option value="undefined">---Select Button Type---</option>
          <option value="url">URl Button</option>
          <option value="call">Call Button</option>
          <option value="action">Action Button</option>
        </ng-container>
      </select>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="reset-button">
      <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>
      <button   type="submit" class="btn btn-add pull-right">Filter <i class="fa fa-filter"></i> </button>

    </div>
  </mat-dialog-actions>
</form>
</div>





