import {Component, OnDestroy, OnInit} from '@angular/core';
import {ServiceDao, ServiceType, UserMasterDao} from '../Services/User/UserMasterDao';
import {Subject} from 'rxjs';
import {UsermasterService} from '../Services/UsermasterService/usermaster.service';
import {UserService} from '../Services/User/user.service';
import {AccountMasterService} from '../account-master/AccountMasterService/account-master.service';
import {AccountMasterDao} from '../account-master/AccountMasterDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {EnumServiceService} from '../Services/EnumController/enum-service.service';
import {UidGenerationService} from '../Services/Uids/uid-generation.service';
import {MatDialog} from '@angular/material/dialog';
import {UserMasterReportingComponent} from './user-master-reporting/user-master-reporting.component';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.css']
})
export class UserMasterComponent implements OnInit, OnDestroy {
  hasWriteOrUpdatePermission: boolean = this.user.checkPermission('ROLE_USERINSORUPD');
  isMasterAdminAccount: boolean = this.user.checkPermission('ROLE_MASTERADMIN');
  tableList: Array<UserMasterDao> = [];
  isCreate = true;
  addNewDiv = false;
  dtOptions: any = {bDestroy: true, order: []};
  reportingList: Array<UserMasterDao> = [];
  userMasterDao: UserMasterDao = {
    accountId: 0,
    hasTwoStepAuthencation:false,
    otpVerified:false,
    accountMasterDao: undefined,
    accountType: 'USER',
    active: true,
    createAt: '',
    createBy: '',
    email: '',
    id: 0,
    password: '',
    permissions: undefined,
    phone: '',
    services: [],
    servicesList: '',
    updateAt: '',
    updateBy: '',
    username: '',
    fullname: '',
    enableAgentSupport:false,
    agentStatus:false
  };
  serviceList: Array<ServiceDao> = [];
  userAccountType: Array<string> = [];
  accountMasterDao: AccountMasterDao = null;
  dtTrigger: Subject<any> = new Subject<any>();
  private updateId: number;

  constructor(private usermasterService: UsermasterService, private user: UserService, private accountService: AccountMasterService, private alert: AlertMessageService, private enumService: EnumServiceService, private counter: UidGenerationService, private dialog: MatDialog) {
    this.loadData();
    this.enumService.getArrrayEnum('getusertype').subscribe(value => {
      const arr = [];
      for (let i = 0; i < value.length; i++) {


        if (value[i] != 'MASTERADMIN') {
          console.log(value[i]);
          arr.push(value[i]);
        }

      }

      if (this.isMasterAdminAccount) {
        arr.push('MASTERADMIN');
      }
      this.userAccountType = arr;
    });
    this.accountService.getAccountById(user.user.accountId).subscribe(value => {
      this.accountMasterDao = value;

      console.log('accountMasterDao', value);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.accountMasterDao.services.length; i++) {
        this.serviceList.push({
          active: false,
          serviceName: this.accountMasterDao.services[i].serviceName,
          id: 0,
          apiUrl: '',
          descri: '',
          logo: '',
          serviceType: ServiceType.COIN
        });
      }


    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  // tslint:disable-next-line:typedef
  loadData() {
    this.usermasterService.getAll().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  createOrUpdate() {
    let msg = 'Update User?';
    if (this.isCreate) {
      msg = 'Create User?';
    }
    this.alert.confirmMessage(msg, 'Are you sure ?').then(value => {
      this.userMasterDao.accountId = this.user.user.accountId;
      console.log('will update user ', this.userMasterDao);
      const serviceDao = this.userMasterDao.services;
      const services = [];
      for (let i = 0; i < serviceDao.length; i++) {
        if (serviceDao[i].active) {
          services.push(serviceDao[i].serviceName);
        }
      }
      this.userMasterDao.servicesList = services.join(',');

      if (this.isCreate) {
        this.counter.generateCounterNumber(1, 1).then(id => {
          this.userMasterDao.id = id;
          this.usermasterService.createUser(this.userMasterDao).subscribe(userDao => {
            this.userMasterDao = userDao;
            this.isCreate = false;
            this.tableList.unshift(userDao);
            this.alert.showSuccesMessage('Successfully Created User! Userid is ' + userDao.id);

          });
        });


      } else {
        console.log('updateusers',this.userMasterDao);
        this.usermasterService.updateUser(this.userMasterDao).subscribe(userDao => {
          this.userMasterDao = userDao;
          this.isCreate = false;
          this.tableList[this.updateId] = userDao;
          this.alert.showSuccesMessage('Successfully Updated User');

        });
      }
      this.cancel();


    });


  }

  addNew() {
    for (let i = 0; i < this.serviceList.length; i++) {
      this.serviceList[i].active = false;
    }
    this.userMasterDao = {
      accountId: 0,
      otpVerified:false,
      hasTwoStepAuthencation:false,
      accountMasterDao: undefined,
      accountType: this.userAccountType[0],
      active: true,
      createAt: '',
      createBy: '',
      email: '',
      id: 0,
      password: '',
      permissions: undefined,
      phone: '',
      services: this.serviceList,
      servicesList: '',
      updateAt: '',
      updateBy: '',
      username: '',
      fullname: '',
      enableAgentSupport:false,
      agentStatus:false
    };

    this.addNewDiv = true;
  }

  edit(user: UserMasterDao, ind: number) {
    this.updateId = ind;
    this.isCreate = false;
    const serviceArr = user.servicesList.split(',');
    const serviceList = this.serviceList;
    for (let i = 0; i < serviceList.length; i++) {
      let found = false;
      for (let m = 0; m < serviceArr.length; m++) {

        if (serviceArr[m] == serviceList[i].serviceName) {
          found = true;
        }
      }

      serviceList[i].active = found;


    }
    console.log(serviceList);
    this.userMasterDao = user;
    this.userMasterDao.services = serviceList;
    this.addNewDiv = true;


  }


  cancel() {

    this.addNewDiv = false;


  }

  viewReporting(user: UserMasterDao, ind: number) {
      this.dialog.open(UserMasterReportingComponent, {data: user});
  }
}
