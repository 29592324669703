import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RechargeFormDao} from '../../account-master/recharge/RechargeDao';
import {UserMasterDao} from '../../Services/User/UserMasterDao';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ClientSidePagination} from '../../CommonObj/ClientSidePagination';

@Component({
  selector: 'app-user-master-reporting',
  templateUrl: './user-master-reporting.component.html',
  styleUrls: ['./user-master-reporting.component.css']
})
export class UserMasterReportingComponent implements OnInit {
  searchText = '';
  userId = 0;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: UserMasterDao, private userService: UsermasterService, private alert: AlertMessageService) { }
  addnewdiv = false;
  reportingList: ClientSidePagination<UserMasterDao> = null;
  userList: Array<UserMasterDao> = [];
  ngOnInit(): void {
    console.log('User Data {}', this.data);
    this.alert.showLoader();
    this.userService.getMyTeam(this.data.id).subscribe(value => {
      this.reportingList = {data: value, total: value.length, end: 10, start: 0};
      this.alert.hideLoader();
    });
    this.userService.getAll().subscribe(value => {
      this.userList = value;
    });
  }
  tableChange(json, dataChange: ClientSidePagination<any>) {

    dataChange.start = json.pageIndex * json.pageSize;
    dataChange.end = dataChange.start + json.pageSize;
    console.log(dataChange);


  }

  deleteReporting(data: UserMasterDao, ind: number) {
    this.alert.confirmMessage('Delete ' + data.fullname + ' from Reporting List?', 'Are you sure').then(value => {
      this.userService.deleteReporting(data.id, this.data.id).subscribe(value1 => {
        this.reportingList.data.splice(ind, 1);
      });

    });

  }

  addReporting() {
    if (this.userId == 0)
    {
      return;
    }
    this.alert.showLoader();
    this.userService.insertReporting(this.data.id, this.userId).subscribe(inser => {

      this.userService.getMyTeam(this.data.id).subscribe(value => {
        this.reportingList = {data: value, total: value.length, end: 10, start: 0};
        this.userId = 0;
        this.alert.hideLoader();

      });
    }, error => {
      this.alert.showErrorMessage('Already Exists!!');
      this.alert.hideLoader();
    });

  }
}
