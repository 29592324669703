

<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
<!--        <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal" ><i class="fa fa-window-close"></i></button>-->
      <button type="button" (click)="onClose()" mat-dialog-close  class="close" data-dismiss="modal" aria-hidden="true">×</button>
    <div class="panel-body">
      <h3 style="text-align: center;text-decoration: underline;text-decoration-color: darkred"> <b>Add New Page</b></h3><br>

       <form ngNativeValidate  class= "form-group col-lg-12" (ngSubmit)="createOrUpdate()">
         <div class="form-group">
           <label>Status  <sup class="required">*</sup></label>
           <mat-slide-toggle   name="create" [(ngModel)]="dao.active"></mat-slide-toggle>
         </div>
        <div class="form-group col-lg-6">
          <label   class="control-label" for="accountid">Account Id <sup class="required">*</sup></label>
          <input   type="number" placeholder="Please enter you account id" title="Please enter you accountid" required   name="accountid" id="accountid" class="form-control" [(ngModel)]="dao.accountId">
        </div>

         <div class="form-group col-lg-6">
           <label   class="control-label" for="pageName">Page Name <sup class="required">*</sup></label>
           <input   type="text" placeholder="Enter Page Name" title="Please enter you accountid" required   name="pageName" id="pageName" class="form-control" [(ngModel)]="dao.pageName">
         </div>


         <div  class="form-group col-lg-6">
           <label class="control-label">Select Menu <sup class="required">*</sup></label>
           <select class="form-control"    name="group"   [(ngModel)]="dao.menu">
             <ng-container  *ngFor="let menuData of menuList.data" >
               <option   [ngValue]="menuData.name" [innerHTML]= "menuData.name"></option>
             </ng-container>
           </select>
         </div>

         <div class="form-group col-lg-6">
           <label   class="control-label" for="description">Description <sup class="required">*</sup></label>
           <textarea   type="number" placeholder="Please enter description"   required   name="description" id="description" class="form-control" [(ngModel)]="dao.description"></textarea>
         </div>
         <div  class="form-group col-lg-12">
            <section [formGroup]="this.types">
              <label   class="control-label" >Page Permission Type: <sup class="required">*</sup></label>
                  <div class="col-lg-12">
                    <div class="col-lg-3"><mat-checkbox formControlName="download">Download Button</mat-checkbox> </div>
                    <div class="col-lg-3">  <mat-checkbox formControlName="read">View Button</mat-checkbox></div>
                      <div class="col-lg-3">   <mat-checkbox formControlName="create">Create Button</mat-checkbox></div>
                        <div class="col-lg-3">   <mat-checkbox formControlName="update">Update Button</mat-checkbox> </div>
             </div>
               <div class="col-lg-12">

                <div class="col-lg-3"> <mat-checkbox formControlName="delete">Delete Button</mat-checkbox></div>
                <div class="col-lg-3">  <mat-checkbox formControlName="template">Template Button</mat-checkbox></div>
                  <div class="col-lg-3">  <mat-checkbox formControlName="file">File</mat-checkbox></div>
                    <div class="col-lg-3">  <mat-checkbox formControlName="text">Text</mat-checkbox></div>
<!--             </div>-->
              </div>

           </section>

         </div>
        <div class="col-sm-6" style="margin-left:  83%">
          <button type="submit" class="btn btn-add btn-sm">{{submitBtnTxt}}</button>
          <button type="button" (click)="onClose()" mat-dialog-close class="btn btn-danger btn-sm">Close</button>
        </div>

      </form>

    </div>
  </div>
</div>
</div>


