import {Component, OnInit} from '@angular/core';
import {DriveMasterDao} from './DriveMasterDao';
import {DriveService} from '../drive.service';
import {MatDialog} from '@angular/material/dialog';
import {FileUploadDriveComponent} from '../file-upload-drive/file-upload-drive.component';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {environment} from "../../../environments/environment.prod";
const appUrl = environment.driveLocationUrl;
@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.css']
})


export class DriveComponent implements OnInit {
  tableList: Array<DriveMasterDao> = [];
  deleteList: Array<DriveMasterDao>= [];
  selectedList: Array<boolean> = [];
  checkAllList: boolean = false;

  constructor(private drive: DriveService,private dialog:MatDialog,private alert:AlertMessageService) {
    this.loadData();
  }

  loadData() {
    this.drive.getAll().subscribe(value => {
      this.tableList = value;
    });
  }

  ngOnInit(): void {
  }

  addNew() {
  this.dialog.open(FileUploadDriveComponent).afterClosed().subscribe(value => {
    this.loadData();
  })
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  delete(data: DriveMasterDao) {
    this.alert.confirmMessage("Delete "+data.filename+"?" ,"Are you sure ?").then(value => {
      this.drive.deleteFile(data.id).subscribe(value1 => {
        this.loadData();

      })
    })
  }
  multiDelete(data: Array<DriveMasterDao>) {
    this.alert.confirmMessage("Delete Selected Files ?" ,"Are you sure ?").then(value => {
      this.drive.deleteMultipleFile(data).subscribe(value1 => {
        this.selectedList = [];
        this.checkAllList = false;
        this.loadData();
        this.alert.showSuccesMessage("Files Deleted");
      })
    })
  }
  selectRows(driveMasterDao:DriveMasterDao, index:number){
      if(!this.selectedList[index]){
      this.deleteList.push(driveMasterDao);
      }else{
        this.deleteList = this.deleteList.filter(item => item.id !== driveMasterDao.id);
      }
  }

  selectedDelete() {
    this.multiDelete(this.deleteList);
  }
  copy(data:DriveMasterDao)
  {
    if(data.id>3361){
      this.copyToClipboard(appUrl + "Shareablelinks/"+ data.accountId + "/" + data.id+'/'+data.filename);
    }else{
      this.copyToClipboard(appUrl + "Shareablelinks/"+ data.id+'/'+data.filename);
    }
    // this.copyToClipboard("http://62.171.169.159/Shareablelinks/" + data.accountId + "/" +data.id+'/'+data.filename)
    this.alert.showSuccesMessage("Succesfully Copied Link!!")
  }

  convertToMb(fileSize: number) {
    return fileSize / Math.pow(1000,2);

  }

  selectAllRows() {
    if(!this.checkAllList){
      for(let i =0;i<this.tableList.length;i++){
        if(i<=150){
          this.selectedList[i] = true;
          this.deleteList[i] = this.tableList[i];
        }
      }
    }else{
      this.deleteList = [];
      this.selectedList = [];
    }
  }
}
