



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Country<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">

        <div *ngIf="addnewDiv">
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Country Code  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="countryDao.countryCode" name="countryCode" required>
            </div>

            <div class="form-group col-lg-12">
              <label>Country Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="countryDao.name" name="countryName" required>
            </div>
            <div class="form-group col-lg-12">
            <button type="submit" class="btn btn-add" >Submit</button>
            <button (click)="cancelBtn()" type="button" class="btn btn-add" data-dismiss="modal">Cancel</button>
            </div>
          </form>
        </div>





        <div *ngIf="!addnewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Country Name</th>
              <th>Country Code</th>

              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.length==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList;let ind =index">
              <tr>
                <td [innerHTML]="data.name"></td>

                <td [innerHTML]="data.countryCode"></td>

<td>
                  <i title="Click Here To Delete" (click)="edit(data)"
                     class="btn btn-add fa fa-pencil-square"></i>







                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







