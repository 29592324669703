




<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>
          <select (change)="refreshChange()" style="width: 200px!important;" type="date" [(ngModel)]="managerId" name="managerId" required>
            <ng-container *ngFor="let user of userList">
              <option [value]="user.id" [innerHTML]="user.fullname"></option>
            </ng-container>

          </select>




          <select (change)="refreshChange()"  style="width: 200px!important;" type="date" [(ngModel)]="show" name="show" required>

            <option value="TRUE">Valid</option>
            <option value="FALSE">In-Valid</option>

          </select>


        </div>
      </div>
      <div style="" class="panel-body">
        <div  id="tablediv">
          <div *ngIf="zoneArr!=undefined" class="tabularreport">
          <h4 style="text-align: center;font-weight: bolder">Zone Wise Report ({{totalBrands}})</h4>
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Value</th>
                <th>Count</th>
                <th>Percantage</th>

              </tr>
              </thead>
              <tbody >
              <tr style="text-align: center" *ngIf="zoneArr.total==0">
                <td colspan="3">No Entry</td>


              </tr>

              <ng-container  *ngFor="let data of zoneArr.data| slice: zoneArr.start : zoneArr.end">
                <tr>
                  <td [innerHTML]="data.value"></td>
                  <td [innerHTML]="data.count"></td>
                  <td [innerHTML]="calculatePercentage(data.count,totalBrands)"></td>

                </tr>
              </ng-container>




              </tbody>
            </table>



          </div>
            <mat-paginator (page)="tableChange($event,zoneArr)" [length]=zoneArr.total
                           [pageSize]="10"
                           [pageSizeOptions]="[5,10,20,50]"></mat-paginator>

          </div>

          <div *ngIf="teamArr!=undefined" class="tabularreport">
            <h4 style="text-align: center;font-weight: bolder">Team Wise Report ({{totalBrands}})</h4>
            <div class="table-responsive">


              <table class="table table-bordered table-striped table-hover">
                <thead>
                <tr>
                  <th>Value</th>
                  <th>Count</th>
                  <th>Percantage</th>


                </tr>
                </thead>
                <tbody >
                <tr style="text-align: center" *ngIf="teamArr.total==0">
                  <td colspan="3">No Entry</td>


                </tr>

                <ng-container  *ngFor="let data of teamArr.data| slice: teamArr.start : teamArr.end">

                  <tr>
                    <td [innerHTML]="data.value"></td>
                    <td [innerHTML]="data.count"></td>

                    <td [innerHTML]="calculatePercentage(data.count,totalBrands)"></td>


                  </tr>
                </ng-container>



                </tbody>
              </table>
            </div>
            <mat-paginator (page)="tableChange($event,teamArr)" [length]=teamArr.total
                           [pageSize]="10"
                           [pageSizeOptions]="[5,10,20,50]"></mat-paginator>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>









