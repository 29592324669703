<aside style="background-color: #1c5b7a;position: fixed;" class="main-sidebar ">
  <!-- sidebar -->
  <div class="sidebar">
    <!-- sidebar menu -->
    <notifier-container></notifier-container>
    <ul class="sidebar-menu">
      <!--      <button    style="width:250px;border-radius: 15px;background-color: whitesmoke; font-size: 18px;margin-top: 25px" id="mailsend1"  ><i  style="height: 40px;margin-top: 5px" class=" fa fa-pencil">&nbsp;&nbsp;<b style="text-align: center;"> Compose</b></i></button>-->

      <li class="active" *ngIf="this.tableList==null">
        <a *ngIf="this.user.accountMasterDao.creditType!='INHERITED';else balnaceDiv"><i
          class="fa fa-credit-card"></i><span><b>Balance   :
          <span *ngIf="closingBalance<0 else balancePos" style="color: red">{{closingBalance.toFixed(2)}}</span>
          <ng-template #balancePos style="color:green;">{{closingBalance.toFixed(2)}}</ng-template>
         </b><i style="margin-left: 4px;cursor: pointer" (click)="refreshBal()" class="fa fa-refresh"></i></span>
          <span class="pull-right-container">
                     </span>
        </a>
        <ng-template #balnaceDiv>
          <a><i class="fa fa-credit-card"></i><span><b>Credit Used   :
          <span *ngIf="closingBalance<0 else balancePos" style="color: red">{{closingBalance.toFixed(2)}}</span>
          <ng-template #balancePos style="color:green;">{{closingBalance.toFixed(2)}}</ng-template>
         </b><i style="margin-left: 4px;cursor: pointer" (click)="refreshBal()" class="fa fa-refresh"></i></span>
            <span class="pull-right-container">
                     </span>
          </a>
        </ng-template>
      </li>

      <li class="active" *ngIf="this.tableList==null">
        <a><i class="fa fa-cloud"></i><span><b>Storage   </b>
          <br>
          <div class="progress runningprogress">
                                 <div class="progress-bar progress-bar-striped"
                                      [style.background-color]="getColour(driveSub.used,driveSub.allowed)"
                                      role="progressbar" [style.width.%]="percentage(driveSub.used,driveSub.allowed)"
                                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-toggle="tooltip"
                                      data-placement="top"
                                      [title]="percentage(driveSub.used,driveSub.allowed)+'%'"></div>
                              </div>

          <div style="text-align: center;font-weight: bolder">
          {{getMbOrGb(driveSub.used)  }} of {{ getMbOrGb(driveSub.allowed)}} Used
            </div>
         </span>
          <span class="pull-right-container">
                     </span>
        </a>
      </li>

      <ng-container *ngFor="let menu of menuJson;let ind = index">
        <li class="treeview">
          <a>
            <i [ngClass]="menu.menuIcon"></i><span [innerHTML]="menu.menuName"></span>
            <span class="pull-right-container">
                     <i class="fa fa-angle-left pull-right"></i>
                     </span>
          </a>
          <ul class="treeview-menu">
            <ng-container *ngFor="let menuInner of menu.menuinnerdao">
              <li><a [routerLink]="menuInner.menuUrl" [innerHTML]="menuInner.moduleName"></a></li>

            </ng-container>

          </ul>
        </li>
      </ng-container>


    </ul>
  </div>
  <!-- /.sidebar -->
</aside>
