import {Component, OnInit} from '@angular/core';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {CigarateDao} from '../../../Digimozo/Cigarate/cigarate-panel-dash/CigarateDao';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {environment} from '../../../../../environments/environment.prod';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {EmpReportDao} from './EmpReportDao';
import {UserService} from '../../../../Services/User/user.service';
import {UserMasterDao} from '../../../../Services/User/UserMasterDao';
import {UsermasterService} from '../../../../Services/UsermasterService/usermaster.service';
import {ProductMasterDao} from '../productmaster/ProductMasterDao';


@Component({
  selector: 'app-empreport',
  templateUrl: './empreport.component.html',
  styleUrls: ['./empreport.component.css']
})
export class EmpreportComponent implements OnInit {
  fullname:string;
  mobile:string;
  tableData: PaginationDao<EmpReportDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  managerId: number=0;
  userList: Array<UserMasterDao> = [];
  team: string="0";
  brand:string="0";
  show: string="valid";
  role=this.user.user.accountType;

  public userMap: Map<number, UserMasterDao>;
  listproduct:Array<ProductMasterDao>=[];
  getUserDetails(id:number):UserMasterDao
  {
  try {
    return this.userMap.get(id)
  }catch (e)
  {
    let userMasterDao:UserMasterDao={
      accountId: 0,
      hasTwoStepAuthencation:false,
      otpVerified:false,
      accountMasterDao: undefined,
      accountType: '',
      active: false,
      createAt: '',
      createBy: '',
      email: '',
      fullname: 'NA',
      id: 0,
      password: '',
      permissions: undefined,
      phone: 'NA',
      services: undefined,
      servicesList: '',
      updateAt: '',
      updateBy: '',
      username: '',
      enableAgentSupport:false,
      agentStatus:false
    }
    return userMasterDao;
  }
  }
  constructor(private http: HttpClient,private userService:UsermasterService, private user: UserService, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {
    console.log("Ts is role ",this.role)
    this.userMap=new Map<number, UserMasterDao>();
    for(var i=0;i<this.user.reportingList.length;i++)
    {
      this.userMap[this.user.reportingList[i].id]=this.user.reportingList[i];
      this.userMap.set(this.user.reportingList[i].id,this.user.reportingList[i]);
    }





    this.http.get<Array<ProductMasterDao>>(environment.appUrl + environment.gsmgatewayservice + 'product/').subscribe(value => {
      this.listproduct = value;

    });

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];

      this.userList =[];


      this.userList =this.user.reportingList;
      this.userList.unshift({
        accountId: 0,
        hasTwoStepAuthencation:false,
        otpVerified:false,
        accountMasterDao: undefined,
        accountType: '',
        active: false,
        createAt: '',
        createBy: '',
        email: '',
        fullname: 'Show All',
        id: 0,
        password: '',
        permissions: undefined,
        phone: '',
        services: undefined,
        servicesList: '',
        updateAt: '',
        updateBy: '',
        username: '',
        enableAgentSupport:false,
        agentStatus:false
      })

      this.loadData();
    /*  if(this.user.user.accountType=="ADMIN") {
        this.userService.getAll().subscribe(value => {
          this.userList = value;
          this.loadData();
        });
      }else{
        this.userList.push(this.user.user);
        this.loadData();
      }*/

    });
  }

  loadData() {
    this.alert.showLoader();
    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = 20101010;
      this.enddate = 20301010;
    }
    let userList="0";
    var arr=[];
    if(this.managerId==0)
    {
      for(var i=0;i<this.userList.length;i++)
      {
        arr.push(this.userList[i].id);
      }
      userList=arr.join(",");
    }
    else{
      userList=this.managerId.toString();
    }
    this.http.get<PaginationDao<EmpReportDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit+"&reportId="+this.managerId+"&team="+this.team+"&show="+this.show+"&brand="+this.brand+"&userlist="+userList).subscribe(value => {

      this.tableData = value;
      this.alert.hideLoader();
    });

  }


  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  tableChange(json) {
    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    this.loadData();

  }

  downloadFile() {
    let userList="0";
    var arr=[];
    if(this.managerId==0)
    {
      for(var i=0;i<this.userList.length;i++)
      {
        arr.push(this.userList[i].id);
      }
      userList=arr.join(",");
    }
    else{
      userList=this.managerId.toString();
    }
    this.http.get<PaginationDao<EmpReportDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=0&limit=20000'+"&reportId="+this.managerId+"&team="+this.team+"&show="+this.show+"&brand="+this.brand+"&userlist="+userList).subscribe(value => {

      var data = value.data;

      var trArr = [];


      var tdArr = [];

      tdArr.push("Team");
      if(this.team=="0")
      {
        tdArr.push("All")
      }
      else{
        tdArr.push(this.team)
      }
      trArr.push(tdArr);

      tdArr = [];

      tdArr.push("Brand");
      if(this.brand=="0")
      {
        tdArr.push("All")
      }
      else{
        tdArr.push(this.brand)
      }
      trArr.push(tdArr);


      tdArr = [];
      tdArr.push("Startdate")
      tdArr.push(this.startdate)

      tdArr.push("Enddate")
      tdArr.push(this.enddate)
      trArr.push(tdArr);
      tdArr = [];
      trArr.push(tdArr);
      tdArr = [];
      trArr.push(tdArr);


      tdArr = [];
      tdArr.push('Date');
      tdArr.push('Team');
      tdArr.push('Mobile No');
      tdArr.push('Emp Id');
      tdArr.push('User Name');
      tdArr.push('Manager Mobile No');
      tdArr.push('Manager Name');
      tdArr.push('Zone');
      tdArr.push('Brand1');
      tdArr.push('QTY');


      trArr.push(tdArr);


      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].date);
        tdArr.push(data[i].team);

        tdArr.push(data[i].mobile);
        tdArr.push(data[i].empId);
        tdArr.push(data[i].empName);

        try {
          tdArr.push(this.getUserDetails(data[i].managerId).phone);
        }catch (e)
        {
          tdArr.push("NA");
        }
        try {
        tdArr.push( this.getUserDetails(data[i].managerId).fullname);
        }catch (e)
        {
          tdArr.push("NA");
        }
        tdArr.push(data[i].zone);
        tdArr.push(data[i].product);
        tdArr.push(data[i].qty);

        trArr.push(tdArr);
      }

      let file = new FileService<any>();
      file.generateFile(trArr, 'Report_Format.xlsx');


    });


  }

  editQty(qty:number,id:number)
  {
    console.log("This is qty ",qty ," and id is ",id)
    this.alert.showLoader();
    this.alert.confirmMessage("Update Quantity?","Are you sure?").then(value => {
      this.http.post(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/?id='+id+'&qty='+qty,{}).subscribe(value1 => {
        this.alert.hideLoader();
      })
    })
  }

  refreshChange() {
    this.offset=0;
    this.limit=10;
    for(var i=0;i<this.userList.length;i++)
    {
      if(this.managerId==this.userList[i].id)
      {
        this.fullname=this.userList[i].fullname;
        this.mobile=this.userList[i].phone;
      }
    }
    this.loadData();
  }
}
