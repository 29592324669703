export interface SubscriptionDao{
  freeTemplatesMessage: number;
  freeSessionMessage: number;
  afterConsumptionTemplateDed: number;
  afterConsumptionSessionDed: number;
  templateCreationDed: number;
  sentTemplate: number;
  sentSession: number;
  expiry: string;
  isActive: boolean;
  transactionId: string;
  subscriptionAmt: number;
  maximumPerCampaign: number;
  templateCreated: number;
  accountId: number;
  freeTemplates: number;
  baseUrl: string;
  userName: string;
  password: string;
  test: boolean;
  deductionType: string;
  gateway: Gateway;
  karixToken: string;
  waVerfiedNumber: string;

  cloudAPIToken: string;
  cloudAPIPhoneNumberID: string;
  cloudAPIWhatsappID: string;
  cloudAPIAppID: string;
  emailTo:string;
  emailCc:string,
  webhookEnable: boolean,
  webhookURL: string,
  current_limit: string;
  phoneNumberEvent: string;
  mediaStoretoDriveStatus: boolean;
  enablePriorityQueue: boolean;
  //test
  outWebhookEnable: boolean;
  outWebhookUrl: string;
  enableConvertUnverified:boolean;
  convertUnverifiedUrl:string;
  dlrReportOnEmail: boolean;
  clientEmail: string;
  enableGoogleSheet:boolean;
  showMessageContent:boolean;
}


export enum Gateway{
  AMEYO='AMEYO',KARIX='KARIX', CLOUDAPI='CLOUDAPI'
}


export interface SubscriptionFormDao{
  accountId: number;
  isReseller?: boolean;

}
