export interface GroupDao<T,T1,T2,T3>
{
  name:T,
  count:T1,
  sessionStartTime:T2,
  sessionEndTime:T3

  percentage?:number
}
export enum TypeOfReport {

  ACTIVESESSIONS,COUNTRYWISE,USERORBUISSNESS
}

export enum SessionType {
  USER,BUISSNESS,AUTHENTICATION,UTILITY,MARKETING
}
export interface UserOrBuisnessIntiatedDao{
  countryCode:string,
  dst:string,
  sessionStartTime:string,
  sessionEndTime:string,
  usrMessageCount:number,
  bsnsMessageCount:number,
  refId:string,
  deduction:number,
  sessionType:SessionType,

}
