import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ChatbotMasterDao} from "../chatbot-master/ChatbotMasterDao";
import {environment} from "../../../../environments/environment.prod";
import {ConversationDao} from "../../../MicroServices/Chatbot/ChatbotConversationFlow/conversation-flow/ConversationDao";
import {MessageDao} from "../../../MessageDao/MessageDao";

const baseUrl=environment.appUrl+ environment.chatbot
@Injectable({
  providedIn: 'root'
})
export class DynamicChatbotService {

  constructor(public http:HttpClient) { }

  getAllMaster(offset:number,limit:number):Observable<any>{
    return this.http.get<Array<ChatbotMasterDao>>(baseUrl + 'api/chatbotmaster/getall?start=' + offset + '&length=' + limit)
  }

  insertChatbotMasterDao(chatbotMasterDao:ChatbotMasterDao):Observable<any>{
    return this.http.post<Array<ChatbotMasterDao>>(baseUrl + 'api/chatbotmaster/insert',chatbotMasterDao)
  }
  updateChatbotMasterDao(chatbotMasterDao:ChatbotMasterDao):Observable<any>{
    return this.http.post<Array<ChatbotMasterDao>>(baseUrl + 'api/chatbotmaster/update',chatbotMasterDao)
  }

  deleteChatbotMasterDao(chatbotMasterId):Observable<any>{
    console.log("chatbotid:",chatbotMasterId)
    return this.http.delete<Array<ChatbotMasterDao>>(baseUrl + 'api/chatbotmaster/delete?id='+chatbotMasterId)
  }

  deleteConversationDao(conversationId):Observable<any>{
    console.log("chatbotid:",conversationId)
    return this.http.delete<Array<ChatbotMasterDao>>(baseUrl + 'api/conversation/delete?id='+conversationId)
  }

  getAllConversation(offset:number,limit:number):Observable<any>{
    return this.http.get<Array<ConversationDao>>(baseUrl + 'api/conversation/getall?start=' + offset + '&length=' + limit)
  }
  getAllConversationByChatbotId(chatbotId,offset:number,limit:number):Observable<any>{
    return this.http.get<Array<ConversationDao>>(baseUrl + 'api/conversation/getByChatbotId?chatbotId='+ chatbotId+'&start=' + offset + '&length=' + limit)
  }

  insertConversationDao(conversationDao:ConversationDao):Observable<any>{
    return this.http.post<Array<ConversationDao>>( baseUrl + 'api/conversation/insert',conversationDao)
  }

  updateConversationDao(conversationDao:ConversationDao):Observable<any>{
    return this.http.post<Array<ConversationDao>>( baseUrl + 'api/conversation/update',conversationDao)
  }
  // getreport(startdate:number,enddate:number,reportBy:number):Observable<any>{
  //   return this.http.get<Array<MessageDao>>(environment.appUrl + environment.whatsappOfficialservice + 'messagereport/getreport?startdate=' + startdate + '&enddate=' +enddate + '&chatside=User&type=template&reportBy='+reportBy)
  // }

}
