export interface ConversationDao{
  id: string;
  unread: boolean;
  lastMessageType: string;
  totalMessage: number;
  lastMessage: string;
  lastMessageTime: number;
  unreadMessage: number;
  saved: boolean;
  contactName: string;
}

export class AssignAgentModel{
  agentAccountId:number;
  mobileNumber:string;
  assignAgent:boolean = false;
  adminAccountId:number;
}
