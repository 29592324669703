import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../Services/User/user.service';
import {MenusubService} from '../Services/MenuSub/menusub.service';


@Component({
  selector: 'app-maincontainer',
  templateUrl: './maincontainer.component.html',
  styleUrls: ['./maincontainer.component.css']
})
export class MaincontainerComponent implements OnInit,OnDestroy {



  constructor(public user:UserService,private menuObj:MenusubService) {

  }

  ngOnDestroy(): void {

    }

  ngOnInit(): void {

  }

}
