import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {SessionDocument} from "../SessionDocument";
import {AgentServiceService} from "../../AgentMaster/agent-service.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-interaction-session-form',
  templateUrl: './interaction-session-form.component.html',
  styleUrls: ['./interaction-session-form.component.css']
})
export class InteractionSessionFormComponent implements OnInit {
  @Output() public eventEmitter = new EventEmitter<string>();

  chatbotNameValidation: any;
  sessionDocument: SessionDocument;
  submitBtnTxt: String = 'Create';
  constructor(private agentService:AgentServiceService,private alertService:AlertMessageService,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog
  ) {

    this.sessionDocument = data.sessionDocumentTemp;
    console.log("dispatchDate",this.sessionDocument)

  }

  ngOnInit(): void {
  }

  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(InteractionSessionFormComponent, {
      width: '830px',
      height: '600px'


    });
  }
  submitMasterForm(sessionDocument: SessionDocument) {
    


    this.agentService.updateSession(sessionDocument).subscribe(value => {
        this.alertService.showSuccesMessage("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });


    //

  }

}
