



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

        </div>
      </div>
      <div style="" class="panel-body">

        <div *ngIf="paginationDao!=null" id="tablediv">
          <div class="table-responsive">


          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th>Amount</th>
              <th>Opening Balance</th>
              <th>Closing Balance</th>
              <th>Transaction Type <b> <i style="color: red" class="fa fa-arrow-up">DEBIT</i> <i style="color: green" class="fa fa-arrow-down">Credit</i></b></th>
              <th>Service</th>
              <th>Entry Type</th>
              <th>Date</th>
              <th>Transaction Id</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: center" *ngIf="paginationDao.total==0">
              <td colspan="8">No Entry</td>


            </tr>
            <ng-container *ngFor="let data of paginationDao.data">
              <tr>
              <td><span style="color: red;font-weight: bolder" *ngIf="data.transationType=='DR'" [innerHTML]="data.drAmount.toFixed(2)"></span><span *ngIf="data.transationType=='CR'"  style="color: green;font-weight: bolder" [innerHTML]="data.crAmount.toFixed(4)"></span></td>

                <td [innerHTML]="data.openingBalance"></td>
                <td [innerHTML]="data.closingBalance"></td>
                <td><b><i *ngIf="data.transationType=='CR'"  style="color: green" class="fa fa-arrow-down"></i><i *ngIf="data.transationType=='DR'" style="color: red" class="fa fa-arrow-up"></i></b></td>


                <td [innerHTML]="data.service"></td>
                <td [innerHTML]="data.voucherType"></td>
                <td [innerHTML]="data.date"></td>
                <td> <span [innerHTML]="data.refId"></span> <button (click)="viewTransaction(data.refId,data.voucherType)" title="Click Here To View Transaction" class="fa fa-info-circle btn btn-add"></button></td>
              </tr>
            </ng-container>



            </tbody>
          </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=paginationDao.total
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </div>

      </div>
    </div>
  </div>
</div>









