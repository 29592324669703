import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaginationDao, WAPagination} from '../../CommonObj/PaginationDao';
import {environment} from '../../../environments/environment.prod';
import {IncomingMessageDao} from './IncomingMessageDao';
import {FileService} from "../../Services/FileUtilsService/FileService";
const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class IncomingMessagesService {



  constructor(private http: HttpClient) { }

  getIncomingMesssage(startDate: string , endDate: string, pageNumber: string,limit:number, accountId: number): Observable<WAPagination<IncomingMessageDao>>
  {
    // tslint:disable-next-line:max-line-length
    // return this.http.get <WAPagination<IncomingMessageDao>>(appPrefix + 'api/messagecontroller/getincomingmessageonly?startDate=' + startDate + '&endDate=' + endDate + '&page=' + pageNumber);
    // tslint:disable-next-line:max-line-length
    return this.http.get <WAPagination<IncomingMessageDao>>(appPrefix + 'api/messagecontroller/getincomingonly?startdate=' + startDate + '&enddate=' + endDate + '&page=' + pageNumber +'&limit=' + limit + '&accountId=' + accountId);
  }


  downloadExternalFile(fileName): Observable<any> {
    const REQUEST_PARAMS = new HttpParams().set('fileName', fileName);
    const REQUEST_URI = appPrefix + 'api/file/download';
    return this.http.get(REQUEST_URI, {params: REQUEST_PARAMS, responseType: 'arraybuffer'})
  }
}
