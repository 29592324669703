
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Role<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Role Status  <sup class="required">*</sup></label>
              <mat-slide-toggle [(ngModel)]="roleDao.status" name="status"></mat-slide-toggle>
            </div>


            <div class="form-group col-lg-12">
              <label>Role name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="roleDao.roleName" name="roleName" required>
            </div>










            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Role Name</th>
              <th> Status</th>

              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.roleName"></td>

                <td ><i *ngIf="data.status" style="color: green" class="fa fa-check"></i><i *ngIf="!data.status" style="color: red" class="fa fa-remove"></i></td>

                <td><i title="Click Here To Edit" (click)="edit(data,ind)"
                       class="btn btn-add fa fa-pencil"></i>


                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







