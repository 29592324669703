import {Component, OnInit} from '@angular/core';
import {RegistrationOtpService} from "../registration-otp.service";
import {RegistrationMasterDao} from "../registration-master-dao";
import {RegistrationOtpDao} from "../registration-otp-dao";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {Router} from "@angular/router";
import {FileService} from "../../../../Services/FileUtilsService/FileService";
import {any} from "codelyzer/util/function";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmMessageComponent} from "../confirm-message/confirm-message.component";

@Component({
  selector: 'app-registration-otp',
  templateUrl: './registration-otp.component.html',
  styleUrls: ['./registration-otp.component.css']
})
export class RegistrationOtpComponent implements OnInit {
  listOfMaster: Array<RegistrationMasterDao>
  listOfMandiName: Array<string>
  registrationDao: RegistrationOtpDao = {
    id: null,
    mandiName: '',
    wsName: '',
    otherWsName: '',
    retailerName: '',
    retailerMobile: '',
    address: '',
    parchiBook: '',
    billAmount: '',
    giftHandOver: false,
    gift: '',
    volumeOfGift: '',
    coupon: '',
    promoterId: null,
    promoterName: '',
    promoterNumber: '',
    date: '',
    dateFilter: '',
    otp: null,
  }
  otpDao: any = {
    id: null,
    createAt: '',
    createAtFilter: '',
    otp: null,
    number: '',
  }
  coupons1: '';
  coupons2: '';
  coupons3: '';
  coupons4:'';
  coupons5:'';
  // coupons: string[];
  addNewDiv: boolean = false;
  showButton: any;
  listOfWs: Array<any>;

  constructor(private service: RegistrationOtpService, private alert: AlertMessageService, private router: Router, private dialog: MatDialog) {


    this.service.getMasterDataBYid().subscribe(value => {
      this.listOfMaster = value;
    })

    this.service.getByMandiName().subscribe(value => {
      console.log('mandiName', value);
      this.listOfMandiName = value;
    })


  }

  enableTextBox: boolean = false;
  sumbitBtnEnabled: boolean = false;

  ngOnInit(): void {


  }

  enable() {
    this.enableTextBox = true;
  }

  sendOtp() {
    if (this.registrationDao.retailerMobile != null || this.registrationDao.retailerMobile != 'undefined') {
      this.otpDao.number = this.registrationDao.retailerMobile;
      this.service.sendOtp(this.otpDao).subscribe(value => {
      });
    } else {
      this.alert.showErrorMessage("Please Enter Retailer Mobile No.")
    }
    //this.sumbitBtnEnabled = true;

    setTimeout(() => {
      this.showButton = true;
    }, 30000); // 30 seconds = 30000 milliseconds
  }

  insert() {
    this.registrationDao.coupon = this.coupons1 + "," + this.coupons2 + "," + this.coupons3+","+this.coupons4+","+this.coupons5
    this.alert.showLoader();
    this.service.save(this.registrationDao).subscribe(value => {
      if (value.id==null)
      {
        this.alert.showErrorMessage("Something Wen't Wrong!")
      } else {
        this.dialog.open(ConfirmMessageComponent, {
          data: value
        }).afterClosed().subscribe(value => {
        })
      }
      // this.dialog.open(ConfirmMessageComponent, {
      //   data: value
      // }).afterClosed().subscribe(value => {
      // })
      // this.alert.showSuccesMessagewithhtml(value);
      // this.alert.showSuccesMessage("Inserted Data Form I'd: "+value.id +" Address: "+value.address+" Bill Amount: "+value.billAmount+" Coupon: "+value.coupon.replace('undefined','')+" date: "+value.date+" Gift: "+value.gift+" Gift Hand Over: "+value.giftHandOver+"others Ws Name: "+value.otherWsName+" Note Book: "+value.parchiBook+" Retailer No: "+value.retailerMobile+" Retailer Name "+value.retailerName+" Volume Of Gift: "+value.volumeOfGift+" Ws NAme: "+value.wsName)
      this.cancel();
      this.alert.hideLoader();
    }, error => {
      this.alert.showErrorMessage("Oops");
      this.alert.hideLoader();
    })
  }


  private generateDataTable(value: any) {
    '';
  }


  private cancel() {
    this.registrationDao = {
      id: null,
      mandiName: '',
      wsName: '',
      otherWsName: '',
      retailerName: '',
      retailerMobile: '',
      address: '',
      parchiBook: '',
      billAmount: '',
      giftHandOver: false,
      gift: '',
      volumeOfGift: '',
      coupon: '',
      promoterId: null,
      promoterName: '',
      promoterNumber: '',
      date: '',
      dateFilter: '',
      otp: null,
    };
  }

  showInputField() {

    var container = document.getElementById('inputContainer');
    var inputCount = container.getElementsByClassName('input-field').length;

    if (inputCount < 2) {
      var coupons = inputCount + 4;
      var inputField = document.createElement('input');
      inputField.setAttribute('type', 'text');
      inputField.setAttribute('class', 'input-field form-control');
      inputField.setAttribute('placeholder', String('Coupon No ' + coupons));
      // inputField.setAttribute('[(ngModel)]', this.coupons4)
      container.appendChild(inputField);
    }
  }


  getWsName(wsName: string) {
    this.service.getByWsName(wsName).subscribe(value => {
      this.listOfWs = value;
    })
  }
}
