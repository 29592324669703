import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { RechargeParamModel } from './credit-debit-report-reseller.model';
import { RechargeService } from '../account-master/recharge/RechargeService/recharge.service';
import { UserService } from '../Services/User/user.service';
import { AccountMasterService } from '../account-master/AccountMasterService/account-master.service';
import { RechargeDao } from '../account-master/recharge/RechargeDao';

@Component({
  selector: 'app-credit-debit-report-reseller',
  templateUrl: './credit-debit-report-reseller.component.html',
  styleUrls: ['./credit-debit-report-reseller.component.css']
})
export class CreditDebitReportResellerComponent implements OnInit {


  services: Array<string> = [];

  dtOptions: any = {bDestroy: true, order: []};
  dtTrigger: Subject<any> = new Subject<any>();

  rechargeList: Array<Map<string, string>>;
  customerWiseCrDrDetailList: Array<Array<RechargeDao>> = []

  getRechargeParam:RechargeParamModel;

  clientsDdl = [];
  startDate:string = "";
  endDate:string = ""

  customerIndex = 0;
  isExpand: boolean = false;
  
  constructor(private rechargeService: RechargeService,public user: UserService,private accountService: AccountMasterService) {

    this.services.push("WHATSAPPOFFICIAL");

    this.getRechargeParam = new RechargeParamModel();

    
  }

  ngOnInit(): void {
    this.getAccontList();
  }

  getBalance() {
    this.getRechargeParam.startDate = this.startDate.split('-').join('');
    this.getRechargeParam.endDate = this.endDate.split('-').join('');

    this.rechargeService.getByAccount(this.getRechargeParam).subscribe(value => {
      this.rechargeList = new Array<Map<string, string>>();
      this.rechargeList = value;
      console.log('rechargeList', this.rechargeList);
      this.dtTrigger.next();
    })
  }

  getCreditDebit(userId: any, index: any) {
    this.customerWiseCrDrDetailList[index] = [];
    this.customerIndex = index;
    this.isExpand = true;
    this.getRechargeParam.accountId = userId;
    // this.showDetail = 'Collapse'
    this.getRechargeParam.startDate = this.getRechargeParam.startDate.split('-').join('');
    this.getRechargeParam.endDate = this.getRechargeParam.endDate.split('-').join('');
    this.rechargeService.getCreditDebitDetail(this.getRechargeParam).subscribe(value => {
      this.customerWiseCrDrDetailList[index] = value;
      userId = null;
      index = null;
    })
  }

  getAccontList(){
    this.accountService.getClientByParentId(this.user.user.accountId).subscribe((data)=>{
      this.clientsDdl = data;
    });
  }


  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  

}
