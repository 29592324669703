<h1 mat-dialog-title style="text-align: center">Services <i class="fa fa-server"></i></h1>

<form ngNativeValidate (submit)="submitData()">
  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Status</label>
      <div class="col-sm-12">
        <mat-slide-toggle name="status" [(ngModel)]="data.accountDetails.active"></mat-slide-toggle>
        Active
      </div>


    </div>
    <div class="form-group col-lg-12">

      <div class="col-sm-12">
        <mat-radio-group [(ngModel)]="data.accountDetails.serviceName" name="serviceName" aria-label="Select an option">
          <ng-container *ngFor="let service of data.services">

            <mat-radio-button [value]="service">{{service}}</mat-radio-button>


          </ng-container>
        </mat-radio-group>
      </div>


    </div>


    <div>


      <div class="form-group col-lg-12">

        <div class="col-sm-12">
          <mat-radio-group [(ngModel)]="data.accountDetails.whatsappCreditDao.accountType" name="accountType"
                           aria-label="Select an option">

            <ng-container *ngFor="let accountType of data.accountType">

              <mat-radio-button [value]="accountType">{{accountType}}</mat-radio-button>


            </ng-container>
          </mat-radio-group>
        </div>


      </div>


      <div *ngIf="data.accountDetails.whatsappCreditDao.accountType=='POSTPAID'" class="form-group col-lg-6">
        <label>Postpaid Maximum Allowed</label>
        <input name="postPaidMaximumAllowed" [(ngModel)]="data.accountDetails.whatsappCreditDao.postPaidMaximumAllowed"
               step="any" type="number" class="form-control" required/>
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
  </mat-dialog-actions>
  <button class="btn btn-add" type="submit">Save</button>
  <button [mat-dialog-close]="null" class="btn btn-danger" type="button">Cancel</button>
</form>

