import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {PaymentDetailsDao, PaymentGatway, PaymentGatwayStatus} from "./PaymentDetailsDao";
import {SubscriptionFilterDao} from "../subscription-package-report/SubscriptionFilterDao";
import {CommoninstancedropdownComponent} from "../Unofficial/commoninstancedropdown/commoninstancedropdown.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {PaymentDetailsService} from "./payment-details.service";
import {FileService} from "../Services/FileUtilsService/FileService";

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  tableList: Array<PaymentDetailsDao>;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  paymentDetailsDao: PaymentDetailsDao = {
  id: '',
  name: '',
  keyId: '',
  keySecret: '',
  amount:0,
  currency: '',
  accountId:0,
  createDate: '',
  createDateFilter:0,
  orderId: '',
  paymentId: '',
  paymentGatway: PaymentGatway.RAZORPAY,
  signature: '',
  paymentGatwayStatus: PaymentGatwayStatus.PENDING
}
  filterDao:SubscriptionFilterDao = {
    startdate:'0',
    enddate:'0',
    offset:0,
    limit:10,
    accountId:0,
    groupBy:'',
    dateFilterType: '0',
    services:'',
    status:true
  };

  constructor(private dialog: MatDialog, private alert:AlertMessageService, private paymetService: PaymentDetailsService) {
    this.loadData();
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  filterData() {
    this.dialog.open(CommoninstancedropdownComponent, {data: []}).afterClosed().subscribe(value => {
      if (value != null) {
        this.filterDao.startdate = value.startdate.replaceAll("-","")+ '000000';
        this.filterDao.enddate = value.enddate.replaceAll("-","") + '235959';
        this.loadData();
      }
    });
  }

  loadData(){
    this.alert.showLoader();
    if(this.filterDao.startdate == '0' && this.filterDao.enddate == '0'){
      let date = new Date();
      let monthStr = (date.getMonth() + 1).toString();
      let onlyDate: string = date.getDate().toString();
      if(monthStr.length != 2){
        monthStr = '0' + monthStr ;
      }
      if(onlyDate.length!=2){
        onlyDate = '0' + onlyDate;
      }
      this.filterDao.startdate = date.getUTCFullYear().toString() + monthStr + '01000000';
      this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate + '235959';
    }
    $('#tableDiv').DataTable().destroy();
    this.paymetService.get(this.filterDao.startdate, this.filterDao.enddate).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.paymentDetailsDao = {
      id: '',
      name: '',
      keyId: '',
      keySecret: '',
      amount:0,
      currency: '',
      accountId:0,
      createDate: '',
      createDateFilter:0,
      orderId: '',
      paymentId: '',
      paymentGatway: PaymentGatway.RAZORPAY,
      signature: '',
      paymentGatwayStatus: PaymentGatwayStatus.PENDING
    }
    this.filterDao = {
      startdate:'0',
      enddate:'0',
      offset:0,
      limit:10,
      accountId:0,
      groupBy:'',
      dateFilterType: '0',
      services:'',
      status:true
    };
    this.loadData();
  }

  downloadReport() {
    this.paymetService.get(this.filterDao.startdate, this.filterDao.enddate).subscribe(paymentDetails => {
      let file = new FileService<any>();
      var trArr = [];
      var tdArr = [];
      tdArr.push("ID");
      tdArr.push("Create Date");
      tdArr.push("Account");
      tdArr.push("Gatway");
      tdArr.push("Currency");
      tdArr.push("Order Id");
      tdArr.push("Payment Id");
      tdArr.push("Amount");
      tdArr.push("Status");
      trArr.push(tdArr);
      for (var i = 0; i < paymentDetails.length; i++) {
        tdArr = [];
        tdArr.push(paymentDetails[i].id);
        tdArr.push(paymentDetails[i].createDate);
        tdArr.push(paymentDetails[i].accountId)
        tdArr.push(paymentDetails[i].paymentGatway);
        tdArr.push(paymentDetails[i].currency);
        tdArr.push(paymentDetails[i].orderId);
        tdArr.push(paymentDetails[i].paymentId);
        tdArr.push(paymentDetails[i].amount);
        tdArr.push(paymentDetails[i].paymentGatwayStatus);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,'Payment_Report_' + new Date().getDate() + '.xlsx');
    });
  }
}
