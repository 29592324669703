import {Component, OnInit} from '@angular/core';
import {ServiceMasterDao} from '../servicemaster/ServiceMasterDao';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {ServiceMasterService} from '../servicemaster/ServiceMasterService/service-master.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {ModuleMasterDao} from './ModuleMasterDao';
import {ModulemasterServiceService} from './ModuleMasterService/modulemaster-service.service';
import {MenuMasterDao} from '../menumaster/MenuMasterDao';
import {MenuMasterService} from '../menumaster/MenuMasterService/menu-master.service';
import {UrlMasterDao} from './UrlMasterService/UrlMasterDao';
import {UsermasterService} from '../Services/UsermasterService/usermaster.service';
import {UrlMasterService} from './UrlMasterService/url-master.service';

@Component({
  selector: 'app-module-master',
  templateUrl: './module-master.component.html',
  styleUrls: ['./module-master.component.css']
})
export class ModuleMasterComponent implements OnInit {
  urlMasterArray:Array<UrlMasterDao>=null;
  addNewDiv: boolean = false;
  serviceId: number = 0;
  serviceName:string="";
  menuList: Array<MenuMasterDao> = [];
  serviceList: Array<ServiceMasterDao> = [];
  data: ModuleMasterDao = {id: 0, menuId: 0, moduleName: '', moduleUrl: '', serviceId: 0, showOnMenu: true};
  tableList: ClientSidePagination<ModuleMasterDao> = {data: [], end: 0, start: 0, total: 0};

  constructor(private service: ModulemasterServiceService, private alert: AlertMessageService, private menuService: MenuMasterService, private services: ServiceMasterService,private urlMasterService:UrlMasterService) {
    this.menuService.getRoles().subscribe(value => {
      this.menuList = value;
    });
    this.services.getRoles().subscribe(value => {
      this.serviceList = value;
      this.changeServiceId(this.serviceList[0].id,this.serviceList[0].serviceName)
    });


  }
  addUrlMaster()
  {
    var count=(this.urlMasterArray.length);
    this.urlMasterArray.push({actionAlias: this.data.moduleName+' ', actionId: count, id: 0, moduleId: this.data.id, role: 'ROLE_'+this.data.moduleName.toUpperCase().replace(/\s+/g, '')+'_'+count, url: '/'+this.data.moduleUrl.toLowerCase()+'/'})
  }
  getData() {
    this.alert.showLoader();
    this.service.get(this.serviceId).subscribe(value => {
      this.tableList = {data: value, end: 10, start: 0, total: value.length};
      this.alert.hideLoader();
    });

  }

  changeServiceId(id: number,serviceName:string) {
    this.serviceName=serviceName;
    this.serviceId = id;

    this.cancel();

  }

  ngOnInit(): void {
  }

  isOrUpdate() {
    this.data.serviceId=this.serviceId;
    this.alert.showLoader();
    this.alert.confirmMessage('Save Module To '+this.serviceName+'?', 'Are you sure?').then(value => {
      this.service.save(this.data).subscribe(value1 => {
        this.data=value1;
        if(this.urlMasterArray==null)
        {
          this.urlMasterArray=[];
          this.urlMasterArray.push({actionAlias: this.data.moduleName+' Page', actionId: 0, id: 0, moduleId: this.data.id, role: 'ROLE_'+this.data.moduleName.toUpperCase().replace(/\s+/g, '')+'_'+0, url: this.data.moduleUrl})

        }


        this.urlMasterService.save(this.urlMasterArray).subscribe(value2 => {
          this.urlMasterArray=value2;
        })
        this.alert.showSuccesMessage('Successfully saved into '+this.serviceName+'!!');

        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage('Duplicate Module!!');
        this.alert.hideLoader();
      });
    },);

  }

  cancel() {

    this.getData();
    this.urlMasterArray=null;
    this.addNewDiv = false;
    this.data = {id: 0, menuId: this.menuList[0].id, moduleName: '', moduleUrl: '', serviceId: this.serviceList[0].id, showOnMenu: true};
  }

  edit(data: ModuleMasterDao, ind: number) {
    this.alert.showLoader();
    this.urlMasterService.get(data.id).subscribe(value => {
      this.urlMasterArray=value;
      this.data = data;
      this.addNewDiv = true;
      this.alert.hideLoader();
    })



  }

  addNew() {
    this.data = {id: 0, menuId: this.menuList[0].id, moduleName: '', moduleUrl: '', serviceId: this.serviceList[0].id, showOnMenu: true};
    this.addNewDiv = true;
    this.urlMasterArray=null;
  }


  deleteRow(ind: number) {
    this.urlMasterArray.splice(ind, 1);
  }
}
