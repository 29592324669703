import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apidocs',
  templateUrl: './apidocs.component.html',
  styleUrls: ['./apidocs.component.css']
})
export class ApidocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
