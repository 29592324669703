import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {Agent} from "../../AgentSupport/AgentMaster/agent-master/AgentDao";
import {environment} from "../../../environments/environment.prod";
import {ChatbotMasterDao} from "../../MicroServices/Chatbot/chatbot-master/ChatbotMasterDao";
import application from "@angular-devkit/build-angular/src/babel/presets/application";
import {SessionDocument} from "../interaction-session/SessionDocument";

@Injectable({
  providedIn: 'root'
})
export class AgentServiceService {

  constructor(private http: HttpClient) {
  }


  getReport(accountId: number,startDate:number,endDate:number,offset: number, limit: number): Observable<PaginationMain<Agent>> {
    // return this.http.get<PaginationMain<Agent>>("http://192.168.1.47:8080/custom/v1/api/agentmaster/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<PaginationMain<Agent>>( environment.appUrl+environment.customappurl+'api/agentmaster/getall?accountId=' +accountId +"&startDate="+startDate+"&endDate="+endDate+ "&offset=" + offset + "&limit=" + limit);
  }

  insertmany(agents:Array<Agent>):Observable<any>{
    // return this.http.post<Array<Agent>>("http://192.168.1.12:8080/custom/v1/api/agentmaster/insertmany",agents)

    return this.http.post<Array<Agent>>(environment.appUrl+environment.customappurl+'api/agentmaster/insertmany',agents)
  }

  update(agent:Agent):Observable<any>{
     // return this.http.post<Array<Agent>>("http://192.168.1.12:8080/custom/v1/api/agentmaster/update",agent)

    return this.http.post<Array<Agent>>(environment.appUrl+environment.customappurl+'api/agentmaster/update',agent)
  }

  insert(agent:Agent):Observable<any>{
    return this.http.post<Array<Agent>>(environment.appUrl+environment.customappurl+'api/agentmaster/insert',agent)
  }

  getSession(accountId: number,startDate:number,endDate:number, offset: number, limit: number): Observable<PaginationMain<SessionDocument>> {
    console.log("session")
    // return this.http.get<PaginationMain<SessionDocument>>(  'http://192.168.1.12:9096/custom/v1/'+'api/sessiondocument/getall?accountId=' +accountId + "&offset=" + offset + "&limit=" + limit);

    return this.http.get<PaginationMain<SessionDocument>>(  environment.appUrl+environment.customappurl+'api/sessiondocument/getall?accountId=' +accountId + "&startDate=" + startDate+ "&endDate=" + endDate +"&offset=" + offset + "&limit=" + limit);

  }

  updateSession(sessionDocument:SessionDocument):Observable<any>{
    // return this.http.post<Array<SessionDocument>> ('http://192.168.1.12:9096/custom/v1/'+'api/sessiondocument/update',sessionDocument);
    return this.http.post<Array<SessionDocument>> (environment.appUrl+environment.customappurl+'api/sessiondocument/update',sessionDocument);

  }




}
