import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {InstanceMasterDao} from '../InstanceMasterDAo';
import {HttpClient} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import {QrStatus, ResponseCommon} from '../ResponseCommon';
import QRCode from 'qrcode';
import {MatDialog} from '@angular/material/dialog';
import {QrWindowComponent} from '../qr-window/qr-window.component';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {InstanceGroupDao} from '../../dashboardunws/InstanceGroupDao';
import {PageDao} from '../../../Feature/page-dao';
import {FeatureDao} from "../../../Feature/feature-dao";

const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
const appPrefix1 = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class InstanceMasterService {
  intervalSubscription;


    constructor(private http: HttpClient, private dialog: MatDialog, private alert: AlertMessageService) {
  }

  get(): Observable<Array<InstanceMasterDao>> {
    return this.http.get<Array<InstanceMasterDao>>(appPrefix + 'api/instance/');
  }

  getActive(): Observable<Array<InstanceMasterDao>> {
    return this.http.get<Array<InstanceMasterDao>>(appPrefix + 'api/instance/active');
  }

  save(inc: InstanceMasterDao): Observable<InstanceMasterDao> {
    return this.http.post<InstanceMasterDao>(appPrefix + 'api/instance/', inc);
  }

  // tslint:disable-next-line:typedef
  loadQrLegacy(id: number) {
    this.alert.showLoader();
    // tslint:disable-next-line:prefer-const
    let current = this;
    this.dialog.open(QrWindowComponent);
    return this.http.get<ResponseCommon<string, QrStatus>>(appPrefix + 'api/instance/loadqrlegacy?id=' + id).subscribe(value => {
      let canvas = document.getElementById('canvas');

      if (value.status == QrStatus.QR) {
        QRCode.toCanvas(canvas, value.data, function (error) {
          current.alert.hideLoader();
        });
      } else {
        this.alert.showErrorMessage('Failed Generating Qr !!Please contact Support');
      }


    });
  }

  loadQr(id: number) {
    this.alert.showLoader();
    let current = this;
    this.dialog.open(QrWindowComponent,{data:id})
    return this.http.get<ResponseCommon<string, QrStatus>>(appPrefix + 'api/instance/loadqr?id=' + id).subscribe(value => {
      let canvas = document.getElementById('canvas');

      if (value.status == QrStatus.QR) {
        QRCode.toCanvas(canvas, value.data, function (error) {
          current.alert.hideLoader();
        });
      } else {
        this.alert.showErrorMessage('Failed Generating Qr !!Please contact Support');
      }

      // Start the interval
      this.intervalSubscription = interval(3000).subscribe(() => {
        this.getActive().subscribe(value1 => {
          for (let i = 0; i < value1.length; i++) {
            if (value1[i].id == id && value1[i].connected) {
              this.alert.showSuccesMessage("Your number has been successfully scanned !!");
              // Assuming you want to stop the interval when the condition is met
              this.intervalSubscription.unsubscribe();
            }
          }
        });
      });
      console.log('after load qr code', value);
    });
  }

  logout(id: number): Observable<ResponseCommon<string, boolean>> {
    return this.http.get<ResponseCommon<string, boolean>>(appPrefix + 'api/instance/logout?id=' + id);
  }

  reboot(id: number): Observable<ResponseCommon<string, boolean>> {
    return this.http.get<ResponseCommon<string, boolean>>(appPrefix + 'api/instance/reboot?id=' + id);
  }

  getReport(id: number, startdate: string, enddate: string): Observable<Array<InstanceGroupDao>> {
    return this.http.get<Array<InstanceGroupDao>>(appPrefix + 'api/instance/getreport?id=' + id + '&startdate=' + startdate + '&enddate=' + enddate);
  }

  getPagePermission(pageName: string): Observable<FeatureDao> {
    return this.http.get<FeatureDao>(appPrefix1 + 'auth/v1/api/pagepermission/getbypage?pageName=' + pageName);
  }



}
