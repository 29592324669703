import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserMasterDao} from './User/UserMasterDao';
import {environment} from '../../environments/environment.prod';
const  appPrefix=environment.appUrl+environment.accountMicroverservice;
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private http:HttpClient) { }


  getAllServices():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/getservices");
  }
  getAllServiceType():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/getserviceType");
  }

  getAllAccoutMode():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/accountmode");
  }
  getdeductionType():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/deductionType");
  }
  getaccountType():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/accountType");
  }
  getrechargeType():Observable<Array<string>>
  {
    return this.http.get<Array<string>>(appPrefix+"service/getrechragetype");
  }
}
