
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
                <h4>Agent Master</h4>
            </div>
            <button  (click)="addOrUpdateAgentMaterForm(undefined,'Create')"  class="btn btn-add">Add Agent<i
              class="fa fa-plus"></i></button>
            <button style="margin-left: 5px" class="btn btn-add " title="Bulk Upload (Maximum 1000)"
                    (click)="bulkUpload()">Bulk Upload <i (click)="downloadSampleFile()"
                                                          title="Click Here To View Sample File"
                                                          class="fa fa-question-circle"></i>
            </button>
            <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
            <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
            <button (click)="downloadFile()" class="btn btn-add">Download<i class="fa fa-download"></i></button>

          </div>

        </div>

        <div style="" class="panel-body">
          <div class="table-responsive">
            <table  id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Number</th>
                <th>Date</th>

                <th>Account Id</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>

              <ng-container *ngFor="let data of agentList;let ind = index">
                <tr>
                  <td  [innerHTML]="data.agentId"  ></td>
                  <td  [innerHTML]="data.agentName"  ></td>
                  <td  [innerHTML]="data.agentNumber"  ></td>
                  <td  [innerHTML]="data.createDate"  ></td>
                  <td  [innerHTML]="data.accountId"  ></td>
                  <td><button class="btn btn-add" (click)="addOrUpdateAgentMaterForm(data,'Update')"><i class="fa fa-pencil"></i></button>
                    <i *ngIf="data.accountId=='20922'" title="Click Here To Copy QR url" (click)="copy(data)"
                       class="btn btn-add fa fa-copy m-r-2"></i>
                    <!--                    <button class="btn btn-danger m-r-2" (click)="deleteConversation(data)"><i class="fa fa-trash"></i></button>-->
                  </td>
                </tr>

              </ng-container>
              </tbody>
            </table>
          </div>
        </div>


<!--        <div id="tablediv">-->
<!--          <div class="table-responsive">-->
<!--            <table class="table table-bordered table-striped table-hover">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th>ID</th>-->
<!--                <th>Name</th>-->
<!--                <th>Number</th>-->
<!--                <th>Account Id</th>-->
<!--                <th>Action</th>-->

<!--                &lt;!&ndash;                <th>Type</th>&ndash;&gt;-->


<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="text-align: center" *ngIf="agentMasterList.recordsTotal==0">-->
<!--                <td colspan="14">No Entry</td>-->


<!--              </tr>-->
<!--              <ng-container *ngFor="let data of agentMasterList.data;let ind = index">-->
<!--                <tr>-->
<!--                  <td  [innerHTML]="data.agentId"  ></td>-->
<!--                  <td  [innerHTML]="data.agentName"  ></td>-->
<!--                  <td  [innerHTML]="data.agentNumber"  ></td>-->
<!--                  <td  [innerHTML]="data.accountId"  ></td>-->
<!--                  <td><button class="btn btn-add" (click)="addOrUpdateAgentMaterForm(data,'Update')"><i class="fa fa-pencil"></i></button>-->
<!--                    <i *ngIf="data.accountId=='20922'" title="Click Here To Copy QR url" (click)="copy(data)"-->
<!--                       class="btn btn-add fa fa-copy m-r-2"></i>-->
<!--&lt;!&ndash;                    <button class="btn btn-danger m-r-2" (click)="deleteConversation(data)"><i class="fa fa-trash"></i></button>&ndash;&gt;-->
<!--                  </td>-->


<!--                </tr>-->
<!--              </ng-container>-->



<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->



<!--          <mat-paginator (page)="tableChange($event)" [length]=agentMasterList.recordsTotal-->
<!--                         [pageSize]="10"-->
<!--                         [pageSizeOptions]="[2, 5, 25, 100]">-->
<!--          </mat-paginator>-->
<!--        </div>-->


        <!--//-->
      </div>
    </div>
  </div>


</section>
