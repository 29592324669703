import { Component, OnInit } from '@angular/core';
import {PriceChartDao} from "../price-chart/PriceChartDao";
import {UserMasterDao} from "../../../Services/User/UserMasterDao";
import {UsermasterService} from "../../../Services/UsermasterService/usermaster.service";
import {UserService} from "../../../Services/User/user.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {environment} from "../../../../environments/environment.prod";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {OutletDao} from "./OutletDao";

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.css']
})
export class OutletComponent implements OnInit {


  outletList: Array<OutletDao> = [];
  update = false;

  startdate: number = undefined;
  enddate: number = undefined;

  outletDao: OutletDao = {
    id:0,
    name:'',
    address:'',
    state:'',
    city:'',
    pincode:'',
    createBy:'',
    createDate:'',
    lat:'',
    lng:''
  };
  addNewDiv: boolean = false;

  constructor(private user:UsermasterService,private userData:UserService,private alert: AlertMessageService, private router: Router, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.http.get<Array<OutletDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/outlet/getall?').subscribe(value => {
      this.outletList = value;
      this.alert.hideLoader();
    });
  }

  addNew() {
    this.outletDao = {
      id:0,
      name:'',
      address:'',
      state:'',
      city:'',
      pincode:'',
      createBy:'',
      createDate:'',
      lat:'',
      lng:''
    };
    this.update = false;
    this.addNewDiv = true;
  }

  isOrUpdate() {
    this.alert.showLoader()
    var message="Create Outlet?"
    var successMessage="Successfully Created Outlet!";
    let url = 'api/nouriture/outlet/insert'
    if(this.update)
    {
      successMessage="Successfully Updated Outlet!";
      message="Update Outlet?"
      url = 'api/nouriture/outlet/update'
    }

    this.alert.confirmMessage("Are you sure?",message).then(value => {
      this.http.post<OutletDao>(environment.appUrl + environment.gsmgatewayservice + url,this.outletDao).subscribe(value => {
        this.outletDao=value;
        this.alert.showSuccesMessage(successMessage);
        this.alert.hideLoader();

      },error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage("Error!!")
      })
    })

  }

  cancel() {
    this.update = false;
    this.addNewDiv = false;
    this.getData();
  }

  edit(outletDao: OutletDao) {
    this.outletDao = outletDao;
    this.update = true;
    this.addNewDiv = true;

  }


  filter() {
    this.dialog.open(DatefilterComponent);
  }


  getData() {
    this.alert.showLoader();

    this.http.get<Array<OutletDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/outlet/getall?').subscribe(value => {
      this.outletList = value;
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  delete(outlet: OutletDao, ind: number) {
    this.alert.confirmMessage('Are you Sure?', 'Delete ' + outlet.name + ' ? ').then(value => {
      this.http.delete(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/outlet/delete?id=' + outlet.id).subscribe(value => {
        this.alert.showSuccesMessage("Successfully deleted!")
        this.getData();



      });
    });
  }
}


