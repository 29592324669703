import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {HDFCampDao} from "../c2c-report/HDFCampDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../environments/environment.prod";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";

@Component({
  selector: 'app-c2c-panel',
  templateUrl: './c2c-panel.component.html',
  styleUrls: ['./c2c-panel.component.css']
})
export class C2cPanelComponent implements OnInit {

  tableData: PaginationDao<HDFCampDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public empname:string="0";
  public empcode:string="0";
  public empmsisdn:string="0";
  public startdateStr:string="0";
  public enddateStr:string="0";


  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getc2clist?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {
      this.tableData = value;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  clearFilter() {
    this.empname ="0"
    this.empcode ="0"
    this.empmsisdn ="0"
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      if(value==null)
      {
        return;
      }

      let al:Array<HDFCampDao>=[]
      for(var i=0;i<value.length;i++)
      {
        let row = {
          id:0,
          msisdn:value[i]["emp_Number"],
          empName:value[i]["emp_Name"],
          empCode:value[i]["emp_Code"],
          campaignType:"c2c",

        }
       al.push(<HDFCampDao>row)
      }
      console.log(al)
      this.alert.confirmMessage("Are you sure?","Upload "+al.length+" records?").then(res => {
        this.alert.showLoader();
        this.http.post<Array<string>>(environment.hdfcBaseUrl +  'hdfccampaign/upload',al).subscribe(alret => {

          this.alert.hideLoader();
          this.alert.showSuccesMessage("Successfully Uploaded")
          this.loadData();
        },error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage("Failed To Upload!!")
        })
      })

    })

  }


  download() {
    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getc2clist?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit).subscribe(value => {
     // this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr =["Emp Name","Emp Code","Emp Mobile","Upload Time"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].empName);
        tdArr.push(data[i].empCode);
        tdArr.push(data[i].msisdn);
        tdArr.push(data[i].requestTime);
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  makeCall(empnumber:string){
    this.alert.confirmMessage(`Make call to ${empnumber} ?`,"Confirm").then(confirm=>{
      if(confirm){
        this.http.get<any>(environment.hdfcBaseUrl + 'hdfccampaign/makecall??msisdn='+empnumber+'&agentnumber='+this.user.user.phone)
      }
    })
  }

}
