
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
              <a href="#">
                <h4>Language Master</h4>
              </a>
            </div>
            <button  (click)="addOrUpdateLanguageMaterForm(undefined,'Create')"  class="btn btn-add">Add<i
              class="fa fa-plus"></i></button>

            &nbsp;
          </div>

        </div>

        <div id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Language</th>
                <th>C2C Agent</th>
                <th >Live Chat Agent</th>
                <th >Action</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="languageWiseAgents.recordsTotal==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of languageWiseAgents.data;let ind = index">
                <tr>
                  <td  [innerHTML]="data.language"  ></td>
                  <td  [innerHTML]="data.agentNumbers"   ></td>
                  <td  [innerHTML]="data.chatAgentNumber"   ></td>


                  <td><button class="btn btn-add" (click)="addOrUpdateLanguageMaterForm(data,'Update')"><i class="fa fa-pencil"></i></button>&nbsp;&nbsp;
                    <button class="btn btn-danger m-r-2" (click)="deleteLanguage(data.id)"><i class="fa fa-trash"></i></button>
                  </td>



                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>



          <mat-paginator (page)="tableChange($event)" [length]=languageWiseAgents.recordsTotal
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>
        </div>


        <!--//-->
      </div>
    </div>
  </div>


</section>
