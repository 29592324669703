import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FilterDao} from '../crdrreport/FilterDao';
import {Observable} from 'rxjs';
import {PaginationDao} from '../CommonObj/PaginationDao';
import {AccountPostingDao} from './AccountPostingDao';
import {environment} from '../../environments/environment.prod';
import {GroupReportDao} from '../crdrreport/GroupReportDao';
import {TransactionService} from './TransactionService/transaction.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../Services/User/user.service';
import {MatDialog} from '@angular/material/dialog';
import {RechargeViewDao} from '../account-master/recharge/recharge-view/RechargeViewDao';
import {RechargeViewComponent} from '../account-master/recharge/recharge-view/recharge-view.component';
import {AccountFilterDao} from '../account-master/account-filter/AccountFilterDao';
import {AccountFilterComponent} from '../account-master/account-filter/account-filter.component';
import {EnumServiceService} from '../Services/EnumController/enum-service.service';
import {FileService} from '../Services/FileUtilsService/FileService';

@Component({
  selector: 'app-transactionreport',
  templateUrl: './transactionreport.component.html',
  styleUrls: ['./transactionreport.component.css']
})
export class TransactionreportComponent implements OnInit {
  paginationDao: PaginationDao<AccountPostingDao> = null;
  dateFilterType: Array<string>;
  services: Array<string>;
  groupBy: Array<string>;
  private filter: FilterDao;
  constructor(private transService: TransactionService, private route: ActivatedRoute, private user: UserService, private dialog: MatDialog, private router: Router, private enumService: EnumServiceService) {


    enumService.getArrrayEnum('groupby').subscribe(value => {
      this.groupBy = value;
    });
    enumService.getArrrayEnum('getdatefilter').subscribe(value => {
      this.dateFilterType = value;
    });
    const serviceArr = [];

    for (let i = 0; i < this.user.accountMasterDao.services.length; i++)
    {
      serviceArr.push(this.user.accountMasterDao.services[i].serviceName);
    }
    serviceArr.push('NONE');
    this.services = serviceArr;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const filterDao: FilterDao = {accountId: queryParams.accountId || 0, dateFilterType: queryParams.dateFilterType || 'CURRENTMONTH', enddate: queryParams.enddate || '0', groupBy: queryParams.groupBy || 'DAY', limit: 10, offset: 0, services: queryParams.services || 'NONE', startdate:  queryParams.startdate || '0'};
      this.filter = filterDao;
      this.loadData(filterDao);
    });
  }


  private loadData(filterDao: FilterDao) {
    if (filterDao.accountId == 0)
    {
      filterDao.accountId = this.user.user.accountId;
    }
    this.transService.getData(filterDao).subscribe(value => {
      this.paginationDao = value;
    });
  }

  private getDataAndDownload(filterDao: FilterDao)
  {
    this.transService.getData(filterDao).subscribe(value => {
      const data = value.data;
      const trArr = [];
      let tdArr = [];
      tdArr.push('Amount');
      tdArr.push('Transaction Type');
      tdArr.push('Service');
      tdArr.push('Entry Type');
      tdArr.push('Date');
      tdArr.push('Transaction Id');
      trArr.push(tdArr);


      for (let i = 0; i < data.length; i++)
      {
        tdArr = [];
        if (data[i].transationType == 'CR')
        {
          tdArr.push(data[i].crAmount.toFixed(2));
        }else{
          tdArr.push(data[i].drAmount.toFixed(2));
        }
        tdArr.push(data[i].transationType);
        tdArr.push(data[i].service);
        tdArr.push(data[i].voucherType);
        tdArr.push(data[i].date);
        tdArr.push(data[i].refId);
        trArr.push(tdArr);

      }

      const file = new FileService<any>();
      file.generateFile(trArr, 'TransactionReport.xlsx');

    });

  }

  tableChange(json) {
    console.log('json is ', json);
    this.filter.offset = json.pageIndex * json.pageSize;
    this.filter.limit = json.pageSize;
    this.loadData(this.filter);
  }

  viewTransaction(refId: string, voucherType: string) {
    if (voucherType == 'Recharge' || voucherType == 'Recharge_Return')
    {
      const rechargeView: RechargeViewDao = {id: refId, heading: voucherType};
      this.dialog.open(RechargeViewComponent, {data: rechargeView});
    }

  }

  clearFilter()
  {
    this.router.navigate(['crdrtrans']);
  }
  filterData() {
    const accountFilterDao: AccountFilterDao = {
      datefilterType: this.dateFilterType,
      filter: this.filter,
      groupBy: this.groupBy,
      pageName: 'crdrtrans',
      service: this.services
    };
    this.dialog.open(AccountFilterComponent, {data: accountFilterDao});
  }

  downloadFile() {

    const tempFilter = this.filter;
    tempFilter.limit = this.paginationDao.total;
    this.getDataAndDownload(tempFilter);



  }


}
