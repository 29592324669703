<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <div>
            <button (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
            <button (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
            <button (click)="downloadFile()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>

          </div>
        </div>
      </div>
      <div style="" class="panel-body">


        <div class="table-responsive">
          <table class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Date and Time</th>
              <th>Sender</th>
              <th>Group Name</th>
              <th>Message Type</th>
              <th>Message</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let data2 of tableList.data;let ind =index">
              <tr>
                <td
                  [innerHTML]="(data2.dateTimeFilter.toString() | slice:0:4)+('-')+(data2.dateTimeFilter.toString() | slice:4:6)+('-')+(data2.dateTimeFilter.toString() | slice:6:8)+(' ')+(data2.dateTimeFilter.toString() | slice:8:10)+(':')+(data2.dateTimeFilter.toString() | slice:10:12)+(':')+(data2.dateTimeFilter.toString() | slice:12:14)"></td>
                <td
                  [innerHTML]="data2.dst.replace('status@broadcast (','').replace('@s.whatsapp.net','').replace(')','')"></td>
                <td [innerHTML]="data2.groupName"></td>

                <td [innerHTML]="data2.messageType"></td>
                <td>
                  <ng-container *ngIf="data2.messageType=='TEXT' ">{{data2.textMessage.content}}</ng-container>
                  <ng-container *ngIf="data2.messageType=='FILE' ">
                    {{data2.fileMessage.fileName}}
                  </ng-container>

                  <ng-container *ngIf="data2.messageType=='BUTTONS' ">{{data2.textMessage.content}}</ng-container>
                </td>
                <td [innerHTML]="data2.messageStatus"></td>

                <td ><i title="Click Here To View More Option " [matMenuTriggerFor]="menu"
                       class="btn btn-add fa fa-ellipsis-v"></i></td>

<!--                <div *ngIf="data2.messageType=='FILE' " >-->
                <mat-menu #menu="matMenu" >
                  <button mat-menu-item (click)="viewMedia(data2)">
                    <i title="Click Here To View"
                       class="fa fa-eye"></i>
                    <span>&nbsp;&nbsp;&nbsp;View</span>
                  </button>

<!--                  <button mat-menu-item (click)="downloadMedia(data2)">-->
<!--                    <i title="Click Here To Download"-->
<!--                       class="fa fa-download"></i>&nbsp;&nbsp;-->
<!--                    <span>Download</span>-->
<!--                  </button>-->


                </mat-menu>
<!--                </div>-->
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <mat-paginator
          (page)="tableChange($event)"
          [length]=tableList.recordsTotal
          [pageSize]="10">
        </mat-paginator>

      </div>
    </div>
  </div>
</div>

