import { Component, OnInit } from '@angular/core';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {CigarateDao} from '../../Cigarate/cigarate-panel-dash/CigarateDao';
import {UserService} from '../../../../Services/User/user.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {formatDate} from '@angular/common';
import {environment} from '../../../../../environments/environment.prod';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {ChatbotDao, ChatBotDaoTemp, ChatBotDetailsDao} from '../ChatbotDao';
import {EmasolTransactionComponent} from '../emasol-transaction/emasol-transaction.component';

@Component({
  selector: 'app-emasolchatbot',
  templateUrl: './emasolchatbot.component.html',
  styleUrls: ['./emasolchatbot.component.css']
})
export class EmasolchatbotComponent implements OnInit {

  tableData: PaginationDao<ChatBotDaoTemp> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {
    this.loadData();
    //user.getAccount().id
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
    });
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }

    // if (this.startdate == undefined || this.enddate == undefined) {
    //   this.startdate = 20101010;
    //   this.enddate = 20301010;
    // }

    this.http.get<PaginationDao<ChatBotDaoTemp>>(environment.appUrl + environment.digimozoservice + 'api/v1/chatbot/?startdate=' + this.startdate+'&endddate='+this.enddate).subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });

  }


  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  downloadFile() {

    this.http.get<PaginationDao<ChatBotDaoTemp>>(environment.appUrl + environment.digimozoservice + 'api/v1/chatbot/?startdate=' + this.startdate+'&endddate='+this.enddate).subscribe(value => {
      this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr = [];
/*      <th>Id</th>
      <th>Pincode</th>
      <th>Full Address of the Customer</th>
      <th>Name</th>
      <th>Email</th>
      <th>Mobile</th>
      <th>Product Name</th>
      <th>Quantity</th>
      <th>Rate</th>
      <th>Grand Total</th>
      <th>Payment Status</th>
      <th>Payment Mode</th>
      <th>Initiation Datetime</th>

      <th>Payment Id</th>*/

      tdArr.push('Order-Id');
      tdArr.push('Pincode');
      tdArr.push('Address');
      tdArr.push('Name');
      tdArr.push('Email');
      tdArr.push('Mobile');

      tdArr.push('Product Name');
      tdArr.push('Quantity');
      tdArr.push('Rate');

      tdArr.push('Grand Total');
      tdArr.push('Payment Status');
      tdArr.push('Payment Mode');
      tdArr.push("Initiation Datetime");

      trArr.push(tdArr);


      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].id);
        tdArr.push(data[i].pincode);
        tdArr.push(data[i].address);
        tdArr.push(data[i].firstName);
        tdArr.push(data[i].email);
        tdArr.push(data[i].phoneNumber);
        tdArr.push(data[i].productName);
        tdArr.push(data[i].qty.toFixed(2));
        tdArr.push(data[i].rate.toFixed(2));
        tdArr.push(data[i].grandTotal.toFixed(2));

        if (data[i].orderStatus==1) {
          tdArr.push("Paid");
        } else {
          tdArr.push("Not Paid");
        }

        tdArr.push(data[i].paymentMode);
        tdArr.push(data[i].date);


        trArr.push(tdArr);
      }

      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');


    });


  }


  getTransaction(id: number) {

    this.http.get<Array<ChatBotDetailsDao>>(environment.appUrl + environment.digimozoservice + 'api/v1/chatbot/getdetails?idfk=' + id).subscribe(value => {
      this.dialog.open(EmasolTransactionComponent,{data:value})

    });

  }
}
