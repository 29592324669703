import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatbotconfig',
  templateUrl: './chatbotconfig.component.html',
  styleUrls: ['./chatbotconfig.component.css']
})
export class ChatbotconfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
