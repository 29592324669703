import { Component, OnInit } from '@angular/core';
import {UserService} from '../Services/User/user.service';
import {MatDialog} from '@angular/material/dialog';
import {ProfileEditComponent} from './profile-edit/profile-edit.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {environment} from "../../environments/environment.prod";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(public user:UserService,private dialog:MatDialog) { }

  ngOnInit(): void {
  }

  editProfile() {
    this.dialog.open(ProfileEditComponent).afterClosed().subscribe(value => {

    })
  }
  editPassword()
  {
    this.dialog.open(ChangePasswordComponent).afterClosed().subscribe(value => {

    })
  }

    openWebhookDocument() {
        window.open(environment.driveLocationUrl + "Shareablelinks/20004/13344/WebhookConfig.pdf")
    }
}
