
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">
          <button  class="btn btn-add" id="addgroups"  (click)="addGroup()"  title="Edit Group Name" ><i class="fa fa-plus" >&nbsp;&nbsp;New Group</i></button>
          <h2 style="margin-left: 350px"><b>PRP Services Private Limited</b></h2>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="!addNewDiv">
        <div  class="table-responsive">
          <div style="" class="panel-body">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" id="table"  >

            <thead>
            <tr>
              <th>Group Name</th>
              <th>Group Desc</th>
              <th>Created By</th>
              <th>Count</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList | filter:searchText">
              <tr>
                                <td [innerHTML]="user.groupName"></td>
                                <td [innerHTML]="user.groupDesc"></td>
                                <td  [innerHTML]='user.createdBy'></td>
                                <td  [innerHTML]='user.count'></td>
                <td><i title="Click Here To Add Mail" (click)="save(user.id,user.groupName)"
                       [(ngModel)]="groupId" class="btn btn-add fa fa-pencil"></i>

                  <button  type="button"  id="edit" class="btn btn-add" data-toggle="modal" data-target="#exampleModal" title="Edit Group Name" (click)="edit(user)"><i class="fa fa-edit"></i></button>
                  <button  type="button"  id="delete" class="btn btn-add"  style="background-color: crimson" (click)="delete(user.id)" title="Delete Group "><i class="fa fa-trash"></i></button>
                </td>



              </tr>
            </ng-container>
            </tbody>
          </table>
          </div>
        </div>

        </div>
<!--=========================================Add Group==========================================-->
        <div *ngIf="addNewDiv">
          <div style="" class="panel-body">
           <form #updateForm="ngForm"  (ngSubmit)="createOrUpdate(updateForm.value)">

            <div class="form-group col-lg-12">
              <label>Group Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control"  #groupName [(ngModel)]="updateToGroup.groupName" name="groupName" placeholder="Enter Group Name" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Group Desc<sup class="required">*</sup></label>
              <input type="text" class="form-control"  #groupDesc [(ngModel)]="updateToGroup.groupDesc"  name="groupDesc"  placeholder="Enter Group Description" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Created By<sup class="required">*</sup></label>
              <input type="text" class="form-control"  #createdBy [(ngModel)]="updateToGroup.createdBy"  name="createdBy" required placeholder="Enter CreatedBy Name">
            </div>


              <div class="form-group col-lg-12">
                <button type="submit" class="btn btn-add">Submit</button>
                <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
              </div>
            </form>

                  </div>

                </div>
              </div>
            </div>
          </div>


<!--===========================================================Update Form=========================================================================-->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel"><b>Update Group Name</b></h1>
<!--        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
      </div>
      <div class="modal-body">
        <form #updateForm="ngForm"  (ngSubmit)="updateGroup(updateForm.value)">

          <div class="form-group col-lg-12">
            <label>Group Id  <sup class="required">*</sup></label>
            <input type="text" class="form-control" disabled  #id [(ngModel)]="updateToGroup.id" name="id" placeholder="Enter Group Name" required  >
          </div>
          <div class="form-group col-lg-12">
            <label>Group Name  <sup class="required">*</sup></label>
            <input type="text" class="form-control"  #groupName [(ngModel)]="updateToGroup.groupName" name="groupName" placeholder="Enter Group Name" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Group Desc<sup class="required">*</sup></label>
            <input type="text" class="form-control"  #groupDesc [(ngModel)]="updateToGroup.groupDesc"  name="groupDesc"  placeholder="Enter Group Description" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Created By<sup class="required">*</sup></label>
            <input type="text" class="form-control"  #createdBy [(ngModel)]="updateToGroup.createdBy"  name="createdBy" required placeholder="Enter CreatedBy Name">
          </div>

          <div class="form-group col-lg-12">

<!--            <input type="submit"  value="Update "  style="background-color: blue;width: 100%;font-size: 25px" name="createdBy" required placeholder="Enter CreatedBy Name">-->

            <button  type="submit" class="btn btn-add" style="width: 100%">Update</button>

          </div>
        </form>
      </div>
      <div class="modal-footer">
<!--        <button type="button" class="btn btn-primary" (click)="updateGroup()">Save changes</button>-->
<!--        <button type="button" class="btn btn-secondary" (click)="cancel()" data-bs-dismiss="modal">Close</button>-->

      </div>
    </div>
  </div>
</div>
</div>
