import {Component, Inject, OnInit} from '@angular/core';
import {RoleServiceService} from '../../rolemaster/RoleService/role-service.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactFormDao} from '../../conversation/contact-form/ContactFormDao';
import {RoleMasterDao} from '../../rolemaster/RoleMasterDao';
import {UserMasterDao} from '../../Services/User/UserMasterDao';
import {RoleMasterAllowDao} from './RoleMasterAllowDao';
import {RolePrivServiceService} from './RolePriv/role-priv-service.service';

@Component({
  selector: 'app-role-and-priv',
  templateUrl: './role-and-priv.component.html',
  styleUrls: ['./role-and-priv.component.css']
})
export class RoleAndPrivComponent implements OnInit {
   roleMasterDao:Array<RoleMasterDao>=[]

  loadData()
  {
    this.roleService.getRoles().subscribe(value => {

      this.rolePriv.getByUser(this.user.id).subscribe(value1 => {

        for(var i=0;i<value.length;i++)
        {
          value[i].checked=value1.some(item => item.roleId === value[i].id);
        }
        this.roleMasterDao=value;

        console.log("asd ",value);


      })




    })
  }
  constructor(private roleService:RoleServiceService,@Inject(MAT_DIALOG_DATA) public user:UserMasterDao,private rolePriv:RolePrivServiceService) {

      this.loadData()

  }

  ngOnInit(): void {

  }


  updateRole(ind: number) {

     if(this.roleMasterDao[ind].checked)
     {
       this.rolePriv.save({roleId:this.roleMasterDao[ind].id,userId:this.user.id}).subscribe(value => {
         this.loadData();
       })
     }else{
       this.rolePriv.delete({roleId:this.roleMasterDao[ind].id,userId:this.user.id}).subscribe(value => {
         this.loadData();
       })
     }

  }
}
