import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {RegistrationOtpService} from "../registration-otp.service";
import {RegistrationMasterDao} from "../registration-master-dao";
import {UserOrBuisnessIntiatedDao} from "../../../../CommonObj/GroupDao";
import {FileService} from "../../../../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";

@Component({
  selector: 'app-registration-master',
  templateUrl: './registration-master.component.html',
  styleUrls: ['./registration-master.component.css']
})
export class RegistrationMasterComponent implements OnInit {
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
 tableList:Array<RegistrationMasterDao>
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  constructor(private service:RegistrationOtpService,private alert:AlertMessageService) { }

  ngOnInit(): void {
    this.service.getMasterData().subscribe(value => {
      this.tableList=value;
      this.dtTrigger.next();
    })
  }

  downloadReport() {
    this.alert.showLoader();
    var trArr = [];
    var tdArr = [];
    tdArr.push("Uid");
    tdArr.push("Ase Name");
    tdArr.push("Ase Number");
    tdArr.push("Asm Area");
    tdArr.push("Asm Name");
    tdArr.push("Asm Number");
    tdArr.push("City");
    tdArr.push("Db Code");
    tdArr.push("Db Name");
    tdArr.push("Mandi Name");
    tdArr.push("Name");
    tdArr.push("Promoter Name");
    tdArr.push("Promoter Number");
    tdArr.push("Region");
    tdArr.push("State");

    trArr.push(tdArr);
    for (var i = 0; i < this.tableList.length; i++) {
      tdArr = [];
      tdArr.push(this.tableList[i].uid);
      tdArr.push(this.tableList[i].aseName);
      tdArr.push(this.tableList[i].aseNumber);
      tdArr.push(this.tableList[i].asmArea);
      tdArr.push(this.tableList[i].asmName);
      tdArr.push(this.tableList[i].asmNumber);
      tdArr.push(this.tableList[i].city);
      tdArr.push(this.tableList[i].dbCode);
      tdArr.push(this.tableList[i].dbName);
      tdArr.push(this.tableList[i].mandiName);
      tdArr.push(this.tableList[i].name);
      tdArr.push(this.tableList[i].promoter);
      tdArr.push(this.tableList[i].promoterNumber);
      tdArr.push(this.tableList[i].region);
      tdArr.push(this.tableList[i].state);

      trArr.push(tdArr);
    }

    let file = new FileService();
    file.generateFile(trArr, "MasterData.xlsx")
    this.alert.hideLoader();

  }
}
