import { Component, OnInit } from '@angular/core';
import {RegistrationMasterDao} from "../../Registration-Otp/registration-master-dao";
import {RegistrationOtpDao} from "../../Registration-Otp/registration-otp-dao";
import {RegistrationOtpService} from "../../Registration-Otp/registration-otp.service";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmMessageComponent} from "../../Registration-Otp/confirm-message/confirm-message.component";
import {ConfirmationMessageComponent} from "../confirmation-message/confirmation-message.component";

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css']
})
export class RegistrationFormComponent implements OnInit {
  listOfMaster: Array<RegistrationMasterDao>
  listOfMandiName: Array<string>
  registrationDao: RegistrationOtpDao = {
    id: null,
    mandiName: '',
    wsName: '',
    otherWsName: '',
    retailerName: '',
    retailerMobile: '',
    address: '',
    parchiBook: '',
    billAmount: '',
    giftHandOver: false,
    gift: '',
    volumeOfGift: '',
    coupon: '',
    promoterId: null,
    promoterName: '',
    promoterNumber: '',
    date: '',
    dateFilter: '',
    otp: null,
  }
  otpDao: any = {
    id: null,
    createAt: '',
    createAtFilter: '',
    otp: null,
    number: '',
  }
  coupons1: '';
  coupons2: '';
  coupons3: '';
  coupons4:'';
  coupons5:'';
  // coupons: string[];
  addNewDiv: boolean = false;
  showButton: any;
  listOfWs: Array<any>;

  constructor(private service: RegistrationOtpService, private alert: AlertMessageService, private router: Router, private dialog: MatDialog) {


    this.service.getMasterDataBYid().subscribe(value => {
      this.listOfMaster = value;
    })

    this.service.getByMandiNameFromKK().subscribe(value => {
      console.log('mandiName', value);
      this.listOfMandiName = value;
    })


  }

  enableTextBox: boolean = false;
  sumbitBtnEnabled: boolean = false;

  ngOnInit(): void {


  }


  sendOtp() {
    if (this.registrationDao.retailerMobile != null || this.registrationDao.retailerMobile != 'undefined') {
      this.otpDao.number = this.registrationDao.retailerMobile;
      this.service.sendOtpFromKK(this.otpDao).subscribe(value => {
      });
    } else {
      this.alert.showErrorMessage("Please Enter Retailer Mobile No.")
    }
    // this.sumbitBtnEnabled = true;

    setTimeout(() => {
      this.showButton = true;
    }, 30000); // 30 seconds = 30000 milliseconds
  }

  insert() {
    // this.registrationDao.coupon = this.coupons1 + "," + this.coupons2 + "," + this.coupons3+","+this.coupons4+","+this.coupons5
    this.alert.showLoader();
    this.service.saveInKK(this.registrationDao).subscribe(value => {
      if (value.id==null)
      {
        this.alert.showErrorMessage("Something Went Wrong!")
      } else {
        this.dialog.open(ConfirmationMessageComponent, {
          data: value
        }).afterClosed().subscribe(value => {
        })
      }

      this.cancel();
      this.alert.hideLoader();
    }, error => {
      this.alert.showErrorMessage("Oops");
      this.alert.hideLoader();
    })
  }



  private cancel() {
    this.registrationDao = {
      id: null,
      mandiName: '',
      wsName: '',
      otherWsName: '',
      retailerName: '',
      retailerMobile: '',
      address: '',
      parchiBook: '',
      billAmount: '',
      giftHandOver: false,
      gift: '',
      volumeOfGift: '',
      coupon: '',
      promoterId: null,
      promoterName: '',
      promoterNumber: '',
      date: '',
      dateFilter: '',
      otp: null,
    };
  }




  getWsName(wsName: string) {
    this.service.getByWsNameFromKK(wsName).subscribe(value => {
      this.listOfWs = value;
    })
  }
}
