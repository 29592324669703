import {Component, OnInit} from '@angular/core';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import {UserService} from '../../Services/User/user.service';
import {AccountMasterService} from "../../account-master/AccountMasterService/account-master.service";
import {AccountMasterDao} from "../../account-master/AccountMasterDao";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  oldpassword: string;
  newpassowrd: string;
  newpasswordCon: string;
  total: number;
  firstNumber: number = this.generateRandomNumber(0, 9);
  secondNumber: number = this.generateRandomNumber(0, 9);

  constructor(private alert: AlertMessageService, private userService: UsermasterService,private user:UserService, private accountMasterService: AccountMasterService) {
  }

  generateRandomNumber(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnInit(): void {
  }

  submitData() {
    if ((this.firstNumber + this.secondNumber) != this.total) {
      this.alert.showErrorMessage('Incorrect Total!!Captcha Failed');
      return;
    }

    if (this.newpasswordCon != this.newpassowrd) {
      this.alert.showErrorMessage('Password Not Matching');
      return;
    }
    this.userService.changePassword(this.oldpassword, this.newpassowrd).subscribe(value => {
      const accountMasterDao: AccountMasterDao = JSON.parse(localStorage.getItem("accountDetails"));
      accountMasterDao.password = this.newpassowrd;
      this.accountMasterService.update(accountMasterDao).subscribe(value2=>{
        this.alert.showSuccesMessage('Successfully Changed Password!!Please Login In Again');
        this.user.logout();
      }, error => {
        console.log(error);
        this.alert.showErrorMessage(error.error.message);
      });
    }, error => {
      console.log(error);
      this.alert.showErrorMessage(error.error.message);
    });
  }
}
