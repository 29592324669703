

<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <!--    <div class="panel panel-bd lobidragdashboard">-->
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
       </div>

      <div style="" class="panel-body">
        <div  class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" >
            <!--          <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">-->
            <thead>
            <tr>
              <th>Group Name </th>
              <th>Email </th>
              <th>Status</th>
              <th>Action</th>

            </tr>
            </thead>
            <tbody>
            <ng-container  >
              <tr *ngFor=" let user of tableList" >
                <td [innerHTML]="user.groupName"></td>
                <td [innerHTML]="user.email"></td>
                <td [innerHTML]="user.status == '1'? 'Enable' : 'Disable'"></td>

<!--                *ngIf="user.status==1"-->
                <td>

                  <button
                    (click)="enableDisableRule(user.id,user.status)" style="font-family: bold"
                    [ngClass]="user.status== '1' ? 'green': 'red'"
                  >
                    {{user.status== '1' ? 'Enable' : 'Disable'}}
                  </button>

                  <button  type="button"  id="delete" class="btn btn-add"  style="background-color: crimson" (click)="delete(user.id)" title="Delete Email  "><i class="fa fa-trash"></i></button>

              </tr>

            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>


