
<h1 mat-dialog-title style="text-align: center; color:  #1c5b7a"><b>File Upload </b> <i  (click)="downloadSampleFile()" title="Click Here To View Sample File" class="fa fa-question-circle"></i>	 </h1>
<form [formGroup] = "uploadForm" ngNativeValidate (submit)="submit()"  class="form-horizontal" >
  <mat-dialog-content class="mat-typography"  >
    <div class="col-md-12 form-group">
      <label class="control-label">File</label>
      <input required class="form-control" name="file"  required  type="file"   accept=".xlsx" (change)="onFileSelect($event)" placeholder="Enter File To Upload" multiple/>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  type="submit" class="btn btn-success">Upload</button>
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</form>

