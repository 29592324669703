




<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

        </div>
      </div>
      <div style="" class="panel-body">

        <div  id="tablediv">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Order-Id</th>
                <th>Pincode</th>
                <th>Address</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Grand Total</th>
                <th>Payment Status</th>
                <th>Payment Mode</th>
                <th>Initiation Datetime</th>


              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.data.length==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of tableData.data;let ind = index">
                <tr>
                  <td [innerHTML]="data.id"></td>
                  <td [innerHTML]="data.pincode"></td>

                  <td [innerHTML]="data.address"></td>
                  <td [innerHTML]="data.firstName"></td>
                  <td [innerHTML]="data.email"></td>
                  <td [innerHTML]="data.phoneNumber"></td>

                  <td [innerHTML]="data.productName"></td>
                  <td [innerHTML]="data.qty.toFixed(2)"></td>
                  <td [innerHTML]="data.rate.toFixed(2)"></td>

                  <td [innerHTML]="data.grandTotal.toFixed(2)"></td>

                  <td ><i *ngIf="data.orderStatus==1" style="color: green" class="fa fa-check"></i> <i style="color: red" *ngIf="data.orderStatus==0" class="fa fa-close"></i></td>

                  <td [innerHTML]="data.paymentMode"></td>
                  <td [innerHTML]="data.date"></td>




                 </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>

        </div>

      </div>
    </div>
  </div>
</div>









