<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <!--        <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal" ><i class="fa fa-window-close"></i></button>-->
      <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal" aria-hidden="true">
        ×
      </button>
      <div class="panel-body">
        <h3 style="text-align: center;text-decoration: underline;text-decoration-color: darkred"><b>{{title}}</b></h3>
        <br>

        <form ngNativeValidate class="form-group col-lg-12" (ngSubmit)="createOrUpdate()">


          <div class="form-group col-lg-6">
            <label class="control-label" for="bungalowNo">Bungalow No<sup class="required">*</sup></label>
            <input [disabled]="this.submitBtnTxt=='Update' " type="text" placeholder="Please Bungalow No"
                   title="Please Enter Bungalow No" required name="bungalowNo" id="bungalowNo" class="form-control"
                   [(ngModel)]="this.dao.bungalowNo">
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label" for="ewsQtrsNo">EwsQtrs No. <sup class="required">*</sup></label>
            <input type="text" placeholder="Enter Page Name" title="Please enter you accountid" required
                   name="ewsQtrsNo" id="ewsQtrsNo" class="form-control" [(ngModel)]="this.dao.ewsQtrsNo">
          </div>
          <div class="form-group col-lg-6">
            <label class="control-label" for="name">Name Of Allottee<sup class="required">*</sup></label>
            <input type="text" placeholder="Please Enter Name Of Allottee" title="Please Enter Name Of Allottee"
                   required name="name" id="name" class="form-control" [(ngModel)]="this.dao.residentialDao.name">
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label" for="mobile">Mobile No.<sup class="required">*</sup></label>
            <input type="number" placeholder="Please Enter Mobile Number" title="Please Enter Mobile Number" required
                   name="mobile" id="mobile" class="form-control" [(ngModel)]="this.dao.residentialDao.number">
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label" for="meterno">Meter No / Installation Date . <sup
              class="required">*</sup></label>
            <input type="text" placeholder="Enter EwsQtrs No" title="Please Enter Meter No" required name="meterno"
                   id="meterno" class="form-control" [(ngModel)]="this.dao.meterNo">
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label" for="preReading">Previous Reading.<sup class="required">*</sup></label>
            <input type="text" placeholder="Enter Previous Reading" title="Please Enter Previous Reading" required
                   name="preReading" id="preReading" class="form-control" [(ngModel)]="this.dao.previousReading">
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label" for="mainReading">Maintenance Reading.<sup class="required">*</sup></label>
            <input type="text" placeholder="Enter Maintenance Reading" title="Please Enter Previous Reading" required
                   name="mainReading" id="mainReading" class="form-control" [(ngModel)]="this.dao.maintenanceReading">
          </div>

          <div class="form-group col-lg-6" *ngIf="dao.maintenanceReading>0">
            <label class="control-label" for="description">Description .<sup class="required">*</sup></label>
            <input type="text" placeholder="Enter Description" title="Please Enter Previous Reading" required
                   name="description" id="description" class="form-control" [(ngModel)]="this.dao.discription">
          </div>

          <div *ngIf="addNewDiv">
            <div class="form-group col-lg-6">
              <label class="control-label" for="currReading">Current Reading.<sup class="required">*</sup></label>
              <input [disabled]="this.submitBtnTxt=='Update' " type="text" placeholder="Enter Current Reading"
                     title="Please Enter Current Reading" required name="currReading" id="currReading"
                     class="form-control" [(ngModel)]="this.dao.currentReading">
            </div>
            <div *ngIf="this.dao.chargeableUnit!=null" class="form-group col-lg-6">
              <label class="control-label" for="chargableUnit">Chargeable Unit.<sup class="required">*</sup></label>
              <input [disabled]="this.submitBtnTxt=='Update' " type="text" placeholder="Enter Chargeable Unit"
                     title="Please Enter Chargeable Unit" required name="chargableUnit" id="chargableUnit"
                     class="form-control" [(ngModel)]="this.dao.chargeableUnit">
            </div>

            <div class="form-group col-lg-6">
              <label class="control-label" for="payDate">Due Date <sup class="required">*</sup></label>
              <input [disabled]="this.submitBtnTxt=='Update' " type="date" placeholder="Please enter Due Date" required
                     name="payDate" id="payDate" class="form-control" [(ngModel)]="this.dao.payDate">
            </div>
          </div>

          <div class="col-sm-6" style="margin-left:  80%">
            <button type="submit" class="btn btn-add btn-sm">{{submitBtnTxt}}</button>
            <button type="button" (click)="onClose()" mat-dialog-close class="btn btn-danger btn-sm">Close</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>



