<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
        <button    class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
<!--        <button   class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>-->
        <button   class="btn btn-add" (click)="enableUpload()">Upload File<i  class="fa-solid fa-upload"></i></button>

      </div>
      <div style="" class="panel-body">
        <div *ngIf="isUpload" class="row">

         <form ngNativeValidate (ngSubmit)="insert()" class="form-group col-lg-6">
           <div class="form-group ">
             <label>Program <sup class="required"></sup></label>
             <select class="form-control " required name="program" [(ngModel)]="this.program">
               <option value="">---Select Option---</option>
               <option value="EMAMI">EMAMI</option>
               <option value="NR">NR</option>
               <option value="FAH-Retail">FAH-Retail</option>
               <option value="FAH-WS/SS">FAH-WS/SS</option>
               <option value="HE DEO">HE DEO</option>
               <option value="kk">KK</option>
               <option value="BPPHP">BPPHP</option>
               <option value="NRCT">NRCT</option>
               <option value="NDT">NDT</option>
               <option value="SUBH LABH">SUBH LABH</option>
             </select>
           </div>


           <div class="mat-typography">
             <div class="form-group">
               <label class="control-label" >File <i (click)="downloadSampleFile()" title="Click Here To Download Sample File" style="color: red;cursor: pointer;margin-left: 10px" class="fa fa-question-circle"></i>

               </label>
               <input (change)="uploadFile($event)" type="file" name="file" placeholder="Enter File"  required name="fileDrive"  class="form-control">
             </div>
           </div>

           <div class="reset-button pull-right">
             <button  type="submit" class="btn btn-add">Submit</button>
             <button  type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
           </div>
         </form>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>


