import {Component, OnInit} from '@angular/core';
import {ExcelReaderCommonComponent} from '../../CommonPopups/excel-reader-common/excel-reader-common.component';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {MatDialog} from '@angular/material/dialog';
import {TemplateDao} from '../../ws-template/MessageDao';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {FileMessageDao, MessageDao, MessageStatus, MessageType} from '../DaoMessageUn';
import {CampaignServiceService} from '../onetomanywsun/campaignService/campaign-service.service';

@Component({
  selector: 'app-manytomanywaun',
  templateUrl: './manytomanywaun.component.html',
  styleUrls: ['./manytomanywaun.component.css']
})
export class ManytomanywaunComponent implements OnInit {
  campaingName = '';
  messageAl: Array<MessageDao> = [];

  constructor(private alertMessage: AlertMessageService, private dialog: MatDialog, private camapaign: CampaignServiceService) {
  }

  ngOnInit(): void {
  }

  createCampiagn() {
    const campaignLen = this.messageAl.length;
    console.log('Found length is ', campaignLen);
    if (campaignLen < 1) {
      this.alertMessage.showErrorMessage('No Campaign Data found!!');
      return;
    }
    this.alertMessage.showLoader();
    this.alertMessage.confirmMessage('Start campaign of ' + campaignLen + ' ?', 'Are you sure?').then(value => {
      this.camapaign.startCampaing({campaignName: this.campaingName, messageDaos: this.messageAl,messageSendingSpeed:0,customMessageInterval:false}).subscribe(response => {
        this.alertMessage.hideLoader();
        this.clearData();
      }, error => {
        this.alertMessage.hideLoader();
        this.alertMessage.showErrorMessage(error.error.message);
      });
    });


  }

  // tslint:disable-next-line:typedef
  clearData() {
    this.campaingName = '';
    this.messageAl = [];
  }

  // tslint:disable-next-line:typedef
  downloadSampleFile() {
    this.alertMessage.showLoader();
    const trArr = [];
    const tdArr = [];
    const templateDao: TemplateDao = null;
    tdArr.push('Phonenumber');
    tdArr.push('Content Type(TEXT/FILE)');
    tdArr.push('Content/File Url');
    tdArr.push('Caption(Required in case of file)');
    tdArr.push('File Name(Required in case of file)');


    trArr.push(tdArr);

    const file = new FileService<any>();
    file.generateFile(trArr, 'DynamicWSUN_Sample_File.xlsx');
    this.alertMessage.hideLoader();
  }

  uploadFileComponent() {
    this.messageAl = [];

    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {


      const campaignLen = value.length;
      if (campaignLen <= 1) {
        this.alertMessage.showErrorMessage('No Campaign Data found!!');
        return;
      }

      for (let i = 0; i < campaignLen; i++) {

        let messageType = MessageType.TEXT;
        let tempFileObj: FileMessageDao = null;
        if (value[i]['Content Type(TEXT/FILE)'] == 'FILE') {
          messageType = MessageType.FILE;
          tempFileObj = {
            fileUrl: value[i]['Content/File Url'],
            fileName: value[i]['File Name(Required in case of file)'],
            caption: value[i]['Caption(Required in case of file)']
          };
        }

        this.messageAl.push({
          preferredIncId: 0,
          buttonMessage: null,
          contactMessage: null,
          dst: value[i].Phonenumber.replace('\'', ''),
          fileMessage: tempFileObj,
          id: '',
          incId: 0,
          listMessage: undefined,
          locationMessage: undefined,
          messageStatus: MessageStatus.SENT,
          messageType,
          promotional: undefined,
          textMessage: {content: value[i]['Content/File Url']},
          templateButtonMessage: undefined,
          chatSide: undefined,
          iSchedule:0,
          scheduleTime:null,
          campaignSize: null
        });
      }


    });
  }
}
