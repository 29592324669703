<h1 mat-dialog-title style="text-align: center">File Upload <i class="fa fa-cloud-upload"></i> </h1>



<form ngNativeValidate>

  <mat-dialog-content class="mat-typography">

    <div class="form-group">
      <label class="control-label" >File</label>
      <button ></button>
      <input (change)="uploadFile($event)" type="file" placeholder="Enter File"  required name="fileDrive"  class="form-control">

    </div>

  </mat-dialog-content>
  <mat-dialog-actions >

    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

  </mat-dialog-actions>
</form>
