import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {OBDCampaignDao} from '../obd/OBDCampaignDao';
import {OBDCampaignGroupByDao} from '../obd/OBDCampaignGroupByDao';
import {ScratchCardDao} from './ScratchCardDao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
const appprefix2 = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class ScratchCardServiceService {

  constructor(private http: HttpClient) { }
  getCampaignOBDGroupByReportDatewise(startdate: number, enddate: number): Observable<Array<ScratchCardDao>>
  {
    // tslint:disable-next-line:max-line-length
    return this.http.get<Array<ScratchCardDao>>(appprefix2 + 'digimozo/v1/scratchcardreport/getReport?startDate=' + startdate + '&endDate=' + enddate, {});

  }
}
