<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
      </div>
      <div style="" class="panel-body">

        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Receipts</th>
              <th>Create At</th>
              <th>Scheduled Time</th>
              <th>Template Id</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr *ngIf="user.iScheduled==1">
                <td [innerHTML]="user.campaingName"></td>
                <td [innerHTML]="user.totalReciepts"></td>
<!--                <td [innerHTML]="user.createBy"></td>-->
                <td [innerHTML]="user.createAt"></td>
<!--                <td>{{  user.scheduledTime.toString().substring(0, 4) + "-" + user.scheduledTime.toString().substring(4, 6) + "-" + user.scheduledTime.toString().substring(6, 8) + " " + user.scheduledTime.toString().substring(9, 11) + ":" + user.scheduledTime.toString().substring(11, 13)}} </td>-->
                <td [innerHTML]="user.scheduledTime|date :'dd-MM-yyyy HH:mm:ss'"></td>
                <td [innerHTML]="user.template"></td>
                <td>
                  <i title="Click Here To Delete Scheduled Campaign" (click)="deleteSchedule(user.id)"
                     class="btn btn-danger fa fa-trash"></i>
                  <!--                  <i title="Download Report" (click)="downloadReport(user,ind)"-->
                  <!--                     class="btn btn-add fa fa-download"></i>-->
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







