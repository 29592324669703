import { Component, OnInit } from '@angular/core';
import {FileService} from "../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {Subject} from "rxjs";
import {SubscriptionFilterDao} from "../subscription-package-report/SubscriptionFilterDao";
import {SubscriptionTransactionReportDao} from "./SubscriptionTransactionReportDao";
import {SubscriptionTransactionReportService} from "./subscription-transaction-report.service";

@Component({
  selector: 'app-subscription-transaction-report',
  templateUrl: './subscription-transaction-report.component.html',
  styleUrls: ['./subscription-transaction-report.component.css']
})
export class SubscriptionTransactionReportComponent implements OnInit {

    tableList: Array<SubscriptionTransactionReportDao>;
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {'bDestroy': true, 'order': []};
    status: boolean = true;

    subscriptionTransactionReportDao: SubscriptionTransactionReportDao = {
    id: '',
    createDate: '',
    createDateFilter: 0,
    accountId: 0,
    status: true,
    subScriptionPackageDao: null
    }
    filterDao:SubscriptionFilterDao = {
        startdate:'0',
        enddate:'0',
        offset:0,
        limit:10,
        accountId:0,
        groupBy:'',
        dateFilterType: '0',
        services:'',
        status:true
    };
  constructor(private alert:AlertMessageService, private subscriptionTransactionReportService: SubscriptionTransactionReportService) {
      this.loadData();
  }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    filterData() {
      if(this.filterDao.startdate.length != 14 || this.filterDao.enddate.length !=14){
          this.filterDao.startdate = this.filterDao.startdate + '000000';
          this.filterDao.enddate = this.filterDao.enddate + '235959';
      }
      this.filterDao.status = this.status;
      this.loadData();
    }
    onchangeStatus() {
        let date = new Date();
        this.filterDao.status = !this.status;
        this.loadData();
    }

    loadData(){
        this.alert.showLoader();
        if(this.filterDao.startdate == '0' || this.filterDao.enddate == '0' || this.filterDao.startdate == undefined || this.filterDao.enddate == undefined){
            let date = new Date();
            let monthStr = (date.getMonth() + 1).toString();
            let onlyDate: string = date.getDate().toString();
            if(monthStr.length != 2){
                monthStr = '0' + monthStr ;
            }
            if(onlyDate.length!=2){
                onlyDate = '0' + onlyDate;
            }
            this.filterDao.startdate = date.getUTCFullYear().toString() + monthStr + '01000000';
            this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate + '235959';

        }else{
            this.filterDao.startdate = this.filterDao.startdate.replace('-','').replace('-','')
            this.filterDao.enddate = this.filterDao.enddate.replace("-","").replace('-','');
        }
        $('#tableDiv').DataTable().destroy();
        this.subscriptionTransactionReportService.get(this.filterDao).subscribe(value => {
            this.tableList = value;
            this.dtTrigger.next();
            this.alert.hideLoader();
        });
    }

    clearFilter() {
        this.subscriptionTransactionReportDao = {
            id: '',
            createDate: '',
            createDateFilter: 0,
            accountId: 0,
            status: true,
            subScriptionPackageDao: null
        }
        this.filterDao = {
            startdate:'0',
            enddate:'0',
            offset:0,
            limit:10,
            accountId:0,
            groupBy:'',
            dateFilterType: '0',
            services:'',
            status:true
        };
        this.status = true;
        this.loadData();
    }

    downloadReport() {
        this.subscriptionTransactionReportService.get(this.filterDao).subscribe(subscriptionTransactionReportDao => {
            let file = new FileService<any>();
            var trArr = [];
            var tdArr = [];
            tdArr.push("Create Date");
            tdArr.push("Account");
            tdArr.push("Package Type");
            tdArr.push("Country");
            tdArr.push("Expiry Type");
            tdArr.push("Plan Type");
            tdArr.push("Recharge Amount");
            tdArr.push("Rental Amount");
            tdArr.push("Total Amount");
            tdArr.push("Status");
            trArr.push(tdArr);
            for (var i = 0; i < subscriptionTransactionReportDao.length; i++) {
                tdArr = [];
                tdArr.push(subscriptionTransactionReportDao[i].createDate);
                tdArr.push(subscriptionTransactionReportDao[i].accountId)
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.subscriptionPackageType);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.country);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.expireyDateType);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.planType);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.rechargeAmount);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.rentalAmount);
                tdArr.push(subscriptionTransactionReportDao[i].subScriptionPackageDao.totalAMount);
                if(subscriptionTransactionReportDao[i].status == true){
                    tdArr.push("ACTIVE");
                }else{
                    tdArr.push("INACTIVE");
                }
                trArr.push(tdArr);
            }
            file.generateFile(trArr,'Sub_Trans_Report_' + new Date().getDate() + '.xlsx');
        });
    }

  ngOnInit(): void {
  }

}
