
<h1 mat-dialog-title style="text-align: center">Profile Edit <i class="fa fa-user"></i> </h1>

<form style="width: 350px" ngNativeValidate (submit)="submitData()">
  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Full Name</label>
      <div class="col-sm-12">
        <input name="fullname" [(ngModel)]="fullname" class="form-control"  required/>
      </div>
    </div>

    <div class="form-group col-lg-12">
      <label>Email</label>
      <div class="col-sm-12">
        <input name="email" type="email" [(ngModel)]="email" class="form-control" required />
      </div>
    </div>


    <div class="form-group col-lg-12">
      <label>Phone</label>
      <div class="col-sm-12">
        <input name="phone" [(ngModel)]="phn" class="form-control" required/>
      </div>
    </div>

    <div class="form-group col-lg-12">
      <label>Enter Current Password</label>
      <div class="col-sm-12">
        <input name="password" type="password" [(ngModel)]="password" class="form-control" required/>
      </div>
    </div>








  </mat-dialog-content>
  <mat-dialog-actions align="end">
  </mat-dialog-actions>

  <button [mat-dialog-close]="null"  class="btn btn-add pull-right" type="button">Cancel</button>
  <button class="btn btn-add pull-right" type="submit">Save</button>
</form>

