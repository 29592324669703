
<form ngNativeValidate (submit)="isOrUpdate()">
<div class="form-group col-lg-12">
  <label>Instance </label>
  <select class="form-control" [(ngModel)]="commonInc.incId" name="incId" required>
    <option [value]="0">Show All</option>
    <ng-container *ngFor="let inc of data">
      <option [value]="inc.id" [innerHTML]="inc.instanceName"></option>
    </ng-container>

  </select>
</div>
  <div class="form-group col-lg-12">
  <label>Status </label>
  <mat-slide-toggle [(ngModel)]="commonInc.status" name="status"></mat-slide-toggle>
</div>
  <div  class="form-group col-lg-12">
    <label>Report Type</label>
    <select class="form-control" [(ngModel)]="commonInc.reportBy" name="reportBy" required>
      <option [value]="0">Show All</option>

      <option [value]="1">System</option>
      <option [value]="2">Api</option>


    </select>
  </div>
<div class="form-group col-lg-12">
  <label>Start date </label>
  <input class="form-control" type="date" name="startdate" [(ngModel)]="commonInc.startdate" required>
</div>


<div class="form-group col-lg-12">
  <label>End date </label>
  <input class="form-control" type="date" name="enddate" [(ngModel)]="commonInc.enddate" required>
</div>
  <button  class="btn btn-success"  type="submit">Filter <i class="fa fa-filter"></i></button>
  <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>
</form>



