import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CampaignDao} from '../onetomany/CampaignDao';
import {CampaingService} from '../CampainService/campaing.service';
import {MatDialog} from '@angular/material/dialog';
import {GraphCommonComponent} from '../graph-common/graph-common.component';
import {GraphCommonDao} from '../graph-common/GraphCommonDao';
import {ChartDataDao} from '../chart-and-tables/ChartDataDao';
import {Label} from 'ng2-charts';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {FileService} from '../Services/FileUtilsService/FileService';

@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.css']
})
export class CampaignReportComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  tableList: Array<CampaignDao> = null;

  constructor(private campaingService: CampaingService, private dialog: MatDialog, private alert: AlertMessageService) {
    this.loadData();
  }

  ngOnInit(): void {
  }

  loadData() {
    this.campaingService.getCampaigns().subscribe(value => {
      console.log("value:", value)
      for(let i =0;i<value.length;i++){
        if(value[i].scheduledTime != null && value[i].scheduledTime.length == 13 ) {
          value[i].scheduledTime = "0" + value[i].scheduledTime.slice(7, 8) + "-0" + value[i].scheduledTime.slice(5, 6) + "-" + value[i].scheduledTime.slice(0, 4) + " 00:00:00";
        }
      }
      this.tableList = value;
      this.tableList.sort((a, b) => {
        if (a.scheduledTime > b.scheduledTime) { return -1; }
        if (a.scheduledTime < b.scheduledTime) { return 1; }
        return 0;
      });
      this.dtTrigger.next();
    })
  }

  drawReport(user: CampaignDao, ind: number) {
    this.alert.showLoader();
    this.campaingService.getCampaingWiseReport(user.id).subscribe(campResponse => {

      let chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
      let labels: Array<Label> = [];
      let value: Array<number> = [];
      for (var i = 0; i < campResponse.length; i++) {
        labels.push(campResponse[i]._id);
        value.push(campResponse[i].count);

      }
      chartDataDao.labels = labels;
      chartDataDao.barChartData.push({
        data: value,
        label: "Message Report",
        backgroundColor: "#FB8170",
        hoverBackgroundColor: "#fb8170",
        fill: false,
        borderColor: "#fb8170",
        barThickness: 40
      })

      chartDataDao.chartName = "Day Wise Report";
      let graph: GraphCommonDao = {chartData: chartDataDao, header: user.campaingName + ' Report'}
      this.dialog.open(GraphCommonComponent, {data: graph})
      this.alert.hideLoader();
    })


  }

  downloadReport(user: CampaignDao, ind: number) {

    let file = new FileService<any>();
    var trArr = [];
    var tdArr = [];
    tdArr.push("Message Id");
    tdArr.push("Dst");
    tdArr.push("Template Name");
    tdArr.push("Message Type");
    tdArr.push("Status");
    tdArr.push("Submission Time");
    tdArr.push("Dlr Time");
    tdArr.push("View Time");
    trArr.push(tdArr);
    this.campaingService.getTransCampaingWise(user.id).subscribe(value => {
      for (var i = 0; i < value.length; i++) {
        tdArr = [];
        tdArr.push(value[i].messageId);
        tdArr.push(value[i].to);
        tdArr.push(value[i].template.name)
        tdArr.push(value[i].type.toUpperCase());
        tdArr.push(value[i].messageStatus);
        tdArr.push(value[i].date);
        tdArr.push(value[i].dlrTime);
        tdArr.push(value[i].viewTime);
        trArr.push(tdArr);
      }
      file.generateFile(trArr, user.campaingName + ".xlsx");

    })


  }
}
