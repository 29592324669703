
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
              <a href="#">
                <h4>Extension Master</h4>
              </a>
            </div>
            <button  (click)="addOrUpdateAgentMaterForm(undefined,'Create')"  class="btn btn-add">Add<i
              class="fa fa-plus"></i></button>

            &nbsp;
          </div>

        </div>

        <div id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Extension</th>
                <th>Agent Name</th>
                <th>Agent Number</th>

                <th>Action</th>

                <!--                <th>Type</th>-->


              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="extensionWiseAgents.recordsTotal==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of extensionWiseAgents.data;let ind = index">
                <tr>
                  <td  [innerHTML]="data.extenstion"  ></td>
                  <td  [innerHTML]="data.agentName"  ></td>
                  <td  [innerHTML]="data.agentNumber"  ></td>

                  <td><button class="btn btn-add" (click)="addOrUpdateAgentMaterForm(data,'Update')"><i class="fa fa-pencil"></i></button>&nbsp;&nbsp;
                                        <button class="btn btn-danger m-r-2" (click)="deleteExtension(data.id)"><i class="fa fa-trash"></i></button>
                  </td>



                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>



          <mat-paginator (page)="tableChange($event)" [length]=extensionWiseAgents.recordsTotal
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>
        </div>


        <!--//-->
      </div>
    </div>
  </div>


</section>
