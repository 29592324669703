import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {FileService} from "../../Services/FileUtilsService/FileService";

@Component({
  selector: 'app-excel-reader-with-datettime',
  templateUrl: './excel-reader-with-datettime.component.html',
  styleUrls: ['./excel-reader-with-datettime.component.css']
})
export class ExcelReaderWithDatettimeComponent implements OnInit {

  public calltime:string;
  constructor(private dialog:MatDialogRef<ExcelReaderWithDatettimeComponent>) { }

  ngOnInit(): void {
  }
  incomingfile(event)
  {
    this.file= event.target.files[0];
  }
  file:File;
  upload() {
    let file = new FileService<any>();
    file.readFile(this.file).then(value => {

      this.dialog.close({list:value,time:this.calltime});
    })
  }

}
