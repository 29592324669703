<h1 mat-dialog-title style="text-align: center">Filter <i class="fa fa-filter"></i> </h1>



<form ngNativeValidate (submit)="filter()">

  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Startdate  <sup class="required">*</sup></label>
      <input type="date" class="form-control" [(ngModel)]="startdate" name="startdate" required>
    </div>
    <div class="form-group col-lg-12">
      <label>Enddate  <sup class="required">*</sup></label>
      <input type="date" class="form-control" [(ngModel)]="enddate" name="enddate" required>
    </div>

    <div class="form-group col-lg-12">
      <label>Longcode SMS </label>
      <mat-slide-toggle [(ngModel)]="active" name="active"></mat-slide-toggle>
    </div>

    <div *ngIf="active==true ">
    <div class="form-group col-lg-12">
      <label>Status </label>
      <select class="form-control"    [(ngModel)]="status"  name="status"  >
        <ng-container >
          <option value="undefined">---Select Status Type---</option>
          <option value="Buy">Buy</option>
          <option value="samp">samp</option>
          <option value="Duplicate">Duplicate</option>
          <option value="Invalid">Invalid</option>
        </ng-container>
      </select>
    </div>
    </div>
    <div *ngIf="active==false">
    <div class="form-group col-lg-12">
      <label>Status</label>
      <select class="form-control"    [(ngModel)]="status"  name="status"  >
        <ng-container >
          <option value="undefined">---Select Status Type---</option>
          <option value="2">Duplicate</option>
          <option value="1">FirstTime</option>
        </ng-container>
      </select>
    </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  [mat-dialog-close]="null" class="btn btn-success"  type="submit">Filter <i class="fa fa-filter"></i></button>
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

  </mat-dialog-actions>
</form>
