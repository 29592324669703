import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import {Observable} from 'rxjs';
import {ProxyMasterDao} from '../ProxyMasterDao';
const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class ProxyService {

  constructor(private http:HttpClient) { }

  get():Observable<Array<ProxyMasterDao>>
  {
    return this.http.get<Array<ProxyMasterDao>>(appPrefix+"proxy/")
  }
  getActive():Observable<Array<ProxyMasterDao>>
  {
    return this.http.get<Array<ProxyMasterDao>>(appPrefix+"proxy/active")
  }
  save(proxy:ProxyMasterDao):Observable<ProxyMasterDao>{
    return this.http.post<ProxyMasterDao>(appPrefix+"proxy/",proxy);
  }
}
