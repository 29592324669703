import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SendmailDao} from './sendmail-dao';
import {Config} from 'protractor';
import {error} from 'jquery';
import {LongcodenrctDao} from '../../RahulPackage/LongcodeNRCT/longcodenrct-dao';
import {GroupDao} from '../mailgroup/mailgroupadd/group-dao';
import {environment} from '../../../environments/environment.prod';
import {EmailObjectDao} from '../email-object-dao';
const appprefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class SendmailserviceService {

  constructor(private http: HttpClient) { }


  getAll(): Observable<Array<GroupDao>>
  {
    return this.http.get<Array<GroupDao>>(appprefix + 'gsmgateway/v1/api/group/get', {});
  }

  // getAll(): Observable<Array<GroupDao>>
  // {
  //   return this.http.get<Array<GroupDao>>(  'http://localhost:8089/api/group/get', {});
  // }

  insert(data): Observable<any>
  {

    return this.http.post(appprefix + 'gsmgateway/v1/api/mailSend/transMail', data , {responseType: 'text'});
  }

  sendMail(data: EmailObjectDao, file: FormData): Observable<any>
  {

    var headers = new Headers();
    headers.append('Content-Type', 'application/form-data');
    // const options = new  Request({headers: headers});

    var body = "groupId=" + data.groupId + "&subject=" + data.subject + "&message=" +  data.message + "&file=" + file;

    return this.http.post('http://localhost:8081/api/mailSend/send',data );
  }
}
