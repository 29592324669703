import { Component, OnInit } from '@angular/core';
import {FilterDao} from "../crdrreport/FilterDao";
import {VerfiedIncomingMessageReportService} from "./verfied-incoming-message-report.service";
import {MessageDao} from "../ws-template/MessageDao";
import {Subject} from "rxjs";
import {CommoninstancedropdownComponent} from "../Unofficial/commoninstancedropdown/commoninstancedropdown.component";
import {MatDialog} from "@angular/material/dialog";
import {UserOrBuisnessIntiatedDao} from "../CommonObj/GroupDao";
import {FileService} from "../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {PaginationDao} from "../CommonObj/PaginationDao";

@Component({
  selector: 'app-verified-incoming-message-report',
  templateUrl: './verified-incoming-message-report.component.html',
  styleUrls: ['./verified-incoming-message-report.component.css']
})
export class VerifiedIncomingMessageReportComponent implements OnInit {
  tableList: PaginationDao<MessageDao>= {data:null,total:0};
  tableListDownload: PaginationDao<MessageDao> = {data:null,total:0};
  filterDao: FilterDao = {
    startdate:'0',
    enddate:'0',
    offset:0,
    limit:10,
    accountId:0,
    groupBy:'',
    dateFilterType: '0',
    services:''
  }
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  // dtOptions: DataTables.Settings = {};

  constructor(private alert: AlertMessageService, private dialog: MatDialog  ,private incomingMessageReportService: VerfiedIncomingMessageReportService) {
    this.loadData(null);
  }

  ngOnInit(): void {
  }


  filterData() {
    this.dialog.open(CommoninstancedropdownComponent, {data: []}).afterClosed().subscribe(value => {
      if (value != null) {
        this.filterDao.startdate = value.startdate.replaceAll("-","") + "000000";
        this.filterDao.enddate = value.enddate.replaceAll("-","") + "235959";
        this.loadData(null);
      }
    });
  }

  loadData(event:any){
    this.alert.showLoader();
    if(this.filterDao.startdate == '0' && this.filterDao.enddate == '0'){
      let date = new Date();
      let monthStr = (date.getMonth() + 1).toString();
      let onlyDate: string = date.getDate().toString();
      if(monthStr.length != 2){
        monthStr = '0' + monthStr ;
      }
      if(onlyDate.length!=2){
        onlyDate = '0' + onlyDate;
      }
      this.filterDao.startdate = date.getUTCFullYear().toString() + monthStr + onlyDate + '000000';
      this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate + '235959';
    }
    if(event == null || event ==undefined){
      this.filterDao.offset = 0;
      this.filterDao.limit = 10;
    }else{
      this.filterDao.offset = event.pageIndex;
      this.filterDao.limit = event.pageSize;
    }

    // $('#tableDiv').DataTable().destroy();
    this.incomingMessageReportService.verfiedIncomingMessageReport(this.filterDao).subscribe(value => {
      // this.tableList.data = value.data.filter(item => item.type != undefined);
      this.tableList.data = value.data;
      this.tableList.total = value.total;
      // this.tableList = value;
      // this.dtTrigger.next();
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.filterDao = {
      startdate:'0',
      enddate:'0',
      offset:0,
      limit:10,
      accountId:0,
      groupBy:'',
      dateFilterType: '0',
      services:''
    }
    this.loadData(null);
  }

  downloadReport() {
    this.alert.showLoader();
    let filterDaoDownload = this.filterDao;
    filterDaoDownload.offset = 0;
    filterDaoDownload.limit = 75000;
    this.incomingMessageReportService.verfiedIncomingMessageReport(filterDaoDownload).subscribe(value => {
      this.tableListDownload = value;
      var trArr = [];
      var tdArr = [];
      tdArr.push("Date");
      tdArr.push("Name");
      tdArr.push("Phone Number");
      tdArr.push("Message Type");
      tdArr.push("Message Content");
      trArr.push(tdArr);

      for (var i = 0; i < this.tableListDownload.data.length; i++) {
        tdArr = [];
        tdArr.push(this.tableListDownload.data[i].date);
        tdArr.push(this.tableListDownload.data[i].profileName);
        tdArr.push(this.tableListDownload.data[i].to);
        tdArr.push(this.tableListDownload.data[i].type);
        if(this.tableListDownload.data[i].type == 'text'){
          if(this.tableListDownload.data[i].text.body.length < 32767){
            tdArr.push(this.tableListDownload.data[i].text.body);
          }else{
            tdArr.push(this.tableListDownload.data[i].text.body.slice(0,2000));
          }
        }else if(this.tableListDownload.data[i].type == 'image'){
          tdArr.push(this.tableListDownload.data[i].image.link + "  Caption " + this.tableListDownload.data[i].image.caption);
        }else if(this.tableListDownload.data[i].type == 'video'){
          tdArr.push(this.tableListDownload.data[i].video.link + "  Caption " + this.tableListDownload.data[i].video.caption);
        }else if(this.tableListDownload.data[i].type == 'audio'){
          tdArr.push(this.tableListDownload.data[i].audio.link + "  Caption " + this.tableListDownload.data[i].audio.caption);
        }else if(this.tableListDownload.data[i].type == 'document'){
          tdArr.push(this.tableListDownload.data[i].document.link + "  Caption " + this.tableListDownload.data[i].document.caption);
        }else if(this.tableListDownload.data[i].type == 'interactive' && this.tableListDownload.data[i].interactive.button_reply != null){
          tdArr.push(this.tableListDownload.data[i].interactive.button_reply.title);
        }else if(this.tableListDownload.data[i].type == 'interactive' && this.tableListDownload.data[i].interactive.list_reply != null){
          tdArr.push(this.tableListDownload.data[i].interactive.list_reply.title);
        }else if(this.tableListDownload.data[i].type == 'button'){
          tdArr.push(this.tableListDownload.data[i].button.text);
        }else if(this.tableListDownload.data[i].type == 'location'){
          tdArr.push("Longitude:- " + this.tableListDownload.data[i].location.longitude + "Latitude:-" + this.tableListDownload.data[i].location.latitude);
        }
        trArr.push(tdArr);
      }

      let file = new FileService();
      file.generateFile(trArr, "IncomingMessagingReport.xlsx")
      this.alert.hideLoader();
    });

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
