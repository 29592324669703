import {Component, Inject, OnInit} from '@angular/core';
import {AccountMasterService} from '../account-master/AccountMasterService/account-master.service';
import {AccountMasterDao} from '../account-master/AccountMasterDao';
import {CountryMasterDao} from '../countrymaster/CountryMasterDao';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {CountryWisePriceDao, CountryWiseRateTempDao} from './CountryWiseRateTempDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserMasterDao} from "../Services/User/UserMasterDao";
import {CountryWisePriceListDao} from "../client-sign-up/CountryWisePriceDaoList";
import {error} from "jquery";

const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Component({
  selector: 'app-countrywiserate',
  templateUrl: './countrywiserate.component.html',
  styleUrls: ['./countrywiserate.component.css']
})
export class CountrywiserateComponent implements OnInit {
  countryList: Array<CountryWisePriceListDao> = [];
  countryWisePrice: Array<CountryWiseRateTempDao> = [];


  accountId: number = 0;

  constructor(private http: HttpClient, private alert: AlertMessageService,@Inject(MAT_DIALOG_DATA) public data:any,private  dialogRef:MatDialogRef<any>) {

    console.log('matdialogdata',data);
    this.accountId=data;



    // accountService.getAccountsByUsername().subscribe(value => {
    //   this.accountList = value;
    // });
    this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/countrywisepricelist/getall").subscribe(value => {
      this.countryList = value;
    });
    // this.http.get<Array<CountryMasterDao>>(appPrefix + 'country/').subscribe(value => {
    //   this.countryList = value;
    // });
  }


  ngOnInit(): void {
    this.changeAccount();
  }

  async changeAccount() {
    this.alert.showLoader();
    this.countryWisePrice = [];
    console.log('This is account id ', this.accountId);
    let countryWiseRate = await this.http.get<Array<CountryWisePriceDao>>(appPrefix + 'api/countrywise/' + this.accountId).toPromise();
    for (var i = 0; i < this.countryList.length; i++) {

      let countryPriceDao: CountryWiseRateTempDao = {
        countryDao: this.countryList[i],
        found: false,
        rate: {
          countryCode: this.countryList[i].countryCode,
          userInitiatedRates: 0,
          bsnsInitiatedRates: 0,
          marketingRates:0,
          utilityRates:0,
          authenicationRates:0,
          active: true,
          accountId: this.accountId
        }
      };
      for (var m = 0; m < countryWiseRate.length; m++) {
        if (this.countryList[i].countryCode == countryWiseRate[m].countryCode) {
          countryPriceDao.found = true;
          countryPriceDao.rate = countryWiseRate[m];
        }
      }


      this.countryWisePrice.push(countryPriceDao);

    }

    this.alert.hideLoader();


  }


  async saveData(rate: CountryWisePriceDao) {
    this.alert.showLoader();
    this.alert.confirmMessage('Save Account Country rate of ' + rate.countryCode, 'Are you sure?').then(value => {
      this.http.post<CountryWisePriceDao>(appPrefix + 'api/countrywise/', rate).subscribe(value1 => {
        rate = value1;
        this.alert.showSuccesMessage("Successfully saved!!")
        this.alert.hideLoader();
      });

    });

  }

  onClose() {
    this.dialogRef.close();
  }

  deleteCountryRates(found: boolean, rate: CountryWisePriceDao) {
    if(found == false){
      this.alert.confirmMessage('Delete Account Country rate of ' + rate.countryCode, 'Are you sure?').then(value => {
        this.http.post<CountryWisePriceDao>(appPrefix + 'api/countrywise/delete', rate).subscribe(value1 => {
          this.alert.showSuccesMessage("Successfully Deleted!!");
        },error => {
          this.alert.showErrorMessage("Error Occured");
        });
      },error =>{
        this.alert.showErrorMessage("Error Occured");
      });
    }
  }
}
