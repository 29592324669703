<div class="background-image">
<div class="login-wrapper" >
  <div class="container-center" >
    <div class="login-area" >
      <div class="panel panel-bd panel-custom"  >
        <div class="panel-heading"  >
          <div class="view-header" >
            <div class="header-icon">
              <i class="fa-solid fa-lock"></i>

            </div>
            <div class="header-title">
              <h3>Sign Up</h3>
              <small><strong>Fill Details</strong><i (click)="showSignUpDoc()" style="color: red" class="fa fa-question-circle"></i></small>
                <button style=" background-color: #8dc1cd;
            border-radius: 50px;
    border-color: #b1d3db;
    width: 20%;
    color: black;
    min-width: fit-content;
    font-weight: 800; float: right;" class="btn btn-success" (click)="login()">Login</button>
            </div>

          </div>
        </div>
        <div class="panel-body">
          <form style="height: 100%" ngNativeValidate (submit)="signUp()">
            <div class="form-group col-lg-6">
              <label   class="control-label" >Company Name <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="3" placeholder="Enter Company Name" title="Please enter you Company Name" required [(ngModel)]="clientSignUpDao.companyName" name="companyName" id="companyName" class="form-control">

            </div>
            <div class="form-group col-lg-6">
              <label   class="control-label" >Contact Person Name <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="3" placeholder="Enter Your Name" title="Please enter you name" required [(ngModel)]="clientSignUpDao.clientName" name="clientName" id="clientName" class="form-control">

            </div>
            <div class="form-group col-lg-6">
              <label   class="control-label" >Contact Person Number <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" [maxlength]="14" placeholder="Enter Mobile Number" title="Please enter Mobile Number" required [(ngModel)]="clientSignUpDao.clientNumber" name="clientNumber" id="clientNumber" [disabled]="clientNumberstatus" class="form-control">


            </div>
            <div class="form-group col-lg-6">
              <label   class="control-label" >Country <i style="color: red" class="fa fa-asterisk"></i></label>
              <select   name="country" [(ngModel)]="clientSignUpDao.country"  type="text" class="form-control" required>
                <ng-container *ngFor="let field of countryWithPricesList">
                  <option  [value]="field['countryCode']" >{{field['country']}} </option>
                </ng-container>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label   class="control-label" >Verify Email <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="email" placeholder="example@gmail.com" title="Please enter you email" required [(ngModel)]="clientSignUpDao.email" name="email" id="email" class="form-control">

            </div>
            <div class="form-group col-lg-3">
              <label class="control-label" >Facebook Verfication <i (click)="showFacebookDoc()" style="color: red" class="fa fa-question-circle"></i></label>
              <div>
                <label class="form-check-label col-lg-3">
                  <input type="radio" class="form-check-input" name="facebookVerifyStatus" [value]="true"  [(ngModel)]="clientSignUpDao.facebookVerifyStatus">Yes&nbsp;&nbsp;&nbsp;
                </label>
                <label class="form-check-label col-lg-3">
                  <input type="radio" class="form-check-input" name="facebookVerifyStatus" [value]="false" [(ngModel)]="clientSignUpDao.facebookVerifyStatus" checked>No
                </label>
              </div>
            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true" class="form-group col-lg-3">
              <label class="control-label" >Verfied Whatsapp<i (click)="showVerfiedWhatsappDoc()" style="color: red" class="fa fa-question-circle"></i></label>
              <div>
                <label class="form-check-label col-lg-3">
                  <input type="radio" class="form-check-input" name="defaultSubscription" [value]="false" [(ngModel)]="clientSignUpDao.defaultSubscription" >Yes&nbsp;&nbsp;&nbsp;
                </label>
                <label class="form-check-label col-lg-3">
                  <input type="radio" class="form-check-input" name="defaultSubscription" [value]="true" [(ngModel)]="clientSignUpDao.defaultSubscription" checked>No
                </label>
              </div>
            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true && !clientSignUpDao.defaultSubscription == true" class="form-group col-lg-6">
              <label   class="control-label" >Whatsapp Number <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" [maxlength]="25" placeholder="Enter Whatsapp Number" title="Please enter your Whatsapp Number" [required]="!clientSignUpDao.defaultSubscription" [(ngModel)]="clientSignUpDao.whatsappNumber" name="whatsappNumber" id="whatsappNumber" class="form-control">

            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true && !clientSignUpDao.defaultSubscription == true" class="form-group col-lg-6">
              <label   class="control-label" >Whatsapp ID <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" [maxlength]="25" placeholder="Enter Whatsapp ID" title="Please enter your whatsapp ID" [required]="!clientSignUpDao.defaultSubscription" [(ngModel)]="clientSignUpDao.whatsappId" name="whatsappId" id="whatsappId" class="form-control">

            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true && !clientSignUpDao.defaultSubscription == true" class="form-group col-lg-6">
              <label   class="control-label" >Whatsapp Phone Id <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" [maxlength]="25" placeholder="Enter Phone ID" title="Please enter your PHONE ID" [required]="!clientSignUpDao.defaultSubscription" [(ngModel)]="clientSignUpDao.whatsappPhoneId" name="whatsappPhoneId" id="whatsappPhoneId" class="form-control">

            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true && !clientSignUpDao.defaultSubscription == true" class="form-group col-lg-6">
              <label   class="control-label" >Whatsapp App ID <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" [maxlength]="25" placeholder="Enter App ID" title="Please enter your APP ID" [required]="!clientSignUpDao.defaultSubscription" [(ngModel)]="clientSignUpDao.whatsappAppId" name="whatsappAppId" id="whatsappAppId" class="form-control">
            </div>
            <div *ngIf="clientSignUpDao.facebookVerifyStatus == true && !clientSignUpDao.defaultSubscription == true" class="form-group col-lg-6">
              <label   class="control-label" >Whatsapp Permanent Token <i style="color: red" class="fa fa-asterisk"></i></label>
              <input   type="text" [minlength]="10" placeholder="Enter Permanent Token" title="Please enter Permanent Token" [required]="!clientSignUpDao.defaultSubscription" [(ngModel)]="clientSignUpDao.whatsappVerifiedToken" name="whatsappVerifiedToken" id="whatsappVerifiedToken" class="form-control">
            </div>

            <div class="form-group col-lg-6"><br>
              <button style=" background-color: #b1d3db;
            border-radius: 50px;
    border-color: #b1d3db;
    width: 100%;
    color: black;
    font-weight: 900; " class="btn btn-success">Next <i class="fa fa-arrow-right" ></i></button>
            </div>
            <div class="form-group col-lg-6"><br>
              <button style="float: right; background-color: #b1d3db; border-radius: 50px;border-color: #b1d3db; width: 100%;color: black;font-weight: 900;" class="btn btn-success" (click)="clearData()">Reset <i class="fa-solid fa-xmark"></i></button>
            </div>
          </form><br>
          <div class="form-group col-lg-12"><br>
<!--            <button config_id="1131908301456490" (click)="onSignupClick();" style="width: 100%; float:right; background-color: #b1d3db; border: 0; border-radius: 50px; color: black; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; height: 40px;padding: 0 24px;">Login with Facebook</button>-->
            <button config_id="440381552356352" (click)="onSignupClick();" style="width: 100%; float:right; background-color: #b1d3db; border: 0; border-radius: 50px; color: black; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; height: 40px;padding: 0 24px;">Login with Facebook</button>
          </div>
<!--          <div class="form-group col-lg-12"><br>-->
<!--            <button (click)="test();" style="width: 100%; float:right; background-color: #b1d3db; border: 0; border-radius: 50px; color: black; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; height: 40px;padding: 0 24px;">test embadded</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

</div>
