import { Component, OnInit } from '@angular/core';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {DriveService} from '../drive.service';
import {UserService} from '../../Services/User/user.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-drive',
  templateUrl: './file-upload-drive.component.html',
  styleUrls: ['./file-upload-drive.component.css']
})
export class FileUploadDriveComponent implements OnInit {

  constructor(private alert: AlertMessageService, private driveService: DriveService, private user: UserService, private dialog: MatDialogRef<FileUploadDriveComponent>) {


  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  uploadFile($event: Event) {
   this.alert.confirmMessage('Upload File?', 'Are you sure?').then(value => {
     this.alert.showLoader();
     this.driveService.uploadFile($event, this.user.user.accountId).then(value1 => {

       this.alert.hideLoader();
       this.alert.showSuccesMessage('Successfully uploaded file!!');
       this.dialog.close(value1);

     }, reason => {
       this.alert.showErrorMessage(reason);
       this.alert.hideLoader();
     });

   });
  }
}
