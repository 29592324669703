import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {data} from "jquery";
import {InstanceMasterService} from "../InstanceService/instance-master.service";
import {InstanceMasterDao} from "../InstanceMasterDAo";
import {AlertMessageService} from "../../../alert-message/alert-message.service";

@Component({
  selector: 'app-qr-window',
  templateUrl: './qr-window.component.html',
  styleUrls: ['./qr-window.component.css']
})
export class QrWindowComponent implements OnInit {

  masterDao:Array<InstanceMasterDao>;
  constructor(private dialog: MatDialogRef<QrWindowComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service:InstanceMasterService,private  alert:AlertMessageService) { }

  ngOnInit(): void {
    const current = this;
    current.checkConnection(this.data)
  }

  checkConnection(id:number): void {
    const current=this;
    setInterval(function(){
      current.service.getActive().subscribe(value => {
        current.masterDao=value;
        for (let  i=0;i<=current.masterDao.length;i++){
          if (current.masterDao[i].id==id && current.masterDao[i].connected==true){
            // current.alert.showSuccesMessage("QR Code Scanned!");
            this.current.dialog.close();
          }
        }
      })
    }, 3000);
  }


}
