
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
                <h4>Client Master</h4>
            </div>
            <button  (click)="addOrUpdateClientMaterForm(undefined,'Create')"  class="btn btn-add">Add Client<i
              class="fa fa-plus"></i></button>
            <button style="margin-left: 5px" class="btn btn-add " title="Bulk Upload (Maximum 1000)"
                    (click)="bulkUpload()">Bulk Upload <i (click)="downloadSampleFile()"
                                                          title="Click Here To View Sample File"
                                                          class="fa fa-question-circle"></i>
            </button>
            &nbsp;
          </div>

        </div>

        <div id="tablediv">
          <div class="table-responsive">
            <table  class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Customer Id</th>

                <th>Name</th>
                <th>Campany Name</th>
                <th>Email</th>


                <th>Number</th>
                <th>Agent Name</th>
                <th>Agent Number</th>


                <th>Account Id</th>
                <th>Action</th>
                <!--                <th>Type</th>-->


              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="clientMasterList.recordsTotal==0">
                <td colspan="14">No Entry</td>




              </tr>
              <ng-container *ngFor="let data of clientMasterList.data;let ind = index">
                <tr>
                  <td>{{data.customerId}}</td>
                  <td  [innerHTML]="data.clientName"  ></td>
                  <td  [innerHTML]="data.clientCompanyName"  ></td>
                  <td  [innerHTML]="data.clientEmailId"  ></td>
                  <td  [innerHTML]="data.clientNumber"  ></td>
                  <td  [innerHTML]="data.agentName"  ></td>
                  <td  [innerHTML]="data.agentNumber"  ></td>

                  <td  [innerHTML]="data.accountId"  ></td>
                  <td><button class="btn btn-add" (click)="addOrUpdateClientMaterForm(data,'Update')"><i class="fa fa-pencil"></i></button>
                    <!--                    <button class="btn btn-danger m-r-2" (click)="deleteConversation(data)"><i class="fa fa-trash"></i></button>-->
                  </td>






                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>

          <mat-paginator (page)="tableChange($event)" [length]=clientMasterList.recordsTotal
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>
        </div>


        <!--//-->
      </div>
    </div>
  </div>


</section>
