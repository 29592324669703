import { Component, OnInit } from '@angular/core';
import {ScheduleCampaignDao} from '../schedule-campaign-dao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {CampaignDao} from '../onetomanywsun/campaignService/CampaignDao';
import {CampaignServiceService} from '../onetomanywsun/campaignService/campaign-service.service';
import {PaginationDao} from "../../CommonObj/PaginationDao";

const  appPrefix= environment.appUrl +environment.whatsappunOfficialservice;
@Component({
  selector: 'app-schedule-campaign-report',
  templateUrl: './schedule-campaign-report.component.html',
  styleUrls: ['./schedule-campaign-report.component.css']
})
export class ScheduleCampaignReportComponent implements OnInit {
  dtTrigger: any;
  dtOptions: any;
  tableList: Array<ScheduleCampaignDao>;
  tableData: PaginationDao<CampaignDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  constructor(private http: HttpClient, private alert: AlertMessageService, private campaign: CampaignServiceService) {
  }

  ngOnInit(): void {
    this.loadData();
  }



  deleteCampaign(data : any) {
    console.log('Will delete ScheduleCampaign {}',data.id );
    this.alert.confirmMessage('Delete campaign ?', 'Are you sure?').then(value => {
      this.alert.showLoader();
      this.http.delete(appPrefix +'api/campaignSchedule/delete?campaignId='+data.id +'&accountId='+data.accountId).subscribe(value1 => {
        this.alert.hideLoader();
        this.alert.showSuccesMessage('Successfully Deleted Campaign!!');
      }, error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage(error.error.message);
      });
    });

  }

  loadData(){
    this.alert.showLoader();
    this.campaign.get(this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      this.alert.hideLoader();
    });
  }


  tableChange(json) {
    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    this.loadData();
  }
}
