import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApplicationDeployementService} from '../application-deployement.service';
import {ApplicationDeployeDao} from '../application-deploye-dao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ExcelReaderCommonComponent} from '../../CommonPopups/excel-reader-common/excel-reader-common.component';
import {MatDialog} from '@angular/material/dialog';
import {UploadFileComponent} from '../upload-file/upload-file.component';
import {Output, EventEmitter} from '@angular/core';
import {any} from 'codelyzer/util/function';
import {UserService} from '../../Services/User/user.service';
import {UserMasterDao} from '../../Services/User/UserMasterDao';
import {WaContactGroupDao} from '../../Unofficial/WaContactGroup/wa-contact-group-dao';

@Component({
  selector: 'app-application-deployement',
  templateUrl: './application-deployement.component.html',
  styleUrls: ['./application-deployement.component.css']
})
export class ApplicationDeployementComponent implements OnInit {


  private tableList: Array<ApplicationDeployeDao>;

  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private service: ApplicationDeployementService, private alert: AlertMessageService, private dialog: MatDialog, private userService: UserService) {
  }

  project: '';
  technology: '';
  type: '';
  server: '';
  deployementcontainer: '';
  description: '';
  addNewDiv = false;
  approvedBy: '';
  deployedBy: '';
  status: '';
  addNewDivS = false;
  // uploadedBy: any;
  // tslint:disable-next-line:typedef
  domain: any;
  id: number;
  uploadForm: FormGroup;
  formData;
  user: UserMasterDao;

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });
    this.getData();
  }

  // tslint:disable-next-line:typedef
  getData() {
    this.service.get().subscribe(value => {
      this.tableList = value;
    });
  }


  // tslint:disable-next-line:typedef
  submit() {

    // tslint:disable-next-line:label-position
    this.user = this.userService.getUser();
    const uploadedBy = this.user.accountId;
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('filetoUploadDrive').value);
    this.alert.showLoader();
    this.alert.confirmMessage('Upload Data', 'Are you sure?').then(value => {
      // tslint:disable-next-line:max-line-length no-shadowed-variable
      this.service.save(this.project, this.technology, this.type, this.server, this.deployementcontainer, uploadedBy, this.description, this.formData).subscribe(value => {
        console.log(value);
        this.alert.hideLoader();
        this.cancel();
      }, error => {
        this.alert.showErrorMessage('Something Went Wrong ');
        this.alert.hideLoader();
      });
    },);


  }

  // tslint:disable-next-line:typedef
  onFileSelect(event: Event) {
    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
      // this.uploadForm.get('filetoUpload').setValue(file);
    }
  }

  // tslint:disable-next-line:typedef
  addNew() {
    this.addNewDiv = true;
    this.addNewDivS = false;
  }

  // tslint:disable-next-line:typedef
  cancel() {

    this.project = '',
      this.type = '',
      // this.uploadedBy = '',
      this.deployementcontainer = '',
      this.technology = '',
      this.server = '',
      this.description = '',
      this.approvedBy = '',
      this.deployedBy = '',
      this.status = '',
      this.addNewDiv = false;
    this.addNewDivS = false;
  }

  // tslint:disable-next-line:typedef
  uploadFileComponent() {
    this.dialog.open(UploadFileComponent,
      {
        height: '40%',
        width: '40%',

      }).afterClosed().subscribe(value => {
      const fileList: FileList = value.target.files;
      if (fileList.length > 0) {
        const file = fileList[0];

        // const formData = new FormData();
        this.formData = new FormData();
        this.formData.append('file', file);

        console.log('form Data value' + this.formData);
      } else {
        this.alert.showErrorMessage('Please Select File');
      }
      console.log(value);
    });
  }


  // tslint:disable-next-line:typedef
  changeStatus(id: number) {
    this.id = id;
    this.addNewDivS = true;
  }

  // tslint:disable-next-line:typedef
  updateStatus() {

    console.log('id is this' + this.id);
    this.alert.showLoader();
    this.alert.confirmMessage('Change Status', 'Are you sure?').then(value => {
      // tslint:disable-next-line:max-line-length no-shadowed-variable
      this.service.updateStatus(this.approvedBy, this.deployedBy, this.status, this.id).subscribe(value => {
        console.log(value);
        this.alert.confirmMessage('Status Changed', this.id.toString());
        this.alert.hideLoader();
        this.cancel();
      }, error => {
        this.alert.showErrorMessage('Something Went Wrong ');
      });
    },);

  }
}
