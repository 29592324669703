
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
               </div>
      <div style="" class="panel-body">
        <div  class="table-responsive">


          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" >
            <thead>
            <tr>
              <th>Group Name </th>
              <th>Date </th>
              <th>Subject</th>
              <th>Message</th>
              <th>FileName</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container  >
              <tr *ngFor=" let data of tableList" >
                <td [innerHTML]="data.groupName"></td>
                <td [innerHTML]="data.date"></td>
                <td [innerHTML]="data.subject"></td>
                <td [innerHTML]="data.body"></td>
                <td [innerHTML]="data.fileName"></td>
                <td>


                  <button  type="button"  id="delete" class="btn btn-add"  style="background-color: crimson" (click)="delete(data.id)" title="Delete Email  "><i class="fa fa-trash"></i></button>

              </tr>

            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>


