import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {SlabDao} from "../slab-dao";
import {HttpClient} from "@angular/common/http";
import {NbccserviceService} from "../nbccservice.service";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {MatDialog} from "@angular/material/dialog";
import {error} from "jquery";

@Component({
  selector: 'app-slab-master',
  templateUrl: './slab-master.component.html',
  styleUrls: ['./slab-master.component.css']
})
export class SlabMasterComponent implements OnInit {
  tableList: Array<SlabDao>;
  dtTrigger: Subject<any> = new Subject<any>();

  dtOptions: any = {  bDestroy: true, order: []};
  constructor(private http:HttpClient, private service:NbccserviceService,private alert:AlertMessageService,private dialog:MatDialog) { }

  dao:SlabDao={id:null,maxSlab:null,minSlab:null,consumptionCharges:null};
  submitBtnTxt: any='Update';
  addNewDiv: any;
  ngOnInit(): void {
    this.getSlab();
  }

  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const toleng = value.length;
      console.log('data length', value);
      if (toleng < 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

     let k=0;
      for (let i = 0; i < toleng; i++) {
        this.alert.showLoader();
        if (value[0]['Min Slab'] == 0) {
            this.dao.maxSlab = value[i]['Max Slab'];
            this.dao.minSlab = value[i]['Min Slab'];
            this.dao.consumptionCharges = value[i]['Consumption Charges'];
            this.service.insertSlab(this.dao).subscribe(value1 => {
              this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
              console.log('upload file values is :: ' + value1);
              this.alert.showSuccesMessage('Upload Successfully !!');
              this.alert.hideLoader();
              window.location.reload();
              this.cancel();
            }, error => {
              this.alert.showErrorMessage('Failed To Upload Data!!');
              this.alert.hideLoader();
              this.cancel();
            });
          }
        else {
          this.alert.showErrorMessage('Cannot Insert Value Greate Than 0!!');
          this.alert.hideLoader();
        }
        }
    });

  }

  getSlab(){

    this.service.getSlab().subscribe(value => {
      console.log('Getting Slab',value)
      this.tableList=value;
      this.dtTrigger.next();
    })
  }

  downloadReport() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];

      tdArr.push('Max Slab');
      tdArr.push('Min Slab');
      tdArr.push('Consumption Charges');

    trArr.push(tdArr);
    this.service.getSlab().subscribe(value => {

      console.log('values are' + value);

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++)
      {
        tdArr = [];
           tdArr.push(value[i].maxSlab);
          tdArr.push(value[i].minSlab);
          tdArr.push(value[i].consumptionCharges);
        trArr.push(tdArr);
      }
      file.generateFile(trArr,   'Slab_Master_Report.xlsx');
    });
    this.alert.hideLoader();

  }

  editDao(data: SlabDao, ind: number, b: boolean) {

     this.addNewDiv=true;
     this.dao=data;
  }

  downloadSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
      tdArr.push('Max Slab');
      tdArr.push('Min Slab');
      tdArr.push('Consumption Charges');
    trArr.push(tdArr);

    tdArr = [];

      tdArr.push('400');
      tdArr.push('200');
      tdArr.push('20');
    trArr.push(tdArr);
    file.generateFile(trArr, 'Sample_SlabFile.xlsx');
    this.alert.hideLoader();
  }

  private cancel() {

  }

  delete(id: number) {

    this.alert.confirmMessage("Delete Record","Are You Sure?").then(value => {
      this.alert.showLoader();
      this.service.deleteSlabById(id).subscribe(value => {
        console.log('delete',value);
        this.alert.showSuccesMessage("Deleted");
        this.alert.hideLoader();
        window.location.reload();
      })
    })


  }

  onClose() {
    this.dao={id:null,maxSlab:null,minSlab:null,consumptionCharges:null};
    this.addNewDiv=false;
  }

  createOrUpdate() {
    this.alert.confirmMessage("Update Slab","Are You Sure?").then( (value) => {
      this.alert.showLoader();

      if (this.dao.minSlab>0)
      {


      this.service.updateSlab(this.dao).subscribe((value)=>{
        console.log(value);
        this.alert.showSuccesMessage('Successfully Inserted!');
        this.alert.hideLoader();
        this.onClose();

      },error => {
        this.alert.showErrorMessage("Something Went Wrong!");
        this.alert.hideLoader();
      })
      }else {
        this.alert.showErrorMessage("Cannot Insert 0 value");

      }


    },(error)=>{})
  }
}
