<div class="login-wrapper">

  <div class="container-center">
    <div class="login-area">
      <div class="panel panel-bd panel-custom">
        <div class="panel-heading">
          <div class="view-header">
            <div class="header-icon" style="width: 50px;height: 50px">
              <i   class="fa-solid fa-unlock"></i>
            </div>
            <div class="header-title">
              <h3>Verification OTP</h3>
            </div>
          </div>

        </div>
        <div class="panel-body">
          <form (submit)="verifyOtp()">
            <div style="display: block" class="form-group">
              <input  type="text"  placeholder="Enter OTP"  [(ngModel)]="otp" name="otp" required class="form-control">
            </div>
            <div>


              <br>
              <button class="btn btn-add pull-right">Login</button>

            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
