<div class="row">
  <div class="col-md-12">

    <form >

      <!--<h3>Agent Master Form</h3>-->

      <div  class="form-group col-lg-12">
        <label>Extension <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Extension(4367) "
               name="extenstion" [(ngModel)]="extensionWiseAgent.extenstion" maxlength="4" >
      </div>
      <div  class="form-group col-lg-12">
        <label>Agent Number <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter agent number +91"
               name="agentNumber" [(ngModel)]="extensionWiseAgent.agentNumber">
      </div>
      <div  class="form-group col-lg-12">
        <label>Agent Name <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="agentName" [(ngModel)]="extensionWiseAgent.agentName">
      </div>

      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitMasterForm(extensionWiseAgent)">{{submitBtnTxt}}</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>
  </div>
</div>
