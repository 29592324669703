import {Component, Inject, OnInit} from '@angular/core';
import {Gateway, SubscriptionDao, SubscriptionFormDao} from './SubscriptionDao';
import {SubscriptionServiceService} from './SubscriptionService/subscription-service.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {UidGenerationService} from '../Services/Uids/uid-generation.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  addnewform: boolean=false;
  isUpdate:boolean=false;
  tableList: Array<SubscriptionDao>=[];
  subscriptionDao:SubscriptionDao=null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SubscriptionFormDao,private subscription:SubscriptionServiceService,private alert:AlertMessageService,private uid:UidGenerationService) {
    this.loadData();
  }

  ngOnInit(): void {

  }


  loadData()
  {
    this.subscription.getByAccountId(this.data.accountId).subscribe(value => {
      if(value==null||value==undefined)
      {
        this.addnewform=true;
      }else{
        this.tableList.push(value);
        if(value.gateway == null){
          value.gateway = Gateway.AMEYO;
        }
        this.subscriptionDao=value;
      }


    },error => {
      this.addnewform=true;
      this.subscriptionDao={
        accountId: null,
        afterConsumptionSessionDed: null,
        afterConsumptionTemplateDed: null,
        baseUrl: null,
        deductionType: null,
        expiry: null,
        freeSessionMessage: null,
        freeTemplates: null,
        freeTemplatesMessage: null,
        isActive: true,
        maximumPerCampaign: null,
        password: null,
        sentSession: null,
        sentTemplate: null,
        subscriptionAmt: null,
        templateCreated: null,
        templateCreationDed: null,
        test: false,
        transactionId: null,
        userName: null,
        gateway:null,
        karixToken:null,
        waVerfiedNumber:null,
        cloudAPIToken:null,
        cloudAPIWhatsappID:null,
        cloudAPIPhoneNumberID:null,
        emailTo:'',
        emailCc:'',
        cloudAPIAppID:'',
        webhookEnable: false,
        webhookURL: '',
        outWebhookEnable: false,
        outWebhookUrl: '',
        current_limit:'',
        phoneNumberEvent:'',
        mediaStoretoDriveStatus:false,
        enablePriorityQueue:false,
        enableConvertUnverified:false,
        convertUnverifiedUrl:'',
        dlrReportOnEmail:false,
        clientEmail:'',
        enableGoogleSheet:false,
        showMessageContent:false
      }
    });
  }
  addOrUpdate() {
    this.subscriptionDao.accountId=this.data.accountId;
    var message="Create Subscription?";
    if(this.isUpdate)
    {
      message="Update Subscription?";
    }
    if(!this.isUpdate)
    {


        this.subscription.addSubsription(this.subscriptionDao).subscribe(value => {
          this.subscriptionDao=value;
          this.alert.showSuccesMessage("Successfully Added Subscription till "+value.expiry);
          this.cancelBtn();
          this.loadData()
        })


    }
    else{
      this.subscription.updateSubsription(this.subscriptionDao).subscribe(value => {
        this.subscriptionDao=value;
        this.alert.showSuccesMessage("Successfully Updated Subscription");
        this.cancelBtn();
        this.loadData()
      })
    }



  }

  cancelBtn() {
    this.addnewform=false;
    this.isUpdate=false;
  }

  editSubscription(subscription: SubscriptionDao) {
    console.log("This is sub ",subscription)
    this.subscriptionDao=subscription;
    this.addnewform=true;
    this.isUpdate=true;
    if(this.subscriptionDao == null){
      this.subscriptionDao.gateway = Gateway.AMEYO
    }

  }


  addNewBtn() {
    this.addnewform=true;
  }

  restoreSubscription() {
      this.subscription.restoreSubscription(this.data.accountId).subscribe(value => {
        console.log("The restore Subscription is ",value);
        this.subscriptionDao = value;
        if(value.gateway == null){
          this.subscriptionDao.gateway = Gateway.AMEYO;
        }
      });
  }
}
