import {Component, OnInit} from '@angular/core';
import {AccountMasterDao, CreditType} from '../../account-master/AccountMasterDao';
import {Subject} from 'rxjs';
import {UserService} from '../../Services/User/user.service';
import {AccountMasterService} from '../../account-master/AccountMasterService/account-master.service';
import {FormBuilder} from '@angular/forms';
import {ApplicationsService} from '../../Services/applications.service';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {UidGenerationService} from '../../Services/Uids/uid-generation.service';
import {UsermasterService} from '../../Services/UsermasterService/usermaster.service';
import * as $ from 'jquery';

import {PaginationDao} from '../../CommonObj/PaginationDao';
import {CampaignDao} from '../onetomanywsun/campaignService/CampaignDao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';

const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;

@Component({
  selector: 'app-stop-campaign',
  templateUrl: './stop-campaign.component.html',
  styleUrls: ['./stop-campaign.component.css']
})
 export class StopCampaignComponent implements OnInit {
   accountMasterDao: AccountMasterDao = {} as AccountMasterDao;
  dtOptions: any = {  bDestroy: true, order: []};
  parentId = 0;
  dtTrigger: Subject<any> = new Subject<any>();
  tableData: PaginationDao<CampaignDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
 accountId:number;
  tableList: Array<AccountMasterDao> = [];
 AddNewDiv= false;
  isInsert = true;
  private updateId: number;
  // tslint:disable-next-line:max-line-length
  constructor(public user: UserService, private accountService: AccountMasterService, private _formBuilder: FormBuilder, private application: ApplicationsService, private dialog: MatDialog, private alert: AlertMessageService, private counter: UidGenerationService, private userServie: UsermasterService,private http:HttpClient) {

    this.parentId = user.accountMasterDao.id;
    this.refreshData();

  }
  refreshData()
  {

    this.accountService.getAccountsByUsername().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }



  addNew() {
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
    this.accountMasterDao = {
      accountEmail: '',
      accountLogo: '',
      accountMinLogo: '',
      accountName: '',
      accountPhone: '',
      active: true,
      enableReseller:false,
      createAt: '',
      createBy: '',
      footer: '',
      id: 0,
      services: [],
      title: '',
      updateAt: '',
      updateBy: '',
      parentId: this.parentId,
      reseller: false,
      creditType:CreditType.SELF,
      accountTransactionReportDao:null,
      resellerReportDao:null,
      password:'',
      serviceName:'',
      agentStatus:true,
      enableAgentSupport:false
    };
    this.isInsert = true;
  }

  // tslint:disable-next-line:typedef
  cancelBtn() {
    $('#addnewdiv').css('display', 'none');
    $('#tablediv').fadeIn();
  }
  editAccount(account: AccountMasterDao, ind: number)
  {

    this.updateId = ind;

    this.isInsert = false;


    console.log('account', account);
    this.accountMasterDao = account;

    console.log('accountMasterDao', this.accountMasterDao);
    $('#tablediv').css('display', 'none');
    $('#addnewdiv').fadeIn();
  }

  ngOnInit(): void {


  }

  getAllActiveCampaign(id:number)
  {
    this.http.get<PaginationDao<CampaignDao>>(appPrefix+ 'api/messagecontroller/activeCampaign?accountId='+id+'&offset='+this.offset+'&limit='+this.limit).subscribe(value => {
      this.tableData=value;
    })
  }



  // tslint:disable-next-line:typedef
  showCampaign(id: number) {
this.accountId=id;
    this.getAllActiveCampaign(id);
    this.AddNewDiv=true;

  }

    tableChange(json) {
      this.offset = json.pageIndex*json.pageSize;
      this.limit = json.pageSize;
      this.getAllActiveCampaign(this.accountId);
    }

  stopCampaign(accountId: number) {

    this.alert.confirmMessage('Stop Campaign ?', 'Are You Sure!').then(value => {
      this.alert.showLoader();
      this.http.get(appPrefix +'rabbitmq/stopcampaign?accountId='+accountId).subscribe(value1 => {
        this.alert.hideLoader();
        this.cancel();
        this.alert.showSuccesMessage('Successfully Stop Campaign!!');
      });

    })

  }

  private cancel() {
    this.accountId=null;
  }
}
