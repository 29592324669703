import {Component, OnInit} from '@angular/core';
import {CampaignServiceService} from '../onetomanywsun/campaignService/campaign-service.service';
import {ChartDataDao} from '../../chart-and-tables/ChartDataDao';
import {GroupByDao} from '../../CampainService/GroupByDao';
import {CommoninstancedropdownComponent} from '../commoninstancedropdown/commoninstancedropdown.component';
import {InstanceDashBoardReport} from '../dashboardunws/InstanceGroupDao';
import {InstanceMasterService} from '../instance-master/InstanceService/instance-master.service';
import {InstanceMasterDao} from '../instance-master/InstanceMasterDAo';
import {MatDialog} from '@angular/material/dialog';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {AlertMessageService} from '../../alert-message/alert-message.service';

@Component({
  selector: 'app-dlr-report',
  templateUrl: './dlr-report.component.html',
  styleUrls: ['./dlr-report.component.css']
})
export class DlrReportComponent implements OnInit {
  dlrMessage = 0;
  totalMessage = 0;
  incList: Array<InstanceMasterDao> = [];
  fieldTable: Array<GroupByDao> = [];
  dlrPer = 10;
  startDate = '0';
  endddate = '0';
  reportBy=0;
  chartData: ChartDataDao = null;

  constructor(private campaignService: CampaignServiceService, private instanceService: InstanceMasterService, private dialog: MatDialog, private alert: AlertMessageService) {

    this.instanceService.getActive().subscribe(value => {
      this.incList = value;
    });
    this.clearFilter();

  }

  ngOnInit(): void {
  }
  //

  calculatePercentage(partialValue: number, totalValue: number) {
    return (100 * partialValue) / totalValue;
  }

  // tslint:disable-next-line:typedef
  loadData() {
    this.totalMessage = 0;
    this.dlrMessage = 0;
    this.dlrPer = 0;
    const chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
    // tslint:disable-next-line:max-line-length
    this.campaignService.getReportGroup({enddate: this.endddate, id: '', idNum: 0, page: 0, startdate: this.startDate, accountId: 0,reportBy:this.reportBy}).subscribe(value => {
      this.fieldTable = value;
      const dataNumber: Array<number> = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++) {

        // tslint:disable-next-line:triple-equals
        if (value[i]._id == 'DLV' || value[i]._id == 'VIEW') {
          this.dlrMessage += value[i].count;
        }
        this.totalMessage += value[i].count;
        chartDataDao.labels.push(value[i]._id);
        dataNumber.push(value[i].count);


      }
      chartDataDao.barChartData.push({
        data: dataNumber,
        label: 'Dlr Report Status',
        backgroundColor: '#FB8170',
        hoverBackgroundColor: '#fb8170',
        fill: false,
        borderColor: '#fb8170',
        barThickness: 40
      });



      this.dlrPer = Number(this.calculatePercentage(this.dlrMessage, this.totalMessage).toFixed(2).replace('NaN',''));
      chartDataDao.chartName = 'DLR Report';

      this.chartData = chartDataDao;


    });
  }


  clearFilter() {
    const todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);
    this.startDate = todayDate + ' ' + '00:00:00';
    this.endddate = todayDate + ' ' + '24:00:00';
    this.reportBy=0;
    this.loadData();
  }

  downloadFile() {
    this.alert.showLoader();
    this.campaignService.getTransReport({enddate: this.endddate, id: '', idNum: 0, page: 0, startdate: this.startDate, accountId: 0,reportBy:this.reportBy}).subscribe(value => {
      const file = new FileService<any>();
      const trArr = [];
      let tdArr = [];
      tdArr.push('Message-Id');
      tdArr.push('DST');
      tdArr.push('Content Type');
      tdArr.push('Content');
      tdArr.push('Caption');
      tdArr.push('Message Status');
      tdArr.push('Report Type');

      tdArr.push('Sent TIME');
      tdArr.push('DLR TIME');
      tdArr.push('VIEW TIME');
      trArr.push(tdArr);
      for (let i = 0; i < value.recordsTotal; i++) {
        tdArr = [];
        tdArr.push(value.data[i].id);
        tdArr.push(value.data[i].dst);
        tdArr.push(value.data[i].messageType);
        console.log(value.data[i].messageType.toString());
        switch (value.data[i].messageType.toString()) {
          case 'TEXT':
            tdArr.push(value.data[i].textMessage.content);
            tdArr.push('');
            break;
          case 'FILE':
            tdArr.push(value.data[i].fileMessage.fileUrl);
            tdArr.push(value.data[i].fileMessage.caption);
            break;

          default:
            tdArr.push('');
            tdArr.push('');
            break;
        }
        tdArr.push(value.data[i].messageStatus);

        // @ts-ignore
        console.log("value of data",value.data[i].panel)

        // @ts-ignore
        if(value.data[i].panel){
          tdArr.push("System");
        }
        else{
          tdArr.push("API");
        }


        tdArr.push(value.data[i].dateTiming.sentTime);
        tdArr.push(value.data[i].dateTiming.dlrTime);
        tdArr.push(value.data[i].dateTiming.viewTime);
        trArr.push(tdArr);


      }
      file.generateFile(trArr, 'DLRREPROT.xlsx');
      this.alert.hideLoader();

    });
  }

  // tslint:disable-next-line:typedef
  filter() {
    this.dialog.open(CommoninstancedropdownComponent, {data: this.incList}).afterClosed().subscribe(value => {
      if (value != null) {

        this.startDate = value.startdate + ' 00:00:00';
        this.endddate = value.enddate + ' 24:00:00';
        this.reportBy=value.reportBy;

        this.loadData();
      }
    });
  }
}
