import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Agent} from "../../AgentSupport/AgentMaster/agent-master/AgentDao";
import {UserService} from "../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {AgentServiceService} from "../../AgentSupport/AgentMaster/agent-service.service";
import {ExtensionWiseAgent} from "../ExtensionWiseAgent";
import {ExtensionWiseAgentService} from "../extension-wise-agent.service";
import { FormControl, Validators, ValidatorFn } from '@angular/forms';


@Component({
  selector: 'app-extension-wise-agent-form',
  templateUrl: './extension-wise-agent-form.component.html',
  styleUrls: ['./extension-wise-agent-form.component.css']
})
export class ExtensionWiseAgentFormComponent implements OnInit {

  @Output() public eventEmitter = new EventEmitter<string>();

  chatbotNameValidation: any;
  extensionWiseAgent: ExtensionWiseAgent;
  submitBtnTxt: String = 'Create';

  constructor(public user: UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,
              private extensionWiseAgentService: ExtensionWiseAgentService,
              @Inject(MAT_DIALOG_DATA) public data) {
    // @ts-ignore
    this.extensionWiseAgent = data.extensionWiseAgent;
    // @ts-ignore
    this.submitBtnTxt = data.submitBtnTxt;
  }

  ngOnInit(): void {
  }


  submitMasterForm(extensionWiseAgent1: ExtensionWiseAgent) {
    console.log("extension:", extensionWiseAgent1,this.submitBtnTxt)

    if (extensionWiseAgent1.extenstion == '') {
      this.alert.showErrorMessage('Extension can not be blank');
      return;

    }
    console.log("extension size",extensionWiseAgent1.extenstion.length)
    if (extensionWiseAgent1.extenstion.length!=4) {
      this.alert.showErrorMessage(' size must be 4');
      return;

    }
    if (extensionWiseAgent1.agentNumber == '') {
      this.alert.showErrorMessage('Agent number can not be blank');
      return;

    }

    if (this.submitBtnTxt == "Create") {
      console.log("create:")
      this.extensionWiseAgentService.insert(extensionWiseAgent1).subscribe(value => {
        console.log("value",value)
        this.alert.showSuccesMessageWithoutReload("Extension inserted Successfully")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
      },error => {
        console.log("Error aya :",error)
        this.alert.showErrorMessage(error.error)
        this.closeFlowForm();

      });
    }
    else {
      this.extensionWiseAgentService.update(extensionWiseAgent1).subscribe(value => {
        this.alert.showSuccesMessageWithoutReload("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }

    //

  }


  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(ExtensionWiseAgentFormComponent, {
      width: '830px',
      height: '600px'


    });
  }

}
