export interface ResponseCommon<DATA,T>
{
  data:DATA,
  status:T
  _data:DATA,
  _status:T
}
export enum QrStatus{
  CONNECTED, QR, FAILED
}
