import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import {RolePrivDao} from '../RoleMasterAllowDao';
import {Observable} from 'rxjs';
const  appPrefix=environment.appUrl+environment.authMicroservice;
@Injectable({
  providedIn: 'root'
})
export class RolePrivServiceService {

  constructor(private http:HttpClient) { }

  getByUser(id:number):Observable<Array<RolePrivDao>>
  {
    return this.http.get<Array<RolePrivDao>>(appPrefix+"api/rolemapping/"+id)
  }
  save(dao:RolePrivDao)
  {
    return this.http.post(appPrefix+"api/rolemapping/",dao)
  }

  delete(dao:RolePrivDao)
  {
    return this.http.post(appPrefix+"api/rolemapping/delete",dao)
  }


}
