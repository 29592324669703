import { Component, OnInit } from '@angular/core';
import {OutletDao} from "../outlet/OutletDao";
import {UsermasterService} from "../../../Services/UsermasterService/usermaster.service";
import {UserService} from "../../../Services/User/user.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment.prod";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {FreightChartDao} from "./FreightChartDao";

@Component({
  selector: 'app-freigh-chart',
  templateUrl: './freigh-chart.component.html',
  styleUrls: ['./freigh-chart.component.css']
})
export class FreighChartComponent implements OnInit {



  freightList: Array<FreightChartDao> = [];
  update = false;

  startdate: number = undefined;
  enddate: number = undefined;

  freightChartDao: FreightChartDao = {
    id:0,
    state:"",
    minWeight:0,
    maxWeight:0,
    fixedCost:0,
    toll:0,
    perKmCharge:0,
    feed:""
  };
  addNewDiv: boolean = false;

  constructor(private user:UsermasterService,private userData:UserService,private alert: AlertMessageService, private router: Router, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.http.get<Array<FreightChartDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/freight/getall?').subscribe(value => {
      this.freightList = value;
      this.alert.hideLoader();
    });
  }

  addNew() {
    this.freightChartDao = {
      id:0,
      state:"",
      minWeight:0,
      maxWeight:0,
      fixedCost:0,
      toll:0,
      perKmCharge:0,
      feed:""
    };
    this.update = false;
    this.addNewDiv = true;
  }

  isOrUpdate() {
    this.alert.showLoader()
    var message="Create Freight?"
    var successMessage="Successfully Created Freight!";
    let url = 'api/nouriture/freight/insert'
    if(this.update)
    {
      successMessage="Successfully Updated Outlet!";
      message="Update Freight?"
      url = 'api/nouriture/freight/update'
    }

    this.alert.confirmMessage("Are you sure?",message).then(value => {
      this.http.post<FreightChartDao>(environment.appUrl + environment.gsmgatewayservice + url,this.freightChartDao).subscribe(value => {
        this.freightChartDao=value;
        this.alert.showSuccesMessage(successMessage);
        this.alert.hideLoader();

      },error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage("Error!!")
      })
    })

  }

  cancel() {
    this.update = false;
    this.addNewDiv = false;
    this.getData();
  }

  edit(freightChartDao: FreightChartDao) {
    this.freightChartDao = freightChartDao;
    this.update = true;
    this.addNewDiv = true;

  }


  filter() {
    this.dialog.open(DatefilterComponent);
  }


  getData() {
    this.alert.showLoader();

    this.http.get<Array<FreightChartDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/freight/getall?').subscribe(value => {
      this.freightList = value;
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  delete(freightChartDao: FreightChartDao, ind: number) {
    this.alert.confirmMessage('Are you Sure?', 'Delete ' + freightChartDao.state + ' ? ').then(value => {
      this.http.delete(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/freight/delete?id=' + freightChartDao.id).subscribe(value => {
        this.alert.showSuccesMessage("Successfully deleted!")
        this.getData();
 });
    });
  }
}

