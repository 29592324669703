import {Component, Inject, OnInit} from '@angular/core';
import {CommonInstanceDropDao} from './CommonInstanceDropDao';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InstanceMasterService} from '../instance-master/InstanceService/instance-master.service';
import {InstanceMasterDao} from '../instance-master/InstanceMasterDAo';
import {AccountServiceFormDao} from '../../account-master/account-services/AccountServiceDao';

@Component({
  selector: 'app-commoninstancedropdown',
  templateUrl: './commoninstancedropdown.component.html',
  styleUrls: ['./commoninstancedropdown.component.css']
})
export class CommoninstancedropdownComponent implements OnInit {
  commonInc: CommonInstanceDropDao = {enddate: '', incId: 0, startdate: '',reportBy:0, status:true};



  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<InstanceMasterDao>,private dialog: MatDialogRef<CommoninstancedropdownComponent>, private instanceService: InstanceMasterService) {

  }

  ngOnInit(): void {
  }

  isOrUpdate() {
    this.dialog.close(this.commonInc);
  }
}
