import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ExtensionWiseAgent} from "../ExtensionWiseAgent";
import {UserService} from "../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {ExtensionWiseAgentService} from "../extension-wise-agent.service";
import {LanguageWiseAgent} from "../LanguageWiseAgent";
import {LanguagewiseagentService} from "../languagewiseagent.service";

@Component({
  selector: 'app-language-wise-agent-form',
  templateUrl: './language-wise-agent-form.component.html',
  styleUrls: ['./language-wise-agent-form.component.css']
})
export class LanguageWiseAgentFormComponent implements OnInit {
  @Output() public eventEmitter = new EventEmitter<string>();

  chatbotNameValidation: any;
  languageWiseAgent: LanguageWiseAgent;
  submitBtnTxt: String = 'Create';

  constructor(public user: UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,
              private languagewiseagentService: LanguagewiseagentService,
              @Inject(MAT_DIALOG_DATA) public data) {
    // @ts-ignore
    this.languageWiseAgent = data.languageWiseAgent;
    // @ts-ignore
    this.submitBtnTxt = data.submitBtnTxt;
  }

  ngOnInit(): void {
  }


  submitMasterForm(languageWiseAgent1: LanguageWiseAgent) {
    console.log("agentMasterDao:", languageWiseAgent1,this.submitBtnTxt)
    if (languageWiseAgent1.language == '') {
      this.alert.showErrorMessage('language can not be blank');
      return;

    }
    if (languageWiseAgent1.agentNumbers == '') {
      this.alert.showErrorMessage('agent numbers can not be blank');
      return;

    }

    if (this.submitBtnTxt == "Create") {
      console.log("create:")

      this.languagewiseagentService.insert(languageWiseAgent1).subscribe(value => {
        console.log("value",value)
        this.alert.showSuccesMessageWithoutReload("Extension inserted Successfully")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
        },error => {
        console.log("Error aya :",error)
        this.alert.showErrorMessage(error.error)
        this.closeFlowForm();
      });

    }
    else {
      this.languagewiseagentService.update(languageWiseAgent1).subscribe(value => {
        this.alert.showSuccesMessageWithoutReload("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }

    //

  }


  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(LanguageWiseAgentFormComponent, {
      width: '830px',
      height: '600px'


    });
  }

  updateLanguageId() {
    if (this.languageWiseAgent.language === 'ENGLISH') {
      this.languageWiseAgent.languageId = "1";
    } else if (this.languageWiseAgent.language === 'HINDI') {
      this.languageWiseAgent.languageId = "2"; // Change the value as per your requirement
    }
    else if (this.languageWiseAgent.language === 'KANNADA') {
      this.languageWiseAgent.languageId = "3"; // Change the value as per your requirement
    }
    else if (this.languageWiseAgent.language === 'TELUGU') {
      this.languageWiseAgent.languageId = "4"; // Change the value as per your requirement
    }
    else if (this.languageWiseAgent.language === 'TAMIL') {
      this.languageWiseAgent.languageId = "5"; // Change the value as per your requirement
    }
    else if (this.languageWiseAgent.language === 'URDU') {
      this.languageWiseAgent.languageId = "6"; // Change the value as per your requirement
    }
    else {
      this.languageWiseAgent.languageId = undefined;
    }
  }



}
