

  <h1 mat-dialog-title style="text-align: center">Message Info <i class="fa fa-info-circle"></i> </h1>

  <mat-dialog-content class="mat-typography">



    <table class="table table-bordered table-striped table-hover">

      <thead>
      <tr>
        <th>Status</th>
        <th>Sent Time</th>
        <th>Dlr Time</th>
        <th>View Time</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td [innerHTML]="data.messageStatus"></td>
        <td [innerHTML]="data.date"></td>
        <td [innerHTML]="data.dlrTime"></td>
        <td [innerHTML]="data.viewTime"></td>
      </tr>

      </tbody>

    </table>





  </mat-dialog-content>


  <mat-dialog-actions>
    <div class="reset-button">
      <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>


    </div>
  </mat-dialog-actions>

