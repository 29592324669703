
<section class="content-header">
  <div class="header-icon">

  </div>

  <div class="buttonexport">

    <div class="panel-title">
      <select  class="btn btn-add" #device (change)="enquiryTypeChange(device.value)">
        <option value="2">Orders</option>
        <option value="1">Dealership</option>
        <option value="3">Queries</option>
      </select>
      <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
      <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
      <button (click)="download()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

    </div>
  </div>



</section>
<!-- Main content -->
<!-- Main content -->
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">
          <div class="btn-group" id="buttonexport">
            <a href="#">
              <h4>Enquiries</h4>
            </a>
          </div>
        </div>

          <div  id="tablediv">
            <div class="table-responsive">


              <table class="table table-bordered table-striped table-hover">
                <thead>
                <tr>
                  <th *ngIf="enquiryType==2">Order ID</th>
                  <th>Date</th>
                  <th>Contact</th>
                  <th>Language</th>
                  <th *ngIf="enquiryType==1">Name</th>
                  <th *ngIf="enquiryType==1">Organisation</th>
                  <th >Enquired For</th>
                  <th *ngIf="enquiryType==2 || enquiryType==1">Pincode</th>
                  <th *ngIf="enquiryType==2 || enquiryType==1">State</th>
                  <th *ngIf="enquiryType==2 || enquiryType==1">City</th>
                  <th *ngIf="enquiryType==2">Delivery Mode</th>
                  <th *ngIf="enquiryType==1">Associated Farmers</th>
                  <th *ngIf="enquiryType==1">Monthly Req</th>
                  <th *ngIf="enquiryType==2">Material</th>
                  <th *ngIf="enquiryType==2">Feed</th>
                  <th *ngIf="enquiryType==2">Brand</th>
                  <th *ngIf="enquiryType==2">Product</th>
                  <th *ngIf="enquiryType==2">Price</th>
                  <th *ngIf="enquiryType==2">Count</th>
                  <th *ngIf="enquiryType==2">Weight</th>
                  <th *ngIf="enquiryType==2">Discounted Amount</th>
                  <th *ngIf="enquiryType==2">MRP</th>
                  <th *ngIf="enquiryType==2">Distance</th>
                  <th *ngIf="enquiryType==2">Nearest Factory</th>
                  <th *ngIf="enquiryType==2">Order Status</th>
                </tr>
                </thead>
                <tbody>
                <tr style="text-align: center" *ngIf="tableData.total==0">
                  <td colspan="14">No Entry</td>


                </tr>
                <ng-container *ngFor="let data of tableData.data;let ind = index">
                  <tr>
                    <td *ngIf="enquiryType==2" [innerHTML]="data.id"></td>
                    <td [innerHTML]="data.date"></td>
                    <td [innerHTML]="data.phoneNumber"></td>
                    <td ><span *ngIf="data.language == 1">English</span><span *ngIf="data.language == 2">Hindi</span><span *ngIf="data.language == 3">Bengali</span></td>
                    <td  *ngIf="enquiryType==1" [innerHTML]="data.name"></td>
                    <td  *ngIf="enquiryType==1" [innerHTML]="data.organisation"></td>
                    <td ><span *ngIf="data.enquiryType == 1">Dealership</span><span *ngIf="data.enquiryType == 2">Buy Feed</span><span *ngIf="data.enquiryType == 3">Query</span></td>
                    <td *ngIf="enquiryType==1||enquiryType==2" [innerHTML]="data.pincode"></td>
                    <td *ngIf="enquiryType==1||enquiryType==2" [innerHTML]="data.state"></td>
                    <td *ngIf="enquiryType==1||enquiryType==2" [innerHTML]="data.city"></td>
                    <td  *ngIf="enquiryType==2"><span *ngIf="data.deliveryMode == 1">Self Pickup</span><span *ngIf="data.deliveryMode == 2">Get Delivered</span></td>
                    <td  *ngIf="enquiryType==1" [innerHTML]="data.totalFarmers"></td>
                    <td  *ngIf="enquiryType==1" [innerHTML]="data.monthlyReq"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.material"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.feedType"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.brand"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.product"></td>
                    <td  *ngIf="enquiryType==2"[innerHTML]="data.price"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.count"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.totalWeight"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.totalAmount"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.mrpAmount"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.distance"></td>
                    <td  *ngIf="enquiryType==2" [innerHTML]="data.nearestFactory"></td>
                    <td  *ngIf="enquiryType==2" ><span *ngIf="data.status == 0">Unconfirmed</span><span *ngIf="data.status == 1">Confirmed</span><span *ngIf="data.status == 2">Paid</span></td>


                  </tr>
                </ng-container>



                </tbody>
              </table>
            </div>
            <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                           [pageSize]="10"
                           [pageSizeOptions]="[2, 5, 25, 100]">
            </mat-paginator>

          </div>



      </div>
    </div>
  </div>


</section>
