
<h1 mat-dialog-title style="text-align: center">Reporting for {{data.fullname}} <i class="fa fa-sitemap"></i> </h1>



<form ngNativeValidate>

  <mat-dialog-content class="mat-typography">

    <div   *ngIf="!addnewdiv">
      <div *ngIf="reportingList!=null">
        <select (change)="addReporting()" [(ngModel)]="userId" name="userId" class="form-control">
          <option [value]="0" [innerHTML]="'-------Select User-------'"></option>
          <ng-container *ngFor="let data of userList ">
            <option [value]="data.id" [innerHTML]="data.fullname"></option>

          </ng-container>

        </select>
        <input autocomplete="off" placeholder="Search Here" type="text" class="form-control" style="width: 100px" name="searchText" [(ngModel)]="searchText">
      <div class="table-responsive" >
        <table class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th>UserId</th>
            <th>Fullname</th>
            <th>Number</th>
            <th>Action</th>


          </tr>
          </thead>
          <tbody >
          <tr style="text-align: center" *ngIf="reportingList.total==0">
            <td colspan="3">No Entry</td>


          </tr>

          <ng-container  *ngFor="let data of reportingList.data| slice: reportingList.start : reportingList.end|filter:searchText ;let ind =index">

            <tr>
              <td [innerHTML]="data.id"></td>
              <td [innerHTML]="data.fullname"></td>
              <td [innerHTML]="data.phone"></td>
              <td><i (click)="deleteReporting(data,ind)" title="Delete Reporting"  class="fa fa-trash btn btn-add"></i></td>

              <td ></td>


            </tr>
          </ng-container>



          </tbody>
        </table>
      </div>
      <mat-paginator (page)="tableChange($event,reportingList)" [length]=reportingList.total
                     [pageSize]="10"
                     [pageSizeOptions]="[5,10,20,50]"></mat-paginator>
    </div>

</div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  class="btn btn-success"  type="submit">Filter <i class="fa fa-filter"></i></button>
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</form>
