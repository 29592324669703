
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">
          <button  (click)="filterData()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button  (click)="misscallReport()" class="btn btn-add">MissCall Report<i class="fa-solid fa-phone-missed"></i> </button>
          <button  (click)="downloadFile()" class="btn btn-add">Download File<i class="fa fa-download"></i></button>
          <button  (click)="uploadFile()" class="btn btn-add">Upload File<i class="fa fa-cloud-upload"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="!NewDiv">
          <div  id="tablediv">
            <div  class="table-responsive">
              <div style="" class="panel-body">
                <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" id="table"  >
                  <thead>
              <tr>
                <th>Retailer Number</th>
                <th>Retailer Code</th>
                <th>Retailer Name</th>
                <th>Sales Officer Name</th>
                <th>Location</th>
                <th>Types User</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              <ng-container  *ngFor="let data of  listsmsDao;let ind = index">
<!--              <ng-container >-->
                <tr>
                  <td [innerHTML]="data.retailerNumber"></td>
                  <td [innerHTML]="data.code "></td>
                  <td [innerHTML]="data.retailerName"></td>
                  <td [innerHTML]="data.salesOfficerName"></td>
                  <td [innerHTML]="data.location"></td>
                  <td [innerHTML]="data.status"></td>
                  <td [innerHTML]="data.date"></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        </div>
        </div>
        </div>
          <div style="" class="panel-body">
        <div *ngIf="NewDiv">
          <div  id="tablediv1">
            <div  class="table-responsive">
              <div style="" class="panel-body">
                <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" id="table1"  >
                <thead>
                <tr>
                  <th>MissCall Number</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <ng-container  *ngFor="let data of listmisscall;let ind = index">
                  <tr>
                    <td [innerHTML]="data.misscallNumber"></td>
                    <td [innerHTML]="data.status==1?'Registerd' : 'Diplicate Call' "></td>
                    <td [innerHTML]="data.date"></td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>

          </div>
        </div>
          </div>
          </div>
        </div>
  </div>
</div>
</div>
