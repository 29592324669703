<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" class="btn btn-add">Create Account<i
            class="fa fa-plus"></i></button>
        </div>
      </div>
      <div style="" class="panel-body">
        <div *ngIf="addNewDiv">
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-6" >
              <label>Client Name  <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.userName" MAXLENGTH="40" MINLENGTH="5"  type="text" class="form-control"  name="userName" required>
            </div>
            <div class="form-group col-lg-6" >
              <label>Display Name  <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.display_name" MAXLENGTH="40" type="text" class="form-control"  name="display_name" required>
            </div>
            <div class="form-group col-lg-6" >
              <label>Mobile No.  <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.mobileno" MAXLENGTH="40" type="text" class="form-control"  name="mobileno" required>
            </div>
            <div class="form-group col-lg-6" >
              <label>Email <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.email" MAXLENGTH="40" type="text" class="form-control"  name="email" required>
            </div>
            <div class="form-group col-lg-6" >
              <label>WABA ID <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.waba_id" MAXLENGTH="40" type="text" class="form-control"  name="watsapp_id" required>
            </div>
            <div class="form-group col-lg-6" >
              <label>Phone Number ID <sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.phone_display_id" MAXLENGTH="40" type="text" class="form-control"  name="phone_display_id" required>
            </div>
            <div class="form-group col-lg-6" >
              <label> Sender ID<sup class="required">*</sup></label>
              <input [(ngModel)]="accountOnKarixDao.sender_id" MAXLENGTH="40" type="text" class="form-control"  name="sender_id" required>
            </div>
            <div class="form-group col-lg-12">
              <button type="button" (click)="getDetails()" class="btn btn-add">Get Details</button>
              <button  type="submit" class="btn btn-add" [innerHTML]="'Save'"></button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv" class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Display Name</th>
              <th>Name</th>
              <th>Create Date </th>
              <th>Mobile No </th>
              <th>Whatsapp ID</th>
              <th>Phone ID</th>
              <th>Email</th>
              <th>API Key</th>
              <th>SENDER ID</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let accountOnKarixDao of tableList;let ind =index">
              <tr>
                <td [innerHTML]="accountOnKarixDao.display_name"></td>
                <td [innerHTML]="accountOnKarixDao.userName"></td>
                <td [innerHTML]="accountOnKarixDao.createDate"></td>
                <td [innerHTML]="accountOnKarixDao.mobileno"></td>
                <td [innerHTML]="accountOnKarixDao.waba_id"></td>
                <td [innerHTML]="accountOnKarixDao.phone_display_id"></td>
                <td [innerHTML]="accountOnKarixDao.email"></td>
                <td [innerHTML]="accountOnKarixDao.api_key"></td>
                <td [innerHTML]="accountOnKarixDao.sender_id"></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
<!--        <mat-paginator (page)="loadData($event)" [length]=this.tableList.length-->
<!--                       [pageSize]="10"-->
<!--                       [pageSizeOptions]="[2, 5,10, 25,50, 100]">-->
<!--        </mat-paginator>-->
      </div>
    </div>
  </div>
</div>

