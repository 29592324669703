<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div>
            <div>
                <h1>Add Meta Whatsapp Details</h1>
            </div>
            <form ngNativeValidate>
                <div class="form-group col-lg-6">
                    <label> <sup class="required">*</sup>Whatsapp Number</label>
                    <input type="text" [minlength]="10" [maxlength]="25" class="form-control" [(ngModel)]="data.whatsappNumber" name="whatsappNumber" required>
                </div>
                <div class="form-group col-lg-6">
                    <label> <sup class="required">*</sup>Whatsapp Phone Id</label>
                    <input type="text"  [minlength]="10" [maxlength]="25" class="form-control" [(ngModel)]="data.whatsappPhoneId" name="whatsappPhoneId" required>
                </div>
                <div class="form-group col-lg-6">
                    <label> <sup class="required">*</sup>Whatsapp App Id</label>
                    <input type="text" [minlength]="10" [maxlength]="25" class="form-control" [(ngModel)]="data.whatsappAppId" name="whatsappAppId" required>
                </div>
                <div class="form-group col-lg-6">
                    <label> <sup class="required">*</sup>whatsapp Id</label>
                    <input type="text" [minlength]="10" [maxlength]="25" class="form-control" [(ngModel)]="data.whatsappId" name="whatsappId" required>
                </div>
                <div class="form-group col-lg-12">
                    <label> <sup class="required">*</sup>Permanent Token</label>
                    <input type="text" [minlength]="20" class="form-control" [(ngModel)]="data.whatsappVerifiedToken" name="whatsappVerifiedToken" required>
                </div>
                <div class="form-group col-lg-12" [hidden]="visibleOtp">
                        <label> <sup class="required">*</sup>Enter OTP</label>
                        <input type="number" class="form-control" [(ngModel)]="otp" name="otp" required>
                </div>
                <div class="form-group col-lg-12" [hidden]="visibleOtp">
                        <button type="submit" class="btn btn-add" [hidden]="visibleOtp" (click)="submit()">Submit</button>
                        <button title="Cancel" (click)="cancel()" class="btn btn-add">Cancel</button>
                </div>
                <div class="form-group col-lg-12">
                    <div class="form-group col-lg-12" [hidden]="!visibleOtp">
                        <button type="submit" title="Click here for sending otp" class="btn btn-add"  (click)="generateOtp()">Send Otp</button>
                        <button title="Cancel" (click)="cancel()" class="btn btn-add">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
