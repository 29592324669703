
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

          <div *ngIf="tableList1!=null || tableList1!=undefined">

           <div *ngIf="tableList1.type.create==true">
          <button (click)="addNew()"  class="btn btn-add">Add  Instance<i
            class="fa fa-plus"></i></button>
           </div>
          </div>

          <div *ngIf="tableList1==null || tableList1==undefined">
            <button (click)="addNew()"  class="btn btn-add">Add  Instance<i
              class="fa fa-plus"></i></button>
          </div>
          <br>
        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Instance Active </label>
              <mat-slide-toggle [(ngModel)]="instance.active" name="active"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-12">
              <label>Store To Database </label>
              <mat-slide-toggle [(ngModel)]="instance.saveToDb" name="saveToDb"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-12">
              <label>Proxy Enabled </label>
              <mat-slide-toggle [(ngModel)]="instance.hasProxy" name="hasProxy"></mat-slide-toggle>
            </div>

            <div class="form-group col-lg-12">
              <label>Webhook Enabled </label>
              <mat-slide-toggle [(ngModel)]="instance.hasWebhook" name="hasWebhook"></mat-slide-toggle>
            </div>
<!--            <div *ngIf="instance.hasWebhook"  class="form-group col-lg-12">-->
<!--              <label>Enabled Header </label>-->
<!--              <mat-slide-toggle [(ngModel)]="instance.hasIncomingWebhookHeader" name="hasIncomingWebhookHeader"></mat-slide-toggle>-->
<!--            </div>-->
<!--            <div *ngIf="instance.hasIncomingWebhookHeader"  class="form-group col-lg-12">-->
<!--              <label>Header Key  <sup class="required">*</sup></label>-->
<!--              <input type="url" class="form-control" [(ngModel)]="instance.headerKey" name="headerKey" required>-->
<!--            </div>-->
<!--            <div *ngIf="instance.hasIncomingWebhookHeader"  class="form-group col-lg-12">-->
<!--              <label>Header Value  <sup class="required">*</sup></label>-->
<!--              <input type="url" class="form-control" [(ngModel)]="instance.headerValue" name="headerValue" required>-->
<!--            </div>-->


            <div class="form-group col-lg-12">
              <label>Out Webhook Enabled </label>
              <mat-slide-toggle [(ngModel)]="instance.hasOutWebhook" name="hasOutWebhook"></mat-slide-toggle>
            </div>

            <div class="form-group col-lg-12">
              <label>Sales Force Api Enabled </label>
              <mat-slide-toggle [(ngModel)]="instance.activeSalesApi" name="isActiveSalesApi"></mat-slide-toggle>
            </div>

            <div class="form-group col-lg-12">
              <label>Instance Name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.instanceName" name="instanceName" required>
            </div>
            <div *ngIf="instance.hasProxy" class="form-group col-lg-12">
              <label>Proxy </label>
              <select  class="form-control" [(ngModel)]="instance.proxyId" name="proxyId" required>



                <option value="">--Select Proxy--</option>
                <ng-container *ngFor="let data of proxyList ">
                  <option [value]="data.id" [innerHTML]="data.proxy"></option>
                </ng-container>
              </select>

            </div>
            <div *ngIf="instance.hasWebhook"  class="form-group col-lg-12">
              <label>Webhook Url  <sup class="required">*</sup></label>
              <input type="url" class="form-control" [(ngModel)]="instance.webhook" name="webhook" required>
            </div>

            <div *ngIf="instance.hasOutWebhook"  class="form-group col-lg-12">
              <label>Out Webhook    <sup class="required">*</sup></label>
              <input type="url" class="form-control" [(ngModel)]="instance.outwebhook" name="hasOutWebhook" required>
            </div>

            <div   class="form-group col-lg-12">
              <label>Per Day Limit<sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="instance.perDayLimit" name="perDayLimit" required>
            </div>

            <div  class="form-group col-lg-12">
              <label>Domain </label>
              <select  class="form-control" [(ngModel)]="instance.domain" name="domain" required>
                <option value="">--Select Domain--</option>
                <ng-container *ngFor="let data of domainList ">
                  <option [value]="data.host" [innerHTML]="data.host"></option>
                </ng-container>

              </select>

            </div>

            <div *ngIf="instance.activeSalesApi"  class="form-group col-lg-12">
              <label>Client Id   <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.clientid" name="isActiveSalesApi" required>
            </div>

            <div *ngIf="instance.activeSalesApi"  class="form-group col-lg-12">
              <label>Client Secret   <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.clientsecret" name="isActiveSalesApi" required>
            </div>

            <div *ngIf="instance.activeSalesApi"  class="form-group col-lg-12">
              <label>Grant Type<sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.granttype" name="isActiveSalesApi" required>
            </div>

            <div *ngIf="instance.activeSalesApi"  class="form-group col-lg-12">
              <label>User Name <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.username" name="isActiveSalesApi" required>
            </div>

            <div *ngIf="instance.activeSalesApi"  class="form-group col-lg-12">
              <label>Password <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="instance.password" name="isActiveSalesApi" required>
            </div>


            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>


        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Instance Id</th>
              <th>Instance Name</th>
              <th>Active</th>
              <th>Per day limit</th>
              <th>Proxy Enabled</th>
              <th>Save To Db</th>
              <th>Has Webhook</th>
              <th>Connected</th>
              <th>IsActiveSalesApi</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.id"></td>

                <td [innerHTML]="data.instanceName"></td>
                <td> <app-common-active-or-disable [active]="data.active"></app-common-active-or-disable>   </td>

                <td [innerHTML]="data.perDayLimit"></td>
                <td> <app-common-active-or-disable [active]="data.hasProxy"></app-common-active-or-disable>   </td>
                <td> <app-common-active-or-disable [active]="data.saveToDb"></app-common-active-or-disable>   </td>
                <td> <app-common-active-or-disable [active]="data.hasWebhook"></app-common-active-or-disable>   </td>
                <td> <app-common-active-or-disable [active]="data.connected"></app-common-active-or-disable>   </td>
                <td> <app-common-active-or-disable [active]="data.activeSalesApi"></app-common-active-or-disable>   </td>
                <td>


                  <div  *ngIf="tableList1!=undefined|| tableList1!=null" >
                    <div *ngIf="tableList1.type.update==true">
                  <i title="Click Here To Edit" (click)="edit(data)"
                     class="btn btn-add fa fa-pencil"></i>
                  </div>
                  </div>

                  <div  *ngIf="tableList1==undefined|| tableList1==null" >
                    <i title="Click Here To Edit" (click)="edit(data)"
                       class="btn btn-add fa fa-pencil"></i>
                  </div>

                  <div *ngIf="!data.connected else logoutcase">
                    <i  title="Click Here To Load Qr of latest version" (click)="loadQr(data)"
                        class="btn btn-add fa fa-qrcode"></i>
<!--                    <i  title="Click Here To Load Qr of latest Legacy version" (click)="loadQrlegacy(data)"-->
<!--                        class="btn btn-add fa fa-qrcode"></i>-->
                  </div>

                  <ng-template #logoutcase>
                     <i title="Click Here To Reboot" (click)="reboot(data)"
                         class="btn btn-add fa fa-refresh"></i>
                    <i title="Click Here To Logout" (click)="logout(data)"
                       class="btn btn-add fa fa-sign-out"></i>

                  </ng-template>



                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







