import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SessionDocument} from "../SessionDocument";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-question-response-flow',
  templateUrl: './question-response-flow.component.html',
  styleUrls: ['./question-response-flow.component.css']
})
export class QuestionResponseFlowComponent implements OnInit {
  @Output() public eventEmitter = new EventEmitter<string>();
  dynamicMap = new Map<string, string>();
  dynamicMapEntries: Array<[string, string]> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data,private sanitizer:DomSanitizer) {
    console.log("data of question response", data.sessionDocument);

    this.initializeDynamicMap(data.sessionDocument.questionsResponse);
  }

  getSafeHtml(text: string): SafeHtml {
    const urlRegex = /(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/gi;
    const replacedText = text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return this.sanitizer.bypassSecurityTrustHtml(replacedText);
  }

  containsLink(text: string): boolean {
    const urlRegex = /(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/i;
    return urlRegex.test(text);
  }

  initializeDynamicMap(questionsResponse) {

    if (questionsResponse !== undefined) {
      for (const key in questionsResponse) {
        if (questionsResponse.hasOwnProperty(key)) {
          const value = questionsResponse[key];
          this.dynamicMap.set(key, value);
        }
      }
      this.dynamicMapEntries = Array.from(this.dynamicMap.entries());
      console.log("question response", this.dynamicMap);
    }
  }

  ngOnInit(): void {
  }
}
