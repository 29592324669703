import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import {AccountMasterDao} from '../../AccountMasterDao';
import {Observable} from 'rxjs';
import {UidGenerationService} from '../../../Services/Uids/uid-generation.service';
import {RechargeDao} from '../RechargeDao';
import {any} from "codelyzer/util/function";
import { RechargeParamModel } from 'src/app/credit-debit-report-reseller/credit-debit-report-reseller.model';
const  appPrefix=environment.appUrl+environment.accountMicroverservice;
@Injectable({
  providedIn: 'root'
})
export class RechargeService {

  constructor(private http:HttpClient,private uid:UidGenerationService) { }

  insertReseller(account:RechargeDao):Observable<RechargeDao>{

    return  this.http.post<RechargeDao>(appPrefix+"recharge/resellerrecharge",account);
  }
  insert(account:RechargeDao):Observable<RechargeDao>{

    return  this.http.post<RechargeDao>(appPrefix+"recharge/insert",account);
  }
   getByAccount(rechare:any):Observable<Array<Map<string,string>>>
   {
      let url=appPrefix+'recharge/rechangebydate?accountId='+rechare.accountId+'&startDate='+rechare.startDate+'&endDate='+rechare.endDate+'&service='+rechare.service;
     return  this.http.get<Array<Map<string,string>>>(url);
   }


  getCreditDebitDetail(rechare:any):Observable<Array<RechargeDao>>
  {
    let url=appPrefix+`recharge/getbyaccountId/${rechare.accountId}/?startDate=`+rechare.startDate+'&endDate='+rechare.endDate+'&service='+rechare.service;

    console.log('urlis',url);
    return  this.http.get<Array<RechargeDao>>(url);
  }
   getById(transactionId:string):Observable<RechargeDao>
   {
     return  this.http.get<RechargeDao>(appPrefix+"recharge/getbytransactionId?id="+transactionId);
   }

   getCreditDebitDetailAsReseller(param:RechargeParamModel):Observable<Array<Map<string,string>>>{
    return this.http.get<Array<Map<string,string>>>(appPrefix + "recharge/rechangebydate?" + 
                    `accountId=${param.accountId}&startDate=${param.startDate}&endDate=${param.endDate}&service=${param.service}`);
   }
}
