import {Component, Input, OnInit} from '@angular/core';
import {Paramters} from '../ws-template/MessageDao';
import {currency} from '../../environments/currency';
import {AlertMessageService} from "../alert-message/alert-message.service";

@Component({
  selector: 'app-template-messagetype',
  templateUrl: './template-messagetype.component.html',
  styleUrls: ['./template-messagetype.component.css']
})
export class TemplateMessagetypeComponent implements OnInit {
  @Input("params")components:Paramters
  currency=currency;
  constructor(private alert:AlertMessageService) { }

  ngOnInit(): void {
    console.log('components: ',this.components);
  }

  convertDate() {
    console.log("this is data ",this.components.date_time);
  }

  validateInput() {
    if(this.components.type=='image'){
       if(this.components.image.link.search(" ") != -1 || this.components.image.filename.search(" ") != -1){
         this.alert.showErrorMessage("Space Not Allowed")
       }
    }else if(this.components.type=='document'){
      if(this.components.document.link.search(" ") != -1 || this.components.document.filename.search(" ") != -1){
        this.alert.showErrorMessage("Space Not Allowed")
      }
    }else if(this.components.type=='video'){
      if(this.components.video.link.search(" ") != -1 || this.components.video.filename.search(" ") != -1){
        this.alert.showErrorMessage("Space Not Allowed")
      }
    }
  }
}
