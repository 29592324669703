


<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          Many To Many
        </div>
      </div>
      <div style="" class="panel-body">
        <div >
          <form ngNativeValidate (submit)="createCampiagn()">


            <div class="form-group col-lg-12">
              <label>Campaign Name   <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="campaingName" name="campaingName" required>
            </div>





            <div class="form-group col-lg-12">
              <label>File   <sup class="required">*</sup> <i class="fa fa-question-circle" (click)="downloadSampleFile()"></i></label>
              <div   class="form-group col-lg-12">
                <button type="button" (click)="uploadFileComponent()" class="btn btn-add">Upload File <i class="fa fa-upload"></i></button>
              </div>
            </div>





            <div *ngIf="messageAl.length>0" class="form-group col-lg-12">
              <button *ngIf="messageAl.length>0" type="submit" class="btn btn-add">Start Campaign</button>
              <button type="button" class="btn btn-danger" (click)="clearData()">Clear</button>

            </div>

          </form>

        </div>


      </div>
    </div>
  </div>
</div>







