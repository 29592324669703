import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {ShubhLabhcampaignDao} from './shubh-labhcampaign-dao';
import {PaginationDao} from "../../CommonObj/PaginationDao";
const appprefix2 = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class ShubhLabhcampaignServiceService {

  constructor(private http: HttpClient) { }

  getData(startdate: number, enddate: number,offset: number,limit: number): Observable<PaginationDao<ShubhLabhcampaignDao>>
  {
    // tslint:disable-next-line:max-line-length
   return this.http.get<PaginationDao<ShubhLabhcampaignDao>>( appprefix2 + 'digimozo/v1/api/longcodews/getAll?startdate=' + startdate + '&enddate=' + enddate+'&offset='+offset+'&limit='+limit);
  }
  getAll(): Observable<Array<ShubhLabhcampaignDao>>
  {
    // tslint:disable-next-line:max-line-length
    return this.http.get<Array<ShubhLabhcampaignDao>>( appprefix2 + 'digimozo/v1/api/longcodews/get');

  }


}
