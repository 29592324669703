import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-initiate-chatbot',
  templateUrl: './initiate-chatbot.component.html',
  styleUrls: ['./initiate-chatbot.component.css']
})
export class InitiateChatbotComponent implements OnInit {
   msg:string;

  constructor(private route:ActivatedRoute) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.queryParams['id'];
    // const number = this.route.snapshot.queryParams['number'];

    console.log('ID from URL:', id);
    if (id) {
      // Construct the WhatsApp Web URL with the phone number and message
      // const whatsappUrl = `https://wa.me/917669933483?text=${id}`;
      const whatsappUrl = `https://wa.me/919311910612?text=${id}`;
      // const whatsappUrl = `https://wa.me/${number}?text=${id}`;


      this.msg="Welcome"
      // Redirect to WhatsApp Web
      window.open(whatsappUrl);
    } else {
      this.msg="Error | Id not found"
      // Handle the case where 'id' is not provided in the URL
      console.log('id not  found in the URL.');
    }

    // this.route.params.subscribe(params => {
    //   console.log("params",params)
    //   const id = params['id']; //
    //   // 'id' should match the parameter name in your route configuration console.log('ID from URL:', id); //
    //   console.log("id from param:",id)
    //   });
  }

}
