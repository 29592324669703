import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {formatDate} from "@angular/common";

import {NbccserviceService} from "../nbccservice.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BungalowMasterDao} from "../BungalowMasterDao";
import {onAction} from "sweetalert/typings/modules/actions";
import {any} from "codelyzer/util/function";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../Services/User/user.service";
import {v4 as uuidv4} from 'uuid';
import {TransationDao} from "../transation-dao";
import {environment} from "../../../../environments/environment.prod";

declare var jsSHA: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  data: BungalowMasterDao;
  amount: any;

  submitBtnTxt: any = 'Submit';
  // merchantID:any='T_33340';
  merchantID: any = 'P_31588';
  merchantTxnID: any;
  currencyCode: any = '356';
  transactionType: any = '';
  // payType: any=0;
  txnDate: any;
  result: any;
  customerName: any;
  customerMobileNo: any;
  bunaglowNo: any;
  visible: boolean = false;
  aggregatorID: any = 'AM_00066';
  dao: any = {
    secureHash: '',
    amount: '',
    respDescription: '',
    paymentMode: '',
    paymentInstId: '',
    responseCode: '',
    customerMobileNo: '',
    paymentSubInstType: '',
    merchantId: '',
    paymentID: '',
    merchantTxnNo: '',
    paymentDateTime: '',
    txnID: '',
    bungalowN: '',
    chequeNo: '',
    date: ''
  };

  constructor(private http: HttpClient, private service: NbccserviceService, private route: ActivatedRoute, private router: Router, private userservice: UserService) {
    this.route.queryParams.subscribe(queryParams => {
      this.amount = queryParams.amount;
      this.customerName = queryParams.name;
      this.customerMobileNo = queryParams.mobile;
      this.bunaglowNo = queryParams.bungalowNo;
      if (queryParams.openpage) {
        this.userservice.openpage = true;
        this.checkpagePermission();
      }
    });
  }

  ngOnInit(): void {
    let uuid = uuidv4();
    var rString = this.randomString(10, '0839475lmnrstuvwxyzkdiw124ABCDEFGjsdjkdHQERIJKLMNOPQRSTUVWXYZ');
    this.merchantTxnID = this.bunaglowNo + '' + rString;
    let today: number = Date.now();
    const format = 'yyyyMMddHHmmss';
    const locale = 'en-IN';
    const date = new Date();
    const formattedDate = formatDate(date, format, locale);
    this.txnDate = formattedDate;
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }


  createOrUpdate() {

    this.getHMACResult()

  }



  getHMACResult() {
    this.dao.merchantTxnNo = this.merchantTxnID;
    this.dao.bungalowN = this.bunaglowNo;
    this.http.post(environment.driveLocationUrl + 'gateway/nbcc/v1/api/trans/save',this.dao,{ }).subscribe(value => {
      },error => {
    })
    this.visible = true;
    var cardNo = document.forms["myForm"].cardNo.value
    var cardExpiry = document.forms["myForm"].cardExpiry.value
    var nameOnCard = document.forms["myForm"].nameOnCard.value
    var cvv = document.forms["myForm"].cvv.value
    var customerEmailID = document.forms["myForm"].customerEmailID.value
    var customerMobileNo = document.forms["myForm"].customerMobileNo.value
    var invoiceNo = document.forms["myForm"].invoiceNo.value
    var hashInput = "";
    hashInput =
      document.forms["myForm"].allowDisablePaymentMode.value +
      document.forms["myForm"].addlParam1.value +
      document.forms["myForm"].addlParam2.value +
      document.forms["myForm"].aggregatorID.value +
      document.forms["myForm"].amount.value + cardExpiry + cardNo +
      document.forms["myForm"].currencyCode.value + customerEmailID + customerMobileNo +
      document.forms["myForm"].customerName.value +
      document.forms["myForm"].customerUPIAlias.value + cvv +
      document.forms["myForm"].invoiceNo.value +
      document.forms["myForm"].merchantID.value +
      document.forms["myForm"].merchantTxnNo.value + nameOnCard +
      document.forms["myForm"].payType.value +
      document.forms["myForm"].paymentMode.value +
      document.forms["myForm"].returnURL.value +
      document.forms["myForm"].transactionType.value +
      document.forms["myForm"].txnDate.value;
    if (document.forms["myForm"].payType.value == 1) {
      hashInput =
        document.forms["myForm"].allowDisablePaymentMode.value +
        document.forms["myForm"].addlParam1.value +
        document.forms["myForm"].aggregatorID.value +
        document.forms["myForm"].amount.value + cardExpiry + cardNo +
        document.forms["myForm"].currencyCode.value + customerEmailID + customerMobileNo +
        document.forms["myForm"].customerName.value +
        document.forms["myForm"].customerUPIAlias.value + cvv +
        document.forms["myForm"].invoiceNo.value +
        document.forms["myForm"].merchantID.value +
        document.forms["myForm"].merchantTxnNo.value + nameOnCard +
        document.forms["myForm"].payType.value +
        document.forms["myForm"].paymentMode.value +
        document.forms["myForm"].returnURL.value +
        document.forms["myForm"].transactionType.value + document.forms["myForm"].txnDate.value;
    }
    const shaObj = new jsSHA("SHA-256", 'TEXT');
    console.log('sha', shaObj);
    shaObj.setHMACKey("99c4e9f9a4804a9fa2a847c33ad270ca", "TEXT"); // use the key shared with the merchant
    document.getElementById("hashtext").innerHTML = hashInput
    shaObj.update(hashInput);
    this.result = shaObj.getHMAC("HEX");
    document.forms["myForm"].secureHash.value = this.result;
  }


  saveData(){
    this.service.savePaymentDetails(this.dao).subscribe(value => {
      console.log('values', value);
    })
  }
  onClose() {
    // this.dialogRef.close();
  }

  checkpagePermission() {
    return true;
  }
}
