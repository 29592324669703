import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../Services/User/user.service";
import {environment} from "../../environments/environment.prod";
import {KarixTemplateMasterDao} from "./KarixTemplateMasterDao";
const  appPrefix = environment.appUrl + environment.whatsappOfficialservice;


@Injectable({
  providedIn: 'root'
})
export class KarixTemplateMasterService {

  constructor(private http: HttpClient, public auth: UserService) { }

  insert(formData: FormData):Observable<KarixTemplateMasterDao>{
    return this.http.post<KarixTemplateMasterDao>(appPrefix + "api/karixtemplatemaster/",formData, {headers: {'Testtt': 'multipart/form-data', Authorization: 'Bearer ' + this.auth.getAccessToken()}});
  }
  update(karixTemplateMasterDao: KarixTemplateMasterDao): Observable<KarixTemplateMasterDao>{
    return this.http.post<KarixTemplateMasterDao>(appPrefix + "api/karixtemplatemaster/update",karixTemplateMasterDao);
  }
  getbytemplateid(templateId: string): Observable<KarixTemplateMasterDao>{
    return this.http.get<KarixTemplateMasterDao>(appPrefix + "api/karixtemplatemaster/getbytemplateid?templateId=" + templateId);
  }
  getAllKarixTemplates(): Observable<Array<KarixTemplateMasterDao>>{
    return this.http.get<Array<KarixTemplateMasterDao>>(appPrefix + "api/karixtemplatemaster/getall");
  }
  disableKarixTemplate(templateId: string):Observable<any> {
    return this.http.get<any>(appPrefix + "api/karixtemplatemaster/delete?templateId="+templateId);
  }
}
