import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {Agent} from "../AgentMaster/agent-master/AgentDao";
import {environment} from "../../../environments/environment.prod";
import {ConversationGroup} from "./ConversationGroup";
import {Conversation} from "./Conversation";
import {CustomerGroupDao} from "./CustomerGroupDao";

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  c

  constructor(private http: HttpClient) {
  }


  // getClientNumberByAgent(accountId: number): Observable<Array<ConversationGroup>> {
  //   // return this.http.get<PaginationMain<Chatbot>>("http://localhost:8080/v1/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);
  //
  //   return this.http.get<Array<ConversationGroup>>(environment.appUrl+environment.customappurl+'api/conversation/getallclientnumberbyagent?accountId=' + accountId);
  // }
  getAllConversationByClientNumber(accountId: number,clientNumber:string): Observable<Array<Conversation>> {
    // return this.http.get<PaginationMain<Chatbot>>("http://localhost:8080/v1/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<Array<Conversation>>(environment.appUrl+environment.customappurl+'api/conversation/getallbycleintnumberandaccountid?accountId=' + accountId+'&clientNumber='+clientNumber);
  }
  getClientNumberByAgent(accountId: number,agentNumber:string): Observable<Array<CustomerGroupDao>> {
    // return this.http.get<PaginationMain<Chatbot>>("http://localhost:8080/v1/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<Array<CustomerGroupDao>>(environment.appUrl+environment.customappurl+'api/conversation/getallclientnumbersbyagent?accountId=' + accountId+"&agentNumber="+agentNumber);
  }

}
