import { Component, OnInit } from '@angular/core';
import {EmpMasterDao} from "../../GsmGateway/Daone/empmaster/EmpMasterDao";
import {UserMasterDao} from "../../../Services/User/UserMasterDao";
import {UsermasterService} from "../../../Services/UsermasterService/usermaster.service";
import {UserService} from "../../../Services/User/user.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment.prod";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {PriceChartDao} from "./PriceChartDao";
import {GridOptions} from "ag-grid-community";

@Component({
  selector: 'app-price-chart',
  templateUrl: './price-chart.component.html',
  styleUrls: ['./price-chart.component.css']
})
export class PriceChartComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  public gridColumnDefs;
  private sortingOrder;
  public gridOptions: GridOptions;
  public searchValue:string

  priceList: Array<PriceChartDao> = [];

 update = false;

  priceChartDao: PriceChartDao = {
    id:0,
    feedType:'',
    subFeedType:'NA',
    product:'',
    brand:'',
    weight:0,
    price:0,
    state:'',
    createDate:'',
    createBy:'',
    material:0,
    stock:0,
    discount:0
  };
  addNewDiv: boolean = false;

  constructor(private user:UsermasterService,private userData:UserService,private alert: AlertMessageService, private router: Router, private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute) {

    this.gridColumnDefs = [
      {
        headerName:"Material",
        field:"material",
        sortable: true,
        // width:"150px"
      },
      {
        headerName:"Feed Type",
        field:"feedType",
        sortable: true,
        // width:"150px"
      },
      {
        headerName:"Sub Feed Type",
        field:"subFeedType",
        // width:"150px"

      },
      {
        headerName:"Product",
        field:"product",
        // width:"150px"
      },
      {
        headerName:"Brand",
        field:"brand",
        // width:"150px"
      },
      {
        headerName:"Price",
        field:"price",
        // width:"70px"
      },
      {
        headerName:"Discount",
        field:"discount",
        // width:"70px"
      },
      {
        headerName:"Weight",
        field:"weight",
        // width:"70px"
      },
      {
        headerName:"State",
        field:"state",
        // width:"120px"
      },
      {
        headerName:"Stock",
        field:"stock",
        // width:"120px"
      },
      {
        headerName:"Last Update",
        field:"updateDate",
        // width:"120px"
      },
      {
        headerName:"Edit",
       // width:"100px",
        cellRenderer: (params)=>{
          var eSpan = document.createElement('button');
          eSpan.innerHTML = '<i class="hvr-buzz-out fa fa-pencil-square btn btn-add"></i>';
          eSpan.addEventListener('click', ()=> {
            this.edit(params.data)
          });
          return eSpan;
        },
      },
      {
        headerName:"Delete",
        // width:"100px",
        cellRenderer: (params)=>{
          var eSpan = document.createElement('button');
          eSpan.innerHTML = '<i class="hvr-buzz-out fa fa-trash btn btn-add"></i>';
          eSpan.addEventListener('click', ()=> {
            this.delete(params.data)
          });
          return eSpan;
        },
      },
    ]

  }

  ngOnInit(): void {
    this.alert.showLoader();

    this.http.get<Array<PriceChartDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/pricechart/getall').subscribe(value => {
      this.priceList = value;
      this.alert.hideLoader();
    });
  }

  addNew() {
    this.priceChartDao={
      id:0,
      feedType:'',
      subFeedType:'NA',
      product:'',
      brand:'',
      weight:0,
      price:0,
      state:'',
      createDate:'',
      createBy:'',
      material:0,
      stock:0,
      discount:0

    };
    this.update = false;
    this.addNewDiv = true;
  }

  isOrUpdate() {
    this.alert.showLoader()
    var message="Create Price?"
    var successMessage="Successfully Create Price!";
    let url = 'api/nouriture/pricechart/insert'
    if(this.update)
    {
      successMessage="Successfully Updated!";
      message="Update?"
      url = 'api/nouriture/pricechart/update'
    }

    this.alert.confirmMessage("Are you sure?",message).then(value => {
      this.http.post<PriceChartDao>(environment.appUrl + environment.gsmgatewayservice + url,this.priceChartDao).subscribe(value => {
        this.priceChartDao=value;
        this.alert.showSuccesMessage(successMessage);
        this.alert.hideLoader();

      },error => {
        this.alert.hideLoader();
        this.alert.showErrorMessage("Error!!")
      })
    })


  }

  cancel() {
    this.update = false;
    this.addNewDiv = false;
    this.getData();
  }

  edit(priceChartDao: PriceChartDao) {
    console.log("editing :: ",priceChartDao)
    this.priceChartDao = priceChartDao;
    this.update = true;
    this.addNewDiv = true;

  }

  verifyUploadFile(index,key,flag,row){

    let value = row[index][key];
    let isRequired = new Map();
    isRequired.set("0_Feed Type",true)
    isRequired.set("0_Brand",true)
    isRequired.set("0_Weight",true)
    isRequired.set("0_Product",true)
    isRequired.set("0_Price",true)
    isRequired.set("0_State",true)
    isRequired.set("0_Material",true)
    isRequired.set("0_Stock",true)

    isRequired.set("3_Material",true)
    isRequired.set("3_Discount",true)
    isRequired.set("3_State",true)
    isRequired.set("2_Material",true)
    isRequired.set("2_Stock",true)
    isRequired.set("2_State",true)
    isRequired.set("1_Material",true)
    isRequired.set("1_Price",true)
    isRequired.set("1_State",true)

    console.log("value",value)
    console.log("key",flag+"_"+key)
    console.log("isRequired",isRequired.get(flag+"_"+key))

    if(value == undefined || value == null || value == ""){
      if(isRequired.get(flag+"_"+key)){
        this.alert.showErrorMessage(`${key} can't be empty at row number : ${index}`)
        throw new Error(`${key} can't be empty at row number : ${index}`)
       }
  }
    return value;
  }

  bulkUpload(flag:number) {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      if(value==null)
      {
        return;
      }

      let al:Array<PriceChartDao>=[]
      for(var i=0;i<value.length;i++)
      {
        let row = {
          id:0,
          feedType:this.verifyUploadFile(i,"Feed Type",flag,value),
          subFeedType:value[i]["Sub Feed Type"],
          brand:this.verifyUploadFile(i,"Brand",flag,value),
          weight:this.verifyUploadFile(i,"Weight",flag,value),
          product:this.verifyUploadFile(i,"Product",flag,value),
          price:this.verifyUploadFile(i,"Price",flag,value),
          discount:this.verifyUploadFile(i,"Discount",flag,value),
          state:this.verifyUploadFile(i,"State",flag,value),
          createDate:'',
          createBy:'',
          material:this.verifyUploadFile(i,"Material",flag,value),
          stock:this.verifyUploadFile(i,"Stock",flag,value)
        }
        // id:0,
        //   feedType:value[i]["Feed Type"],
        // subFeedType:value[i]["Sub Feed Type"],
        // brand:value[i]["Brand"],
        // weight:value[i]["Weight"],
        // product:value[i]["Product"],
        // price:value[i]["Price"],
        // state:value[i]["State"],
        // createDate:'',
        // createBy:'',
        // material:value[i]["Material"],
        // stock:value[i]["Stock"]

        al.push(row)
      }
      console.log(al)
      this.alert.confirmMessage("Are you sure?","Upload "+al.length+" Prices?").then(res => {
        this.alert.showLoader();
        this.http.post<Array<string>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/pricechart/bulkinsert?flag='+flag,al).subscribe(alret => {

          this.alert.hideLoader();
          this.alert.showSuccesMessage("Successfully Uploaded")
          this.getData();

        },error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage("Failed To Upload!!")
        })
      })

    })

  }

  filter() {
    this.dialog.open(DatefilterComponent);
  }

  downloadSampleFile() {

    var trArr = [];
    var tdArr = [];
    let headerArr=[];
    headerArr.push('Material')
    headerArr.push('Feed Type')
    headerArr.push('Sub Feed Type')
    headerArr.push('Brand')
    headerArr.push('Product')
    headerArr.push('Price')
    headerArr.push('Discount')
    headerArr.push('Weight')
    headerArr.push('State')
    headerArr.push('Stock')

    tdArr.push('210000001');
    tdArr.push('Poultry Feed');
    tdArr.push('NA');
    tdArr.push('Hentek');
    tdArr.push('Pre-Starter');
    tdArr.push('2300');
    tdArr.push('23');
    tdArr.push('40');
    tdArr.push('Delhi');
    tdArr.push('100');

    trArr.push(headerArr)
    trArr.push(tdArr);

    let file = new FileService<any>();
    file.generateFile(trArr, 'SampleUpload.xlsx');
  }

  getData() {
    this.alert.showLoader();

    this.http.get<Array<PriceChartDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/pricechart/getall').subscribe(value => {
      this.priceList = value;
      this.alert.hideLoader();
    });
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  delete(priceChartDao: PriceChartDao) {
    this.alert.confirmMessage('Are you Sure?', 'Delete ' + priceChartDao.product + ' ? ').then(value => {
      this.http.delete(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/pricechart/delete?id=' + priceChartDao.id).subscribe(value => {
        this.alert.showSuccesMessage("Successfully deleted!")
        this.getData();



      });
    });
  }
  onFilterTextBoxChanged() {
    console.log()
    let value = document.getElementById('filter-text-box');
    this.searchValue=value["value"];
  }
  onGridReady(params: any) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.http.get<Array<PriceChartDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/pricechart/getall').subscribe(value => {
      this.priceList=value;
      params.api.setRowData(value);
      params.columnApi.autoSizeAllColumns();
    })

    // let list:Array<PriceChartDao>=[{  id:1,
    //   feedType:'Poultry Feed',
    //   subFeedType:'NA',
    //   product:'Pre-Starter',
    //   brand:'Anmol',
    //   weight:30,
    //   price:3200,
    //   state:'Uttar Pradesh',
    //   createDate:'',
    //   createBy:''}]
    //
    // this.priceList=list;
    // params.api.setRowData(list);
    // params.columnApi.autoSizeAllColumns();
  }

}
