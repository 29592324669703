

<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="addNew()" *ngIf="hasWriteOrUpdatePermission" class="btn btn-add">Add New Account<i class="fa fa-plus"></i></button>
        </div>
      </div>
      <div   style="" class="panel-body">
        <div *ngIf="hasWriteOrUpdatePermission" style="display: none" id="addnewdiv">
          <form ngNativeValidate (submit)="createOrUpdate()">


            <div class="form-group col-lg-12">
              <label>Account Status</label>
              <mat-slide-toggle name="status" [(ngModel)]="accountMasterDao.active"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-12">
              <label>Account Alias  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="accountMasterDao.accountName" name="accountName" required>
            </div>
            <div class="form-group col-lg-6">
              <label>Account Contact Number <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="accountMasterDao.accountPhone" name="accountPhone" required>
            </div>
            <div class="form-group col-lg-6">
              <label>Account  Email <sup class="required">*</sup></label>
              <input type="email" class="form-control" [(ngModel)]="accountMasterDao.accountEmail" name="accountEmail" required>
            </div>

            <div class="form-group col-lg-6">
              <label>Account  Logo <sup class="required">*</sup></label>
              <input type="url" class="form-control" [(ngModel)]="accountMasterDao.accountLogo" name="accountLogo" required>
            </div>

            <div class="form-group col-lg-6">
              <label>Account Min  Logo <sup class="required">*</sup></label>
              <input type="url" class="form-control" [(ngModel)]="accountMasterDao.accountMinLogo" name="accountMinLogo" required>
            </div>

            <div class="form-group col-lg-6">
              <label>Account Title <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="accountMasterDao.title" name="title" required>
            </div>
            <div class="form-group col-lg-6">
              <label>Account Footer <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="accountMasterDao.footer" name="footer" required>
            </div>

                        <div class="form-group col-lg-12">
                          <label>Credit Type<sup class="required">*</sup></label>
                          <mat-radio-group aria-label="Select an option"  [(ngModel)]="accountMasterDao.creditType" name="creditType">
                            <mat-radio-button [value]="'SELF'" [checked]="true">SELF</mat-radio-button>
                            <mat-radio-button [value]="'INHERITED'">INHERITED</mat-radio-button>
                          </mat-radio-group>
                        </div>





            <div class="form-group col-lg-12">
              <label>Services <sup class="required"></sup></label>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">

                  <thead>
                  <tr>
                    <th>Service</th>
                    <th>Service Type</th>
                    <th>Status</th>
                    <th>Action <i (click)="addService()" class="fa fa-plus-circle"></i></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container  *ngFor="let service of accountMasterDao.services;let ind = index">
                    <tr>
                      <td [innerHTML]="service.serviceName">

                      </td><td [innerHTML]="service.serviceType"></td>
                      <td ><i *ngIf="service.active" style="color: green" class="fa fa-check"></i><i *ngIf="!service.active" style="color: red" class="fa fa-remove"></i></td>
                      <td><i (click)="edit(ind,service)" class="btn btn-add fa fa-pencil-square">
                      </i><i (click)="delete(ind,service)" class="btn btn-add fa fa-trash"></i>
                        <i *ngIf="!isInsert" (click)="addSubscription(service)" title="Add Subscription" class="fa fa-shopping-cart btn btn-add"></i>
                        <i *ngIf="service.serviceName=='WHATSAPPOFFICIAL'  "  (click)="addCountryRates(this.accountMasterDao.id)" title="Add Country Rates" class="fa-solid fa-indian-rupee-sign btn btn-add"></i>

                      </td></tr>
                  </ng-container>





                  </tbody>

                </table>
              </div>
            </div>
            <div class="form-group col-lg-4">
              <div class="reset-button">
                <button  type="submit" class="btn btn-add">Save</button>
                <button type="button" (click)="cancelBtn()" class="btn btn-add">Cancel</button>
              </div>
            </div>
          </form>
        </div>

        <div id="tablediv">



          <div class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Account Id</th>
              <th>Account Alias</th>
              <th>Contact Number</th>
              <th>Password</th>
               <th>Email</th>

              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
          <ng-container *ngFor="let account of tableList;let ind =index" >
              <tr>
              <td [innerHTML]="account.id"></td>
              <td [innerHTML]="account.accountName"></td>
              <td [innerHTML]="account.accountPhone"></td>
              <td [innerHTML]="account.password"></td>
               <td [innerHTML]="account.accountEmail"></td>
              <td ><i *ngIf="account.active" style="color: green" class="fa fa-check"></i><i *ngIf="!account.active"  style="color: red" class="fa fa-remove"></i></td>
              <td><i title="Click Here To Edit" (click)="editAccount(account,ind)"  *ngIf="hasWriteOrUpdatePermission" class="btn btn-add fa fa-pencil">
              </i><i *ngIf="hasRechargePermission" title="Click here to view recharge" (click)="rechare(account)" class="btn btn-add fa fa-money-bills"></i>
                <i (click)="addRole(account.id)" title="click here to give role to account" class="btn btn-add fa fa-key"></i>

              </td>


            </tr>


          </ng-container>
            </tbody>
          </table>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>






