import {Component, Input, OnInit} from '@angular/core';
import {MessageDao, Paramters} from '../../ws-template/MessageDao';
import {MatDialog} from '@angular/material/dialog';
import {MessageInfoComponent} from '../message-info/message-info.component';
import {MediaService} from '../../MediaService/media.service';

@Component({
  selector: 'app-message-obj',
  templateUrl: './message-obj.component.html',
  styleUrls: ['./message-obj.component.css']
})
export class MessageObjComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('messageDao')messageDao: MessageDao;

  documentIcon: any;


  className: string;
  getChatType(): string
  {
    if (this.messageDao.chatSide == null || this.messageDao.chatSide == undefined || this.messageDao.chatSide == 'User')
    {
      return 'sentChat';
    }
    else{
      return 'recievedChat';
    }
      }
  constructor(private dialog: MatDialog, private mediaService: MediaService) { }


  ngOnInit(): void {
    this.className = this.getChatType();

  }

  // tslint:disable-next-line:typedef
  openMessageDetails(messageDao: MessageDao) {
    this.dialog.open(MessageInfoComponent, {data: messageDao});
  }



  // tslint:disable-next-line:typedef
  handleNull(name: string) {
    if (name == null)
    {
      return '';
    }
  }

  // tslint:disable-next-line:typedef
  checkTextMessage(messageDao: MessageDao) {

    // tslint:disable-next-line:triple-equals
    return messageDao.text != undefined;
  }

  // tslint:disable-next-line:typedef
  checkImageMessage(messageDao: MessageDao) {
    return messageDao.image != undefined;
  }
  checkVideoMessage(messageDao: MessageDao) {
    return messageDao.video != undefined;
  }
  checkAudioMessage(messageDao: MessageDao) {
    return messageDao.audio != undefined;
  }
  checkLocationMessage(messageDao: MessageDao) {
    return messageDao.location != undefined;
  }
  // tslint:disable-next-line:typedef
  checkDocumentMessage(messageDao: MessageDao) {
    let fileFormat="";
    if(messageDao.document.filename== null){
      let fileName = messageDao.document.mime_type.split('/');
       fileFormat =fileName[1];

    }
    else{
      console.log("ameyo")
      let fileName= messageDao.document.filename.split('.');
        fileFormat= fileName[1];

    }
     if(fileFormat=='pdf'){
       this.documentIcon='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png'

     }else if(fileFormat=='vnd.openxmlformats-officedocument.wordprocessingml.document'){
       this.documentIcon='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/2048px-.docx_icon.svg.png'

     }
     else if (fileFormat=='vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
       this.documentIcon='https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png'
     }
     else
     {
       this.documentIcon='src="assets/Images/document.png"'

     }


    return messageDao.document != undefined;
  }

  // tslint:disable-next-line:typedef
  downloadMedia(id: string, mimeType: string, date: string,fileName: string, attachementBase64: string,daoMimeType:string) {
      this.mediaService.downloadFile(id, mimeType,date,fileName,attachementBase64,daoMimeType);
  }

  checkChatbootMessage(messageDao: MessageDao) {
    return messageDao.interactive!=undefined;
  }

  checkButtonMessage(messageDao: MessageDao) {
    return messageDao.button!=undefined;
  }

  openMediaFile(messageDao: MessageDao) {
    if(messageDao.type == 'image'){
      window.open(messageDao.image.link);
    }else if(messageDao.type == 'document'){
      window.open(messageDao.document.link);
    }else if(messageDao.type == 'audio'){
      window.open(messageDao.audio.link);
    }else if(messageDao.type == 'video'){
      window.open(messageDao.video.link);
    }
  }
  openTemplateMediaFile(parameter: Paramters) {
    if(parameter.type == 'image'){
      window.open(parameter.image.link);
    }else if(parameter.type == 'document'){
      window.open(parameter.document.link);
    }else if(parameter.type == 'video'){
      window.open(parameter.video.link);
    }
  }
}
