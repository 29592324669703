import {Component, Inject, OnInit} from '@angular/core';
import {MessageDao} from '../ws-template/MessageDao';
import {CampaingService} from '../CampainService/campaing.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactFormDao} from '../conversation/contact-form/ContactFormDao';
import {ConversationDao} from '../conversation/ConversationDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {MediaDao, MediaService} from '../MediaService/media.service';

@Component({
  selector: 'app-quick-reply-message',
  templateUrl: './quick-reply-message.component.html',
  styleUrls: ['./quick-reply-message.component.css']
})
export class QuickReplyMessageComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(public mediaService: MediaService, private campservice: CampaingService, @Inject(MAT_DIALOG_DATA) public data: ConversationDao, private dialogRef: MatDialogRef<QuickReplyMessageComponent>, private alert: AlertMessageService) { }
  messageDao: MessageDao = {
    image: {caption: '', id: '', mime_type: '', filename: '', link:''},
    campaingId: 0,
    countryCode:'IND',
    chatSide: '',
    date: '',
    dlrTime: '',
    messageId: '',
    messageStatus: '',
    template: null,
    to: '',
    type: 'image',
    viewTime: '',
    text: {body: ''},
    video: {caption: '', id: '', mime_type: '', filename: '',link:''},
    audio: {caption: '', id: '', mime_type: '', filename: '',link:''},
    document: {caption: '', id: '', mime_type: '', filename: '',link:''},
    isPanel: true,
    interactive: null,
    attachementBase64: '',
    mimeType:'',
    scheduleTime:null,
    iScheduled:0,
    button:null,
    catalogMessageDao:null,
    location:null,
    messageContent:'',
    dateFilterLong:0,
    profileName:''
  };
  mediaDao: MediaDao = null;

  ngOnInit(): void {
  }

  sendMessage() {
/*
    {template: templateDao, to: to, type: type,campaingId:campaingId,date:"",dlrTime:"",viewTime:"",messageId:"SENT",messageStatus:"SENT",chatSide:"User",text:null};
*/

    this.alert.showLoader();
    this.messageDao.to = this.data.id;
    this.messageDao.messageId = 'SENT';
    this.messageDao.messageStatus = 'SENT';
    this.messageDao.chatSide = 'User';
    switch (this.messageDao.type) {
      case 'image':

        this.messageDao.audio = null;
        this.messageDao.video = null;
        this.messageDao.document = null;
        this.messageDao.text = null;

        if (this.mediaDao == null)

        {
          this.alert.showErrorMessage('No Media!!');
          return;
        }
        this.messageDao.image.id = this.mediaDao.media[0].id;
        this.messageDao.attachementBase64 = this.mediaDao.media[0].attachedFileBase64;
        this.messageDao.mimeType = this.mediaDao.media[0].mimeType;
        break;
      case 'video':
        this.messageDao.image = null;
        this.messageDao.audio = null;

        this.messageDao.document = null;
        this.messageDao.text = null;

        if (this.mediaDao == null)
        {
          this.alert.showErrorMessage('No Media!!');
          return;
        }
        this.messageDao.video.id = this.mediaDao.media[0].id;
        this.messageDao.attachementBase64 = this.mediaDao.media[0].attachedFileBase64;
        this.messageDao.mimeType = this.mediaDao.media[0].mimeType;
        break;
      case 'document':
        this.messageDao.image = null;
        this.messageDao.audio = null;
        this.messageDao.video = null;

        this.messageDao.text = null;

        if (this.mediaDao == null)
        {
          this.alert.showErrorMessage('No Media!!');
          return;
        }
        this.messageDao.document.id = this.mediaDao.media[0].id;
        this.messageDao.attachementBase64 = this.mediaDao.media[0].attachedFileBase64
        this.messageDao.mimeType = this.mediaDao.media[0].mimeType;
        break;
      case 'audio':
        this.messageDao.image = null;

        this.messageDao.video = null;
        this.messageDao.document = null;
        this.messageDao.text = null;

        if (this.mediaDao == null)
        {
          this.alert.showErrorMessage('No Media!!');
          return;
        }


        this.messageDao.audio.id = this.mediaDao.media[0].id;
        break;

    }
    this.messageDao.isPanel = true;
    this.campservice.sendSingleMessage(this.messageDao).subscribe(value => {


      this.messageDao.messageId = value.ids[0];
      console.log('Before ', this.messageDao);
      this.dialogRef.close(this.messageDao);
      this.alert.hideLoader();

    });

  }

  uploadFile($event: Event) {
    this.alert.showLoader();
    this.mediaService.uploadFile($event).then(value => {
      this.mediaDao = value;
      this.alert.hideLoader();
    }).catch(reason => {
      this.alert.hideLoader();
      this.alert.showErrorMessage('Failed To Upload File!!');
    });
  }
}
