import { Component, OnInit } from '@angular/core';
import {GroupMasterDao} from './group-master-dao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {Router} from '@angular/router';
import {MailgroupsService} from '../mailgroup/mailgroups.service';
import {GroupMasterService} from './group-master.service';
import {formatDate} from '@angular/common';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-group-master',
  templateUrl: './group-master.component.html',
  styleUrls: ['./group-master.component.css']
})
export class GroupMasterComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private service: GroupMasterService, private alert: AlertMessageService, private formBuilder: FormBuilder) {
    this.groupId = this.router.getCurrentNavigation().extras.state.groupId;
    this.groupName = this.router.getCurrentNavigation().extras.state.gname;


    console.log('gId' + this.groupId);
  }

  groupId: any;
  email: any;
  status: any;
  gId: any;
  groupName: any;
  searchText: any;
  file;
  uploadForm: any;

  ngOnInit(): void {

    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });

  }


  // tslint:disable-next-line:typedef
  createOrUpdate(data: GroupMasterDao) {
    // console.log('group Master component ' + this.router.getCurrentNavigation().extras.state.groupId);

    console.log('data ares' + data);
    this.alert.showLoader();
    this.alert.confirmMessage('Email added ?', 'Are you sure?').then(value => {
      this.service.save(data).subscribe(value1 => {
        this.alert.showSuccesMessage('Successfully Created!!');
        this.cancel();
        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage('Something Went Wrong!!' + error.message);
        this.alert.hideLoader();
      });
    }, );

  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  cancel() {

    this.router.navigate(['gsmgateway/v1/mailgroup']);
  }

  // tslint:disable-next-line:typedef
  onFileSelect($event: Event) {

    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
    }
  }

  // tslint:disable-next-line:typedef
  downloadSampleFile() {

    this.alert.showLoader();


    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('email');
    tdArr.push('status');
    tdArr.push('groupName');
    trArr.push(tdArr);

    tdArr = [];
    tdArr.push('example@gmail.com');
    tdArr.push('Active/InActive');
    tdArr.push('XYZ');
    trArr.push(tdArr);
    file.generateFile(trArr, 'SampleBulkUpload.xlsx');
    this.alert.hideLoader();

  }

  // tslint:disable-next-line:typedef
  submit() {

    const formData = new FormData();
    // formData.append('file', this.uploadForm.get('filetoUploadDrive').value);
    const file = this.uploadForm.get('filetoUploadDrive').value;
    const fileService = new FileService<GroupMasterDao>();
    const leadJson: Array<GroupMasterDao> = [];
    fileService.readFile(file).then(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++) {
        const leadData: GroupMasterDao = value[i];

        leadJson.push(leadData);
      }

      console.log('this is lead json ', leadJson);
      this.alert.showLoader();
      this.alert.confirmMessage('Upload Data?', 'Are you sure?').then(value2 => {
        // tslint:disable-next-line:max-line-length
        this.service.bulkUpload(this.groupId, leadJson).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + leadJson.length + ' Email ');
          console.log('upload file values is :: ' + value1.value);
          this.alert.showSuccesMessage('Upload Successfully !!');
          this.cancel();


        }, error => {
          this.alert.showErrorMessage('Failed To Upload Email!!');
          this.alert.hideLoader();
        });
      });


    });

  }
}
