export interface MessageDao{
  id: string;
  messageType: MessageType;
  dst: string;
  messageStatus: MessageStatus;
  textMessage: TextMessageDao;
  fileMessage: FileMessageDao;
  locationMessage: LocationMessageDao;
  contactMessage: ContactMessageDao;
  incId: number;
  listMessage: ListMessageSection;
  buttonMessage: ListMessageButton;
  dateTiming?: DateTiming;
  chatSide: string;
  templateButtonMessage: TemplateButtonMessage;
  promotional: boolean;
  iSchedule: number,
  scheduleTime: number,
  campaignSize: number,
  preferredIncId:number


}
export interface ListMessageRow{
  title: string;
  description: string;
  rowId: string;
}
export interface ListMessageButton{
  buttonText: string;
  description: string;
  sections: Array<ListMessageSection>;
  listType: number;
}
export interface ListMessageSection{
  title: string;
  rows: Array<ListMessageRow>;
}
export interface TextMessageDao{
  content: string;
}
export interface FileMessageDao{
  fileUrl: string;
  caption: string;
  fileName: string;
}
export interface LocationMessageDao{
  longitude: number;
  latitude: number;
}
export interface ContactMessageDao{
  name: string;
  vcard: string;
}
export enum MessageStatus {
  FAILED, SENT, DLV, VIEW, INVALIDDST, BLACKLISTED, CUTTING, CONTACTNOTFOUND, CONNECTIONNOTFOUND, INSMAXLIMIT, CAMPAIGNSTOPPED

}
export enum MessageType  {
  TEXT, FILE, CONTACT, LOCATION, BUTTONS, LISTS, TEMPLATEBUTTON, TEMPLATEBUTTONVIDEO, TEMPLATEBUTTONIMAGE,



}
export interface DateTiming
{
  sentTime: string;
  dlrTime: string;
  viewTime: string;
}

export interface  Image {

  url: string;
}

export interface Video {
  url: string;
}

export interface TemplateButtonMessage {
  text: string;
  footer: string;
  // templateButtons: any = [{index: undefined, urlButton: {displayText:null,url:null}, callButton: , quickReplyButton: undefined}];
  templateButtons: Array<any>;
  image: any;
  caption: string;
  video: any;
}

export interface TemplateButtons {
  index: number;
  urlButton: UrlButton;
  callButton: CallButton;
  quickReplyButton: QuickReplyButton;
  quickReplyButton2: QuickReplyButton;
  quickReplyButton3: QuickReplyButton;
}

export interface UrlButton {
  displayText: string;
  url: string;
}

export interface CallButton {
  displayText: string;
  phoneNumber: string;
}

export interface QuickReplyButton {
  displayText: string;
  id: string;
}


export enum ChatSide {
  User, Application
}
