<!-- <div class="panel panel-bd panel-custom"  >-->
<!--        <div class="panel-heading"  >-->
<!--          <div class="view-header"  >-->
<!--            <div class="header-icon">-->
<!--              <i class="fa fa-key" ></i>-->
<!--            </div>-->
<!--            <div class="header-title">-->
<!--              <h3>Verify Otp</h3>-->
<!--              <small><strong>Fill Details</strong></small>-->
<!--          </div>-->
<!--        </div>-->
<!--        </div>-->
<!--        <div class="panel-body">-->
<!--          <form style="height: 100%" ngNativeValidate (submit)="signUp()" >-->
<!--            <label style="color: red"  class="control-label" ><i style="color: red" class="fa fa-asterisk"></i> Otp Send To Provided details</label>-->
<!--            <div class="form-group col-lg-12">-->
<!--              <label   class="control-label" >Registered Mobile Number OTP <i style="color: red" class="fa fa-asterisk"></i></label>-->
<!--              <input   type="text" placeholder="Enter Mobile Number" title="Please enter Mobile Number" required  name="clientNumberOtp" id="clientNumberOtp" [(ngModel)]="receivedPhoneOtp" class="form-control">-->
<!--            </div>-->
<!--            <div *ngIf="clientSignUpDao.defaultSubscription == false" class="form-group col-lg-12">-->
<!--              <label   class="control-label" >Verified Whatsapp Number OTP <i style="color: red" class="fa fa-asterisk"></i></label>-->
<!--              <input   type="text" placeholder="Verified Whatsapp Number OTP" title="Please Enter Verified Whatsapp Number OTP" name="verifiedNumberOtp" id="verifiedNumberOtp" [(ngModel)]="receivedWhatsappNumberOtp" [required]="!this.clientSignUpDao.defaultSubscription"  class="form-control">-->
<!--            </div>-->

<!--            <div class="form-group col-lg-6"><br>-->
<!--              <button [disabled]="signUpButtonStatus" style=" background-color: #b1d3db;border-radius: 50px;border-color: #b1d3db;width: 100%;color: black;-->
<!--    font-weight: 900; " class="btn btn-success" >Sign Up <i class="fa-solid fa-lock"></i></button>-->
<!--            </div>-->
<!--            <div class="form-group col-lg-6"><br>-->
<!--              <button style="float: right; background-color: #b1d3db; border-radius: 50px;border-color: #b1d3db; width: 100%;color: black;font-weight: 900;" class="btn btn-success" (click)="back()">Back <i class="fa fa-arrow-left" aria-hidden="true"></i></button>-->
<!--            </div>-->
<!--            <div [hidden]="showSpinner == true" class="centered-spinner"><br>-->
<!--              <mat-spinner [hidden]="showSpinner == true" diameter="80" color="warn"></mat-spinner>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<div class="panel panel-bd panel-custom">
  <div class="panel-heading">
    <div class="view-header">
      <div class="header-icon">
        <i class="fa fa-key"></i>
      </div>
      <div class="header-title">
        <h3>Verify OTP</h3>
        <small><strong>Fill Details</strong></small>
      </div>
    </div>
  </div>
  <div class="panel-body">
    <form style="height: 100%" ngNativeValidate (submit)="signUp()">
      <div class="form-group">
        <label style="color: red" class="control-label"><i style="color: red" class="fa fa-asterisk"></i> OTP sent to provided details</label>
      </div>
      <div class="form-group">
        <label class="control-label">Registered Mobile Number OTP <i style="color: red" class="fa fa-asterisk"></i></label>
        <input type="text" placeholder="Enter Mobile Number" title="Please enter Mobile Number" required name="clientNumberOtp" id="clientNumberOtp" [(ngModel)]="receivedPhoneOtp" class="form-control">
      </div>
      <div *ngIf="clientSignUpDao.defaultSubscription == false" class="form-group">
        <label class="control-label">Verified Whatsapp Number OTP <i style="color: red" class="fa fa-asterisk"></i></label>
        <input type="text" placeholder="Verified Whatsapp Number OTP" title="Please Enter Verified Whatsapp Number OTP" name="verifiedNumberOtp" id="verifiedNumberOtp" [(ngModel)]="receivedWhatsappNumberOtp" [required]="!this.clientSignUpDao.defaultSubscription" class="form-control">
      </div>
      <div class="form-group">
        <button style="background-color: #b1d3db; width: 100%; border-radius: 50px; border-color: #b1d3db; color: black; font-weight: 900;" [disabled]="signUpButtonStatus" class="btn btn-success ">Sign Up <i class="fa-solid fa-lock"></i></button>
      </div>
      <div class="form-group">
        <button style="background-color: #b1d3db; border-radius: 50px; border-color: #b1d3db; color: black; font-weight: 900;" class="btn btn-success btn-block" (click)="back()">Back <i class="fa fa-arrow-left" aria-hidden="true"></i></button>
      </div>
      <div [hidden]="showSpinner == true" class="text-center">
        <mat-spinner [hidden]="showSpinner == true" diameter="80" color="warn"></mat-spinner>
      </div>
    </form>
  </div>
</div>

