import { Component, OnInit } from '@angular/core';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'app-filteration',
  templateUrl: './filteration.component.html',
  styleUrls: ['./filteration.component.css']
})
export class FilterationComponent implements OnInit {
  startdate: string;
  enddate: string;
  status: string;
  active: boolean;
  constructor(private router: Router) { }

  ngOnInit(): void {

     this.active = false;
  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  filter() {
    // tslint:disable-next-line:max-line-length
    this.router.navigate([], {queryParams: {startdate: this.startdate.split('-').join(''), enddate: this.enddate.split('-').join(''), status: this.status}});
  }

  // tslint:disable-next-line:typedef
  checkServece() {

    this.active = true;
  }
}
