import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {TemplateDao, TemplateDaoButton} from '../MessageDao';
import {Observable} from 'rxjs';

const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class WsTemplateService {

  constructor(private http: HttpClient) {
  }


  insertTemplate(templateDao: TemplateDao): Observable<TemplateDao>
  {
    return this.http.post<TemplateDao>(appPrefix + 'template/insert', templateDao);
  }

  insertTemplateButton(templateDao: TemplateDaoButton): Observable<TemplateDaoButton>
  {
    console.log('insert button method calling');
    return this.http.post<TemplateDaoButton>(appPrefix + 'template/insert', templateDao);
  }

  updateTemplateButton(templateDao: TemplateDaoButton): Observable<TemplateDaoButton>
  {
    console.log('update buttom method calling');
    return this.http.post<TemplateDaoButton>(appPrefix + 'template/update', templateDao);
  }


  updateTemplate(templateDao: TemplateDao): Observable<TemplateDao>
  {
    return this.http.post<TemplateDao>(appPrefix + 'template/update', templateDao);
  }

  getTemplateWsByAccountId(accountId: number): Observable<Array<TemplateDao>> {
    return this.http.get<Array<TemplateDao>>(appPrefix + 'template/getbyaccountid');
  }
  getTemplateWsByAccountIdStatus(): Observable<Array<TemplateDao>> {
    return this.http.get<Array<TemplateDao>>(appPrefix + 'template/getbyaccountactive');
  }
  findById(id: number): Observable<TemplateDao> {
    return this.http.get<TemplateDao>(appPrefix + 'template/get?id=' + id);
  }
}
