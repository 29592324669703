import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AccountServiceFormDao} from './AccountServiceDao';
import {ApplicationsService} from '../../Services/applications.service';

@Component({
  selector: 'app-account-services',
  templateUrl: './account-services.component.html',
  styleUrls: ['./account-services.component.css']
})
export class AccountServicesComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AccountServiceFormDao, private dialogRef: MatDialogRef<AccountServicesComponent>) { }

  ngOnInit(): void {
    console.log('this is account service form data ', this.data);
  }

  // tslint:disable-next-line:typedef
  submitData() {
    this.dialogRef.close(this.data.accountDetails);
    console.log('this is submit data ', this.data);
  }
}
