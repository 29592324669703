<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download Report<i class="fa fa-download"></i></button>


        </div>
      </div>
      <div   style="" class="panel-body">
<div class="row">
  <div *ngIf="showChart" class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
    <div id="cardbox1">
      <div class="statistic-box">
        <i class="fa fa-money fa-3x"></i>
        <div class="counter-number pull-right">
          <span class="count-number" [innerHTML]="openingBalance"></span>
          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>
                              </span>
        </div>
        <h3>Opening Balance</h3>
      </div>
    </div>
  </div>


  <div *ngIf="showChart" class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
    <div id="cardbox3">
      <div class="statistic-box">
        <i class="fa fa-money fa-3x"></i>
        <div class="counter-number pull-right">
          <span class="count-number" [innerHTML]="closingBalance"></span>
          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>
                              </span>
        </div>
        <h3>Closing Balance</h3>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showChart" class="row">
  <div class="col-xs-12 col-sm-12 col-md-12">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs">
      <li class="active"><a href="#tab1" data-toggle="tab" aria-expanded="true">Graphical <i
        class="fa fa-bar-chart"></i></a></li>
      <li class=""><a href="#tab2" data-toggle="tab" aria-expanded="false">Tabular <i class="fa fa-table"></i></a></li>
     </ul>
    <!-- Tab panels -->
    <div class="tab-content">
      <div class="tab-pane fade active in" id="tab1">
        <div class="panel-body">
          <app-chart-and-tables [chartData]="chartData"></app-chart-and-tables>
        </div>
      </div>
      <div class="tab-pane fade" id="tab2">
        <div class="panel-body">


          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">

              <thead>
              <tr>
                <th>Name</th>
                <th>Cr Amount</th>
                <th>Dr Amount</th>
                <th>Running Balance</th>



              </tr>
              </thead>
              <tbody>
              <tr>
                <td><b>Opening Balance</b></td>
                <td><b>{{result[0].crAmount}}</b></td>
                <td><b>{{result[0].drAmount}}</b></td>
                <td><b>{{result[0].runningBalance}}</b></td>


              </tr>
              <ng-container *ngFor="let fieldTable of result.slice(1);let ind = index">

                <tr>
                  <td [innerHTML]="fieldTable.name"></td>
                  <td [innerHTML]="fieldTable.crAmount"></td>
                  <td [innerHTML]="fieldTable.drAmount"></td>
                  <td [innerHTML]="fieldTable.runningBalance"></td>

                </tr>
              </ng-container>


              </tbody>
              <tfoot>
              <tr>
                <th><b>Closing Balance</b></th>
                <th><b>{{result[lengthData-1].crAmount}}</b></th>
                <th><b>{{result[lengthData-1].drAmount}}</b></th>
                <th><b>{{result[lengthData-1].runningBalance}}</b></th>
                <th></th>

              </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>



      <div class="tab-pane fade" id="tab3">
        <div class="panel-body">

      <form>
        <div>





        </div>
      </form>


        </div>
      </div>
    </div>
  </div>
</div>
  </div>
    </div>
</div>
</div>
