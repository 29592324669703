import {Component, Inject, OnInit} from '@angular/core';
import {ServiceTypeApp, SubscriptionDaoUnWa, SubscriptionDuration} from './SubscriptionDao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {SubscriptionUnService} from './SubscriptionService/subscription-un.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SubscriptionFormDao} from '../../subscription/SubscriptionDao';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
@Component({
  selector: 'app-subscription-wa-un',
  templateUrl: './subscription-wa-un.component.html',
  styleUrls: ['./subscription-wa-un.component.css']
})
export class SubscriptionWaUnComponent implements OnInit {
  apiQueList: Array<string> = [];
  panelQueList: Array<string> = [];
  subscriptionDao: SubscriptionDaoUnWa = {
    apiQue: '', panelQue: '', perMinMsg: 10,
    active: false,
    dateFilter: 0,
    expiry: '0',
    expiryReminder: '',
    perInstanceCreation: 0,
    perMessageDeduct: 0,
    refId: '',
    serviceType: ServiceTypeApp.BALANCE,
    subscriptionAmt: 0,
    subscriptionDuration: SubscriptionDuration.MONTHLY,
    subscriptionDurationDays: 0,
    accountId: 0,
    updateBy: 0,
    salesPersonEmail: '',
    driveLimit: null,
  };
  serviceTypeApp: ServiceTypeApp;

  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private alert: AlertMessageService, private subscription: SubscriptionUnService, @Inject(MAT_DIALOG_DATA) public data: SubscriptionFormDao) {
    this.http.get<Array<string>>(appPrefix + 'cache/getpanelqueall').subscribe(value => {
      this.panelQueList = value;
    });
    this.http.get<Array<string>>(appPrefix + 'cache/getapiqueall').subscribe(value => {
      this.apiQueList = value;
    });

  }

  ngOnInit(): void {

    this.subscription.getSubscription(this.data.accountId).subscribe(value => {
      if (value != null) {
        this.subscriptionDao = value;

        console.log('subscriptionDao', value);
      }

    });
  }

  // tslint:disable-next-line:typedef
  addOrUpdate() {
    this.subscriptionDao.accountId = this.data.accountId;

    // tslint:disable-next-line:triple-equals



    // tslint:disable-next-line:triple-equals
    if (this.subscriptionDao.serviceType == ServiceTypeApp.EXPIRY) {
      // tslint:disable-next-line:triple-equals
      if (this.subscriptionDao.subscriptionDuration == SubscriptionDuration.MONTHLY) {
         this.subscriptionDao.subscriptionDurationDays = 30;
        // tslint:disable-next-line:triple-equals
      } else if (this.subscriptionDao.subscriptionDuration == SubscriptionDuration.QUARTER) {
        this.subscriptionDao.subscriptionDurationDays = 90;
      }
      if (this.subscriptionDao.subscriptionDurationDays <= 0) {
        this.alert.showErrorMessage('Subription days cannot be 0');
        return;
      }
    }

    // tslint:disable-next-line:triple-equals
    if (this.subscriptionDao.serviceType == ServiceTypeApp.HYBRID) {
      console.log('service type hybrid');
      // tslint:disable-next-line:triple-equals
      if (this.subscriptionDao.subscriptionDuration == SubscriptionDuration.MONTHLY) {
        this.subscriptionDao.subscriptionDurationDays = 30;
        // tslint:disable-next-line:triple-equals
      } else if (this.subscriptionDao.subscriptionDuration == SubscriptionDuration.QUARTER) {
        this.subscriptionDao.subscriptionDurationDays = 90;
      }
      if (this.subscriptionDao.subscriptionDurationDays <= 0) {
        this.alert.showErrorMessage('Subription days cannot be 0');
        return;
      }
    }


    console.log('This is subscription object ', this.subscriptionDao);
    this.alert.confirmMessage('Save Subscription?', 'Are you sure?').then(value => {
      this.subscription.save(this.subscriptionDao).subscribe(value1 => {
        this.subscriptionDao = value1;
        this.alert.showSuccesMessage('Successfully Saved Subscription!!');
      }, error => {
        console.log(error);
        this.alert.showErrorMessage(error.error.message);
      });
    });


  }

  // tslint:disable-next-line:typedef
  cancelBtn() {

  }
}
