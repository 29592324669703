import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountMasterDao} from '../AccountMasterDao';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {AccountTransactionReportDao} from "../AccountTransactionReportDao";
import { CampaignReportListModel } from 'src/app/campaign-report-reseller/campaign-report-resseller.model';
import { ScheduledReportListModel } from 'src/app/scheduled-report-reseller/scheduled-report-reseller.model';
const  appPrefix = environment.appUrl + environment.accountMicroverservice;

@Injectable({
  providedIn: 'root'
})
export class AccountMasterService {

  private refreshSubject = new Subject<void>();
  // Observable to subscribe to for refresh events
  refresh$ = this.refreshSubject.asObservable();
  // Method to trigger a refresh
  triggerRefresh() {
    this.refreshSubject.next();
  }

  constructor(private http: HttpClient) { }



  insert(account: AccountMasterDao): Observable<AccountMasterDao>{
    console.log('AccountMasterDao', account);
    return  this.http.post<AccountMasterDao>(appPrefix + 'account/insert', account);
  }
  update(account: AccountMasterDao): Observable<AccountMasterDao>{
    console.log('AccountMasterDao', account);
    return  this.http.patch<AccountMasterDao>(appPrefix + 'account/update', account);
  }
  gellAll(): Observable<Array<AccountMasterDao>>{
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/');
  }
  gellById(id: number): Observable<Array<AccountMasterDao>>{
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/findbyid/' + id);
  }
  getbalance(id: number): Observable<any>{
    return  this.http.get<any>(appPrefix + 'account/getaccountBalance?id=' + id);
  }
  getAccountsByUsername(): Observable<Array<AccountMasterDao>>
  {
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/myaccounts');
  }

  getAccountById(id: number): Observable<AccountMasterDao> {
    return this.http.get<AccountMasterDao>(appPrefix + 'account/getbyidaccount?id=' + id);
  }

  getAccountByParentId(id: number): Observable<Array<AccountMasterDao>>
  {
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/getbyparent/' + id);
  }

  getAccountAgentByParentId(id: number): Observable<Array<AccountMasterDao>>
  {
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/getbyparentAgent/' + id);
  }

  getUserAgents(id:number): Observable<Array<any>>{
    return this.http.get<Array<any>>(appPrefix + 'account/getbyallAgent/' + id);
  }


  getResellerStats(id:number,startDate:number,endDate:number,offset:number,limit:number):Observable<Array<AccountMasterDao>>{
    return  this.http.get<Array<AccountMasterDao>>(appPrefix + 'account/getresellerstats?resellerId=' + id+"&startDate="+startDate+"&endDate="+endDate);

  }

  getCampaignReportById(id: number): Observable<Array<CampaignReportListModel>>
  {
    return  this.http.get<Array<CampaignReportListModel>>(environment.appUrl + "wabuissness/v1/campaign/" + id);
  }

  getScheduledReportById(id: number): Observable<Array<CampaignReportListModel>>
  {
    return  this.http.get<Array<CampaignReportListModel>>(environment.appUrl + "wabuissness/v1/campaign/" + id);
  }

  getClientByParentId(id: number): Observable<Array<any>>
  {
    return  this.http.get<Array<any>>(appPrefix + '/account/getbyclients/' + id);
  }


}
