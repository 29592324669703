<h1 mat-dialog-title style="text-align: center">Filter <i class="fa fa-filter"></i> </h1>



<form ngNativeValidate>

<mat-dialog-content class="mat-typography">

  <div class="form-group">

  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button  class="btn btn-success"  type="submit">Filter <i class="fa fa-filter"></i></button>
  <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

</mat-dialog-actions>
</form>
