<p>long-code-campaign works!</p>


<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <h2 style="text-align: center">Lucky Draw Campaign Report</h2>
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">




        </div>
        <button  (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
        <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
        <!--      <button  (click)="loadData()" class="btn btn-add">Show All<i class="fa fa-remove"></i></button>-->

      </div>

      <div style="" class="panel-body">

        <div  class="table-responsive">


          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover" >
            <thead>
            <tr>
              <th>Mobile Number</th>
              <th>message</th>
              <th>IOCL</th>
              <th>SAP CODE</th>
              <th>Bill Number</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody>

          <ng-container *ngFor="let data of tableList;let ind =index">

              <tr >

                <td [innerHTML]="data.phoneNo"></td>
                <td [innerHTML]="data.message"></td>
                <td [innerHTML]="data.iocl"></td>
                <td [innerHTML]="data.sapCode"></td>
                <td [innerHTML]="data.billNumber"></td>
                <td [innerHTML]="(data.date | slice:6:8)+('-')+(data.date | slice:4:6)+('-')+(data.date | slice:0:4)"></td>

                <td>
                  <!-- <i title="Click Here To Edit " (click)="drawObdReport(user,ind)"
                        class="btn btn-add fa fa-bar-chart "></i>-->
<!--                  <i title="Download Report" (click)="downloadReport(user,ind)"-->
<!--                     class="btn btn-add fa fa-download "></i>-->
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
