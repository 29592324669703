



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Domain<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">




            <div class="form-group col-lg-12">
              <label>Host  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="domainDao.host" name="host" required>
            </div>










            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Domain Name</th>


              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.host"></td>


                <td>

                  <i title="Click Here To Edit" (click)="edit(data)"
                     class="btn btn-add fa fa-pencil"></i>

                  <i title="Click Here To Delete" (click)="delete(data,ind)"
                       class="btn btn-add fa fa-trash"></i>


                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







