<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
          <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
          <button (click)="downloadFile()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

        </div>
      </div>
      <div style="" class="panel-body">

        <div  id="tablediv">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Transaction-Id</th>
                <th>Mobile</th>
                <th>Circle</th>
                <th>Unique</th>
                <th>Misscall Time</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Connect Time</th>
                <th>Hangup Time</th>
                <th>Call Duration(Mins)</th>
                <th>Call Stage</th>
                <th>Cashback</th>
                <th>Amount Transfer </th>
                <th>Status Code</th>
                <th>Status Message</th>
                <th>Answer Key</th>
                <th>Age Consent</th>
                <th>Coupon</th>
                <th>Circle</th>
                <th>Is Coupon Valid</th>
                <th>Pulses</th>
                <th>Msg</th>

                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.total==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of tableData.data;let ind = index">
                <tr>
                  <td [innerHTML]="data.id"></td>
                  <td [innerHTML]="data.mobile"></td>
                  <td [innerHTML]="data.carrierCircle"></td>
                  <td ><i *ngIf="data.isunique" style="color: green" class="fa fa-check"></i> <i style="color: red" *ngIf="!data.isunique" class="fa fa-close"></i></td>

                  <td [innerHTML]="data.misscallTime"></td>
                  <td [innerHTML]="data.questionStr"></td>
                  <td ><span *ngIf="data.answerKey == 4" ><audio controls style="width: 170px"><source src="{{RecordingServerUrl}}{{data.mobile}}-{{data.id}}.wav" type="audio/wav"></audio></span> <span *ngIf="data.answerKey != 4" [innerHTML]="data.answerStr"></span></td>
                  <td [innerHTML]="data.connectTime"></td>
                  <td [innerHTML]="data.disConnectTime"></td>
                  <td [innerHTML]="data.callduration"></td>
                  <td [innerHTML]="data.callStage"></td>
                  <td ><i *ngIf="data.amountTransfer" style="color: green" class="fa fa-check"></i> <i style="color: red" *ngIf="!data.amountTransfer" class="fa fa-close"></i></td>
                  <td [innerHTML]="data.amountTransferAmt.toFixed(2)"></td>
                  <td [innerHTML]="data.statusCode"></td>
                  <td [innerHTML]="data.statusMessage"></td>
                  <td [innerHTML]="data.answerKey"></td>
                  <td ><i *ngIf="data.ageConsentVal" style="color: green" class="fa fa-check"></i> <i style="color: red" *ngIf="!data.ageConsentVal" class="fa fa-close"></i></td>
                  <td [innerHTML]="data.coupon"></td>
                  <td [innerHTML]="data.codeCircle"></td>
                  <td ><i *ngIf="data.isCouponValid == 1" style="color: green" class="fa fa-check"></i> <i style="color: red" *ngIf="data.isCouponValid == 0" class="fa fa-close"></i></td>
                 <td [innerHTML]="data.pulses"></td>
                  <td [innerHTML]="data.msg"></td>


                  <td><i (click)="refreshTransaction(data.id,ind)" title="Click Here To Refresh Transaction Details" class="fa fa-refresh btn btn-add"></i></td>
                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>

        </div>

      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>








