<div  class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
      <div class="panel panel-bd lobidragdashboard">
        <div class="panel-heading">
          <div class="panel-title">
            <button (click)="addNew()"  class="btn btn-add">Add New Account<i class="fa fa-plus"></i></button>
          </div>
        </div>
        <div  class="panel-body">
          <div  style="display: none" id="addnewdiv">
            <form ngNativeValidate (submit)="createOrUpdate()">
  
              <div class="form-group col-lg-12">
                <label>Account Status</label>
                <mat-slide-toggle name="status" [(ngModel)]="accountMasterDao.active"></mat-slide-toggle>
              </div>
  
              <div class="form-group col-lg-12">
                <label>Agent Status</label>
                <mat-slide-toggle name="agentStatus" [(ngModel)]="accountMasterDao.agentStatus"></mat-slide-toggle>
              </div>
              <div class="form-group col-lg-12">
                <label>Account Alias  <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="accountMasterDao.accountName" name="accountName" required>
              </div>
              <div class="form-group col-lg-6">
                <label>Account Contact Number <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="accountMasterDao.accountPhone" name="accountPhone" required>
              </div>
              <div class="form-group col-lg-6">
                <label>Account  Email <sup class="required">*</sup></label>
                <input type="email" class="form-control" [(ngModel)]="accountMasterDao.accountEmail" name="accountEmail" required>
              </div>
  
              <div class="reset-button">
  
                <button  type="submit" class="btn btn-add">Save</button>
                <button type="button" (click)="cancelBtn()" class="btn btn-add">Cancel</button>
              </div>
            </form>
          </div>
          <div id="tablediv">
  
  
  
            <div class="table-responsive">
              <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
                <thead>
                <tr>
                  <th>Account Id</th>
                  <th>Account Alias</th>
                  <th>Contact Number</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let account of tableList;let ind =index">
                  <tr>
                    <td [innerHTML]="account.id"></td>
                    <td [innerHTML]="account.accountName"></td>
                    <td [innerHTML]="account.accountPhone"></td>
                    <td [innerHTML]="account.accountEmail"></td>
                    <td [innerHTML]="account.password"></td>
                    <td ><i *ngIf="account.active" style="color: green" class="fa fa-check"></i><i *ngIf="!account.active"  style="color: red" class="fa fa-remove"></i></td>
                    <td><i title="Click Here To Edit" (click)="editAccount(account,ind)"   class="btn btn-add fa fa-pencil"></i>
                      <i (click)="addRole(account.id)" title="click here to give role to account" class="btn btn-add fa fa-key"></i>
                    </td>
  
  
                  </tr>
  
  
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
