<div class="row">
  <div class="col-md-12">

    <form >

<!--<h3>Agent Master Form</h3>-->

      <div  class="form-group col-lg-12">
        <label>Name <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="agentName" [(ngModel)]="agentMasterDao.agentName">
      </div>
      <div  class="form-group col-lg-12">
        <label>Number <sup class="required">*</sup></label>
        <input type="text" class="form-control" placeholder="Enter Name"
               name="agentNumber" [(ngModel)]="agentMasterDao.agentNumber">
      </div>
<!--      <div  class="form-group col-lg-12">-->
<!--        <label>Account Id <sup class="required">*</sup></label>-->
<!--        <input type="text" class="form-control" placeholder="Enter Name"-->
<!--               name="accountId" [(ngModel)]="agentMasterDao.accountId">-->
<!--      </div>-->

      <div class="form-group col-lg-12">
        <button type="button" class="btn btn-add" (click)="submitMasterForm(agentMasterDao)">{{submitBtnTxt}}</button>
        <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
      </div>

    </form>
  </div>
</div>
