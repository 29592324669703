import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {Observable} from 'rxjs';
import {InstanceMasterDao} from '../../instance-master/InstanceMasterDAo';
import {CampaignDao} from './CampaignDao';
import {MessageResponseDao} from './MessageResponseDao';
import {CampaignEntryDao} from '../CampaignEntryDao';
import {CommonReportFilter} from '../../CommonReportFilter';
import {PaginationDao, WAPagination} from '../../../CommonObj/PaginationDao';
import {MessageDao} from '../../DaoMessageUn';
import {GroupByDao} from '../../../CampainService/GroupByDao';
const appPrefix = environment.appUrl + environment.whatsappunOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class CampaignServiceService {

  constructor(private http: HttpClient) {
  }


  get(offset:number,limit:number): Observable<PaginationDao<CampaignDao>> {
    const  BASE_URL=appPrefix+'v1/campaing/?offset='+offset+'&limit='+limit
    return this.http.get<PaginationDao<CampaignDao>>(BASE_URL);
  }

  startCampaing(data: CampaignEntryDao): Observable<Array<MessageResponseDao>>
  {
    return this.http.post<Array<MessageResponseDao>>(appPrefix + 'v1/campaing/', data);
  }

  getCampaignReport(data: CommonReportFilter): Observable<WAPagination<MessageDao>>
  {
    return this.http.post<WAPagination<MessageDao>>(appPrefix + 'api/messagecontroller/getbycampaingId', data);
  }


  getTransReport(data: CommonReportFilter): Observable<WAPagination<MessageDao>>
  {
    return this.http.post<WAPagination<MessageDao>>(appPrefix + 'api/messagecontroller/getreportbyaccountId', data);
  }



  getReportGroup(data: CommonReportFilter): Observable<Array<GroupByDao>>
  {
    return this.http.post<Array<GroupByDao>>(appPrefix + 'api/messagecontroller/report', data);
  }

  getCampaingWiseReport(id: string): Observable<any> {
    const BASE_URL = appPrefix + "v1/campaing/getStats?campaignId="+id;
    return this.http.get(BASE_URL);
  }
}
