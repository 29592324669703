import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactFormDao} from '../../../conversation/contact-form/ContactFormDao';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {ConversationService} from '../../../conversation/ConversationService/conversation.service';
import {ConversationwaunService} from '../conversationwaun.service';

@Component({
  selector: 'app-contact-form-component-wun',
  templateUrl: './contact-form-component-wun.component.html',
  styleUrls: ['./contact-form-component-wun.component.css']
})
export class ContactFormComponentWunComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: ContactFormDao, private dialogRef: MatDialogRef<ContactFormComponentWunComponent>, private alert: AlertMessageService, private conversation: ConversationwaunService ) { }

  ngOnInit(): void {
    console.log('Will get Contact data', this.data);
  }

  // tslint:disable-next-line:typedef
  save() {
    console.log(this.data.id);
    this.alert.confirmMessage('Save Contact to  ' + this.data.contactName + ' of  ' + this.data.id + '?', 'Are you sure ?').then(value => {
      this.conversation.saveContact(this.data.contactName, this.data.id).subscribe(response => {
        this.dialogRef.close(null);
      });
    });
  }

}
