import {Component, Inject, Input, OnInit} from '@angular/core';
import {Paramters} from '../ws-template/MessageDao';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AccountFilterDao} from '../account-master/account-filter/AccountFilterDao';
import {currency} from '../../environments/currency';

@Component({
  selector: 'app-message-type',
  templateUrl: './message-type.component.html',
  styleUrls: ['./message-type.component.css']
})
export class MessageTypeComponent implements OnInit {
  currency = currency;
  subType: string;
  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public components: any , private dialogRef: MatDialogRef<MessageTypeComponent>) { }

  ngOnInit(): void {

    console.log('dialog components:: ' + this.components.type);
    console.log('dialog components:: ' + this.components);

  }

  // tslint:disable-next-line:typedef
  submitData() {
    this.dialogRef.close(this.components);
  }

  // tslint:disable-next-line:typedef
  submitDataButton() {

    console.log('subType' + this.subType);
    this.dialogRef.close(this.subType);
  }
}
