<!--Author: Sujit Yada
Date: 31/July/2022
-->
<form style="width: 350px" ngNativeValidate (submit)="save()">

  <h1 mat-dialog-title style="text-align: center">Contact <i class="fa fa-user-plus"></i> </h1>

  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Contact Name  <sup class="required">*</sup></label>
      <div class="col-sm-12">

        <input type="text" name="contactName" class="form-control" [(ngModel)]="data.contactName" required>
      </div>



    </div>



  </mat-dialog-content>


  <mat-dialog-actions>
    <div class="reset-button">
      <button mat-dialog-close="null"   type="button" class="btn btn-add pull-right">Cancel </button>
      <button   type="submit"  class="btn btn-add pull-right">Save </button>

    </div>
  </mat-dialog-actions>
</form>

