<a class="inbox_item {{className}}">
  <div class="inbox-avatar">

    <img alt="" class="border-gray hidden-xs hidden-sm" src="assets/dist/img/avatar4.png" style="float: right">
    <div class="inbox-avatar-text">
      <div style="font-size: 18px">
        <small>

        <span>





      <ng-container *ngIf="conversation.messageType=='text' || conversation.messageType=='button'">
         <span [innerHTML]="conversation.msg||''"></span>
      </ng-container>


<br>
           <ng-container *ngIf="conversation.messageType=='image'">
                  <figure>
             <img class="commonmessagetypeimg"
                  src="assets/Images/imageicon.png">
                    <!--                    <span [innerHTML]="messageDao.document.filename"> </span>-->
                <figcaption [innerHTML]="conversation.fileMessage.caption"></figcaption>
               <button  title="View Image" (click)="downloadMedia(conversation.fileMessage.link)" style="width: 50%" class="fa fa-eye btn btn-add"></button>
                  </figure>


      </ng-container>
           <ng-container *ngIf="conversation.messageType=='video' ">
                  <figure>
             <img  class="commonmessagetypeimg"
                   src="assets/Images/videoicon.png">
                    <!--                    <span [innerHTML]="messageDao.document.filename"> </span>-->
                <figcaption [innerHTML]="conversation.fileMessage.caption"></figcaption>
               <button  title="View Image" (click)="downloadMedia(conversation.fileMessage.link)" style="width: 50%" class="fa fa-eye btn btn-add"></button>
                  </figure>


      </ng-container>


           <ng-container *ngIf="conversation.messageType=='document'&& checkDocumentMessage(conversation.fileMessage.link)">
                  <figure>
                                 <img  class="commonmessagetypeimg" [src]="this.documentIcon">
                    <figcaption [innerHTML]="conversation.fileMessage.caption"></figcaption>
               <button  title="View Image" (click)="downloadMedia(conversation.fileMessage.link)" style="width: 50%" class="fa fa-eye btn btn-add"></button>
                  </figure>


      </ng-container>



      </span>


        </small></div>

        <ng-container *ngIf="conversation.messageStatus=='Sent'">
          <i [title]="conversation.messageStatus" class="fa fa-check" style="color: green"></i>
        </ng-container>

        <ng-container *ngIf="conversation.messageStatus=='Failed'">
          <i [title]="conversation.messageStatus" class="fa fa-ban" style="color: red"></i>
        </ng-container>



      <small [innerHTML]="conversation.createDate"></small>
    </div>

  </div>
</a>






