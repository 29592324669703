import { Component, OnInit } from '@angular/core';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {HDFCampDao} from "../c2c-report/HDFCampDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {environment} from "../../../../environments/environment.prod";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {HDFCCallStatsObj} from "../HDFCCallStatsObj";

@Component({
  selector: 'app-hdfc-calls',
  templateUrl: './hdfc-calls.component.html',
  styleUrls: ['./hdfc-calls.component.css']
})
export class HdfcCallsComponent implements OnInit {

  tableData: PaginationDao<HDFCCallStatsObj> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public startdateStr:string="0";
  public enddateStr:string="0";

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<Array<HDFCCallStatsObj>>(environment.hdfcBaseUrl + 'hdfc/getcallstats?startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {
      this.tableData.data = value;
      this.tableData.total = value.length;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  filterData() {

  }


  clearFilter() {
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {

  }


  download() {
    this.http.get<Array<HDFCCallStatsObj>>(environment.hdfcBaseUrl + 'hdfc/getcallstats?startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {
      this.tableData.data = value;
      this.tableData.total = value.length;
      var data = value;
      var trArr = [];
      var tdArr =["Date","Uploads","Total Calls","Not Connected","Connected","Completed","Matched","MisMatched"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        // @ts-ignore
        tdArr.push(data[i].totalUploads);
        // @ts-ignore
        tdArr.push(data[i].totalCallsSch);
        // @ts-ignore
        tdArr.push(data[i].totalNotConnected);
        // @ts-ignore
        tdArr.push(data[i].totalConnected);
        // @ts-ignore
        tdArr.push(data[i].totalComplete);
        // @ts-ignore
        tdArr.push(data[i].totalMatched);
        // @ts-ignore
        tdArr.push(data[i].totalMismatched);
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  formatDate(intDate:number):string{
    let date = intDate+"";
    date = date.slice(0, 4) + "-" + date.slice(4);
    date = date.slice(0, 7) + "-" + date.slice(7);
    return date;
  }

}
