import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {SignutraDao} from '../signutra-dao';
import {MisscallDao} from '../misscall-dao';
import {formatDate} from '@angular/common';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {IncommingSmsDao} from '../../Oppo/incomming-sms-report/IncommingSmsDao';
import {environment} from '../../../../../environments/environment.prod';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {UploadDataComponent} from '../upload-data/upload-data.component';
import {FilterationComponent} from '../filteration/filteration.component';


@Component({
  selector: 'app-signutra',
  templateUrl: './signutra.component.html',
  styleUrls: ['./signutra.component.css']
})
export class SignutraComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  NewDiv = false;
  startdate: number = undefined;
  enddate: number = undefined;
  status: string;
  listsmsDao: Array<SignutraDao>;
  listmisscall: Array<MisscallDao>;
  // tslint:disable-next-line:typedef
  dtTrigger: any;
  dtOptions: any;

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams.startdate;
      this.enddate = queryParams.enddate;
      this.status = queryParams.status;

      console.log('status is:: ', this.status);
      this.loadData();
      this.loadDataMisscall();
    });
  }
  // tslint:disable-next-line:typedef
  loadData() {
    this.alert.showLoader();
    const format = 'yyyyMMdd';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined || this.enddate == undefined) {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
    }

    // tslint:disable-next-line:triple-equals
    if (this.status == undefined) {
      // tslint:disable-next-line:max-line-length
      this.http.get<Array<SignutraDao>>(environment.appUrl + environment.digimozoservice + 'longcode/signutra/get?startDate=' + this.startdate + '&endDate=' + this.enddate).subscribe(value => {
        this.listsmsDao = value;
        // const length = this.listsmsDao.length;
        console.log('smsdao', value);
        this.alert.hideLoader();
      });
    }
    else
    {

      // tslint:disable-next-line:max-line-length
      this.http.get<Array<SignutraDao>>(environment.appUrl + environment.digimozoservice + 'longcode/signutra/getbyStatus?startDate=' + this.startdate + '&endDate=' + this.enddate + '&status=' + this.status).subscribe(value => {
        this.listsmsDao = value;
        // const length = this.listsmsDao.length;
        console.log('findbysatus', value);
        this.alert.hideLoader();
      });

    }

  }


  // tslint:disable-next-line:typedef
  loadDataMisscall() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    // tslint:disable-next-line:triple-equals
    if (this.startdate == undefined || this.enddate == undefined) {
      // tslint:disable-next-line:radix
      this.startdate = parseInt(formattedDateFirst);
      // tslint:disable-next-line:radix
      this.enddate = parseInt(formattedDateLast);
    }

    // tslint:disable-next-line:triple-equals
    if (this.status == undefined) {
      // tslint:disable-next-line:max-line-length
      this.http.get<Array<MisscallDao>>(environment.appUrl + environment.digimozoservice + 'api/misscall/get?startDate=' + this.startdate + '&endDate=' + this.enddate).subscribe(value => {
        this.listmisscall = value;
        console.log('misscalldao', value);
        this.alert.hideLoader();
      });

    }
    else {

      // tslint:disable-next-line:max-line-length
      this.http.get<Array<MisscallDao>>(environment.appUrl + environment.digimozoservice + 'api/misscall/getbystatus?startDate=' + this.startdate + '&endDate=' + this.enddate + '&status=' + this.status).subscribe(value => {
        this.listmisscall = value;
        // const length = this.listsmsDao.length;
        console.log('findbysatus miss report', value);
        this.alert.hideLoader();
      });
    }
  }

  // tslint:disable-next-line:typedef
  filterData() {
    this.dialog.open(FilterationComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }
  // tslint:disable-next-line:typedef
  misscallReport() {
    console.log('misscall method call');
    this.NewDiv = true;
  }


  // tslint:disable-next-line:typedef
  downloadFile() {
    console.log('enddate :: ', this.enddate);
    console.log('startdate :: ', this.startdate);
    console.log('status :: ', this.status);
    // tslint:disable-next-line:triple-equals

    if (this.NewDiv === true)
    {
      this.http.get<Array<MisscallDao>>(environment.appUrl + environment.digimozoservice + 'api/misscall/get?startDate=' + this.startdate + '&endDate=' + this.enddate).subscribe(value => {
        this.listmisscall = value;
        const data = value;
        const trArr = [];
        let tdArr = [];
        tdArr.push('Number');
        tdArr.push('Status');
        tdArr.push('Date');
        trArr.push(tdArr);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < data.length; i++) {
          tdArr = [];
          tdArr.push(data[i].misscallNumber);
          tdArr.push(data[i].status);
          tdArr.push(data[i].date);
          trArr.push(tdArr);
        }
        const file = new FileService<any>();
        file.generateFile(trArr, 'MisscallReport.xlsx');
      });
    }

    else {
      // tslint:disable-next-line:max-line-length
      this.http.get<Array<SignutraDao>>(environment.appUrl + environment.digimozoservice + 'longcode/signutra/get?startDate=' + this.startdate + '&endDate=' + this.enddate).subscribe(value => {
        this.listsmsDao = value;
        const data = value;
        const trArr = [];
        let tdArr = [];
        tdArr.push('Retailer_Number');
        tdArr.push('Reatiler_Code');
        tdArr.push('Retailer_Name');
        tdArr.push('Sales_Officer_Name');
        tdArr.push('Location');
        tdArr.push('Types User');
        tdArr.push('Date');
        trArr.push(tdArr);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < data.length; i++) {
          tdArr = [];
          tdArr.push(data[i].retailerNumber);
          tdArr.push(data[i].code);
          tdArr.push(data[i].retailerName);
          tdArr.push(data[i].salesOfficerName);
          tdArr.push(data[i].location);
          tdArr.push(data[i].status);
          tdArr.push(data[i].date);
          trArr.push(tdArr);
        }

        const file = new FileService<any>();
        file.generateFile(trArr, 'SMSReport.xlsx');


      });
    }
  }

  // tslint:disable-next-line:typedef
  uploadFile() {

    this.dialog.open(UploadDataComponent).afterClosed();
  }
}
