import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AccountOnKarixDao} from "./AccountOnKarixDao";
import {environment} from "../../environments/environment.prod";
import {Observable} from "rxjs";
import {CloudTemplateMasterDao} from "../whatsapp-cloud-template-master/CloudTemplateMasterDao";
import {EmbeddedSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {PaginationDao} from "../CommonObj/PaginationDao";
const  appPrefix = environment.appUrl + environment.whatsappOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class AccountOnKarixService {

  constructor(private http:HttpClient) { }
  create(accountOnKarix:AccountOnKarixDao): Observable<AccountOnKarixDao>{
    return this.http.post<AccountOnKarixDao>(appPrefix + "api/karixaccount/",accountOnKarix);
  }

  getEmbeddedSignUpDaoByWabaId(whatsapp_id: string): Observable<EmbeddedSignUpDao> {
   return this.http.get<EmbeddedSignUpDao>(appPrefix + "api/embeddedsignup/findbywabaid?wabaid="+ whatsapp_id);
  }

  getAll(offset:number,limit:number):Observable<PaginationDao<AccountOnKarixDao>> {
  return this.http.get<PaginationDao<AccountOnKarixDao>>(appPrefix + "api/karixaccount/getall?offset="+offset+"&limit="+limit);
}
}
