import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testcmp',
  templateUrl: './testcmp.component.html',
  styleUrls: ['./testcmp.component.css']
})
export class TestcmpComponent implements OnInit {



  constructor() { }

  sayHello(name:string)
  {
    return new Promise((resolve, reject) => {
      if(name=="")
      {

      }else{

      }
    })
  }


  ngOnInit(): void {
  }

}
