import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment.prod";
import {Observable} from "rxjs";
import {CatalogMessageDao} from "./catalog-message-dao";

const appPrefix = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class CatalogueMessageServiceService {

  constructor(private http: HttpClient) {
  }

  save(data: any) {
    let BASE_URL = appPrefix + "wabuissness/v1/api/catalogues/save";
    return this.http.post(BASE_URL, data, {responseType: 'test' as 'json'})
  }

  get(): Observable<any> {
    let BASE_URL = appPrefix + "wabuissness/v1/api/catalogues/get?offset=0&limit=5";
    return this.http.get<Array<any>>(BASE_URL)
  }

  update(catalogueMessageDao: CatalogMessageDao): Observable<any> {
    let baseUrl = appPrefix + 'wabuissness/v1/api/catalogues/update';
    return this.http.patch(baseUrl, catalogueMessageDao, {responseType: 'test' as 'json'});
  }

  getById(id: string): Observable<CatalogMessageDao> {
    let baseUrl = appPrefix + 'wabuissness/v1/api/catalogues/getById?id=' + id;
    return this.http.get<CatalogMessageDao>(baseUrl)
  }

  deleteById(id: string): Observable<any> {
    let baseUrl = appPrefix + 'wabuissness/v1/api/catalogues/delete?id=' + id;
    return this.http.delete(baseUrl, {responseType: 'test' as 'json'});
  }
}
