<h1 mat-dialog-title style="text-align: center" >{{data.heading}} <i class="fa fa-table"></i></h1>

<mat-dialog-content class="mat-typography">


  <div class="table-responsive">
  <table *ngIf="rechargeDao!=null"  class="table table-striped row-border hover">
    <thead>
    <tr>

      <th>Transaction Id</th>
      <th>Amount</th>
      <th>Create By</th>
      <th>Create At</th>
      <th>Desc</th>
    </tr>
    </thead>
    <tbody>


      <tr>
        <td [innerHTML]="rechargeDao.transactionId"></td>
        <td [innerHTML]="rechargeDao.amount"></td>
        <td [innerHTML]="rechargeDao.createBy"></td>
        <td [innerHTML]="rechargeDao.createAt"></td>
        <td [innerHTML]="rechargeDao.descri"></td>
      </tr>


    </tbody>

  </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="pull-right">
  <button class="btn btn-add " mat-dialog-close="null">Close</button>
</mat-dialog-actions>
