import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FilterCommonComponent} from '../filter-common/filter-common.component';
import {UserService} from '../Services/User/user.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(private dialog:MatDialog,public user:UserService) {



    if(this.user.serviceName!=undefined)
    {

      this.user.takeMeToDashBoardOfService(this.user.serviceName);
    }

  }

  ngOnInit(): void {



  }
  loadFilter()
  {
    this.dialog.open(FilterCommonComponent);
  }

}
