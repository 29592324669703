import {Component, OnInit} from '@angular/core';
import {RegistrationOtpDao} from "../../Registration-Otp/registration-otp-dao";
import {Subject} from "rxjs";
import {RegistrationOtpService} from "../../Registration-Otp/registration-otp.service";
import {AlertMessageService} from "../../../../alert-message/alert-message.service";
import {FileService} from "../../../../Services/FileUtilsService/FileService";
import {PaginationDao} from "../../../../CommonObj/PaginationDao";

@Component({
    selector: 'app-registration-kkmaster',
    templateUrl: './registration-kkmaster.component.html',
    styleUrls: ['./registration-kkmaster.component.css']
})
export class RegistrationKKMasterComponent implements OnInit {


    tableData: PaginationDao<any> = {data: [], total: 0}
    tableList: Array<RegistrationOtpDao>;
    offset: number = 0;
    limit: number = 10;

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {'bDestroy': true, 'order': []};

    constructor(private service: RegistrationOtpService, private alert: AlertMessageService) {
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        this.service.getAllFromKKDao(this.offset,this.limit).subscribe(value => {
            console.log(value)
            this.tableData = value;
        })
    }

    downloadReport() {
        this.alert.showLoader();
        var trArr = [];
        var tdArr = [];
        tdArr.push("S.No");
        tdArr.push("Date");
        tdArr.push("Promoter Name");
        tdArr.push("Promoter Number");
        tdArr.push("Mandi Name");
        tdArr.push("WS Name");
        tdArr.push("others Ws Name");
        tdArr.push("Retailer Name");
        tdArr.push("Retailer Number");
        tdArr.push("Address");
        tdArr.push("Bill Amount ");
        tdArr.push("Note Book");
        tdArr.push("Gift Hand Over");
        tdArr.push("Gift");
        tdArr.push("Coupon");
        tdArr.push("Volume Of Gift");

        trArr.push(tdArr);
        for (var i = 0; i < this.tableData.total; i++) {
            tdArr = [];
            tdArr.push(this.tableData.data[i].id);
            tdArr.push(this.tableData.data[i].date);
            tdArr.push(this.tableData.data[i].promoterName);
            tdArr.push(this.tableData.data[i].promoterNumber);
            tdArr.push(this.tableData.data[i].mandiName);
            tdArr.push(this.tableData.data[i].wsName);
            tdArr.push(this.tableData.data[i].otherWsName);
            tdArr.push(this.tableData.data[i].retailerName);
            tdArr.push(this.tableData.data[i].retailerMobile);
            tdArr.push(this.tableData.data[i].address);
            tdArr.push(this.tableData.data[i].billAmount);
            tdArr.push(this.tableData.data[i].parchiBook);
            tdArr.push(this.tableData.data[i].giftHandOver == true ? 'Yes' : 'NO');
            tdArr.push(this.tableData.data[i].gift);
            tdArr.push(this.tableData.data[i].coupon.replace('undefined', '0'));
            tdArr.push(this.tableData.data[i].volumeOfGift);
            trArr.push(tdArr);
        }

        let file = new FileService();
        file.generateFile(trArr, "Report.xlsx")
        this.alert.hideLoader();

    }

    tableChange(json) {
        this.offset = json.pageIndex * json.pageSize;
        this.limit = json.page;
        this.getData();
    }
}
