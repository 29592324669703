import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpClientJsonpModule, HttpErrorResponse} from "@angular/common/http";
import {error} from "jquery";
import {EmbeddedSignUpDao} from "./ClientSignUpDao";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment.prod";
import {MatDialog} from "@angular/material/dialog";
import {ClientDetailsComponent} from "../client-details/client-details.component";
declare const FB: any;
@Injectable({
  providedIn: 'root'
})
export class ClientSignUpService implements OnDestroy{
  //   embaddedSignUpDao:EmbeddedSignUpDao = {
  //    tempToken:'',
  //    businessToken:'',
  //    wabaId:'',
  //    phoneNumberId:'',
  //    name:'',
  //    email:'',
  //    contactNumber:''
  // };

  constructor(private dialog: MatDialog, private http:HttpClient) {
    console.log("In Constructor");
    // Register the event listener
    window.addEventListener('message', this.sessionInfoListener);
  }
  sessionInfoListener = (event: MessageEvent) => {
    if (event.origin == null) {
      return;
    }

    // Make sure the data is coming from facebook.com
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }

    try {
      console.log("The Method is reached in try block");
      // Decode URL-encoded data
      const decodedData = decodeURIComponent(event.data);

      const data = JSON.parse(decodedData);
      console.log("The Method is reached in try block after data");

      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        console.log("The Method is reached in if signup block");
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
          let embaddedSignUpDao: EmbeddedSignUpDao = {
            wabaId: waba_id,
            phoneNumberId:phone_number_id,
            tempToken:'',
            businessToken:'',
            name:'',
            email:'',
            contactNumber:'',
            displayName:'',
            whatsappNumber:''
          }
          this.dialog.open(ClientDetailsComponent,{
            width:"100%",
            height:"90%",
            data:embaddedSignUpDao,
            disableClose: true,
          }).afterClosed().subscribe(value => {
            console.log("dialog is ",value);
          });
          console.log("The method hits after");
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === 'ERROR') {
          const { error_message } = data.data;
          console.error("Error ", error_message);
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          console.warn("Cancel at ", current_step);
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log('Non JSON Response', event.data);
      console.log("The Method is reached in try block",event);
    }
  };

  ngOnDestroy() {
    // Remove the event listener when the service is destroyed
    window.removeEventListener('message', this.sessionInfoListener);
  }

  launchWhatsAppSignup() {
    this.loadFacebookSdk().then(() => {
      console.log("FB: Method Reached First here ");
      // Launch Facebook login
      FB.login(function (response) {
        console.log("FB: Method Reached here ");
        if (response.authResponse) {
          console.log('accessToken after SignUp is ' ,response );
          const accessToken = response.authResponse.code;
          console.log('accessToken after SignUp is ' , accessToken);
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {
        // config_id: '1131908301456490', // configuration ID obtained in the previous step goes here(for 20511)
        config_id: '440381552356352', // configuration ID obtained in the previous step goes here( for DEMO)
        response_type: 'code',     // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          feature: "whatsapp_embedded_signup",
          version:2,
          sessionInfoVersion: 3,  //  Receive Session Logging Info
          setup: {
            // solution id for karix for 20511 account
            // solutionID: '849963489229289'
            //karix solution id for 20048 account
            solutionID: '895165572666015'
            // ... // Prefilled data can go here
          }
        }
      });
    });
  }

  loadFacebookSdk() {
    return this.http.jsonp('https://connect.facebook.net/en_US/sdk.js', 'callback')
      .toPromise().catch(this.handleError);
  }
  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
  }

  insertToEmbeddedSignUpDao(embeddedSignUpDao:EmbeddedSignUpDao):Observable<boolean>{
    return this.http.post<boolean>(environment.appUrl + environment.whatsappOfficialservice +"api/embeddedsignup/?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a",embeddedSignUpDao);
  }

}
