import { Injectable } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MessageDao, TemplateDao} from '../../ws-template/MessageDao';
import {Observable} from 'rxjs';
import {CampaignResponseDao} from '../../CampainService/CampaignResponseDao';
import {OBDCampaignGroupByDao} from './OBDCampaignGroupByDao';
import {OBDCampaignDao} from './OBDCampaignDao';
import {environment} from '../../../environments/environment.prod';
const appPrefix = environment.appUrl + environment.whatsappOfficialservice;
const appprefix2 = environment.appUrl;

@Injectable({
  providedIn: 'root'
})
export class ObdService {

  constructor(private http: HttpClient) { }

  // convertToMessage(to: string, type: string, templateDao: TemplateDao, campaingId: number): MessageDao
  // {
  //
  //   switch (type)
  //   {
  //     case 'template':
  //       for (let i = 0; i < templateDao.components.length; i++)
  //       {
  //         // tslint:disable-next-line:triple-equals
  //         if (templateDao.components[i].parameters.length == 0)
  //         {
  //           templateDao.components.splice(i, 1);
  //         }
  //
  //       }
  //       // tslint:disable-next-line:max-line-length
  // tslint:disable-next-line:max-line-length
  //       return {template: templateDao, to, type, campaingId, date: '', dlrTime: '', viewTime: '', messageId: 'SENT', messageStatus: 'SENT', chatSide: 'User', text: null, image: null, video: null, document: null, audio: null, isPanel: true};
  //
  //
  //   }
  //   return null;
  //
  // }
  //
  // sendSingleMessage(MessageDa: MessageDao): Observable<CampaignResponseDao>
  // {
  //   switch (MessageDa.type)
  //   {
  //     case 'text':
  //       MessageDa.image = null;
  //       break;
  //     case 'image':
  //       MessageDa.text = null;
  //       break;
  //
  //   }
  //   MessageDa.isPanel = true;
  //   return this.http.post<CampaignResponseDao>(appPrefix + 'message/', MessageDa);
  // }
  // sendMultipleMessage(MessageDa: Array<MessageDao>): Observable<CampaignResponseDao>
  // {
  //
  //   return this.http.post<CampaignResponseDao>(appPrefix + 'message/batchpanel', MessageDa);
  // }
  // // tslint:disable-next-line:no-shadowed-variable
  // createCampaign(OBDCampaignDao: OBDCampaignDao): Observable<OBDCampaignDao>
  // {
  //   return  this.http.post<OBDCampaignDao>(appPrefix + 'campaign/insert', OBDCampaignDao);
  // }
  // getCampaigns(): Observable<Array<OBDCampaignGroupByDao>>
  // {
  //   return this.http.get<Array<OBDCampaignGroupByDao>>(appPrefix + 'campaign/');
  // }
  //
  // getCampaingWiseReport(campaidId: number): Observable<Array<OBDCampaignGroupByDao>>
  // {
  //   return this.http.get<Array<OBDCampaignGroupByDao>>(appPrefix + 'message/getsts?id=' + campaidId);
  // }
  getTransCampaingWise(campaigndate: string, campaignName: string): Observable<Array<OBDCampaignDao>>
  {

    // tslint:disable-next-line:max-line-length
    return this.http.get<Array<OBDCampaignDao>>(appprefix2 + 'digimozo/v1/videoCon/getGraph?champaginName=' + campaignName + '&dateFilter=' + campaigndate, {});

  }

  getCampaignOBDData(): Observable<Array<OBDCampaignDao>>
  {


    return this.http.get<Array<OBDCampaignDao>>(appprefix2 + 'digimozo/v1/videoCon/get', {});

  }
  getCampaignOBDGroupByReport(): Observable<Array<OBDCampaignGroupByDao>>
  {

    return this.http.get<Array<OBDCampaignGroupByDao>>(appprefix2 + 'digimozo/v1/videoCon/getReport', {});

  }
  getCampaignOBDGroupByReportDatewise(startdate: number, enddate: number): Observable<Array<OBDCampaignGroupByDao>>
  {

    return this.http.get<Array<OBDCampaignGroupByDao>>(appprefix2 + 'digimozo/v1/videoCon/getdatewisereport?startdate=' + startdate + '&enddate=' + enddate, {});

  }

}
