import {Component, Inject, Input, OnInit} from '@angular/core';
import {ChartDataDao} from '../chart-and-tables/ChartDataDao';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Paramters} from '../ws-template/MessageDao';
import {GraphCommonDao} from './GraphCommonDao';

@Component({
  selector: 'app-graph-common',
  templateUrl: './graph-common.component.html',
  styleUrls: ['./graph-common.component.css']
})
export class GraphCommonComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public graphCommonDao: GraphCommonDao) { }

  ngOnInit(): void {
  }

}
