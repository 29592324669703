<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Reciepts</th>
              <th>Create At</th>
              <th>Sent Time</th>
              <th>Template Id/ Catalogue Id</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let user of tableList;let ind =index">
              <tr>
                <td [innerHTML]="user.campaingName"></td>
                <td [innerHTML]="user.totalReciepts"></td>
                <td [innerHTML]="user.createAt"></td>
                <td>{{ (user.scheduledTime | date : 'dd-MM-yyyy HH:mm:ss') }}</td>
                <!--                <td>{{user.scheduledTime}}</td>-->
                <td [innerHTML]="user.template"></td>
                <td><i title="Click Here To Edit" (click)="drawReport(user,ind)"
                       class="btn btn-add fa fa-bar-chart"></i>
                  <i title="Download Report" (click)="downloadReport(user,ind)"
                     class="btn btn-add fa fa-download"></i>
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>







