import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CampaingService} from '../../CampainService/campaing.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ClientSidePagination} from '../../CommonObj/ClientSidePagination';
import {ConversationDao} from '../../conversation/ConversationDao';
import {MessageDao, MessageType} from '../DaoMessageUn';
import {Subject, Subscription} from 'rxjs';
import {ContactFormDao} from '../../conversation/contact-form/ContactFormDao';
import {QuickReplyMessageComponent} from '../../quick-reply-message/quick-reply-message.component';
import {ConversationwaunService} from './conversationwaun.service';
import {ConversationWaunSubscriptionService} from './conversation-waun-subscription.service';
import {ContactFormComponentWunComponent} from './contact-form-component-wun/contact-form-component-wun.component';

@Component({
  selector: 'app-conversationwaun',
  templateUrl: './conversationwaun.component.html',
  styleUrls: ['./conversationwaun.component.css']
})
export class ConversationwaunComponent implements OnInit,AfterViewInit {
  @ViewChild('tableWrapper') tableWrapperRef: ElementRef<HTMLElement>;

// tslint:disable-next-line:max-line-length
  constructor(private conversationService: ConversationwaunService, private campservice: CampaingService, private conversationSub: ConversationWaunSubscriptionService, private alertService: AlertMessageService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
    this.searchStr = '0';
    this.loadData(0, 10, this.searchStr);
  }

  ngAfterViewInit(): void {
    this.subscription = this.conversationSub.getObj().subscribe(value => {
      console.log("value",value)
      if (value != null)
      {
        if (value.isNew) {
          console.log("this offset  and search", this.offset,this.searchStr,this.limit)

          if(this.searchStr!='0' || this.offset==0) {
            this.conversationService.getConversation(this.searchStr, 0, this.limit).subscribe(data => {
              console.log('Will Load Conversation From Server:: ', data);
              this.conversationList = {data: data.data, end: this.limit, start: 0, total: data.recordsTotal};
              if (this.conversationDao != null) {
                this.updateReadStatusLocallyAndGlobally(this.conversationDao.id);
                // this.updateReadStatusLocallyAndGlobally(this.conversationDao.id);
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < value.data.length; i++) {
                  // tslint:disable-next-line:triple-equals
                  if (value.data[i].id == this.conversationDao.id) {
                    console.log('Will Read New Chat after ', value.lastMessageTime);
                    this.conversationService.getMessageAfterTime(this.conversationDao.id, value.lastMessageTime).subscribe(messages => {

                      // tslint:disable-next-line:prefer-for-of
                      for (let m = 0; m < messages.length; m++) {
                        this.messageList.push(messages[m]);

                      }
                      // tslint:disable-next-line:only-arrow-functions typedef
                      setTimeout(function () {
                        $('#chatconversationdiv').stop().animate({
                          scrollTop: $('#chatconversationdiv')[0].scrollHeight
                        }, 800);
                      }, 1000);
                    });
                  }
                }
              }

            });
          }
        }
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      console.log('Query PArams are ', queryParams);
      const id = queryParams.id;
      // tslint:disable-next-line:triple-equals
      if (id != undefined)
      {
        let savedSts = false;
        // tslint:disable-next-line:triple-equals
        if (queryParams.contactName == 'true')
        {
          savedSts = true;
        }
        this.conversationDao = {
          id,
          lastMessage: queryParams.lastMessage,
          lastMessageTime: queryParams.lastMessageTime,
          lastMessageType:  queryParams.lastMessageType,
          totalMessage:  queryParams.totalMessage,
          unread:  queryParams.unread,
          unreadMessage: queryParams.unreadMessage,
          contactName: queryParams.contactName,
          saved: savedSts,
        };

        this.updateReadStatusLocallyAndGlobally(id);
        console.log("load chats for ",this.offset,this.limit)
        this.loadChats(this.offset, this.limit, id, true);
      }
      else{
        this.conversationList = null;
      }
    });
  }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};

  textMessage = '';
  conversationList: ClientSidePagination<ConversationDao> =  null;
  conversationDao: ConversationDao = null;
  messageList: Array<MessageDao> = [];
  offset = 0;
  limit = 10;
  searchStr = '';
  loadMoreEnable = false;
  private subscription: Subscription;
  messageDao: Array<MessageDao> = [];

  // tslint:disable-next-line:typedef use-lifecycle-interface
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dtTrigger.unsubscribe();

  }

  // tslint:disable-next-line:typedef
  loadData(offset, limit, search)

  {

    // tslint:disable-next-line:triple-equals
    if (search.length == 0)
    {
      search = '0';
      offset=this.offset
    }
    // this.alertService.showLoader();
    this.conversationService.getConversation(search, offset, limit).subscribe(value => {
      this.conversationList = {data: value.data, end: limit, start: offset, total: value.recordsTotal};
      this.dtTrigger.next();

      // this.alertService.hideLoader();
    });

  }

  // tslint:disable-next-line:typedef
  loadChats(offset, limit, id, isNew, scroll= true) {
    this.offset = offset;
    this.limit = limit;
    if (isNew)
    {
      this.messageList = [];
      offset = 0;
      limit = 10;
    }

    this.loadMoreEnable = this.conversationDao.totalMessage >= ((offset + 1) * limit);
    // this.loadMoreEnable = true;
    // this.alertService.showLoader();
    this.conversationService.getMessages(offset, limit, id).subscribe(value => {

      const len = value.length;
      for (let i = 0; i < len; i++)
      {
        this.messageList.unshift(value[i]);
      }
      if (scroll) {
        // tslint:disable-next-line:only-arrow-functions typedef
        setTimeout(function() {
          $('#chatconversationdiv').stop().animate({
            scrollTop: $('#chatconversationdiv')[0].scrollHeight
          }, 800);
        }, 1000);
      }

      // this.alertService.hideLoader();
    });
  }

  ngOnInit(): void {


  }
  // tslint:disable-next-line:typedef
  updateReadStatusLocallyAndGlobally(id: string)
  {
      console.log('updateReadStatusLocallyAndGlobally', id);
      console.log('updateReadStatusLocallyAndGlobally', this.conversationList);

      this.conversationService.updateReadStatus(id).subscribe(value => {

       // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.conversationList.data.length; i++)
      {
        // tslint:disable-next-line:triple-equals
        if (this.conversationList.data[i].id == id)
        {
          this.conversationList.data[i].unreadMessage = 0;
          this.conversationList.data[i].unread = false;
        }
      }
    });
  }

  // tslint:disable-next-line:typedef
  takeMeToNumber(json: ConversationDao) {
    this.router.navigate([], {queryParams: json});
  }
  // tslint:disable-next-line:typedef
  saveContact() {
    const contactForm: ContactFormDao = {contactName: this.conversationDao.contactName, id: this.conversationDao.id};
    this.dialog.open(ContactFormComponentWunComponent, {data: contactForm}).afterClosed().subscribe(value => {
      this.conversationDao.saved = true;
      this.conversationDao.contactName = contactForm.contactName;
      this.loadData(this.offset,this.limit, this.searchStr);
    });
  }

  // tslint:disable-next-line:typedef
  editContact() {
    const contactForm: ContactFormDao = {contactName: this.conversationDao.contactName, id: this.conversationDao.id};
    this.dialog.open(ContactFormComponentWunComponent, {data: contactForm}).afterClosed().subscribe(value => {
      this.conversationDao.saved = true;
      this.conversationDao.contactName = contactForm.contactName;
      this.loadData(this.offset, this.limit, this.searchStr);
    });
  }

  // tslint:disable-next-line:typedef
  replyMessage() {
    this.dialog.open(QuickReplyMessageComponent, {data: this.conversationDao}).afterClosed().subscribe(value => {
      console.log('Reponse After Closed', value);

      // tslint:disable-next-line:triple-equals
      if (value.messageId != undefined)
      {
        this.messageList.push(value);
        $('#chatconversationdiv').stop().animate({
          scrollTop: $('#chatconversationdiv')[0].scrollHeight
        }, 800);
      }

    });
  }
  // tslint:disable-next-line:typedef
  tableChange(json, data: ClientSidePagination<any>) {
       this.offset = json.pageIndex;
       this.limit = json.pageSize;

    data.start = json.pageIndex;
    data.end = json.pageSize;
    console.log("table",this.searchStr)
    if(this.searchStr==''){
      this.searchStr='0';
    }
   console.log("limt and offset in tabel change",this.limit,this.offset)

    this.loadData(data.start, data.end, this.searchStr);

  }
  // tslint:disable-next-line:typedef
  sendTextMessage() {

    // tslint:disable-next-line:triple-equals
   if (this.textMessage.length == 0)
   {
     return;
  }
   const messageDao: Array<MessageDao> = [];
   messageDao.push( {
     buttonMessage: undefined,
     preferredIncId:0,
     contactMessage: undefined,
     dst: this.conversationDao.id,
     fileMessage: undefined,
     id: '',
     incId: 0,
     listMessage: undefined,
     locationMessage: undefined,
     messageStatus: undefined,
     messageType: MessageType.TEXT,
     promotional: undefined,
     textMessage: {content: this.textMessage},
     templateButtonMessage: undefined,
     chatSide: undefined,
     iSchedule:0,
     scheduleTime:null,
     campaignSize:null
    });
   const campaignNames = 'Conversation';
   console.log('MessageDao Object' , messageDao);
   // this.alertService.confirmMessage('Send Message', 'Are you sure?').then(value => {
   this.conversationService.startCampaing({campaignName: campaignNames, messageDaos: messageDao, messageSendingSpeed:0,customMessageInterval:false}).subscribe(value => {
       // this.messageList.push( messageDao);
       this.textMessage = '';
       $('#chatconversationdiv').stop().animate({
         scrollTop: $('#chatconversationdiv')[0].scrollHeight
       }, 800);

       // this.alertService.showSuccesMessage('Successfully Send Message!!');
     });
     // , error => {
     //   this.alertService.hideLoader();
     //   this.alertService.showErrorMessage(error.error.message);
     // });
   // });
    //

    // this.campservice.sendMultipleMessage([messageDao]).subscribe(value => {
    //   message.messageId = value.ids[0];
    //   this.messageList.push(message);
    //   this.textMessage = '';
    //   $('#chatconversationdiv').stop().animate({
    //     scrollTop: $('#chatconversationdiv')[0].scrollHeight
    //   }, 800);
    // });

  }

// tslint:disable-next-line:typedef
clearData()
{
  this.textMessage = '';
}


  scrollToLast() {
    const tableWrapperElement: HTMLElement = this.tableWrapperRef.nativeElement;
    const scrollButtonContainerElement: HTMLElement = document.querySelector('.scroll-button-container');
    const isAtBottom: boolean = tableWrapperElement.scrollHeight - tableWrapperElement.scrollTop === tableWrapperElement.clientHeight;

    if (isAtBottom) {
      // Scroll to top
      tableWrapperElement.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Scroll to last
      scrollButtonContainerElement.scrollIntoView({ behavior: 'smooth' });
    }
  }


}
