<div class="row">
  <div class="col-sm-9 col-md-9 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
      </div>
      <div style="" class="panel-body">
        <div>
          <form ngNativeValidate>

            <div class="form-group col-lg-12">
              <label>Campaign Name <sup class="required">*</sup></label>
              <input placeholder="Maxmium 25 Characters" title="Name Must not be more Then 25 Words" type="text" [maxLength]="25" class="form-control" [(ngModel)]="campaingnDao.campaingName" name="campaingName" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Catalogue Message</label>
              <mat-slide-toggle [(ngModel)]="messageTypestatus" name="messageType"></mat-slide-toggle>
            </div>
            <div class="form-group col-lg-12" *ngIf="messageTypestatus==false">
              <label>Template </label>
              <select (change)="changeParam()" type="text" class="form-control" [(ngModel)]="campaingnDao.template"
                      name="template" >

                <option value="">---Select Template---</option>
                <ng-container *ngFor="let template of tmpArr">

                  <option [value]="template.id" [innerHTML]="template.name"></option>
                </ng-container>
              </select>
            </div>
            <div class="form-group col-lg-12" *ngIf="messageTypestatus==true">
              <label>Catalogue </label>
              <select type="text"   class="form-control"  name="catalogue"  [(ngModel)]="campaingnDao.template" (click)="getCatalogDataById($event.target.value)">
                <option value="">---Select Catalogue---</option>
                <ng-container *ngFor="let data of tableData.data">
                  <option   [value]="data.id" [innerHTML]="data.catalogName"></option>
                </ng-container>
              </select>
            </div>
            <div class="form-group col-lg-12">
              <label>Country <sup class="required">*</sup></label>
              <select type="text" class="form-control" [(ngModel)]="campaingnDao.country" name="country" required>
                <ng-container *ngFor="let countryDao of countryWithPricesList">
                  <option [value]="countryDao.countryCode" [innerHTML]="countryDao.countryCode"></option>
                </ng-container>
              </select>
            </div>


            <div *ngIf="templateDao!=null" class="form-group col-lg-12">
              <ng-container *ngFor="let componenent of templateDao.components">
                <h5 *ngIf="componenent.parameters.length>0" [innerHTML]="componenent.type.toUpperCase()"></h5>


                <ng-container *ngFor="let paramters of componenent.parameters">
                  <app-template-messagetype [params]="paramters"></app-template-messagetype>
                </ng-container>


              </ng-container>

            </div>


            <!--====================================Dynamic Message=================================-->
            <div class="form-group col-lg-12">
              <label>Dynamic Message </label>
              <mat-slide-toggle [(ngModel)]="active" name="active"></mat-slide-toggle>
            </div>

            <div class="form-group col-lg-12">
              <label>Remove Duplicate No.</label>
              <mat-slide-toggle [(ngModel)]="removeDuplicatioNo" name="duplicateNo"></mat-slide-toggle>
            </div>

            <div *ngIf="active==true">
              <label>File <i title="Click Here To Download SampleFile " class="fa fa-question-circle"
                             (click)="downloadSampleFile()"></i> <small style="color: red">&nbsp;Make sure contact
                number includes country code e.g. 919988776655</small></label>
              <div class="form-group col-lg-12">
                <button type="button" (click)="dynamicSMS()" class="btn btn-add">Upload File <i
                  class="fa fa-upload"></i></button>
              </div>
            </div>

            <!--            ===============================================End==========================-->


            <div class="form-group col-lg-12" *ngIf="!active">
              <label>Reciepts <sup class="required">*</sup></label>
              <textarea style="min-height: 100px" class="form-control" [(ngModel)]="recieptsTo" name="recieptsTo"
                   (input)="templateNameValidate()" required>
              </textarea>
            </div>


            <div *ngIf="!active">
              <label>File <i title="Click Here To Download SampleFile " class="fa fa-question-circle"
                             (click)="downloadSampleFile()"></i> <small style="color: red">&nbsp;Make sure contact
                number includes country code e.g. 919988776655</small></label>
              <div class="form-group col-lg-12">
                <button type="button" (click)="uploadFile()" class="btn btn-add">Upload File <i
                  class="fa fa-upload"></i></button>
              </div>
            </div>

            <div class="form-group col-lg-12">
              <button id="preview" [disabled]="previewStatus" [title]="previewTitle" class="btn btn-add" (click)="preview()">Preview</button>
              <button type="submit" [disabled]="showSubmitButton" class="btn btn-add" (click)="createCampiagn()">Start Campaign</button>
              <button title="Click Here To Schedule Campaign" id="btnschedule" (click)="campaignSchecule()" class="btn btn-add">Schedule Campaign</button>
            </div>
            <div [hidden]="showSpinner == true" class="centered-spinner"><br>
              <mat-spinner [hidden]="showSpinner == true" diameter="80" color="primary"></mat-spinner>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>

  <!--------------------------------------------PreviewDiv-------------------------------------------------->
  <div class="col-sm-3 col-md-3 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div style="margin: 2px" class="panel-heading">
        <div style="margin: 2px" class="panel-title">
        </div>
      </div>
      <div style="background: #1c5b7a; color: whitesmoke; " class="panel-body">
        <div style="margin: 0px; padding: 0px">
          <div style="margin-bottom: 0px; padding: 0px " class="form-group col-lg-12">
            <label>Preview</label>
          </div>
          <div *ngIf="templateDao != null" style="margin-bottom: 0px; padding: 0px" class="form-group col-lg-12">
            <ng-container *ngFor="let component of templateDao.components">
              <div *ngIf="component.type == 'header'">
                <ng-container *ngFor="let parameter of component.parameters">
                  <img *ngIf="parameter.type == 'image'" style="width: 100%; height: 180px"
                       [src]="previewTemplateBodyHeader">
                  <img *ngIf="parameter.type == 'document'" style="width: 100%; height: 180px"
                       [src]="previewTemplateBodyHeader">
                  <video *ngIf="parameter.type == 'video'" preload="auto" style=" width: 100%; height: 180px"
                         [src]="previewTemplateBodyHeader" controls></video>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div style="margin-top: 0px; margin-bottom: 0px; padding: 0px" class="form-group col-lg-12">
            <textarea id="previewTextrea" disabled type="text"
                      style=" height: 18vh; margin-bottom: 0px; font-weight: bold; background: white"
                      class="form-control" [innerHTML]="this.previewTemplateBodyText" name="previewTemplateBodyText"
                      required></textarea>
          </div>
          <div *ngIf="templateDao != null" style="border-radius: 10%; margin: 0px; margin-left: 0px; padding: 0px"
               class="form-group col-lg-12">
            <ng-container *ngFor="let component of templateDao.components">
              <div style=" margin-left: 0px; border-radius: 10%; padding: 0px" *ngIf="component.type == 'button'">
                <ng-container *ngFor="let parameter of component.parameters; let i = index">
                  <button *ngIf="component.sub_type == 'url'"
                          style="padding: 0px; margin-left: 0px; background: white; border-radius: 10%; width: 100%; height: 25px">
                    <i style="color: #1b5b7a" class="fa-solid fa-arrow-up-right-from-square"></i><span
                    style="color: #104e75"> &nbsp; {{this.previewButtonText[i]}}</span></button>
                  <button *ngIf="component.sub_type == 'call'"
                          style="padding: 0px; margin-left: 0px; background: white;  border-radius: 10%; width: 100%; height: 25px">
                    <i style="color: #1b5b7a" class="fa fa-phone" aria-hidden="true"></i><span style="color: #104e75"> &nbsp; {{this.previewButtonText[i]}}</span>
                  </button>
                  <button *ngIf="component.sub_type == 'action'"
                          style="padding: 0px; margin-left: 0px; background: white; border-radius: 10%; width: 50%; height: 25px">
                    <span style="color: #104e75">{{this.previewButtonText[i]}}</span></button>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







