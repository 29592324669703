import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../Services/User/user.service';
import { AccountMasterService } from '../account-master/AccountMasterService/account-master.service';
import { CampaignReportListModel } from './campaign-report-resseller.model';

@Component({
  selector: 'app-campaign-report-reseller',
  templateUrl: './campaign-report-reseller.component.html',
  styleUrls: ['./campaign-report-reseller.component.css']
})
export class CampaignReportResellerComponent implements OnInit {

  
  tableList: Array<CampaignReportListModel> = [];
  clientsDdl = [];
  
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};
  

  selectedAccountId:number = 0;

  constructor(public user: UserService,private accountService: AccountMasterService) { }

  ngOnInit(): void {
    this.getAccontList();
    this.getCampaignReport();
  }


  getCampaignReport(){
    this.accountService.getCampaignReportById(this.selectedAccountId).subscribe((data)=>{
      this.tableList = data;
    });
  }

  getAccontList(){
    this.accountService.getClientByParentId(this.user.user.accountId).subscribe((data)=>{
      this.clientsDdl = data;
    });
  }


  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
