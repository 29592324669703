<h1 mat-dialog-title style="text-align: center">Upload <i class="fa fa-filter"></i> </h1>



<form ngNativeValidate (submit)="upload()">

  <mat-dialog-content class="mat-typography">

    <div class="form-group col-lg-12">
      <label>Enter File  <sup class="required">*</sup></label>
      <input type="file" class="form-control" (change)="incomingfile($event)" name="samplefileupload" required>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button   class="btn btn-success"  type="submit">Upload <i class="fa fa-filter"></i></button>
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>

  </mat-dialog-actions>
</form>
