import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../Services/User/user.service';
import { AccountMasterService } from '../account-master/AccountMasterService/account-master.service';
import { ScheduledReportListModel } from './scheduled-report-reseller.model';
import { CampaignReportListModel } from '../campaign-report-reseller/campaign-report-resseller.model';
@Component({
  selector: 'app-scheduled-report-reseller',
  templateUrl: './scheduled-report-reseller.component.html',
  styleUrls: ['./scheduled-report-reseller.component.css']
})
export class ScheduledReportResellerComponent implements OnInit {

  tableList: Array<CampaignReportListModel> = [];
  clientsDdl = [];  
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};
  


  selectedAccountId:number = 0;

  constructor(public user: UserService,private accountService: AccountMasterService) { }

  ngOnInit(): void {
    this.getAccontList()
    this.getScheduledReport();
  }

  getAccontList(){
    this.accountService.getClientByParentId(this.user.user.accountId).subscribe((data)=>{
      this.clientsDdl = data;
    });
  }


  getScheduledReport(){
    this.accountService.getScheduledReportById(this.selectedAccountId).subscribe((data)=>{
      console.log("scheduled Report Data=>",data);
      this.tableList = data;
  // getScheduledReport(){
  //   this.accountService.getScheduledReportById(this.selectedAccountId).subscribe((data)=>{
  //     console.log("scheduled Report Data=>",data);
  //     this.tableList = data;
    });
  }

  // getScheduledReport(){
  //   this.accountService.getScheduledReportById(this.selectedAccountId).subscribe((data:any)=>{
  //     console.log("scheduled Report Data=>",data);
  //     data.forEach(element =>{
  //       if(element.iScheduled == 1){
  //         this.tableList.push(element);
  //       }
  //     });
  //   });
  // }


  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
