import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UrlConfigDao} from './url-config-dao';
import {environment} from '../../../../environments/environment.prod';
 const appPrefix= environment.appUrl +environment.gsmgatewayservice;
@Injectable({
  providedIn: 'root'
})
export class UrlConfigServiceService {

  constructor(private http:HttpClient) { }

  get():Observable<any>
  {
    return this.http.get<Array<any>>(appPrefix + 'v2/api/');
    //return this.http.get<Array<any>>(  'http://localhost:8080/v2/api/');
  }

  insert(data:UrlConfigDao):Observable<any>{
    return this.http.post(appPrefix + 'v2/api/',data)
   // return this.http.post(  'http://localhost:8080/v2/api/',data)
  }

  update(data: UrlConfigDao) {

    return this.http.patch(appPrefix + 'v2/api/update',data)

    //return this.http.patch('http://localhost:8080/v2/api/update',data)
  }
}
