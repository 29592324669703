



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  File<i
            class="fa fa-plus"></i></button>
          <button *ngIf="this.deleteList.length !=0" (click)="selectedDelete()"  class="btn btn-add">Delete Files<i class="fa fa-plus"></i></button>
          <label *ngIf="this.deleteList.length !=0" style="color: red">*Maximum 150 Files in one time*</label>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">



        <div>


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th><mat-checkbox style="stroke: #fff !important;" [(ngModel)]="this.checkAllList" (click)="selectAllRows()"></mat-checkbox>
              Select</th>
              <th>File Name</th>
              <th>Create Date</th>
              <th>Size</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.length==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList;let ind =index">

              <tr>
                <td><mat-checkbox style="stroke: #fff !important;" [(ngModel)]="this.selectedList[ind]" (click)="selectRows(tableList[ind],ind)"></mat-checkbox></td>
                <td [innerHTML]="data.filename"></td>
                <td [innerHTML]="data.time"></td>
                <td [innerHTML]="convertToMb(data.fileSize).toFixed(2)+' MB' "></td>
                <td>
                  <i title="Click Here To Delete" (click)="delete(data)" class="btn btn-add fa fa-trash"></i>
                  <i title="Click Here To Copy Link" (click)="copy(data)" class="btn btn-add fa fa-copy"></i>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







