import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UrlMasterDao} from '../../module-master/UrlMasterService/UrlMasterDao';
import {environment} from '../../../environments/environment.prod';
import {PermissionEntryDao, PermissionEntryDaoReporting} from '../PermissionEntryDao';
import {MenuMasterDao} from '../MenuMasterDao';

@Injectable({
  providedIn: 'root'
})
export class PermissionentryService {

  constructor(private http:HttpClient) { }
  delete(moduleId:number,roleId:number,actionId:number):Observable<Array<UrlMasterDao>>
  {
    return this.http.delete<Array<UrlMasterDao>>(environment.appUrl+environment.authMicroservice+"permissionentry/delete/"+moduleId+"/"+roleId+"/"+actionId);
  }
  save(data:PermissionEntryDaoReporting):Observable<PermissionEntryDaoReporting>
  {
    return this.http.post<PermissionEntryDaoReporting>(environment.appUrl+environment.authMicroservice+"permissionentry/save",data);
  }
  getMenuByApplication(service:number):Observable<{[name: string]: Array<MenuMasterDao>}>
  {
  return   this.http.get<{[name: string]: Array<MenuMasterDao>}>(environment.appUrl+environment.authMicroservice+"permissionentry/getmenuuser/"+service)
  }

}
