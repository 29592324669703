import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChartOptions} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ChartDataDao} from './ChartDataDao';

@Component({
  selector: 'app-chart-and-tables',
  templateUrl: './chart-and-tables.component.html',
  styleUrls: ['./chart-and-tables.component.css']
})
export class ChartAndTablesComponent implements OnInit {
  @Input('chartData') chartData: ChartDataDao;

  @Output() changeEvent: EventEmitter<string> = new EventEmitter();
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {xAxes: [{}], yAxes: [{}]},
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
        title: () => null,
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };


  public barChartType: any = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];


  constructor() {
  }

  ngOnInit(): void {
  }

  // events
  public campaignNameChart:any;


  chageGraphType(bar: string) {

    if (bar == 'table') {
      this.changeEvent.emit('TABLEVIEW');
    } else {
      this.barChartType = bar;
    }


  }



}
