<div style="min-width: 400px">

<h1 mat-dialog-title style="text-align: center">Subscription <i class="fa fa-shopping-cart"></i> </h1>

<mat-dialog-content class="mat-typography">


  <div  *ngIf="addnewform &&subscriptionDao!=null">
    <form ngNativeValidate (submit)="addOrUpdate()">

      <div class="form-group col-lg-12">
        <label>Testing Account </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.test" name="test"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Enable Agent Campaign </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.enableAgentCampaign" name="test"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Enable Agent Support </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.enableAgentSupport" name="test"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Store Media To Drive </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.mediaStoretoDriveStatus" name="mediaStoretoDriveStatus"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Enable Priority Queue </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.enablePriorityQueue" name="enablePriorityQueue"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Visible Template content </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.showMessageContent" name="showMessageContent"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12">
        <label>Enable DLR Report Send on Email </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.dlrReportOnEmail" name="dlrReportOnEmail"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.dlrReportOnEmail== true">
        <label>Enter Emails</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.clientEmail" name="clientEmail" />
      </div>
      <div>
        <div class="form-group col-lg-12">
          <label> Gateway </label>
          <mat-radio-group name="gateway" [(ngModel)]="subscriptionDao.gateway">
            <mat-radio-button value="AMEYO">AMEYO</mat-radio-button>
            <mat-radio-button value="KARIX">KARIX</mat-radio-button>
            <mat-radio-button value="CLOUDAPI">CLOUDAPI</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.gateway=='CLOUDAPI'">
        <label>Cloud API Token</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.cloudAPIToken" name="cloudAPIToken" />
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.gateway=='CLOUDAPI'">
        <label>Cloud API PhoneNumber ID</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.cloudAPIPhoneNumberID" name="cloudAPIPhoneNumberID" />
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.gateway=='CLOUDAPI' || subscriptionDao.gateway=='KARIX'">
        <label>Whatsapp ID</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.cloudAPIWhatsappID" name="cloudAPIWhatsappID" />
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.gateway=='KARIX'">
        <label>Karix Token</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.karixToken" name="karixToken" required/>
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.gateway=='CLOUDAPI'">
        <label>Cloud API APP ID</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.cloudAPIAppID" name="cloudAPIAppID" />
      </div>
      <div class="form-group col-lg-12">
        <label>Enable Webhook </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.webhookEnable" name="webhookEnable"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.webhookEnable == true">
        <label>Webhook URL</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.webhookURL" name="webhookURL" />
      </div>

      <div class="form-group col-lg-12">
        <label>Enable Unverified Format </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.enableConvertUnverified" name="enableConvertUnverified"></mat-slide-toggle>
      </div>

      <div class="form-group col-lg-12" *ngIf="subscriptionDao.enableConvertUnverified == true">
        <label>Convert Unverified Url</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.convertUnverifiedUrl" name="convertUnverifiedUrl" />
      </div>

      <div class="form-group col-lg-12">
        <label>Enable Google Sheet </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.enableGoogleSheet" name="enableConvertUnverified"></mat-slide-toggle>
      </div>

      <div class="form-group col-lg-12" *ngIf="subscriptionDao.enableGoogleSheet == true">
        <label>Spread Sheet Id</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.spreadsheetId" name="convertUnverifiedUrl" />
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.enableGoogleSheet == true">
        <label>Range</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.range" name="convertUnverifiedUrl" />
      </div>

      <div class="form-group col-lg-12">
        <label>Enable Out Webhook </label>
        <mat-slide-toggle [(ngModel)]="subscriptionDao.outWebhookEnable" name="outWebhookEnable"></mat-slide-toggle>
      </div>
      <div class="form-group col-lg-12" *ngIf="subscriptionDao.outWebhookEnable == true">
        <label>Out Webhook URL</label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.outWebhookUrl" name="outWebhookURL" />
      </div>

      <div class="form-group col-lg-12"  *ngIf="subscriptionDao.gateway=='KARIX'">
        <label>Whatsapp Number </label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.waVerfiedNumber" name="waVerfiedNumber" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Free Template </label>
        <input type="number" class="form-control" [(ngModel)]="subscriptionDao.freeTemplatesMessage" name="freeTemplatesMessage" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Free Session Message </label>
        <input type="number" class="form-control" [(ngModel)]="subscriptionDao.freeSessionMessage" name="freeSessionMessage" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>After Consumption Template Charge </label>
        <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.afterConsumptionTemplateDed" name="afterConsumptionTemplateDed" tep="any"  required/>
      </div>
      <div class="form-group col-lg-12">
        <label>After Consumption Session Charge </label>
        <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.afterConsumptionSessionDed" name="afterConsumptionSessionDed" step="any" required/>
      </div>

      <div class="form-group col-lg-12">
        <label>Template Creation Deduction </label>
        <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.templateCreationDed" name="templateCreationDed"   required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Subscription Monthly Charge </label>
        <input type="number" class="form-control" step="any" [(ngModel)]="subscriptionDao.subscriptionAmt" name="subscriptionAmt" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Subscription Expiry </label>
        <input type="date" class="form-control" [(ngModel)]="subscriptionDao.expiry" name="expiry" required/>
      </div>


      <div class="form-group col-lg-12">
        <label>Subscription Url </label>
        <input type="url" class="form-control" [(ngModel)]="subscriptionDao.baseUrl" name="baseUrl" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Subscription Username </label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.userName" name="userName" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Subscription Password </label>
        <input type="text" class="form-control" [(ngModel)]="subscriptionDao.password" name="password" required/>
      </div>
      <div class="form-group col-lg-12">
        <label>Maximum Campaign Size </label>
        <input type="number" class="form-control" [(ngModel)]="subscriptionDao.maximumPerCampaign" name="maximumPerCampaign" required/>
      </div>

      <div class="form-group col-lg-12">
        <label>Email Id</label>
        <input type="email" class="form-control" [(ngModel)]="subscriptionDao.emailTo" name="emailTo" required/>
      </div>

      <div class="form-group col-lg-12">
        <label>Sales Person Email Id</label>
        <input type="email" class="form-control" [(ngModel)]="subscriptionDao.emailCc" name="emailcc" required/>
      </div>

      <div class="reset-button">
        <button style="margin-left: 10px;float: right" type="button" (click)="cancelBtn()" class="btn btn-add">Cancel</button>
        <button  style="float: right"  type="submit" class="btn btn-add">Save</button>
        <button style="margin-left: 10px;float: right" type="button" (click)="restoreSubscription()" class="btn btn-add">Restore Subscription</button>

      </div>
    </form>
  </div>

  <div *ngIf="!addnewform&&tableList.length>0" class="row">

    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th> Status</th>
        <th> Expiry</th>
        <th>Free Template</th>
        <th>Free Session</th>
        <th>Free Template Creation</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td ><i class="fa fa-check" *ngIf="subscriptionDao.isActive"></i><i style="color: green" class="fa fa-check" *ngIf="subscriptionDao.isActive"></i><i style="color: red" *ngIf="!subscriptionDao.isActive" class="fa fa-remove"></i></td>
        <td [innerHTML]="subscriptionDao.expiry"></td>
        <td [innerHTML]="subscriptionDao.freeTemplatesMessage"></td>
        <td [innerHTML]="subscriptionDao.freeSessionMessage"></td>
        <td [innerHTML]="subscriptionDao.freeTemplates"></td>
        <td ><i (click)="editSubscription(subscriptionDao)" class="fa fa-pencil-square btn btn-add"></i></td>
      </tr>
      </tbody>
    </table>

  </div>

</mat-dialog-content>
<mat-dialog-actions>

</mat-dialog-actions>
</div>
