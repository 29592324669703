import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AllMailsComponent} from '../../../mailSendingApp/all-mails/all-mails.component';
import {GroupAddComponent} from '../group-add/group-add.component';
import {Router} from '@angular/router';
import {WaContactGroupDao} from '../wa-contact-group-dao';
import {WaContactGroupServiceService} from '../wa-contact-group-service.service';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {Subject} from "rxjs";

@Component({
  selector: 'app-wa-contact-group',
  templateUrl: './wa-contact-group.component.html',
  styleUrls: ['./wa-contact-group.component.css']
})
export class WaContactGroupComponent implements OnInit {


  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
  tableList: Array<WaContactGroupDao>;
  searchText: string;
  addNewDiv: boolean;

  groupName: string;
  groupDesc: string;
  createdBy: string;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  groupadd: any = {
    id:  '',
    groupName: '',
    groupDesc: '',
    createdBy: '',
    totalUser: '',
    userId: '',
    date: '',
    dateFilter: '',
    status: '',
  };
  // tslint:disable-next-line:max-line-length
  constructor(private dialog: MatDialog, private router: Router, private service: WaContactGroupServiceService, private alert: AlertMessageService) { }

  ngOnInit(): void {
    this.get();
  }


  // tslint:disable-next-line:typedef
  get(){
    this.service.get().subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
      console.log('TableList values are:: {}' + this.tableList);
    });
  }
  // tslint:disable-next-line:typedef
  addGroup() {
    // this.addNewDiv = true;
    this.dialog.open(GroupAddComponent, {
      height: '80%',
      width: '40%',

    }).afterClosed().subscribe(value => {
      console.log(value);
    });
  }

  // tslint:disable-next-line:typedef
  save(id: number, groupName: string) {
   this.router.navigate(['wacontactgroupmaster'], {state: {groupId: id, gname: groupName} });
  }

  // tslint:disable-next-line:typedef
  delete(id: number) {
    this.alert.showLoader();
    this.service.deleteGroup(id).subscribe(value => {
      console.log(value);
      this.alert.hideLoader();
      window.location.reload();
    });
  }


  edit(group: WaContactGroupDao) {
    this.dialog.open(GroupAddComponent, {
      // height: '50%',
      width: '40%',
      data:group,
    }).afterClosed().subscribe(value => {
      console.log(value);
    });
  }
}
