import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InstanceMasterDao} from "../../../../Unofficial/instance-master/InstanceMasterDAo";
import {RegistrationOtpDao} from "../registration-otp-dao";

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.css']
})
export class ConfirmMessageComponent implements OnInit {
  user: RegistrationOtpDao;

  constructor(@Inject(MAT_DIALOG_DATA) public data: RegistrationOtpDao,private dialog: MatDialogRef<ConfirmMessageComponent>) { }

  ngOnInit(): void {
    this.user=this.data;
    console.log('matdialog',this.data)
    console.log('user',this.user)
    // this.dialog.close();
  }

}
