
<!-- Main content -->
<!-- Main content -->
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
              <a href="#">
                <h4>{{tableTite}}</h4>
              </a>
            </div>

           &nbsp; <button *ngIf="isMasterPage" (click)="openMasterCreateForm()"  class="btn btn-add">Add New Chatbot&nbsp; <i
              class="fa fa-plus"></i></button>
            <button *ngIf="isFlowPage" (click)="addNewFlow()"  class="btn btn-add">Add New Flow&nbsp; <i
              class="fa fa-plus"></i></button>
            <button *ngIf="isFlowPage" (click)="backMasterFlow()"  class="btn btn-add">Back&nbsp;<i
              class="fa fa-arrow-left"></i></button>
          </div>

        </div>

        <div *ngIf="!showTabeleDiv" id="flow-div">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Key</th>
                <th>Key value</th>
                <th>ValidInputs</th>
                <th>Type</th>
                <th style="white-space: break-spaces">Change Stage</th>
                <th style="white-space: break-spaces">Delete Stage</th>
                <th>Edit</th>

              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="coversationtableData.length==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of coversationtableData;let ind = index">
                <tr>
                  <td  [innerHTML]="data.key"  ></td>
                  <td  [innerHTML]="data.keyValue"  ></td>
                  <td  [innerHTML]="data.validInputs"  ></td>
                  <td  [innerHTML]="data.actionType"  ></td>
                  <td ><i *ngIf="data.changeStage==0" class="fa fa-close" style="color: red"></i><i *ngIf="data.changeStage==1" class="fa fa-check"  style="color: green"></i></td>
                  <td ><i *ngIf="data.deleteStage==0" class="fa fa-close" style="color: red"></i><i *ngIf="data.deleteStage==1" class="fa fa-check"  style="color: green"></i></td>
                  <td><button class="btn btn-add" (click)="openFlowForm(data)"><i class="fa fa-pencil"></i></button>
                    <button class="btn btn-danger m-r-2" (click)="deleteConversation(data)"><i class="fa fa-trash"></i></button>
                  </td>


                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="showTabeleDiv" id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.length==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of tableData;let ind = index">
                <tr>
                  <td  [innerHTML]="data.id"  ></td>
                  <td  [innerHTML]="data.name"  ></td>
                  <td *ngIf="data.status==1">Active</td>
                  <td *ngIf="data.status==0">Inactive</td>


                  <i (click)="openMasterEditForm(data)" class="btn btn-add fa fa-pencil m-r-2" ></i>
                    <i (click)="deleteMaster(data.id)" class="btn btn-danger fa fa-trash m-r-2"></i>
                    <i (click)="switchView(data.id)" class="btn btn-add fa fa-gears"></i>


                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)"
                         [length]=tableData.length
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>

        </div>



      </div>
    </div>
  </div>


</section>
