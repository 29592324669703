import { Component, OnInit } from '@angular/core';
import {DatefilterComponent} from '../../CommonPopups/datefilter/datefilter.component';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {LongCodeCampaignDao} from './long-code-campaign-dao';
import {LongCodeCampaignServiceService} from './long-code-campaign-service.service';

@Component({
  selector: 'app-long-code-campaign',
  templateUrl: './long-code-campaign.component.html',
  styleUrls: ['./long-code-campaign.component.css']
})
export class LongCodeCampaignComponent implements OnInit {


  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, bPaginate: true, order: []};

  tableList: Array<LongCodeCampaignDao>;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private service: LongCodeCampaignServiceService) { }

  // tslint:disable-next-line:typedef
  filterDatafordate() {
    this.dialog.open(DatefilterComponent);
  }

  // tslint:disable-next-line:typedef
  clearFilter()
  {
    this.router.navigate([], {queryParams: {}});
  }

  // tslint:disable-next-line:typedef
  getData()
  {
    this.service.get().subscribe(value => {
      this.tableList = value;
      console.log('Data is this :: ' + this.tableList);
    });
  }


  ngOnInit(): void {
    this.getData();
  }

}
