import {Component, OnInit} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
  CallButton, FileMessageDao, Image,
  MessageDao, MessageStatus,
  MessageType,
  QuickReplyButton,
  TemplateButtonMessage,
  TemplateButtons,
  UrlButton, Video
} from '../DaoMessageUn';
import {CampaignServiceService} from './campaignService/campaign-service.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {WaContactGroupServiceService} from '../WaContactGroup/wa-contact-group-service.service';
import {WaContactGroupDao} from '../WaContactGroup/wa-contact-group-dao';
import {WacontactgroupmasterDao} from '../WaContactGroup/GroupMaster/wacontactgroupmaster-dao';
import {TemplateDao} from '../../ws-template/MessageDao';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {ExcelReaderCommonComponent} from '../../CommonPopups/excel-reader-common/excel-reader-common.component';
import {MatDialog} from '@angular/material/dialog';
import {ScheduleMailComponent} from '../../mailSendingApp/schedule-mail/schedule-mail.component';
import {UserService} from '../../Services/User/user.service';
import {GroupInnerDao} from "../WhatsappGroup/GroupInnerDao";
import {
  ButtonMessageInnerDao
} from "../../MicroServices/Chatbot/ChatbotConversationFlow/conversation-flow/ConversationDao";
import {id} from "@swimlane/ngx-datatable";
import {PageDao} from "../../Feature/page-dao";
import {InstanceMasterService} from "../instance-master/InstanceService/instance-master.service";
import {InstanceMasterDao} from "../instance-master/InstanceMasterDAo";
import {AuthGuard} from "../../Services/Routing/Authguard";
import {FeatureDao} from "../../Feature/feature-dao";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-onetomanywsun',
  templateUrl: './onetomanywsun.component.html',
  styleUrls: ['./onetomanywsun.component.css']
})
export class OnetomanywsunComponent implements OnInit {
  isFeaturAccess: FeatureDao;
  instanceId: any = 0;
  countryCode: any = '';
  pagePermission: number;
  instanceArr: Array<InstanceMasterDao>;
  isWhatsappGroup: boolean = false;
  activeInstanceId = 0;
  isActive: boolean;
  accountId: number;
  recieptsTo = '';
  content = '';
  campaingName = '';
  contentType: number;
  fileUrl = '';
  caption = '';
  fileName = '';
  footer = '';
  displayText = '';
  url = '';
  displayText1 = '';
  phoneNumber = '';
  quickReply = '';
  quickReply2 = '';
  quickReply3 = '';
  imageCaption = '';
  imageUrl = '';
  videoUrl = '';
  groupId: any;
  tableList1: Array<WacontactgroupmasterDao>;
  tableList: Array<WaContactGroupDao>;
  groupList: Array<GroupInnerDao>;
  activeInstanceList: Array<InstanceMasterDao>;
  removeDuplication: boolean;
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  active: boolean;
  image: Image = {url: ''};

  video: Video = {url: ''};
  valuemap: any;
  dynamicsms: Array<any> = [];
  iSchedule: number;
  scheduleTime: null;
  whatsappGroupId: any;
  whatsappGroupIdList: Array<any> = [];
  selectValueArray: Array<any> = []


  urlButton: UrlButton = {displayText: this.displayText, url: this.url};
  callButton: CallButton = {displayText: this.displayText1, phoneNumber: this.phoneNumber};
  quickReplyButton: QuickReplyButton = {displayText: this.quickReply, id: 'id- like-buttons-message'};
  quickReplyButton2: QuickReplyButton = {displayText: this.quickReply2, id: 'id- like-buttons-message'};
  quickReplyButton3: QuickReplyButton = {displayText: this.quickReply3, id: 'id- like-buttons-message'};

  // tslint:disable-next-line:max-line-length
  templateButton: any = {
    index: 1,
    urlButton: this.urlButton,
    callButton: null,
    quickReplyButton: null,
    quickReplyButton2: null,
    quickReplyButton3: null
  };
  // tslint:disable-next-line:max-line-length
  templateButton1: any = {
    index: 2,
    urlButton: null,
    callButton: this.callButton,
    quickReplyButton: null,
    quickReplyButton2: null,
    quickReplyButton3: null
  };
  // tslint:disable-next-line:max-line-length
  templateButton2: any = {
    index: 3,
    urlButton: null,
    callButton: null,
    quickReplyButton: this.quickReplyButton,
    quickReplyButton2: null,
    quickReplyButton3: null
  };
  // tslint:disable-next-line:max-line-length
  templateButton3: any = {
    index: 4,
    urlButton: null,
    callButton: null,
    quickReplyButton: null,
    quickReplyButton2: this.quickReplyButton2,
    quickReplyButton3: null
  };
  // tslint:disable-next-line:max-line-length
  templateButton4: any = {
    index: 5,
    urlButton: null,
    callButton: null,
    quickReplyButton: null,
    quickReplyButton2: null,
    quickReplyButton3: this.quickReplyButton3
  };
  templateButonList: any = [this.templateButton, this.templateButton1, this.templateButton2, this.templateButton3, this.templateButton4];
  // tslint:disable-next-line:max-line-length
  // templateButtons: any = [{index: 1, urlButton: this.urlButton, callButton: null, quickReplyButton: null}, {index: 2, urlButton: null, callButton: this.callButton , quickReplyButton: null}, { index: 3, urlButton: null, callButton: null, quickReplyButton: this.quickReplyButton}];
  // tslint:disable-next-line:max-line-length
  templateButtonMessage: TemplateButtonMessage = {
    text: this.caption,
    footer: this.footer,
    templateButtons: this.templateButonList,
    caption: this.imageCaption,
    image: this.image,
    video: this.video
  };

  countryDao: any = {
    code: '',
    name: '',
    date: '',
    dateFilter: 0,
    status: false
  }

  StringUtil = {
    // tslint:disable-next-line:only-arrow-functions typedef label-position
    substitute: (function () {
      const regexp = /{([^{]+)}/g;
      // tslint:disable-next-line:only-arrow-functions typedef
      return function (str, o) {
        // tslint:disable-next-line:only-arrow-functions typedef
        return str.replace(regexp, function (ignore, key) {
          // tslint:disable-next-line:no-conditional-assignment
          return (key = o[key]) == null ? '' : key;
        });
      };
    })(),
  };


  // tslint:disable-next-line:max-line-length
  messageSendingSpeed: number;
  customMessageInterval: boolean;
  countryData: Array<any>;


  constructor(private camapaign: CampaignServiceService, private instanceMasterService: InstanceMasterService, private alert: AlertMessageService, private service: WaContactGroupServiceService, private dialog: MatDialog, private user: UserService, private authservice: AuthGuard, private _snackBar: MatSnackBar, private instance: InstanceMasterService) {
    this.contentType = 0;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  ngOnInit(): void {
    this.getContryCode();
    this.featureAccess();
    this.getActiveInstance();
    this.getGroup();
    this.accountId = this.user.user.accountId;

    this.instance.getActive().subscribe(value => {
      this.instanceArr = value;
    })
  }

  getContryCode() {
    this.service.getContryCode().subscribe(value => {
      this.countryData = value;
    }, error => {
    })
  }

  // tslint:disable-next-line:typedef
  getGroup() {
    this.service.get().subscribe(value => {
      this.tableList = value;
    });
  }

  // tslint:disable-next-line:typedef
  getByGroupId() {
    this.service.getbyGroupId(this.groupId).subscribe(value => {
      this.tableList1 = value;

      for (let i = 0; i <= this.tableList1.length; i++) {
        this.recieptsTo += '\n' + this.tableList1[i].phonenumber;
      }
    });


  }

  // tslint:disable-next-line:typedef
  createCampiagn() {

    const messageDao: Array<MessageDao> = [];
    // IF ENABLES DYNAMIC MESSAGE
    // tslint:disable-next-line:triple-equals
    if (this.active == true) {
      // tslint:disable-next-line:no-shadowed-variable

      //IF REMOCEDUPLICATION EBALBE
      if (this.removeDuplication) {
        this.dynamicsms = this.getUniqueListBy(this.dynamicsms, 'dst1');
      }


      // tslint:disable-next-line:no-shadowed-variable
      const messageDao: Array<MessageDao> = [];
      // tslint:disable-next-line:triple-equals
      if (this.contentType == 0) {

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dynamicsms.length; i++) {

          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(this.dynamicsms[i].dst1),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEXT,
            textMessage: {content: this.dynamicsms[i].msg},
            templateButtonMessage: undefined,
            chatSide: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
          });
        }
      }


      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dynamicsms.length; i++) {

          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(this.dynamicsms[i].dst1),
            fileMessage: {fileUrl: this.fileUrl, fileName: this.fileName, caption: this.dynamicsms[i].caption},
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.FILE,
            textMessage: undefined,
            templateButtonMessage: undefined,
            chatSide: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
          });
        }
      }
      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 2) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dynamicsms.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.dynamicsms[i].dst1,
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            chatSide: undefined,
            messageType: MessageType.TEMPLATEBUTTON,
            textMessage: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.dynamicsms[i].caption,
              footer: this.footer,
              caption: this.imageCaption,
              image: null,
              video: null,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }
      }

      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 3) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dynamicsms.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(this.dynamicsms[i].dst1),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            chatSide: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEMPLATEBUTTONIMAGE,
            textMessage: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.dynamicsms[i].imageCaption,
              footer: this.footer,
              caption: this.dynamicsms[i].imageCaption,
              image: this.image,
              video: null,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }
      }
      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 4) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dynamicsms.length; i++) {

          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(this.dynamicsms[i].dst1),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            chatSide: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEMPLATEBUTTONVIDEO,
            textMessage: undefined,
            promotional: undefined,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.dynamicsms[i].imageCaption,
              footer: this.footer,
              caption: this.dynamicsms[i].imageCaption,
              image: null,
              video: this.video,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }

      }

      console.log('Will Create Campaign {}', messageDao);
      this.alert.confirmMessage('Start campaign of size ' + this.dynamicsms.length + '?', 'Are you sure?').then(value => {
        this.alert.showLoader();
        this.camapaign.startCampaing({
          campaignName: this.campaingName,
          messageDaos: messageDao,
          messageSendingSpeed: this.messageSendingSpeed,
          customMessageInterval: this.customMessageInterval
        }).subscribe(value1 => {
          this.alert.hideLoader();
          this.alert.showSuccesMessage('Successfully Started Campaign!!');
          this.clearData();
        }, error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage(error.error.message);
        });
      });
    } else {
      var arr = this.recieptsTo.split('\n');

      if (this.removeDuplication) {
        arr = this.removeDuplicate(arr);
      }

      // const messageDao: Array<MessageDao> = [];
      // tslint:disable-next-line:triple-equals
      if (this.contentType == 0) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(arr[i]),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEXT,
            textMessage: {content: this.content},
            templateButtonMessage: undefined,
            chatSide: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
          });
        }
      }
      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(arr[i]),
            fileMessage: {fileUrl: this.fileUrl, fileName: this.fileName, caption: this.caption},
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.FILE,
            textMessage: undefined,
            templateButtonMessage: undefined,
            chatSide: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: 0,
          });
        }
      }
      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 2) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(arr[i]),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            chatSide: undefined,
            messageType: MessageType.TEMPLATEBUTTON,
            textMessage: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.caption,
              footer: this.footer,
              caption: this.imageCaption,
              image: null,
              video: null,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }
      } else if (this.contentType == 3) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(arr[i]),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            locationMessage: undefined,
            chatSide: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEMPLATEBUTTONIMAGE,
            textMessage: undefined,
            promotional: false,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.caption,
              footer: this.footer,
              caption: this.imageCaption,
              image: this.image,
              video: null,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }
      }

      // tslint:disable-next-line:triple-equals
      else if (this.contentType == 4) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < arr.length; i++) {
          messageDao.push({
            buttonMessage: undefined,
            preferredIncId: this.instanceId,
            contactMessage: undefined,
            dst: this.validatePhoneNumber(arr[i]),
            fileMessage: undefined,
            id: '',
            incId: 0,
            listMessage: undefined,
            chatSide: undefined,
            locationMessage: undefined,
            messageStatus: undefined,
            messageType: MessageType.TEMPLATEBUTTONVIDEO,
            textMessage: undefined,
            promotional: undefined,
            iSchedule: this.iSchedule,
            scheduleTime: this.scheduleTime,
            campaignSize: null,
            // templateButtonMessage: this.templateButtonMessage,
            // tslint:disable-next-line:max-line-length
            templateButtonMessage: {
              text: this.caption,
              footer: this.footer,
              caption: this.imageCaption,
              image: null,
              video: this.video,
              templateButtons: [{
                index: 1,
                urlButton: {displayText: this.displayText, url: this.url},
                callButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 2,
                callButton: {displayText: this.displayText1, phoneNumber: this.phoneNumber},
                urlButton: null,
                quickReplyButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 3,
                quickReplyButton: {displayText: this.quickReply, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null,
                quickReplyButton2: null,
                quickReplyButton3: null
              }, {
                index: 4,
                quickReplyButton: {displayText: this.quickReply2, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }, {
                index: 5,
                quickReplyButton: {displayText: this.quickReply3, id: 'id-like-buttons-message'},
                urlButton: null,
                callButton: null
              }]
            }
          });
        }

      }


      this.alert.confirmMessage('Start campaign of size ' + arr.length + '?', 'Are you sure?').then(value => {
        this.alert.showLoader();
        this.camapaign.startCampaing({
          campaignName: this.campaingName,
          messageDaos: messageDao,
          customMessageInterval: this.customMessageInterval,
          messageSendingSpeed: this.messageSendingSpeed
        }).subscribe(value1 => {
          this.alert.hideLoader();
          this.alert.showSuccesMessage('Successfully Started Campaign!!');
          this.clearData();
        }, error => {
          this.alert.hideLoader();
          this.alert.showErrorMessage(error.error.message);
        });
      });

    }

  }


  // @ts-ignore
  // @ts-ignore
  // tslint:disable-next-line:typedef
  clearData() {
    this.campaingName = '';
    this.caption = '';
    this.content = '';
    this.recieptsTo = '';
    this.contentType = 0;
    this.fileName = '';
    this.fileUrl = '';
    this.footer = '';
    this.displayText = '';
    this.url = '';
    this.displayText1 = '';
    this.quickReply2 = '';
    this.quickReply3 = '';
    this.phoneNumber = '';
    this.quickReply = '';
    this.imageCaption = '';
    this.videoUrl = '';
    this.iSchedule = 0;
    this.scheduleTime = null;


    this.video = {
      url: ''
    };

    this.image = {
      url: ''
    };

    this.dynamicsms = [];


    //for whatsapp group
    this.isWhatsappGroup = false;
    this.activeInstanceId = 0;
    this.selectValueArray = [];
    this.whatsappGroupIdList = [];


  }


  addNewSelect() {
    console.log("add new Select", this.selectValueArray.length)
    // @ts-ignore
    if (this.selectValueArray.length == 10) {
      return;
    } else {
      this.whatsappGroupIdList.push("");
      let newId = this.selectValueArray.length + 1;
      this.selectValueArray.push({"id": newId})
      console.log(this.selectValueArray)
    }
  }

  onChangeWhatsappGroup() {
    console.log("whatsappGroupId:", this.whatsappGroupId);

    this.recieptsTo = this.whatsappGroupIdList.join('\n');


  }

  onChangeIsWhatsappGroup() {
    if (this.isWhatsappGroup == false) {
      this.recieptsTo = "";
    }
  }

  // tslint:disable-next-line:typedef
  uploadFileComponent() {

    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const dst = [];
      const toleng = value.length;
      console.log('data length', toleng);
      if (toleng <= 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      for (let i = 0; i < toleng; i++) {

        this.recieptsTo += '\n' + value[i].PhoneNumber.replace('\'', '');
      }

    });
  }


  // tslint:disable-next-line:typedef
  dynamicSMS() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      const dst = [];
      const toleng = value.length;
      console.log('data length', toleng);
      if (toleng <= 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      for (let i = 0; i < toleng; i++) {
        this.valuemap = value[i];
        const msg = this.StringUtil.substitute(this.content, this.valuemap);
        const caption = this.StringUtil.substitute(this.caption, this.valuemap);
        const imageCaption = this.StringUtil.substitute(this.imageCaption, this.valuemap);
        const dst1 = value[i].PhoneNumber.replace('\'', '');
        this.dynamicsms.push({msg, dst1, imageCaption, caption});
      }

    });
  }


  // tslint:disable-next-line:typedef
  downloadSampleFile() {


    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('PhoneNumber');
    trArr.push(tdArr);

    tdArr = [];
    tdArr.push('919988776655');
    trArr.push(tdArr);
    file.generateFile(trArr, 'SampleFileUpload.xlsx');
    this.alert.hideLoader();
  }


// change variable parts
  // tslint:disable-next-line:typedef


  campaignScheduled() {
    this.dialog.open(ScheduleMailComponent, {
      width: '40%',
      height: '40%'
    }).afterClosed().subscribe(value => {

      if (value != null || value != undefined) {
        this.iSchedule = 1;
        this.scheduleTime = value.replaceAll("T", "").replaceAll("-", "").replaceAll(":", "");
        this.createCampiagn();
      }
    })
  }

  getGroupDetails(id) {
    this.service.getallGroupDetails(id).subscribe(value => {
      this.groupList = value;
      console.log("group details:", value)
    });
  }

  getActiveInstance() {
    console.log("get active instance by account id", this.accountId)
    this.instanceMasterService.getActive().subscribe(value => {
      this.activeInstanceList = value;
      console.log(" list of active instance:", this.activeInstanceList)
    });
  }


  featureAccess() {
    this.authservice.getPagePermission('onetomany').subscribe(value => {
      if (value != null) {
        this.isFeaturAccess = value;
      } else {
        this.pagePermission = 3;
      }
      // this.isFeaturAccess=value;
    })
  }

  deleteSelect(id) {
    if (this.whatsappGroupIdList.length > 1) {
      this.whatsappGroupIdList.splice(id, 1); // 2nd parameter means remove one item only
    }
    this.recieptsTo = this.whatsappGroupIdList.join("\n")

    if (this.selectValueArray.length > 1) {
      // @ts-ignore
      this.selectValueArray.pop(id)

    } else {
      return;

    }
  }

  deleteSelect1(id) {
    console.log("delete select ", this.recieptsTo)
    let receiptArray = this.recieptsTo.split("\n");
    if (receiptArray.length > 1) {
      receiptArray.splice(id, 1); // 2nd parameter means remove one item only
    }
    this.recieptsTo = receiptArray.join('\n');

    // @ts-ignore
    if (this.selectValueArray.length > 1) {
      // @ts-ignore
      this.selectValueArray.pop(id)

    } else {
      return;
    }

  }


  onChangeInstanceSelect() {
    if (this.activeInstanceId == 0) {
      this.groupList = [];
      this.recieptsTo = "";
      this.selectValueArray = [];
      this.whatsappGroupIdList = [];
    } else {

      this.selectValueArray.push({id: 0})
      this.getGroupDetails(this.activeInstanceId);

    }
  }


  removeDuplicate(array: any) {

    let simpleArray = array;
    simpleArray = simpleArray.filter(
      (element, i) => i === simpleArray.indexOf(element)
    );

    return simpleArray;
  };

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }


  changeContenType(number: number) {
    this.contentType = number;
  }


  validatePhoneNumber(phoneNumber: string): string {
    const MAX_PHONE_NUMBER_LENGTH = 10;

    if (phoneNumber.startsWith(this.countryCode)) {
      if (phoneNumber.length <= MAX_PHONE_NUMBER_LENGTH) {
        return this.countryCode + phoneNumber;
      } else {
        return phoneNumber;
      }
    } else {
      return this.countryCode + phoneNumber.slice(-MAX_PHONE_NUMBER_LENGTH);
    }
  }



}
