<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          Campaign Report
        </div>
      </div>
      <div style="" class="panel-body">

        <div  id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
          <!--          <table class="table table-bordered table-striped table-hover">-->
            <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Receipts</th>
              <th>Create At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align: center" *ngIf="tableData.total==0">
              <td colspan="4">No Entry</td>
            </tr>
            <ng-container *ngFor="let data of tableData.data;let ind =index">
              <tr>
                <td [innerHTML]="data.campaignName"></td>
                <td [innerHTML]="data.count"></td>
                <td [innerHTML]="data.date"></td>
                <td>
                  <i title="Click Here To View " (click)="drawReport(data,ind)"
                     class="btn btn-add fa fa-bar-chart"></i>
                  <i title="Download Report" (click)="downloadReport(data,ind)"
                     class="btn btn-add fa fa-download"></i>
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5,10, 25, 100]">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>





