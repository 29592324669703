
<app-service-selection ></app-service-selection>






<!--<div class="row">-->

<!--  <button title="Click Here To Filter" (click)="loadFilter()" style="float: right" type="button" class="btn btn-default btn-circle m-b-5 "><i class="glyphicon glyphicon-filter"></i></button>-->
<!--  <button title="Click Here To Refresh" style="float: right" type="button" class="btn btn-default btn-circle m-b-5"><i class="glyphicon glyphicon-refresh"></i></button>-->


<!--</div>-->

<!--<div class="row">-->
<!--  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">-->
<!--    <div id="cardbox1">-->
<!--      <div class="statistic-box">-->
<!--        <i class="fa fa-user-plus fa-3x"></i>-->
<!--        <div class="counter-number pull-right">-->
<!--          <span class="count-number">11</span>-->
<!--          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>-->
<!--                              </span>-->
<!--        </div>-->
<!--        <h3> Total Sent</h3>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">-->
<!--    <div id="cardbox2">-->
<!--      <div class="statistic-box">-->
<!--        <i class="fa fa-user-secret fa-3x"></i>-->
<!--        <div class="counter-number pull-right">-->
<!--          <span class="count-number">4</span>-->
<!--          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>-->
<!--                              </span>-->
<!--        </div>-->
<!--        <h3>  Total Delievered</h3>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">-->
<!--    <div id="cardbox3">-->
<!--      <div class="statistic-box">-->
<!--        <i class="fa fa-user-secret fa-3x"></i>-->
<!--        <div class="counter-number pull-right">-->
<!--          <span class="count-number">4</span>-->
<!--          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>-->
<!--                              </span>-->
<!--        </div>-->
<!--        <h3>  Total Failed</h3>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">-->
<!--    <div id="cardbox4">-->
<!--      <div class="statistic-box">-->
<!--        <i class="fa fa-user-secret fa-3x"></i>-->
<!--        <div class="counter-number pull-right">-->
<!--          <span class="count-number">4</span>-->
<!--          <span class="slight"><i class="fa fa-play fa-rotate-270"> </i>-->
<!--                              </span>-->
<!--        </div>-->
<!--        <h3>  Total Recieved</h3>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="row">-->


<!--</div>-->
