import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment.prod";
import * as http from "http";
import {TokenInterceptorService} from "../../../Services/TokenInterceptor/token-interceptor.service";
import {PaginationDao} from "../../../CommonObj/PaginationDao";

const BASE_URL = environment.appUrl + environment.digimozoservice;

@Injectable({
  providedIn: 'root'
})
export class RegistrationOtpService {


  constructor(private http: HttpClient,private tokenService:TokenInterceptorService) {
  }

  sendOtp(data: string): Observable<any> {
    return this.http.post(BASE_URL + 'api/registraiton/sendOtp', data)
  }

  sendOtpFromKK(data: string): Observable<any> {
    return this.http.post(BASE_URL + 'api/registration/kk/sendOtp', data)
  }
  getMasterData(): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api/registationmaster/get");
  }



  getMasterDataBYid(): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api/registationmaster/getById");
  }

  getByMandiName(): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api/registationmaster/mandiName");
  }

  getByMandiNameFromKK(): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api//master/kk/mandiName");
  }

  getByWsName(wsName:string): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api/registationmaster/wsName?wsName="+wsName);
  }


  getByWsNameFromKK(wsName:string): Observable<Array<any>> {
    return this.http.get<Array<any>>(BASE_URL + "api/master/kk/wsName?wsName="+wsName);
  }

  save(data: any): Observable<any> {
    return this.http.post(BASE_URL + 'api/registraiton/save', data)
  }

  saveInKK(data: any): Observable<any> {
    return this.http.post(BASE_URL + 'api/registration/kk/save', data)
  }
  get():Observable<any>{
    return this.http.get<Array<any>>(BASE_URL+'api/registraiton/get')
  }

  getFromKK(offset:number,limit:number):Observable<any>{
    return this.http.get<PaginationDao<any>>(BASE_URL+'api/master/kk/get?offset='+offset+'&limit='+limit)
  }

  getallmaster(offset:number, limit: number):Observable<any>{
    return this.http.get<PaginationDao<any>>(BASE_URL+'api/registraiton/getAll?offset='+offset+'&limit='+limit)
  }

  getAllFromKKDao(offset:number,limit:number):Observable<any>{
    return this.http.get<PaginationDao<any>>(BASE_URL+'api/registration/kk/getAll?offset='+offset+'&limit='+limit)
  }
}
