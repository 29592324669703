<div class="row">
  <div class="col-sm-8">
    <legend> One To Many Messages</legend>

    <div class="radio-item-container ">
      <div>
        <!--      <div class="panel-heading">-->
        <!--        <div class="panel-title">-->
        <!--          One To Many-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="panel-body">
          <div>
            <form ngNativeValidate>

              <div class="form-group col-lg-4">
                <label>Campaign Name <sup class="required">*</sup></label>
                <input type="text" class="form-control" [(ngModel)]="campaingName" name="campaingName" required>
              </div>

              <div class="form-group col-lg-4">
                <label>Preferable Instance</label>
                <select class="form-control" [(ngModel)]="instanceId">
                  <option value="" selected>---Choose Instance-----</option>
                  <ng-container *ngFor="let instance of instanceArr">
                    <option [value]="instance.id">{{ instance.instanceName }}</option>
                  </ng-container>
                </select>
              </div>

              <div class="form-group col-lg-4">
                <label>Country <sup class="required">*</sup></label>
                <select class="form-control" name="countryCode" [(ngModel)]="countryCode" required>
                  <option value="" selected>---Choose Country-----</option>
                  <ng-container *ngFor="let data of countryData">
                    <option [value]="data.code">{{ data.name }}</option>
                  </ng-container>
                </select>
              </div>


              <container-element [ngSwitch]="contentType">
                <some-element *ngSwitchCase="0">
                  <div class="form-group col-lg-12">
                    <label>Message<sup class="required">*</sup></label>
                    <textarea style="min-height: 130px" class="form-control" [(ngModel)]="content" name="content"
                              required
                              ng-blur="update(content.name)">
              </textarea>

                  </div>
                </some-element>
                <some-element *ngSwitchCase="1">
                  <div class="form-group col-lg-12">
                    <label>File Url <sup class="required">*</sup></label>
                    <input type="url" class="form-control" [(ngModel)]="fileUrl" name="fileUrl" required>
                  </div>
                  <div class="form-group col-lg-12">
                    <label>File Name <sup class="required">*</sup></label>
                    <input type="text" class="form-control" [(ngModel)]="fileName" name="fileName" required>
                  </div>
                  <div class="form-group col-lg-12">
                    <label> Caption <sup class="required">*</sup></label>
                    <textarea type="text" class="form-control" [(ngModel)]="caption" name="caption" required>
                                  </textarea>
                  </div>
                </some-element>


                <!--              ============================================templateImage===============================-->

                <some-element *ngSwitchCase="3">
                  <!--                <div class="form-group col-lg-12">-->
                  <!--                  <label>Text   <sup class="required">*</sup></label>-->
                  <!--                  <textarea type="text" class="form-control" [(ngModel)]="caption" name="caption" required>-->
                  <!--                                  </textarea>-->
                  <!--                </div>-->
                  <div class="form-group col-lg-12">
                    <label>Footer </label>
                    <input type="text" class="form-control" [(ngModel)]="footer" name="footer">
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Caption<sup class="required">*</sup></label>
                    <textarea type="text" class="form-control" [(ngModel)]="imageCaption" name="imageCaption"
                              required></textarea>
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Image Url<sup class="required">*</sup></label>
                    <input type="text" class="form-control" [(ngModel)]="image.url" name="imageUrl" required>
                  </div>
                  <div class="col-sm-12">
                    <div>
                      <div class="form-group col-lg-12">
                        <label>URL Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText" name="displayText">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>URL</label>
                        <input type="url" class="form-control" [(ngModel)]="url" name="url">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Call Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText1" name="displayText1">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" [(ngModel)]="phoneNumber" name="phoneNumber">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Quick Reply</label>
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 1</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply" name="quickReply">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 2</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply2" name="quickReply2">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 3</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply3" name="quickReply3">
                      </div>
                    </div>

                  </div>
                </some-element>


                <some-element *ngSwitchCase="4">
                  <!--                <div class="form-group col-lg-12">-->
                  <!--                  <label>Text   <sup class="required">*</sup></label>-->
                  <!--                  <textarea type="text" class="form-control" [(ngModel)]="caption" name="caption" required>-->
                  <!--                                  </textarea>-->
                  <!--                </div>-->
                  <div class="form-group col-lg-12">
                    <label>Footer </label>
                    <input type="text" class="form-control" [(ngModel)]="footer" name="footer">
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Caption<sup class="required">*</sup></label>
                    <textarea type="text" class="form-control" [(ngModel)]="imageCaption" name="imageCaption"
                              required></textarea>
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Video Url<sup class="required">*</sup></label>
                    <input type="text" class="form-control" [(ngModel)]="video.url" name="video" required>
                  </div>
                  <div class="col-sm-12">
                    <div>
                      <div class="form-group col-lg-12">
                        <label>URL Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText" name="displayText">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>URL</label>
                        <input type="url" class="form-control" [(ngModel)]="url" name="url">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Call Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText1" name="displayText1">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" [(ngModel)]="phoneNumber" name="phoneNumber">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Quick Reply</label>
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 1</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply" name="quickReply">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 2</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply2" name="quickReply2">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 3</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply3" name="quickReply3">
                      </div>
                    </div>

                  </div>
                </some-element>

                <some-element *ngSwitchDefault>
                  <div class="form-group col-lg-12">
                    <label>Text <sup class="required">*</sup></label>
                    <textarea type="text" class="form-control" [(ngModel)]="caption" name="caption" required>
                                  </textarea>
                  </div>
                  <div class="form-group col-lg-12">
                    <label>Footer</label>
                    <input type="text" class="form-control" [(ngModel)]="footer" name="footer">
                  </div>
                  <div class="col-sm-12">
                    <div>
                      <div class="form-group col-lg-12">
                        <label>URL Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText" name="displayText">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>URL</label>
                        <input type="url" class="form-control" [(ngModel)]="url" name="url">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Call Button</label></div>
                      <div class="form-group col-lg-6">
                        <label>Display Text</label>
                        <input type="text" class="form-control" [(ngModel)]="displayText1" name="displayText1">
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" [(ngModel)]="phoneNumber" name="phoneNumber">
                      </div>
                    </div>
                    <div>
                      <div class="form-group col-lg-12">
                        <label>Quick Reply</label>
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 1</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply" name="quickReply">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 2</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply2" name="quickReply2">
                      </div>
                      <div class="form-group col-lg-4">
                        <label>Button 3</label>
                        <input type="text" class="form-control" [(ngModel)]="quickReply3" name="quickReply3">
                      </div>
                    </div>

                  </div>
                </some-element>
              </container-element>
              <!--====================================Dynamic Message=================================-->
              <!--            <div class="form-group col-lg-12">-->
              <!--              <label>Dynamic Message </label>-->
              <!--              <mat-slide-toggle [(ngModel)]="active" name="active"></mat-slide-toggle>-->
              <!--            </div>-->

              <div *ngIf="active==true">

                <label>File <i title="Click Here To Download SampleFile " class="fa fa-question-circle"
                               (click)="downloadSampleFile()"></i> <small style="color: red">&nbsp;Make sure contact
                  number includes country code e.g. 919988776655</small></label>
                <div class="form-group col-lg-12">
                  <button type="button" (click)="dynamicSMS()" class="btn btn-add">Upload File <i
                    class="fa fa-upload"></i></button>
                </div>
              </div>

              <!--            ===============================================End==========================-->

              <div *ngIf="this.active ==undefined || this.active ==false">
                <div *ngIf="this.isWhatsappGroup==false" class="col-md-12 form-group">
                  <!--                  <label class="control-label">Select Group</label>-->
                  <!--                  <select class="form-control" [(ngModel)]="this.groupId" (click)="getByGroupId()" name="group">-->
                  <!--                    <ng-container *ngFor="let group of tableList">-->
                  <!--                      <option [ngValue]="group.id" [innerHTML]="group.groupName"></option>-->
                  <!--                    </ng-container>-->
                  <!--                  </select>-->
                </div>

                <!--              <div class="form-group col-lg-12">-->
                <!--                <label>Custom Message Time Interval</label>-->
                <!--&lt;!&ndash;                <mat-slide-toggle [(ngModel)]="customMessageInterval" name="customMessageInterval"></mat-slide-toggle>&ndash;&gt;-->
                <!--              </div>-->
                <!--              <div *ngIf="customMessageInterval == true" class="form-group col-lg-4">-->
                <!--                <label>Set Time Interval(in Seconds not less then 1)</label>-->
                <!--                <input type="text" class="form-control" [(ngModel)]="messageSendingSpeed" name="messageSendingSpeed">-->
                <!--              </div>-->

                <!--              <div class="form-group col-lg-12">-->
                <!--                <label> Whatsapp Group</label>-->
                <!--                <mat-slide-toggle  name="isWhatsappGroup" [(ngModel)]="isWhatsappGroup"-->
                <!--                                  (change)="onChangeIsWhatsappGroup()"></mat-slide-toggle>-->
                <!--              </div>-->

                <div *ngIf="isWhatsappGroup==true">
                  <div class="col-md-12 form-group">
                    <label class="control-label">Select Instance</label>
                    <select class="form-control" [(ngModel)]="activeInstanceId" (change)="onChangeInstanceSelect()"
                            name="ativeInstance">
                      <option [ngValue]="0">Select option</option>
                      <ng-container *ngFor="let activeInstance of activeInstanceList">
                        <option [ngValue]="activeInstance.id" [innerHTML]="activeInstance.instanceName"></option>
                      </ng-container>
                    </select>
                  </div>


                  <div *ngIf="activeInstanceId>0">
                    <ng-container *ngFor="let row of selectValueArray;let i = index">
                      <div class="form-group col-lg-6">
                        <label>Select Whatsapp group </label>
                        <select class="form-control" [(ngModel)]="this.whatsappGroupIdList[i]" [name]="'button'+i"
                                (click)="onChangeWhatsappGroup()" name="whatsappgroup">
                          <option value="">Select option</option>
                          <ng-container *ngFor="let group of groupList">
                            <option [ngValue]="group.id" [innerHTML]="group.subject"></option>
                          </ng-container>
                        </select>
                      </div>


                      <div class="form-group col-lg-1">
                        <label></label>
                        <button type=button (click)="deleteSelect(i)" class=" form-control btn btn-danger "><i
                          class="fa fa-trash"></i></button>

                      </div>
                      <div class="form-group col-lg-6">
                      </div>
                    </ng-container>
                    <div class="form-group col-lg-1">
                      <label></label>
                      <button type=button (click)="addNewSelect()" class="form-control btn btn-primary"><i
                        class="fa fa-plus"></i></button>
                    </div>

                  </div>
                </div>
                <!--              <div  class="col-md-12 form-group">-->
                <!--                <label class="control-label">Select Whatsapp Group</label>-->
                <!--                <select class="form-control" [(ngModel)]="this.whatsappGroupId" (click)="onChangeWhatsappGroup()" name="whatsappgroup"  >-->
                <!--                  <ng-container *ngFor="let group of groupList">-->
                <!--                    <option value="">Select option</option>-->

                <!--                    <option [ngValue]="group.id" [innerHTML]= "group.subject"></option>-->
                <!--                  </ng-container>-->
                <!--                </select>-->
                <!--              </div>-->

                <div *ngIf="isWhatsappGroup==false" class="form-group col-lg-12">
                  <label>Recipts <sup class="required">*</sup></label>
                  <textarea style="min-height: 100px" class="form-control" [(ngModel)]="recieptsTo" name="recieptsTo"
                            required>
              </textarea>
                  <div>
                    <div>
                      <label>File <i title="Click Here To Download SampleFile " class="fa fa-question-circle"
                                     (click)="downloadSampleFile()"></i> <small style="color: red">&nbsp;Make sure
                        contact
                        number includes country code e.g. 919988776655</small></label>
                      <div class="form-group col-lg-12">
                        <button type="button" (click)="uploadFileComponent()" class="btn btn-add">Upload File <i
                          class="fa fa-upload"></i></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="form-group col-lg-12">
                <button type="submit" class="btn btn-add" (click)="createCampiagn()">Start Campaign</button>
                <button title="Click Here To Schedule Campaign" id="btnschedule" (click)="campaignScheduled()"
                        class="btn btn-add">Schedule Campaign
                </button>
              </div>
            </form>

          </div>


        </div>


      </div>
    </div>
  </div>

  <div>
    <fieldset>
      <!--      You can use these features-->
      <legend>
        <br>
      </legend>
      <div class="radio-item-container">
        <div *ngIf="this.isFeaturAccess!=null ||  this.isFeaturAccess!=undefined">

          <div class="radio-item" *ngIf="this.isFeaturAccess.type.text==true">
            <label for="textMessage">
              <input type="radio" id="textMessage" name="flavor" [value]="0" [(ngModel)]="contentType">
              <span>Text  Messge<span class="icon">💬</span> </span>
            </label>
          </div>

          <div class="radio-item" *ngIf="this.isFeaturAccess.type.file==true">
            <label for="document">
              <input type="radio" id="document" name="flavor" [value]="1" [(ngModel)]="contentType">
              <span>Document <span class="icon">📄</span></span></label>
          </div>

          <div class="radio-item">
            <label for="button">
              <input type="radio" id="button" name="flavor" value="button" value="2" [(ngModel)]="contentType">
              <span>Template Button <span class="icon">🆗</span></span></label>
          </div>


          <div *ngIf="this.isFeaturAccess.type.template==true">
            <div class="radio-item">
              <label for="Image">
                <input type="radio" id="Image" name="flavor" value="3" [(ngModel)]="contentType">
                <span>Template Image <span class="icon">📷</span></span></label>
            </div>

            <div class="radio-item">
              <label for="video">
                <input type="radio" id="video" name="flavor" value="4" [(ngModel)]="contentType">
                <span>Template Video <span class="icon"> 📹</span></span></label>
            </div>
          </div>
        </div>

        <div *ngIf="isFeaturAccess==undefined || isFeaturAccess==null">

          <div class="radio-item">
            <label for="textMessage1">
              <input type="radio" id="textMessage1" name="flavor" [value]="0" [(ngModel)]="contentType">
              <span>Text  Message<span class="icon">💬</span> </span>
            </label>
          </div>

          <div class="radio-item">
            <label for="document1">
              <input type="radio" id="document1" name="flavor" [value]="1" [(ngModel)]="contentType">
              <span>Document <span class="icon">📄</span></span></label>
          </div>

          <div class="radio-item">
            <label for="button1">
              <input type="radio" id="button1" name="flavor" value="button" value="2" [(ngModel)]="contentType">
              <span>Template Button <span class="icon">🆗</span></span></label>
          </div>

          <div class="radio-item">
            <label for="Image1">
              <input type="radio" id="Image1" name="flavor" value="3" [(ngModel)]="contentType">
              <span>Template Image <span class="icon">📷</span></span></label>
          </div>

          <div class="radio-item">
            <label for="video1">
              <input type="radio" id="video1" name="flavor" value="4" [(ngModel)]="contentType">
              <span>Template Video <span class="icon"> 📹</span></span></label>
          </div>

        </div>

        <hr>

        <div class="radio-item">

          <mat-slide-toggle class="example-margin"
                            [color]="color"
                            [checked]="checked"
                            [disabled]="disabled" [(ngModel)]="active" [disabled]="isWhatsappGroup==true">
            Dynamic Message<span class="icon">✉️</span>
          </mat-slide-toggle>

        </div>

        <div class="radio-item">

          <mat-slide-toggle class="example-margin"
                            [color]="color"
                            [checked]="checked"
                            [disabled]="disabled" [(ngModel)]="customMessageInterval">
            Message (Per Second)<span class="icon">⏰</span>
          </mat-slide-toggle>

        </div>

        <div *ngIf="customMessageInterval == true" class="form-group col-lg-4 adio-item">
          <div class="row">
            <div class="col-lg-8"><input style="width: 200% !important;margin-left: 40px" type="number"
                                         class="form-control" [(ngModel)]="messageSendingSpeed"
                                         name="messageSendingSpeed"></div>
            <div class="col-lg-4"><span style="margin-left: 60px;">Second</span></div>

          </div>


        </div>

        <div class="radio-item">
          <mat-slide-toggle class="example-margin"
                            [color]="color"
                            [checked]="checked"
                            [disabled]="disabled" [(ngModel)]="isWhatsappGroup" [disabled]="active==true">
            Group Message <span class="icon"> 👥</span>
          </mat-slide-toggle>

        </div>

        <div class="radio-item">

          <mat-slide-toggle class="example-margin"
                            [color]="color"
                            [checked]="checked"
                            [disabled]="disabled" [(ngModel)]="removeDuplication">
            Remove Duplicate No. <span class="icon">🚫</span>
          </mat-slide-toggle>

        </div>
      </div>
    </fieldset>

  </div>
</div>







