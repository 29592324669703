<div style="" class="panel-body">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <div id="cardbox3">
        <div class="statistic-box">
          <i class="fa fa-envelope fa-3x"></i>
          <div class="counter-number pull-right">
            <span class="count-number" [innerHTML]="totalMessage"></span>
          </div>
          <h3> Total Message</h3>
        </div>
      </div>
    </div>
  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <div id="cardbox3">
      <div class="statistic-box">
        <i class="fa fa-check fa-3x"></i>
        <div class="counter-number pull-right">
          <span class="count-number" [innerHTML]="dlrMessage"></span>

        </div>
        <h3> Delivered Message</h3>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <div id="cardbox3">
      <div class="statistic-box">
        <i class="fa fa-percent fa-3x"></i>
        <div class="counter-number pull-right">
          <span class="count-number" > {{dlrPer}} %</span>

        </div>
        <h3>Delivered Percentage</h3>
      </div>
    </div>
  </div>



</div>

<div *ngIf="chartData!=null"  class="row">




  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button (click)="filter()" class="btn btn-add">Filter <i class="fa fa-filter"></i></button>
          <button  (click)="clearFilter()"  class="btn btn-add">Clear Filter <i class="fa fa-times-circle"></i></button>

          <button (click)="downloadFile()"  class="btn btn-add">Download Report<i class="fa fa-download"></i></button>


        </div>
      </div>
      <div   style="" class="panel-body">



        <div *ngIf="chartData!=null" class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs">
              <li class="active"><a href="#tab1" data-toggle="tab" aria-expanded="true">Graphical <i
                class="fa fa-bar-chart"></i></a></li>
              <li class=""><a href="#tab2" data-toggle="tab" aria-expanded="false">Tabular <i class="fa fa-table"></i></a></li>
            </ul>
            <!-- Tab panels -->
            <div class="tab-content">
              <div class="tab-pane fade active in" id="tab1">
                <div class="panel-body">
                  <app-chart-and-tables [chartData]="chartData"></app-chart-and-tables>
                </div>
              </div>
              <div class="tab-pane fade" id="tab2">
                <div class="panel-body">


                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover">

                      <thead>
                      <tr>
                        <th>Status</th>
                        <th>Count</th>
                        <th>Percentage (%)</th>


                      </tr>
                      </thead>
                      <tbody>

                      <ng-container *ngFor="let data of fieldTable;let ind = index">

                        <tr>

                          <td [innerHTML]="data._id"></td>
                          <td [innerHTML]="data.count"></td>
                          <td [innerHTML]="calculatePercentage(data.count,totalMessage).toFixed(2)"></td>


                        </tr>
                      </ng-container>


                      </tbody>
                      <tfoot>
                      <tr>



                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>



              <div class="tab-pane fade" id="tab3">
                <div class="panel-body">

                  <form>
                    <div>





                    </div>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
