import { Component, OnInit } from '@angular/core';
import {UserService} from '../Services/User/user.service';
import {ServiceDao, UserType} from '../Services/User/UserMasterDao';
import {MenuDao} from '../Services/User/MenuDao';
import {ServiceMasterService} from '../servicemaster/ServiceMasterService/service-master.service';
import {ServiceMasterDao} from '../servicemaster/ServiceMasterDao';
import {SubscriptionServiceService} from "../subscription/SubscriptionService/subscription-service.service";

@Component({
  selector: 'app-service-selection',
  templateUrl: './service-selection.component.html',
  styleUrls: ['./service-selection.component.css']
})
export class ServiceSelectionComponent implements OnInit {
  serviceArr:Array<ServiceMasterDao>=[];
  constructor(private subscriptionService: SubscriptionServiceService, public user:UserService,private service:ServiceMasterService) {
    this.service.getByRole().subscribe(value => {
      this.serviceArr=value;
    })
  }

  ngOnInit(): void {
  }

  makeDefaultApplication(json:ServiceMasterDao) {
    debugger;
    console.log("The Service is ", json.serviceUrl);
    if(json.serviceUrl == "wabuissness/v1"){
      this.subscriptionService.getCurrentSubscription().subscribe(value => {
        localStorage.setItem("subscriptionDao", JSON.stringify(value));
        console.log("Item Set");
      },error => {
        console.log("Subscription not set");
      });
    }
  this.user.selectService(json);
  }
}
