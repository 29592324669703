

<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">

    <!--    <div class="panel panel-bd lobidragdashboard">-->
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="panel-title">

        </div>
        <button  (click)="filterDatafordate()" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
        <button  (click)="clearFilter()" class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>

        <!--              <button  (click)="loadData()" class="btn btn-add">Show All<i class="fa fa-remove"></i></button>-->
<!--        <button  (click)="downloadReport()" class="btn btn-add">Download Report<i class="fa fa-download"></i></button>-->
        <input  style="float: right"  type="text" name="search" [(ngModel)]="searchText"   placeholder="Type to search here">
      </div>
      <div style="" class="panel-body">
        <div  class="table-responsive">
          <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" >
            <!--          <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">-->
            <thead>
            <tr>
              <th>GroupName</th>
              <th>CampaignName</th>
              <th>Attached File</th>
              <th>Count</th>
              <th>Date</th>
              <th>Action</th>

            </tr>
            </thead>
            <tbody>
            <ng-container  >
              <tr *ngFor=" let user of tableList" >
                <td [innerHTML]="user.groupName"></td>
                <td [innerHTML]="user.campaignName"></td>
                <td [innerHTML]="user.attachedFile"></td>
                <td [innerHTML]="user.count"></td>
                <td [innerHTML]="user.date"></td>
                <td>
                  <i title="Download Report" (click)="downloadCampaignReport(user.id)"
                     class="btn btn-add fa fa-download"></i>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>



