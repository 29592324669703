import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OBDCampaignGroupByDao} from '../obd/OBDCampaignGroupByDao';
import {environment} from '../../../environments/environment.prod';
import {LongCodeCampaignDao} from "./long-code-campaign-dao";
const appprefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class LongCodeCampaignServiceService {

  constructor(private http: HttpClient) { }


  get(): Observable<Array<LongCodeCampaignDao>>
  {
    return this.http.get<Array<LongCodeCampaignDao>>(appprefix + 'digimozo/v1/campaign/get ');
  }
}
