
<h1 mat-dialog-title style="text-align: center; color:  #1c5b7a"><b>File Upload </b><i class="fa fa-cloud-upload"></i> </h1>
<form ngNativeValidate>
  <mat-dialog-content class="mat-typography" >
        <div class="form-group col-lg-12">
          <label>Enter File  <sup class="required">*</sup></label>
      <input (change)="uploadFile($event && $event != 'null' ? $event : null)" type="file" placeholder="Enter File"  required name="file"  class="form-control">

          <!--              <input type="file" class="form-control"   name="file"  (change)="onFileSelect($event && $event != 'null' ? $event : null)" multiple required>-->

        </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>
  </mat-dialog-actions>
</form>
