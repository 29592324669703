<header id="headerdiv" style="position: fixed;width: 100%" class="main-header">
  <a style="background-color: #1c5b7a;cursor: pointer" (click)="changeToIndex()" class="logo">
    <!-- Logo -->
    <span class="logo-mini">
               <img style="max-width: 50px;padding: 10px" [src]="user.accountMasterDao.accountMinLogo" alt="">
               </span>
    <span class="logo-lg">
               <img style="max-width: 100%;padding: 10px" [src]="user.accountMasterDao.accountLogo" alt="">
               </span>
  </a>
  <!-- Header Navbar -->
  <nav style="background-color: #1c5b7a!important;" class="navbar navbar-static-top">
    <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
      <!-- Sidebar toggle button-->
      <span class="sr-only">Toggle navigation</span>
      <!--      <i class="fa-duotone fa-bars"  ></i>-->
      <i style="color: white" class="fa fa-circle-chevron-left"></i>
      <!--      <img src="https://cdn1.iconfinder.com/data/icons/heroicons-ui/24/menu-512.png">-->
      <!--      <span class="pe-7s-angle-left-circle"></span>-->
    </a>
    <!-- searchbar-->
    <!--<div id="search">
      <button type="button" class="close">×</button>
      <form>
        <input type="search" value="" placeholder="type keyword(s) here" />
        <button type="submit" class="btn btn-add">Search...</button>
      </form>
    </div>-->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li *ngIf="conversationList!=null" class="dropdown messages-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-mail"></i>
            <span class="label label-success" *ngIf="conversationList!=null"
                  [innerHTML]="conversationList.length"></span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <div class="slimScrollDiv"
                   style="position: relative; overflow: scroll;overflow-x: hidden; width: auto; height: 200px;">
                <ul class="menu" style="overflow: hidden; width: 100%; height: 200px;">
                  <ng-container *ngFor="let data of conversationList ">
                    <li>
                      <!-- start message -->
                      <a (click)="takeMeToConversation(data)" class="border-gray">
                        <div class="pull-left">
                          <img src="assets/dist/img/avatar.png" class="img-circle" alt="User Image">
                        </div>
                        <h4 [innerHTML]="data.contactName||data.id"></h4>
                        <p [innerHTML]="data.unreadMessage +' New Messsage'"></p>
                        <span class="badge badge-success badge-massege"><small [innerHTML]="data.lastMessage">15 hours ago</small>
                                       </span>
                      </a>
                    </li>
                  </ng-container>
                </ul>
                <div class="slimScrollBar"
                     style="background: rgb(0, 0, 0); width: 3px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 1px;"></div>
                <div class="slimScrollRail"
                     style="width: 3px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
              </div>
            </li>
          </ul>
        </li>
        <li class="dropdown dropdown-user">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img src="assets/dist/img/image12.jpeg" class="img-circle" width="45" height="45" alt="user"></a>
          <ul class="dropdown-menu">
            <li>
              <a routerLink="/accounts/v1/profile">
                <i class="fa fa-user"></i>{{user.user.fullname}} </a>
            </li>
            <li><a (click)="user.logoutOff()"><i class="fa fa-th"></i> Back To Service</a></li>
            <li><a (click)="user.logout()">
              <i class="fa fa-sign-out"></i> Signout</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>
