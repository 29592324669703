// noinspection AngularMissingOrInvalidDeclarationInModule

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams,} from '@angular/common/http';
import {SendmailserviceService} from './sendmailservice.service';


import {AlertMessageService} from '../../alert-message/alert-message.service';
 import {MailgroupsService} from '../mailgroup/mailgroups.service';
import {SendmailDao} from './sendmail-dao';
 import {GroupDao} from '../mailgroup/mailgroupadd/group-dao';
 import {FormBuilder, FormGroup} from '@angular/forms';
 import {UploadFileComponent} from '../../ApplicationDeployement/upload-file/upload-file.component';
import {MatDialog} from '@angular/material/dialog';
import {ScheduleMailComponent} from '../schedule-mail/schedule-mail.component';
 import {EmailObjectDao, EmailObjectDaos} from '../email-object-dao';
import {DriveService} from '../../Drive/drive.service';
import {ResponseDao} from './response-dao';
import {environment} from '../../../environments/environment.prod';

const appPrefix = environment.appUrl + environment.gsmgatewayservice;



@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private service: SendmailserviceService, private alert: AlertMessageService, private service1: MailgroupsService, private formBuilder: FormBuilder, private dialog: MatDialog,private drive: DriveService) {
  }

  // tslint:disable-next-line:max-line-length
  selectedValue: any;

  groupId: number;
  subject: any;
  message: any;
  formData;
  id: number;
  data: SendmailDao;
  tableList: Array<GroupDao>;
  scheduleDate: any;
  fileId:string;
  fileName:string;
  // tslint:disable-next-line:typedef
  uploadForm: FormGroup;
  emailObjectDao: EmailObjectDao;
    arr=[];

  ngOnInit(): void {

    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });
    this.getAll();
  }


  // tslint:disable-next-line:typedef
  getAll() {
    console.log('method is call');
    this.service.getAll().subscribe(value => {

      this.tableList = value;
      console.log(' table list is' + this.tableList);
    });
  }

  // tslint:disable-next-line:typedef
  createOrUpdate(data: SendmailDao) {
    this.alert.showLoader();
    this.alert.confirmMessage('Send  Mail?', 'Are you sure?').then(value => {
      this.service.insert(data).subscribe(value1 => {

        this.alert.showSuccesMessage('Send Successfully !!');
        this.cancel();
        this.alert.hideLoader();
      }, error => {
        console.log(error);
        this.alert.showErrorMessage('Something Went Wrong!!' + error.message);
        this.alert.hideLoader();
      });
    }, );

  }

  // tslint:disable-next-line:typedef
  cancel() {
   this.emailObjectDao=null;
   this.fileId= null,
     this.fileName=null,
     this.scheduleDate=null,
     this.groupId =null,
     this.message =null,
     this.subject= null
    // window.location.reload();
  }

  cancelbtn() {
    window.location.reload();
  }

  // tslint:disable-next-line:typedef
    submitData() {

    console.log('data are' + this.groupId);
    console.log('subject ' + this.subject);
    console.log('message ' + this.message);


      if(this.fileId ==undefined || this.fileName ==undefined)
      {
           this.fileId=null;
           this.fileName=null;
      }

      if (this.scheduleDate ==undefined)
      {
        this.scheduleDate=null;
      }
      if (this.scheduleDate !=undefined || this.scheduleDate !=null)
      {
        this.scheduleDate=this.scheduleDate.replaceAll("T"," ");

      }
     this.emailObjectDao =
    {
    groupId:this.groupId.toString(),
    message:this.message,
    subject:this.subject,
    fileId: this.fileId,
    fileName:this.fileName,
    date: this.scheduleDate,
  }
        this.alert.confirmMessage('Send  Mail?', 'Are you sure?').then(value => {
         this.alert.showLoader();
          this.http.post<ResponseDao>(appPrefix+ 'api/mailSend/bulkMail',  this.emailObjectDao  ).subscribe(value1 => {
            console.log('error',value1)
            this.alert.hideLoader();
            this.alert.showSuccesMessage('Send Successfully !!');
            this.cancel();
            // window.location.reload();
          }, error => {
            this.alert.showErrorMessage(error.error.message);
          });
        },);
      }





  // tslint:disable-next-line:typedef
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);

    }
  }

  // tslint:disable-next-line:typedef
  uploadFileComponent() {
    this.dialog.open(UploadFileComponent).afterClosed().subscribe(value => {


      this.fileId=value.id;
      this.fileName=value.filename;

      console.log('fileess id:: ',this.fileId);
      console.log('fileName  :: ',this.fileName);
      console.log('After upload file:: ',value);
    });
  }


  // tslint:disable-next-line:typedef
  createSchedule() {

    console.log('schedule method calling');
    this.dialog.open(ScheduleMailComponent, {
      width: '40%',
      height: '40%'
    }).afterClosed().subscribe(value => {

      this.scheduleDate = value;
      console.log('scedule date ' + this.scheduleDate);
      console.log('Schedule value getting' + value);
    });
  }


  // tslint:disable-next-line:typedef
   deleteParams(id: string) {
    let ids: number;
    ids=Number(id);
    this.alert.showLoader();
    this.alert.confirmMessage('Delete ' + name + '?', 'Are you sure?').then(value => {
       this.drive.deleteFile(ids).subscribe(value1 => {
         console.log('File Deleted!!')
         this.alert.hideLoader();
         this.alert.showSuccesMessage('File Deleted !!');
         this.cancel();
       })
      console.log('After Delete formdata value:: {}');
      // this.cancell();
    });

  }



}
