import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
// import {ConversationService} from "../../conversation/ConversationService/conversation.service";
import {ConversationService} from "../../AgentSupport/agent-client-conversation/conversation.service";

import {CampaingService} from "../../CampainService/campaing.service";
import {ConversationSubscriptionService} from "../../conversation/ConversationSubscription/ConversationSubscriptionService";
import {Subject, Subscription} from "rxjs";
import {ClientSidePagination} from "../../CommonObj/ClientSidePagination";
import {ConversationDao} from "../../conversation/ConversationDao";
import {MessageDao} from '../../ws-template/MessageDao';
import {ContactFormDao} from "../../conversation/contact-form/ContactFormDao";
import {ContactFormComponent} from "../../conversation/contact-form/contact-form.component";
import {QuickReplyMessageComponent} from "../../quick-reply-message/quick-reply-message.component";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {ActivatedRoute, Router} from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import {ConversationGroup} from "./ConversationGroup";
import {Conversation} from "./Conversation";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {UsermasterService} from "../../Services/UsermasterService/usermaster.service";
import {Agent} from "../AgentMaster/agent-master/AgentDao";
import {AgentServiceService} from "../AgentMaster/agent-service.service";
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {CustomerGroupDao} from "./CustomerGroupDao";



@Component({
  selector: 'app-agent-client-conversation',
  templateUrl: './agent-client-conversation.component.html',
  styleUrls: ['./agent-client-conversation.component.css']
})
export class AgentClientConversationComponent implements OnInit,AfterViewInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {bDestroy: true, order: []};
  textMessage = '';
  agentNumbers:Array<ConversationGroup>;
  agentMasterList: PaginationMain<Agent> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  filteredCustomerList: Array<CustomerGroupDao> = [];

  // clientNumbers:Array<string> =  null;
  customerList:Array<CustomerGroupDao>=[];
  conversationList :Array<Conversation>=[];
  conversation: Conversation = null;
  // conversationList: ClientSidePagination<ConversationDao> = null;

  activeAgentId:string=undefined;
  activeClientNumber:string="";
  activeClientName:string="";

  currentUser:UserMasterDao


  offset = 0;
  limit = 10;
  searchStr = '';
  loadMoreEnable = true;
  isLoadeMore: boolean;

  private subscription: Subscription;


  // tslint:disable-next-line:max-line-length
  constructor(private agentService:AgentServiceService,private userMasterService:UsermasterService,private conversationService: ConversationService,private campservice: CampaingService, private conversationSub: ConversationSubscriptionService, private alertService: AlertMessageService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {

  }

  ngAfterViewInit(): void {
    this.userMasterService.getCurrentUser().subscribe(data => {
      this.currentUser = data;
      console.log("current user", this.currentUser)
      this.agentService.getReport(this.currentUser.accountId,20231123,20231123,0,1000).subscribe(value=>{
        this.agentMasterList=value;
        console.log("agentMasterList:",this.agentMasterList)
      });
    });

  }

  ngOnInit(): void {

    }





  // agentNumberList(){
  //   this.conversationService.getClientNumberByAgent(this.currentUser.accountId).subscribe(value => {
  //     this.agentNumbers=value;
  //     console.log("agentNumbers",this.agentNumbers)
  //   });
  // }

  onSelectAgent(){
    console.log("select Agent",this.activeAgentId);
    this.conversationService.getClientNumberByAgent(this.currentUser.accountId,this.activeAgentId).subscribe(value => {
          this.customerList=value;

      this.filteredCustomerList=this.customerList
      console.log("client number list: of agent ",this.filteredCustomerList)

    });



  }


  //   onSelectAgent(){
  //   console.log("select Agent",this.activeAgentId);
  //   this.activeClientNumber="";
  //   this.conversationList=[];
  //   const filteredGroup = this.agentNumbers.find(group => group._id === this.activeAgentId);
  //   if (filteredGroup) {
  //     this.clientNumbers = filteredGroup.receiverNumbers;
  //   } else {
  //     this.clientNumbers = [];
  //   }
  //   this.filteredPhoneNumbers=this.clientNumbers
  //   console.log("client number list: of agent ",this.clientNumbers,this.activeAgentId)
  //
  //
  //
  // }
  searchString(searchString) {
    console.log("searchString",searchString)
    if(this.containsNumericValue(searchString)){
      this.filteredCustomerList = this.customerList.filter(customer => customer.customerNumbers.includes(searchString));

    }
    else{
      this.filteredCustomerList = this.customerList.filter(customer => customer.customerName.includes(searchString));

    }
  }

  loadMyConversation(customerName:string,customerNumber:string){
    this.activeClientNumber=customerNumber;
    this.activeClientName=customerName

    console.log("load my conversation with agent:",customerNumber,this.activeAgentId)

    this.conversationService.getAllConversationByClientNumber(this.currentUser.accountId,customerNumber).subscribe(value => {
      this.conversationList=value;
      console.log("messagelist",this.conversationList)
    });
  }
   containsNumericValue(str) {
    return /\d/.test(str);
  }

}

