import { Component, OnInit } from '@angular/core';
import {formatDate} from "@angular/common";
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {EnquiryDetailDao} from "../../Nouriture/enquiry-details/EnquiryDetailDao";
import {environment} from "../../../../environments/environment.prod";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {HDFCampDao} from "./HDFCampDao";
import {FileService} from "../../../Services/FileUtilsService/FileService";

@Component({
  selector: 'app-c2c-report',
  templateUrl: './c2c-report.component.html',
  styleUrls: ['./c2c-report.component.css']
})
export class C2cReportComponent implements OnInit {

  tableData: PaginationDao<HDFCampDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public empname:string="0";
  public empcode:string="0";
  public empmsisdn:string="0";
  public startdateStr:string="0";
  public enddateStr:string="0";


  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdateStr == "0" || this.enddateStr == "0") {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }else{
      this.startdate = parseInt(this.startdateStr.replace(/-/g, ''));
      this.enddate = parseInt(this.enddateStr.replace(/-/g, ''));
    }

    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getc2ccdr?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit ).subscribe(value => {
      this.tableData = value;
      console.log(value)
      this.alert.hideLoader();
    });

  }

  filterData() {

  }


  clearFilter() {
    this.empname ="0"
    this.empcode ="0"
    this.empmsisdn ="0"
    this.startdateStr ="0"
    this.enddateStr ="0"
    this.loadData();
  }

  upload() {

  }


  download() {
    this.http.get<PaginationDao<HDFCampDao>>(environment.hdfcBaseUrl + 'hdfccampaign/getc2ccdr?empCode='+this.empcode+'&empName='+this.empname+'&msisdn='+this.empmsisdn+'&campaigntype=c2c&startdate=' + this.startdate + '&enddate=' + this.enddate + '&start=' + this.offset + '&length=' + this.limit).subscribe(value => {
      this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr =["Emp Name","Emp Code","Emp Mobile","Call Status","Call Connect Time","Call Disconnect Time","Duration","Pulses","Sup Number","Sup Call Status","Sup Call Connect Time","Sup Call Disconnect Time","Sup Duration","Sup Pulses"];
      trArr.push(tdArr);

      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].empName);
        tdArr.push(data[i].empCode);
        tdArr.push(data[i].msisdn);
        tdArr.push(data[i].callReason);
        tdArr.push(data[i].callConnecttime);
        tdArr.push(data[i].callDisconnecttime);
        tdArr.push(data[i].callDuration);
       // tdArr.push(data[i].pulses);
        tdArr.push(String(Math.ceil(parseInt(data[i].callDuration) / 30)));
        tdArr.push(data[i].supervisorNumber);
        tdArr.push(data[i].supervisorCallStatus);
        tdArr.push(data[i].supervisorConnectTime);
        tdArr.push(data[i].supervisorDisconnectTime);
        tdArr.push(String(data[i].supervisorDuration));
        tdArr.push(String(data[i].supervisorPulses));
        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }
  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  formatRecordingUrl(url:string):string{

    let serverUrl = "https://prpmobility.com/voice";
    url += url.replace("/VOICEPROMPTS","");
    return url;
  }
}
