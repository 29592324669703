export class ClientSignUpDao {

  id: number;
  companyName: string;
  clientType: string;
  clientName: string;
  accountId:number;
  password:string;
  createBy:string;
  createAt:string;
  active:boolean;
  email:string;
  facebookVerifyStatus:boolean;
  whatsappVerifyStatus:boolean;
  clientNumber:string;
  verifyPhoneStatus:boolean;
  country:string;
  whatsappPhoneId:string;
  whatsappAppId:string;
  whatsappVerifiedToken:string;
  whatsappId:string;
  whatsappNumber:string;
  verifyEmailStatus:boolean;
  defaultSubscription:boolean;
  templateId: string;
  authToken: string;
}
export class EmbeddedSignUpDao{
  tempToken:string
  businessToken:string;
  wabaId:string;
  phoneNumberId:string;
  name:string;
  email:string;
  contactNumber:string;
  displayName: string;
  whatsappNumber: string;
}
