import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SubscriptionTransactionReportDao} from "./SubscriptionTransactionReportDao";
import {FilterDao} from "../crdrreport/FilterDao";
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionTransactionReportService {

  constructor(private http: HttpClient) { }
  get(filterDao: FilterDao):Observable<Array<SubscriptionTransactionReportDao>>{
    return this.http.post<Array<SubscriptionTransactionReportDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/subscriptionpackagetransaction/get",filterDao);
  }
}
