import { Component, OnInit } from '@angular/core';
import {ReportService} from "../../crdrreport/ReportService/report-service.service";
import {DragAndDropChatbotService} from "../drag-and-drop-chatbot.service";
import {ActivatedRoute, Router} from "@angular/router";

import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../../Services/User/user.service";
import {Chatbot} from "../Chatbot";
import {GroupReportDao} from "../../crdrreport/GroupReportDao";
import {UsermasterService} from "../../Services/UsermasterService/usermaster.service";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {DriveMasterDao} from "../../Drive/drive/DriveMasterDao";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {PaginationDao, PaginationMain} from "../../CommonObj/PaginationDao";
import {TokenStoreDaoWaUn} from "../../Unofficial/apikeywaun/TokenStoreDaoWaUn";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment.prod";
import {TokenDao} from "../../api-key/TokenDao";

@Component({
  selector: 'app-drag-and-drop-chatbot',
  templateUrl: './drag-and-drop-chatbot.component.html',
  styleUrls: ['./drag-and-drop-chatbot.component.css']
})
export class DragAndDropChatbotComponent implements OnInit {
  chatbotList: PaginationMain<Chatbot> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  offset: number = 0;
  limit: number = 10;
  currentUser:UserMasterDao;
  chatbot:Chatbot
  token:TokenStoreDaoWaUn={createAt: '', token: ''}
  tokenDao:TokenDao={createAt: '', lastCreateBy: '', token: ''}




  constructor(private http:HttpClient,private alert:AlertMessageService,private userMasterService:UsermasterService,private dropChatbotService: DragAndDropChatbotService, private user: UserService, private route: ActivatedRoute, private router: Router) {


  }
  ngOnInit(): void {
    this.http.get<TokenStoreDaoWaUn>(environment.driveLocationUrl + "gateway/waunofficial/v1/externaltoken/token").subscribe(value => {
      this.token=value;

    })
    this.http.get<TokenDao>(environment.appUrl+environment.whatsappOfficialservice+"externaltoken/").subscribe(value => {
      this.tokenDao=value;

    });


      this.userMasterService.getCurrentUser().subscribe(data => {
      this.currentUser = data;
      console.log("current user",this.currentUser)
      this.loadData();

    });

  }

  // /
  loadData(){
    this.dropChatbotService.getReport(this.currentUser.accountId,this.offset,this.limit).subscribe(data => {
      console.log("data : ",data)
      this.chatbotList=data;
      console.log("chatbotlist:",this.chatbotList)

    });




  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  copy(data:Chatbot)
  {
    this.copyToClipboard(environment.appUrl+environment.dragdropChatbotApiUrl+"/webhook/waun/"+this.token.token+"/"+data.id)
    this.alert.showSuccesMessage("Succesfully Copied Link!!")
  }
  copyWhatsappOfficial(data:Chatbot)
  {
    this.copyToClipboard(environment.appUrl+environment.dragdropChatbotApiUrl+"/webhook/waoff/"+this.tokenDao.token+"/"+data.id)
    this.alert.showSuccesMessage("Succesfully Copied Link!!")
  }
  addNewChatbot(){
    // window.open("http://localhost:36159/?accountId="+this.currentUser.accountId);
    // const headers = new HttpHeaders()
    //   .set('Authorization', 'Bearer your-access-token')
    //   .set('Custom-Header', 'Custom Value');
    //
    // this.http.get(environment.dragdropChatbotUi+"/?accountId="+this.currentUser.accountId, { headers })
    //   .subscribe(
    //     response => {
    //       console.log('Response:', response);
    //
    //
    //     },
    //     error => {
    //       console.error('Error:', error);
    //     }
    //   );

    window.open(environment.dragdropChatbotUi+"/?accountId="+this.currentUser.accountId);

  }
  openMasterEditForm(chatbot:Chatbot){
    console.log("chatbotid",chatbot)

      // window.open("http://localhost:36159/?id="+chatbot.id);

    window.open(environment.dragdropChatbotUi+"/?id="+chatbot.id);

  }

  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }


}
