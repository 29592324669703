
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidrag">
      <div class="panel-heading">
        <div class="header-icon">
<!--          <i class="fa fa-users fa-3x"></i>-->
        </div>
        <div class="header-title" >
          <h1>Contact Groups</h1>
          <button id="addnewgroup" (click)="addGroup()" title="Add New Group" class="btn btn-add">Add Group <i class="hvr-buzz-out fa fa-plus"></i></button>
         </div>
<!--        <input  style="float: right; margin-right: 25px"  type="text" name="search" [(ngModel)]="searchText"   placeholder="Type to search here">-->

      </div>
      <div style="" class="panel-body">

        <div  class="table-responsive">
          <div class="col-sm-12">
<!--            <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" id="table"  >-->
            <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover" id="table"  >
              <thead>
              <tr>
                <!--              <th>Group Id </th>-->
                <th>Group Name</th>
                <th>Group Desc</th>
                <th>Created By</th>
<!--                <th>Count</th>-->
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let group of tableList  ;let ind =index">
                <tr>
                  <!--                                <td [innerHTML]="user.id"></td>-->
                  <td [innerHTML]="group.groupName"></td>
                  <td [innerHTML]="group.groupDesc"></td>
                  <td  [innerHTML]='group.createdBy'></td>
<!--                  <td  [innerHTML]='group.totalUser'></td>-->
                  <td  [innerHTML]='group.status ==true?"<i style=\"margin-left: 5px;  font-size: 18px;color: green\"   class=\"hvr-buzz-out fa fa-check\"></i>":"<i style=\" margin-left: 5px;  font-size: 18px;color: red\"    class=\"hvr-buzz-out fa fa-ban\"></i>"'></td>
                  <td>
                    <button title='Click Here To View Clients inside group' type="button" (click)="save(group.id,group.groupName)" [(ngModel)]="group.id" class="viewgroupdetails btn btn-add btn-sm" ><i class="fa fa-list"></i></button>
                    <button  type="button"  id="edit" class="btn btn-add" (click)="edit(group)" data-toggle="modal" data-target="#exampleModal" title="Edit Group Name"><i class="fa fa-edit"></i></button>
                    <button  type="button"  id="delete" class="btn btn-add"  style="background-color: crimson" (click)="delete(group.id)" title="Delete Group "><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>




    </div>
  </div>
</div>


