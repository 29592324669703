<!--<div class="row">-->
<!--  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">-->
<!--    <div class="panel panel-bd lobidragdashboard">-->
<!--      <div class="panel-heading">-->
<!--        <div class="panel-title">-->

<!--          Credit / Debit Details-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="" class="panel-body">-->
<!--        <form ngNativeValidate (submit)="getBalance()">-->

<!--          <div class="form-group col-lg-6">-->
<!--            <label>Account Id <sup class="required">*</sup></label>-->
<!--            <select class="form-control" name="accountId" required  [(ngModel)]="rechargeDao.accountId">-->
<!--              <option value="undefined"  >-&#45;&#45;&#45;&#45;Select Option-&#45;&#45;&#45;&#45;</option>-->
<!--              <option  *ngFor="let account of tableList " [value]="account.id" >{{account.id}}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="form-group col-lg-6">-->
<!--            <label>Service<sup class="required">*</sup></label>-->
<!--            <select class="form-control" name="service" required [(ngModel)]="rechargeDao.service">-->
<!--              <option value="undefined">-&#45;&#45;&#45;&#45;Select Option-&#45;&#45;&#45;&#45;</option>-->
<!--              <option value="WHATSAPPOFFICIAL">WHATSAPPOFFICIAL</option>-->
<!--              <option value="WHATSAPPUNOFFICIAL">WHATSAPPUNOFFICIAL</option>-->
<!--            </select>-->
<!--          </div>-->

<!--          <div class="form-group col-lg-6">-->
<!--            <label>Start Date<sup class="required">*</sup></label>-->
<!--            <input type="date" class="form-control"  name="startdate" [(ngModel)]="startDate">-->
<!--          </div>-->


<!--          <div class="form-group col-lg-6">-->
<!--            <label>End Date<sup class="required">*</sup></label>-->

<!--            <input type="date" class="form-control"  name="enddate"[(ngModel)]="endDate">-->
<!--          </div>-->


<!--          <button  style="float: right" type="submit" class="btn btn-add">Search</button>-->

<!--        </form>-->
<!--      </div>-->

<!--      <div style="" class="panel-body">-->

<!--        <div  id="tablediv1"  *ngIf="this.accountId!=null && this.accountId!=' ' ">-->
<!--          <div class="table-responsive">-->
<!--            <table class="table table-bordered table-striped table-hover">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th>Account Id</th>-->
<!--                <th>Company Name</th>-->
<!--                <th>Email</th>-->
<!--                <th>Total Credit</th>-->
<!--                <th>Total Debit</th>-->
<!--                <th>Show Details</th>-->


<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <ng-container    >-->
<!--                <tr>-->
<!--                  <td [innerHTML]=" this.accountId"></td>-->
<!--                  <td [innerHTML]="this.name"></td>-->
<!--                  <td [innerHTML]="this.email"></td>-->
<!--                  <td [innerHTML]="this.credit"></td>-->
<!--                  <td [innerHTML]="this.debit"></td>-->
<!--                  <td >-->
<!--                    <a  (click)="getCreditDebit()">{{showDetail}}</a>-->

<!--                  </td>-->


<!--                </tr>-->
<!--              </ng-container>-->


<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div  id="tablediv"  *ngIf="creditdebitDetail.length!=0 ">-->
<!--          <div class="table-responsive">-->
<!--            <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                                        <th>Transaction Id</th>-->
<!--                                        <th>Amount</th>-->
<!--                                        <th>Recharge Type</th>-->
<!--                                        <th>Date</th>-->


<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <ng-container  *ngFor="let data of creditdebitDetail;let ind = index">-->
<!--                <tr>-->
<!--                                         <td [innerHTML]="data.transactionId "></td>-->
<!--                                         <td [innerHTML]="data.amount"></td>-->
<!--                                         <td [innerHTML]="data.rechareType=='RECHARE'?'Credit':'Debit' "></td>-->
<!--                                         <td [innerHTML]="data.createAt"></td>-->

<!--                </tr>-->
<!--              </ng-container>-->


<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--  </div>-->
<!--</div>-->
<!--</div>-->


<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          Credit / Debit Details
        </div>
      </div>
      <div style="" class="panel-body">
        <form ngNativeValidate (submit)="getBalance()">

          <div class="form-group col-lg-6">
            <label>Account Id <sup class="required">*</sup></label>
            <select class="form-control" name="accountId" required [(ngModel)]="rechargeDao.accountId">
              <option *ngIf="user.user.accountType!='USER'" value="undefined">-----Select Option-----</option>
              <option *ngIf="user.user.accountType=='ADMIN'" value="0">Show All</option>
              <option *ngFor="let account of tableList " [value]="account.id">{{account.id}}</option>
            </select>
          </div>
          <div class="form-group col-lg-6">
            <label>Service<sup class="required">*</sup></label>
            <select class="form-control" name="service" required [(ngModel)]="rechargeDao.service">
              <option *ngIf="user.user.accountType!='USER'" value="undefined">-----Select Option-----</option>
              <option *ngFor="let service of services" [value]="service">{{service}}</option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label>Start Date<sup class="required">*</sup></label>
            <input type="date" class="form-control" name="startdate" [(ngModel)]="startDate">
          </div>

          <div class="form-group col-lg-6">
            <label>End Date<sup class="required">*</sup></label>

            <input type="date" class="form-control" name="enddate" [(ngModel)]="endDate">
          </div>
          <button style="float: right" type="submit" class="btn btn-add">Search</button>

        </form>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading">
      </div>
      <div class="panel-body">
        <table class="table table-condensed table-striped">
          <thead>
          <tr>
            <th>Account Id</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Total Credit</th>
            <th>Total Debit</th>
            <th>Show Details</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let data of rechargeList;let indParent = index">
            <tr class="accordion-toggle">
              <td [innerHTML]="data['accountId']"></td>
              <td [innerHTML]="data['name']"></td>
              <td [innerHTML]="data['email']"></td>
              <td [innerHTML]="data['credit']"></td>
              <td [innerHTML]="data['debit']"></td>
              <td>
                <!--                <a class="btn btn-default btn-xs" data-targer='#{{indParent}}}' (click)="data['isExpand'] = !data['isExpand']">-->
                <button class="btn btn-default btn-xs" data-targer='#{{indParent}}}'
                        (click)="getCreditDebit(data['accountId'],indParent);data['isExpand'] = !data['isExpand']">
                  <span *ngIf="!data['isExpand']">Expand</span>
                  <span *ngIf="data['isExpand']">Collapse</span>
                </button>
              </td>
            </tr>

            <tr *ngIf="data['isExpand']" id="{{indParent}}}">
              <td colspan="12" class="hiddenRow">
                <div class="accordian-body  ">
                  <table class="table table-striped">
                    <thead>
                    <tr class="info">
                      <th>Transaction Id</th>
                      <th>Amount</th>
                      <th>Recharge Type</th>
                      <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr data-toggle="collapse" class="accordion-toggle"
                        *ngFor="let data of customerWiseCrDrDetailList[indParent]">
                      <td [innerHTML]="data.transactionId "></td>
                      <td [innerHTML]="data.amount"></td>
                      <td [innerHTML]="data.rechareType=='RECHARE'?'Credit':'Debit' "></td>
                      <td [innerHTML]="data.createAt"></td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

