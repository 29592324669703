
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
                <h4>Session Master</h4>
            </div>
            <button (click)="filterData()"  class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
            <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
            <button (click)="downloadFile()" class="btn btn-add">Download<i class="fa fa-download"></i></button>

          </div>
        </div>

        <div style="" class="panel-body">
          <div class="table-responsive">
            <table  id="tableDiv" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table table-striped row-border hover">
              <thead>
              <tr>
                <th style="overflow-wrap: break-word; white-space: normal;">Id</th>
                <th style="overflow-wrap: break-word; white-space: normal;">Name</th>
                <th style="overflow-wrap: break-word; white-space: normal;">Number</th>
<!--                <th>Account Id</th>-->

                <th>Client Number</th>
                <th>Client Name</th>
                <th>Session Date</th>
                <th>Dispatch Date</th>
                <th>Dispatch Remark</th>
                <th>Delivery Date</th>
                <th>Delivery Remark</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>

              <ng-container *ngFor="let data of sessionList.data;let ind = index">
                <tr>
                  <td  [innerHTML]="data.agentId"  ></td>
                  <td  [innerHTML]="data.agentName"  ></td>
                  <td  [innerHTML]="data.agentNumber"  ></td>
<!--                  <td  [innerHTML]="data.accountId"  ></td>-->

                  <td  [innerHTML]="data.clientNumber"  ></td>
                  <td  [innerHTML]="data.clientName"  ></td>
                  <td  [innerHTML]="data.createDate"  ></td>
                  <td  [innerHTML]="data.dispatchDate"  ></td>
                  <td  [innerHTML]="data.dispatchRemark"  ></td>
                  <td  [innerHTML]="data.deliveryDate"  ></td>
                  <td  [innerHTML]="data.deliveryRemark"  ></td>
                  <td>
                    <i title="Click Here To Check Flow "
                       class="btn btn-add fa fa-eye " (click)="getFlow(data)"></i>&nbsp;&nbsp;
                    <i title="Click Here To Edit Session" class="btn btn-add fa fa-pencil " (click)="updateSessionFormForm(data)"></i>

                  </td>

                </tr>

              </ng-container>
              </tbody>
            </table>
          </div>
        </div>


<!--                <div id="tablediv">-->
<!--          <div class="table-responsive">-->
<!--            <table class="table table-bordered table-striped table-hover">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th style="overflow-wrap: break-word; white-space: normal;">Id</th>-->
<!--                <th style="overflow-wrap: break-word; white-space: normal;">Name</th>-->
<!--                <th style="overflow-wrap: break-word; white-space: normal;">Number</th>-->
<!--                <th>Client Number</th>-->
<!--                <th>Client Name</th>-->
<!--                <th>Session Date</th>-->
<!--                <th>Dispatch Date</th>-->
<!--                <th>Dispatch Remark</th>-->
<!--                <th>Delivery Date</th>-->
<!--                <th>Delivery Remark</th>-->
<!--                <th>Action</th>-->

<!--                &lt;!&ndash;                <th>Type</th>&ndash;&gt;-->


<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr style="text-align: center" *ngIf="sessionList.recordsTotal==0">-->
<!--                <td colspan="14">No Entry</td>-->


<!--              </tr>-->
<!--              <ng-container *ngFor="let data of sessionList.data;let ind = index">-->
<!--                <tr>-->
<!--                  <td  [innerHTML]="data.agentId"  ></td>-->
<!--                  <td  [innerHTML]="data.agentName"  ></td>-->
<!--                  <td  [innerHTML]="data.agentNumber"  ></td>-->
<!--                  <td  [innerHTML]="data.clientNumber"  ></td>-->
<!--                  <td  [innerHTML]="data.clientName"  ></td>-->
<!--                  <td  [innerHTML]="data.createDate"  ></td>-->
<!--                  <td  [innerHTML]="data.dispatchDate"  ></td>-->
<!--                  <td  [innerHTML]="data.dispatchRemark"  ></td>-->
<!--                  <td  [innerHTML]="data.deliveryDate"  ></td>-->
<!--                  <td  [innerHTML]="data.deliveryRemark"  ></td>-->

<!--                  <td>-->
<!--                    <i title="Click Here To Check Flow "-->
<!--                     class="btn btn-add fa fa-eye " (click)="getFlow(data)"></i>&nbsp;&nbsp;-->
<!--                    <i title="Click Here To Edit Session" class="btn btn-add fa fa-pencil " (click)="updateSessionFormForm(data)"></i>-->

<!--                  </td>-->
<!--                </tr>-->
<!--              </ng-container>-->



<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->



<!--          <mat-paginator (page)="tableChange($event)" [length]=sessionList.recordsTotal-->
<!--                         [pageSize]="10"-->
<!--                         [pageSizeOptions]="[2, 5, 25, 100]">-->
<!--          </mat-paginator>-->
<!--        </div>-->



      </div>
    </div>
  </div>


</section>
