import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';
import {PaginationDao} from "../../../CommonObj/PaginationDao";
const  appprefix2 = environment.appUrl;


@Injectable({
  providedIn: 'root'
})
export class LongcodenrServiceService {
  constructor(private http: HttpClient) { }


  getData(startdate: number, enddate: number,offset:number,limit:number): Observable<PaginationDao<any>>
  {
    // tslint:disable-next-line:max-line-length
    return this.http.get<PaginationDao<any>>( appprefix2 + 'digimozo/v1/api/longcodeznpd/get?startdate=' + startdate + '&enddate=' + enddate+'&offset='+offset+'&limit='+limit);
  }

  getNRData(startdate: number, enddate: number, offset: number, limit: number): Observable<PaginationDao<any>> {
    console.log('startDate: ' + startdate + ' endDate: ' + enddate + ' offset: ' + offset + ' limit: ' + limit)
    return this.http.get<PaginationDao<any>>(appprefix2 + 'digimozo/v1/api/longcodenr/get?startdate=' + startdate + '&enddate=' + enddate+'&offset='+offset+'&limit='+limit);
  }

}
