import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-filter-report',
  templateUrl: './filter-report.component.html',
  styleUrls: ['./filter-report.component.css']
})
export class FilterReportComponent implements OnInit {
  startdate: string;
  enddate: string;
  accountId: 0;
  active: boolean;
  constructor(private router: Router) { }

  ngOnInit(): void {

    this.active = false;
  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  filter() {
    // tslint:disable-next-line:max-line-length
    this.router.navigate([], {queryParams: {startdate: this.startdate.split('-').join(''), enddate: this.enddate.split('-').join(''), accountId: this.accountId}});
  }

  // tslint:disable-next-line:typedef
  checkServece() {

    this.active = true;
  }
}
