
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div  class="panel panel-bd lobidragdashboard">
      <div   class="panel-heading">
        <div class="panel-title">
          <button (click)="downloadReport()"  class="btn btn-add">Download  <i class="fa fa-download"></i></button>

        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Bungalow No</th>
              <th>Maintenance Reading</th>
              <th>Description </th>
              <th>Date</th>
<!--              <th>Payment Time</th>-->
<!--              <th>Payment Status</th>-->
              <!--               <th>Action</th>-->
            </tr>
            </thead>
            <tbody>
            <ng-container  *ngFor=" let tableData of this.tableData  ;let ind =index">

              <tr  *ngIf="tableData==null">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr *ngIf="tableData.maintenanceReading!=0">
                <td [innerHTML]="this.tableData.bungalowNo"></td>
                <td [innerHTML]="this.tableData.maintenanceReading"></td>
                <td [innerHTML]="this.tableData.discription"></td>
                <td [innerHTML]="this.tableData.date"></td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>

