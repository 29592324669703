<div class="row">
  <div class="col-sm-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <div class="card-header-headshot"></div>
      </div>
      <div style="height: 300px" class="card-content">
        <div class="card-content-member text-center">
          <h4 class="m-t-0" [innerHTML]="user.user.fullname"></h4>
          <p class="m-t-0" [innerHTML]="user.user.username"></p>
        </div>
        <div class="card-content-languages">
          <div class="card-content-languages-group">
            <div>
              <h4>Email:</h4>
            </div>
            <div>
              <ul>
                <li [innerHTML]="user.user.email">

                  <div class="fluency fluency-4"></div>
                </li> <i (click)="editProfile()" class="btn btn-add fa fa-pencil"></i>
              </ul>
            </div>
          </div>
          <div class="card-content-languages-group">
            <div>
              <h4>Phone:</h4>
            </div>
            <div>
              <ul>
                <li [innerHTML]="user.user.phone"></li> <i (click)="editProfile()" class="btn btn-add fa fa-pencil"></i>

              </ul>
            </div>
          </div>
          <div class="card-content-languages-group">
            <div>
              <h4>Incoming URL:</h4>
            </div>
            <div>
              <ul>
                <li >https://wa.chatmybot.in/gateway/wabuissness/v1/api/webhook/cloudapi/{{user.user.username}}</li> <i class="btn btn-add fa fa-download" (click)="openWebhookDocument()" aria-hidden="true"></i>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="card-footer-stats">

        </div>
        <div class="card-footer-message">
          <h4 style="cursor: pointer" (click)="editPassword()" ><i  class="fa fa-pencil"></i> Change Password</h4>

        </div>
      </div>
    </div>
  </div>

</div>
