import { Component, OnInit } from '@angular/core';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {GroupMasterDao} from '../../../../mailSendingApp/group-master/group-master-dao';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {HttpClient} from '@angular/common/http';
import {SignutraMasterDao} from '../signutra-master-dao';
import {environment} from '../../../../../environments/environment.prod';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.css']
})
export class UploadDataComponent implements OnInit {
  uploadForm: any;
  constructor(private alert: AlertMessageService, private http: HttpClient, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      filetoUploadDrive: ['']
    });
  }



  // tslint:disable-next-line:typedef
  onFileSelect($event: Event) {

    if ((event.target as HTMLInputElement).files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      this.uploadForm.get('filetoUploadDrive').setValue(file);
    }
  }

  // insert Method Stated
  // tslint:disable-next-line:typedef
  submit() {
    const formData = new FormData();
    // formData.append('file', this.uploadForm.get('filetoUploadDrive').value);
    const file = this.uploadForm.get('filetoUploadDrive').value;
    const fileService = new FileService<SignutraMasterDao>();
    const leadJson: Array<SignutraMasterDao> = [];
    fileService.readFile(file).then(value => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < value.length; i++) {
        const leadData: SignutraMasterDao = value[i];
        leadJson.push(leadData);
      }

      console.log('this is lead json ', leadJson);
      this.alert.showLoader();
      this.alert.confirmMessage('Upload Data?', 'Are you sure?').then(value2 => {
        // tslint:disable-next-line:max-line-length
        this.http.post(environment.appUrl + environment.digimozoservice + '/api/longode/upload', leadJson).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + leadJson.length + ' Data ');
          console.log('upload file values is :: ' + leadJson);
          this.alert.showSuccesMessage('Upload Successfully !!');
          this.alert.hideLoader();
          window.location.reload();
        }, error => {
          this.alert.showErrorMessage('Failed To Upload Email!!');
          this.alert.hideLoader();
        });
      });


    });

  }
  // method end

  // tslint:disable-next-line:typedef
  downloadSampleFile() {

    this.alert.showLoader();


    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('retailerCode');
    tdArr.push('salesOfficerName');
    tdArr.push('location');
    tdArr.push('retailerName');
    trArr.push(tdArr);
    tdArr = [];
    tdArr.push('1111');
    tdArr.push('Sujit Yadav');
    tdArr.push('Delhi');
    tdArr.push('Natasha');
    trArr.push(tdArr);
    file.generateFile(trArr, 'SampleBulkUpload.xlsx');
    this.alert.hideLoader();

  }
}
