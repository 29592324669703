<p>app-deployment-tabe works!</p>


<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button    class="btn btn-add">Add  Instance<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">

        </div>


        <div  >


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Instance Name</th>
              <th>Active</th>
              <th>Per day limit</th>
              <th>Proxy Enabled</th>
              <th>Save To Db</th>
              <th>Has Webhook</th>
              <th>Connected</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
<!--            <tr  >-->
<!--              <td colspan="9" style="text-align: center">No Record</td>-->
<!--            </tr>-->
            <ng-container  >
              <tr>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>

