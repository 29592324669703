import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {CatalogMessageDao} from "../catalog-message-dao";
import {Interactive} from "../interactive";
import {Header} from "../header";
import {Body} from "../body";
import {Footer} from "../footer";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {CatalogueMessageServiceService} from "../catalogue-message-service.service";
import {Subject} from "rxjs";
import {Action} from "../action";
import {Sections} from "../sections";
import {ProductItems} from "../product-items";
import {PaginationDao} from "../../CommonObj/PaginationDao";
import {data} from "jquery";

@Component({
  selector: 'app-catalogue-message',
  templateUrl: './catalogue-message.component.html',
  styleUrls: ['./catalogue-message.component.css']
})
export class CatalogueMessageComponent implements OnInit {
  // @Input("catalogMessageDao") catalogMessageDao: CatalogMessageDao;

  tableData: PaginationDao<CatalogMessageDao> = {data: [], total: 0};
  section: Sections = {title: '', product_items: []};
  action: Action = {catalog_id: '', sections: []}
  header: Header = {type: 'text', text: ''}
  body: Body = {text: ''}
  footer: Footer = {text: ''}


  // Definition of Interactive object
  interactive: Interactive = {
    type: 'product_list',
    header: this.header,
    body: this.body,
    footer: this.footer,
    action: this.action
  };

// Definition of CatalogMessageDao object
  catalogueMessageDao: CatalogMessageDao = {
    id: '',
    messaging_product: 'whatsapp',
    to: '',
    type: 'interactive',
    interactive: this.interactive,
    accountId: null,
    date: '',
    dateFilter: 0,
    updateDate: '',
    active: false,
    catalogName: ''
  };


  ADD_PRODUCTS: boolean = false;
  ADDNEWCATALOG: any;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  titleStr: any;
  private isUpdate: boolean = false;

  constructor(private dialog: MatDialog, private alert: AlertMessageService, private service: CatalogueMessageServiceService) {
    this.loadData();
  }

  ngOnInit(): void {
    // this.dtTrigger.unsubscribe();
  }

  loadData() {
    this.service.get().subscribe(value => {
      this.tableData = value
      console.log('data: ', this.tableData)
      // this.dtTrigger.next();
    })
  }


  cancel() {
    this.ADDNEWCATALOG = false;
    this.loadData();
    this.catalogueMessageDao = null;

  }


  isOrUpdate() {
    this.alert.showLoader();
    const confirmMessage = this.isUpdate ? 'Update!' : 'Insert!';

    this.alert.confirmMessage('Are You Sure?', confirmMessage).then(() => {
      const action = this.isUpdate ? this.service.update(this.catalogueMessageDao) : this.service.save(this.catalogueMessageDao);
      action.subscribe(
        (value) => {
          console.log('Updated values', value);
          const successMessage = this.isUpdate ? 'Successfully Update!' : 'Successfully Insert!';
          this.alert.showSuccesMessage(successMessage);
          this.alert.hideLoader();
        },
        (error) => {
          this.alert.showErrorMessage('Something went wrong!');
          this.alert.hideLoader();
          console.log('ERROR OCCURRED: ', error);
        }
      );
      //window.location.reload();
    });
  }


  addNew() {
    this.ADDNEWCATALOG = true;
  }

  addSections() {
    if (this.titleStr.length>0)
    {
      this.catalogueMessageDao.interactive.action.sections.push({title: this.titleStr, product_items: []})
      this.titleStr = "";
      console.log('data saved!');
    }
  }

  addList(ind: number) {
    this.catalogueMessageDao.interactive.action.sections[ind].product_items.push({product_retailer_id: '',quantity:'',item_price:'',currency:'',variantsTitle:''})
  }

  deleteSection(ind: number, indRow: number) {
    this.catalogueMessageDao.interactive.action.sections[ind].product_items.splice(ind, 1);

  }

  edit(data: CatalogMessageDao) {
    this.catalogueMessageDao = data;
    this.ADDNEWCATALOG = true;
    this.isUpdate = true;

  }

  deleteById(id: string) {
    this.alert.confirmMessage('Are You Sure', 'Delete').then(value => {
      this.alert.showLoader();
      this.service.deleteById(id).subscribe(value => {
        console.log('value: ', value);
        this.alert.hideLoader();
        this.alert.showSuccesMessage('Successfully Deleted!');
      },)
    })
  }
}
