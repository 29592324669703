
<!--<div class="row">-->
<!--  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">-->
<!--    <div class="panel panel-bd lobidragdashboard">-->
<!--      <div class="panel-heading">-->
<!--        <div class="panel-title">-->


<!--          <button (click)="addNew()"  class="btn btn-add">Add  Page <i-->
<!--            class="fa fa-plus"></i></button>-->

<!--          <button (click)="addPage()"  class="btn btn-add">Add New Page <i-->
<!--            class="fa fa-plus"></i></button>-->
<!--          <br>-->




<!--        </div>-->
<!--      </div>-->
<!--      <div style="" class="panel-body">-->
<!--        <div  *ngIf="addNewDiv" >-->
<!--          <form ngNativeValidate (submit)="createOrUpdate()">-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label>Account Status  <sup class="required">*</sup></label>-->
<!--              <mat-slide-toggle [(ngModel)]="data.active" name="status"></mat-slide-toggle>-->
<!--            </div>-->


<!--            <div class="form-group col-lg-12">-->
<!--              <label>Account Id  <sup class="required">*</sup></label>-->
<!--              <input type="text" [(ngModel)]="data.accountId" class="form-control"  name="accountId" required>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label>Page name  <sup class="required">*</sup></label>-->
<!--              <input type="text" [(ngModel)]="data.pageName" class="form-control"  name="pageName" required>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <button type="submit" class="btn btn-add">Save</button>-->
<!--              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--        <div *ngIf="!addNewDiv">-->
<!--        <div *ngIf="!addNewDiv1">-->


<!--          <table  class="table table-striped row-border hover">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>Account Id</th>-->
<!--              <th> Page</th>-->
<!--              <th> Status</th>-->
<!--              <th>Action</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <ng-container *ngFor="let data of tableList;let ind =index">-->
<!--              <tr>-->
<!--                <td [innerHTML]="data.accountId"></td>-->
<!--                <td [innerHTML]="data.pageName"></td>-->
<!--                 <td ><i *ngIf="data.active== true" style="color: green" class="fa fa-check"></i><i *ngIf="data.active== false" style="color: red" class="fa fa-remove"></i></td>-->

<!--                 <div *ngIf="!addNewDiv1">-->
<!--                <td>-->
<!--                  <i title="Click Here To Edit" (click)="editDao(data,ind)"-->
<!--                       class="btn btn-add fa fa-pencil"></i>-->

<!--                     <i _ngcontent-vmw-c175="" title="click here to give permission" (click)="edit(data,ind)" class="btn btn-add fa fa-key"></i>-->

<!--                     <i _ngcontent-vmw-c175="" title="click here delete" (click)="delete(data.id)" class="btn btn-add fa fa-trash"></i>-->
<!--                   </td>-->
<!--                 </div>-->
<!--              </tr>-->


<!--            </ng-container>-->
<!--            </tbody>-->
<!--          </table>-->

<!--        </div>-->
<!--        </div>-->

<!--&lt;!&ndash;        =================================add page permission==============================&ndash;&gt;-->

<!--        <div *ngIf="addNewDiv1">-->

<!--          <form ngNativeValidate (submit)="createOrUpdatePage()">-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label>Create  <sup class="required">*</sup></label>-->
<!--              <mat-slide-toggle [(ngModel)]="pageDao.create" name="create"></mat-slide-toggle>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label>View  <sup class="required">*</sup></label>-->
<!--              <mat-slide-toggle [(ngModel)]="pageDao.read" name="read"></mat-slide-toggle>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label>Update  <sup class="required">*</sup></label>-->
<!--              <mat-slide-toggle [(ngModel)]="pageDao.update" name="update"></mat-slide-toggle>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <label> Delete <sup class="required">*</sup></label>-->
<!--              <mat-slide-toggle [(ngModel)]="pageDao.delete" name="delete"></mat-slide-toggle>-->
<!--            </div>-->

<!--            <div class="form-group col-lg-12">-->
<!--              <button type="submit" class="btn btn-add">Save</button>-->
<!--              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--&lt;!&ndash;        =============================end page permission======================================&ndash;&gt;-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->




<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


<!--          <button (click)="addNew()"  class="btn btn-add">Add  Page <i-->
<!--            class="fa fa-plus"></i></button>-->

          <button (click)="addPage()"  class="btn btn-add">Add New Page <i
            class="fa fa-plus"></i></button>
          <br>

        </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
              <thead>
              <tr>
                <th>Account Id</th>
                <th>Menu</th>
                <th> Page Name</th>
                <th> Status</th>
                <th> Description</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let data of tableList;let ind =index">

                <tr *ngIf="tableList.length==0">
                  <td colspan="9" style="text-align: center">No Record</td>
                </tr>
                <tr>
                  <td [innerHTML]="data.accountId"></td>
                  <td [innerHTML]="data.menu"></td>
                  <td [innerHTML]="data.pageName"></td>
                  <td ><i *ngIf="data.active== true" style="color: green" class="fa fa-check"></i><i *ngIf="data.active== false" style="color: red" class="fa fa-remove"></i></td>
                  <td [innerHTML]="data.description"></td>

<!--                  <div *ngIf="!addNewDiv1">-->
                    <td>
                      <i title="Click Here To Edit" (click)="editDao(data,ind)"
                         class="btn btn-add fa fa-pencil"></i>

<!--                      <i _ngcontent-vmw-c175="" title="click here to give permission" (click)="edit(data,ind)" class="btn btn-add fa fa-key"></i>-->

                      <i _ngcontent-vmw-c175="" title="click here delete" (click)="delete(data.id)" class="btn btn-add fa fa-trash"></i>
                    </td>
<!--                  </div>-->
                </tr>


              </ng-container>
              </tbody>
            </table>

      </div>
      </div>
      </div>
      </div>
      </div>
