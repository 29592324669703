import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Agent} from "../../AgentMaster/agent-master/AgentDao";
import {UserService} from "../../../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {AgentServiceService} from "../../AgentMaster/agent-service.service";
import {ClientDao} from "../ClientDao";
import {ClientMasterService} from "../client-master.service";

@Component({
  selector: 'app-client-master-form',
  templateUrl: './client-master-form.component.html',
  styleUrls: ['./client-master-form.component.css']
})
export class ClientMasterFormComponent implements OnInit {

  @Output() public eventEmitter = new EventEmitter<string>();

  clientMasterDao: ClientDao;
  submitBtnTxt: String = 'Create';

  constructor(public user: UserService,
              private http: HttpClient,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private alert: AlertMessageService,
              private router: Router,
              private clientMasterService: ClientMasterService,
              @Inject(MAT_DIALOG_DATA) public data) {
    console.log("clientdao :",data.clientDao)
    // @ts-ignore
    this.clientMasterDao = data.clientDao;
    // @ts-ignore
    this.submitBtnTxt = data.submitBtnTxt;
  }

  ngOnInit(): void {
  }


  submitMasterForm(clientMasterDao: ClientDao) {
    console.log("clientMasterDao:", clientMasterDao,this.submitBtnTxt)

    if (clientMasterDao.clientName == '') {
      this.alert.showErrorMessage('');
      return;

    }

    if (this.submitBtnTxt == "Create") {
      console.log("create:")

      this.clientMasterService.insert(clientMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('create')
        this.closeFlowForm();
      });
    } else {
      this.clientMasterService.update(clientMasterDao).subscribe(value => {
        this.alert.showSuccesMessage("Success")
        this.eventEmitter.emit('update')
        this.closeFlowForm();
      });
    }

    //

  }


  closeFlowForm() {
    console.log("close master flow form")
    // @ts-ignore
    this.dialog.closeAll(ClientMasterFormComponent, {
      width: '830px',
      height: '600px'


    });
  }
}
