import { Component, OnInit } from '@angular/core';
import {PaginationDao} from '../../../../CommonObj/PaginationDao';
import {EmpReportDao} from '../empreport/EmpReportDao';
import {UserMasterDao} from '../../../../Services/User/UserMasterDao';
import {ProductMasterDao} from '../productmaster/ProductMasterDao';
import {HttpClient} from '@angular/common/http';
import {UsermasterService} from '../../../../Services/UsermasterService/usermaster.service';
import {UserService} from '../../../../Services/User/user.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertMessageService} from '../../../../alert-message/alert-message.service';
import {environment} from '../../../../../environments/environment.prod';
import {DatefilterComponent} from '../../../../CommonPopups/datefilter/datefilter.component';
import {FileService} from '../../../../Services/FileUtilsService/FileService';
import {EmpreportGroupDao} from './EmpreportGroupDao';
import {ChartDataDao} from '../../../../chart-and-tables/ChartDataDao';
import {ClientSidePagination} from '../../../../CommonObj/ClientSidePagination';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  fullname:string;
  mobile:string;

  zoneArr:ClientSidePagination<EmpreportGroupDao>=undefined;
  teamArr:ClientSidePagination<EmpreportGroupDao>=undefined;

  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  managerId: number=0;
  userList: Array<UserMasterDao> = [];
  team: string="0";
  brand:string="0";
  show: string="TRUE";
  totalBrands:number=0;



  public userMap: Map<number, UserMasterDao>;
  listproduct:Array<ProductMasterDao>=[];

  getUserDetails(id:number):UserMasterDao
  {

    return  this.userMap.get(id)
  }
  constructor(private http: HttpClient,private userService:UsermasterService, private user: UserService, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {

    this.userMap=new Map<number, UserMasterDao>();
    for(var i=0;i<this.user.reportingList.length;i++)
    {
      this.userMap[this.user.reportingList[i].id]=this.user.reportingList[i];
      this.userMap.set(this.user.reportingList[i].id,this.user.reportingList[i]);
    }







  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];


      this.userList =[];

      this.userList =this.user.reportingList;
      this.userList.unshift({
        accountId: 0,
        hasTwoStepAuthencation:false,
        otpVerified:false,
        accountMasterDao: undefined,
        accountType: '',
        active: false,
        createAt: '',
        createBy: '',
        email: '',
        fullname: 'Show All',
        id: 0,
        password: '',
        permissions: undefined,
        phone: '',
        services: undefined,
        servicesList: '',
        updateAt: '',
        updateBy: '',
        username: '',
        enableAgentSupport:false,
        agentStatus:false
      })

      this.loadData();
      /*  if(this.user.user.accountType=="ADMIN") {
          this.userService.getAll().subscribe(value => {
            this.userList = value;
            this.loadData();
          });
        }else{
          this.userList.push(this.user.user);
          this.loadData();
        }*/

    });
  }

  loadData() {
    this.alert.showLoader();
    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = 20101010;
      this.enddate = 20301010;
    }
    let userList="0";
    var arr=[];
    if(this.managerId==0)
    {
      for(var i=0;i<this.userList.length;i++)
      {
        arr.push(this.userList[i].id);
      }
      userList=arr.join(",");
    }
    else{
      userList=this.managerId.toString();
    }
    this.http.get<Array<EmpreportGroupDao>>(environment.appUrl + environment.gsmgatewayservice + 'hcns/report/groupby?zone=2323&startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit+"&reportId="+this.managerId+"&team="+this.team+"&show="+this.show+"&brand="+this.brand+"&userlist="+userList).subscribe(value => {
      let zoneArr:Array<EmpreportGroupDao>=[];
      let teamArr:Array<EmpreportGroupDao>=[];
      this.totalBrands=0;


      for(var i=0;i<value.length;i++)
      {
        switch (value[i].typ)
        {
          case "Team":
            teamArr.push(value[i]);
            break;

          case "ZONE":
            zoneArr.push(value[i]);
            break;
          case "TOTAL":
            this.totalBrands=value[i].count;
            break;

        }


      }
      this.totalBrands=this.totalBrands/2;
      this.zoneArr={data:zoneArr,start:0,end:10,total:zoneArr.length}
      this.teamArr={data:teamArr,start:0,end:10,total:teamArr.length}


      this.alert.hideLoader();
    });

  }
  calculatePercentage(count:number,total:number)
  {


    return ((count/total) * 100).toFixed(2) + '%'

  }

  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  tableChange(json,dataChange:ClientSidePagination<any>) {

    dataChange.start=json.pageIndex*json.pageSize;
    dataChange.end=dataChange.start+json.pageSize;
    console.log(dataChange)


  }

  downloadFile() {
      var trArr=[];
      var tdArr=[];
      tdArr.push("Zone Wise Report");
      trArr.push(tdArr);
    tdArr=[];
    tdArr.push("Value");
    tdArr.push("Count");
    tdArr.push("Percentage");
    trArr.push(tdArr);
      for(var i=0;i<this.zoneArr.total;i++)
      {
        tdArr=[];
        tdArr.push(this.zoneArr.data[i].value);
        tdArr.push(this.zoneArr.data[i].count);
        tdArr.push(this.calculatePercentage(this.zoneArr.data[i].count,this.totalBrands));
        trArr.push(tdArr);
      }


    tdArr=[];
    tdArr.push("Team Wise Report");
    trArr.push(tdArr);

    for(var i=0;i<this.teamArr.total;i++)
    {
      tdArr=[];
      tdArr.push(this.teamArr.data[i].value);
      tdArr.push(this.teamArr.data[i].count);
      tdArr.push(this.calculatePercentage(this.teamArr.data[i].count,this.totalBrands));
      trArr.push(tdArr);
    }
      let file = new FileService<any>();
      file.generateFile(trArr, 'Report_Summary.xlsx');





  }


  refreshChange() {
    this.offset=0;
    this.limit=10;
    for(var i=0;i<this.userList.length;i++)
    {
      if(this.managerId==this.userList[i].id)
      {
        this.fullname=this.userList[i].fullname;
        this.mobile=this.userList[i].phone;
      }
    }
    this.loadData();
  }

}
