import { Component, OnInit } from '@angular/core';
import {AccountMasterService} from "../AccountMasterService/account-master.service";
import {ConversationDao} from "../../MicroServices/Chatbot/ChatbotConversationFlow/conversation-flow/ConversationDao";
import {environment} from "../../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../../Services/User/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {AccountTransactionReportDao} from "../AccountTransactionReportDao";
import {ChatbotMasterDao} from "../../MicroServices/Chatbot/chatbot-master/ChatbotMasterDao";
import {formatDate} from "@angular/common";
import {DatefilterComponent} from "../../CommonPopups/datefilter/datefilter.component";
import {PaginationDao} from "../../CommonObj/PaginationDao";
import {CigarateDao} from "../../MicroServices/Digimozo/Cigarate/cigarate-panel-dash/CigarateDao";
import {FileService} from "../../Services/FileUtilsService/FileService";
import {AccountMasterDao} from "../AccountMasterDao";

@Component({
  selector: 'app-reseller-stats',
  templateUrl: './reseller-stats.component.html',
  styleUrls: ['./reseller-stats.component.css']
})
export class ResellerStatsComponent implements OnInit {
  tableData: Array<AccountMasterDao> = [];
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;

  constructor(private accountMasterService:AccountMasterService,private user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
  })}
  loadData() {
     // this.alert.showLoader();
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    console.log("currentdate:",date)
    let y = date.getFullYear()
    let m = date.getMonth();
    let d = date.getDate();

    var currentDay = new Date(y, m, d);

    const currentDate = formatDate(currentDay, format, locale);


    console.log("startDate",currentDate)


    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = Number(currentDate);
      this.enddate = Number(currentDate)}


    console.log("log in account id :",this.user.accountMasterDao.id,this.startdate,this.enddate)
    this.accountMasterService.getResellerStats(this.user.accountMasterDao.id,this.startdate,this.enddate,this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      console.log("load resellerstats",value)

    });
  }

  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  downloadFile(){

    this.accountMasterService.getResellerStats(this.user.accountMasterDao.id,this.startdate,this.enddate,this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      console.log("load resellerstats",value)
      var data = value;
      console.log("DOWNLOADED DATA")
      var trArr = [];
      var tdArr = [];
      tdArr.push('account-Id');
      tdArr.push('name');
      tdArr.push('Acc. Type');
      tdArr.push('Total Msg');
      tdArr.push('Sent Msg');
      tdArr.push('Rem Msg');
      tdArr.push('Connnected');
      tdArr.push('Expiry');
      tdArr.push('Service Type');
      tdArr.push('Total Credit');
      tdArr.push('Total Debit');
      tdArr.push('Balance');

      trArr.push(tdArr);
      console.log("length of data",data.length)
      for (var i = 0; i < data.length; i++) {
        console.log("accccoontID:",data[i].id)
        tdArr = [];
        tdArr.push(data[i].id);

        if(data[i].accountName!=null ||data[i].accountName!=undefined){
          tdArr.push(data[i].accountName);

        }
        else{
          tdArr.push("NA")

        }
        if(data[i].services[0]!=null){
          tdArr.push(data[i].services[0].whatsappCreditDao.accountType);

        }
        else{
          tdArr.push("NA")

        }
        if(data[i].resellerReportDao!=null){
          tdArr.push(data[i].resellerReportDao.totalMsg);

        }
        else{
          tdArr.push("0")
        }

        if(data[i].resellerReportDao!=null){
          tdArr.push(data[i].resellerReportDao.totallSentMsg);

        }
        else{
          tdArr.push("0")
        }
        if(data[i].resellerReportDao!=null){
          tdArr.push(data[i].resellerReportDao.totalRemMsg);

        }
        else{
          tdArr.push("0")
        }

        if(data[i].resellerReportDao!=null){
          // @ts-ignore
          if(data[i].resellerReportDao.connected==1){
            tdArr.push("Connected");

          }
          else{
            tdArr.push("Not Connected");

          }

        }
        else{
          tdArr.push("NA")
        }
        if(data[i].resellerReportDao!=null){
          tdArr.push(data[i].resellerReportDao.expiry);

        }
        else{
          tdArr.push("NA")
        }



        if(data[i].resellerReportDao!=null){
          tdArr.push(data[i].resellerReportDao.serviceType);

        }
        else{
          tdArr.push("NA")
        }

        if(data[i].accountTransactionReportDao!=null){
          tdArr.push(data[i].accountTransactionReportDao.credit);

        }
        else{
          tdArr.push("NA")

        }
        if(data[i].accountTransactionReportDao!=null){
          tdArr.push(data[i].accountTransactionReportDao.debit);

        }
        else{
          tdArr.push("NA")

        }
        if(data[i].accountTransactionReportDao!=null){
          tdArr.push(data[i].accountTransactionReportDao.balance);

        }
        else{
          tdArr.push("NA")

        }


        trArr.push(tdArr);


      }
        let file = new FileService<any>();
        console.log("Sending file for xlsx creation")
        file.generateFile(trArr, 'ReportDownload.xlsx');
        trArr=[];

    });
  }


  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  getInnerHtml(data: AccountMasterDao,field:string) {
    if (field == 'totalMsg') {
      if (data.resellerReportDao != null) {
        return data.resellerReportDao.totalMsg
      } else {
        return '0'
      }
    }
    else if (field == 'accountName') {
      if (data.accountName != null || data.accountName!=undefined) {
        return data.accountName
      } else {
        return 'NA'
      }
    }
    else if (field == 'totalSent') {
      if (data.resellerReportDao != null) {
        return data.resellerReportDao.totallSentMsg
      } else {
        return '0'
      }
    } else if (field == 'totalRem') {
      if (data.resellerReportDao != null) {
        return data.resellerReportDao.totalRemMsg
      } else {
        return '0'
      }
    }
    else if (field == 'phoneNumber') {
      if (data.resellerReportDao != null) {
        console.log("phone number",data.resellerReportDao.phoneNumber)
        if(data.resellerReportDao.phoneNumber!=null){
          return data.resellerReportDao.phoneNumber

        }
      else {
        return 'NA'
        }
      } else {
        return 'NA'
      }
    }
    else if (field == 'connected') {
      if (data.resellerReportDao != null) {
        if (data.resellerReportDao.connected) {
          return 'color-green fa fa-check '
        } else {
          return 'fa fa-close color-red'
        }
      } else {
        return 'fa fa-close color-red '
      }
    } else if (field == 'expiry') {
      if (data.resellerReportDao != null) {
        return data.resellerReportDao.expiry
      } else {
        return 'NA'
      }
    }
    else if (field == 'accountType') {
      if (data.services[0] != null) {
        return data.services[0].whatsappCreditDao.accountType
      } else {
        return 'NA'
      }
    }
    else if (field == 'type') {
      if (data.resellerReportDao != null) {
        return data.resellerReportDao.serviceType
      } else {
        return 'NA'
      }
    }
    else if (field == 'balance') {
      if (data.accountTransactionReportDao != null) {
        return data.accountTransactionReportDao.balance
      } else {
        return '0'
      }
    }
   else if (field == 'debit') {
      if (data.accountTransactionReportDao != null) {
        return data.accountTransactionReportDao.debit
      } else {
        return '0'
      }
    }
    else if (field == 'credit') {
      if (data.accountTransactionReportDao != null) {
        return data.accountTransactionReportDao.credit
      } else {
        return '0'
      }
    } else {
    }
  }}


