<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
                <div class="panel-title">
                    Reseller Scheduled Report
                </div>
            </div>
            <div class="panel-body">
                <form ngNativeValidate (submit)="getScheduledReport()">

                    <div class="form-group col-md-4">
                        <label>Account Id <sup class="required">*</sup></label>
                        <select class="form-control" name="accountId" [(ngModel)]="selectedAccountId" required>
                            <option  value="0">-----Select Option-----
                            </option>
                            <option *ngFor="let account of clientsDdl " [value]="account.clientId">{{account.clientId}}</option>
                        </select>
                    </div>





                    <button style="margin-top: 23px;" type="submit" class="btn btn-add">Search</button>

                </form>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
            </div>
            <div class="panel-body">

                <div id="tablediv">



                    <div class="table-responsive">
                        <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable
                            class="table table-striped row-border hover">
                            <thead>
                                <tr>
                                    <th>Campaign Name</th>
                                    <th>Reciepts</th>
                                    <th>Created At</th>
                                    <th>Scheduled Time</th>

                                    <th>Template Id/Catalogue</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let campaign of tableList;let ind =index">
                                    <tr>
                                        <td [innerHTML]="campaign.campaingName"></td>
                                        <td [innerHTML]="campaign.totalReciepts"></td>
                                        <td [innerHTML]="campaign.createAt"></td>
                                        <td [innerHTML]="campaign.scheduledTime"></td>
                                        <td [innerHTML]="campaign.template"></td>
                                    </tr>


                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
