import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {MailgroupsService} from '../mailgroup/mailgroups.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DatePipe, formatDate} from '@angular/common';

@Component({
  selector: 'app-schedule-mail',
  templateUrl: './schedule-mail.component.html',
  styleUrls: ['./schedule-mail.component.css']
})
export class ScheduleMailComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private service: MailgroupsService, public dialogRef: MatDialogRef<ScheduleMailComponent>, private router: Router) {



    //
 this.date = moment().format('DD/MM/YYYY');
 // this.currentDate = moment().format('dd MMM yyyy hh:mm a');
    // tslint:disable-next-line:triple-equals
 if (this.day == 0)
    {
        this.WeekDay = 'Sunday';
    }

    // tslint:disable-next-line:triple-equals
 if (this.day == 1)
    {
      this.WeekDay = 'Monday';
    }

    // tslint:disable-next-line:triple-equals
 if (this.day == 2)
    {
      this.WeekDay = 'Tuesday';
    }
    // tslint:disable-next-line:triple-equals
 if (this.day == 3)
    {
      this.WeekDay = 'Wednesday';
    }
    // tslint:disable-next-line:triple-equals
 if (this.day == 4)
    {
      this.WeekDay = 'Thusday';
    }
    // tslint:disable-next-line:triple-equals
 if (this.day == 5)
    {
      this.WeekDay = 'Friday';
    }
    // tslint:disable-next-line:triple-equals
 if (this.day == 6)
    {
      this.WeekDay = 'Saturday';
    }

 this.now.setDate( this.now.getDate() + 1);
 this.tomorrow.setHours(10);
 this.tomorrow.setMinutes(0);
 this.tomorrow.setMilliseconds(0);

 this.afternoon.setDate(this.afternoon.getDate());
 this.afternoon.setHours(14);
 this.afternoon.setMinutes(0);
 this.afternoon.setMilliseconds(0);
 console.log('now date :: ' + this.now);
 console.log('now date :: ' + this.day);

  }

  WeekDay: string;
  currentDate = new Date();
  dd = this.currentDate.getDay();
  MM = this.currentDate.getMonth();
  yyy = this.currentDate.getFullYear();
  hh = this.currentDate.getHours();
  mm = this.currentDate.getMinutes();
  ss = this.currentDate.getSeconds();

  today =  new Date();
  afternoon =  new Date();
  day = this.today.getDay();
  tomorrow =  new Date(this.today.setDate(this.today.getDate() + 1));
  date: string;
  prevMonday = new Date(this.today.getDate() - (this.today.getDay() + 6) % 7);
   now = new Date();
   addNewDv = false;

  // tslint:disable-next-line:typedef
    isEditable = true;

  // tslint:disable-next-line:typedef
  startTimeStr: any;

  // tslint:disable-next-line:typedef
  startdate: any;





ngOnInit(): void {
  }


  // tslint:disable-next-line:typedef
  afternoonValue() {
  console.log('afternoon datefile date' + this.afternoon);
  this.dialogRef.close(this.afternoon);
  }

  // tslint:disable-next-line:typedef
  currentValue() {



    console.log('afternoon datefile date' + this.tomorrow);
    this.dialogRef.close(this.tomorrow);

  }

  // tslint:disable-next-line:typedef
  tomorrowValue() {

    console.log('afternoon datefile date' + this.tomorrow);
    this.dialogRef.close(this.tomorrow);
  }
  // tslint:disable-next-line:typedef
  pickDateAndTime() {
  this.addNewDv = true;
  console.log('method is calling');
   // this.router.navigate(['datePicker']);
  }
  // tslint:disable-next-line:typedef
  filter() {

    const format = 'ddMMyyy HHmmss';
    const locale = 'en-IN';
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const HH = date.getHours();
    const MM = date.getMinutes();
    const ss = date.getSeconds();
    const formattedDateFirst = formatDate(firstDay, format, locale, locale);
    console.log('afternoon datefile date' + this.startdate);
    this.dialogRef.close(this.startdate);
  }

  onClose() {

  }
}


