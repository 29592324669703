import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-common',
  templateUrl: './filter-common.component.html',
  styleUrls: ['./filter-common.component.css']
})
export class FilterCommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
