
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">

    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Module<i
            class="fa fa-plus"></i></button>

          <br>
          <ul class="nav nav-tabs">

            <ng-container *ngFor="let data of serviceList;let ind =index">
            <li *ngIf="ind==0"  (click)="changeServiceId(data.id,data.serviceName)" class="active"><a href="#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.serviceName"></a></li>
              <li *ngIf="ind>0"  (click)="changeServiceId(data.id,data.serviceName)" class=""><a href="#{{data.id}}" data-toggle="tab" aria-expanded="false" [innerHTML]="data.serviceName"></a></li>

            </ng-container>
          </ul>



        </div>
      </div>
      <div style="" class="panel-body">


        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Show On Menu  <sup class="required">*</sup></label>
              <mat-slide-toggle [(ngModel)]="data.showOnMenu" name="showOnMenu"></mat-slide-toggle>
            </div>


            <div class="form-group col-lg-12">
              <label>Module name  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="data.moduleName" name="moduleName" required>
            </div>


            <div class="form-group col-lg-12">
              <label>Module Url  <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="data.moduleUrl" name="moduleUrl" required>
            </div>


            <div *ngIf="data.showOnMenu" class="form-group col-lg-12">
              <label>Menu  <sup class="required">*</sup></label>
              <select  class="form-control" [(ngModel)]="data.menuId" name="menuId" required>
                <ng-container *ngFor="let data of menuList">
                  <option [value]="data.id" [innerHTML]="data.name"></option>
                </ng-container>
              </select>
            </div>


            <div *ngIf="urlMasterArray!=null" class="form-group col-lg-12">
              <div class="table-responsive">
                <table  class="table table-striped row-border hover">
                  <thead>
                  <tr>
                    <th>Url Alias</th>
                    <th>Action Id</th>

                    <th> Role</th>

                    <th>Url</th>
                    <th>Action <i (click)="addUrlMaster()" title="Click Here To Add Url" class="btn btn-add fa fa-plus-circle"></i></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let data of urlMasterArray;let ind =index">
                    <tr>
                    <td>
                      <input type="text" [(ngModel)]="data.actionAlias" name="actionAlias{{ind}}" required>
                    </td>
                      <td>
                        <input disabled type="number" [(ngModel)]="data.actionId" name="action{{ind}}" required>
                      </td>
                      <td>
                        <input disabled type="text" [(ngModel)]="data.role" name="role{{ind}}" required>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="data.url" name="url{{ind}}" required>
                      </td>
                      <td><i (click)="deleteRow(ind)" class="btn btn-add fa fa-trash"></i></td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>












            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Module Name</th>
              <th>Module Url</th>

              <th> Show On Menu</th>

              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.moduleName"></td>
                <td [innerHTML]="data.moduleUrl"></td>

                <td ><i *ngIf="data.showOnMenu" style="color: green" class="fa fa-check"></i><i *ngIf="!data.showOnMenu" style="color: red" class="fa fa-remove"></i></td>

                <td><i title="Click Here To Edit" (click)="edit(data,ind)"
                       class="btn btn-add fa fa-pencil"></i>


                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







