import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStoreDaoWaUn} from './TokenStoreDaoWaUn';
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-apikeywaun',
  templateUrl: './apikeywaun.component.html',
  styleUrls: ['./apikeywaun.component.css']
})
export class ApikeywaunComponent implements OnInit {

  constructor(private http:HttpClient) { }
  token:TokenStoreDaoWaUn={createAt: '', token: ''}

  ngOnInit(): void {
    this.http.get<TokenStoreDaoWaUn>(environment.driveLocationUrl + "gateway/waunofficial/v1/externaltoken/token").subscribe(value => {
      this.token=value;
    })
  }

}
