import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {PaginationMain} from "../CommonObj/PaginationDao";
import {Agent} from "../AgentSupport/AgentMaster/agent-master/AgentDao";
import {environment} from "../../environments/environment.prod";
import {ExtensionWiseAgent} from "./ExtensionWiseAgent";
import * as Http from "http";
import {HttpClient} from "@angular/common/http";
import {ChatbotMasterDao} from "../MicroServices/Chatbot/chatbot-master/ChatbotMasterDao";

@Injectable({
  providedIn: 'root'
})
export class ExtensionWiseAgentService {

  constructor(private http:HttpClient) { }


  getReport(accountId: number, offset: number, limit: number): Observable<PaginationMain<ExtensionWiseAgent>> {
    // return this.http.get<PaginationMain<ExtensionWiseAgent>>("http://monika-linux.dlink:8080/custom/v1/extensionwiseagent/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<PaginationMain<ExtensionWiseAgent>>( environment.appUrl+environment.customappurl+'api/extensionwiseagent/getall?accountId=' +accountId + "&offset=" + offset + "&limit=" + limit);
  }

  deleteExtension(id):Observable<any>{
    console.log("id:",id);
    // return this.http.delete<Array<ExtensionWiseAgent>>("http://192.168.1.43:8080/custom/v1/extensionwiseagent/deletebyid?id="+id)
    return this.http.delete<Array<ExtensionWiseAgent>>(environment.appUrl+environment.customappurl+"api/extensionwiseagent/deletebyid?id="+id)

  }


  update(extensionWiseAgent:ExtensionWiseAgent):Observable<any>{
    // return this.http.post<Array<ExtensionWiseAgent>>("http://192.168.1.43:8080/custom/v1/extensionwiseagent/update",extensionWiseAgent)

    return this.http.post<Array<Agent>>(environment.appUrl+environment.customappurl+'api/extensionwiseagent/update',extensionWiseAgent)
  }

  insert(extensionWiseAgent:ExtensionWiseAgent):Observable<any>{
    // return this.http.post<Array<ExtensionWiseAgent>>("http://192.168.1.43:8080/custom/v1/extensionwiseagent/insert",extensionWiseAgent)


    return this.http.post(environment.appUrl+environment.customappurl+'api/extensionwiseagent/insert',extensionWiseAgent,{responseType: 'text'})
  }
}
