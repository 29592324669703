import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AlertMessageService} from '../../../alert-message/alert-message.service';
import {FilterDao} from '../../../crdrreport/FilterDao';
import {Subject} from 'rxjs';
import {formatDate} from '@angular/common';
import {FileService} from '../../../Services/FileUtilsService/FileService';
import {DatefilterComponent} from '../../../CommonPopups/datefilter/datefilter.component';
import {LongcodenrDao} from './longcodenr-dao';
import {LongcodenrServiceService} from './longcodenr-service.service';
import {PaginationDao} from "../../../CommonObj/PaginationDao";

// @ts-ignore
@Component({
    selector: 'app-longcodenr',
    templateUrl: './longcodenr.component.html',
    styleUrls: ['./longcodenr.component.css']
})
export class LongcodenrComponent implements OnInit {

    // tslint:disable-next-line:max-line-length
    constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private alert: AlertMessageService, private service: LongcodenrServiceService) {

    }

    tableData: PaginationDao<any> = {data: [], total: 0};
    tableData2: PaginationDao<any> = {data: [], total: 0};
    startdate: number = undefined;
    enddate: number = undefined;
    offset: number = 0;
    limit: number = 10;
    dateFilterType: Array<string>;
    services: Array<string>;
    groupBy: Array<string>;
    private filter: FilterDao;
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {bDestroy: true, bPaginate: true, order: []};
    searchText: any;
    tableList: Array<LongcodenrDao>;

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(queryParams => {
            this.startdate = queryParams.startdate;
            this.enddate = queryParams.enddate;
            this.loadDataforDate();
        });
    }

    // tslint:disable-next-line:typedef
    // loadData()
    // {
    //   // @ts-ignore
    //   this.service.getAll().subscribe(value => {
    //     this.tableList = value;
    //     console.log(this.tableList);
    //     this.dtTrigger.next();
    //   });
    // }


    // tslint:disable-next-line:typedef
    loadDataforDate() {
        this.tableList = null;
        this.alert.showLoader();

        const format = 'yyyyMMdd';
        const locale = 'en-IN';
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const formattedDateFirst = formatDate(firstDay, format, locale);
        const formattedDateLast = formatDate(lastDay, format, locale);

        // tslint:disable-next-line:triple-equals
        if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined) {
            // tslint:disable-next-line:radix
            this.startdate = parseInt(formattedDateFirst);
            // tslint:disable-next-line:radix
            this.enddate = parseInt(formattedDateLast);
            // this.loadData();
            console.log('Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
        }
        this.service.getNRData(this.startdate, this.enddate, this.offset, this.limit).subscribe(value => {
            this.tableData = value;
            console.log(this.tableList);
            this.alert.hideLoader();
        });
      let tempoffset: number = 0;
      let templimit: number = 100000;

      this.service.getNRData(this.startdate, this.enddate, tempoffset, templimit).subscribe(value2 => {
        this.tableData2 = value2;
        this.alert.hideLoader();
      });
    }

    downloadReport() {
        this.alert.showLoader();

        const format = 'yyyyMMdd';
        const locale = 'en-IN';
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const formattedDateFirst = formatDate(firstDay, format, locale);
        const formattedDateLast = formatDate(lastDay, format, locale);

        // tslint:disable-next-line:triple-equals
        if (this.startdate == undefined && isNaN(this.enddate) || this.enddate == undefined) {
            // tslint:disable-next-line:radix
            this.startdate = parseInt(formattedDateFirst);
            // tslint:disable-next-line:radix
            this.enddate = parseInt(formattedDateLast);
            // this.loadData();
            console.log('Startdate at load Time' + this.startdate + ' and Endate' + this.enddate);
        }
        const file = new FileService<any>();
        // tslint:disable-next-line:prefer-const
        let trArr = [];
        let tdArr = [];
        tdArr.push('Id');
        tdArr.push('Retaileruid');
        tdArr.push('PhoneNumber');
        tdArr.push('Status');
        tdArr.push('Date');
        tdArr.push('Region');
        tdArr.push('ASMArea');
        tdArr.push('DbCode');
        tdArr.push('DistributorName');
        tdArr.push('Minslab');
        tdArr.push('RetailerName');
        tdArr.push('Channel');
        tdArr.push('DepotName');
        tdArr.push('States');
        trArr.push(tdArr);
        // this.service.getNRData(this.startdate, this.enddate, this.offset, this.limit).subscribe(value => {
          // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.tableData2.total; i++) {
              tdArr = [];
                tdArr.push(this.tableData2.data[i].id);
                tdArr.push(this.tableData2.data[i].retailerUId);
                tdArr.push(this.tableData2.data[i].phonenumber);
                tdArr.push(this.tableData2.data[i].status);
                tdArr.push(this.tableData2.data[i].date);
                tdArr.push(this.tableData2.data[i].region);
                tdArr.push(this.tableData2.data[i].asmArea);
                tdArr.push(this.tableData2.data[i].dbCode);
                tdArr.push(this.tableData2.data[i].distributorName);
                tdArr.push(this.tableData2.data[i].slab);
                tdArr.push(this.tableData2.data[i].retailerName);
                tdArr.push(this.tableData2.data[i].channel);
                tdArr.push(this.tableData2.data[i].depotName);
                tdArr.push(this.tableData2.data[i].states);
                trArr.push(tdArr);
            }
            file.generateFile(trArr, 'NR_Campaign_Report.xlsx');
        // });
        this.alert.hideLoader();
    }

    filterDatafordate() {
        this.dialog.open(DatefilterComponent);
    }

    clearFilter() {
        this.router.navigate([], {queryParams: {}});
    }


    tableChange(json) {
        this.offset = json.pageIndex * json.pageSize;
        this.limit = json.pageSize;
        this.loadDataforDate();
    }
}
