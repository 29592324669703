import { Component, OnInit } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialog} from "@angular/material/dialog";
import {AlertMessageService} from "../alert-message/alert-message.service";
import {SubScriptionPackageDao} from "./SubScriptionPackageDao";
import {SubscriptionFilterDao} from "../subscription-package-report/SubscriptionFilterDao";
import {SubscriptionPackageService} from "../subscription-package-report/subscription-package.service";
import {PlanType} from "./PlanType";
import {SubscriptionPackageType} from "./SubscriptionPackageType";
import {SubscriptionDetailsShowComponent} from "./subscription-details-show/subscription-details-show.component";
import {ClientSignUpDao} from "../client-sign-up/ClientSignUpDao";
import {environment} from "../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-subscription-package',
  templateUrl: './subscription-package.component.html',
  styleUrls: ['./subscription-package.component.css']
})
export class SubscriptionPackageComponent implements OnInit {

  tableList: Array<SubScriptionPackageDao> = [];
  filterDao:SubscriptionFilterDao = {
    startdate:'0',
    enddate:'0',
    offset:0,
    limit:10,
    accountId:0,
    groupBy:'',
    dateFilterType: '0',
    services:'',
    status:true
  };

  constructor(private http:HttpClient, private dialog: MatDialog, private alert: AlertMessageService, private subscriptionPackageService: SubscriptionPackageService ) {
    this.loaddata();
  }

  loaddata(){
    let date = new Date();
    let monthStr = (date.getMonth() + 1).toString();
    let onlyDate: string = date.getDate().toString();
    if(monthStr.length != 2){
      monthStr = '0' + monthStr ;
    }
    if(onlyDate.length!=2){
      onlyDate = '0' + onlyDate;
    }
    this.filterDao.startdate = '20220101';
    this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate;
    this.filterDao.status = true;
    this.subscriptionPackageService.getstatusWiseReport(this.filterDao).subscribe(value => {
      this.http.get<ClientSignUpDao>(environment.appUrl + environment.whatsappOfficialservice + "api/clientsignup/getbyid").subscribe(clientSignUpDao => {
        for(let i =0;i<value.length;i++){
          if(value[i].country == clientSignUpDao.country){
            this.tableList.push(value[i]);
          }
        }
        // this.tableList = value;
      });
    });
  }

  ngOnInit(): void {
  }

  moreInfo(subScriptionPackageDao: SubScriptionPackageDao) {
    this.dialog.open(SubscriptionDetailsShowComponent, {maxHeight:'80vh', width: '80vh',data: subScriptionPackageDao}).afterClosed().subscribe(value => {

    })
  }
}
