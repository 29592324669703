import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupMasterDao} from './group-master-dao';
import {environment} from '../../../environments/environment.prod';
const appprefix = environment.appUrl;
@Injectable({
  providedIn: 'root'
})
export class GroupMasterService {

  constructor(private http: HttpClient) { }


  save(data): Observable<any>
  {

    return this.http.post(appprefix + 'gsmgateway/v1/api/groupmaster/save' , data);
  }


  // save(data): Observable<any>
  // {
  //
  //   return this.http.post( 'http://localhost:8089/api/groupmaster/save' , data);
  // }

  // tslint:disable-next-line:typedef
  get(){

    return this.http.get( environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/groupmaster/getAll');
  }

  // tslint:disable-next-line:typedef
  bulkUpload(groupId, data): Observable<any>
  {
    return this.http.post(environment.driveLocationUrl + 'gateway/gsmgateway/v1/api/groupmaster/bulkupload2?groupId=' + groupId, data);
  }

}
