import { Component, OnInit } from '@angular/core';
import {UserService} from "../Services/User/user.service";
import {HttpClient} from "@angular/common/http";
import {UsermasterService} from "../Services/UsermasterService/usermaster.service";
import {NbccserviceService} from "../MicroServices/NBCC/nbccservice.service";

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.css']
})
export class LoginOTPComponent implements OnInit {

  private originalOTP:string;
  public otp:string;

  constructor(private userService:UserService, private http:HttpClient,private service: NbccserviceService) { }

  ngOnInit(): void {
    this.userService.setOTPStatus(false);
    let otp = Math.floor(1000 + Math.random() * 9000);
    this.originalOTP = otp+"";
    this.service.otpVerifiy(this.userService.user.phone,this.originalOTP).subscribe(value => {
      console.log('call otpVerifiy');
    });

    //SEND OTP VIA SMS
  }


  verifyOtp() {
    if (this.otp == this.originalOTP){
      this.userService.setOTPStatus(true);
    }else{
      alert('Invalid OTP')
    }


  }



}
