import {Component, Inject, OnInit} from '@angular/core';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ApplicationDeployementService} from '../application-deployement.service';
import {ApplicationDeployementComponent} from '../application-deployement/application-deployement.component';
import {UploadService} from '../upload-service';
import {UserService} from '../../Services/User/user.service';
import {DriveService} from '../../Drive/drive.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  file: File;

  uploadForm: FormGroup;
  // tslint:disable-next-line:max-line-length
  constructor(public dialogRef: MatDialogRef<UploadFileComponent>, private formBuilder: FormBuilder, private  alert: AlertMessageService,private user: UserService, private driveService: DriveService) { }

  ngOnInit(): void {
    // this.dialogRef.updatePosition(top : '20')
  }


  // tslint:disable-next-line:typedef
  uploadFile($event: Event) {
    // this.dialogRef.close($event);

    console.log('accountId', this.user.user.accountId);
    this.alert.confirmMessage('Upload File?', 'Are you sure?').then(value => {
      this.alert.showLoader();
      this.driveService.uploadFile($event, this.user.user.accountId).then(value1 => {

        this.alert.hideLoader();
        this.alert.showSuccesMessage('Successfully uploaded file!!');
        this.dialogRef.close(value1);
      }, reason => {
        this.alert.showErrorMessage(reason);
        this.alert.hideLoader();
      });

    });
  }



}
