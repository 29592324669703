import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment.prod";
import { FormBuilder, FormGroup } from "@angular/forms";
import {AlertMessageService} from "../../../alert-message/alert-message.service";

@Component({
  selector: 'app-prompt-upload',
  templateUrl: './prompt-upload.component.html',
  styleUrls: ['./prompt-upload.component.css']
})
export class PromptUploadComponent implements OnInit {

  form: FormGroup;
  public empCode:string="";
  constructor(private http: HttpClient,public fb: FormBuilder,private alert: AlertMessageService) {
    this.form = this.fb.group({
      file: [null]
    })
  }

  ngOnInit(): void {
  }

  uploadFileToUrl(files): Promise<any> {
    // Note that setting a content-type header
    // for mutlipart forms breaks some built in
    // request parsers like multer in express.
    const uploadUrl = environment.hdfcBaseUrl + 'hdfc/upload?empcode=TEST123'
    const options = {} as any; // Set any options you like
    const formData = new FormData();

    // Append files to the virtual form.
    for (const file of files) {
      formData.append(file.name, file)
    }

    // Optional, append other kev:val rest data to the form.
    //Object.keys(restObj).forEach(key => {
    //  formData.append(key, restObj[key]);
    //});

    // Send it.
    return this.http.post(uploadUrl, formData, options)
      .toPromise()
      .catch((e) => {
        // handle me
      });
  }

  upload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      file: file
    });
    this.form.get('file').updateValueAndValidity()
  }

  submit() {
    this.alert.showLoader();
    var formData: any = new FormData();
    formData.append("file", this.form.get('file').value);
    let empcodeparam = this.empCode.trim();
    const uploadUrl = environment.hdfcBaseUrl + 'hdfc/upload?empcode='+this.empCode
    this.http.post(uploadUrl, formData,{headers:{"Testtt":"multipart/form-data"}}).subscribe(
      (response) =>{
        this.alert.hideLoader()
        this.alert.showSuccesMessage("Successfully Uploaded!")
      } ,
      (error) => {
        this.alert.hideLoader()
        this.alert.showErrorMessage("Upload Failed!")
      }
    )
  }


  setEmpCode(empCodeVal: EventTarget) {
    // @ts-ignore
    this.empCode=empCodeVal.value;
    console.log("empCode Typed value ::", this.empCode)
    this.empCode = this.empCode.trim();
    this.empCode = this.empCode.replace(/[^a-z0-9]/gi,'');
    this.empCode = this.empCode.toUpperCase();
  }
}
