
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">

          <div class="panel-title">
            <div class="btn-group" id="buttonexport">
              <a href="#">
                <h4>Chatbot Master</h4>
              </a>
            </div>
            <button  (click)="addNewChatbot()"  class="btn btn-add">Add Chatbot <i
              class="fa fa-plus"></i></button>
            &nbsp;
          </div>

        </div>

        <div id="tablediv">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Create By</th>

                <th>Status</th>
<!--                <th>Type</th>-->
                <th>Action</th>


              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="chatbotList.recordsTotal==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of chatbotList.data;let ind = index">
                <tr>
                  <td>{{data.id}}</td>
                  <td  [innerHTML]="data.chatBotName"  ></td>
                  <td  [innerHTML]="data.createBy"  ></td>

                  <td  *ngIf="data.enabled==true; else elseBlock" >Active</td>
                  <ng-template #elseBlock>Inactive</ng-template>

<!--                  <td  [innerHTML]="data.chatBotType"  ></td>-->
                  <td><i (click)="openMasterEditForm(data)" class="btn btn-add fa fa-pencil m-r-2" ></i>
                   <i title="Click Here To Copy unofficial webhook url" (click)="copy(data)"
                    class="btn btn-add fa fa-copy m-r-2"></i>
                    <i title="Click Here To Copy waofficial webhook url"  (click)="copyWhatsappOfficial(data)"
                       class="btn btn-danger fa fa-copy "></i>

                  </td>

<!---->

                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>

          <mat-paginator (page)="tableChange($event)" [length]=chatbotList.recordsTotal
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>
        </div>


<!--//-->
      </div>
    </div>
  </div>


</section>
