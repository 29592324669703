import {Component, OnDestroy, OnInit} from '@angular/core';
import {CampaignDao} from '../onetomanywsun/campaignService/CampaignDao';
import {CampaignServiceService} from '../onetomanywsun/campaignService/campaign-service.service';
import {Subject} from 'rxjs';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {FileService} from '../../Services/FileUtilsService/FileService';
import {MessageType} from '../DaoMessageUn';
import {data} from 'jquery';
import {InstanceMasterService} from "../instance-master/InstanceService/instance-master.service";
import {PaginationDao} from "../../CommonObj/PaginationDao";
import {IncommingSmsDao} from "../../MicroServices/GsmGateway/Oppo/incomming-sms-report/IncommingSmsDao";
import {PageEvent} from "@angular/material/paginator";
import {GraphCommonComponent} from "../../graph-common/graph-common.component";
import {GraphCommonDao} from "../../graph-common/GraphCommonDao";
import {ChartDataDao} from "../../chart-and-tables/ChartDataDao";
import {Label} from "ng2-charts";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-campaign-report-un',
  templateUrl: './campaign-report-un.component.html',
  styleUrls: ['./campaign-report-un.component.css']
})
export class CampaignReportUnComponent implements OnInit, OnDestroy {
  constructor(private campaign: CampaignServiceService, instanceMasterService:InstanceMasterService,private alert: AlertMessageService,private dialog:MatDialog) {

  }
  dtTrigger: Subject<any> = new Subject<any>();
  tableData: PaginationDao<CampaignDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  dtOptions: any = {bDestroy: true, order: []};
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
this.loadData();
  }

  loadData(){
    this.alert.showLoader();
    this.campaign.get(this.offset,this.limit).subscribe(value => {
      this.tableData = value;
      console.log('tableData Values ',this.tableData)
      this.alert.hideLoader();
    });
  }


  tableChange(json) {
    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    this.loadData();
  }

  // tslint:disable-next-line:typedef
  drawReport(user: CampaignDao, ind: number) {
    this.alert.showLoader();
    this.campaign.getCampaingWiseReport(user.id).subscribe(campResponse => {

      let chartDataDao: ChartDataDao = {barChartData: [], chartName: '', labels: []};
      let labels: Array<Label> = [];
      let value: Array<number> = [];
      for (var i = 0; i < campResponse.length; i++) {
        labels.push(campResponse[i]._id);
        value.push(campResponse[i].count);

      }
      chartDataDao.labels = labels;
      chartDataDao.barChartData.push({
        data: value,
        label: "Message Report",
        backgroundColor: "#FB8170",
        hoverBackgroundColor: "#fb8170",
        fill: false,
        borderColor: "#fb8170",
        barThickness: 40
      })

      chartDataDao.chartName = "Day Wise Report";
      let graph: GraphCommonDao = {chartData: chartDataDao, header: user.campaignName + ' Report'}
      this.dialog.open(GraphCommonComponent, {data: graph})
      this.alert.hideLoader();
    })


  }

  // tslint:disable-next-line:typedef
  downloadReport(user: CampaignDao, ind: number) {
    this.alert.showLoader();
    this.campaign.getCampaignReport({enddate: '', id: user.id, idNum: 0, page: 0, startdate: '', accountId: 0,reportBy:0}).subscribe(value => {
      const file = new FileService<any>();
      const trArr = [];
      let tdArr = [];


      tdArr.push('Message-Id');
      tdArr.push('DST');
      tdArr.push('Content Type');
      tdArr.push('Content');
      tdArr.push('Caption');
      tdArr.push('Message Status');
      tdArr.push('Date');
      tdArr.push('DLR TIME');
      tdArr.push('IncId');
      trArr.push(tdArr);
      for (let i = 0; i < value.recordsTotal; i++)
      {
         tdArr = [];
         tdArr.push(value.data[i].id);

         if(value.data[i].dst.includes("@g.us")){


         }
         tdArr.push(value.data[i].dst);
         tdArr.push(value.data[i].messageType);
         console.log(value.data[i].messageType.toString());
         switch (value.data[i].messageType.toString()) {
          case 'TEXT':
            tdArr.push(value.data[i].textMessage.content);
            tdArr.push('');
            break;
          case 'FILE':
            tdArr.push(value.data[i].fileMessage.fileUrl);
            tdArr.push(value.data[i].fileMessage.caption);
            break;

          default:
            tdArr.push('');
            tdArr.push('');
            break;
        }
         tdArr.push(value.data[i].messageStatus);

         tdArr.push(value.data[i].dateTiming.sentTime);
         tdArr.push(value.data[i].dateTiming.dlrTime);
        tdArr.push(value.data[i].incId);

        //  if (value.data[i].promotional )
        // {
        //   tdArr.push(0);
        // }
        //  else {
        //    tdArr.push(value.data[i].incId);
        //  }


         trArr.push(tdArr);


      }
      file.generateFile(trArr, 'Campaign_Report.xlsx');
      this.alert.hideLoader();

    });
  }

  getDlvCount(data: CampaignDao) {

    try {

      return data.report.DLV;
    } catch (e) {
      return 0;
    }

  }


}
