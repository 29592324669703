import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ContactFormDao} from "../contact-form/ContactFormDao";
import {MessageDao} from "../../ws-template/MessageDao";

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.css']
})
export class MessageInfoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDao) { }

  ngOnInit(): void {
  }

}
