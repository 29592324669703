import { Component, OnInit } from '@angular/core';
import {UrlMasterDao} from '../module-master/UrlMasterService/UrlMasterDao';
import {MenuMasterDao} from '../menumaster/MenuMasterDao';
import {ServiceMasterDao} from '../servicemaster/ServiceMasterDao';
import {ModuleMasterDao} from '../module-master/ModuleMasterDao';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {ModulemasterServiceService} from '../module-master/ModuleMasterService/modulemaster-service.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {MenuMasterService} from '../menumaster/MenuMasterService/menu-master.service';
import {ServiceMasterService} from '../servicemaster/ServiceMasterService/service-master.service';
import {UrlMasterService} from '../module-master/UrlMasterService/url-master.service';
import {PermissionEntryDao, PermissionEntryDaoReporting} from './PermissionEntryDao';
import {RoleServiceService} from '../rolemaster/RoleService/role-service.service';
import {RoleMasterDao} from '../rolemaster/RoleMasterDao';

import {MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PermissionentryService} from './PermissionEntryService/permissionentry.service';


@Component({
  selector: 'app-permissionentry',
  templateUrl: './permissionentry.component.html',
  styleUrls: ['./permissionentry.component.css']
})
export class PermissionentryComponent implements OnInit {

  urlMasterArray:Array<UrlMasterDao>=null;
  addNewDiv: boolean = false;
  serviceId: number = 0;
  roleId: number = 0;
  serviceName:string="";
  roleList: Array<RoleMasterDao> = [];
  serviceList: Array<ServiceMasterDao> = [];
  panelOpenState = false;
  data: ModuleMasterDao = {id: 0, menuId: 0, moduleName: '', moduleUrl: '', serviceId: 0, showOnMenu: true};
  tableList: ClientSidePagination<PermissionEntryDao> = {data: [], end: 0, start: 0, total: 0};

  constructor(private service: ModulemasterServiceService, private alert: AlertMessageService, private menuService: MenuMasterService, private services: ServiceMasterService,private urlMasterService:UrlMasterService,private roleService:RoleServiceService,private permissionEntryService:PermissionentryService) {

    this.services.getRoles().subscribe(value => {
      this.serviceList = value;
      this.changeServiceId(this.serviceList[0].id,this.serviceList[0].serviceName,false)
      this.roleService.getRoles().subscribe(value => {
        this.roleList=value;
        this.changeRole(value[0].id,true)
      })
    });



  }

  getData() {
    this.alert.showLoader();
    this.service.getPermissionEntry(this.roleId,this.serviceId).subscribe(value => {

      this.tableList = {data: value, end: 10, start: 0, total: value.length};
      this.alert.hideLoader();
    });

  }

  changeServiceId(id: number,serviceName:string,callApi:boolean) {
    this.serviceName=serviceName;
    this.serviceId = id;
    if(callApi)
    {
      this.getData();
    }


  }
  changeRole(id:number,api:boolean)
  {
    this.roleId=id;
    if(api) {
      this.getData();
    }
  }

  ngOnInit(): void {

  }

  isOrUpdate() {
    this.alert.showLoader();
    this.alert.confirmMessage('Save Module To '+this.serviceName+'?', 'Are you sure?').then(value => {
      this.service.save(this.data).subscribe(value1 => {
        this.data=value1;
        if(this.urlMasterArray==null)
        {
          this.urlMasterArray=[];
        }
        else{
          if(this.urlMasterArray.length>0)
          {
            this.urlMasterService.save(this.urlMasterArray).subscribe(value2 => {
              this.urlMasterArray=value2;
            })
          }
        }
        this.alert.showSuccesMessage('Successfully saved into '+this.serviceName+'!!');

        this.alert.hideLoader();
      }, error => {
        this.alert.showErrorMessage('Duplicate Module!!');
        this.alert.hideLoader();
      });
    },);

  }


  addPermission(moduleId: number, actionId: number,event:MatSlideToggleChange) {
    console.log("Event is ",event.checked)

  }
  checkPermission(id:number,permissions:Array<number>):boolean
  {

    let found=false;
    for(var i=0;i<permissions.length;i++)
    {
      if(id==permissions[i])
      {

        found=true;
      }
    }
    return found;
  }

  toggleChanges($event: MatSlideToggleChange,moduleId: number, actionId: number) {
      console.log('Event is',$event.checked,' Module id ',moduleId,' Action id is ',actionId)
    let permission:PermissionEntryDaoReporting={actionId: actionId, moduleId: moduleId, roleId:this.roleId}

    if($event.checked)
    {
      this.permissionEntryService.save(permission).subscribe(value => {

      })
    }else{
      this.permissionEntryService.delete(moduleId,this.roleId,actionId).subscribe(value => {

      })
    }

  }
}
