import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment.prod";
import {Observable} from "rxjs";
import {NdtDao} from "./ndt/ndt-dao";
import {HttpClient} from "@angular/common/http";
import {PaginationDao} from "../../CommonObj/PaginationDao";
const appPrefix=environment.appUrl
@Injectable({
  providedIn: 'root'
})
export class DigimozoLongCodeService {

  constructor(private http:HttpClient) { }

  getData(startdate: number, enddate: number, offset: number, limit: number): Observable<PaginationDao<NdtDao>> {
    return this.http.get<PaginationDao<NdtDao>>(appPrefix + 'digimozo/v1/api/ndt/get?startdate=' + startdate + '&enddate=' + enddate + '&offset=' + offset + '&limit=' + limit);
  }


}
