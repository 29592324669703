import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationDao, PaginationMain} from "../../CommonObj/PaginationDao";
import {ClientDao} from "../ClientMaster/ClientDao";
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class ClientMasterService {

  constructor(private http: HttpClient) {
  }


  getReport(accountId: number, offset: number, limit: number): Observable<PaginationMain<ClientDao>> {
    // return this.http.get<PaginationMain<Chatbot>>("http://localhost:8080/v1/chatbot/getall?accountId="+accountId+"&offset="+offset+"&limit="+limit);

    return this.http.get<PaginationMain<ClientDao>>( environment.appUrl+environment.customappurl+'api/clientmaster/getall?accountId=' +accountId + "&offset=" + offset + "&limit=" + limit);
  }

  insertmany(clientDaos:Array<ClientDao>):Observable<any>{
    return this.http.post<Array<ClientDao>>(environment.appUrl+environment.customappurl+'api/clientmaster/insertmany',clientDaos)
  }

  update(clientDao:ClientDao):Observable<any>{
    return this.http.post<Array<ClientDao>>(environment.appUrl+environment.customappurl+'api/clientmaster/update',clientDao)
  }
  insert(clientDao:ClientDao):Observable<any>{
    return this.http.post<Array<ClientDao>>(environment.appUrl+environment.customappurl+'api/clientmaster/insert',clientDao)
  }
}
