import {Component, OnInit} from '@angular/core';
import {InstanceMasterDao} from '../instance-master/InstanceMasterDAo';
import {ClientSidePagination} from '../../CommonObj/ClientSidePagination';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {DomainService} from '../domain-master/DomainService/domain.service';
import {ProxyMasterDao} from './ProxyMasterDao';
import {ProxyService} from './ProxyService/proxy.service';

@Component({
  selector: 'app-proxymaster',
  templateUrl: './proxymaster.component.html',
  styleUrls: ['./proxymaster.component.css']
})
export class ProxymasterComponent implements OnInit {

  addNewDiv: boolean = false;

  proxy: ProxyMasterDao = {
    active: false, id: 0, pass: '', port: 0, proxy: '', username: ''

  };
  tableList: ClientSidePagination<ProxyMasterDao> = {data: [], end: 0, start: 0, total: 0};

  constructor(private alert: AlertMessageService, private proxyService: ProxyService) {
  }

  ngOnInit(): void {

  this.loadData()
  }

  loadData() {
    this.proxyService.get().subscribe(value => {
      this.tableList = {data: value, end: 0, total: value.length, start: 0};
    });
  }

  edit(proxy: ProxyMasterDao) {
    this.proxy = proxy;
    this.addNewDiv = true;
  }


  isOrUpdate() {
    this.alert.confirmMessage('Save Proxy?', 'Are you sure?').then(value => {
      this.proxyService.save(this.proxy).subscribe(value1 => {
        this.proxy = value1;
        this.loadData();
        this.alert.showSuccesMessage("Successfully Saved Proxy!!")
      });
    });

  }

  delete(data: ProxyMasterDao, ind: number) {

  }

  cancel() {
    this.addNewDiv = false;
    this.proxy = {
      active: false, id: 0, pass: '', port: 0, proxy: '', username: ''

    };
  }


  addNew() {
    this.cancel();
    this.addNewDiv = true;
  }

}
