
<section class="content-header">
  <div class="header-icon">

  </div>

  <div class="modal fade" id="filterModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="headingpopup" style="text-align: center;width: 100%;" class="modal-title"></h4>
        </div>
        <form id="filterForm">
          <div id="datediv" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2">
            <label for="empname">Employee Name</label>
            <input type="text" class="form-control" id="empname" placeholder="" name="empname" [(ngModel)]="empname" value="">
            <label for="empcode">Employee Code</label>
            <input type="text" class="form-control" id="empcode" placeholder="" name="empcode" [(ngModel)]="empcode" value="">
            <label for="msisdn">Employee Number</label>
            <input type="text" class="form-control" id="msisdn" placeholder="" name="empmsisdn" [(ngModel)]="empmsisdn" value="">
            <label for="startdate">Start Date</label>
            <input type="date" class="form-control" id="startdate" placeholder="Date" name="startdateStr" [(ngModel)]="startdateStr" value="" >
            <label for="enddate">End Date</label>
            <input type="date" class="form-control" id="enddate" placeholder="Date" name="enddateStr" [(ngModel)]="enddateStr" value="" >
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" id="transfer" (click)="loadData()">Filter</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </form>

      </div>

    </div>
  </div>

  <div class="buttonexport">

    <div class="panel-title">

      <button data-toggle="modal" data-target="#filterModal" class="btn btn-add">Filter<i class="fa fa-filter"></i></button>
      <button (click)="clearFilter()"  class="btn btn-add">Clear Filter<i class="fa fa-remove"></i></button>
      <button (click)="download()"  class="btn btn-add">Download File<i class="fa fa-download"></i></button>

      <button (click)="upload()"  class="btn btn-add">Upload File<i class="fa fa-upload"></i></button>
    </div>
  </div>



</section>
<!-- Main content -->
<!-- Main content -->
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">
          <div class="btn-group" id="buttonexport">
            <a href="#">
              <h4>Resigned Employees</h4>
            </a>
          </div>
        </div>

        <div  id="tablediv">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>Emp Name</th>
                <th>Emp Code</th>
                <th>Emp Mobile</th>
                <th>Request Time</th>
                <th>Call Time</th>
                <th>Call Status</th>
                <th>Call Connect Time</th>
                <th>Call Disconnect Time</th>
                <th>Duration</th>
                <th>Pulses</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.total==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of tableData.data;let ind = index">
                <tr>
                  <td  [innerHTML]="data.empName" ></td>
                  <td  [innerHTML]="data.empCode" ></td>
                  <td  [innerHTML]="data.msisdn" ></td>
                  <td  [innerHTML]="data.requestTime" ></td>
                  <td  [innerHTML]="data.callTime" ></td>
                  <td  [innerHTML]="data.callReason" ></td>
                  <td  [innerHTML]="data.callConnecttime" ></td>
                  <td  [innerHTML]="data.callDisconnecttime" ></td>
                  <td  [innerHTML]="data.callDuration" ></td>
                  <td  [innerHTML]="data.pulses" ></td>
                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>

        </div>



      </div>
    </div>
  </div>


</section>
