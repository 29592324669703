import { Component, OnInit } from '@angular/core';
import {RoleMasterDao} from '../../rolemaster/RoleMasterDao';
import {ClientSidePagination} from '../../CommonObj/ClientSidePagination';
import {DomainMasterDao} from './DomainMasterDao';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {DomainService} from './DomainService/domain.service';

@Component({
  selector: 'app-domain-master',
  templateUrl: './domain-master.component.html',
  styleUrls: ['./domain-master.component.css']
})
export class DomainMasterComponent implements OnInit {
  addNewDiv:boolean=false;

  domainDao:DomainMasterDao={host:""}
  tableList:ClientSidePagination<DomainMasterDao>={data: [], end: 0, start: 0, total: 0}
  constructor(private alert:AlertMessageService,private domain:DomainService) { }

  ngOnInit(): void {

      this.loadData();
  }
  loadData()
  {
    this.domain.get().subscribe(value => {
      this.tableList={data: value, end: 0, start: 0, total: value.length}
    })
  }
  edit(domain:DomainMasterDao)
  {
    this.domainDao=domain;
    this.addNewDiv=true;
  }


  isOrUpdate() {
    this.alert.confirmMessage("Save Domain?","Are you sure?").then(value =>
    {
      this.domain.save(this.domainDao).subscribe(value1 => {
        this.domainDao=value1;
        this.alert.showSuccesMessage("Successfully saved Domain!!")
        this.cancel();
        this.loadData();
      })
    })

  }

  delete(data: DomainMasterDao, ind: number) {
    this.alert.confirmMessage("Delete Domain?","Are you sure?").then(value => {
      this.domain.delete(data.host).subscribe(value1 => {
        this.loadData();
      })
    })

  }

  cancel() {
    this.addNewDiv=false;
    this.domainDao={host: ''}
  }



  addNew() {
    this.domainDao={host: ''};
    this.addNewDiv=true;
  }
}
