
<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div  *ngIf="!addNewDiv" class="panel panel-bd lobidragdashboard">
      <div   class="panel-heading">
        <div class="panel-title">
          <button (click)="addPayemntStatus()"  class="btn btn-add">Update Manually  <i class="fa fa-plus"></i></button>

         </div>
      </div>
      <div style="" class="panel-body">
        <div class="table-responsive">
          <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Mobile No</th>
              <th>Amount</th>
              <th>Received Amount</th>
              <th>Payment Mode</th>
              <th>Payment Id</th>
              <th>Payment Time</th>
              <th>Payment Status</th>
               <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container  *ngFor=" let tableData of this.tableData  ;let ind =index">

              <tr  *ngIf="tableData==null">
                <td colspan="9" style="text-align: center">No Record</td>
              </tr>
              <tr>
                <td [innerHTML]="this.tableData.customerMobileNo"></td>
                <td [innerHTML]="this.tableData.amount"></td>
                <td [innerHTML]="this.tableData.receivedAmount"></td>
                <td [innerHTML]="this.tableData.paymentMode"></td>
                <td [innerHTML]="this.tableData.paymentID"></td>
                <td [innerHTML]="this.tableData.paymentDateTime"></td>
                <td [innerHTML]="this.tableData.respDescription"></td>
                <td>
                  <i title="Click Here To Delete" class="btn btn-danger fa fa-trash" (click)="deleteById(this.tableData.id)"></i>
                </td>


              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>
    </div>

    <div *ngIf="addNewDiv">



      <div  class="row">
        <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
          <div class="panel panel-bd lobidragdashboard">
            <!--        <button type="button" (click)="onClose()" mat-dialog-close class="close" data-dismiss="modal" ><i class="fa fa-window-close"></i></button>-->
            <button type="button" (click)="onClose()" mat-dialog-close  class="close" data-dismiss="modal" aria-hidden="true">×</button>
            <div class="panel-body">
              <h3 style="text-align: center;text-decoration: underline;text-decoration-color: darkred"> <b>{{title}}</b></h3><br>

              <form ngNativeValidate  class= "form-group col-lg-12" (ngSubmit)="createOrUpdate()">


                <div class="form-group col-lg-6">
                  <label   class="control-label" for="bungalowNo">Bungalow No<sup class="required">*</sup></label>
                  <input  [disabled]="this.submitBtnTxt=='Submit' " type="text" placeholder="Please Bungalow No" title="Please Enter Bungalow No" required   name="bungalowNo" id="bungalowNo" class="form-control"  [(ngModel)]="this.dao.bungalowN" >
                </div>

                <div class="form-group col-lg-6">
                  <label   class="control-label" for="mobile">Mobile No.<sup class="required">*</sup></label>
                  <input  [disabled]="this.submitBtnTxt=='Submit' "  type="number" placeholder="Please Enter Mobile Number" title="Please Enter Mobile Number" required   name="mobile" id="mobile" class="form-control"  [(ngModel)]="this.dao.customerMobileNo">
                </div>

                <div class="form-group col-lg-6">
                  <label   class="control-label" for="amount">Amount <sup class="required">*</sup></label>
                  <input   [disabled]="this.submitBtnTxt=='Submit' "   type="text" placeholder="Enter Amount  " title="Please Enter Amount " required   name="amount" id="amount" class="form-control"  [(ngModel)]="this.dao.amount" >
                </div>


                <div class="form-group col-lg-6">
                  <label   class="control-label" for="receivedAmount">Received Amount <sup class="required">*</sup></label>
                  <input    type="text" placeholder="Enter received Amount  " title="Please Enter Amount " required   name="receivedAmount" id="receivedAmount" class="form-control"  [(ngModel)]="this.dao.receivedAmount" >
                </div>

                <div class="form-group col-lg-6">
                  <label   class="control-label" for="paymentid">Payment Id<sup class="required">*</sup></label>
                  <input     type="text" placeholder="Enter Payment Id" title="Please Enter Payment Id " required   name="paymentid" id="paymentid" class="form-control"  [(ngModel)]="this.dao.paymentID " >
                </div>

                <div class="form-group col-lg-6">
                  <label   class="control-label" for="paymentdatetime">Payement Date/ Time <small>(yyyyMMddHHmmss)</small><sup class="required">*</sup></label>
                  <input     type="datetime-local" placeholder="Enter Payment Id" title="Please Enter Payment Id " required   name="paymentdatetime" id="paymentdatetime" class="form-control"  [(ngModel)]="this.dao.paymentDateTime " >
                </div>
                <div class="form-group col-lg-6">
                  <label   class="control-label"  >Payment Mode <sup class="required">*</sup></label>
                  <select    name="paymentMode" id="Cash" class="form-control"  [(ngModel)]="dao.paymentMode">
<!--                    <option value="cash" class="form-control" >Cash</option>-->
                    <option value="bd" class="form-control" >BD (Bank Draft)</option>
                    <option value="neft/rtgs" class="form-control" >NEFT / RTGS</option>
                    <option value="cheque" class="form-control" >Cheque</option>
                    <option value="UPI" class="form-control" >UPI</option>
                   </select>
                </div>

                <div class="form-group col-lg-6"  *ngIf="dao.paymentMode=='cheque' ">
                  <label   class="control-label" for="chequeNo">Cheque No:<sup class="required">*</sup></label>
                  <input     type="text" placeholder="Enter Payment Id" title="Please Enter Cheque No " required   name="chequeno" id="chequeNo" class="form-control"  [(ngModel)]="this.dao.chequeNo " >
                </div>

                <div class="col-sm-6" style="margin-left:  83%">
                  <button type="submit" class="btn btn-add btn-sm">{{submitBtnTxt}}</button>
                  <button type="button" (click)="onClose()" mat-dialog-close class="btn btn-danger btn-sm">Close</button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
    </div>
