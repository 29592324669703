

<div class="panel-body">

  <div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
      <div class="panel panel-bd lobidragdashboard">
        <div class="panel-heading">
          <div class="panel-title">
             <h4  [innerHTML]="chartData.chartName"></h4> <button (click)="chageGraphType('line')" class="btn btn-add">Line Chart <i class="hvr-buzz-out fa fa-line-chart"></i></button><button (click)="chageGraphType('bar')" class="btn btn-add">Bar Chart <i class="hvr-buzz-out fa fa-bar-chart"></i></button>
          </div>
        </div>
        <div   style="" class="panel-body">

          <div *ngIf="barChartType!='table'" style="display: block">
            <canvas baseChart
                    [datasets]="this.chartData.barChartData"
                    [labels]="this.chartData.labels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
            </canvas>
          </div>

        </div>


      </div>


    </div>

  </div>
</div>
