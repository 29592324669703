import { Component, OnInit } from '@angular/core';
import {PaginationDao, PaginationMain, WAPagination} from "../../../CommonObj/PaginationDao";
import {ClientDao} from "../ClientDao";
import {UserMasterDao} from "../../../Services/User/UserMasterDao";
import {UsermasterService} from "../../../Services/UsermasterService/usermaster.service";
import {ClientMasterService} from "../client-master.service";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {MatDialog} from "@angular/material/dialog";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {Agent} from "../../AgentMaster/agent-master/AgentDao";
import {AgentMasterFormComponent} from "../../AgentMaster/agent-master-form/agent-master-form.component";
import {ClientMasterFormComponent} from "../client-master-form/client-master-form.component";
import {KKRTCDao} from "../../../MicroServices/KKRTC/KKRTCDao";

@Component({
  selector: 'app-client-master',
  templateUrl: './client-master.component.html',
  styleUrls: ['./client-master.component.css']
})
export class ClientMasterComponent implements OnInit {
  // clientMasterList: PaginationDao<ClientDao> = {data: [], total: 0};

  clientMasterList: PaginationMain<ClientDao> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  currentUser:UserMasterDao
  offset: number = 0;
  limit: number = 10;
  clientList: Array<ClientDao>;
  private data: ClientDao;


  constructor(private userMasterService:UsermasterService, private clientMasterService: ClientMasterService, private dialog: MatDialog, private alert: AlertMessageService) { }

  ngOnInit(): void {
    this.userMasterService.getCurrentUser().subscribe(data => {
      this.currentUser = data;
      console.log("current user", this.currentUser)
      this.loadData();
    });
  }



  loadData()
  {
    this.clientMasterService.getReport(this.currentUser.accountId, this.offset, this.limit).subscribe(value => {
      console.log("data : ", value)
      this.clientMasterList=value

      console.log("clientMasterList:", this.clientMasterList)

    });
  }
  tableChange(json) {
    console.log("json",json)

    this.offset = json.pageIndex*json.pageSize;
    this.limit = json.pageSize;
    console.log("offset and limit",this.offset,this.limit)
    this.loadData();

  }

  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const toleng = value.length;

      console.log('arrayofvalues', value);
      console.log('data length', toleng);
      if (toleng < 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      this.clientList = value;
      this.clientMasterService.insertmany(this.clientList).subscribe(value1 => {
        this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
        console.log('upload file values is :: ' + value1);
        this.alert.showSuccesMessage('Upload Successfully !!');
        this.loadData();
        // window.location.reload();
        this.cancel();
      }, error => {
        this.alert.showErrorMessage('Failed To Upload Data!!');
        this.alert.hideLoader();
      });



      this.alert.hideLoader();
      // window.location.reload();

    });


  }

  downloadSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];

    tdArr.push('clientName');
    tdArr.push('clientNumber');
    tdArr.push('clientEmailId');

    tdArr.push('clientCompanyName');
    tdArr.push('agentName');

    tdArr.push('agentNumber');
    tdArr.push('accountId');

    trArr.push(tdArr);
    tdArr = [];
    tdArr.push('Abc');
    tdArr.push('91876543210');
    tdArr.push('test@13gmail.com');
    tdArr.push('Xyz');

    tdArr.push('91987654321');
    tdArr.push('1200');
    trArr.push(tdArr);

    file.generateFile(trArr, 'ClientMasterFile.xlsx');
    this.alert.hideLoader();
  }



  cancel() {
    this.data = null;
  }

  addOrUpdateClientMaterForm(clientTemp:ClientDao,submitBtnText:String) {
    console.log("clientTemp in edit or add:",clientTemp)
    let clientDao:ClientDao={
      accountId: this.currentUser.accountId,
      agentCompanyName: "",
      agentId: 0,
      agentName: "",
      agentNumber: "",
      clientDesignation: "",
      clientEmailId: "",
      clientName: "",
      clientCompanyName:"",
      clientNumber: "",
      createDate: "",
      createDateFilter: 0,
      customerId: 0,
      id: 0,
      location: "",
      updateDate: "",
      updateDateFilter: 0
    };


    if (clientTemp != undefined){
      clientDao = clientTemp
      console.log("clientMaster",clientDao)
    }

    let dialogRef =  this.dialog.open(ClientMasterFormComponent,{width: '140vw',
      maxHeight: '95vh',
      data:{clientDao,submitBtnTxt:submitBtnText}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao; ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe client Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }

}
