import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as Http from "http";
import {CampaingService} from "../../../CampainService/campaing.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {AgentServiceService} from "../agent-service.service";
import {PaginationMain} from "../../../CommonObj/PaginationDao";
import {Agent} from "../../AgentMaster/agent-master/AgentDao";
import {UsermasterService} from "../../../Services/UsermasterService/usermaster.service";
import {UserMasterDao} from "../../../Services/User/UserMasterDao";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {BungalowMasterDao} from "../../../MicroServices/NBCC/BungalowMasterDao";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {ChatbotMasterDao} from "../../../MicroServices/Chatbot/chatbot-master/ChatbotMasterDao";
import {ChatbotMasterFormComponent} from "../../../MicroServices/Chatbot/chatbot-master-form/chatbot-master-form.component";
import {AgentMasterFormComponent} from "../agent-master-form/agent-master-form.component";
import {InteractionSessionsDao} from "../../interaction-session/InteractionSession";
import {environment} from "../../../../environments/environment.prod";
import {Subject} from "rxjs";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {ActivatedRoute, Router} from "@angular/router";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-agent-master',
  templateUrl: './agent-master.component.html',
  styleUrls: ['./agent-master.component.css']
})
export class AgentMasterComponent implements OnInit,AfterViewInit {
  agentMasterList: PaginationMain<Agent> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  // agentList:Array<Agent>;
  currentUser:UserMasterDao
  offset: number = 0;
  limit: number = 100000;
  startdate: number = undefined;
  enddate: number = undefined;
  agentList: Array<Agent>;
  bulkAgent: Array<Agent>;

  private data: Agent;

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  constructor(private userMasterService:UsermasterService, private agentServiceService: AgentServiceService,private route: ActivatedRoute, private dialog: MatDialog, private alert: AlertMessageService, private router: Router) {

    // this.loadData();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];

      this.userMasterService.getCurrentUser().subscribe(data => {
        this.currentUser = data;
        console.log("current user", this.currentUser)
        this.loadData();

      });

    });
  }

  ngOnInit(): void {

//
    // this.loadData();



  }



loadData()
{
  // this.alert.showLoader();
  const format = 'yyyyMMdd';
  const locale = 'en-IN';

  let date = new Date()
  let y = date.getFullYear()
  let m = date.getMonth()
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);

  const formattedDateFirst = formatDate(firstDay, format, locale);
  const formattedDateLast = formatDate(lastDay, format, locale);

  if (this.startdate == undefined || this.enddate == undefined) {
    this.startdate = 0;
    this.enddate = 0;
  }
  $('#tableDiv').DataTable().destroy();

  this.agentServiceService.getReport(this.currentUser.accountId,this.startdate,this.enddate, this.offset, this.limit).subscribe(data => {
    console.log("data : ", data)
    this.agentList = data.data;
    this.dtTrigger.next();
    // this.alert.hideLoader();
    console.log("agentlist:", this.agentList)

  });
}
// tableChange(json) {
//     console.log("json",json)
//   this.offset = json.pageIndex;
//   this.limit = json.pageSize;
//   this.loadData();
//
// }

  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  bulkUpload() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      const toleng = value.length;

      console.log('arrayofvalues', value);
      console.log('data length', toleng);
      if (toleng < 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

        this.bulkAgent = value;
        this.agentServiceService.insertmany(this.bulkAgent).subscribe(value1 => {
          this.alert.showSuccesMessage('Successfully Uploaded ' + value1 + ' of ' + toleng + ' Data ');
          console.log('upload file values is :: ' + value1);

          this.alert.showSuccesMessage('Upload Successfully !!');
          this.loadData();
          // window.location.reload();
          this.cancel();
        }, error => {
          this.alert.showErrorMessage('Failed To Upload Data!!');
          this.alert.hideLoader();
        });



      this.alert.hideLoader();
      // window.location.reload();

    });


  }

  downloadSampleFile() {
    this.alert.showLoader();
    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];

      tdArr.push('agentName');
     tdArr.push('agentNumber');
     tdArr.push('accountId');

      trArr.push(tdArr);
      tdArr = [];
      tdArr.push('Abc');
      tdArr.push('91876543210');
      tdArr.push('20511');
      trArr.push(tdArr);

      file.generateFile(trArr, 'SampleAgentMasterFile.xlsx');
      this.alert.hideLoader();
    }


  addOrUpdateAgentMaterForm(agentTemp:Agent,submitBtnText:String) {
    console.log("agentTemp in edit or add:",agentTemp)
    let agentMasterDao:Agent={accountId: this.currentUser.accountId, agentName: "", agentNumber: "", id: 0,agentId:0,createDateInt:0,createDate:""}
    if (agentTemp != undefined){
      agentMasterDao = agentTemp
      console.log("agentMaster",agentMasterDao)
    }

    let dialogRef =  this.dialog.open(AgentMasterFormComponent,{width: '140vw',
      maxHeight: '95vh',
      data:{agentMasterDao,submitBtnTxt:submitBtnText}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao; ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe agent Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }

  copy(data:Agent)
  {
    this.copyToClipboard(environment.withoutGatewayAppUrl+data.agentId)
    this.alert.showSuccesMessage("Succesfully Copied Link!!")
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }



  cancel() {
    this.data = null;
  }

  downloadFile(){
    // this.alert.showLoader();

    this.agentServiceService.getReport(this.currentUser.accountId,this.startdate,this.enddate, this.offset, this.limit).subscribe(value => {
      console.log("data : ", value.data);
      const file = new FileService<any>();
      const trArr = [];



      // Push headers as the first row
      trArr.push(['Agent Id', 'Agent Name', 'Agent Number', 'Account Id']);

      // Populate rows
      for (let i = 0; i < value.data.length; i++) {
        const rowData = [];

        // Push clientName, Client Number, Agent Id, Create Date
        rowData.push(value.data[i].agentId);
        rowData.push(value.data[i].agentName);
        rowData.push(value.data[i].agentNumber);
        rowData.push(value.data[i].accountId);
        trArr.push(rowData);
      }

      console.log(trArr);
      file.generateFileWithoutHyperLink(trArr, 'AgentMaster.xlsx');
      // this.alert.hideLoader();
    });

  }
}
