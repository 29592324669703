import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MediaService} from '../../../MediaService/media.service';
import {MessageInfoComponent} from '../../../conversation/message-info/message-info.component';
import {MessageDao, MessageType} from '../../DaoMessageUn';
import {MessageInfoWunComponent} from '../message-info-wun/message-info-wun.component';

@Component({
  selector: 'app-message-objun',
  templateUrl: './message-objun.component.html',
  styleUrls: ['./message-objun.component.css']
})
export class MessageObjunComponent implements OnInit {
  constructor(private dialog: MatDialog, private mediaService: MediaService) { }
  @Input('messageDao')messageDao: MessageDao;
  className: string;
  getChatType(): string
  {
    console.log(this.messageDao);
    // tslint:disable-next-line:triple-equals
    if (this.messageDao.chatSide != null && this.messageDao.chatSide != undefined && this.messageDao.chatSide == 'User')
    {
       return 'recievedChat';
    }


    else{
      return 'sentChat';
    }
  }

  // tslint:disable-next-line:typedef
  checkImageMessage(messageDao: MessageDao) {
    // tslint:disable-next-line:triple-equals
    return messageDao.templateButtonMessage != undefined;
  }
  // checkVideoMessage(messageDao: MessageDao) {
  //   return messageDao.video != undefined;
  // }
  // checkAudioMessage(messageDao: MessageDao) {
  //   return messageDao.audio != undefined;
  // }
  // // tslint:disable-next-line:typedef
  // checkDocumentMessage(messageDao: MessageDao) {
  //   return messageDao.document != undefined;
  // }



  ngOnInit(): void {
   this.className = this.getChatType();
  }

  // tslint:disable-next-line:typedef
  openMessageDetails(messageDao: MessageDao) {
    this.dialog.open(MessageInfoWunComponent, {data: messageDao});
  }



  // handleNull(name: string) {
  //   if (name == null)
  //   {
  //     return '';
  //   }
  // }

  // tslint:disable-next-line:typedef
  checkTextMessage(messageDao: MessageDao) {
    // tslint:disable-next-line:triple-equals
  if (messageDao.messageType == MessageType.TEXT && messageDao.textMessage != null)
    {
      console.log('content message' + messageDao.textMessage.content);
      return true;
    }
  return false;
  }

  // tslint:disable-next-line:typedef
  // isPlanTypeCase(): boolean {
  //   // tslint:disable-next-line:triple-equals
  //   return this.messageDao == this.type.FILE;
  // }
  // tslint:disable-next-line:typedef
  downloadMedia( fileUrl: any) {

    window.location = fileUrl;
    console.log('file url is:: ', fileUrl);
  }


  // tslint:disable-next-line:typedef
  checkMessageType(messageDao: MessageDao) {
    console.log('checkMessageType method call');
    // tslint:disable-next-line:triple-equals
    // if (messageDao.messageType == MessageType.TEMPLATEBUTTON && messageDao.messageType != null) {
    // return true;
    // }
    // tslint:disable-next-line:triple-equals
    if (messageDao.messageType == MessageType.TEMPLATEBUTTONIMAGE && messageDao.messageType != null) {
      return true;
    }
    return false;
  }
}
