



<div class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">


          <button (click)="addNew()"  class="btn btn-add">Add  Proxy<i
            class="fa fa-plus"></i></button>

          <br>




        </div>
      </div>
      <div style="" class="panel-body">
        <div  *ngIf="addNewDiv" >
          <form ngNativeValidate (submit)="isOrUpdate()">

            <div class="form-group col-lg-12">
              <label>Active </label>
              <mat-slide-toggle [(ngModel)]="proxy.active" name="active"></mat-slide-toggle>
            </div>


            <div class="form-group col-lg-12">
              <label>Proxy Username <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="proxy.username" name="username" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Password <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="proxy.pass" name="pass" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Proxy <sup class="required">*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="proxy.proxy" name="proxy" required>
            </div>
            <div class="form-group col-lg-12">
              <label>Port <sup class="required">*</sup></label>
              <input type="number" class="form-control" [(ngModel)]="proxy.port" name="port" required>
            </div>











            <div class="form-group col-lg-12">
              <button type="submit" class="btn btn-add">Save</button>
              <button type="button" (click)="cancel()" class="btn btn-add">Cancel</button>
            </div>
          </form>
        </div>
        <div *ngIf="!addNewDiv">


          <table  class="table table-striped row-border hover">
            <thead>
            <tr>
              <th>Proxy</th>
              <th>Username</th>
              <th>Port</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="tableList.total==0">
              <td colspan="9" style="text-align: center">No Record</td>
            </tr>
            <ng-container *ngFor="let data of tableList.data;let ind =index">
              <tr>
                <td [innerHTML]="data.proxy"></td>


                <td [innerHTML]="data.username"></td>
                <td [innerHTML]="data.port"></td>
                <td> <app-common-active-or-disable [active]="data.active"></app-common-active-or-disable>   </td>
                <td>

                  <i title="Click Here To Edit" (click)="edit(data)"
                     class="btn btn-add fa fa-pencil"></i>




                </td>


              </tr>


            </ng-container>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</div>







