<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Popular Plans">
    <div class="container">
      Basic
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index " >
        <mat-card *ngIf="subScriptionPackageDao.planType == 'BASIC' && subScriptionPackageDao.subscriptionPackageType == 'NORMAL'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Standard
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card *ngIf="subScriptionPackageDao.planType == 'STANDARD' && subScriptionPackageDao.subscriptionPackageType == 'NORMAL'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rental:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>
        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Advanced
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card *ngIf="subScriptionPackageDao.planType == 'ADVANCED' && subScriptionPackageDao.subscriptionPackageType == 'NORMAL'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab label="Recommended Plans">
    <div class="container">
      Basic
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index " >
        <mat-card *ngIf="subScriptionPackageDao.planType == 'BASIC' && subScriptionPackageDao.subscriptionPackageType == 'RECOMMENDED'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Standard
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card *ngIf="subScriptionPackageDao.planType == 'STANDARD' && subScriptionPackageDao.subscriptionPackageType == 'RECOMMENDED'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Advanced
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card *ngIf="subScriptionPackageDao.planType == 'ADVANCED' && subScriptionPackageDao.subscriptionPackageType == 'RECOMMENDED'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab label="TopUp Plans">
    <div class="container">
      Basic
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index " >
        <mat-card style="text-align: center" *ngIf="subScriptionPackageDao.planType == 'BASIC' && subScriptionPackageDao.subscriptionPackageType == 'ONLYCREDIT'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
<!--          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>-->
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Standard
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card style="text-align: center" *ngIf="subScriptionPackageDao.planType == 'STANDARD' && subScriptionPackageDao.subscriptionPackageType == 'ONLYCREDIT'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
<!--          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>-->
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button class="btn btn-add" style="alignment-baseline: center" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
    <div class="container">
      Advanced
      <ng-container *ngFor=" let subScriptionPackageDao of this.tableList;let i=index ">
        <mat-card *ngIf="subScriptionPackageDao.planType == 'ADVANCED' && subScriptionPackageDao.subscriptionPackageType == 'ONLYCREDIT'" (click)="moreInfo(subScriptionPackageDao)">
          <span>Platform Rent:- {{subScriptionPackageDao.rentalAmount}}</span>
<!--          <span>Plan Expiry:- {{subScriptionPackageDao.expireyDateType}}</span>-->
          <span>Country:- {{subScriptionPackageDao.country}}</span>
          <span>Recharge Amount:- {{subScriptionPackageDao.rechargeAmount}}</span>
          <span>Package Amount:- {{subScriptionPackageDao.rechargeAmount + subScriptionPackageDao.rentalAmount}}</span>
          <button style="alignment-baseline: center" class="btn btn-add" (click)="moreInfo(subScriptionPackageDao)">More Details</button>

        </mat-card>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>
