

  <div class="form-group col-lg-12">
    <label>Message {{components.nameOfParams}} Type  <sup class="required">*</sup></label>
    <div class="col-sm-12">
      <mat-radio-group [(ngModel)]="components.type"   aria-label="Select an option">
        <mat-radio-button value="text" >Text</mat-radio-button>
        <mat-radio-button value="image" >Image</mat-radio-button>
        <mat-radio-button value="document" >Document</mat-radio-button>
        <mat-radio-button value="video" >Video</mat-radio-button>
        <mat-radio-button value="currency" >Currency</mat-radio-button>
        <mat-radio-button value="date_time" >Date Time</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-group col-lg-12">

    <div class="col-sm-12">
      <ng-container *ngIf="components.type=='text'">
        <label>Text  <sup class="required">*</sup></label>
        <input type="text" class="form-control" name="textformat" [(ngModel)]="components.text" required>
      </ng-container>
      <ng-container *ngIf="components.type=='image'">
        <label>Image Url  <sup class="required">*</sup></label>
        <input type="url" class="form-control" name="imageurl" (input)="validateInput()" [(ngModel)]="components.image.link" required>
        <label>Image Name  <sup class="required">*</sup></label>
        <input pattern="^[0-9a-zA-Z.]*$" (input)="validateInput()" [maxLength]="25" placeholder="Not contains any special character except dot" type="text" class="form-control" name="imagename" [(ngModel)]="components.image.filename" required>

      </ng-container>

      <ng-container *ngIf="components.type=='document'">
        <label>Document Url  <sup class="required">*</sup></label>
        <input type="url" class="form-control" name="documenturl" [(ngModel)]="components.document.link" required>
        <label>Document Name  <sup class="required">*</sup></label>
        <input pattern="^[0-9a-zA-Z.]*$" (input)="validateInput()" [maxLength]="25" placeholder="Not contains any special character except dot" type="text" class="form-control" name="documentname" [(ngModel)]="components.document.filename" required>

      </ng-container>

      <ng-container *ngIf="components.type=='video'">
        <label>Video Url  <sup class="required">*</sup></label>
        <input type="url" class="form-control" name="videourl" [(ngModel)]="components.video.link" required>
        <label>Video Name  <sup class="required">*</sup></label>
        <input pattern="^[0-9a-zA-Z.]*$" (input)="validateInput()" [maxLength]="25" placeholder="Not contains any special character except dot" type="text" class="form-control" name="videoname" [(ngModel)]="components.video.filename" required>
      </ng-container>
      <ng-container *ngIf="components.type=='currency'">
        <label>Fallback Value  <sup class="required">*</sup></label>
        <input type="text" class="form-control" name="currencyfallback_value" [(ngModel)]="components.currency.fallback_value" required>
        <label>Currency Code  <sup class="required">*</sup></label>
        <select class="form-control" name="currencyCode" [(ngModel)]="components.currency.code">
          <ng-container *ngFor="let curr of currency">
            <option [value]="curr.currency_code" [innerHTML]="curr.country">

            </option>
          </ng-container>
        </select>

        <label> Amount  <sup class="required">*</sup></label>
        <input type="url" class="form-control" name="amount_1000" [(ngModel)]="components.currency.amount_1000" required>

      </ng-container>
      <ng-container *ngIf="components.type=='date_time'">
        <label>Fallback Value  <sup class="required">*</sup></label>
        <input type="text" class="form-control" name="date_timefallback_value" [(ngModel)]="components.date_time.fallback_value" required>
        <label>Datiming(YYYY-MM-DD HH:MM)  <sup class="required">*</sup></label>
        <input type="text" (ngModelChange)="convertDate()" class="form-control" name="date_timefallback_value" [(ngModel)]="components.date_time.fullDate" required>
      </ng-container>
    </div>
  </div>
