import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubscriptionPackageService} from "./subscription-package.service";
import {SubScriptionPackageDao} from "../subscription-package/SubScriptionPackageDao";
import {Subject} from "rxjs";
import {AlertMessageService} from "../alert-message/alert-message.service";
// import * as $ from "jquery";
import {PlanType} from "../subscription-package/PlanType";
import {SubscriptionPackageType} from "../subscription-package/SubscriptionPackageType";
import {ExpiryDateType} from "../subscription-package/ExpiryDateType";
import {CountryWisePriceListDao} from "../client-sign-up/CountryWisePriceDaoList";
import {HttpClient} from "@angular/common/http";
import {CommoninstancedropdownComponent} from "../Unofficial/commoninstancedropdown/commoninstancedropdown.component";
import {MatDialog} from "@angular/material/dialog";
import {SubscriptionFilterDao} from "./SubscriptionFilterDao";
import {environment} from "../../environments/environment.prod";

@Component({
  selector: 'app-subscription-package-report',
  templateUrl: './subscription-package-report.component.html',
  styleUrls: ['./subscription-package-report.component.css']
})
export class SubscriptionPackageReportComponent implements OnInit, OnDestroy{

  tableList: Array<SubScriptionPackageDao>
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {'bDestroy': true, 'order': []};
  status: boolean = true;
  public SubscriptionPackageType = SubscriptionPackageType;
  public ExpiryDateType = ExpiryDateType;
  public PlanType = PlanType;
  filterDao:SubscriptionFilterDao = {
    startdate:'0',
    enddate:'0',
    offset:0,
    limit:10,
    accountId:0,
    groupBy:'',
    dateFilterType: '0',
    services:'',
    status:true
  };

  subScriptionPackageDao: SubScriptionPackageDao = {
    id: 0,
    createDate: "0",
    createDateFilter: 0,
    updateDate: "0",
    accountId: 0,
    rentalAmount: 0,
    rechargeAmount: 0,
    roleId: 0,
    remarks: "",
    status: true,
    expireyDateType:ExpiryDateType.MONTHLY,
    planType:PlanType.BASIC,
    subscriptionPackageType: SubscriptionPackageType.NORMAL,
    country:'IND',
    marketingRates: 0,
    utilityRates: 0,
    authenticationRates: 0,
    userInitiatedRates: 0,
    accessToken:'',
    gst:0,
    totalAMount:0,
    razorPayAmount:0,
    amountWithGST:0,
  }
  countryWithPricesList: Array<CountryWisePriceListDao>;
  constructor(private dialog: MatDialog, private http: HttpClient, private subscriptionPackageService: SubscriptionPackageService, private alert: AlertMessageService) {
    this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl+ environment.whatsappOfficialservice + "api/countrywisepricelist/getall").subscribe(value => {
      this.countryWithPricesList = value;
    });
    this.loadData();
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  filterData() {
    this.dialog.open(CommoninstancedropdownComponent, {data: []}).afterClosed().subscribe(value => {
      if (value != null) {
        this.filterDao.startdate = value.startdate.replaceAll("-","");
        this.filterDao.enddate = value.enddate.replaceAll("-","");
        this.filterDao.status = value.status;
        this.loadData();
      }
    });
  }



  loadData(){
    this.alert.showLoader();
    if(this.filterDao.startdate == '0' && this.filterDao.enddate == '0'){
      let date = new Date();
      let monthStr = (date.getMonth() + 1).toString();
      let onlyDate: string = date.getDate().toString();
      if(monthStr.length != 2){
        monthStr = '0' + monthStr ;
      }
      if(onlyDate.length!=2){
        onlyDate = '0' + onlyDate;
      }
      this.filterDao.startdate = '20220101';
      this.filterDao.enddate = date.getUTCFullYear().toString() + monthStr + onlyDate;
    }
    $('#tableDiv').DataTable().destroy();
    this.subscriptionPackageService.getstatusWiseReport(this.filterDao).subscribe(value => {
      this.tableList = value;
      this.dtTrigger.next();
      this.alert.hideLoader();
    });
  }

  downloadReport() {

  }
  clearFilter() {
    this.status = true;
    this.subScriptionPackageDao = {
      id: 0,
      createDate: "0",
      createDateFilter: 0,
      updateDate: "0",
      accountId: 0,
      rentalAmount: 0,
      rechargeAmount: 0,
      roleId: 0,
      remarks: "",
      status: true,
      expireyDateType:ExpiryDateType.MONTHLY,
      planType:PlanType.BASIC,
      subscriptionPackageType: SubscriptionPackageType.NORMAL,
      country:'IND',
      marketingRates: 0,
      utilityRates: 0,
      authenticationRates: 0,
      userInitiatedRates: 0,
      accessToken:'',
      gst:0,
      totalAMount:0,
      razorPayAmount:0,
      amountWithGST:0
    }
    this.filterDao = {
      startdate:'0',
      enddate:'0',
      offset:0,
      limit:10,
      accountId:0,
      groupBy:'',
      dateFilterType: '0',
      services:'',
      status:true,
    };
    this.loadData();
  }

  addSubscription() {
     let packageAmountGST = Number((this.subScriptionPackageDao.rechargeAmount + this.subScriptionPackageDao.rentalAmount)*18)/100;
    this.subScriptionPackageDao.amountWithGST = this.subScriptionPackageDao.rechargeAmount + this.subScriptionPackageDao.rentalAmount + packageAmountGST;
    this.subScriptionPackageDao.razorPayAmount = (this.subScriptionPackageDao.amountWithGST*2)/100;
    // this.subScriptionPackageDao.gst =Number(((this.subScriptionPackageDao.razorPayAmount*18)/100).toFixed(2));
    this.subScriptionPackageDao.totalAMount =Number((this.subScriptionPackageDao.amountWithGST + this.subScriptionPackageDao.razorPayAmount).toFixed(2));
    this.alert.confirmMessage('Add Subscription Package', 'Are you sure?').then(value => {
      this.subscriptionPackageService.insert(this.subScriptionPackageDao).subscribe(value1 => {
        this.alert.showSuccesMessage("Subscription Package Added");
        this.clearFilter();
        this.cancel();
      }, error => {
        this.alert.showErrorMessage("Error Occured" +  error);
      });
    });
  }
  openSubscription() {
    $('#tabview').css('display', 'none');
    $('#addnewdiv').fadeIn();
  }

  cancel() {
    $('#addnewdiv').css('display', 'none');
    $('#tabview').fadeIn();
  }

  changeStatus(subScriptionPackageDao:SubScriptionPackageDao) {
    this.alert.confirmMessage('Change Subscription Status', 'Are you sure?').then(value => {
      this.subscriptionPackageService.changeStatus(subScriptionPackageDao).subscribe(value1 => {
        this.alert.showSuccesMessage("Subscription Package Status Changed");
        this.loadData();
      }, error => {
        this.alert.showErrorMessage("Error Occured" +  error);
      });
    });
  }

  getByStatus(status: boolean) {
    this.filterDao = {
      startdate:'0',
      enddate:'0',
      offset:0,
      limit:10,
      accountId:0,
      groupBy:'',
      dateFilterType: '0',
      services:'',
      status:status
    };
    this.loadData();
  }
}
