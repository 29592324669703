import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AssignAgentModel, ConversationDao} from './ConversationDao';
import {ConversationService} from './ConversationService/conversation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FilterDao} from '../crdrreport/FilterDao';
import {MessageDao} from '../ws-template/MessageDao';
import {MatDialog} from '@angular/material/dialog';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {ContactFormDao} from './contact-form/ContactFormDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {QuickReplyMessageComponent} from '../quick-reply-message/quick-reply-message.component';
import {ClientSidePagination} from '../CommonObj/ClientSidePagination';
import {PaginationDao} from '../CommonObj/PaginationDao';
import {ConversationSubscriptionService} from './ConversationSubscription/ConversationSubscriptionService';
import {interval, Subject, Subscription} from 'rxjs';
import {CampaingService} from '../CampainService/campaing.service';
import { UserMasterDao } from '../Services/User/UserMasterDao';
import { UserService } from '../Services/User/user.service';
import { RolePrivServiceService } from '../account-master/role-and-priv/RolePriv/role-priv-service.service';
import { RoleServiceService } from '../rolemaster/RoleService/role-service.service';
import { AccountMasterService } from '../account-master/AccountMasterService/account-master.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit, OnDestroy,AfterViewInit {

  isAgentSupport:boolean = false;
  ddlAgents = [];
  selectedAgent = []

  assignAgentParam:AssignAgentModel;

  ddlAgentTxtColor:string = 'Black';

  countryCodeList:Array<any> = [{"numberCode":"91","countryCode":"IND"},{"numberCode":"92","countryCode":"PAK"},{"numberCode":"54","countryCode":"ARG"},{"numberCode":"1","countryCode":"USA"},{"numberCode":"55","countryCode":"BRA"},{"numberCode":"49","countryCode":"DEU"},{"numberCode":"62","countryCode":"IDN"},{"numberCode":"39","countryCode":"ITA"},{"numberCode":"966","countryCode":"SAU"},{"numberCode":"34","countryCode":"ESP"},{"numberCode":"971","countryCode":"ARE"},{"numberCode":"44","countryCode":"GBR"}];

  // tslint:disable-next-line:max-line-length
  constructor(public user: UserService,private conversationService: ConversationService, private campservice: CampaingService,
    private conversationSub: ConversationSubscriptionService, private alertService: AlertMessageService, private router: Router,
     private route: ActivatedRoute, private dialog: MatDialog,private roleService:RoleServiceService,
     private rolePriv:RolePrivServiceService,private accountService: AccountMasterService) {
    this.searchStr = '';

      this.assignAgentParam = new AssignAgentModel();

    this.loadData(0, 10, 0);

    this.getUserRoles();
    this.getUserAgents();



  }
  isDiv1Visible: boolean = true; // Div 1 is always visible
  isDiv2Visible: boolean = true; // Div 2 hidden by default
  isLargeScreen: boolean // To detect if the screen is large

  // Detect screen resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 764;
    this.isDiv1Visible = true; // Div 1 is always visible
    this.isDiv2Visible = this.isLargeScreen; // Div 2 visible only on large screens
  }
  
  toggleDiv2() {
    [this.isDiv1Visible, this.isDiv2Visible] = [!this.isDiv1Visible, !this.isDiv2Visible];
  }

  ngAfterViewInit(): void {
    let ths = this;
    $('#chatconversationdiv').scroll(function() {
      var pos = $('#chatconversationdiv').scrollTop();
      if (pos == 0) {
        // ths.loadChats(ths.offset, ths.limit, ths.conversationDao.id, false,false);
      }
    });

    if(this.searchStr == ''){
      this.searchStr='0'
    }

    this.subscription = this.conversationSub.getObj().subscribe(value => {
      if (value != null)
      {
        if (value.isNew) {
          console.log('Will Load Conversation From Server',this.searchStr,this.offset);
          if(this.searchStr!='0' || this.offset==0) {
            console.log('offeset =0');

            this.conversationService.getConversation(this.searchStr, 0, this.limit).subscribe(data => {
              this.conversationList = {data: data.data, end: this.limit, start: 0, total: data.total};
              // this.conversationList = {data: data.data, end: 10, start: 0, total: data.total};

              if (this.conversationDao != null) {
                // ths.loadChats(this.offset+1, 10, ths.conversationDao.id, true,true);

                this.updateReadStatusLocallyAndGlobally(this.conversationDao.id);
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < value.data.length; i++) {
                  // tslint:disable-next-line:triple-equals
                  if (value.data[i].id == this.conversationDao.id) {

                    // console.log('Will Read New Chat after ', value.lastMessageTime);
                    this.conversationService.getMessageAfterTime(this.conversationDao.id, value.lastMessageTime).subscribe(messages => {
                      for (let m = 0; m < messages.length; m++) {

                        this.messageList.push(messages[m]);
                        this.messageList = this.messageList.sort((a, b) => a.dateFilterLong - b.dateFilterLong);
                      }
                      // tslint:disable-next-line:only-arrow-functions typedef


                      setTimeout(function () {
                        // window.alert('Window on Top');

                        $('#chatconversationdiv').stop().animate({
                          scrollTop: $('#chatconversationdiv')[0].scrollHeight
                        }, 800);
                      }, 1000);
                    });
                  }
                }
              }

            });
          }

        }
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      console.log('Query PArams are ', queryParams);
      const id = queryParams.id;
      if (id != undefined)
      {
        let savedSts = false;
        if (queryParams.contactName == 'true')
        {
          savedSts = true;
        }
        this.conversationDao = {
          id,
          lastMessage: queryParams.lastMessage,
          lastMessageTime: queryParams.lastMessageTime,
          lastMessageType:  queryParams.lastMessageType,
          totalMessage:  queryParams.totalMessage,
          unread:  queryParams.unread,
          unreadMessage: queryParams.unreadMessage,
          contactName: queryParams.contactName,
          saved: savedSts,
        };

        this.updateReadStatusLocallyAndGlobally(id);
        this.loadChats(this.offset, this.limit, id, true);
      }
      else{
        this.conversationList = null;
      }


    });
  }


  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {  bDestroy: true, order: []};

  textMessage = '';
  conversationList: ClientSidePagination<ConversationDao> =  null;
  conversationDao: ConversationDao = null;
  messageList: Array<MessageDao> = [];
  offset = 0;
  limit = 10;
  searchStr = '';
  loadMoreEnable = true;
  isLoadeMore: boolean;

  private subscription: Subscription;



  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dtTrigger.unsubscribe();

  }

  assignAgent(){
    if(this.conversationDao == null){
      this.alertService.showErrorMessage("Please select chat to assign agent!!");
    }
    else if(this.assignAgentParam.agentAccountId == undefined){
      this.alertService.showErrorMessage("Please select agent to assign chat!!");
    }
    else{
      this.assignAgentParam.assignAgent = true;
      this.assignAgentParam.mobileNumber = this.conversationDao.id;

      this.assignAgentParam.agentAccountId = parseInt(this.assignAgentParam.agentAccountId.toString());
      this.assignAgentParam.adminAccountId = this.user.accountMasterDao.id;

      this.conversationService.assignAgentSupport(this.assignAgentParam).subscribe((data)=>{
        this.alertService.showSuccesMessage("Agent assigned");
      },(error)=>{
        this.alertService.showErrorMessage("Agent not assigned");
      });
    }
  }

  getUserRoles(){
    this.roleService.getRoles().subscribe(value => {

      this.rolePriv.getByUser(this.user.accountMasterDao.id).subscribe(value1 => {

        for(var i=0;i<value.length;i++)
        {
          value[i].checked=value1.some(item => item.roleId === value[i].id);
          if(value[i].roleName == "UpdateAgentSupport"){
            this.isAgentSupport = value[i].checked;
          }
        }
        // this.roleMasterDao=value;

        console.log("asd ",value);


      });
    });
  }

  getUserAgents(){
    this.accountService.getUserAgents(this.user.accountMasterDao.id).subscribe(value => {
      this.ddlAgents = value;
    });
  }


  // tslint:disable-next-line:typedef
  loadData(offset, limit, search)
  {
    if (search=='Search')
    {
      search='0'
    }
    if (search.length == 0)
    {
      search = '0';
      offset=this.offset

    }
    // this.alertService.showLoader();
    this.conversationService.getConversation(search, offset, limit).subscribe(value => {
      this.conversationList = {data: value.data, end: limit, start: offset, total: value.total};

      console.log('conversationdata',this.conversationList);
      this.dtTrigger.next();
      // this.alertService.hideLoader();
    });

  }


  // tslint:disable-next-line:typedef
  loadChats(offset, limit, id, isNew, scroll= true) {
    this.offset = offset;
    this.limit = limit;
    if (isNew)
    {
      this.messageList = [];
      offset = 0;
      limit = 10;
    }



    this.loadMoreEnable = this.conversationDao.totalMessage >= ((offset) * limit);
    // this.alertService.showLoader();
    this.conversationService.getMessages(offset, limit, id).subscribe(value => {
      const len = value.length;
      for (let i = 0; i < len; i++)
      {
        // this.messageList.unshift(value[i]);
        this.messageList.push(value[i]);
        this.messageList = this.messageList.sort((a, b) => a.dateFilterLong - b.dateFilterLong);

      }
      if (scroll) {
        setTimeout(function() {
          $('#chatconversationdiv').stop().animate({
            scrollTop: $('#chatconversationdiv')[0].scrollHeight
          }, 800);
        }, 1000);
      }

      // this.alertService.hideLoader();
    });
  }

  ngOnInit(): void {
    this.checkScreenSize();
  }
  updateReadStatusLocallyAndGlobally(id: string)
  {

    this.conversationService.updateReadStatus(id).subscribe(value => {

      if (this.conversationList!=null)
      {
        for (let i = 0; i < this.conversationList.data.length; i++)
        {
          if (this.conversationList.data[i].id == id)
          {
            this.conversationList.data[i].unreadMessage = 0;
            this.conversationList.data[i].unread = false;
          }
        }
      }

    });
  }

  // tslint:disable-next-line:typedef
  takeMeToNumber(json: ConversationDao) {
    if(!this.isLargeScreen){
      this.toggleDiv2();
    }
    if(this.isAgentSupport){
      this.assignedAgentCheck(json.id);
    }

    this.router.navigate([], {queryParams: json});
  }

  assignedAgentCheck(mobileNumber:string){
    let getAssignedAgentModel = new AssignAgentModel;
    getAssignedAgentModel.adminAccountId = this.user.accountMasterDao.id;
    getAssignedAgentModel.mobileNumber = mobileNumber;

    this.assignAgentParam = new AssignAgentModel();

    this.ddlAgentTxtColor = 'Black'
    this.conversationService.getAssignedAgent(getAssignedAgentModel).subscribe((data)=>{
      debugger;

      if(data != null){
        this.assignAgentParam = data;
        if(this.assignAgentParam.assignAgent){
          this.ddlAgentTxtColor = 'Green';
        }
        else{
          this.ddlAgentTxtColor = 'Red';
        }
      }
    });
  }

  // searchDst() {
  //   console.log('Will Search ', this.searchStr);
  //   if (this.searchStr.length == 0)
  //   {
  //     this.loadData(0, 10, '0');
  //   }
  //   if (this.searchStr.length < 2)
  //   {
  //     return;
  //   }
  //
  //   this.loadData(0, 10, this.searchStr);
  // }

  saveContact() {
    const contactForm: ContactFormDao = {contactName: this.conversationDao.contactName, id: this.conversationDao.id};
    this.dialog.open(ContactFormComponent, {data: contactForm}).afterClosed().subscribe(value => {
      this.conversationDao.saved = true;
      this.conversationDao.contactName = contactForm.contactName;
      // alert("save contact"+this.offset)
      this.loadData(this.offset,this.limit, this.searchStr);

      // this.loadData(0, this.limit, this.searchStr);
    });
  }

  editContact() {
    const contactForm: ContactFormDao = {contactName: this.conversationDao.contactName, id: this.conversationDao.id};
    this.dialog.open(ContactFormComponent, {data: contactForm}).afterClosed().subscribe(value => {
      this.conversationDao.saved = true;
      this.conversationDao.contactName = contactForm.contactName;
      // alert("edit contact"+this.offset)

      this.loadData(this.offset,this.limit, this.searchStr);

      // this.loadData(0, this.limit, this.searchStr);
    });
  }

  replyMessage() {
    this.dialog.open(QuickReplyMessageComponent, {data: this.conversationDao}).afterClosed().subscribe(value => {
      console.log('Reponse After Closed', value);

      if (value.messageId != undefined)
      {
        this.messageList.push(value);
        $('#chatconversationdiv').stop().animate({
          scrollTop: $('#chatconversationdiv')[0].scrollHeight
        }, 800);
      }

    });
  }
  tableChange(json, data: ClientSidePagination<any>) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    data.start = json.pageIndex;
    data.end = json.pageSize;
    if(this.searchStr==''){
      this.searchStr='0';
    }
    console.log("limt and offset in tabel change",this.limit,this.offset)
    this.loadData(data.start, data.end, this.searchStr);

  }

  autoRefresh(current)
  {
    current.loadData(this.conversationList.start, this.conversationList.end, '0');
    if (current.conversationDao != null)
    {
      current.loadChats(current.offset, current.limit, current.conversationDao.id, false);
    }
  }

  findCountryCode(){
    for(let i =0;i<this.countryCodeList.length;i++){
      console.log("Country List is ",this.countryCodeList[i].numberCode);
      if(this.conversationDao.id.startsWith(this.countryCodeList[i].numberCode)){
        console.log("Country List selected is ",this.countryCodeList[i].numberCode);
        return this.countryCodeList[i].countryCode;
      }
    }
  }
  // tslint:disable-next-line:typedef
  sendTextMessage() {
    if (this.textMessage.length == 0)
    {
      return;
    }
    const message: MessageDao = {
      audio: null,
      campaingId: 0,
      chatSide: 'User',
      countryCode: this.findCountryCode(),
      date: '',
      dlrTime: '',
      document: null,
      image: null,
      messageId: 'SENT',
      messageStatus: 'SENT',
      template: null,
      text: {body: this.textMessage},
      to: this.conversationDao.id,
      type: 'text',
      video: null,
      viewTime: '',
      isPanel: true,
      interactive: null,
      attachementBase64: '',
      mimeType:'',
      iScheduled:0,
      scheduleTime:null,
      button:null,
      catalogMessageDao:null,
      location:null,
      messageContent:'',
      dateFilterLong:0,
      profileName:''
    };


    this.campservice.sendMultipleMessage([message]).subscribe(value => {
      message.messageId = value.ids[0];
      this.messageList.push(message);
      this.textMessage = '';
      $('#chatconversationdiv').stop().animate({
        scrollTop: $('#chatconversationdiv')[0].scrollHeight
      }, 800);
    });

  }

  @HostListener("chatconversationdiv")
  scrollFunction(){
    if(document.body.scrollTop>0 || document.documentElement.scrollHeight){
      //this.isLoadeMore=true;
      window.alert('Top...')
    }
    else {
      this.isLoadeMore=false;
    }

  }

}
