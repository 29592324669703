<div class="row">

  <ng-container *ngFor="let service of serviceArr">

    <mat-card style="margin: 10px;min-height: 300px;max-height: 300px"  class="col-xs-11 col-sm-12 col-md-4 col-lg-2 " >
      <mat-card-title style="text-align: center">

        <img class="img-circle fa-align-center" width="70" height="70" [src]="service.logo">
      </mat-card-title>

      <mat-card-subtitle style="text-align: center">
        <b [innerHTML]="service.serviceName"> </b>
      </mat-card-subtitle>

      <mat-card-content style="text-align: center" [innerHTML]="service.descrip">


      </mat-card-content>
      <mat-card-footer>
        <button style="    position: absolute;width: 100%;
    bottom: 0;" title="Click Here To Select Service As Default" (click)="makeDefaultApplication(service)" class="btn btn-add center-block">Select Service</button>
      </mat-card-footer>


    </mat-card>
    <ng-container></ng-container>

  </ng-container>
</div>
