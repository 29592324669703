import { Component, OnInit } from '@angular/core';
import {PaginationMain} from "../../CommonObj/PaginationDao";
import {Agent} from "../../AgentSupport/AgentMaster/agent-master/AgentDao";
import {UserMasterDao} from "../../Services/User/UserMasterDao";
import {UsermasterService} from "../../Services/UsermasterService/usermaster.service";
import {AgentServiceService} from "../../AgentSupport/AgentMaster/agent-service.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertMessageService} from "../../alert-message/alert-message.service";
import {ExcelReaderCommonComponent} from "../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {FileService} from "../../Services/FileUtilsService/FileService";
import {AgentMasterFormComponent} from "../../AgentSupport/AgentMaster/agent-master-form/agent-master-form.component";
import {ExtensionWiseAgent} from "../ExtensionWiseAgent";
import {ExtensionWiseAgentService} from "../extension-wise-agent.service";
import {ExtensionWiseAgentFormComponent} from "../extension-wise-agent-form/extension-wise-agent-form.component";

@Component({
  selector: 'app-extension-wise-agent',
  templateUrl: './extension-wise-agent.component.html',
  styleUrls: ['./extension-wise-agent.component.css']
})
export class ExtensionWiseAgentComponent implements OnInit {

  extensionWiseAgents: PaginationMain<ExtensionWiseAgent> = {data: [], recordsFiltered: 0, recordsTotal: 0};
  currentUser:UserMasterDao
  offset: number = 0;
  limit: number = 10;
  private data: ExtensionWiseAgent;


  constructor(private userMasterService:UsermasterService, private extensionWiseAgentService: ExtensionWiseAgentService, private dialog: MatDialog, private alert: AlertMessageService) { }

  ngOnInit(): void {
    this.userMasterService.getCurrentUser().subscribe(data => {
      this.currentUser = data;
      console.log("current user", this.currentUser)
      this.loadData();
    });
  }



  loadData()
  {
    this.extensionWiseAgentService.getReport(this.currentUser.accountId, this.offset, this.limit).subscribe(data => {
      console.log("data : ", data)
      this.extensionWiseAgents = data;
      console.log("chatbotlist:", this.extensionWiseAgents)

    });
  }
  tableChange(json) {
    console.log("json",json)
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  deleteExtension(id) {
    this.alert.confirmMessage("delete this Extension??",'Are you sure?').then(response=>{
        if (response=='success'){
          this.extensionWiseAgentService.deleteExtension(id).subscribe(value => {
            console.log("id",value)
            this.alert.showSuccesMessageWithoutReload("deleted")
            this.loadData();
          });
        }
        else{
        }
      }
    );
  }


  addOrUpdateAgentMaterForm(agentTemp:ExtensionWiseAgent,submitBtnText:String) {
    console.log("agentTemp in edit or add:",agentTemp)
    let extensionWiseAgent:ExtensionWiseAgent={id:0,accountId: this.currentUser.accountId, agentName: "", agentNumber: "", extenstion: ""}


    if (agentTemp != undefined){
      extensionWiseAgent = agentTemp
      console.log("extensionWiseAgent",extensionWiseAgent)
    }

    let dialogRef =  this.dialog.open(ExtensionWiseAgentFormComponent,{width: '140vw',
      maxHeight: '95vh',
      data:{extensionWiseAgent,submitBtnTxt:submitBtnText}
    });
    dialogRef.componentInstance.eventEmitter.subscribe((event)=>{
      console.log(" dao; ",event)
      this.loadData();
    })

    dialogRef.afterClosed().subscribe(()=>{
      console.log("subscribe agent Master after dao")
      dialogRef.componentInstance.eventEmitter.unsubscribe();
    })

  }




  cancel() {
    this.data = null;
  }
}
