import {Component, Input, OnInit} from '@angular/core';
import {MessageDao} from "../../../ws-template/MessageDao";
import {MatDialog} from "@angular/material/dialog";
import {MediaService} from "../../../MediaService/media.service";
import {MessageInfoComponent} from "../../../conversation/message-info/message-info.component";
import {Conversation} from "../Conversation";

@Component({
  selector: 'app-agent-client-conversation-obj',
  templateUrl: './agent-client-conversation-obj.component.html',
  styleUrls: ['./agent-client-conversation-obj.component.css']
})
export class AgentClientConversationObjComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('conversation') conversation: Conversation;

  documentIcon: any;




  className: string;

  getChatType(): string {
    if (this.conversation.type == null || this.conversation.type == undefined || this.conversation.type == 'AGENT') {
      return 'sentChat';
    } else {
      return 'recievedChat';
    }
  }

  constructor(private dialog: MatDialog, private mediaService: MediaService) {
  }


  ngOnInit(): void {
    this.className = this.getChatType();
    console.log("chattype:",this.className)

  }

  // tslint:disable-next-line:typedef
  // openMessageDetails(messageDao: MessageDao) {
  //   this.dialog.open(MessageInfoComponent, {data: messageDao});
  // }


  // tslint:disable-next-line:typedef
  handleNull(name: string) {
    if (name == null) {
      return '';
    }
  }

  // tslint:disable-next-line:typedef
  checkTextMessage(messageDao: MessageDao) {

    // tslint:disable-next-line:triple-equal
    return messageDao.text != undefined;
  }

  // tslint:disable-next-line:typedef
  checkImageMessage(messageDao: MessageDao) {
    return messageDao.image != undefined;
  }

  checkVideoMessage(messageDao: MessageDao) {
    return messageDao.video != undefined;
  }

  checkAudioMessage(messageDao: MessageDao) {
    return messageDao.audio != undefined;
  }

  // tslint:disable-next-line:typedef
  checkDocumentMessage(fileUrl: string) {
    let fileFormat = "";

         let fileName = fileUrl.split('.');
         console.log("filename:",fileName)
      fileFormat = fileName[3];
      console.log("docuemnt",fileFormat)


    if (fileFormat == 'pdf') {
      console.log("test pdf msg")
      this.documentIcon = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png'

    } else if (fileFormat == 'xlsx') {
      this.documentIcon = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png'
    } else {
      this.documentIcon = 'src="assets/Images/document.png"'

    }
    console.log("documenticon",this.documentIcon)
    return  fileUrl!=undefined;

  }

  downloadMedia( fileUrl: any) {

    window.open(fileUrl, '_blank');
    console.log('file url is:: ', fileUrl);
  }



  checkChatbootMessage(messageDao: MessageDao) {
    return messageDao.interactive != undefined;
  }
}
