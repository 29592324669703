

<div  class="row">
  <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
         </div>
      </div>
      <div   style="" class="panel-body">
       <div *ngIf="!AddNewDiv">
        <div id="tablediv">
          <div class="table-responsive">
            <table  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-striped row-border hover">
              <thead>
              <tr>
                <th>Account Id</th>
                <th>Account Alias</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let account of tableList;let ind =index">
                <tr>
                  <td [innerHTML]="account.id"></td>
                  <td [innerHTML]="account.accountName"></td>
                  <td ><i *ngIf="account.active" style="color: green" class="fa fa-check"></i><i *ngIf="!account.active"  style="color: red" class="fa fa-remove"></i></td>
                  <td>
<!--                    <i title="Click Here To Edit" (click)="editAccount(account,ind)"  *ngIf="hasWriteOrUpdatePermission" class="btn btn-add fa fa-pencil">-->
<!--                  </i>-->

                    <i (click)="showCampaign(account.id)" title="Click Here To View Active Campaign" class="btn btn-add fa fa-pencil"></i>
<!--                    <i (click)="showCampaign(account.id)" title="Click Here To Stop Campaign" class="btn btn-add fa fa-pencil"></i>-->
<!--                    <i (click)="showCampaign(account.id)" title="Click Here To Stop Campaign"  class="btn btn-add fa fa-pencil"></i>                  </td>-->

                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
       </div>

        <div *ngIf="AddNewDiv">
                <div style="" class="panel-body">

                  <div  id="tabledivs">
                    <div class="table-responsive">
                      <table [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable class="table table-bordered table-striped table-hover">
                        <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>Total Recipts</th>
                          <th>Pending Recipts</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="text-align: center" *ngIf="tableData.total==0">
                          <td colspan="9">No Entry</td>
                        </tr>
                        <ng-container  *ngFor="let data of tableData.data;let ind = index">
                          <tr>
                            <td [innerHTML]="data.campaignName"></td>
                            <td [innerHTML]="data.count"></td>
                            <td [innerHTML]="data.pendingData"></td>
                            <td [innerHTML]="data.date"></td>
                            <td  >   <i (click)="stopCampaign(data.accountId)" title="Click Here To Stop Campaign" class="btn btn-danger fa fa-trash"></i>
                            </td>
                          </tr>
                        </ng-container>



                        </tbody>
                      </table>
                    </div>
                    <mat-paginator (page)="tableChange($event)" [length]=tableData.total
                                   [pageSize]="10"
                                   [pageSizeOptions]="[2, 5,10, 25, 100]">
                    </mat-paginator>

                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>

      </div>








