<div class="row">
  <div class="col-sm-4 col-md-4 chartPanelClass customsidedivmenu" style="margin-left: 300px">
    <div class="panel panel-bd lobidragdashboard">
      <div class="panel-heading">
        <div class="panel-title">
          <button style="margin-left: 5px"   class="btn btn-add " title="Bulk Upload (Maximum 1000)" data-toggle="modal" data-target="#exampleModal">Bulk Upload <i class="hvr-buzz-out fa fa-plus"></i>
          </button>
          <h3 style="text-align: center">Add Mail </h3>
        </div>
      </div>
      <div style="" class="panel-body">

        <form #groupMasterForm="ngForm" (ngSubmit)="createOrUpdate(groupMasterForm.value)">

          <div class="form-group col-lg-12">
            <input type="hidden" class="form-control"   [(ngModel)]="groupId" name="groupId" required>

          </div>
          <div class="form-group col-lg-12">
            <input type="hidden" class="form-control" [(ngModel)]="groupName" name="groupName" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Email<sup class="required">*</sup></label>
            <input type="text" class="form-control" [(ngModel)]="email" name="email" required>
          </div>
          <div class="form-group col-lg-12">
            <label>Status <sup class="required">*</sup></label>
            <!--            <input type="text" class="form-control"  [(ngModel)]="status" name="status" required>-->
            <select class="form-control"  [(ngModel)]="status" name="status" required>
              <option [value]="1">Active</option>
              <option [value]="0">In Active</option>
            </select>
          </div>


          <div class="form-group col-lg-12">
            <button type="submit" class="btn btn-add">Save</button>
            <button type="button"  (click)="cancel()" class="btn btn-add">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModal" aria-hidden="true" style="margin-top: 250px">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModa"><b>Bulk Upload Mail &nbsp;</b> <i  (click)="downloadSampleFile()" title="Click Here To View Sample File" class="fa fa-question-circle"></i>	</h2>
        <div class="modal-body">

          <form [formGroup] = "uploadForm" ngNativeValidate (submit)="submit()"  class="form-horizontal" >
            <!--          <form    class="form-horizontal" >-->
            <div  class="col-md-12 form-group">
              <label class="control-label">User</label>
              <select  id="userlistdatalist" class="form-control">
                <!--                <ng-container *ngFor="let userMasterDao of data.userList">-->
                <ng-container  >
                  <!--                  <option   [value]="userMasterDao.id" [innerHTML]="userMasterDao.username"></option>-->
                  <option   [value]=" this.groupId" [innerHTML]=" this.groupName  "></option>

                </ng-container>

              </select>
            </div>
            <div class="col-md-12 form-group">
              <label class="control-label">File</label>
              <input required class="form-control" name="file"  required  type="file"   accept=".xlsx" (change)="onFileSelect($event)" placeholder="Enter File To Upload" multiple/>

            </div>



            <div class="reset-button">
              <button  type="submit" class="btn btn-success">Upload</button>
              <button mat-dialog-close type="button" (click)="cancel()" class="btn btn-warning">Cancel</button>

            </div>


          </form>
        </div>

      </div>
    </div>
  </div>
</div>
