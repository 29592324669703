import { Component, OnInit } from '@angular/core';
import {GridOptions, IGetRowsParams} from 'ag-grid-community';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment.prod";
import {EnquiryDetailDao} from "./EnquiryDetailDao";
import {PaginationDao} from "../../../CommonObj/PaginationDao";
import {CigarateDao} from "../../Digimozo/Cigarate/cigarate-panel-dash/CigarateDao";
import {UserService} from "../../../Services/User/user.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertMessageService} from "../../../alert-message/alert-message.service";
import {formatDate} from "@angular/common";
import {DatefilterComponent} from "../../../CommonPopups/datefilter/datefilter.component";
import {FileService} from "../../../Services/FileUtilsService/FileService";
import {ExcelReaderCommonComponent} from "../../../CommonPopups/excel-reader-common/excel-reader-common.component";
import {PriceChartDao} from "../price-chart/PriceChartDao";

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './enquiry-details.component.html',
  styleUrls: ['./enquiry-details.component.css']
})
export class EnquiryDetailsComponent implements OnInit {
  tableData: PaginationDao<EnquiryDetailDao> = {data: [], total: 0};
  offset: number = 0;
  limit: number = 10;
  startdate: number = undefined;
  enddate: number = undefined;
  public enquiryType:number=2;

  constructor(public user:UserService,private http: HttpClient, private dialog: MatDialog, private route: ActivatedRoute, private alert: AlertMessageService, private router: Router) {
    this.loadData();
    //user.getAccount().id
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.startdate = queryParams['startdate'];
      this.enddate = queryParams['enddate'];
      this.loadData();
    });
  }


  loadData() {
    this.alert.showLoader();

    const format = 'yyyyMMdd';
    const locale = 'en-IN';

    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const formattedDateFirst = formatDate(firstDay, format, locale);
    const formattedDateLast = formatDate(lastDay, format, locale);

    if (this.startdate == undefined || this.enddate == undefined) {
      this.startdate = parseInt(formattedDateFirst);
      this.enddate = parseInt(formattedDateLast);
    }
    this.http.get<PaginationDao<EnquiryDetailDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/enquirydetails/getall?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=' + this.offset + '&limit=' + this.limit + '&enquirytype='+this.enquiryType).subscribe(value => {
      this.tableData = value;

      // this.tableData.data = value;
      // this.tableData.total = value.length;
      this.alert.hideLoader();
    });

  }


  filterData() {
    this.dialog.open(DatefilterComponent);
  }

  clearFilter() {
    this.router.navigate([], {queryParams: {}});
  }


  tableChange(json) {
    this.offset = json.pageIndex;
    this.limit = json.pageSize;
    this.loadData();

  }

  download() {
    this.http.get<PaginationDao<EnquiryDetailDao>>(environment.appUrl + environment.gsmgatewayservice + 'api/nouriture/enquirydetails/getall?startdate=' + this.startdate + '&enddate=' + this.enddate + '&offset=0&limit=999999&enquirytype='+this.enquiryType).subscribe(value => {
      this.tableData = value;
      var data = value.data;
      var trArr = [];
      var tdArr = [];
      tdArr.push("ID");
      tdArr.push("Date");
      tdArr.push("Contact");
      tdArr.push("Language");
      tdArr.push("Name");
      tdArr.push("Organisation");
      tdArr.push("Enquired For");
      tdArr.push("Pincode");
      tdArr.push("State");
      tdArr.push("City");
      tdArr.push("Delivery Mode");
      tdArr.push("Associated Farmers");
      tdArr.push("Monthly Req.");
      tdArr.push("Material");
      tdArr.push("Feed Type");
      tdArr.push("Brand");
      tdArr.push("Product");
      tdArr.push("Price");
      tdArr.push("Count");
      tdArr.push("Weight");
      tdArr.push("Discounted Amount");
      tdArr.push("MRP");
      tdArr.push("Order Status");

      trArr.push(tdArr);


      for (var i = 0; i < data.length; i++) {
        tdArr = [];
        tdArr.push(data[i].id)
        tdArr.push(data[i].date);
        tdArr.push(data[i].phoneNumber);
        tdArr.push(data[i].language);
        tdArr.push(data[i].name);
        tdArr.push(data[i].organisation);
        tdArr.push(data[i].enquiryType);
        tdArr.push(data[i].pincode);
        tdArr.push(data[i].state);
        tdArr.push(data[i].city);
        tdArr.push(data[i].deliveryMode);
        tdArr.push(data[i].totalFarmers);
        tdArr.push(data[i].monthlyReq);
        tdArr.push(data[i].material);
        tdArr.push(data[i].feedType);
        tdArr.push(data[i].brand);
        tdArr.push(data[i].product);
        tdArr.push(data[i].price);
        tdArr.push(data[i].count);
        tdArr.push(data[i].totalWeight);
        tdArr.push(data[i].totalAmount);
        tdArr.push(data[i].mrpAmount);
        tdArr.push(data[i].status);

        trArr.push(tdArr);
      }
      let file = new FileService<any>();
      file.generateFile(trArr, 'ReportDownload.xlsx');
    });
  }

  showOnlyDealershipCols(){

  }

  showOnlyBuyerCols(){

  }

  showOnlyQueryCols(){

  }

  enquiryTypeChange(value:any){
   this.enquiryType=value
    this.loadData()
  }



}
