
  <div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
      <div class="panel panel-bd lobidragdashboard">
        <div class="panel-heading">
          <button type="button" (click)="onClose()" mat-dialog-close  class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times" aria-hidden="true"></i>
          </button>

          <div *ngIf="!addNewDv">
          <div class="panel-title">
             <h1 mat-dialog-title style="text-align: center; color:  #1c5b7a"><b> Schedule send</b><br></h1>
             <p style="text-align: center">India Standard Time</p>
          </div>
            <div style="" class="panel-body">

            <form ngNativeValidate>
              <mat-dialog-content class="mat-typography" >
                   <div class="form-group col-lg-12">
<!--                         <button style="width: 100%;height: 40px;border: none;background-color: whitesmoke" (click)="currentValue()" >Tommorrow morning  &nbsp;&nbsp;&nbsp;&nbsp;{{tomorrow | date:'dd MMM yyyy hh:mm a'}}</button><br>-->
<!--                         <button style="width: 100% ;height: 40px;border: none;background-color: whitesmoke" (click)="afternoonValue()" [(ngModel)]="afternoon">Tommorrow afternoon &nbsp;&nbsp;&nbsp;&nbsp; {{ afternoon | date:'dd MMM yyyy hh:mm a'}}</button><br>-->
<!--                         <button style="width: 100% ;height: 40px;border: none;background-color: whitesmoke" (click)="tomorrowValue()">{{WeekDay}} morning &nbsp;&nbsp;&nbsp;&nbsp;{{tomorrow | date:'dd MMM yyyy hh:mm a'}}</button>-->
<!--                     <button style="width: 100% ;height: 40px;border: none;background-color: whitesmoke"  >{{prevMonday}}</button><br>-->
                          <button style="width: 100% ;height: 40px;border: none;background-color: whitesmoke"  (click)="pickDateAndTime()"><i class="fa fa-calendar" aria-hidden="true"></i>  Pick Date & Time</button><br>
<!--      <ngx-date-time-picker [selectedDateTime]="date" (getData)="getStartDate($event)" [options]="{timePicker: false}" [dateTimeFormat]="'MM/DD/YYYY'"></ngx-date-time-picker>-->
                    </div>
               </mat-dialog-content>
               <mat-dialog-actions align="end">
<!--                <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>-->
               </mat-dialog-actions>
            </form>
            </div>
            </div>
          <div style="" class="panel-body">
          <div *ngIf="addNewDv">
            <h3 style="text-align: center">Pick Date & Time</h3>   <br>

            <form ngNativeValidate (submit)="filter()">

              <mat-dialog-content class="mat-typography">

                <div class="form-group col-lg-8">
                   <input style="margin-left: 35%" type="datetime-local" class="form-control" [(ngModel)]="startdate" name="startdate" required>
                </div>
              </mat-dialog-content>
              <div class="col-sm-6" style="margin-left:  53%">
              <mat-dialog-actions  >
                <br>
                 <button  [mat-dialog-close]="null"   class="btn btn-add btn-sm"  type="submit">Schedule send<i class="fa fa-send"></i></button>
<!--                <button class="btn btn-danger" [mat-dialog-close]="null">Cancel</button>-->

              </mat-dialog-actions>
              </div>
            </form>

          </div>
          </div>

          </div>
        </div>
      </div>
    </div>

