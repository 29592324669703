import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {Observable} from 'rxjs';
import {AssignAgentModel, ConversationDao} from '../ConversationDao';
import {MessageDao} from '../../ws-template/MessageDao';
import {PaginationDao} from '../../CommonObj/PaginationDao';
import {ConversationGroup} from "../../AgentSupport/agent-client-conversation/ConversationGroup";
const appPrefix = environment.appUrl + environment.whatsappOfficialservice;
@Injectable({
  providedIn: 'root'
})
export class ConversationService {



  constructor(private http: HttpClient) { }
  getConversationUnread(): Observable<Array<ConversationDao>>
  {
    return  this.http.get<Array<ConversationDao>>(appPrefix + 'conversation/getunreadconversation');
  }
  getConversation(search: string, offset: number, limit: number): Observable<PaginationDao<ConversationDao>>
  {
   return  this.http.post<PaginationDao<ConversationDao>>(appPrefix + 'conversation/', {offset, limit, search});
  }
  // tslint:disable-next-line:typedef
  updateReadStatus(id: string)
  {
    return  this.http.get<Array<ConversationDao>>(appPrefix + 'conversation/updatests?id=' + id);
  }
  getMessages(offset: number, limit: number, id: string): Observable<Array<MessageDao>>
  {
     return  this.http.post<Array<MessageDao>>(appPrefix + 'message/getmessagesbyconversation', {offset, limit, dst: id});

  }
  getMessageAfterTime(id: string, afterDate: number): Observable<Array<MessageDao>>
  {
    return  this.http.get<Array<MessageDao>>(appPrefix + 'message/getmsgafter?to=' + id + '&dateTiming=' + afterDate);
  }

  // tslint:disable-next-line:typedef
  saveContact(contactName: string, id: string)
  {
    return  this.http.get<Array<ConversationDao>>(appPrefix + 'conversation/savecontact?id=' + id + '&contact=' + contactName);
  }

  getUserAgents(id:number): Observable<Array<any>>{
    return this.http.get<Array<ConversationDao>>(appPrefix + 'account/getbyallAgent/' + id);
  }

  assignAgentSupport(param:AssignAgentModel): Observable<Array<any>>{
    return this.http.post<Array<ConversationDao>>(appPrefix + 'agent/assignagentsupport',param);
  }

  getAssignedAgent(param:AssignAgentModel): Observable<any>{
    return this.http.post<any>(appPrefix + 'agent/assignednumber',param);
  }
}
