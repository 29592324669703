import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { saveAs } from 'file-saver';
import {environment} from '../../environments/environment.prod';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {UserService} from '../Services/User/user.service';
const appPrefix = environment.appUrl + environment.whatsappOfficialservice;

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  mediaDao: MediaDao;
  constructor(private http: HttpClient, private alert: AlertMessageService, public auth: UserService) {
  }

  dataURItoBlob(dataURI,mediaType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: mediaType });
    return blob;
  }
  downloadFile(id: string, mediaType: string,date: string,fName: string,attachementBase64: string,daoMimeType:string): any {


       if(attachementBase64!= null){
         console.log('attachment value',attachementBase64,daoMimeType)
         saveAs( this.dataURItoBlob(attachementBase64,daoMimeType))
       }
       else {
         date = date.split('-').join('').split(' ').join('').split(':').join('');

         let fileName: string;
         if (mediaType == 'image/jpeg') {
           fileName = 'Whatsapp_Image_' + date + '.jpeg';
         }

         else if (mediaType == 'application/pdf') {
           fileName = 'Whatsapp_Doc'+ date  + '.pdf';
         }
         else if (mediaType == 'image/png') {
           fileName = 'Whatsapp_Image'+ date  + '.png';
         }
        else if (mediaType == 'video/mp4') {
           fileName = 'Whatsapp_Video_' + date + '.mp4';
         }
         else if (mediaType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
           fileName = 'Whatsapp_Doc' + date + '.docx';
         }else if (mediaType == 'text/plain') {
           fileName = 'Whatsapp_Doc' + date + '.txt';
         }else if (mediaType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
           fileName = 'Whatsapp_Doc'+ date  + '.xlsx';
         }
         else {
           fileName = fName;
         }

         this.http.get(appPrefix + 'media/karix' + '?extensionType=' + mediaType + '&id=' + id , {responseType: 'blob', observe: 'response'},)
           .subscribe(response => {
             if(response.status != 400){
               let filename = response.headers.get('Content-Disposition').split('=')[1];
               saveAs(response.body,fileName);
             }
           }, error => {
             this.alert.showErrorMessage('Failed to download File');
             this.alert.hideLoader();
           });
       }
  }

  uploadFile(event)
  {
    return new Promise<MediaDao>((resolve, reject) => {


    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];

      const formData = new FormData();
      formData.append('file', file);
      formData.append('reportProgress', 'true');

      this.http.post<MediaDao>(appPrefix + 'media/', formData, {headers: {Testtt: 'multipart/form-data', Authorization: 'Bearer ' + this.auth.getAccessToken()}}).subscribe(value => {
        resolve(value);
      }, error => {
        throw new Error('File Cannot be uploaded!!');
      });

    }
    });
  }

}
export interface MediaDao{
  media: Array<MediaInnerDao>;
}
export interface MediaInnerDao{
  id: string;
  attachedFileBase64: string;
  mimeType: string;

}
