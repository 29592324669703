<div class="row">
  <div class="col-md-12">

  <form >


    <div class="form-group col-lg-12">
      <label id="example-radio-group-label"> Status </label>
      <br>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="chatbotMasterDao.status" name="status"   >
        <mat-radio-button [value]="1" [checked]="true">Active</mat-radio-button>
        <mat-radio-button [value]="0">Inactive</mat-radio-button>
      </mat-radio-group>
    </div>

    <div  class="form-group col-lg-12">
      <label>Name <sup class="required">*</sup></label>
      <input type="text" class="form-control" placeholder="Enter Name"
             name="chatbotName" [(ngModel)]="chatbotMasterDao.name">
    </div>

    <div class="form-group col-lg-12">
      <button type="button" class="btn btn-add" (click)="submitMasterForm(chatbotMasterDao)">{{submitBtnTxt}}</button>
      <button type="button" mat-dialog-close class="btn btn-add">Cancel</button>
    </div>

  </form>
  </div>
</div>
