import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ModuleMasterDao} from '../ModuleMasterDao';
import {environment} from '../../../environments/environment.prod';
import {ServiceMasterDao} from '../../servicemaster/ServiceMasterDao';
import {UrlMasterDao} from './UrlMasterDao';

@Injectable({
  providedIn: 'root'
})
export class UrlMasterService {

  constructor(private http:HttpClient) { }
  get(moduleId:number):Observable<Array<UrlMasterDao>>
  {
    return this.http.get<Array<UrlMasterDao>>(environment.appUrl+environment.authMicroservice+"urlmaster/get/"+moduleId);
  }
  save(data:Array<UrlMasterDao>):Observable<Array<UrlMasterDao>>
  {
    return this.http.post<Array<UrlMasterDao>>(environment.appUrl+environment.authMicroservice+"urlmaster/save",data);
  }


}
