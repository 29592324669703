import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-common-active-or-disable',
  templateUrl: './common-active-or-disable.component.html',
  styleUrls: ['./common-active-or-disable.component.css']
})
export class CommonActiveOrDisableComponent implements OnInit {
  @Input("active")active:boolean
  constructor() { }

  ngOnInit(): void {
  }

}
