import {Component, Input, OnInit} from '@angular/core';
import {MenuDao} from '../Services/User/MenuDao';
import {MenusubService} from '../Services/MenuSub/menusub.service';
import {AccountMasterService} from '../account-master/AccountMasterService/account-master.service';
import {UserService} from '../Services/User/user.service';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {NotifierService} from 'angular-notifier';
import {DriveSubscriptionDao} from '../Drive/DriveSubscriptionDao';
import {DriveService} from '../Drive/drive.service';
import {AuthGuard} from "../Services/Routing/Authguard";
import {FeatureDao} from "../Feature/feature-dao";
import {Router} from "@angular/router";
import {AccountBalanceDao} from "../account-master/account-balance-dao";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('menuJson') menuJson: Array<MenuDao>;
  closingBalance = 0;
  tableList: FeatureDao;
  driveSub: DriveSubscriptionDao = {accountId: 0, allowed: 0, used: 0};
  accountt: number;
  accountBalanceDao: AccountBalanceDao;
  private refreshSubscription: Subscription;

  // tslint:disable-next-line:max-line-length
  constructor(private accountService: AccountMasterService, private driveService: DriveService, public menuObj: MenusubService, private account: AccountMasterService, private user: UserService, private alert: AlertMessageService, private notifier: NotifierService, private pageAccess: AuthGuard, private router: Router) {


    this.loadBalance();
      this.driveService.get(this.user.user.accountId).subscribe(value => {
      this.driveSub = value;
    });
  }

  // tslint:disable-next-line:typedef
  loadBalance() {
    this.accountt = this.user.user.accountId;
    this.account.getbalance(this.user.user.accountId).subscribe(value => {
      this.accountBalanceDao = value;
      console.log('accountBalanceDao', this.accountBalanceDao);
      let service = this.user.getService().serviceUrl;
      console.log('service', this.user.getService().serviceUrl);
      if (service == 'wabuissness/v1') {
        this.closingBalance = this.accountBalanceDao.balance;
      } else if (service=='waunofficial/v1')  {
        this.closingBalance = this.accountBalanceDao.balanceUnofficial;
      }else if (service=='accounts/v1')  {
        this.closingBalance = this.accountBalanceDao.resellerBalance;
      }
      else {
        this.closingBalance=0;
      }
      console.log('closingBalalnce', this.closingBalance);
      this.alert.hideLoader();
    });

  }


  ngOnInit(): void {


    const urls = this.router.url;
    // let after =urls.split("?")[1];
    // after=after.split("=")[1]
    console.log('paramurl', urls);


    console.log('Menu json is', this.menuJson);

    this.pageAccess.getPagePermission('sidebar').subscribe(value => {
      console.log('sidebar menu', value);
      this.tableList = value;
    })
    // Subscribe to the refresh observable
    this.refreshSubscription = this.accountService.refresh$.subscribe(() => {
      // Perform any actions needed on refresh
      this.loadBalance();
    });
  }


  // tslint:disable-next-line:typedef
  refreshBal() {

    this.alert.showLoader();
    this.loadBalance();
  }

  // tslint:disable-next-line:variable-name
  getMbOrGb(number: number): string {
    if (number >= 1000) {
      return (number / 1000).toFixed(2) + ' GB';
    } else {
      return number.toFixed(2) + ' MB';
    }

  }

  percentage(partialValue: number, totalValue: number): number {
    return Number(((100 * partialValue) / totalValue).toFixed(2));
  }


  // tslint:disable-next-line:typedef
  getColour(used: number, allowed: number) {
    const percentage = this.percentage(used, allowed);
    if (percentage <= 25) {
      return 'green';
    } else if (percentage <= 70) {
      return 'orange';
    } else {
      return 'red';
    }


  }


}
