
<section class="content-header">
  <div class="header-icon">

  </div>

  <div class="modal fade" id="filterModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="headingpopup" style="text-align: center;width: 100%;" class="modal-title"></h4>
        </div>
        <form id="filterForm">


        </form>

      </div>

    </div>
  </div>

  <div class="buttonexport">

    <div class="panel-title">


    </div>
  </div>



</section>
<!-- Main content -->
<!-- Main content -->
<section class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">
          <div class="btn-group" id="buttonexport">
            <a href="#">
              <h4>Conversation</h4>
            </a>
          </div>
        </div>




        <div  id="tablediv">
          <div class="table-responsive">


            <table class="table table-bordered table-striped table-hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>ChatbotId</th>
                <th>Key</th>
                <th>KeyValue</th>
                <th>validInputs</th>
                <th>ChangeStage</th>
              </tr>
              </thead>
              <tbody>
              <tr style="text-align: center" *ngIf="tableData.length==0">
                <td colspan="14">No Entry</td>


              </tr>
              <ng-container *ngFor="let data of tableData;let ind = index">
                <tr>
                  <td  [innerHTML]="data.id"  ></td>
                  <td  [innerHTML]="data.chatbotId"  ></td>
                  <td  [innerHTML]="data.key"  ></td>
                  <td  [innerHTML]="data.keyValue"  ></td>
                  <td  [innerHTML]="data.validInputs"  ></td>
                  <td  [innerHTML]="data.changeStage"  ></td>
                </tr>
              </ng-container>



              </tbody>
            </table>
          </div>
          <mat-paginator (page)="tableChange($event)"
                         [length]=tableData.length
                         [pageSize]="10"
                         [pageSizeOptions]="[2, 5, 25, 100]">
          </mat-paginator>

        </div>



      </div>
    </div>
  </div>


</section>
